export default {
  telegram: {
    options: {
      title: 'Telegram',
      url: 'https://t.me/##BOT_NAME##?start=##TOKEN##',
      icon: {
        component: 'icon-telegram',
        src: '',
      },
      exclusion: false,
      subscribed: false,
      undefinedStatus: true,
      disabled: false,
      maskEnabled: false,
      hidden: false,
    },
  },
  viber: {
    options: {
      title: 'Viber',
      url: 'viber://pa?chatURI=##BOT_NAME##&context=##TOKEN##',
      icon: {
        component: 'icon-viber',
        src: '',
      },
      exclusion: false,
      subscribed: false,
      undefinedStatus: true,
      disabled: false,
      maskEnabled: false,
      hidden: false,
    },
  },
  vk: {
    options: {
      title: 'Vk',
      url: 'https://vk.me/##BOT_NAME##?ref=##TOKEN##&ref_source=PWA',
      icon: {
        component: 'icon-vk',
        src: '',
      },
      exclusion: true,
      subscribed: false,
      undefinedStatus: true,
      disabled: false,
      maskEnabled: true,
      hidden: false,
    },
  },
  facebook: {
    options: {
      title: 'Facebook',
      url: 'https://m.me/##BOT_NAME##?ref=##TOKEN##',
      icon: {
        component: 'icon-facebook-messenger',
        src: '',
      },
      exclusion: false,
      subscribed: false,
      undefinedStatus: true,
      disabled: false,
      maskEnabled: true,
      hidden: false,
    },
  },
};
