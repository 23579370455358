<template>
  <div :class="b({ isDisabled: disabled, hasFail: errors.length })">
    <label
      v-if="labelBefore"
      :class="b('label-before')"
      :for="vId"
      v-html="labelBefore"
    ></label>

    <div :class="b('container')">
      <input
        :id="vId"
        class="form-control"
        type="text"
        :value="result"
        :disabled="disabled"
        @input="handlerInput"
        @change="handlerChange"
      />

      <div :class="b('controls')">
        <button
          :class="b('button-increment')"
          :disabled="disabled || value === max"
          @click.prevent="increase"
        >
          <IconPlus width="10" />
        </button>

        <button
          :class="b('button-decrement')"
          :disabled="disabled || value === min"
          @click.prevent="decrease"
        >
          <IconMinus width="10" />
        </button>
      </div>
    </div>

    <label
      v-if="labelAfter"
      :class="b('label-after')"
      :for="vId"
      v-html="labelAfter"
    ></label>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';
  import IconPlus from 'general/components/icons/IconPlus';
  import IconMinus from 'general/components/icons/IconMinus';

  export default {
    name: 'control-counter',

    mixins: [
      baseMixin,
    ],

    components: {
      IconPlus,
      IconMinus,
    },

    model: {
      prop: 'value',
      event: 'update',
    },

    props: {
      value: {
        type: Number,
        default: 0,
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      step: {
        type: Number,
        default: 1,
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      labelBefore: {
        type: String,
      },
      labelAfter: {
        type: String,
      },
    },

    data: () => ({
      result: 0,
      errors: [],
    }),

    watch: {
      value: {
        handler(data) {
          this.result = data;
        },
        immediate: true,
      },
    },

    methods: {
      /**
       * Увеличение счетчика
       */
      increase() {
        this.result += this.step;
        this.recalculate();
      },

      /**
       * Уменьшение счетчика
       */
      decrease() {
        this.result -= this.step;
        this.recalculate();
      },

      /**
       * Обработчик изменений input'а при каждом вводе
       *
       * @param {Object} event
       */
      handlerInput(event) {
        const { value } = event.target;

        if (this.debug) {
          console.log('handlerInput!');
          console.log('value: ', value);
        }

        const isValidate = /^-?(\d+)?$/.test(value);

        if (this.debug) {
          console.log('isValidate: ', isValidate);
        }

        if (!isValidate || value.length > 1) {
          this.recalculate(parseInt(value, 10));
        }
      },

      /**
       * Обработчик изменений input'а при потере фокуса
       *
       * @param {Object} event
       */
      handlerChange(event) {
        const { value } = event.target;

        if (this.debug) {
          console.log('handlerInput!');
          console.log('value: ', value);
        }

        this.recalculate(parseInt(value, 10));
      },

      /**
       * Пересчет и проверка условий
       *
       * @param {number} value
       */
      recalculate(value = null) {
        if (this.debug) {
          console.log('recalculate!');
          console.log('value: ', value);
          // console.log('min: ', this.min);
          // console.log('max: ', this.max);
          // console.log('step: ', this.step);
          console.log('result before: ', this.result);
        }

        if (value !== null) {
          if (this.debug) {
            console.log('manual input!');
          }

          this.result = (!isNaN(value)) ? value : 0;
          this.$forceUpdate();
        }
        if (this.min !== null && this.result < this.min) {
          if (this.debug) {
            console.log('set min!');
          }

          this.result = this.min;
        }
        if (this.max !== null && this.result > this.max) {
          if (this.debug) {
            console.log('set max!');
          }

          this.result = this.max;
        }

        if (this.debug) {
          console.log('result after: ', this.result);
        }

        this.$emit('update', this.result);
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-counter
    &_is-disabled
      cursor not-allowed

    > label
      ~/_is-disabled&
        pointer-events none

    &__label-before
      margin-bottom 10px

    &__container
      position relative

      > input
        padding-right 32px

        &:disabled
          pointer-events none

    &__controls
      flex()
      flex-direction column
      position absolute
      top 0
      right 0
      bottom 0

    &__button-increment
    &__button-decrement
      link()
      reset-button()
      flex(center, center)
      size(24px, 19px)
      border 1px solid transparent
      outline none
      state-background(#fff, $brand-info)
      state-border(#ccc, $brand-info)
      state-color($color-black, #fff)

      &:disabled
        color lighten($color-black, 50%)
        opacity .65
        pointer-events none

    &__button-increment
      border-bottom 0
      border-top-right-radius 2px

    &__button-decrement
      border-bottom-right-radius 2px

    &__label-after
      margin-left 10px
</style>
