var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 128 99.64",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M113.27 13.74H88.59v1.33A10.14 10.14 0 0 1 78.46 25.2H49.52a10.14 10.14 0 0 1-10.14-10.13v-1.33H14.7A14.7 14.7 0 0 0 0 28.44v56.49a14.7 14.7 0 0 0 14.7 14.71h98.6A14.7 14.7 0 0 0 128 84.93V28.44a14.7 14.7 0 0 0-14.73-14.7zM27 45.47a12.35 12.35 0 0 1-.39-1.41 10.39 10.39 0 0 1 0-4.54 10.1 10.1 0 0 1 2.64-4.65 12.59 12.59 0 0 1 2.46-2 10.86 10.86 0 0 1 2.37-1.23 9.81 9.81 0 0 1 7.6.73 6.87 6.87 0 0 1 2.64 2.46s4.39.31 2.9 9.24a7.42 7.42 0 0 1-.39 1.41c.89-.07 1.9.42.94 3.87-.71 2.51-1.39 3.21-1.88 3.26a10.87 10.87 0 0 1-6.58 8.13 7.43 7.43 0 0 1-4.84 0 10.7 10.7 0 0 1-6.58-8.15c-.49 0-1.17-.76-1.88-3.27-.89-3.43.16-3.95.99-3.85zm10 35H15.64c.11-8.88-.23-13.64 5.51-15.73a49.27 49.27 0 0 0 8.36-4l4 12.74.55 1.73 1.8-5.12C31.73 64.25 36.2 64 37 64c.79 0 5.23.28 1.1 6.06l1.8 5.12.55-1.73 4-12.74a49.77 49.77 0 0 0 8.36 4c5.77 2.09 5.41 6.85 5.51 15.73zM113.61 71h-43v-5.5h43zm0-11.55h-43V54h43zm0-11.57h-43v-5.49h43z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M49.52 20.32h28.91a5.25 5.25 0 0 0 5.25-5.25v-3.58a5.25 5.25 0 0 0-5.25-5.25h-1.65V4a4 4 0 0 0-4-4H55.19a4 4 0 0 0-4 4v2.24h-1.67a5.25 5.25 0 0 0-5.25 5.25v3.58a5.25 5.25 0 0 0 5.25 5.25z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }