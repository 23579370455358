import _ from 'lodash';
import { default as schemaComponents, Layouts } from '@/bc/pages/constructor/schemaComponents/index';

_.each(schemaComponents, (value, key) => {
  if (key !== 'mbst-screen' && key !== 'mbst-toolbar') {
    value.properties.loop = {
      isEnabled: false,
      dataSource: null,
      aliasName: null,
      isShowPreloader: false,
      pagination: {
        enabled: false,
        page: 1,
        pageSize: 10,
        showButtonUp: false,
        showInformer: true,
      }
    }
  }
});

_.each(Layouts, (value, key) => {
  value.properties.loop = {
    isEnabled: false,
    dataSource: null,
    aliasName: null,
    isShowPreloader: false,
    pagination: {
      enabled: false,
      page: 1,
      pageSize: 10,
      showButtonUp: false,
      showInformer: true,
    }
  }
});

const excludedComponentsFromPalette = [
  'mbst-screen',
  'mbst-toolbar__item',
  'mbst-bottom-toolbar__item',
  'mbst-flexrow__col',
  'mbst-row__item',
  'mbst-slider__slide',
  'mbst-tabs__tab',
  'mbst-list__item',
  // ** hide old components
  'mbst-row',
  'mbst-toolbar',
  'mbst-bottom-toolbar',
  'mbst-input',
  'mbst-radio-buttons',
  'mbst-checkboxes'
];

const state = {
  data: _.omit(schemaComponents, excludedComponentsFromPalette),
  layouts: Layouts
};

const getters = {};

const actions = {};

const mutations = {
  setScreen (state, screen) {
    state.data = screen;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
