export default $axios => {
  const resource = '/applications'

  const methods = {
    /**
     * Create Application
     * @param {Object} data
     * @param {Object} config - axios config
     * @returns {Promise<any>}
     */
    // create: (data = { withData: 0 }, config = {}) => $axios.$post('/apps', data, config),
    create: (data = { withData: 0 }, config = {}) => $axios.$post('/pwalessapps', data, config),

    update: (id, data, config = {}) => $axios.$put(`/apps/${id}`, data, config),

    remove: (id, config = {}) => $axios.$delete(`${resource}/${id}`, config),

    /**
     * Get a list of applications
     * @param {Object} config - axios config
     * @param {Object} config.params
     * @param {Number} config.params.page
     * @param {Number} config.params.pageSize
     * @returns {Promise<any>}
     */
    get: (config = {}) => $axios.$get(resource, config),

    /**
     * Get the application by ID
     * @param {number|string} id
     * @param {Object} config
     * @returns {*}
     */
    getById: (id, config = {}) => $axios.$get(`/apps/${id}`, config),

    // Copy the Application
    copy: {

      /**
       * Full Application copy (all of set up and all of data)
       * @param {string} data - x-www-form-urlencoded (to use new URLSearchParams)
       * @param {Object} config - axios config
       * @returns {*}
       */
      fullWithData: (data = null, config = {}) => $axios.$post(resource, data, config),

      /**
       * Full copy Application's set up of
       * @param {Object|null} data
       * @param {string} data.sourceTenant
       * @param {string} data.sourceAppId
       * @param {string} data.destServer
       * @param {string} data.destTenant
       * @param {string} data.destAppId
       * @param {string} [data.destLogin]
       * @param {string} [data.destPass]
       * @param {Number} [data.destCreateNew]
       * @param {Object} config - axios config
       * @returns {*}
       */
      full: (data = null, config = {}) => $axios.$post(`${resource}/copybranch1`, data, config),

      /**
       * Partial copy Application's set up of
       * @param {Object|null} data
       * @param {Array}  data.blocks
       * @param {string} data.sourceTenant
       * @param {string} data.sourceAppId
       * @param {string} data.destServer
       * @param {string} data.destTenant
       * @param {string} data.destAppId
       * @param {string} [data.destLogin]
       * @param {string} [data.destPass]
       * @param {number} [data.destCreateNew]
       * @param {Object} config - axios config
       * @returns {*}
       */
      partial: (data = null, config = {}) => $axios.$post(`${resource}/copybranch2`, data, config),
    }

  }

  return methods
}
