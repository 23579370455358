'use strict';

// Libraries
import logger from 'general/logger';
import Cookie from 'js-cookie';

var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');
var DateTimePicker = require('eonasdan-bootstrap-datetimepicker');
var moment = require('moment');

// Views
var TriggerOperationsTreeView = require('views/trigger-operations/TriggerOperationsTreeView');
var CronTabView = require('views/crontab/CronTabView');
var WarningManualView = require('views/trigger/WarningManualView');

// Collections
var OperationsCollection = require('collections/OperationsCollection');
var TriggerOperationsCollection = require('collections/TriggerOperationsCollection');

// Templates
var _triggerSettings = require('text!bcTemplates/triggerSettings.html');

// Helper
var PubSub = require('helper/pubsub');

module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'details-body b-modal-hero__body b-global-box',
    id: 'triggerSettings',

    events: {
        'click #createTriggerCancel': 'cancel', // возврат к списку триггеров
        'click #saveTrigger': 'save', // сохранение настроек триггера
        'change [name="AutoStart"]': 'changeTriggerMode', // переключение режима триггера авто/ручной
        'click [data-js-toggle="schedule"]': 'scheduleSetting', // Настройка крон

        'click [data-js-toggle="manualExecute"]': 'manualExecute', // Execute now
        'click [data-js-toggle="cancelManualExecute"]': 'cancelManualExecute', // Cancel Execute now
        'click [data-js-toggle="warningStop"]': 'warningStop', // Cancel Execute now

        'click [data-js-toggle="startAutomatic"]': 'activateTrigger'
    },

    bindings: {
        '#TriggerName': 'TriggerName',
        '[name="SmartfilterBranch"]': 'SmartfilterBranch',
        '[name="AutoStart"]': {
            observe: 'AutoStart',
            selectOptions: {
                collection: function () {
                    return [{value: false, label: 'Manual'}, {value: true, label: 'Automatic'}];
                }
            }
        },
        '[name="ManualRecordsScope"]': 'ManualRecordsScope',
        '[name="AutoAllRecordsScope"]': { // Toggle
            observe: 'AutoAllRecordsScope',
            onSet: 'toggleRecordsScope'
        },
        '[name="AutoAllRecordsLimitState"]': 'AutoAllRecordsLimitState',
        '[name="AutoAllRecordsLimitCount"]': {
          observe: 'AutoAllRecordsLimitCount',
            initialize: function($el, model, options) {
            $el.on('input keyup change', function(e) {
                var str = $el.val();
                if (str === '') str = '1';

                var result = str.match(/[0-9]/gm);
                if (result !== null) {
                  str = '';
                  result.forEach(function(item, i, arr) {
                    str = str + item;
                  });
                }

                model.set(options.observe, str);
            });
          },
          onGet: function(value, options) {
            return value;
          }
        },
        '[name="AutoAllRecordsLimitDate"]': {
            observe: 'AutoAllRecordsLimitDate',
            initialize: function($el, model, options) {
                $el.datetimepicker({
                    format: "DD/MM/YYYY HH:mm A"
                });
                $el.on('dp.change', function(e) {
                    var date = new Date(moment($el.val(), "DD/MM/YYYY HH:mm A"));
                    var milliseconds = date.getTime();

                    if ((milliseconds + 5000) < Date.now()) {
                        var trueTime = moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS");
                    } else {
                        var trueTime = moment($el.val(), "DD/MM/YYYY HH:mm A").format("YYYY-MM-DD HH:mm:ss.SSSSSS");
                    }

                    model.set(options.observe, trueTime);
                });
            },
            onGet: function(value, options) {
                return moment(value, "YYYY-MM-DD HH:mm:ss.SSSSSS").format("DD/MM/YYYY HH:mm A");
            }
        },
        '[name="AutoNewRecordsScope"]': { // Toggle
            observe: 'AutoNewRecordsScope',
            onSet: 'toggleRecordsScope'
        },
        '[name="AutoNewRecordsInstantly"]': {
            observe: 'AutoNewRecordsInstantly',
            onSet: function(val, options) {
                return (val == 'true');
            }
        },
        '[data-js-toggle="manualExecute"]': {
            observe: 'State',
            update: function($el, val, model, options) {
                switch (val) {
                    case 'Idle':
                        $el.text(lang('Execute now')).attr('data-js-toggle', 'manualExecute');
                        break;
                    case 'Scheduled':
                        $el.text(lang('Cancel executing')).attr('data-js-toggle', 'cancelManualExecute');
                        break;
                    case 'Executing':
                        $el.text(lang('Cancel executing')).attr('data-js-toggle', 'warningStop');
                        break;
                }
            }
        },
        '[data-js-toggle="startAutomatic"]': {
            observe: 'Active',
            update: function($el, val, model, options) {
                if (val) {
                    $el.text(lang('Stop automatic mode'));
                } else {
                    $el.text(lang('Start automatic mode'))
                }
            }
        }
    },

    toggleRecordsScope: function(value, options) {
        var val = true, opt = 'AutoNewRecordsScope';
        switch (options.observe) {
            case 'AutoNewRecordsScope':
                opt = 'AutoAllRecordsScope';
                break;
            case 'AutoAllRecordsScope':
                opt = 'AutoNewRecordsScope';
                break;
        }
        if (value) {
            val = false;
        }
        this.$('[name="' + opt + '"]').attr('checked', val);
        this.model.set(opt, val);

        return value;
    },

    initialize: function(options) {
        this.options = options || {};
        this.triggersView = this.options.triggersView;
        this.tpl = _.template(_triggerSettings);
        this.listenTo(this.model, 'change', this.ch, this);
        if (this.model.isNew()){
            this.save();
        }
    },

    ch: function() {
        this.$('#saveTrigger').attr('disabled', false);
    },

    manualExecute: function(e) {
        // var Model = Backbone.Model.extend();
        // var warningManualView = new WarningManualView({
        //     parentView: this,
        //     isFooter: true,
        //     model: new Model({
        //         title: lang('Execute now'),
        //         text: lang('Trigger may take some time to run. Start now?'),
        //         footer: [
        //             { text: lang('Cancel'), className: 'btn-default', attr: 'data-dismiss="modal"' },
        //             { text: lang('Yes'), className: 'btn-primary', attr: 'data-js-toggle="executeTrigger"' },
        //         ]
        //     })
        // });
        //
        // warningManualView.renderInner();
        // $('body').append(warningManualView.$el);
        // $(warningManualView.el).modal('show');


        e.preventDefault();
        let t = this;
        const auth = Cookie.getJSON('__mbst_authdata');
        let accessToken = auth['accessToken'];
        $.ajax({
            url: '/api/v8/triggers/execute/' + t.model.get('id'),
            type: 'GET',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            },
        })
          .done(function (a, b, c) {
              if (a.success) {
                  $('[data-js-toggle="manualExecute"]').attr('disabled', false);
                  $('[data-js-toggle="manualExecute"]').css('opacity', '0');
                  gritlong(lang('trigger is running by ') + a.success + lang(' records'), 'growl-success');
              } else {
                  gritlong(lang('not found records for trigger'));
              }
          })
          .fail(function (a, b, c) {
              let data = a.responseJSON;
              let error = {};
              if (data.errors) {
                  error.code = data.errors[0].code || null;
                  error.detail = data.errors[0].detail || null;
                  error.status = data.errors[0].status || null;
                  error.title = data.errors[0].title || null;
              } else {
                  error.code = data.ErrorNNumber || null;
                  error.detail = data.ErrorText || null;
              }
              gritlong(lang(error.detail), 'growl-danger');
          });


    },

    cancelManualExecute: function(e) {
        e.preventDefault();
        var t = this;
        t.model.fetch({
            reset: true,
            beforeSend: function() {
                // TODO: добавить спиннер загрузки данных
            },
            success: function(model, response, options) {
                if (t.model.get('State') === "Executing") {
                    t.warningStop();
                } else {
                    var Model = Backbone.Model.extend();
                    var warningManualView = new WarningManualView({
                        parentView: t,
                        isFooter: true,
                        model: new Model({
                            title: lang('Cancel Executing'),
                            text: lang('Are you sure want to cancel trigger execution?'),
                            footer: [
                                { text: lang('Cancel'), className: 'btn-default', attr: 'data-dismiss="modal"' },
                                { text: lang('Yes'), className: 'btn-primary', attr: 'data-js-toggle="cancelExecutingTrigger"' },
                            ]
                        })
                    });

                    warningManualView.renderInner();
                    $('body').append(warningManualView.$el);
                    $(warningManualView.el).modal('show');
                }
            },
            error: function(model, response, options) {
                // console.log(model);
            },
            complete: function(model, response, options) {
                // console.log(model);
            }
        });
    },

    warningStop: function() {
        var Model = Backbone.Model.extend();
        var warningManualView = new WarningManualView({
            parentView: this,
            isFooter: true,
            model: new Model({
                title: lang('Cancel Executing'),
                text: lang('You can\'t stop trigger execution, until it stops'),
                footer: [
                    { text: lang('Close'), className: 'btn-default', attr: 'data-dismiss="modal"' },
                ]
            })
        });

        warningManualView.renderInner();
        $('body').append(warningManualView.$el);
        $(warningManualView.el).modal('show');
    },

    render: function() {
        var t = this;
        console.info('Trigger Model:');
        console.log(t.model);
        t.$el.empty();

        var triggerSettingsView = t.tpl({
            trigger: t.model.toJSON()
        });
        t.$el.append(triggerSettingsView);

        $('#tabpane-trigger').append(t.$el);

        // t.$el.css('padding-bottom', t.$('>.b-global-box__footer').outerHeight());
        t.$('.b-trigger__settings').css('padding-bottom', t.$('.b-trigger__settings .b-global-box__footer').outerHeight());

        t.stickit();

        t.initOperations();

    },

    initOperations: function() {
        var t = this;

        var OperationsView = Backbone.View.extend({
            initialize: function() {
                this.tpl = _.template('<option value="<% print(id); %>"><% print(label); %></option>');
            },
            render: function() {
                var that = this;
                var $items = _.map(that.collection.models, function(model) {
                    return that.tpl({id: model.get('id'), label: model.get('operation').Name});
                });
                this.$el.html($items).trigger('change');
            }
        });
        t.operationsView = new OperationsView({
            collection: new OperationsCollection()
        });
        t.operationsView.collection.fetch({
            beforeSend: function() {

            },
            success: function(collection, response, options) {
                t.initTriggerOperations();
            }
        });
        console.log('OperationsView:');
        console.log(this.operationsView);
    },
    initTriggerOperations: function() {
        var t = this;

        t.triggerOperations = new TriggerOperationsTreeView({
            triggerView: t,
            operationsView: t.operationsView,
            collection: new TriggerOperationsCollection()
        });

        if (!t.model.isNew()) {
            t.fetchTriggerOperations();
        } else {
            t.triggerOperations.render();
        }
    },

  fetchTriggerOperations: function() {
    this.triggerOperations.collection.fetch({
      reset: true,
      data: $.param({id: this.model.get('id')}),
      beforeSend: function() {
        // TODO: добавить спиннер загрузки данных
      },
      success: function(collection, response, options) {
        // TODO: добавить обработку естессна (если нет данных соответсвенно сообщить об этом)
        // console.log(collection);
      },
      error: function(collection, response, options) {
        // console.log(collection);
      },
      complete: function(collection, response, options) {
        // console.log(collection);
      }
    });
  },

    getTriggerOperations: function() {
        var t = this;
        $.ajax({
            url: '/triggers/triggeroperationsapi',
            type: 'GET',
            beforeSend: function() {

            }
        }).done(function(data) {
            var pars = {};

            try {
                pars = JSON && JSON.parse(data) || $.parseJSON(data);
            } catch (error) {
                logger.exception(error, data);
            }

            MOBSTEDAPP.operations = pars;
            var collection = t.parseCollection( _.filter(pars, function(obj) { return obj.TriggerId == t.model.get('id') }) );
            t.triggerOperations.collection.reset(collection);
        }).fail(function() {

        }).always(function() {

        });
    },

    activateTrigger: function() {
        if (this.model.get('Active')) {
            this.model.set('Active', false);
        } else {
            this.model.set('Active', true);
        }
        this.save();
    },

    // Сохранение настроек триггера
    save: function() {
        var t = this, isNew = t.model.isNew();

        t.model.save({}, {
            beforeSend: function() {
                t.$('#saveTrigger').attr('disabled', true);
            },
            success: function(m, response) {
                //console.log('response');
                //console.log(response);
                if (response && _.isEmpty(response.error)) {
                    m.unset('error');
                    if (isNew) {
                        t.triggersView.collection.add(m);
                        MOBSTEDAPP.sfTriggers.push(m.toJSON());
                    }
                    t.triggersView.render();
                    grit(lang('Save'), 'growl-success');
                } else {
                    gritlong(lang('Error saving changes') + ": " + lang(response.error), 'growl-warning');
                }
                t.$('#saveTrigger').attr('disabled', true);
            },
            error: function(m, response) {
                //console.log('error');
                grit(lang('Error saving changes'), 'growl-warning');
                t.$('#saveTrigger').attr('disabled', false);
                //console.log(response);
            },
            complete: function(m, response) {
                //console.log('complete');
                //console.log(response);
            }
        });
    },

    changeTriggerMode: function(e) {
        console.log($(e.target).val());
        $('[data-js-autostart]').hide();
        $('[data-js-autostart="'+$(e.target).val()+'"]').show();
    },

    scheduleSetting: function(e) {
        e.preventDefault();
        var type = $(e.currentTarget).attr('data-target');
        var modelType = this.model.get(type);
        var model = {};

        try {
          model = JSON && JSON.parse(modelType) || $.parseJSON(modelType);
        } catch (error) {
            logger.exception(error, modelType);
        }

        var cronTabView = new CronTabView({
            parentView: this,
            type: type,
            model: model,
        });
        cronTabView.render();
        $('body').append(cronTabView.$el);
        $(cronTabView.el).modal('show');
    },

    // Навешивает на селекты seletc2
    // TODO: переделать в универсальный метод, используется во многих местах
    // ----------------------------------------------------
    selectTwo: function() {

        $('.b-trigger__settings .b-constructor__select').each(function () {

            var select = $(this).children('select'),
                options = {};

            if ($(this).attr('data-placeholder') && $(this).attr('data-placeholder') !== '') {
                options.placeholder = $(this).attr('data-placeholder');
            }

            switch (select.attr('data-type')) {
                case 'tags':
                    options.tags = true;
                    break;

                case 'nosearch':
                    options.minimumResultsForSearch = -1;
                    break;

            }

            if (!select.hasClass('select2-hidden-accessible')) {
                select.select2(options);
            }

        });

    },

    cancel: function(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.triggerOperations)
            this.triggerOperations.close();

        this.undelegateEvents();
        this.$el.removeData().unbind();

        this.remove();
    }

});