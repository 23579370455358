/* global window */

export default {
  methods: {
    /**
     * @param {string} text
     * @returns {boolean}
     */
    confirm(text) {
      return window.confirm(text);
    },
  },
};
