'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Models
var TriggerOperationItemModel = require('models/TriggerOperationItemModel');
var TriggerOperationModel = require('models/TriggerOperationModel');

// Views
var TriggerOperationsItemView = require('views/trigger-operations/TriggerOperationsItemView');
var TriggerOperationSettingsView = require('views/TriggerOperationSettingsView');

// Templates
var _triggerOperations = require('text!bcTemplates/trigger-operations/triggerOperationsTree.html');

// Helper
var PubSub = require('helper/pubsub');

module.exports = Backbone.View.extend({

    el: '#triggerOperations',

    events: {
        'click #createTriggerOperation': 'createOperation',
        'click #addTriggerOperation': 'addTriggerOperation',
    },

    initialize: function(options) {
        this.triggerView = options.triggerView;
        this.operationsView = options.operationsView;
        this.$el.html( _.template(_triggerOperations) ).css('height', '100%');
        this.operationsView.setElement(this.$('#all-operations'));
        this.operationsView.$el.select2({
            tags: true
        });
        this.operationsView.render();

        this.listenTo(this.collection, "reset", this.render, this);
        this.listenTo(this.collection, "add", this.render, this);
    },

    render: function() {
        var t = this;
        t.itemView = [];
        t.$triggerOperationsItemView = [];

        _.each(t.collection.models, function(model) {
            var triggerOperationsItemView = new TriggerOperationsItemView({
                triggerOperationsView: t,
                model: model
            });

            t.itemView.push(triggerOperationsItemView);
            triggerOperationsItemView.render();
            t.$triggerOperationsItemView.push(triggerOperationsItemView.$el);

            if ( !_.isEmpty(triggerOperationsItemView.model.get('success')) ) {
                t.rec(triggerOperationsItemView.model.get('success'), 'onSuccess');
            }
            if ( !_.isEmpty(triggerOperationsItemView.model.get('fail')) ) {
                t.rec(triggerOperationsItemView.model.get('fail'), 'onFail');
            }
            if ( !_.isEmpty(triggerOperationsItemView.model.get('always')) ) {
                t.rec(triggerOperationsItemView.model.get('always'), 'onAlways');
            }
        });

        t.$el.find('.b-trigger-operations__list-body').empty();
        t.$el.find('.b-trigger-operations__list-body').append(t.$triggerOperationsItemView);
        t.$el.css('padding-bottom', t.$('>.b-global-box__header').outerHeight());
    },

    rec: function(model, type) {
        var t = this

        var tempM = new TriggerOperationItemModel(model);
        var level = tempM.get('triggerOperation').Level || 0;
        var tempV = new TriggerOperationsItemView({
            type: type,
            offset: 20*level,
            triggerOperationsView: t,
            model: tempM
        });
        tempV.render();
        t.$triggerOperationsItemView.push(tempV.$el);

        if ( !_.isEmpty(tempM.get('success')) ) {
            t.rec(tempM.get('success'), 'onSuccess');
        }
        if ( !_.isEmpty(tempM.get('fail')) ) {
            t.rec(tempM.get('fail'), 'onFail');
        }
        if ( !_.isEmpty(tempM.get('always')) ) {
            t.rec(tempM.get('always'), 'onAlways');
        }
    },

    close: function() {
        PubSub.off('initOperationSettings');
        _.each(this.itemView, function(itemView){
            if (itemView.close){
                itemView.close();
            }
        });
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    addTriggerOperation: function(e) {
        var t = this;
        if (e) {
            e.preventDefault();
        }
        var idOperation = t.$('#all-operations').val(),
            operationModel = t.operationsView.collection.get(idOperation);

        var triggerOperationModel = new TriggerOperationModel({
            TriggerId: t.triggerView.model.get('id'),
            OperationId: operationModel.get('id'),
        });
        triggerOperationModel.save({}, {
            beforeSend: function() {

            },
            success: function(m, response) {
                console.log(response);
                if (response && _.isEmpty(response.error)) {
                    m.unset('error');
                    t.collection.add(new TriggerOperationItemModel({
                        id: m.get('id'),
                        triggerOperation: m.toJSON(),
                        operation: operationModel.get('operation'),
                        operationExt: operationModel.get('operationExt')
                    }))
                    grit(lang('Add operation'), 'growl-success');
                } else {
                    grit(lang('Error add operation'), 'growl-warning');
                }
            },
            error: function(m, response) {
                grit(lang('Error add operation'), 'growl-warning');
                console.log(response);
            },
            complete: function(m, response) {
                //console.log(response);
            }
        });

        // this.collection.add(operationModel);
    },

    createOperation: function(e) {
        e.preventDefault();
        var triggerId = this.triggerView.model.get('id');
        var triggerOperationItemModel = new TriggerOperationItemModel({
            triggerOperation: {
                id: null,
                TriggerId: triggerId,
                OperationId: null,
                SortOrder: null,
                SuccessOperationId: null,
                FailOperationId: null,
                AlwaysOperationId: null
            }
        });

        var triggerOperationsItemView = new TriggerOperationsItemView({
            triggerOperationsView: this,
            model: triggerOperationItemModel
        });

        var triggerOperationSettingsView = new TriggerOperationSettingsView({
            operationView: triggerOperationsItemView,
            model: triggerOperationItemModel.get('operation')
        });

        triggerOperationSettingsView.render();
    }

});