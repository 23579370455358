<template>
  <div :class="b()">
    <div
      v-if="editorMode"
      :class="b('mask')"
    ></div>

    <div :class="b('item')">
      <a
        :class="b('link')"
        :href="editorMode ? '#' : url"
        :target="editorMode ? false : '_blank'"
      >
        <div :class="b('media')">
          <IconIdCard width="32" />
        </div>

        <div :class="b('title')">
          vCard
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import baseMixin from 'general/mixins/baseMixin';
  import IconIdCard from 'general/components/icons/IconIdCard';

  export default {
    name: 'w-vcard',

    mixins: [
      baseMixin,
    ],

    components: {
      IconIdCard,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      companyName: {
        type: String,
      },

      companyEmail: {
        type: String,
      },

      companyPhone: {
        type: String,
      },

      widgetType: {
        type: Object,
      },
    },

    computed: {
      ...mapGetters({
        applicationId: 'getApplicationId',
        objectId: 'getObjectId',
        screenId: 'getScreenId',
        baseUrl: 'getBaseUrl',
      }),

      url() {
        if (!this.editorMode) {
          return `${this.baseUrl}/applications/vcard`
            + `?applicationId=${this.applicationId}`
            + `&objectId=${this.objectId}`
            + `&screenId=${this.screenId}`
            + `&widgetTypeId=${this.widgetType.id}`;
        }
        return '#';
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-vcard
    flex(center, center)
    flex-wrap wrap
    padding 10px 15px
    position relative

    &__mask
      absolute-parent(1)

    &__item
      max-width 60px
      padding 2px 2px 0
      position relative
      transition-duration $duration

    &__link
      flex(center, center)
      flex-direction column
      link()
      state-color($brand-primary)

    &__media
      button()
      flex(center, center)
      size(56px)
      background-color #f9f9fb
      position relative
      box-sizing border-box

    &__title
      margin-top 8px
      typography(12px)
      color #444
</style>
