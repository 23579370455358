'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Models
var TriggerModel = require('models/TriggerModel');

// Views
var TriggerSettingsView = require('views/TriggerSettingsView');

// Templates
var _triggers = require('text!bcTemplates/triggers.html');

// Helper
var PubSub = require('helper/pubsub');

module.exports = Backbone.View.extend({

    el: '#triggersList',

    events: {
        'click #createTrigger': 'createTrigger'
    },

    initialize: function (options) {

        this.options = options || {};
        this.tpl = _.template(_triggers);

        PubSub.on('showTriggerSettings', this.showTriggerSettings, this);

    },

    render: function () {
        var triggersView = this;
        triggersView.$el.empty();

        var $triggersView = triggersView.tpl();
        triggersView.$el.append($triggersView);
        // TODO: переделать рендер, как это сделано в TriggerOperationsView (что бы можно было в цикле удалить дочерние вью)
        triggersView.$el
            .find('.b-trigger__list-body')
            .append(triggersView.collection.renderAll());
    },

    createTrigger: function (e) {
        e.preventDefault();
        var newModel = new TriggerModel({
            SmartfilterId: parseInt($('#smartFilterIdHidden').val())
        });
        // this.collection.add(newModel);
        this.showTriggerSettings(newModel);
    },

    showTriggerSettings: function (model) {
        this.settingsView = new TriggerSettingsView({
            triggersView: this,
            model: model
        });

        this.settingsView.render();
    },

    close: function() {
        PubSub.off('showTriggerSettings');
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();

        if (this.settingsView)
            this.settingsView.cancel();
    }

    //    this.$el.removeData().unbind(); or this.unbind
    //    // handle other unbinding needs, here
    //    _.each(this.childViews, function(childView){
    //        if (childView.close){
    //            childView.close();
    //        }
    //    })
    //}

});