var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b({ isDisabled: _vm.disabled, unclearable: !_vm.clearable }) },
    [
      _vm.labelBefore
        ? _c("label", {
            class: _vm.b("label-before"),
            domProps: { innerHTML: _vm._s(_vm.labelBefore) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.b("container") }, [
        _c(
          "select",
          {
            staticClass: "form-control",
            attrs: {
              id: _vm.vId,
              name: _vm.name,
              multiple: _vm.multiple,
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.localValue },
            on: { change: _vm.handlerChange },
          },
          _vm._l(_vm.options, function (option, index) {
            return option
              ? _c("option", {
                  key: index,
                  attrs: { disabled: option.disabled },
                  domProps: {
                    value: option.value,
                    selected: option.selected || _vm.localValue == option.value,
                    innerHTML: _vm._s(option.label),
                  },
                })
              : _vm._e()
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.labelAfter
        ? _c("label", {
            class: _vm.b("label-after"),
            domProps: { innerHTML: _vm._s(_vm.labelAfter) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }