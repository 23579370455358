'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Models
var TriggerModel = require('models/TriggerModel');

// Views
var TriggerItemView = require('views/TriggerItemView');


module.exports = Backbone.Collection.extend({

    url: '/triggers/triggerapi',

    initialize: function() {
        //console.log(this);
    },

    renderAll: function(options) {

        var that = this;
        that.options = options || {};

        return this.map(function(TriggerModel) {

                return new TriggerItemView({
                model: TriggerModel
            }).render();

        })
    },

    gnoy: function(model) {
        var autoAllRecordsLimitDate = model.get('AutoAllRecordsLimitDate');

        if (!_.isEmpty(autoAllRecordsLimitDate)) {
            // Split timestamp into [ Y, M, D, h, m, s ]
            var t = autoAllRecordsLimitDate.split(/[- :]/);

            // Apply each element to the Date function
            // var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);

            var month = parseInt(d.getMonth())+1;

            model.set('AutoAllRecordsLimitDate', d.getFullYear() + '-' + month + '-' + d.getDate());
        }
    }

});