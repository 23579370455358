<template>
  <div
    :class="b()"
    v-if="isShown"
  >
    <button
      :class="b('button-close')"
      @click.prevent="close"
    >
      <IconClose width="10" />
    </button>

    <div :class="b('columns')">
      <div
        v-if="isApple"
        :class="b('fake-button')"
      ></div>

      <button
        v-if="!isApple && code"
        :class="b('button-copy')"
        @click.prevent="copy"
      >
        <IconCopy width="22" />
        <span>
          {{ 'Copy' }}

          <!--TODO: {{ $t('') }}-->
        </span>
      </button>

      <div :class="b('help-text')">Write the code</div>
      <div
        v-if="code"
        :class="b('code')"
      >
        {{ code }}
      </div>
      <div :class="b('help-text')">after connection</div>

      <button
        :class="b('button-refresh')"
        @click.prevent="refresh"
      >
        <IconRefresh width="22" />
        <span>
          {{ 'Refresh' }}

          <!--TODO: {{ $t('') }}-->
        </span>
      </button>
    </div>

    <div
      v-if="hint"
      :class="b('hint')"
    >
      {{ hintMessage }}
    </div>

    <ServiceMessage
      :class="b('service-message')"
      v-bind="serviceMessageData"
    />
  </div>
</template>

<script>
  import UAParser from 'ua-parser-js';
  import { mapState, mapActions } from 'vuex';
  import MakeEventBus from 'general/utils/MakeEventBus';
  import { copyToClipboard, replaceSnippets } from 'general/utils/utils';
  import serviceMessageMixin from 'general/mixins/serviceMessageMixin';
  import ServiceMessage from 'general/components/ServiceMessage';
  import IconClose from 'general/components/icons/IconClose';
  import IconCopy from 'general/components/icons/IconCopy';
  import IconRefresh from 'general/components/icons/IconRefresh';

  const eventBus = MakeEventBus('wBots');

  const ua = new UAParser(window.navigator.userAgent);

  export default {
    name: 'w-bots-code',

    mixins: [
      serviceMessageMixin,
    ],

    components: {
      ServiceMessage,
      IconClose,
      IconCopy,
      IconRefresh,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      isShown: true,
      isError: false,
    }),

    computed: {
      ...mapState({
        code: state => state.wBots.botCode,
      }),

      hintMessage() {
        return replaceSnippets(this.hint, {
          BOT_CODE: this.code,
        });
      },

      isApple() {
        const device = ua.getDevice();
        return (device && device.vendor) ? device.vendor.toLowerCase() === 'apple' : false;
      },
    },

    async created() {
      if (this.editorMode) {
        return false;
      }

      const code = await this.fetchCode();

      if (!code) {
        this.setServiceMessage('Connection error!', this.SERVICE_MESSAGE_LEVEL_ERROR);
      }
    },

    methods: {
      ...mapActions({
        fetchCode: 'wBots/fetchCodeVk',
      }),

      /**
       * Закрытие блока кода
       */
      close() {
        this.isShown = false;
        eventBus.$emit('code:closed');
      },

      /**
       * Повторная генерация кода
       */
      async refresh() {
        const oldCode = this.code;

        this.resetServiceMessage();
        const code = await this.fetchCode();

        if (!code || code === oldCode) {
          this.setServiceMessage('Refresh error!', this.SERVICE_MESSAGE_LEVEL_ERROR);
        }

        eventBus.$emit('code:refreshed');
      },

      /**
       * Копирование кода в буфер обмена
       */
      copy() {
        const copied = copyToClipboard(this.code);

        if (copied) {
          this.setServiceMessage('Copied');
        } else {
          this.setServiceMessage('Copy error!', this.SERVICE_MESSAGE_LEVEL_ERROR);
        }

        eventBus.$emit('code:copied');
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-bots-code
    flex(center)
    flex-direction column
    background-color $brand-info
    padding 15px
    margin-y(15px)
    position relative
    overflow hidden
    box-shadow 0 0 5px 0 rgba(0, 0, 0, .2)
    overflow initial !important

    &__button-close
      button()
      button_radial()
      button_theme('close')
      flex(center, center)
      size(24px)
      position absolute
      top -10px
      right -10px
      z-index 1

    &__columns
      display flex
      flex-direction column

    &__code
      margin-x(auto)
      margin-top 10px
      margin-bottom: 6px
      typography(50px)
      line-height: normal
      font-weight 700
      color #fff
      letter-spacing 12px

    &__help-text
      typography(28px)
      text-decoration: underline
      color #fff
      text-align: center

    &__fake-button
      size(50px)

    &__button-copy
    &__button-refresh
      reset-button()
      link()
      size(50px)
      flex(center, center)
      flex-direction column
      padding 0
      color #fff
      outline 0

      span
        margin-top 5px
        typography(11px)
        text-transform uppercase

    &__button-copy
      margin-right auto

    &__button-refresh
      margin-left auto

    &__hint
      margin-top 10px
      font-weight 700
      color $color-black
      text-align center

    &__service-message
      margin-top 10px
      color #fff
      text-align center
</style>
