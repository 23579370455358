var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classRoot },
    [
      _c("img", { attrs: { src: _vm.files.length ? _vm.files[0].url : "" } }),
      _vm._v(" "),
      _c("div", { class: _vm.b("container") }, [
        _c("div", { class: _vm.b("label") }, [
          _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.attachments.length,
                expression: "attachments.length",
              },
            ],
            class: _vm.b("body"),
          },
          [
            _vm._l(_vm.attachments, function (attachment, index) {
              return [
                _c("div", { key: index, class: _vm.b("item") }, [
                  _vm.multiple
                    ? _c("input", {
                        attrs: {
                          type: "hidden",
                          name: `${_vm.name}[${index}]`,
                        },
                        domProps: { value: attachment.binary },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPreview && attachment.isImage
                    ? _c(
                        "div",
                        {
                          class: _vm.b("preview"),
                          style: _vm.handlerStylePreview(attachment),
                        },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: attachment.binary,
                                  expression: "attachment.binary",
                                },
                              ],
                              class: _vm.b("button-remove-item"),
                              attrs: { title: _vm.titleButtonRemoveItem },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeAttachment(index)
                                },
                              },
                            },
                            [_c("IconFileRemove")],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showInfo
                    ? _c("div", { class: _vm.b("info") }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(attachment.file.name) +
                            "\n            "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            " +
                            _vm._s(attachment.sizeFormat) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.b("controls") },
          [
            _c(
              "file-upload",
              {
                ref: "upload",
                class: _vm.b("button-add", "btn btn-xs btn-info"),
                attrs: {
                  id: _vm.vId,
                  title: !_vm.attachments.length
                    ? _vm.titleButtonAdd
                    : _vm.titleButtonAddAdded,
                  accept: "image/*",
                  name: !_vm.multiple ? _vm.name : null,
                  multiple: _vm.multiple,
                  required: _vm.required && !_vm.attachments.length,
                  disabled: _vm.disabled,
                  headers: _vm.headers,
                  "post-action": _vm.endpoint,
                },
                on: { "input-file": _vm.inputFile },
                model: {
                  value: _vm.files,
                  callback: function ($$v) {
                    _vm.files = $$v
                  },
                  expression: "files",
                },
              },
              [
                !_vm.attachments.length && _vm.textButtonAdd
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.textButtonAdd) +
                          "\n        "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.textButtonAddAdded) +
                          "\n        "
                      ),
                    ]),
                _vm._v(" "),
                _vm.multiple && _vm.attachments.length
                  ? _c("IconPlus", { attrs: { width: "12" } })
                  : _vm.attachments.length
                  ? _c("IconRefresh", { attrs: { width: "12" } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            (!_vm.showPreview || _vm.multiple) && _vm.attachments.length
              ? _c(
                  "button",
                  {
                    class: _vm.b("button-remove-all", "btn btn-xs btn-danger"),
                    attrs: {
                      title: !_vm.showPreview
                        ? _vm.titleButtonRemoveItem
                        : _vm.titleButtonRemoveAll,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.removeAttachmentAll.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.textButtonRemoveAll
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.textButtonRemoveAll) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("IconTrash"),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "ServiceMessage",
        _vm._b(
          { class: _vm.b("service-message") },
          "ServiceMessage",
          _vm.serviceMessageData,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }