import Cookie from 'js-cookie';

var Custom = require('refactor/custom');
var ModalHero = require('refactor/modal-hero');
var Print = require('refactor/print');
var Charts = require('refactor/charts');
var SmartFilters = require('refactor/smart-filters');

window.getFontSize = function (countChar) {
  var fontSize = 0,
    precentage = 1;

  if (countChar > 0 && countChar <= 3) {
    fontSize = 24;
  } else if (countChar > 3 && countChar <= 6) {
    fontSize = 16;
  } else if (countChar > 6 && countChar <= 9) {
    fontSize = 16;
  } else if (countChar >= 10) {
    precentage = countChar / 10;
    fontSize = 14 / precentage;
  }

  return fontSize;
};

window.updateFontSize = function () {

  $('.b-smart-filters__item')
    .each(function () {
      var self = $(this),
        mainBox = self.find('.b-smart-filters__cell--main'),
        countCharacter = mainBox.find('>span')
          .text().length;

      mainBox.css('font-size', getFontSize(countCharacter));
    });

};

window.heightSmartFilters = function () {
  var heights = $('.b-smart-filters__cell--main')
      .map(function () {
        return $(this)
          .outerHeight();
      })
      .get(),

    maxHeight = Math.max.apply(null, heights);

  $('.b-smart-filters__cell--main')
    .css('height', maxHeight);
};

updateFontSize();
heightSmartFilters();

$(window)
  .on('load', function () {
    $(window)
      .on('resize', function (e) {
        $('.b-smart-filters__cell--main')
          .css('height', 'auto');
        heightSmartFilters();
      });

    if ($('.b-user-app').length > 0) {
      var typeListApp = 'grid',
        gridApp = $('.b-app-items'),
        switchType = $('.b-app-items__switcher');

      if (localStorage) {
        typeListApp = localStorage.getItem('typeListApp');
      }

      if (typeListApp === 'grid' && !gridApp.hasClass('b-app-items--grid')) {

        gridApp.removeClass('b-app-items--list')
          .addClass('b-app-items--grid');
        switchType.find('input[value="' + typeListApp + '"]')
          .attr('checked', true);

      } else if (typeListApp === 'list' && !gridApp.hasClass('b-app-items--list')) {

        gridApp.removeClass('b-app-items--grid')
          .addClass('b-app-items--list');
        switchType.find('input[value="' + typeListApp + '"]')
          .attr('checked', true);

      }

    }
  });

$(document)
  .ready(function () {
    const isAuth = Cookie.get('__mbst_authdata');

    // extend jquery
    $.fn.extend({
      insertAtCaret: function (myValue) {
        const input = this[0];

        if (document.selection) {
          this.focus();
          const sel = document.selection.createRange();

          sel.text = myValue;
          this.focus();
        } else if (typeof input.selectionStart !== 'undefined' && (input.selectionStart || input.selectionStart == '0')) {
          const startPos = input.selectionStart;
          const endPos = input.selectionEnd;
          const scrollTop = input.scrollTop;

          input.value = input.value.substring(0, startPos) + myValue + input.value.substring(endPos, input.value.length);
          this.focus();
          input.selectionStart = startPos + myValue.length;
          input.selectionEnd = startPos + myValue.length;
          input.scrollTop = scrollTop;
        } else {
          input.value += myValue;
          this.focus();
        }
      },
    });

    if ($.fn.yiiGridView) {
      $(document)
        .off('click.yiiGridView', '**');
    }

    if (typeof yiiGridViewOptions !== 'undefined') {
      $('#my-model-grid')
        .yiiGridView(yiiGridViewOptions);
    }

    Custom.myProfileEvents();
    Custom.accountSettingsEvents();
    Custom.registrationAndSecurity();
    Custom.importObjects();             // Импорт объектов
    Custom.util(Charts);                // Чарты в бэкенде
    SmartFilters.init();                // Смарти фильтры в объектах и бэкенде
    ModalHero.init();
    Print.init();


    if ($('#backend-page').length > 0) {
      Charts.init();
    }

    $('body')
      .delegate('#categorylink', 'click', function (e) {
        /** Женя навесил в custom.js евент на класс в который Blank категория попадает
         * поэтому тут это условие чтобы два раза не срабатывало */
        if ($(this)
          .parent()
          .hasClass('empty_parent') === false) {
          clickOnParent(this);
        }
      });

    $('#step1search')
      .keyup(function () {
        $.ajax(
          {
            url: '/papplications/searchapps/' + this.value,
            type: 'POST',
            beforeSend: function (xhr) {
            },
          })
          .done(function (a, b, c) {
            $('#step1apps')
              .html(a);
            $('.b-wizard')
              .removeClass('b-wizard--createapp')
              .addClass('b-wizard--newapp');
            $('.b-wizard__btn--next')
              .addClass('disabled')
              .removeClass('b-wizard__btn--blink');
            step1_element_events();
          })
          .fail(function (a, b, c) {

          })
          .always(function (a, b, c) {
          });
      });

    window.IsJsonString = function (str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };

    window.setpushon = function (subscription) {
      if (!isAuth) {
        return false;
      }

      $.ajax({
        url: '/users/setpushon',
        type: 'POST',
        data: { point: subscription.endpoint, useragent: navigator.userAgent },
      })
        .done(function (a, b, c) {
          var obj = {};
          if (IsJsonString(a)) {
            obj = JSON && JSON.parse(a) || $.parseJSON(a);
            if (obj.error === '' && obj.objectid !== '') {

            }
          }
        });
    };

    window.subscribe = function (checkbox, auto) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function (reg) {
          if (!reg || reg === null || !reg.pushManager || reg.pushManager === null) {
            return false;
          }
          reg.pushManager.getSubscription()
            .then(function (subscription) {
              if (subscription) {
                setpushon(subscription, auto);
              } else {
                //navigator.serviceWorker.register('/sw.js').then(function(reg) {
                reg.pushManager.subscribe({ userVisibleOnly: true })
                  .then(function (subscription) {
                    setpushon(subscription, auto);
                  })
                  .catch(function (e) {
                    if (Notification.permission === 'denied') {
                      $(checkbox)
                        .prop('checked', false);
                      //console.log('Permission for Notifications was denied');
                      if (!auto) {
                        console.log('Permission for Notifications was denied');
                        //alert(lang('Permission for Notifications was denied'));
                      }
                    } else {
                      $(checkbox)
                        .prop('checked', false);
                      console.log('Unable to subscribe to push.', e);
                      //alert(e);
                    }
                  });
                //});
              }
            });
        });
      } else {
        $(checkbox)
          .prop('checked', false);
        console.log('No service worker');
        //alert('No service worker');
      }
    };

    window.unsubscribe = function () {
      if ('serviceWorker' in navigator) {

        navigator.serviceWorker.ready.then(function (reg) {
          if (!reg || reg === null || !reg.pushManager || reg.pushManager === null) {
            return false;
          }
          reg.pushManager.getSubscription()
            .then(
              function (subscription) {

                $.ajax({
                  url: '/users/setpushoff',
                  type: 'POST',
                  data: { point: subscription.endpoint },
                })
                  .done(function (a, b, c) {
                    var obj = {};
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    if (obj.error === '' && obj.lastendpoint === '1') {
                      setTimeout(function () {
                        subscription.unsubscribe()
                          .then(function (successful) {
                            console.log('Unsubscription success');
                          })
                          .catch(function (e) {
                            console.log('Unsubscription error: ', e);
                          });
                      }, 3000);
                    }
                  });

              })
            .catch(function (e) {
              //console.log('Error thrown while unsubscribing from ' + 'push messaging.', e);
            });
        });

      }
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js')
        .then(function (reg) {
        });
      navigator.serviceWorker.ready.then(function (reg) {

        subscribe($('#push-notes'), 1);

        if (!reg || reg === null || !reg.pushManager || reg.pushManager === null || !isAuth) {
          return false;
        }
        reg.pushManager.getSubscription()
          .then(function (subscription) {
            if (subscription) {
              $.ajax({
                url: '/users/getpushstate',
                type: 'POST',
                data: { point: subscription.endpoint },
              })
                .done(function (a, b, c) {

                  var obj = {};
                  if (IsJsonString(a)) {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    if (obj.error === '') {
                      if (obj.subscription == '1') {
                        $('#push-notes')
                          .prop('checked', true);
                      } else {
                        $('#push-notes')
                          .prop('checked', false);
                      }
                    }
                  }
                });
            }
          });
      });
    }

    $('#push-notes')
      .on('click', function () {
        var ch = $(this)
          .is(':checked');
        if (ch) {
          subscribe(this, 0);
        } else {
          unsubscribe();
        }
      });


  });
