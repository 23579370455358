<template>
  <div :class="b({ isDefault: !src })">
    <img
      :class="b('image')"
      :src="internalSrc || srcDefault"
      :alt="alt"
    />
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'w-image',

    mixins: [
      baseMixin,
    ],

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      src: {
        type: String,
      },

      alt: {
        type: String,
      },

      widgetType: {
        type: Object,
      },
    },

    data: () => ({
      srcDefault: '/logo.svg',
    }),

    computed: {
      baseUrl() {
        const key = (this.editorMode) ? 'widgetSettings/getBaseUrl' : 'getBaseUrl';
        return this.$store.getters[key] || '';
      },

      internalSrc() {
        const { src, baseUrl } = this;
        return (src) ? `${baseUrl}${src}` : null;
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-image
    padding 10px 15px

    &__image
      display block
      width 100%

      ~/_is-default &
        width 70%
        margin-x(auto)
</style>
