var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 93.87 128",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("path", { attrs: { d: "M64 29.87V0H21.33v102.4h72.54V29.87H64z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M72.53 0v21.33h21.34L72.53 0zM12.8 110.93V25.6H0V128h72.53v-17.07H12.8z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }