'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Views
var ExecutionItemView = require('views/execution/ExecutionItemView');
var ExecutionSettingsView = require('views/execution/ExecutionSettingsView');

// Templates
var _executionItem = require('text!bcTemplates/execution/executionItem.html');

module.exports = Backbone.View.extend({

    tagName: 'li',

    events: {
        'click [data-js-toggle="editExecutionField"]': 'edit',
        'click [data-js-toggle="deleteExecutionField"]': 'delete'
    },

    bindings: {
        '[name="executionValue"]': 'value'
    },

    initialize: function(options) {
        var t = this;
        t.tpl = _.template(_executionItem);
        t.parentView = options.parentView;
    },

    render: function() {
        var t = this;
        t.$el.empty();

        t.$el.html(t.tpl(t.model.toJSON()));
        t.stickit();
    },

    edit: function(e) {
        e.preventDefault();
        if (this.executionSettingsView) {
            return false;
        }

        _.each(this.parentView.itemViews, function(itemView) {
            if (itemView.executionSettingsView) {
                itemView.executionSettingsView.close();
                itemView.executionSettingsView = undefined;
            }
        });

        var Model = Backbone.Model.extend();
        var model = new Model( _.omit(this.model.toJSON(), 'value') );
        this.executionSettingsView = new ExecutionSettingsView({
            model: model,
            parentView: this
        });
        this.executionSettingsView.render();
    },

    delete: function(e) {
        e.preventDefault();
        this.parentView.collection.remove(this.model);
        this.close();
    },

    close: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }

});