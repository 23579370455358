import { makeControlProps, backgroundProps, typographyProps } from './helper'

const view = [
  {
    selector: '.q-drawer-container .q-drawer--right',
    controls: [
      typographyProps,
      backgroundProps,
      makeControlProps('spacing', { label: 'Spacing', directives: ['padding'] }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
    ],
  },
]

export default {
  view,
}
