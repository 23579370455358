//TODO: сделать описание, обработчки ошибок и вообще довести до ума, сейчас быстро дулаю для презы
import _ from 'lodash';
import MakeHttp from '@/general/utils/MakeHttp';
import Vue from 'vue';
import { extend, uid } from 'quasar'
import { convertCSStoNewStyle } from '@/bc/pages/constructor/utils/css.mbst.js';

const http = MakeHttp();

/**
 * Assign new ParentUUID's deep
 */
const buildScreen = (components, parent = {}) => {
  components.forEach(component => {
    Vue.set(component, 'parentUUID', parent.uuid)

    // *** Convert old components CSS to new style ***
    // *** for compatibility ***
    if (component.css.all === undefined) {
      Vue.set(component.css, 'all', convertCSStoNewStyle(component.css.android)) // clone deep
      Vue.delete(component.css, 'android')
      Vue.delete(component.css, 'ios')
    }
    // **
    component.items && buildScreen(component.items, component)
    component.components && buildScreen(component.components, component)
  })
}

/**
 * !!! DEPRECATED !!! moved to mutations
 * Assign new UUID's deep
 */
const addUUID = (component) => {
  const uuid = uid()
  Vue.set(component,'uuid', uuid)
  component.items && component.items.forEach(el => addUUID(el))
  component.components && component.components.forEach(el => addUUID(el))
}

const clearParentUUIDKey = components => {
  _.each(components, component => {
    delete component.parentUUID
    if (component.items) {
      _.each(component.items, item => clearParentUUIDKey(item.components))
    }
  })
};

// ** Create flat list, contains UUID of all screen components
// ** this index uses for fast getting component by his UUID
const _createScreenIndex = (state, component = null) => {

  if (!component) {
    state.__index = {}
    component = state.data
  }

  const path = (state.__index[component.uuid] || {}).path || ''

  const indexSubComponents = (component, path) => {
    state.__index[component.uuid] = { path, backendname: component.properties.backendname }
    component.components && component.components.forEach((el, i) => {
      Vue.set(el, 'indexComponent', i)
      indexSubComponents(el, path + (path ? '.':'') + 'components.' + i)
    })
    component.items && component.items.forEach((el, i) => indexSubComponents(el, path + (path ? '.':'') + 'items.' + i) )
  }

  indexSubComponents(component, path)

  state.__indexCreatedAt = Date.now() // ** to trigger index rebuilding on MU in devmode
}

const _deleteComponent = (components, uuid) => {
  const index = components.findIndex(c => c.uuid === uuid);
  if (index !== -1) components.splice(index, 1);
};
const _insertComponent = (components, data) => {
  if (!components) return false

  if (data.parentUUID && data.parentUUID === data.component.parentUUID) {

    // ** component moves down, while source component just was deleted, and list indexes decreases by 1 **
    // if (data.component.index < data.indexComponent){
    //   data.indexComponent--
    // }
  } else {
    data.component.parentUUID = data.parentUUID
    data.indexComponent = undefined
    // data.indexComponent++
  }

  if (data.indexComponent !== undefined) {
    if (!data.isRawIndex) {
      let j = data.indexComponent;
      let location = data.component.location;
      for (let i=0;i<data.indexComponent;i++){
        if (components[i].location !== location) {
          data.indexComponent++
        } else if (j-- === 0) {
          break
        }
      }
    }
  } else {
    data.indexComponent = components.length
  }

  components.splice(data.indexComponent, 0, data.component);
};

const fillHistory = (history, data) => {
  const tmpHistory = _.cloneDeep(history);
  tmpHistory.prev.push(_.cloneDeep(data));
  tmpHistory.next = [];
  return tmpHistory;
};

export const getComponentByUUID = (components, uuid, callback) => {

  const findComponent = (components, uuid) => {
    for (let i in components) {
      if (components[i].uuid === uuid) {
        return components[i]
      }
      if (components[i].items || components[i].components) {
        if (component = findComponent(components[i].items || components[i].components, uuid)){
          return component
        }
      }
    }
    return false
  }
  let component = findComponent(components, uuid)

  if (component && callback) {
    callback(component);
  }

  return component

  // _.each(components, component => {
  //   if (component.uuid == uuid) {
  //     callback(component);
  //   }
  //   if (component.items) {
  //     _.each(component.items, item => {
  //       if (item.uuid == uuid) {
  //         callback(item);
  //       } else {
  //         getComponentByUUID(item.components, uuid, callback);
  //       }
  //     });
  //   }
  // });
};

const deleteOrLeaveImageFiles = async (component, stateScreens) => {
  console.log('deleteOrLeaveImageFiles');
  if (!component.properties.image.id) // id file is not set, so there'se no our file, only url
  {
    return true;
  } else {
    let allScreens = _.cloneDeep(stateScreens.data);
    let hasSameImageId = false;
    let foundOnScreen = false;

    let rawScreens = _.cloneDeep(stateScreens.data); // get all screens with components
    let fc = [];
    _.reduce(rawScreens, (res, screen) => {
      let c = _.cloneDeep(screen); //чтобы не путалось, склонируем
      const getImageComponents = (c) => {
        _.each(c, (value) => {

          if ((value.name === 'mbst-image') && ((value.properties.image.id === component.properties.image.id)
            && (value.uuid !== component.uuid))) {
            fc.push(value.uuid);
          }
          if (value.items && !_.isEmpty(value.items)) {  // если есть вложенные элементы, то вызовем рекурсивно
            _.each(value.items, item => {
              res = getImageComponents(item.components);
            });
          }
        });
        return fc;
      };
      let cc = getImageComponents(c.attributes.components);
      _.concat(res, cc);

      return res;
    }, []);


    foundOnScreen = fc.length > 0;

    if (!foundOnScreen) // if no match found - delete image
    {
      try {
        let res = await http.delete(`/files/deletebyname?filePath=${component.properties.image.attributes.Name}`, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (res.errors && res.errors.length > 0) {
          _.each(res.errors, error => {
            grit(error.title, 'growl-danger', error.detail);
          });
          return false;
        } else {
          return true;
        }
      } catch (e) {
        grit('Failed deleting', 'growl-danger', e);
        console.error('Err update: ', e);
        return false;
      }
    } else {
      return true;
    }

  }
};

// // ** Create flat list, contains UUID of all components of screen
// // ** this index uses for fast getting component by his UUID
// const _createScreenIndex = payload => {
//   let index = {};
//   let path = '';
//
//   const indexSubComponents = (component, path) => {
//
//     //index[component.uuid] = { path, alias: component.aliasName, name: component.properties.backendname };
//     index[component.uuid] = path;
//
//     if (component.components) {
//       component.components.forEach((el, i) => indexSubComponents(component.components[i], (path? path+'.':'') + 'components.' + i) )
//     }
//     if (component.items) {
//       component.items.forEach((el, i) => indexSubComponents(component.items[i], (path? path+'.':'') + 'items.' + i) )
//     }
//   };
//   indexSubComponents(payload, path);
//   return index;
// };

const state = {
  id: null,
  data: {
    name: 'mbst-screen',
    aliasName: 'Screen',
    properties: {},
    components: [],
  },
  selectedComponent: null,
  history: {
    prev: [],
    next: []
  },
  __index: [],
  __indexCreatedAt: null,

  hashtags: null,
  usedHashtags: null,
  dependencies: null,
  // renderHashtags: true,
  // displayOncomponentLabels: true,
  // enableLiveMode: false
};

const getters = {
  getComponentByUUID: (state) => (uuid) => {
    if (state.__index) {
      // const container = state.data
      if (state.data.uuid == uuid) return state.data
      const indexItem = state.__index[uuid]
      return indexItem !== undefined ? _.get(state.data, indexItem.path) : false
    }
  },

  getComponentByName: (state) => (name) => {
    if (state.__index) {
      if (state.data.properties.backendname == name) return state.data
      const element = Object.values(state.__index).find(el => el.backendname === name)
      if (element) {
        return _.get(state.data, element.path)
      }
    }
    return null
  },

  getComponentByUuidLive: (state, getters) => (uuid, parent) => {
    //let result = state.data.components.find(component => component.uuid === uuid);
    parent = parent || state.data;
    if (parent.uuid === uuid) return parent;
    if (parent.components) {
      for (let i in parent.components){
        if (parent.components[i].uuid === uuid){
          return parent.components[i]
        }
        if (parent.components[i].items){
          for (let j in parent.components[i].items){
            if (parent.components[i].items[j].uuid === uuid){
              return parent.components[i].items[j];
            }
            let result = getters.getComponentByUuid(uuid, parent.components[i].items[j]);
            if (result !== undefined){
              return result;
            }
          }
        }
      }
    }
    return undefined;
  },
  getItemIndexByUuid: (state, getters) => (uuid, parent) => {
    parent = getters.getComponentByUuid(parent);
    if (parent && parent.items){
      return parent.items.findIndex(component => component.uuid === uuid)
    }
    return -1;
  },

  componentsTree: (state, getters, rootState) => {
    if (!state.__index) return [];

    let tree = {};

    const indexSubComponents = (component, index = 0, parentUUID = '') => {

      let element = { index, uuid: component.uuid, label: component.properties.backendname || component.aliasName, type: component.name, parentUUID, location: component.location, children: [] };

      if (rootState.settingsPanel.data && rootState.settingsPanel.data.uuid && rootState.settingsPanel.data.uuid == component.uuid) {
        element.active = true
      }

      if (component.components) {
        const locations = {};
        component.components.forEach((el, i) => {
          if (el.location) {
            if (!locations[el.location]) {
              locations[el.location] = []
              element['children'].push({ index: i, uuid: el.location, label: el.location, type: '__subgroup', parentUUID: component.uuid,  location_del: el.location, children: locations[el.location] })
            }
            locations[el.location].push(indexSubComponents(component.components[i], i, el.location))
          } else {
            element['children'].push(indexSubComponents(component.components[i], i, component.uuid))
          }

          // element['children'].push(indexSubComponents(component.components[i], i, component.uuid))
        })
      }
      if (component.items) {
        component.items.forEach((el, i) => element['children'].push(indexSubComponents(component.items[i], i, component.uuid)) )
      }

      return element;
    };

    tree = indexSubComponents(state.data);

    return [tree];
  },

  hashtagsTree: (state) => {
    if (!state.hashtags) return []

    let tree = []

    const rec = items => {
      let res = []
      for (const key of Object.keys(items)) {
        let item = {}
        const value = items[key]
        const strValue = JSON.stringify(value)

        item.label = `${key}:`
        item.value = strValue
        item.uid = uid()

        if (_.isObject(value) || Array.isArray(value)) {
          item.children = rec(value)
          item.value = strValue.length > 20 ? strValue.slice(0, 19) + '...' : strValue
        }
        
        res.push(item)
      }
      return res
    }

    tree = rec(state.hashtags)

    return tree
  },

  hashtagsUsedOnScreen: (state) => {
    if (!state.usedHashtags) return []
    
    let editable = []
    let notEditable = []
    for (const hashtag of state.usedHashtags) {
      const path = _.trim(hashtag, '#').replace(/\:/g, '.')
      const hashtagGroup = path.split('.')[0]
      const value = _.get(state.hashtags, path)
      const type = typeof value
      let text = JSON.stringify(value)

      if (_.isObject(value) || Array.isArray(value))
        text = text.length > 20 && text.slice(0, 19) + '...' || text

      if (hashtagGroup === 'Loop') notEditable.push({ label: hashtag, text, value, path, type, hashtagGroup, isEditable: false })
      else editable.push({ label: hashtag, text, value, path, type, hashtagGroup, isEditable: true })
    }

    return [...editable, ...notEditable]
  }
};

const actions = {
  async getScreenById({ commit, rootGetters, state }, id) {
    try {
      // const { data: screen } = await http.get(`screen/${id}`);
      const screen = rootGetters['screens/getScreenById'](id);
      if (!screen) {
        state.id = id
        throw new Error('Failed getScreen');
      }

      // ** reformatting old screens **
      // ** inject drawers components into screen (if not exists) **
      const defDw = {
        name: 'mbst-drawer',
        aliasName: 'Drawer',
        location: '',
        actions: [],
        css: { all: [] },
        parentUUID: null,
        uuid: '-1',
        components: [],
        properties: {}
      }

      let drawer = screen.attributes.components.find(
        (el) => el.location === 'left' && el.name.startsWith('mbst-drawer'),
      )
      if (!drawer) {
        const leftDrawerComponents = screen.attributes.components.filter((el) => el.location === 'left')
        drawer = Object.assign({}, defDw, {
          uuid: uid(),
          location: 'left',
          parentUUID: screen.attributes.uuid,
          components: [],
          name: 'mbst-drawer-left',
        })
        screen.attributes.components.unshift(drawer)

        // ** remove left-drawer-components from screen root **
        let clearList = []
        screen.attributes.components.forEach((el, i) => {
          if (el.location !== 'left' || el.name.startsWith('mbst-drawer')) {
            el.indexComponent = clearList.length
            clearList.push(el)
          }
        })
        screen.attributes.components = clearList
        // **
      } else {
        drawer.name = 'mbst-drawer-left'
      }

      drawer = screen.attributes.components.find(
        (el) => el.location === 'right' && el.name.startsWith('mbst-drawer'),
      )
      if (!drawer) {
        const leftDrawerComponents = screen.attributes.components.filter((el) => el.location === 'right')
        drawer = Object.assign({}, defDw, {
          uuid: uid(),
          location: 'right',
          parentUUID: screen.attributes.uuid,
          components: [],
          name: 'mbst-drawer-right',
        })
        screen.attributes.components.unshift(drawer)

        // ** remove right-drawer-components from screen root **
        const clearList = []
        screen.attributes.components.forEach((el, i) => {
          if (el.location !== 'right' || el.name.startsWith('mbst-drawer')) {
            el.indexComponent = clearList.length
            clearList.push(el)
          }
        })
        screen.attributes.components = clearList
        // **
      } else {
        drawer.name = 'mbst-drawer-right'
      }
      // **

      commit('setScreen', screen);
      commit('settingsPanel/setData', state.data, { root: true });
      commit('setSelected', null);

      commit('resetHistory');
      Promise.resolve();
    } catch (e) {
      console.log(e);
    }
  },
  async saveScreen({ state }, screenData) {
    try {
      let data = screenData !== undefined ? screenData : _.cloneDeep({id: state.id, ...state.data});
      clearParentUUIDKey(data.components);
      data.Name = data.properties.name;

      let res = [];
      const getImageUrls = (c) => {
        let fc = [];
        _.each(c, (value) => {
          if (value.name === 'mbst-image') {
            if (value.properties.image.selectedId) {  // if URl is defined, its external url
              if (value.properties.image.id === value.properties.image.selectedId) {  // main image is selected
                fc.push(value.properties.image.attributes.Url);
              } else {
                _.each(value.properties.image.thumbs, (thumb) =>  // check thumbs
                {
                  if (thumb.id === value.properties.image.selectedId) {
                    fc.push(thumb.Url);
                    return false; // break cycle, we dont need it anymore
                  }
                });
              }
            } else if ((!value.properties.image.selectedId) && (value.properties.image.url !== '')) {
              fc.push(value.properties.image.url);
            }
          }
          if (value.items && !_.isEmpty(value.items)) {  // если есть вложенные элементы, то вызовем рекурсивно
            _.each(value.items, item => {
              res.push(getImageUrls(item.components));
            });
          }
        });

        res = _.concat(res, fc);
        return _.uniq(res);
      };

      const getBackgroundUrlsFromCss = (css) => {
        let fc = [];
        _.each(css.android, (value) => {
          _.each(value.rules, (item) => {
            if (item.directive === 'background-image') {
              // value is like "url("https://avatars0.githubusercontent.com/u/123074?s=180&v=4")", so we need only Urls from it
              fc.push(item.value.substr(5)
                .slice(0, -2));
            }
          });
        });
        _.each(css.ios, (value) => {
          _.each(value.rules, (item) => {
            if (item.directive === 'background-image') {
              fc.push(item.value.substr(5)
                .slice(0, -2));
            }
          });
        });
        return _.uniq(fc);
      };

      const getBackgroundsFromComponents = (components) => {
        let resbg = [];
        let bgs = [];
        let recursive = [];
        _.each(components, (cmp) => {
          if (cmp.css) {
            let x = getBackgroundUrlsFromCss(cmp.css);
            if (x.length > 0) {
              bgs.push(...x);
            }
          }
          if (cmp.items && !_.isEmpty(cmp.items)) {  // если есть вложенные элементы, то вызовем рекурсивно
            _.each(cmp.items, (item) => {
              let itemCss = getBackgroundUrlsFromCss(item.css);
              if (itemCss.length > 0) {
                resbg.push(...itemCss);
              }
              if (item.components && !_.isEmpty(item.components)) {
                let recursive = getBackgroundsFromComponents(item.components);
                if (recursive.length > 0) {
                  resbg.push(...recursive);
                }
              }
            });
          }
        });
        resbg = _.concat(resbg, bgs, recursive);
        return _.uniq(resbg);
      };

      let backgrounds = [],
        images = [];
      let bg = getBackgroundUrlsFromCss(data.css);
      let cmpbgs = getBackgroundsFromComponents(data.components);
      if (cmpbgs.length > 0) {
        backgrounds.push(...cmpbgs);
      }
      if (bg.length > 0) {
        backgrounds.push(...bg);
      }
      let img = getImageUrls(data.components);
      if (img.length > 0) {
        images.push(...img);
      }

      data.properties.prefetchImages = _.uniq(_.concat(backgrounds, images));  // This will be used for prefetch images on page load in MU
      await http.put(`screen/${data.id}`, data);
      // grit('Saved', 'growl-success');
      this.$q.notify({ message: 'Saved', type: 'positive' })
    } catch (e) {
      console.log(e.response);
      // grit('Failed save', 'growl-danger');
      this.$q.notify({ message: 'Failed save', type: 'negative' })
    }
  },

  /**
   * Move or Paste componet on fcreen
   * action (move or paste new) depends on type of `source` parameter (see below)
   */
  async moveComponent({ state, commit, dispatch, getters, rootGetters }, { source, targetUUID, position }) {
    // debugger

    let sourceComponent = null
    let sourceParent = null

    let isPasteNewComponent = false

    // ** pass (string) UUID of component for moving it across the screen
    if (typeof source === 'string') { // ** get UUID of component on screen
      sourceComponent = extend(true, {}, getters['getComponentByUUID'](source)) // clone deep
      sourceParent = getters['getComponentByUUID'](sourceComponent.parentUUID)

    // ** pass (object) component for pasting (create new copy) to screen
    } else if (typeof source === 'object') { // ** object from Palette or parsed json from clipboard to paste on screen
      sourceComponent = source // clone deep
      const options = (sourceComponent.uuid && sourceComponent.screenId) ? { keepSync: true, screenId: sourceComponent.screenId } : {}
      commit('addUUID', { component: sourceComponent, options })
      // sourceComponent.indexComponent = 9999 // ** for `shiftIndex` below will be set to 0
      isPasteNewComponent = true
    }
    if (!sourceComponent) return

    const targetComponent = getters['getComponentByUUID'](targetUUID)
    let targetParent = targetComponent.components ? targetComponent : getters['getComponentByUUID'](targetComponent.parentUUID)

    const excludeComponents = ['mbst-flexrow__col', 'mbst-slider__slide', 'mbst-tabs__tab', 'mbst-list__item']
    if (excludeComponents.includes(sourceComponent.name) && excludeComponents.includes(targetParent.name)) {
      targetParent = getters['getComponentByUUID'](targetComponent.parentUUID)
    }

    if (!targetParent) return // ** some error - parentUUID must be always (except mbst-screen)

    // ** remove source component from old place **
    if (sourceParent) {
      const sourceList = sourceParent.components || sourceParent.items
      const index = sourceList.findIndex(
        ({ uuid }) => uuid === sourceComponent.uuid
      )
      sourceList.splice(index, 1)
    }
    // **

    // ** insert source component to new place **
    sourceComponent.parentUUID = targetParent.uuid
    // const shiftIndex = +(sourceComponent.parentUUID === targetComponent.parentUUID && sourceComponent.indexComponent < targetComponent.indexComponent)
    // let targetInsertIndex = +(position === 'before' ? targetComponent.indexComponent : targetComponent.indexComponent + 1) - shiftIndex
    // if (isNaN(targetInsertIndex)) {
      // (targetParent.components || targetParent.items).push(sourceComponent)
    // } else {
      const targetList = targetParent.components || targetParent.items
      const indx = targetList.findIndex(
        ({ uuid }) => uuid === targetComponent.uuid
      )
      targetList.splice(position === 'before' ? indx : indx + 1, 0, sourceComponent)
    // }
    // **

    // ** assign parentUUID for newly inserted structure
    if (isPasteNewComponent) {
      buildScreen(targetParent.components || targetParent.items, targetParent)
    }
    // **

    // ** finalize manipulations **
    state.history = fillHistory(state.history, state.data)
    _createScreenIndex(state)
    // **
  },

  async insertComponent({ state, commit, rootGetters }, data) {
    commit('insertComponent', data)

    // ** check sync-css option **
    // ** when create copy of synced component => need add this copy to synced childs list **
    const component = data.component
    const syncCSS = ((component.config || {}).sync || {}).css
    if ( syncCSS ) {
      let scr = rootGetters['screens/getScreenById'](syncCSS.screenId);
      if (scr) {
        const parent = getComponentByUUID(scr.attributes.components, syncCSS.componentUUID)
        if (parent) {
          let configParent = parent.config || {}
          const index = ((configParent.syncChilds || {}).css || []).findIndex(el => el.uuid === component.uuid)
          if (index === -1) {
            configParent.syncChilds.css.push({ uuid: component.uuid, screenId: state.id })
            Vue.set(parent, 'config', configParent)
          }
        }
      }
    }
    // **
  },
  async deleteComponentByUUID({ commit, rootState, state, getters }, uuid) {
    const component = getters['getComponentByUUID'](uuid)
    if (!component) return false

    let deletionResult = null;
    if (component.name === 'mbst-image') {
      deletionResult = await deleteOrLeaveImageFiles(component, rootState.screens);
    }
    commit('deleteComponentByUUID', { component });

    if (!_.isNull(deletionResult) && deletionResult === false) {
      grit('Error occured while deleting file' + deletionResult, 'growl-danger');
    }
  },

  async deleteFileFromImageByUuid({ commit, rootState, state }, uuid) {
    let component = null;
    getComponentByUUID(state.data.components, uuid, c => component = c);
    if (!component) {
      return false;
    }
    let deletionResult = null;
    if (component.name === 'mbst-image') {
      deletionResult = await deleteOrLeaveImageFiles(component, rootState.screens);
    }
    if (!_.isNull(deletionResult) && deletionResult === false) {
      grit('Error occured while deleting file', 'growl-danger');
    }
  },
  redoScreen({ state, commit, rootGetters }) {
    const { next } = state.history;
    // if next history is empty
    if (next.length === 0) {
      return;
    }
    const screenData = next.pop();
    state.history.prev.push(_.cloneDeep(screenData));

    let selectedComponent = null;
    getComponentByUUID(screenData.components, state.selectedComponent, (comp) => {
      selectedComponent = comp;
    });

    const screen = rootGetters['screens/getScreenById'](state.id);
    screen.attributes = _.cloneDeep(screenData);
    commit('setScreen', screen);
    commit('settingsPanel/setData', _.cloneDeep(selectedComponent || state.data), { root: true });
  },
  undoScreen({ state, commit, rootGetters }) {
    const { prev } = state.history;
    // if prev history includes only an initial state
    if (prev.length === 1) {
      return;
    }
    state.history.next.push(_.cloneDeep(prev.pop()));

    const screenData = prev[prev.length - 1];
    let selectedComponent = null;
    getComponentByUUID(screenData.components, state.selectedComponent, (comp) => {
      selectedComponent = comp;
    });

    const screen = rootGetters['screens/getScreenById'](state.id);
    screen.attributes = _.cloneDeep(screenData);
    commit('setScreen', screen);
    commit('settingsPanel/setData', _.cloneDeep(selectedComponent || state.data), { root: true });
  },

  updateComponent ({ state, commit, rootGetters, dispatch }, payload) {
    // debugger
    // ** update component itself **
    commit('updateComponent', payload)

    // ** update synced child components **
    let { value, pathValue, uuid } = payload
    const component = (state.data.uuid === uuid) ? state.data : getComponentByUUID(state.data.components, uuid)
    const sync = (component.config || {}).syncChilds

    if (sync && sync[pathValue] !== undefined) {

      let screensList = new Set;

      sync[pathValue].forEach((el, i) => {
        let scr = rootGetters['screens/getScreenById'](el.screenId);
        if (scr) {
          const child = scr.attributes.uuid === el.uuid ? scr.attributes : getComponentByUUID(scr.attributes.components, el.uuid)
          if (child) {
            Vue.set(child, pathValue, _.cloneDeep(value))
            if (scr.id !== state.id) screensList.add(scr.id)
          } else {
            // ** remove child sync-dependency if it not exists (deleted) **
            sync[pathValue].splice(i,1)
          }
        }
      })

      screensList.forEach(el => {
        let scr = rootGetters['screens/getScreenById'](el);
        dispatch('saveScreen', Object.assign(scr.attributes, { id: el }))
      })
    }
  }
};

const mutations = {
  setState (state, { key, value }) {
    state[key] = value
  },
  setData(state, data) {
    state.data = data;
  },
  setSelected(state, uuid) {
    state.selectedComponent = uuid;
  },
  setScreen(state, { id, attributes }) {
    state.data = attributes;
    buildScreen(state.data.components, state.data)
    _createScreenIndex(state)

    if (!state.data.properties) {
      state.data.properties = {};
    }
    if (!state.data.properties.name) {
      state.data.properties.name = attributes.name;
    }
    state.data.name = 'mbst-screen';
    state.data.aliasName = 'Screen';
    state.id = id;

    // state.__index = _createScreenIndex(attribut);
  },

  addUUID (state, { component, options }) {
    const srcUUID = component.uuid
    const uuid = uid()
    Vue.set(component,'uuid', uuid)
    Vue.set(component.properties, 'backendname', component.aliasName.replace(' ', '-') + '-' +uuid.split('-')[0])
    if (options.keepSync && options.screenId) {
      const syncData = { enabled: true, screenId: options.screenId, componentUUID: srcUUID }
      this.commit('screen/setSyncView', { syncData, targetUUID: component })
    }

    component.items && component.items.forEach(el => this.commit('screen/addUUID', {component: el, options }))
    component.components && component.components.forEach(el => this.commit('screen/addUUID', {component: el, options }))
  },

  updateComponents(state, components) {
    state.data.components = components;
  },

  insertComponent(state, data) {
    const outflowEls = ['mbst-toolbar', 'mbst-bottom-toolbar'];
    if (!data.parentUUID) {
      _insertComponent(state.data.components, data);
    } else {
      if (_.includes(outflowEls, data.component.name)) {
        return;
      }
      getComponentByUUID(state.data.components, data.parentUUID, component => {
        _insertComponent(component.components || component.items, data);
      });
    }
    buildScreen(state.data.components, state.data);
    state.history = fillHistory(state.history, state.data);

    state.__index = _createScreenIndex(state.data);
  },

  deleteComponentByUUID(state, { uuid, component }) {
    if (component) {
      uuid = component.uuid
    } else if (uuid) {
      component = this.getters['screen/getComponentByUUID'](uuid)
    } else {
      return
    }

    const parentComponent = this.getters['screen/getComponentByUUID'](component.parentUUID)
    if (!parentComponent) return false

    const parentContainer = parentComponent.components || parentComponent.items
    const index = parentContainer.findIndex(c => c.uuid === component.uuid)
    if (index !== -1) parentContainer.splice(index, 1);

    state.history = fillHistory(state.history, state.data);
    _createScreenIndex(state)
  },

  updateComponent(state, { value, pathValue, uuid }) {
    const component = state.data.uuid == uuid ? state.data : getComponentByUUID(state.data.components, uuid)
    const prop = _.get(component, pathValue);
    // ** check property existance **
    // ** if undefined, set it as reacteve **
    if (prop === undefined) {
      pathValue.split('.').reduce((acc, p) => {
        if (acc[p] === undefined) Vue.set(acc, p, {})
        return acc[p]
      }, component)
    }
    _.set(component, pathValue, value);
    state.history = fillHistory(state.history, state.data);
  },
  resetHistory() {
    state.history.prev = [_.cloneDeep(state.data)];
    state.history.next = [];
  },
  createScreenIndex(state) {
    state.__index = _createScreenIndex(state.data)
  },
  highlightComponent (state, {uuid, className, timeout}) {
    uuid = uuid.split('-').shift();
    let obj = document.querySelector('[data-uuid="'+uuid+'"]');
    if (obj){
      obj.firstChild.classList.add(className);
      if (timeout && parseInt(timeout)) setTimeout(()=>obj.firstChild.classList.remove('flashAnimation'), timeout)
    }
  },

  setSyncView (state, { syncData, targetUUID }) {
    const targetComponent = typeof targetUUID === 'object' ? targetUUID : this.getters['screen/getComponentByUUID'](targetUUID)
    if (!targetComponent) return

    const configChild = targetComponent.config || {}
    configChild.sync = { css: Object.assign({}, syncData) }
    Vue.set(targetComponent, 'config', configChild)

    const screen = this.state.screens.data.find(el => el.id == syncData.screenId);
    if (screen) {
      const component = (syncData.componentUUID == screen.attributes.uuid) ? screen.attributes :getComponentByUUID(screen.attributes.components, syncData.componentUUID)
      if (component) {
        const configParent = component.config || {}
        let syncChilds = configParent.syncChilds || {}
        syncChilds.css = syncChilds.css || []

        let needSaveScreen = false

        // let uuid = this.$store.state.settingsPanel.data.uuid
        if (syncData.enabled){
          syncChilds.css.push({ uuid: targetComponent.uuid, screenId: this.state.screen.id })
          needSaveScreen = true
        } else {
          let i = syncChilds.css.findIndex(el => el.uuid === targetComponent.uuid)
          if (i > -1) {
            syncChilds.css.splice(i,1)
            needSaveScreen = true
          }
        }
        configParent.syncChilds = syncChilds
        Vue.set(component, 'config', configParent)

        if (screen.id !== this.state.screen.id && needSaveScreen) {
          this.dispatch('screen/saveScreen', Object.assign({ id: screen.id }, screen.attributes))
        }
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
