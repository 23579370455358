import Vue from 'vue'
import MakeAuth from 'general/utils/MakeAuth'

const auth = MakeAuth()

const DevmodeController = {
  debug: true,
  frame: null,
  frameready: false,
  $store: null,
  commandHandlerObject: null,
  sendpool: [],

  setCommandHandler: function (obj) {
    this.commandHandlerObject = obj
  },

  onRecieve: function (evt) {
    if (typeof evt !== 'object' || evt.data.type !== 'devmode' || evt.source === window) return

    if (this.debug) console.log(' RECIEVED DEV MESSAGE (bc) ', evt.data);
    if (evt.data.action) {
      if (evt.data.action === 'ready') {
        if (evt.data.id) DevmodeController.frameready = true
        if (DevmodeController.sendpool.length) DevmodeController.sendpool.forEach(data => DevmodeController.send(data))
      }
      const methodName = `on${evt.data.action.charAt(0).toUpperCase() + evt.data.action.slice(1)}`
      if (DevmodeController.commandHandlerObject) {
        if (typeof DevmodeController.commandHandlerObject[methodName] === 'function') {
          DevmodeController.commandHandlerObject[methodName](evt.data)
        }
      }
    }
  },

  send: function (data) {
    if (!this.frame) {
      this.farme = document.getElementById('muiframe')
    }

    if (!this.farme) {
      this.sendpool.push(data)
      return
    }

    if (this.debug) console.log(' SEND from bc ', data)
    this.farme.contentWindow.postMessage(Object.assign({ type: 'devmode' }, data), '*')
  },

  init: function (screenid) {
    this.farme = document.getElementById('muiframe')
    this.sendpool.push({ action: 'auth', token: auth.getAccessToken() })
    window.addEventListener("message", this.onRecieve)
  },

  stop () {
    window.removeEventListener("message", this.onRecieve)
  }
}

export default ({ store }) => {
  DevmodeController.$store = store
  Vue.prototype.$devmode = DevmodeController

  // debugger
  // console.log(auth.getAccessToken())

  if (document.readyState === 'complete') {
    DevmodeController.init()
  } else {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        DevmodeController.init()
      }
    }
  }
}
