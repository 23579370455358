var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _c(
      "div",
      { class: _vm.b("header") },
      [
        !_vm.UIControls
          ? [
              _c("div", { class: _vm.b("title") }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("WidgetEditorPropertyPane.title")) +
                    "\n      "
                ),
              ]),
            ]
          : [
              _c(
                "button",
                {
                  class: _vm.b("button-close", "btn btn-default btn-xs"),
                  attrs: {
                    title: _vm.$t("WidgetEditorPropertyPane.buttonCloseTitle"),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearPropertyPane.apply(null, arguments)
                    },
                  },
                },
                [_c("IconClose", { attrs: { width: "12" } })],
                1
              ),
              _vm._v(" "),
              _c("div", { class: _vm.b("title") }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("WidgetEditorPropertyPane.titleSelect")) +
                    "\n      "
                ),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("body", { isEmpty: _vm.isEmpty }) },
      [
        _vm._l(_vm.UIControls, function (propertyComponent, propertyName) {
          return [
            propertyComponent
              ? _c(
                  propertyComponent.componentName,
                  _vm._b(
                    {
                      key: propertyComponent.uuid,
                      tag: "component",
                      class: _vm.b("item"),
                      model: {
                        value:
                          propertyComponent.properties[
                            propertyComponent.vModelPropertyName
                          ],
                        callback: function ($$v) {
                          _vm.$set(
                            propertyComponent.properties,
                            propertyComponent.vModelPropertyName,
                            $$v
                          )
                        },
                        expression:
                          "propertyComponent.properties[propertyComponent.vModelPropertyName]",
                      },
                    },
                    "component",
                    propertyComponent.propertiesBind,
                    false
                  )
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }