import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps, sizingProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),

  makeControlProps('select', {
    pathValue: 'properties.dataFormat',
    label: 'Number format',
    options: [
      {label: 'none', value: ''},
      {label: 'Positive Integer', value: 'int'},
      {label: 'Decimal', value: 'fractional'}
    ],
    subcontrols:{
      'int': [
        makeControlProps('input', {
          pathValue: 'properties.numberMask',
          type: 'number',
          subtype: 'int',
          label: 'Number mask',
        }),
        makeControlProps('input', {
          pathValue: 'properties.defaultvalue',
          type: 'text',
          subtype: 'int',
          label: 'Default Value',
        }),
      ],
      'fractional': [
        makeControlProps('input', {
          pathValue: 'properties.numberMask',
          type: 'number',
          label: 'Number mask',
        }),
        makeControlProps('input', {
          pathValue: 'properties.defaultvalue',
          type: 'text',
          label: 'Default Value',
        }),
      ],
      '': [
        makeControlProps('input', {
          pathValue: 'properties.defaultvalue',
          type: 'text',
          label: 'Default Value',
        }),
      ]
    },
  }),

  makeControlProps('select', {
    pathValue: 'properties.iscached',
    label: 'Cache value',
    options: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ]
  }),

  makeControlProps('checkbox', {
    pathValue: 'properties.numberAutogrow',
    label: 'Increase int positions, when major Number mask position eq 9',
  }),

  makeControlProps('input', {
    pathValue: 'properties.placeholder',
    label: 'Placeholder'
  }),

  makeControlProps('input', {
    pathValue: 'properties.prefix',
    label: 'Prefix'
  }),
  makeControlProps('input', {
    pathValue: 'properties.suffix',
    label: 'Suffix'
  }),
  makeControlProps('select', {
    pathValue: 'properties.design',
    label: 'Design',
    options: [
      {label: 'standard', value: ''},
      {label: 'filled', value: 'filled'},
      {label: 'outlined', value: 'outlined'},
      {label: 'standout', value: 'standout'},
      {label: 'borderless', value: 'borderless'},
    ],
  }),
  makeControlProps('select', {
    pathValue: 'properties.darkMode',
    label: 'Dark mode',
    options: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ]
  }),
  visibilityProps,
  loopControlProps
];

const view = [
  {
    selector: '.input-proxy-val, .mbst-number-input input',
    controls: [
      typographyProps,
    ]
  },
  {
    selector: '.mbst-number-input .q-field__control',
    controls: [
      makeControlProps('typography', {
        label: 'Label styling',
        color: {
          label: 'Focused color',
          pathValue: 'color',
        },
      })
    ]
  },
  {
    selector: '.mbst-number-input',
    controls: [
      _.omit(sizingProps, ['height', 'maxHeight', 'minHeight']),
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps
    ]
  }
];

const actions = []

export default {
  properties,
  view,
  actions
}
