import axios from 'axios';
import { replaceSnippets } from 'general/utils/utils';
import { BASE_URL } from 'general/env';
import MakeAuth from './MakeAuth';

const lang = (key) => {
  try {
    return window.lang(key);
  } catch (error) {
    return key;
  }
};

/**
 * Фабрика http-сервиса
 * Преднастроенный экземпляр axios, здесь уже есть авторизационный токен,
 * обработка ошибки 401 (с обновлением токена и повторным выполнением запроса),
 * обработка ошибки 403 (с очисткой кук и редиректом на логин).
 * Default BaseURL - /api/v8
 *
 * @param {Object} httpConfig
 * @returns {Object}
 * @constructor
 */
const MakeResource = (httpConfig = {}) => {
  const _config = {
    tenant: '',
    server: '',
    baseURL: '/api/v8',
    applicationId: null,
    objectId: null,
    typeService: 'bc', // bc; widgets
    ...httpConfig,
  };
  let host = BASE_URL.indexOf('##') === -1 ? BASE_URL : '';

  if (_config.tenant && _config.server) {
    host = replaceSnippets(host, {
      TENANT: _config.tenant,
      SERVER: _config.server,
    });
  }

  const baseURL = `${host}${_config.baseURL}`;

  const http = axios.create({
    baseURL,
  });

  let axiosExtra = {}
  // Request helpers ($get, $post, ...)
  for (let method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axiosExtra['$' + method] = function () { return this[method].apply(this, arguments).then(res => res && res.data) }
  }
  for (let key in axiosExtra) {
    http[key] = axiosExtra[key].bind(http)
  }

  const auth = MakeAuth({
    tenant: _config.tenant,
    server: _config.server,
    applicationId: _config.applicationId,
    objectId: _config.objectId,
    typeService: _config.typeService,
  });

  /**
   * Установка авторизационного заголовка
   */
  http.interceptors.request.use(
    (config) => {
      const accessToken = auth.getAccessToken();
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

      /*
      // сейчас логин реализован стандартной отправкой формы
      // оставлю на будущее
      const url = config.url.split('/');
      if (url[url.length - 1] === 'login') {
        delete config.headers.Authorization;
      }
      */

      return config;
    },
    (error) => Promise.reject(error),
  );

  /**
   * Обработка ошибок запросов, в частности проверка на протухший токен
   * и дальнейшая попытка его обновить и выполнить изначальный запрос/запросы
   */
  http.interceptors.response.use(undefined, async (error) => {
    const { typeService } = _config;
    const { config, response: { status, data } } = error;
    const originalRequest = config;
    const { code: _code } = auth.getErrorData(data);
    const code = (_code) ? parseInt(_code, 10) : null;
    const errorsForLogout = [
      1003, // Authorization header not found
    ];

    if (typeService == 'bc') {
      // Force logout
      if (code && errorsForLogout.includes(code) || status === 403) {
        auth.alertLogout(lang('Access is denied. Please, authorize again.'));
      }
    }

    // If Access Token expired
    if (status === 401 && !originalRequest.__isRetryRequest) {
      if (auth.isRememberMe() && typeService == 'bc' || typeService !== 'bc') {
        try {
          const { data: res } = await auth.refreshToken();
          const baseURL = originalRequest.baseURL;

          let data = {};
          if (res.data) {
            data.access_token = res.data.attributes.accessToken;
            data.refresh_token = res.data.attributes.refreshToken;
          } else {
            data.access_token = res.access_token;
            data.refresh_token = res.refresh_token;
          }

          auth.setAuthData(data);
          originalRequest.__isRetryRequest = true;
          originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
          originalRequest.baseURL = originalRequest.url.search(baseURL) !== -1 ? null : baseURL;

          return http(originalRequest);
        } catch (error) {
          if (typeService == 'bc') {
            const refreshErrorData = auth.getErrorData(error.response.data);
            auth.alertLogout(refreshErrorData.detail);
          } else {
            const tokens = await auth.authorize();
            originalRequest.headers.Authorization = `Bearer ${tokens.access_token}`;
            originalRequest.baseURL = originalRequest.url.search(baseURL) !== -1 ? null : baseURL;

            return http(originalRequest);
          }
        }
      } else {
        auth.alertLogout(lang('Access is denied. Please, authorize again.'));
      }
    }

    return Promise.reject(error);
  });


  return http;
};

export { MakeResource };
