import _ from 'lodash';
import logger from 'general/logger';

/**
 * TODO: удалить, заменить плагином current-device
 * Определение мобильного устройства
 *
 * @returns {boolean}
 */
const checkMobileAndTablet = () => {
  let result = false;

  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
      result = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);

  return result;
};

/**
 * Экранирование спецсимволов для регулярных выражений
 *
 * @param {string} value
 * @returns {string}
 */
const escapeRegExp = value => (
  value.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
);

/**
 * Множественная замена подстроки
 *
 * @param {string} value
 * @param {string} find
 * @param {string} replace
 * @returns {string}
 */
const replaceAll = (value, find, replace) => (
  value.replace(new RegExp(escapeRegExp(find), 'g'), replace)
);

/**
 * Замена сниппетов в строке
 *
 * @param {string} value
 * @param {Object} snippets
 * @returns {string}
 */
const replaceSnippets = (value, snippets) => {
  let result = value;

  for (const key in snippets) {
    result = replaceAll(result, `##${key}##`, snippets[key]);
  }

  return result;
};

/**
 * Проверка сниппета в строке
 *
 * @param {string} value
 * @param {string} snippet
 * @returns {boolean}
 */
const checkSnippet = (value, snippet) => (
  value.includes(`##${snippet}##`)
);

/**
 * Проверка файла на существование
 *
 * @param {string} url
 * @returns {boolean}
 */
const existFile = async (url) => {
  const img = new Image();
  return new Promise((resolve) => {
    img.src = url;
    img.addEventListener('load', () => resolve(true));
    img.addEventListener('error', () => resolve(false));
  });
};

/**
 * Получить n-символов
 *
 * @param char
 * @param count
 * @returns {string}
 */
const getSingleChars = (char, count) => {
  let result = '';

  for (let i = 0; i <= count; i += 1) {
    result += char;
  }

  return result;
};

/**
 * Форматирование объекта в текст
 *
 * @param {object | array} object
 * @param {null | array} replacer
 * @param {number} space - количество пробелов для одного уровня вложенности
 * @param {number} offsetSpace - количество пробелов отступа всего элемента
 *  (кроме последней закрывающей скобки)
 * @param {number} step
 * @param {string | number} parentKey - для дебага
 * @returns {string}
 */
const objectStringify = (object, replacer = null, space = 0, offsetSpace = 0, step = 1, parentKey = '') => {
  const NL = '\r\n';
  const beforeBrace = (_.isArray(object)) ? '[' : '{';
  const afterBrace = (_.isArray(object)) ? ']' : '}';
  const spaces = getSingleChars(' ', (space * step) + offsetSpace);
  const spacesLast = getSingleChars(' ', space * step);
  let result = '';

  for (const key in object) {
    let value = object[key];

    // если есть "replacer", то проверить вхождение в него значения, иначе исключить значение
    if (_.isArray(replacer) && !replacer.includes(key)) {
      continue;
    }

    const quote = (typeof value === 'string') ? '\'' : '';

    // рекурсия для объектов и массивов
    if (_.isArray(value) || _.isObject(value)) {
      value = objectStringify(value, replacer, space, offsetSpace, step + 1, key);
    }

    // добавление отступов, если значение не пустое
    result += spaces;

    // добавление ключа, если передан объект, а не массив
    if (!_.isArray(object)) {
      result += `${key}: `;
    }

    result += `${quote}${value}${quote},${NL}`;
  }

  // добавление перевода строки после открывающей скобки и отступов, если результат не пустой
  if (result !== '') {
    result = NL + result + spacesLast;
  }

  return beforeBrace + result + afterBrace;
};

/**
 * Замена перевода строки на тэг br
 *
 * @param {string} value
 * @returns {string}
 */
const replaceNl2Br = value => (
  value.replace(/\r\n|\r|\n/g, '<br>')
);

/**
 * Замена тэга br на перевод строки
 *
 * @param {string} value
 * @returns {string}
 */
const replaceBr2Nl = value => (
  value.replace(/<br>/g, '\r\n')
);

/**
 * ...
 *
 * @param pathname
 * @returns {string[]}
 */
const getArrayPathname = (pathname = null) => (
  pathname
    ? pathname.replace(/^\/|\/$/g, '').split('/')
    : window.location.pathname.replace(/^\/|\/$/g, '').split('/')
);

/**
 * Copies a string to the clipboard. Must be called from within an
 * event handler such as click. May return false if it failed, but
 * this is not always possible. Browser support for Chrome 43+,
 * Firefox 42+, Safari 10+, Edge and IE 10+.
 * IE: The clipboard feature may be disabled by an administrator. By
 * default a prompt is shown the first time the clipboard is
 * used (per session).
 *
 * @param {*} text
 * @returns {boolean}
 */
const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
    // } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
  }

  const textarea = document.createElement('textarea');

  textarea.textContent = text;
  textarea.contenteditable = true;
  textarea.readonly = false;
  textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
  document.body.appendChild(textarea);
  textarea.select();

  try {
    const copied = document.execCommand('copy'); // Security exception may be thrown by some browsers.
    console.log('copied: ', copied);
    return copied;
  } catch (error) {
    console.warn('Copy to clipboard failed.', error);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }

  return false;
};

/**
 * Преобразование первой буквы строки в верхний регистр
 *
 * @param {string} value
 * @returns {string}
 */
const capitalizeFirstLetter = value => (
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`
);

/**
 * Преобразование строки "kebab-case" в формат "PascalCase"
 *
 * @param {string} value
 * @returns {string}
 */
const kebabToPascalCase = (value) => {
  const words = value.split('-');

  return (words.length)
    ? words.map(capitalizeFirstLetter).join('')
    : value;
};

/**
 * Преобразование строки "kebab-case" в формат "camelCase"
 *
 * @param {string} value
 * @returns {string}
 */
const kebabToCamelCase = (value) => {
  const words = value.split('-');

  if (words.length > 1) {
    return words
      .map((word, index) => {
        return (index > 0) ? capitalizeFirstLetter(word) : word;
      })
      .join('');
  }

  return value;
};

/**
 * Задержка
 *
 * @param {number} ms
 * @returns {Promise<void>}
 */
const delay = ms => (
  new Promise(resolve => setTimeout(resolve, ms))
);

/**
 * Фабрика генератора задержек
 * предусмотрен механизм отмены задержки, но вызывать его необходимо, но вызова
 *
 * @example
 * const delayer = factoryDelayer();
 * if (await isStopped) {
 *   delayer.cancel();
 * }
 * await delayer.delay(30000);
 *
 * @returns {object}
 */
const factoryDelayer = () => {
  let timer = 0;
  let resolver = null;

  return {
    /**
     * Задержка с указанным временем в мс.
     *
     * @param {number} ms
     * @returns {Promise<void>}
     */
    delay(ms) {
      clearTimeout(timer);
      return new Promise((resolve) => {
        resolver = resolve;
        timer = setTimeout(resolve, ms);
      });
    },

    /**
     * Отмена задержки
     */
    cancel() {
      if (!timer || !resolver) {
        return;
      }
      clearTimeout(timer);
      resolver();
    },
  };
};

/**
 * Конвертирование размера файла
 *
 * @param {number|string} value
 * @param {boolean} isShowName
 * @param {string} lang
 * @returns {*}
 */
const convertBytes = (value, isShowName = false, lang = 'en') => {
  const dimensions = {
    en: ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
    ru: ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'],
  };

  if (!(lang in dimensions)) {
    logger.error(`Value lang: ${lang} does not exist`);
    return false;
  }

  let i = 0;
  let result = parseInt(value, 10);

  while ((result / 1000 | 0) && i < dimensions[lang].length - 1) {
    result /= 1024;
    i += 1;
  }
  result = Math.round(parseFloat(result) * 100) / 100;

  if (isShowName === true) {
    result += ` ${dimensions[lang][i]}`;
  }

  return result;
};

/**
 * ...
*
 * @param {File} file
 * @returns {boolean}
 */
const isImageFile = file => !!file.type.match('image.*');

/**
 * Предварительная загрузка файла в браузере
 *
 * @param {File} file
 * @returns {Promise<string>} - binary string
 */
const preloadFile = file => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      resolve(event.target.result);
    });
    reader.readAsDataURL(file);

    setTimeout(() => {
      reject(new Error('File upload timeout limit exceeded'));
    }, 90 * 1000);
  })
);

/**
 * @param {Array} array
 * @param {Function} callback
 * @returns {Promise<void>}
 */
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};

const parseURL = url => {
  let parsed_url = {}

  if ( url == null || url.length == 0 )
    return parsed_url;

  let protocol_i = url.indexOf('://');
  parsed_url.protocol = url.substr(0,protocol_i);

  let remaining_url = url.substr(protocol_i + 3, url.length);
  let domain_i = remaining_url.indexOf('/');
  domain_i = domain_i == -1 ? remaining_url.length - 1 : domain_i;
  parsed_url.domain = remaining_url.substr(0, domain_i);
  parsed_url.path = domain_i == -1 || domain_i + 1 == remaining_url.length ? null : remaining_url.substr(domain_i + 1, remaining_url.length);

  let domain_parts = parsed_url.domain.split('.');

  switch ( domain_parts.length )
  {
    case 2:
      parsed_url.subdomain = null;
      parsed_url.host = domain_parts[0];
      parsed_url.tld = domain_parts[1];
      break;
    case 3:
      parsed_url.subdomain = domain_parts[0] == 'www' ?  null : domain_parts[0];
      parsed_url.host = domain_parts[1];
      parsed_url.tld = domain_parts[2];
      break;
    case 4:
      parsed_url.subdomain = domain_parts[0] == 'www' ?  null : domain_parts[0];
      parsed_url.host = domain_parts[1];
      parsed_url.tld = domain_parts[2] + '.' + domain_parts[3];
      break;
  }

  parsed_url.parent_domain = parsed_url.host + '.' + parsed_url.tld;


  function getUrlVars(url) {
    let decodedUrl = decodeURIComponent(url);
    let any_param = decodedUrl.indexOf('?');
    if (any_param > 0) {
      any_param = decodedUrl.slice(decodedUrl.indexOf('?') + 1);
      let hashes = any_param.split('&');

      let merged = {};
      let hash;

      for(let i = 0; i < hashes.length; i++) {
        let res = {};
        hash = hashes[i].split('=');

        if(hash.length > 0) {
          res[hash[0]] = hash[1];
          Object.assign(merged, res );
        }
      }
      if(hashes.length > 0) {
        return merged;
      }
    }
    else {
      return null;
    }
  }

  return Object.assign(parsed_url, {qry_str : getUrlVars(url)});
}

const slugify = str => {
  str = String(str).toString()
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const swaps = {
    '0': ['°', '₀', '۰', '０'],
    '1': ['¹', '₁', '۱', '１'],
    '2': ['²', '₂', '۲', '２'],
    '3': ['³', '₃', '۳', '３'],
    '4': ['⁴', '₄', '۴', '٤', '４'],
    '5': ['⁵', '₅', '۵', '٥', '５'],
    '6': ['⁶', '₆', '۶', '٦', '６'],
    '7': ['⁷', '₇', '۷', '７'],
    '8': ['⁸', '₈', '۸', '８'],
    '9': ['⁹', '₉', '۹', '９'],
    'a': ['à', 'á', 'ả', 'ã', 'ạ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ā', 'ą', 'å', 'α', 'ά', 'ἀ', 'ἁ', 'ἂ', 'ἃ', 'ἄ', 'ἅ', 'ἆ', 'ἇ', 'ᾀ', 'ᾁ', 'ᾂ', 'ᾃ', 'ᾄ', 'ᾅ', 'ᾆ', 'ᾇ', 'ὰ', 'ά', 'ᾰ', 'ᾱ', 'ᾲ', 'ᾳ', 'ᾴ', 'ᾶ', 'ᾷ', 'а', 'أ', 'အ', 'ာ', 'ါ', 'ǻ', 'ǎ', 'ª', 'ა', 'अ', 'ا', 'ａ', 'ä'],
    'b': ['б', 'β', 'ب', 'ဗ', 'ბ', 'ｂ'],
    'c': ['ç', 'ć', 'č', 'ĉ', 'ċ', 'ｃ'],
    'd': ['ď', 'ð', 'đ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ᵭ', 'ᶁ', 'ᶑ', 'д', 'δ', 'د', 'ض', 'ဍ', 'ဒ', 'დ', 'ｄ'],
    'e': ['é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ë', 'ē', 'ę', 'ě', 'ĕ', 'ė', 'ε', 'έ', 'ἐ', 'ἑ', 'ἒ', 'ἓ', 'ἔ', 'ἕ', 'ὲ', 'έ', 'е', 'ё', 'э', 'є', 'ə', 'ဧ', 'ေ', 'ဲ', 'ე', 'ए', 'إ', 'ئ', 'ｅ'],
    'f': ['ф', 'φ', 'ف', 'ƒ', 'ფ', 'ｆ'],
    'g': ['ĝ', 'ğ', 'ġ', 'ģ', 'г', 'ґ', 'γ', 'ဂ', 'გ', 'گ', 'ｇ'],
    'h': ['ĥ', 'ħ', 'η', 'ή', 'ح', 'ه', 'ဟ', 'ှ', 'ჰ', 'ｈ'],
    'i': ['í', 'ì', 'ỉ', 'ĩ', 'ị', 'î', 'ï', 'ī', 'ĭ', 'į', 'ı', 'ι', 'ί', 'ϊ', 'ΐ', 'ἰ', 'ἱ', 'ἲ', 'ἳ', 'ἴ', 'ἵ', 'ἶ', 'ἷ', 'ὶ', 'ί', 'ῐ', 'ῑ', 'ῒ', 'ΐ', 'ῖ', 'ῗ', 'і', 'ї', 'и', 'ဣ', 'ိ', 'ီ', 'ည်', 'ǐ', 'ი', 'इ', 'ی', 'ｉ'],
    'j': ['ĵ', 'ј', 'Ј', 'ჯ', 'ج', 'ｊ'],
    'k': ['ķ', 'ĸ', 'к', 'κ', 'Ķ', 'ق', 'ك', 'က', 'კ', 'ქ', 'ک', 'ｋ'],
    'l': ['ł', 'ľ', 'ĺ', 'ļ', 'ŀ', 'л', 'λ', 'ل', 'လ', 'ლ', 'ｌ'],
    'm': ['м', 'μ', 'م', 'မ', 'მ', 'ｍ'],
    'n': ['ñ', 'ń', 'ň', 'ņ', 'ŉ', 'ŋ', 'ν', 'н', 'ن', 'န', 'ნ', 'ｎ'],
    'o': ['ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ø', 'ō', 'ő', 'ŏ', 'ο', 'ὀ', 'ὁ', 'ὂ', 'ὃ', 'ὄ', 'ὅ', 'ὸ', 'ό', 'о', 'و', 'θ', 'ို', 'ǒ', 'ǿ', 'º', 'ო', 'ओ', 'ｏ', 'ö'],
    'p': ['п', 'π', 'ပ', 'პ', 'پ', 'ｐ'],
    'q': ['ყ', 'ｑ'],
    'r': ['ŕ', 'ř', 'ŗ', 'р', 'ρ', 'ر', 'რ', 'ｒ'],
    's': ['ś', 'š', 'ş', 'с', 'σ', 'ș', 'ς', 'س', 'ص', 'စ', 'ſ', 'ს', 'ｓ'],
    't': ['ť', 'ţ', 'т', 'τ', 'ț', 'ت', 'ط', 'ဋ', 'တ', 'ŧ', 'თ', 'ტ', 'ｔ'],
    'u': ['ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'û', 'ū', 'ů', 'ű', 'ŭ', 'ų', 'µ', 'у', 'ဉ', 'ု', 'ူ', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'უ', 'उ', 'ｕ', 'ў', 'ü'],
    'v': ['в', 'ვ', 'ϐ', 'ｖ'],
    'w': ['ŵ', 'ω', 'ώ', 'ဝ', 'ွ', 'ｗ'],
    'x': ['χ', 'ξ', 'ｘ'],
    'y': ['ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'ÿ', 'ŷ', 'й', 'ы', 'υ', 'ϋ', 'ύ', 'ΰ', 'ي', 'ယ', 'ｙ'],
    'z': ['ź', 'ž', 'ż', 'з', 'ζ', 'ز', 'ဇ', 'ზ', 'ｚ'],
    'aa': ['ع', 'आ', 'آ'],
    'ae': ['æ', 'ǽ'],
    'ai': ['ऐ'],
    'ch': ['ч', 'ჩ', 'ჭ', 'چ'],
    'dj': ['ђ', 'đ'],
    'dz': ['џ', 'ძ'],
    'ei': ['ऍ'],
    'gh': ['غ', 'ღ'],
    'ii': ['ई'],
    'ij': ['ĳ'],
    'kh': ['х', 'خ', 'ხ'],
    'lj': ['љ'],
    'nj': ['њ'],
    'oe': ['ö', 'œ', 'ؤ'],
    'oi': ['ऑ'],
    'oii': ['ऒ'],
    'ps': ['ψ'],
    'sh': ['ш', 'შ', 'ش'],
    'shch': ['щ'],
    'ss': ['ß'],
    'sx': ['ŝ'],
    'th': ['þ', 'ϑ', 'ث', 'ذ', 'ظ'],
    'ts': ['ц', 'ც', 'წ'],
    'ue': ['ü'],
    'uu': ['ऊ'],
    'ya': ['я'],
    'yu': ['ю'],
    'zh': ['ж', 'ჟ', 'ژ'],
    '(c)': ['©'],
    'A': ['Á', 'À', 'Ả', 'Ã', 'Ạ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Å', 'Ā', 'Ą', 'Α', 'Ά', 'Ἀ', 'Ἁ', 'Ἂ', 'Ἃ', 'Ἄ', 'Ἅ', 'Ἆ', 'Ἇ', 'ᾈ', 'ᾉ', 'ᾊ', 'ᾋ', 'ᾌ', 'ᾍ', 'ᾎ', 'ᾏ', 'Ᾰ', 'Ᾱ', 'Ὰ', 'Ά', 'ᾼ', 'А', 'Ǻ', 'Ǎ', 'Ａ', 'Ä'],
    'B': ['Б', 'Β', 'ब', 'Ｂ'],
    'C': ['Ç', 'Ć', 'Č', 'Ĉ', 'Ċ', 'Ｃ'],
    'D': ['Ď', 'Ð', 'Đ', 'Ɖ', 'Ɗ', 'Ƌ', 'ᴅ', 'ᴆ', 'Д', 'Δ', 'Ｄ'],
    'E': ['É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ë', 'Ē', 'Ę', 'Ě', 'Ĕ', 'Ė', 'Ε', 'Έ', 'Ἐ', 'Ἑ', 'Ἒ', 'Ἓ', 'Ἔ', 'Ἕ', 'Έ', 'Ὲ', 'Е', 'Ё', 'Э', 'Є', 'Ə', 'Ｅ'],
    'F': ['Ф', 'Φ', 'Ｆ'],
    'G': ['Ğ', 'Ġ', 'Ģ', 'Г', 'Ґ', 'Γ', 'Ｇ'],
    'H': ['Η', 'Ή', 'Ħ', 'Ｈ'],
    'I': ['Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Î', 'Ï', 'Ī', 'Ĭ', 'Į', 'İ', 'Ι', 'Ί', 'Ϊ', 'Ἰ', 'Ἱ', 'Ἳ', 'Ἴ', 'Ἵ', 'Ἶ', 'Ἷ', 'Ῐ', 'Ῑ', 'Ὶ', 'Ί', 'И', 'І', 'Ї', 'Ǐ', 'ϒ', 'Ｉ'],
    'J': ['Ｊ'],
    'K': ['К', 'Κ', 'Ｋ'],
    'L': ['Ĺ', 'Ł', 'Л', 'Λ', 'Ļ', 'Ľ', 'Ŀ', 'ल', 'Ｌ'],
    'M': ['М', 'Μ', 'Ｍ'],
    'N': ['Ń', 'Ñ', 'Ň', 'Ņ', 'Ŋ', 'Н', 'Ν', 'Ｎ'],
    'O': ['Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ø', 'Ō', 'Ő', 'Ŏ', 'Ο', 'Ό', 'Ὀ', 'Ὁ', 'Ὂ', 'Ὃ', 'Ὄ', 'Ὅ', 'Ὸ', 'Ό', 'О', 'Θ', 'Ө', 'Ǒ', 'Ǿ', 'Ｏ', 'Ö'],
    'P': ['П', 'Π', 'Ｐ'],
    'Q': ['Ｑ'],
    'R': ['Ř', 'Ŕ', 'Р', 'Ρ', 'Ŗ', 'Ｒ'],
    'S': ['Ş', 'Ŝ', 'Ș', 'Š', 'Ś', 'С', 'Σ', 'Ｓ'],
    'T': ['Ť', 'Ţ', 'Ŧ', 'Ț', 'Т', 'Τ', 'Ｔ'],
    'U': ['Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Û', 'Ū', 'Ů', 'Ű', 'Ŭ', 'Ų', 'У', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ｕ', 'Ў', 'Ü'],
    'V': ['В', 'Ｖ'],
    'W': ['Ω', 'Ώ', 'Ŵ', 'Ｗ'],
    'X': ['Χ', 'Ξ', 'Ｘ'],
    'Y': ['Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ', 'Ÿ', 'Ῠ', 'Ῡ', 'Ὺ', 'Ύ', 'Ы', 'Й', 'Υ', 'Ϋ', 'Ŷ', 'Ｙ'],
    'Z': ['Ź', 'Ž', 'Ż', 'З', 'Ζ', 'Ｚ'],
    'AE': ['Æ', 'Ǽ'],
    'Ch': ['Ч'],
    'Dj': ['Ђ'],
    'Dz': ['Џ'],
    'Gx': ['Ĝ'],
    'Hx': ['Ĥ'],
    'Ij': ['Ĳ'],
    'Jx': ['Ĵ'],
    'Kh': ['Х'],
    'Lj': ['Љ'],
    'Nj': ['Њ'],
    'Oe': ['Œ'],
    'Ps': ['Ψ'],
    'Sh': ['Ш'],
    'Shch': ['Щ'],
    'Ss': ['ẞ'],
    'Th': ['Þ'],
    'Ts': ['Ц'],
    'Ya': ['Я'],
    'Yu': ['Ю'],
    'Zh': ['Ж'],
  }

  Object.keys(swaps).forEach(
    swap => swaps[swap].forEach(
      s => str = str.replace(new RegExp(s, "g"), swap)
    )
  )
  return str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, "")
}


export {
  checkMobileAndTablet,
  escapeRegExp,
  replaceAll,
  replaceSnippets,
  checkSnippet,
  existFile,
  getSingleChars,
  objectStringify,
  replaceNl2Br,
  replaceBr2Nl,
  getArrayPathname,
  copyToClipboard,
  capitalizeFirstLetter,
  kebabToPascalCase,
  kebabToCamelCase,
  delay,
  factoryDelayer,
  convertBytes,
  isImageFile,
  preloadFile,
  asyncForEach,
  parseURL,
  slugify,
};
