'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Templates
var _tpl = require('text!bcTemplates/execution/executionSettings.html');

module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'b-execution__settings',

    events: {
        'click [data-js-toggle="saveExecutionSettings"]': 'save',
        'click [data-js-toggle="closeExecutionSettings"]': 'close'
    },

    bindings: {
        '#execution-name': 'name',
        '#execution-in': {
            observe: 'in',
            initialize: function($el) {
                this.selectTwoBinding($el);
            },
            selectOptions: {
                collection: function () {
                    return [
                        { label: 'query', value: 'query' },
                        { label: 'header', value: 'header' },
                        { label: 'path', value: 'path' },
                        { label: 'formData', value: 'formData' },
                        { label: 'body', value: 'body' }
                    ];
                }
            }
        },
        '#execution-type': {
            observe: 'type',
            initialize: function($el) {
                this.selectTwoBinding($el);
            },
            selectOptions: {
                collection: function () {
                    return [
                        { label: 'string', value: 'string' },
                        { label: 'number', value: 'number' },
                        { label: 'integer', value: 'integer' },
                        { label: 'boolean', value: 'boolean' },
                        // { label: 'array', value: 'array' },
                        // { label: 'file', value: 'file' }
                    ];
                }
            }
        },
        '#execution-description': 'description',
        '#execution-required': 'required',
    },

    initialize: function(options) {
        var t = this;
        t.parentView = options.parentView;
        t.tpl = _.template(_tpl);

        t.listenTo(t.model, 'change', t.ch, t);
    },

    render: function() {
        var t = this;
        t.$el.empty();

        t.$el.html(t.tpl(t.model.toJSON()));

        t.parentView.$el.append(t.$el);
        t.$('input:first').select();
        t.stickit();
    },

    ch: function() {
        this.$('[data-js-toggle="saveExecutionSettings"]').attr('disabled', false);
    },

    save: function(e) {
        e.preventDefault();
        this.parentView.model.set(this.model.toJSON());
        this.parentView.render();
        this.close();
    },

    close: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
        this.parentView.executionSettingsView = undefined;
    },

    selectTwoBinding: function(select) {

        var options = {};

        switch (select.attr('data-type')) {
            case 'tags':
                options.tags = true;
                break;

            case 'nosearch':
                options.minimumResultsForSearch = -1;
                break;

        }

        if (!select.hasClass('select2-hidden-accessible')) {
            select.select2(options);
        }

    }

});