var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-input", {
    attrs: {
      label: _vm.data.label,
      clearable: "",
      "clear-icon": "close",
      type: "number",
      min: "0",
      step: "1",
      outlined: "",
      dense: "",
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = _vm._n($$v)
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }