<template>
  <div :class="b({ isDisabled: disabled, unclearable: !clearable })">
    <label
      v-if="labelBefore"
      :class="b('label-before')"
      v-html="labelBefore"
    ></label>

    <!--<div :class="b('container')">-->
      <!--&lt;!&ndash;:value="localValue"&ndash;&gt;-->
      <!--<v-select-->
        <!--:input-id="vId"-->
        <!--v-model="localValue"-->
        <!--:options="options"-->
        <!--:label="optionLabelName"-->

        <!--:searchable="searchable"-->
        <!--:multiple="multiple"-->
        <!--@change="handlerChange"-->
      <!--&gt;</v-select>-->
      <!--&lt;!&ndash;:onChange="onChange"&ndash;&gt;-->
      <!--&lt;!&ndash;@change="handlerChange"&ndash;&gt;-->
    <!--</div>-->

    <div :class="b('container')">
      <select
        :id="vId"
        class="form-control"
        :name="name"
        :value="localValue"
        :multiple="multiple"
        :disabled="disabled"
        @change="handlerChange"
      >
        <option
          v-for="(option, index) in options"
          v-if="option"
          :key="index"
          :value="option.value"
          :selected="option.selected || localValue == option.value"
          :disabled="option.disabled"
          v-html="option.label"
        ></option>
      </select>
    </div>

    <label
      v-if="labelAfter"
      :class="b('label-after')"
      v-html="labelAfter"
    ></label>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'control-select',

    mixins: [
      baseMixin,
    ],

    model: {
      prop: 'value',
      event: 'update',
    },

    props: {
      options: {
        type: Array,
        required: true,
      },
      name: {
        type: String,
      },
      value: {
        type: [String, Number, Object],
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      labelBefore: {
        type: String,
      },
      labelAfter: {
        type: String,
      },

      // optionLabelName: {
      //   type: String,
      //   default: 'label',
      // },
      clearable: {
        type: Boolean,
        default: false,
      },
      // searchable: {
      //   type: Boolean,
      //   default: false,
      // },
    },

    data: () => ({
      localValue: null,
    }),

    // watch: {
    //   localValue(value) {
    //     console.log('localValue changed!');
    //     console.log('value: ', value);
    //   },
    // },

    created() {
      if (this.value) {
        this.localValue = this.value;
      } else {
        const checkedOption = this.options.filter(option => option.checked);

        this.localValue = checkedOption.value;
      }
    },

    methods: {
      handlerChange(event) {
        // console.log('handlerChange!');
        // console.log('event: ', event);

        const value = event.target.value;

        this.localValue = value;
        this.$emit('update', value);
      },

      // onChange(option) {
      //   if (!option) {
      //     return false;
      //   }
      //
      //   console.log('onChange!');
      //   console.log('option: ', option);
      //
      //   const value = option.value;
      //
      //   this.localValue = value;
      //   this.$emit('update', value);
      // },

      // handlerChange(option) {
      //   if (!option) {
      //     return false;
      //   }
      //   console.log('handlerChange!');
      //   console.log('option: ', option);
      //   console.log('this.localValue: ', this.localValue);
      //
      //   // const value = option.value;
      //
      //   // this.localValue = option;
      //   // this.$emit('update', option);
      //   this.$emit('update', this.localValue);
      // },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-select
    &_is-disabled
      cursor not-allowed

    > label
      ~/_is-disabled&
        pointer-events none

    &__label-before
      margin-bottom 10px

    &__container
      > select
        width 100%
        height 38px

        &:disabled
          pointer-events none

    &__label-after
      margin-left 10px

    //.v-select
    //  ~/_unclearable&
    //    button.clear
    //      display none !important

    //  &.unsearchable
    //    .dropdown-toggle
    //      padding-bottom 4px

    //    input[type="search"]
    //      width 1px !important
    //      padding 0
</style>
