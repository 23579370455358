'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Models
var ApiProviderModel = require('models/ApiProviderModel');


module.exports = Backbone.Collection.extend({

    url: '/apiproviders/apiprovidersapi',

    model: ApiProviderModel,

    initialize: function() {

    }

});