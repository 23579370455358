/**
 *
 */
export const defaultCSSBreakpoints = [
  { name: 'xs', label: 'XS', width: 375, min: 300, max: 480, icon: 'smartphone', descr: 'mobail portrait', default: 'mobile' },
  { name: 'sm', label: 'S', width: 568, min: 481, max: 767, icon: 'tablet', descr: 'mobail landscape, tablet portrait' },
  { name: 'md', label: 'M', width: 768, min: 768, max: 1024, icon: 'tablet_android', descr: 'tablet landscape' },
  { name: 'lg', label: 'L', width: 1200, min: 1025, max: 1680, icon: 'desktop_windows', descr: 'desktop, laptop', default: 'desktop' },
  { name: 'xl', label: 'XL', width: 1681, min: 1681, max: 7680, icon: 'desktop_windows', descr: 'large desktop' }
]

/**
 * Build (string) CSS from (object) rules
 * @param Object|Array cssRules
 *
 * @return string Text of CSS
 */
export const buildCSS = function (cssRules) {
  if (!cssRules.length) return ''
  const result = []
  if (Array.isArray(cssRules[0].rules)) convertCSStoNewStyle(cssRules)

  cssRules.forEach(item => {
    let textRules = ''
    if (typeof item.rules === 'object') {
      for (let directive in item.rules) textRules += `\t${directive}: ${item.rules[directive]};\n`
    }
    if (textRules) result.push(`${item.selector} {\n${textRules}}\n`)
  })
  return result.join('\n')
}

/**
  * Transform (jotform/css.js)-style Array of css rules to Object-style css rules
  * for compatibility with old style css
  */
export const convertCSStoNewStyle = function (oldStyleCss) {
  const newStyleCss = []
  oldStyleCss.forEach((item, i) => {
    if (!Array.isArray(item.rules)) return
    const rules = {}
    item.rules.forEach(rule => (rules[rule.directive] = rule.value))
    oldStyleCss[i].rules = rules
  })
  return oldStyleCss
}


/**
 * Combine two CSS objects
 *
 * @param Object Target
 * @param Object Source
 *
 * @return Object Combined CSS
 */
export const mergeCSS = function (css, css2,  isTargetPriority = false) {
  if (Array.isArray(css[0].rules)) convertCSStoNewStyle(css)
  if (Array.isArray(css2[0].rules)) convertCSStoNewStyle(css2)

  css2.forEach(item => {
    const itemInTargetCSS = css.find(el => el.selector === item.selector)
    if (!itemInTargetCSS) {
      css.push(Object.assign({}, item))
      return
    }

    for (let directive in item.rules) {
      if (isTargetPriority && itemInTargetCSS.rules[directive] !== undefined) continue
      itemInTargetCSS.rules[directive] = item.rules[directive]
    }
  })
}

/**
 * Add namespase to css selectors
 */
export const applyNamespacing = function (css, namespace, isVStyle) {

  if (!Array.isArray(css)) return css

  return css.map(el => {
    //bypass namespacing for @font-face @keyframes @import
    if(el.selector.indexOf('@font-face') > -1 || el.selector.indexOf('keyframes') > -1 || el.selector.indexOf('@import') > -1 || el.selector.indexOf('.form-all') > -1 || el.selector.indexOf('#stage') > -1){
      return el;
    }

    const selectors = el.selector.split(',')

    selectors.forEach((selector, i) => {
      if (isVStyle) {
        selectors[i] = selectors[i].split(' ')
        selectors[i][0] += `[data-uuid="${namespace}"]`
        selectors[i] = selectors[i].join(' ')
      } else {
        selectors[i] = `${namespace} ${selectors[i]}`
      }
    })

    el.selector = selectors.join(',')

    return el
  })
}
