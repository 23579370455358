import _ from 'lodash';
import { mergeDefaultPropsComponents, mergeDefaultPropsComponent, generateUUIDS } from '@/bc/pages/constructor/utils';
import screenSchema from '@/bc/pages/constructor/schemaComponents/screen';
import MakeHttp from '@/general/utils/MakeHttp';
const http = MakeHttp();
import fi from '@/bc/pages/constructor/utils/css';
import { buildCSS, mergeCSS, applyNamespacing } from '@/bc/pages/constructor/utils/css.mbst.js';
const parser = new fi();

const defaultCssScreen = screenSchema.css;

const state = {
  isProcess: false,
  data: null,
  pagination: null,
  originalScreens: null
};

const getters = {
  idFirstScreen: state => state.data &&  state.data[0] && state.data[0].id || null,
  getScreenById: state => id => (state.data || []).find(screen => screen.id == id),
  // TODO: Need refactor
  getStyle: (state, getters, rootState) => screenId => {
    if (!screenId) return null;
    const os = rootState.os;
    const screen = state.data.find(screen => screen.id == screenId)
    if (!screen) return null;
    const cloneScreenData = _.cloneDeep(screen.attributes);
    const getShortUUID = uuid => uuid.match(/(.+?)(?=-)/)[0];

    const replaceCssRule = (arrSelectors, fromRule, toRule) => {
      Object.keys(arrSelectors).forEach(media => {
        arrSelectors[media].forEach(el => {
          Object.keys(el.rules).forEach(rule => {
            if (typeof fromRule === 'string') {
              if (rule === fromRule) {
                delete el.rules[rule]
                Object.assign(el.rules, toRule)
              }
            } else if (typeof fromRule === 'object') {
              if (fromRule[rule] === el.rules[rule]) {
                delete el.rules[rule]
                Object.assign(el.rules, toRule)
              }
            }
          })
        })
      })
    }

    const getCSS = (component, res = { all: [] }) => {

      // ** component
      if (typeof component.css === 'object') {
        const scopes = Object.keys(component.css).filter(el => el[0] === '.')

        // ** compatibility with legacy apps with old style css structure
        // ** `All` styles where duplicated in `android` and `ios` css branches
        // ** in future this block can be removed
        if ((component.css['android'] || []).length) {
          let cCss = component.css['android'].map(el => Object.assign({}, el))
          // applyNamespacing(cCss, `[data-uuid="${getShortUUID(component.uuid)}"]`)
          if (component.items || component.components) {
            applyNamespacing(cCss, getShortUUID(component.uuid), true)
          } else {
            applyNamespacing(cCss, `[data-uuid="${getShortUUID(component.uuid)}"]`)
          }
          res['all'] = res['all'].concat(...cCss)
        }
        // **

        if ((component.css['all'] || []).length) {
          let cCss = component.css['all'].map(el => Object.assign({}, el))
          // applyNamespacing(cCss, `[data-uuid="${getShortUUID(component.uuid)}"]`)
          if (component.items || component.components) {
            applyNamespacing(cCss, getShortUUID(component.uuid), true)
          } else {
            applyNamespacing(cCss, `[data-uuid="${getShortUUID(component.uuid)}"]`)
          }
          res['all'] = res['all'].concat(...cCss)
        }

        scopes.forEach(scope => {
          if (!res[scope]) res[scope] = []
          let cCss = component.css[scope].map(el => Object.assign({}, el))
          // if ((component.css['all'] || []).length) mergeCSS(cCss, component.css['all'], true)
          if (component.items || component.components) {
            applyNamespacing(cCss, getShortUUID(component.uuid), true)
          } else {
            applyNamespacing(cCss, `[data-uuid="${getShortUUID(component.uuid)}"]`)
          }
          res[scope] = res[scope].concat(cCss)
        })
      }

      // ** items and components
      if (component.components && component.components.length) component.components.forEach(c => getCSS(c, res))
      if (component.items && component.items.length) component.items.forEach(c => getCSS(c, res))

      return res
    }

    let css = getCSS(cloneScreenData)

    // ** set Opacity instead Display:none in Constructor to remain component visible
    replaceCssRule(css, { display: 'none' }, { opacity: '0.3' })


    // // ** adopt header/footer width to screen width (if it set)
    // if (vm.screen) {
    //   const scrCSS = vm.screen.css.ios.find(el => el.selector === "");
    //   if (scrCSS) {
    //     const rule = scrCSS.rules.find(el => el.directive === "max-width")
    //     if (rule) {
    //       css.push({ rules: [rule], selector: '.q-footer, .q-header' })
    //     }
    //   }
    // }
    // // **

    const text = Object.keys(css).reduce((result, cssScope) => {
      if (cssScope === 'all') result += buildCSS(css[cssScope])
      else if (cssScope[0] === '.') {
        applyNamespacing(css[cssScope], cssScope)
        result += buildCSS(css[cssScope])
      }
      return result
    }, '')

    return text
  }
};

const actions = {
  async getAllScreens ({ commit, rootState }, applicationId) {
    try {
      // TODO: Pagination for constructor
      const query = {
        ApplicationId: Number(applicationId),
        page: 1,
        pageSize: 200,
      };
      let { data: screens, meta: pagination } = await http.get('screen', query);
      if (!screens || !pagination) {
        throw new Error('Failed getAllScreens');
      }

      const filtredComps = component => {
        if (component.components) {
          component.components = component.components.filter(comp => !!comp.name)
          for (const comp of component.components) {
            filtredComps(comp)
            if (comp.items) {
              for (const item of comp.items) {
                filtredComps(item)
              }
            }
          }
        }
      }

      screens = _.map(_.sortBy(screens, screen => screen.attributes.SortOrder), screen => {
        if (!screen.attributes.properties) screen.attributes.properties = {};
        if (!screen.attributes.properties.name) screen.attributes.properties.name = screen.attributes.name;
        screen.attributes.name = 'mbst-screen';
        screen.attributes.aliasName = 'Screen';
        if (_.isEmpty(screen.attributes.css)) screen.attributes.css = defaultCssScreen;
        screen.attributes = mergeDefaultPropsComponent(screen.attributes);

        if (screen.attributes.components) {
          filtredComps(screen.attributes)
        }

        screen.attributes.components = mergeDefaultPropsComponents(screen.attributes.components);
        return screen;
      });

      commit('setOriginalScreens', _.cloneDeep(screens));
      commit('setScreens', {screens, pagination});

      // ** initialize current screen, when screens will be loaded
      if (rootState.screen.id && !rootState.screen.__index.length) {
        this.dispatch('screen/getScreenById', rootState.screen.id)
      } else if (!rootState.screen.id && screens.length) {
        let homescreen = screens.find(scr => scr.attributes.ishomescreen)
        if (homescreen === undefined) homescreen = screens[0]
        this.dispatch('screen/getScreenById', homescreen.id)
      }
      return Promise.resolve();
    } catch (error) {
      console.log(error);
    }
  },
  async deleteScreenById ({ commit, state }, id) {
    commit('setIsProcess', true);
    try {
      const {
        meta: { affected },
      } = await http.delete(`screen/${id}`)
      if (!affected) throw new Error(`Could not remove screen`)

      const index = state.data.findIndex((screen) => screen.id == id)
      if (index == -1) throw new Error(`Can't find screen`)

      commit('deleteScreen', index)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setIsProcess', false)
    }
  },
  async setScreenHome({ commit, rootGetters }, id) {
    commit('setIsProcess', true);
    try {
      const findScreen = rootGetters['screens/getScreenById'](id);
      if (!findScreen || !findScreen.attributes) {
        console.warn('Can\'t find screen');
      }
      // todo replace for correct api method
      const res = await http.put(`screen/${id}`, {
        ...findScreen.attributes,
        ishomescreen: true,
        SortOrder: 0,
      });
      if (!res || !res.data) {
        console.warn('Failed set home screen')
      }
    }
    catch (e) {
      console.warn(e)
    }
    finally {
      commit('setIsProcess', false)
    }
  },
  async copyScreenById({ commit, state, rootState }, { screenId, applicationId }) {
    commit('setIsProcess', true);
    const omit = ['CreateDate', 'UpdateDate', 'LastModified', 'uuid', 'SortOrder'];
    try {
      const findScreen = _.find(state.data, s => s.id == screenId);
      if (!findScreen || !findScreen.attributes) throw new Error(`Can't find screen`);

      let copyScreen = _.cloneDeep(_.omit(findScreen.attributes, omit));

      if (applicationId) copyScreen.ApplicationId = Number(applicationId);

      copyScreen.properties.name = copyScreen.Name += ' copy';
      generateUUIDS(copyScreen);

      const res = await http.post('screen', copyScreen);
      if (!res || !res.data) throw new Error('Failed copy screen');

      if (!applicationId || applicationId == rootState.applicationId)
        commit('insertScreen', { index: res.data.attributes.SortOrder, screen: res.data });

      return Promise.resolve(res.data);
    }
    catch(e) {
      console.log(e);
      return e;
    }
    finally {
      commit('setIsProcess', false);
    }
  },
};

const mutations = {
  setScreens (state, payload) {
    state.data = payload.screens;
    state.pagination = payload.pagination;
  },
  setOriginalScreens (state, screens) {
    state.originalScreens = screens;
  },
  insertScreen(state, { index, screen }) {
    state.data.splice(index, 0, screen);
  },
  deleteScreen (state, index) {
    state.data.splice(index, 1);
  },
  setIsProcess(state, payload) {
    state.isProcess = payload;
  },
  updateCopiedComponent(state, copiedComponent) {
    navigator.clipboard.writeText(JSON.stringify(copiedComponent));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
