export default {
  list: state =>
    (state.rawData.data || [])
      .map(widget => ({
        id: Number(widget.id),
        ...widget.attributes,
      }))
      .filter(widget => widget.json_data.type === 'pwaless'),

  getById: (state, getters) => id => getters.list.find(widget => widget.id === Number(id)) || null,
}
