import { backgroundDefault } from './helper';

export default {
  name: 'mbst-text',
  aliasName: 'Text',
  properties: {
    backendname: 'text',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
    shadow: '',
    shadowPosition: '',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: [],
  css: {
    ios: [
      {
        selector: '.mbst-text',
        rules: [
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-text',
        rules: [
          ...backgroundDefault,
        ]
      }
    ]
  },
}
