var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-header",
    { class: { minimized: _vm.headerMinimized, relativePosition: true } },
    [
      _c(
        "div",
        {
          staticClass: "row no-wrap full-width items-center mbst-header",
          on: { mouseover: _vm.headerHover, mouseleave: _vm.headerMouseOut },
        },
        [
          _c(
            "q-btn",
            {
              staticClass: "absolute-top-right",
              attrs: {
                flat: "",
                round: "",
                size: "xs",
                icon: _vm.headerMinimizable ? "expand_more" : "expand_less",
              },
              on: {
                click: function ($event) {
                  return _vm.setHeaderMinimizable(!_vm.headerMinimizable)
                },
              },
            },
            [
              _vm.headerMinimizable
                ? _c("q-tooltip", [_vm._v("Show always")])
                : _c("q-tooltip", [_vm._v("Hide automaticaly")]),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.headerMinimized
            ? [
                _c(
                  "div",
                  {
                    staticClass: "col-auto q-px-sm q-mr-lg",
                    attrs: { "data-tour": "menu-dashboard" },
                  },
                  [
                    _c(
                      "q-btn",
                      {
                        attrs: { size: "md", round: "", flat: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goto("/")
                          },
                        },
                      },
                      [
                        _c("q-icon", {
                          attrs: {
                            size: "2em",
                            name: "img:/front/src/bc/assets/img/logo-sm-white.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "q-tooltip",
                          {
                            attrs: {
                              "max-width": "200px",
                              anchor: "bottom middle",
                              self: "top middle",
                            },
                          },
                          [
                            _c("div", { staticClass: "text-subtitle1" }, [
                              _vm._v("All Apps"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-body2" }, [
                              _vm._v(
                                "Home page, where all apps of this account are listed and managed."
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-auto",
                    attrs: { "data-tour": "menu-section-general" },
                  },
                  [
                    _c("section-menu", {
                      attrs: {
                        section: _vm.sectionsMenu.general,
                        "active-item": _vm.activeItem,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "q-separator q-separator--vertical q-mx-lg",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-auto",
                    attrs: { "data-tour": "menu-section-integrations" },
                  },
                  [
                    _c("section-menu", {
                      attrs: {
                        section: _vm.sectionsMenu.api,
                        "active-item": _vm.activeItem,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-auto" }, [
                  _vm.$store.state.application.data.attributes
                    ? _c(
                        "div",
                        {
                          staticClass: "row no-wrap items-center",
                          attrs: { "data-tour": "constroctor-menu" },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "q-separator q-separator--vertical q-mx-lg",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-auto" }, [
                            _c(
                              "span",
                              { staticClass: "mbst-header__app-name" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm._f("truncate")(
                                        _vm.$store.state.application.data
                                          .attributes.Name,
                                        10
                                      )
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-auto q-ml-md",
                              attrs: { "data-tour": "menu-section-appSetup" },
                            },
                            [
                              _c("section-menu", {
                                attrs: {
                                  section: _vm.sectionsMenu.appSettings,
                                  "active-item": _vm.activeItem,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-auto q-ml-md",
                              attrs: { "data-tour": "menu-section-appData" },
                            },
                            [
                              _c("section-menu", {
                                attrs: {
                                  section: _vm.sectionsMenu.appData,
                                  "active-item": _vm.activeItem,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("q-space"),
                _vm._v(" "),
                _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row no-wrap items-center q-px-sm q-mt-md",
                      attrs: { "data-tour": "menu-section-additional" },
                    },
                    [
                      !_vm.$store.state.application.data.attributes
                        ? _c(
                            "q-btn",
                            {
                              staticClass: "bg-warning",
                              staticStyle: { "background-color": "#4cae4c" },
                              attrs: {
                                id: "btnSyncLogintap",
                                flat: "",
                                "no-caps": "",
                                "text-wrap": "",
                                label: "LOGIN WITH",
                                icon: "fas fa-user-lock",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onShowLogintapWidget()
                                },
                              },
                            },
                            [
                              _c(
                                "q-tooltip",
                                {
                                  attrs: {
                                    "max-width": "300px",
                                    anchor: "bottom middle",
                                    self: "top middle",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-subtitle1" }, [
                                    _vm._v("Authentication via messenger"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-body2" }, [
                                    _vm._v(
                                      "Connect your messenger ot choice for two factor authetication into Mobsted. Enter your e-mail and press messengers button in login form."
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "12px",
                                    "font-size": "19px",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fab fa-facebook-messenger",
                                  }),
                                  _vm._v(" "),
                                  _c("i", { staticClass: "fab fa-telegram" }),
                                  _vm._v(" "),
                                  _c("i", { staticClass: "fab fa-viber" }),
                                  _vm._v(" "),
                                  _c("i", { staticClass: "fab fa-vk" }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mbst-header__item is-middle q-ml-sm",
                          class: { "is-active": "partners" == _vm.activeItem },
                        },
                        [
                          _c(
                            "q-btn",
                            {
                              attrs: {
                                type: "a",
                                round: "",
                                flat: "",
                                href: "/partners/index",
                              },
                            },
                            [
                              _c("q-icon", {
                                attrs: {
                                  size: "1.25em",
                                  name: "fas fa-handshake",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "q-tooltip",
                                {
                                  attrs: {
                                    "max-width": "200px",
                                    anchor: "bottom middle",
                                    self: "top middle",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-subtitle1" }, [
                                    _vm._v("Partners"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-body2" }, [
                                    _vm._v("Partnership reseller program"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "q-btn",
                        { attrs: { round: "", flat: "" } },
                        [
                          _c("q-icon", {
                            attrs: {
                              size: "1.25em",
                              name: "fas fa-question-circle",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "q-tooltip",
                            {
                              attrs: {
                                "max-width": "200px",
                                anchor: "bottom middle",
                                self: "top middle",
                              },
                            },
                            [
                              _c("div", { staticClass: "text-subtitle1" }, [
                                _vm._v("Help"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "q-menu",
                            {
                              attrs: {
                                "auto-close": "",
                                anchor: "top middle",
                                self: "bottom right",
                              },
                            },
                            [
                              _c(
                                "q-list",
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { header: "" } },
                                    [_vm._v("Live Guides")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: {
                                        click: _vm.onStartQuickAppCreationGuide,
                                      },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Quick App Creation"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: { click: _vm.onStartIntroGuide },
                                    },
                                    [_c("q-item-section", [_vm._v("Intro")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: {
                                        click: _vm.onStartConstructorGuide,
                                      },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Constructor"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: { click: _vm.onStartLayoutLiveGuide },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Layout & Live Edit Mode"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: { click: _vm.onStartSecurityGuide },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Security"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: { click: _vm.onStartStatusesGuide },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Statuses"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: { click: _vm.onStartWidgetsGuide },
                                    },
                                    [_c("q-item-section", [_vm._v("Widgets")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      on: {
                                        click: _vm.onStartInviteNotifyGuide,
                                      },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Invitation and Notifications"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("q-separator", { attrs: { spaced: "" } }),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.goto(
                                            "https://docs.mobsted.com/",
                                            "_blank"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Knowledge Base"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.goto(
                                            "https://mobsted.com/data_structure",
                                            "_blank"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Data Org Chart"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      attrs: { clickable: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.goto(
                                            _vm.subjectAskUs,
                                            "_blank"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("q-item-section", [
                                        _vm._v("Ask us at support@mobsted.com"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mbst-header__item is-middle q-ml-sm",
                          class: { "is-active": "profile" == _vm.activeItem },
                        },
                        [
                          _c(
                            "q-btn",
                            {
                              attrs: {
                                type: "a",
                                round: "",
                                flat: "",
                                href: "/myprofile/index",
                              },
                            },
                            [
                              _c("q-icon", {
                                attrs: {
                                  size: "1.25em",
                                  name: "fas fa-user-circle",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "q-tooltip",
                                {
                                  attrs: {
                                    "max-width": "200px",
                                    anchor: "bottom middle",
                                    self: "top middle",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-subtitle1" }, [
                                    _vm._v("My Profile"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-body2" }, [
                                    _vm._v("User's personal login info"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "q-btn",
                        {
                          staticClass: "q-ml-sm",
                          attrs: { round: "", flat: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.goto("/users/logout")
                            },
                          },
                        },
                        [
                          _c("q-icon", {
                            attrs: {
                              size: "1.25em",
                              name: "fas fa-sign-out-alt",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "q-tooltip",
                            {
                              attrs: {
                                "max-width": "200px",
                                anchor: "bottom middle",
                                self: "top middle",
                              },
                            },
                            [
                              _c("div", { staticClass: "text-subtitle1" }, [
                                _vm._v("Logout"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-body2" }, [
                                _vm._v("Exit Mobsted backend"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-teleport-location", {
        staticStyle: { "border-bottom": "1px solid silver" },
        attrs: { name: "subHeader", tag: "div" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }