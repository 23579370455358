import _ from 'lodash'
import { uuid } from 'vue-uuid'
import schemaComponents from './schemaComponents/index'
import schemaSettings from './schemaSettings/index'

const customizerMerge = (objValue, srcValue) => {
  if (_.isArray(objValue)) return srcValue
}

export const mergeDefaultPropsComponent = (component) => {
  // TODO: merge css (use unionBy for array objects)
  // короче мердж работает с массивами не так как нам нужно, позже доработаю напильником
  // upd. Допилено
  if (component.items) {
    _.each(component.items, (item, ind) => {
      mergeDefaultPropsComponent(item)
      component.items[ind].components = mergeDefaultPropsComponents(item.components)
    })
  }
  if (schemaComponents[component.name]) {
    // component.properties = _.merge({}, schemaComponents[component.name].properties, component.properties);
    component.properties = _.mergeWith({}, schemaComponents[component.name].properties, component.properties, customizerMerge)

    // migrate css TODO: check VERSION component
    if (schemaSettings[component.name].viewMigration) {
      for (const migrate of schemaSettings[component.name].viewMigration) {
        for (const key in component.css) {
          const type = component.css[key]
          if (type) {
            const from = type.find(({ selector }) => selector === migrate.from)

            if (from) {
              let fromRules = {}
              for (const directive of migrate.directives) {
                if (from.rules && from.rules[directive]) {
                  fromRules[directive] = from.rules[directive]
                  delete from.rules[directive]
                }
              }

              const to = type.find(({ selector }) => selector === migrate.to)

              if (!to) {
                type.push({
                  selector: migrate.to,
                  rules: fromRules,
                })
              } else {
                to.rules = {
                  ...to.rules,
                  ...fromRules,
                }
              }
            }
          }
        }
      }
    }

    /**
     * css: {
     *   all: [
     *     {
     *       selector: ''.
     *       rules: { directive: '', value: '' }
     *     }
     *   ]
     * }
     */
    // for of type css (all, android, ios)
    // for of selectors ({ rules, selector })
    // for of rules ({ directive, value })
  }
  return component
}

export const mergeDefaultPropsComponents = (components) => _.map(components, (component) => mergeDefaultPropsComponent(component))

export const generateUUIDS = (container) => {
  if (!_.isEmpty(container.actions)) {
    // TODO: в будущем появится скорее всего вложенность и потребуется рекурсия
    _.each(container.actions, (action) => {
      action.uuid = uuid.v1()
    })
  }
  if (!_.isEmpty(container.components)) {
    _.each(container.components, (component) => {
      component.uuid = uuid.v1()

      if (!_.isEmpty(component.actions)) {
        // TODO: в будущем появится скорее всего вложенность и потребуется рекурсия
        _.each(component.actions, (action) => {
          action.uuid = uuid.v1()
        })
      }
      if (component.items) {
        _.each(component.items, (item) => {
          item.uuid = uuid.v1()
          generateUUIDS(item)
        })
      }
    })
  }
}
