import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('select', {
    pathValue: 'properties.direction',
    label: 'Direction',
    options: [
      {label: 'Horizontal', value: 'horizontal'},
      {label: 'Vertical', value: 'vertical'},
    ],
    // 'subcontrols': {
    //   'vertical': [
    //     makeControlProps('select', {
    //       pathValue: 'properties.barWidth',
    //       label: 'Tabbar width',
    //       options: [
    //         {label: '1', value: '2'},
    //         {label: '2', value: '3'},
    //         {label: '3', value: '4'},
    //         {label: '4', value: '5'},
    //         {label: '5', value: '6'},
    //         {label: 'Auto', value: 'auto'},
    //       ]
    //     }),
    //   ]
    // }
  }),
  makeControlProps('select', {
    pathValue: 'properties.position',
    label: 'Tabbar position',
    options: [
      {label: 'Top \\ Left', value: 'top'},
      {label: 'Bottom \\ Right', value: 'bottom'},
    ]
  }),
  makeControlProps('input', {
    pathValue: 'properties.activeTab',
    type: 'number',
    min: 1,
    label: 'Active tab',
  }),
  makeControlProps('select', {
    pathValue: 'properties.rememberActiveTab',
    label: 'Remember the active tab when navigating screens',
    options: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.inlineLabel',
    label: 'Inline label and icon',
    options: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ]
  }),
  visibilityProps,
  loopControlProps,
  makeControlProps('items', {
    type: 'collapse',
    label: 'Tab items',
  }),
]

const view = [
  {
    selector: '.mbst-tabs',
    controls: [
      makeControlProps('sizing', {
        label: 'Component sizing',
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height',
        },
        maxHeight: {
          label: 'Max. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-height',
        },
        minHeight: {
          label: 'Min. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-height',
        },
      }),
    ]
  },
  {
    // selector: '.mbst-tabs .q-tab__label',
    selector: '.mbst-tabs .q-tab',
    controls: [
      _.omit(typographyProps, ['align']),
    ]
  },
  {
    selector: '.mbst-tabs .mbst-tabs__tabbar',
    controls: [
      backgroundProps,
      makeControlProps('sizing', {
        label: 'Bar sizing',
        height: {
          label: 'Height',
          measure: ['px', '%', 'em', 'rem', 'vw', 'vh'],
          pathValue: 'height',
        },
      }),
    ]
  },

  {
    selector: '.mbst-tabs .mbst-tabs__tabbar .q-tab--active',
    // label: 'Active tab view',
    controls: [
      Object.assign({}, _.omit(typographyProps, ['style', 'align']), {
        label: 'Active tab view',
      }),
    ]
  },
  {
    selector: '.mbst-tabs .mbst-tabs__tabbar .q-tab--active .q-tab__label',
    // label: 'Active tab view',
    controls: [
      makeControlProps('typography', {
        label: 'Text style',
        style: _.get(typographyProps, ['style'])
      }),
    ]
  },
  {
    selector: '.mbst-tabs .mbst-tabs__tabbar .q-tab--active',
    controls: [
      Object.assign({}, backgroundProps, {
        label: 'Active tab Background',
      }),
    ]
  },

  {
    selector: '_stub',
    controls: [
      makeControlProps('items', {
        type: 'collapse',
        label: 'Tab items',
      }),
    ]
  }

  // {
  //   selector: '.mbst-tabs .q-tabs__content',
  //   controls: [
  //     typographyProps,
  //
  //     makeControlProps('sizing', {
  //       label: 'Bar sizing',
  //       height: {
  //         label: 'Height',
  //         measure: ['px', 'em', 'rem', 'vw', 'vh'],
  //         pathValue: 'height',
  //       },
  //     }),
  //
  //     makeControlProps('items', {
  //       type: 'collapse',
  //       label: 'Tab items',
  //     }),
  //
  //   ]
  // },
]

const actions = []

export default {
  properties,
  view,
  actions
}
