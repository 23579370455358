<i18n src="general/lang/WidgetEditorContentArea.json"></i18n>

<template>
  <div :class="b()">
    <vddl-list
      :class="b('list')"
      :list="components"
      :drop="handlerDrop"
      :allowed-types="['component']"
      :external-sources="true"
    >
      <template v-for="(component, index) in components">
        <WidgetEditorContentAreaComponent
          v-if="component"
          :key="component.uuid"
          :state="component"
          :index="index"
          :type="'component'"
          :list="components"
        />
      </template>

      <vddl-placeholder :class="b('placeholder')">
        {{ $t('WidgetEditorContentArea.placeholder') }}
      </vddl-placeholder>
    </vddl-list>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { uuid } from 'vue-uuid';
  import WidgetEditorContentAreaComponent from './WidgetEditorContentAreaComponent';

  const { mapGetters, mapActions } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-editor-content-area',

    components: {
      WidgetEditorContentAreaComponent,
    },

    computed: {
      ...mapGetters({
        components: 'editor/getComponents',
        selectedComponentUuid: 'editor/getSelectedComponentUuid',
      }),
    },

    methods: {
      ...mapActions({
        addComponent: 'editor/addComponent',
        setSelectedComponentUuid: 'editor/setSelectedComponentUuid',
        setPropertyPaneComponent: 'propertyPane/setComponent',
      }),

      handlerDrop({ index, item }) {
        if (item) {
          const component = item;
          component.uuid = uuid.v1();
          this.addComponent({ index, component });

          if (component.uuid !== this.selectedComponentUuid) {
            this.setSelectedComponentUuid(component.uuid);
            this.setPropertyPaneComponent(component);
          }
        }
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor-content-area
    &__list
      min-height 20px

    &__placeholder
      flex(center, center)
      width 100%
      background #f0f0f0
      border 1px dashed #ccc
      typography(14px, 18px)
      color #4f5154
</style>
