<i18n src="general/lang/ControlFile.json"></i18n>

<template>
  <div :class="classRoot">
    <img :src="files.length ? files[0].url : ''" />
    <div :class="b('container')">
      <!--<input-->
        <!--:id="vId"-->
        <!--type="file"-->
        <!--:name="(!multiple) ? name : null"-->
        <!--:multiple="multiple"-->
        <!--:accept="accept"-->
        <!--:required="required && !attachments.length"-->
        <!--:disabled="disabled"-->
        <!--@change="handlerChange"-->
      <!--/>-->

      <div :class="b('label')">
        {{ label }}
      </div>

      <div
        :class="b('body')"
        v-show="attachments.length"
      >
        <template v-for="(attachment, index) in attachments">
          <div
            :key="index"
            :class="b('item')"
          >
            <input
              v-if="multiple"
              type="hidden"
              :value="attachment.binary"
              :name="`${name}[${index}]`"
            />

            <div
              v-if="showPreview && attachment.isImage"
              :class="b('preview')"
              :style="handlerStylePreview(attachment)"
            >
              <button
                v-show="attachment.binary"
                :class="b('button-remove-item')"
                :title="titleButtonRemoveItem"
                @click.prevent="removeAttachment(index)"
              >
                <IconFileRemove />
              </button>
            </div>

            <div
              v-if="showInfo"
              :class="b('info')"
            >
              {{ attachment.file.name }}
              <br />
              {{ attachment.sizeFormat }}
            </div>
          </div>
        </template>
      </div>

      <div :class="b('controls')">
        <file-upload
          :id="vId"
          ref="upload"
          v-model="files"
          :class="b('button-add', 'btn btn-xs btn-info')"
          :title="(!attachments.length) ? titleButtonAdd : titleButtonAddAdded"
          accept="image/*"
          :name="(!multiple) ? name : null"
          :multiple="multiple"
          :required="required && !attachments.length"
          :disabled="disabled"
          :headers="headers"
          :post-action="endpoint"
          @input-file="inputFile"
        >
          <span v-if="!attachments.length && textButtonAdd">
            {{ textButtonAdd }}
          </span>
          <span v-else>
            {{ textButtonAddAdded }}
          </span>

          <IconPlus
            v-if="multiple && attachments.length"
            width="12"
          />
          <IconRefresh
            v-else-if="attachments.length"
            width="12"
          />
        </file-upload>

        <button
          v-if="(!showPreview || multiple) && attachments.length"
          :class="b('button-remove-all', 'btn btn-xs btn-danger')"
          :title="(!showPreview) ? titleButtonRemoveItem : titleButtonRemoveAll"
          @click.prevent="removeAttachmentAll"
        >
          <span v-if="textButtonRemoveAll">
            {{ textButtonRemoveAll }}
          </span>

          <IconTrash />
        </button>
      </div>
    </div>

    <ServiceMessage
      :class="b('service-message')"
      v-bind="serviceMessageData"
    />
  </div>
</template>

<script>
  import FileUpload from 'vue-upload-component';
  // import logger from 'general/logger';
  import MakeAuth from 'general/utils/MakeAuth';
  // import { convertBytes, isImageFile, preloadFile } from 'general/utils/utils';
  import { replaceSnippets } from 'general/utils/utils';
  import { BASE_URL } from 'general/env';
  import baseMixin from 'general/mixins/baseMixin';
  import serviceMessageMixin from 'general/mixins/serviceMessageMixin';
  import ServiceMessage from 'general/components/ServiceMessage';
  import IconFileRemove from 'general/components/icons/IconFileRemove';
  import IconPlus from 'general/components/icons/IconPlus';
  import IconRefresh from 'general/components/icons/IconRefresh';
  import IconTrash from 'general/components/icons/IconTrash';
  import MakeHttp from 'general/utils/MakeHttp';

  const http = MakeHttp();
  const endpoint = '/api/v8/files/upload/';

  export default {
    name: 'control-file',

    mixins: [
      baseMixin,
      serviceMessageMixin,
    ],

    components: {
      FileUpload,
      ServiceMessage,
      IconFileRemove,
      IconPlus,
      IconRefresh,
      IconTrash,
    },

    model: {
      prop: 'value',
      event: 'update',
    },

    props: {
      name: {
        type: String,
      },
      label: {
        type: String,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: String,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      showPreview: {
        type: Boolean,
        default: true,
      },
      showInfo: {
        type: Boolean,
        default: true,
      },

      previewWidth: {
        type: Number,
      },
      previewHeight: {
        type: Number,
      },

      titleButtonAdd: {
        type: String,
      },
      textButtonAdd: {
        type: String,
        default() {
          return this.$t('ControlFile.textButtonAdd');
        },
      },
      titleButtonAddAdded: {
        type: String,
      },
      textButtonAddAdded: {
        type: String,
        default() {
          return (this.multiple)
            ? this.$t('ControlFile.textButtonAddAddedByMultiple')
            : this.$t('ControlFile.textButtonAddAdded');
        },
      },
      titleButtonRemoveItem: {
        type: String,
        default() {
          return (this.multiple)
            ? this.$t('ControlFile.titleButtonRemoveItemByMultiple')
            : this.$t('ControlFile.titleButtonRemoveItem');
        },
      },
      titleButtonRemoveAll: {
        type: String,
      },
      textButtonRemoveAll: {
        type: String,
      },
    },

    data: () => ({
      headers: null,
      files: [],
      attachments: [],
      endpoint,
      isError: false,
    }),

    computed: {
      tenant() {
        return this.$store.state.widgetSettings.tenant || null;
      },
      server() {
        return this.$store.state.widgetSettings.server || null;
      },

      classRoot() {
        return this.b({
          isMultiple: this.multiple,
          hasValue: !!this.attachments.length,
          isDisabled: this.disabled,
          isError: this.isError,
        });
      },
    },

    created() {
      const auth = MakeAuth({
        tenant: this.tenant,
        server: this.server,
      });
      this.headers = { Authorization: `Bearer ${auth.getAccessToken()}` };
    },

    methods: {
      inputFile(newFile, oldFile) {
        // Update file
        if (newFile && oldFile) {
          // Uploaded successfully
          if (newFile.success && newFile.xhr.response) {
            const response = JSON.parse(newFile.xhr.response);
            const { errors, data } = response;

            if (errors) {
              const list = [];
              errors.forEach(error => list.push(error.title));
              this.setServiceMessage(list.join('\r\n'), this.SERVICE_MESSAGE_LEVEL_ERROR);
              return;
            }

            if (data && data[0]) {
              const src = data[0].attributes.Name;
              this.$emit('update', src);
            }
          }
        }
        // Automatically activate upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (!this.$refs.upload.active) {
            this.$refs.upload.active = true;
          }
        }
      },
      /**
       * Событие обновления инпута
       *
       * @param event
       */
      // handlerChange(event) {
      //   const files = event.target.files || event.dataTransfer.files;
      //
      //   if (!files.length) {
      //     return;
      //   }
      //
      //   [...files].forEach(async (file) => {
      //     if (!this.multiple) {
      //       // hack for clearing browser files
      //       this.attachments.shift();
      //     }
      //     const index = this.attachments.length;
      //     const sizeFormat = convertBytes(file.size, true);
      //     this.attachments.push({
      //       file,
      //       isImage: isImageFile(file),
      //       sizeFormat,
      //       binary: '',
      //     });
      //
      //     this.preload(index, file);
      //     // this.sendFile(index, file);
      //   });
      // },

      /**
       * ...
       *
       * @param {number} index
       * @param {Object} file
       */
      // async preload(index, file) {
      //   const binary = await preloadFile(file);
      //
      //   if (binary) {
      //     this.attachments[index].binary = binary;
      //   }
      // },

      /**
       * Проблема с передачей файла на сервер
       *
       * @param {number} index
       * @param {Object} file
       */
      // async sendFile(index, file) {
      //   const files = new FormData();
      //   files.set(`file[${index}]`, file);
      //   const url = '/files/upload/';
      //   const params = {
      //     // applicationId: 0,
      //     // fileName: '',
      //     files,
      //   };
      //   const response = await http.post(url, params);
      //   const { errors, data } = response;
      //
      //   if (errors) {
      //     const list = [];
      //     errors.forEach(error => list.push(error.title));
      //     this.setServiceMessage(list.join('\r\n'), this.SERVICE_MESSAGE_LEVEL_ERROR);
      //   }
      //
      //   logger.log('response:', response);
      // },

      /**
       * Удаление прикрепленного файла(ов)
       *
       * @param {number} index
       */
      removeAttachment(index) {
        if (this.multiple) {
          this.removeAttachmentByIndex(index);
        } else {
          this.removeAttachmentAll();
        }
      },

      /**
       * Удаление выбранного прикрепленного файла
       *
       * @param {number} index
       */
      removeAttachmentByIndex(index) {
        this.attachments.splice(index, 1);
      },

      /**
       * Удаление всех прикрепленных файлов
       */
      removeAttachmentAll() {
        this.attachments = [];
      },

      /**
       * @param {Object} attachment
       * @returns {Object}
       */
      handlerStylePreview(attachment) {
        return {
          backgroundImage: `url('${attachment.binary}')`,
          previewWidth: this.previewWidth,
          previewHeight: this.previewHeight,
        };
      },
    },
  };
</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-file
    position relative

    &_is-multiple
      //

    &_has-value
      //

    &_is-error
      //

    svg
      svg-child()

    input[type=file]
      display block
      width 100%
      height 1px
      position absolute
      right 0
      bottom 0
      left 0
      opacity 0

    &__container
      flex(space-between, center)
      flex-wrap wrap

    &__label
      display block
      typography(14px, 18px)

      ~/_has-value &
        width 100%
        margin-bottom 10px

      ~/_is-error &
        color $color-red

    &__body
      display flex
      flex-wrap wrap
      width 100%
      margin-bottom 10px

      ~/_is-multiple &
        margin-x(-10px)

    &__item
      flex-shrink 0
      width 100%
      position relative

      ~/_is-multiple &
        margin-x(10px)
        margin-bottom 20px

        +is-last()
          margin-bottom 0

    &__preview
      display block
      height 160px
      bg-image(cover)
      position relative

    &__info
      border-left 4px solid $color-inverse
      padding-left 8px
      typography(12px, 16px)

      ~/__preview + &
        margin-top 10px

    &__button-remove-item
      reset-button()
      flex(center, center)
      width 32px
      height 32px
      link()
      state-background(rgba($brand-danger, .35), $brand-danger)
      state-color(rgba(#fff, .35), #fff)
      position absolute
      top 0
      right 0
      z-index 1

    &__controls
      display flex
      flex-wrap wrap
      margin-bottom auto

      ~/_has-value &
        width 100%

    &__button-add
    &__button-remove-all
      flex(center, center)
      margin-bottom 0 !important

      span + svg,
      svg + span
        margin-left 8px

    &__button-remove-all
      margin-left 15px

    &__service-message
      margin-top 10px
</style>
