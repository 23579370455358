export default {
  uuid: null,
  type: '',
  name: 'w-image',
  aliasName: 'WImage',
  iconPreview: {
    component: 'IconComponentWImage',
    width: 40,
  },
  properties: {
    src: '',
  },
  actions: {},
};
