var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _c(
      "div",
      { class: _vm.b("row", { description: true }) },
      [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("WidgetSettingsScenariosHead.titleRowDescription"))
          ),
        ]),
        _vm._v(" "),
        _c("HintHelper", {
          attrs: {
            content: _vm.$t("WidgetSettingsScenariosHead.hintRowDescription"),
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("row", { conditions: true }) },
      [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("WidgetSettingsScenariosHead.titleRowConditions"))
          ),
        ]),
        _vm._v(" "),
        _c("HintHelper", {
          attrs: {
            content: _vm.$t("WidgetSettingsScenariosHead.hintRowConditions"),
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("row", { where: true }) },
      [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("WidgetSettingsScenariosHead.titleRowWhere"))),
        ]),
        _vm._v(" "),
        _c("HintHelper", {
          attrs: {
            content: _vm.$t("WidgetSettingsScenariosHead.hintRowWhere"),
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("row", { when: true }) },
      [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("WidgetSettingsScenariosHead.titleRowWhen"))),
        ]),
        _vm._v(" "),
        _c("HintHelper", {
          attrs: { content: _vm.$t("WidgetSettingsScenariosHead.hintRowWhen") },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("row", { counters: true }) },
      [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("WidgetSettingsScenariosHead.titleRowCounters"))
          ),
        ]),
        _vm._v(" "),
        _c("HintHelper", {
          attrs: {
            content: _vm.$t("WidgetSettingsScenariosHead.hintRowCounters"),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }