import { LocalStorage } from 'quasar'
import set from 'lodash/set'

import MakeEventBus from 'general/utils/MakeEventBus';
const EventBus = MakeEventBus('root');

function ToursController (context) {

  const _self = Object.assign({}, context)
  const _tours = {}

  const key = 'tour'
  const startGuide = LocalStorage.getItem('startGuide')

  const normalizeGuideName = (name) => {
    name = name.split('_').map(el => el[0].toUpperCase() + el.slice(1))
    return name.join('');
  }

  _self.getTourOptions = (routeName, tourName) => {
    const toursData = LocalStorage.getItem(key) || {}
    let data = (toursData[routeName] || []).find(guide => guide.name === tourName);
    if (data) {
      data = Object.assign({ isVisit: true, name: 'n/a', props: {} }, data)
    }
    return data
  }

  _self.setTourOptions = (routeName, tourName, path, options) => {
    let toursData = LocalStorage.getItem(key) || {}
    let data = (toursData[routeName] || []).find(guide => guide.name === tourName);
    if (!data) return;
    set(data, path, options);
    LocalStorage.set(key, toursData);
  }

  _self.getTour = async (routeName, tourName) => {
    const data = _self.getTourOptions (routeName, tourName)

    const tourFileNme = normalizeGuideName(tourName)
    const tourCacheName = `${routeName}-${tourName}`

    if (!_tours[tourCacheName]) {
      try {
        const mod = await import(`./${tourFileNme}.js`)
        _tours[tourCacheName] = mod.default.apply(_self)
      } catch (err) {
        console.log("Error module loading", err);
        return null
      }
    }

    return _tours[tourCacheName]
  }

  _self.run = (tour) => {
    EventBus.$emit('root:startTour', tour);
  }

  return _self
}

export default ToursController
