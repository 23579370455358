<template>
  <WidgetModalInnerBase
    :class="b()"
    :editor-mode="true"
    :header="jsonData.modal.header"
    :footer="jsonData.modal.footer"
  >
    <WidgetEditorContentArea />
  </WidgetModalInnerBase>
</template>

<script>
  import WidgetModalInnerBase from 'general/components/WidgetModalInnerBase';
  import WidgetEditorContentArea from './WidgetEditorContentArea';

  export default {
    name: 'widget-editor-demo-modal',

    components: {
      WidgetEditorContentArea,
      WidgetModalInnerBase,
    },

    props: {
      jsonData: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor-demo-modal
    box-shadow 0 20px 60px -2px rgba(27, 33, 58, 0.4)
</style>
