import {
  makeControlProps,
  backgroundProps,
  typographyProps,
  $t
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.label',
    label: 'Label'
  }),
  makeControlProps('input', {
    pathValue: 'properties.icon',
    label: 'Icon class',
    placeholder: 'fas fa-home',
    hint: {
      text: $t('general.iconsPackageHint'),
      action: () => {  window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank') },
    },
    extractClass: true,
  }),
]

const view = [
  {
    selector: '.mbst-tabs__tab',
    controls: [
      typographyProps,
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
    ],
  },
]

const actions = []

export default {
  properties,
  view,
  actions,
}
