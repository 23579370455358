var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-grey-1", class: _vm.b() }, [
    _c(
      "div",
      { staticClass: "q-pa-lg", class: _vm.b("container") },
      [
        _c(
          "q-breadcrumbs",
          {
            staticClass: "text-h6 text-weight-regular col-auto- q-mb-lg",
            scopedSlots: _vm._u([
              {
                key: "separator",
                fn: function () {
                  return [
                    _c("q-icon", {
                      attrs: {
                        size: "1.25em",
                        name: "chevron_right",
                        color: "primary",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            _c("q-breadcrumbs-el", {
              class: { "cursor-pointer": _vm.isSystemWidgets },
              attrs: { label: "Widgets" },
              on: {
                click: function ($event) {
                  _vm.isSystemWidgets = false
                },
              },
            }),
            _vm._v(" "),
            _vm.isSystemWidgets
              ? _c("q-breadcrumbs-el", { attrs: { label: "System Widgets" } })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.isSystemWidgets
          ? _c("div", [
              _c("div", { staticClass: "row q-col-gutter-lg" }, [
                _c(
                  "div",
                  { staticClass: "col-xl-3 col-lg-4 col-sm-6" },
                  [
                    _c(
                      "q-card",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "full-height cursor-pointer",
                        on: {
                          click: function ($event) {
                            _vm.isSystemWidgets = true
                          },
                        },
                      },
                      [
                        _c("q-card-section", [
                          _c("div", { staticClass: "text-h6" }, [
                            _vm._v("System Widgets"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-body1 text-grey-7" }, [
                            _vm._v(
                              "This section manages app saving and push prompts for the applications you build on Mobsted from scratch."
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xl-3 col-lg-4 col-sm-6" },
                  [
                    _c(
                      "q-card",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "full-height cursor-pointer",
                        on: { click: _vm.onGoToPwaLessWidgets },
                      },
                      [
                        _c(
                          "q-card-section",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            staticClass: "full-height cursor-pointer",
                          },
                          [
                            _c("div", { staticClass: "text-h6" }, [
                              _vm._v("PWA JS Capsule Widgets"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "text-body1 text-grey-7" },
                              [
                                _vm._v(
                                  "This section hosts app saving and push request prompts for the PWA JS Capsule, only."
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _c(
              "div",
              [
                !_vm.configurableWidgetType
                  ? _c("WidgetSettingsTypes")
                  : _vm._e(),
                _vm._v(" "),
                _vm.configurableWidgetType ? _c("WidgetEditor") : _vm._e(),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }