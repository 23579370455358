<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 93.87 128"
    :width="width"
    :height="height"
  >
    <g>
      <path
        d="M64 29.87V0H21.33v102.4h72.54V29.87H64z"
      ></path>
      <path
        d="M72.53 0v21.33h21.34L72.53 0zM12.8 110.93V25.6H0V128h72.53v-17.07H12.8z"
      ></path>
    </g>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-copy',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
