import { backgroundDefault } from './helper';
import flexCol from './flexRowCol';

export default {
  name: 'mbst-flexrow',
  aliasName: 'Grid 2x2',
  properties: {
    backendname: 'flexrow',
    direction: 'row',
    wrap: 'wrap',
    justify: '',
    valign: '',
    outerspacing: '',
    innerspacing: '',
    shadow: '',
    shadowPosition: '',
    navigationControls: false,
    toolbar: '',
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  meta: {
    outlineCells: true,
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-row',
        rules: [
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-row',
        rules: [
          ...backgroundDefault,
        ]
      }
    ]
  },
  items: [
    flexCol,
    flexCol,
    flexCol,
    flexCol
  ]
}
