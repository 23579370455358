var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b({ isActive: _vm.jsonData.active }) }, [
    _c("div", { class: _vm.b("wrap") }, [
      _c(
        "div",
        { class: _vm.b("controls") },
        [
          _c(
            "button",
            {
              class: _vm.b("button-toggle", { isActive: _vm.jsonData.active }),
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggle.apply(null, arguments)
                },
              },
            },
            [_c("q-icon", { attrs: { name: "fas fa-check" } })],
            1
          ),
          _vm._v(" "),
          !_vm.isEditing
            ? _c(
                "button",
                {
                  class: _vm.b("button-edit", {
                    isBlink: _vm.isButtonEditBlink,
                  }),
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.edit.apply(null, arguments)
                    },
                  },
                },
                [_c("q-icon", { attrs: { name: "fas fa-pen" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isEditing
            ? [
                _c(
                  "button",
                  {
                    class: _vm.b("button-save"),
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.apply.apply(null, arguments)
                      },
                    },
                  },
                  [_c("q-icon", { attrs: { name: "far fa-save" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: _vm.b("button-cancel"),
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cancel.apply(null, arguments)
                      },
                    },
                  },
                  [_c("q-icon", { attrs: { name: "far fa-times" } })],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.b("button-delete"),
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.deleteItem.apply(null, arguments)
                },
              },
            },
            [_c("q-icon", { attrs: { name: "far fa-trash-alt" } })],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isEditing
        ? _c("div", {
            class: _vm.b("mask"),
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handlerDisabledControls.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("row", { description: true }) },
        [
          _c("ControlTextarea", {
            attrs: {
              "label-before": _vm.$t(
                "WidgetSettingsScenariosItem.descriptionLabel"
              ),
              disabled: !_vm.isEditing,
            },
            model: {
              value: _vm.jsonData.description,
              callback: function ($$v) {
                _vm.$set(_vm.jsonData, "description", $$v)
              },
              expression: "jsonData.description",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("row", { conditions: true }) },
        [
          _c("div", { class: _vm.b("condition-box") }, [
            _c("div", { class: _vm.b("condition-box-name") }),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.b("condition-box-enabled") },
              [
                _c("HintHelper", {
                  attrs: {
                    content: _vm.$t(
                      "WidgetSettingsScenariosItem.conditions.hintEnabled"
                    ),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.b("condition-box-installed") },
              [
                _c("HintHelper", {
                  attrs: {
                    content: _vm.$t(
                      "WidgetSettingsScenariosItem.conditions.hintInstalled"
                    ),
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.jsonData.conditions, function (condition, key) {
            return [
              condition
                ? _c("div", { key: key, class: _vm.b("condition-box") }, [
                    _c("div", { class: _vm.b("condition-box-name") }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.channelNames[key]) +
                          ":\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.b("condition-box-enabled") },
                      [
                        _c("ControlCheckbox", {
                          attrs: { disabled: !_vm.isEditing },
                          model: {
                            value: condition.enabled,
                            callback: function ($$v) {
                              _vm.$set(condition, "enabled", $$v)
                            },
                            expression: "condition.enabled",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.b("condition-box-installed") },
                      [
                        _c("ControlSwitch", {
                          attrs: {
                            disabled: !_vm.isEditing || !condition.enabled,
                            labels: true,
                          },
                          model: {
                            value: condition.installed,
                            callback: function ($$v) {
                              _vm.$set(condition, "installed", $$v)
                            },
                            expression: "condition.installed",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _c("div", {
            class: _vm.b("condition-box-hint"),
            domProps: { innerHTML: _vm._s(_vm.conditionsHint) },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("row", { where: true }) },
        [
          _c("ControlSelect", {
            attrs: {
              "label-before": _vm.$t(
                "WidgetSettingsScenariosItem.where.type.label"
              ),
              options: _vm.whereTypeOptions,
              disabled: !_vm.isEditing,
            },
            model: {
              value: _vm.jsonData.where.type,
              callback: function ($$v) {
                _vm.$set(_vm.jsonData.where, "type", $$v)
              },
              expression: "jsonData.where.type",
            },
          }),
          _vm._v(" "),
          _vm.jsonData.where.type ? _c("br") : _vm._e(),
          _vm._v(" "),
          _vm.jsonData.where.type === _vm.TYPE_WHERE_SCREEN
            ? _c("ControlSelect", {
                attrs: {
                  "label-before": _vm.$t(
                    "WidgetSettingsScenariosItem.where.value.label"
                  ),
                  options: _vm.whereTypeScreensOptions,
                  disabled: !_vm.isEditing,
                },
                model: {
                  value: _vm.jsonData.where.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.jsonData.where, "value", $$v)
                  },
                  expression: "jsonData.where.value",
                },
              })
            : _vm.jsonData.where.type === _vm.TYPE_WHERE_ACTION
            ? _c("ControlSelect", {
                attrs: {
                  "label-before": _vm.$t(
                    "WidgetSettingsScenariosItem.where.value.label"
                  ),
                  options: _vm.whereTypeActionsOptions,
                  disabled: !_vm.isEditing,
                },
                model: {
                  value: _vm.jsonData.where.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.jsonData.where, "value", $$v)
                  },
                  expression: "jsonData.where.value",
                },
              })
            : _vm.jsonData.where.type === _vm.TYPE_WHERE_HIT
            ? _c("ControlCounter", {
                attrs: {
                  "label-before": _vm.$t(
                    "WidgetSettingsScenariosItem.where.value.label"
                  ),
                  min: 0,
                  disabled: !_vm.isEditing,
                },
                model: {
                  value: _vm.jsonData.where.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.jsonData.where, "value", _vm._n($$v))
                  },
                  expression: "jsonData.where.value",
                },
              })
            : _vm.jsonData.where.type
            ? _c("ControlInput", {
                attrs: {
                  "label-before": _vm.$t(
                    "WidgetSettingsScenariosItem.where.value.label"
                  ),
                  disabled: !_vm.isEditing,
                },
                model: {
                  value: _vm.jsonData.where.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.jsonData.where, "value", $$v)
                  },
                  expression: "jsonData.where.value",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("row", { when: true }) },
        [
          _c("ControlCounter", {
            attrs: {
              "label-before": _vm.$t("WidgetSettingsScenariosItem.whenLabel"),
              min: 0,
              disabled: !_vm.isEditing,
            },
            model: {
              value: _vm.jsonData.delay,
              callback: function ($$v) {
                _vm.$set(_vm.jsonData, "delay", _vm._n($$v))
              },
              expression: "jsonData.delay",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("row", { counters: true }) },
        [
          _c("ControlCounter", {
            attrs: {
              "label-before": _vm.$t(
                "WidgetSettingsScenariosItem.countersTotalLabel"
              ),
              min: 0,
              disabled: !_vm.isEditing,
            },
            model: {
              value: _vm.jsonData.counters.total,
              callback: function ($$v) {
                _vm.$set(_vm.jsonData.counters, "total", _vm._n($$v))
              },
              expression: "jsonData.counters.total",
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("ControlCounter", {
            attrs: {
              "label-before": _vm.$t(
                "WidgetSettingsScenariosItem.countersInSessionLabel"
              ),
              min: 0,
              disabled: !_vm.isEditing,
            },
            model: {
              value: _vm.jsonData.counters.inSession,
              callback: function ($$v) {
                _vm.$set(_vm.jsonData.counters, "inSession", _vm._n($$v))
              },
              expression: "jsonData.counters.inSession",
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-xs btn-danger",
              class: _vm.b("button-reset-counters"),
              attrs: { disabled: !_vm.isEditing },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.resetCounters.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("WidgetSettingsScenariosItem.buttonResetCounters")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }