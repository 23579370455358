/*global PRODUCTION, $, window, MOBSTEDAPP, document, intlTelInputUtils */

// Logger
import logger from 'general/logger';

// Libraries
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import _ from 'underscore';
import InputMask from 'inputMask';
import jstz from 'jstz';
import ClientApp from './src/app-init';

// Collections
const ApiProvidersCollection = require('collections/ApiProvidersCollection');

// Views
const SmartFiltersView = require('views/smart-filters/SmartFiltersView');

// set global data
window.MOBSTEDAPP = window.MOBSTEDAPP || {};
window.inputMask = InputMask;
window.ClientApp = ClientApp;
window.jstz = jstz;
window.SmartFiltersView = SmartFiltersView;

require('refactor/main');


$(document).ready(function() {

  // Канатольевское говно
  $('#findac').click(function () {
    $('#selectac').hide();
    $('#searchac').show();
  });

  $('.newac').click(function () {
    $('#selectac').hide();
    $('#login').show();
    $('#searchac').hide();
  });

  $('#backtolist').click(function (e) {
    e.preventDefault();
    $('#selectac').show();
    $('#login').hide();
  });

  $('.tooltips, [data-toggle="tooltip"]').tooltip({container: 'body'});
  // Get API Providers
  if (MOBSTEDAPP.APIProviders) {
    MOBSTEDAPP.APIProviders = _.reduce(MOBSTEDAPP.APIProviders, function (res, apiprovider, index, list) {
      apiprovider.isActive = false;
      const obj = _.find(res, function (item) {
        return item.id === apiprovider.APIProviderId;
      });
      if (!obj) {
        const te = {
          id: apiprovider.APIProviderId,
          isActive: false,
          APIProviderName: apiprovider.APIProviderName,
            APIProviderIconPath: apiprovider.APIProviderIconPath,
          APIProviderMethods: [],
        };
        if (apiprovider.id)
          te.APIProviderMethods.push(_.omit(apiprovider, ['APIProviderId', 'APIProviderName', 'APIProviderIconPath']));
        res.push(te);
      } else {
        if (apiprovider.id)
          obj.APIProviderMethods.push(_.omit(apiprovider, ['APIProviderId', 'APIProviderName', 'APIProviderIconPath']));
      }
      return res;
    }, []);
  }

  // inline js code login page
  $('.front-page .login-box__wr').addClass('login-box__show');
  var tz = jstz.determine();
  $('#detectedtimezone').val(tz.name());
  // end

  // накидываем маски для телефонов

  /*$("[data-js-mask=\"phone\"]").inputmask({"mask": "+7 999 999-99-99"});
  $("[data-column=\"Phone\"] input").inputmask({"mask": "+7 999 999-999-999"}); // грид обектов*/

  /**
   *  Обработка номера телефона на форме авторизации
   */
  function setPhoneMask(el) {
    if (el) {
      el.intlTelInput({
        // utilsScript: u,
        onlyCountries: [
          'AF', 'AX', 'AL',
          'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM',
          'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC',
          'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET',
          'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN',
          'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ',
          'KE', 'KI', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT',
          'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'AN', 'NC', 'NZ',
          'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE',
          'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SK', 'SI', 'SB',
          'SO', 'ZA', 'GS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN',
          'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW',
        ],
        separateDialCode: true,
        placeholderNumberType: 'mobile',
        initialCountry: 'auto',
        autoPlaceholder: false,
        autoHideDialCode: false,
        formatOnDisplay: false,
        nationalMode: false,
        hiddenInput: 'fphone',
        geoIpLookup: function (callback) {
          $.get('https://ipinfo.io', function () {}, 'jsonp')
            .always(function (resp) {
              const countryCode = (resp && resp.country) ? resp.country : '';
              callback(countryCode);
            });
        },
      })
        .done(function () {
          let maskPhone = intlTelInputUtils.getExampleNumber(el.intlTelInput('getSelectedCountryData').iso2, intlTelInputUtils.numberType.RFC3966, intlTelInputUtils.numberType.MOBILE);

          maskPhone = maskPhone.replace(/^\+\d{1,4} /gm, '');
          maskPhone = maskPhone.replace(/[0123456789]/g, '9');

          el.inputmask({'mask': maskPhone});

          el.on('countrychange', function (e, countryData) {
            let maskPhone = intlTelInputUtils.getExampleNumber(countryData.iso2, intlTelInputUtils.numberType.RFC3966, intlTelInputUtils.numberType.MOBILE);
            maskPhone = maskPhone.replace(/^\+\d{1,4} /gm, '');
            maskPhone = maskPhone.replace(/[0123456789]/g, '9');

            el.inputmask({'mask': maskPhone});
          });
        });
    } else {
      console.log('el not found or not set');
    }
  }

  const $inputAdminAccount = $('#admin-phone');
  const $inputUserAccount = $('#user-account');

  if ($inputAdminAccount.length > 0) {
    setPhoneMask($inputAdminAccount);
  }
  if ($inputUserAccount.length > 0) {
    setPhoneMask($inputUserAccount);
  }

  $('#login-button').on('click', function () {
    if ($('#user-account').intlTelInput('isValidNumber')) {
      return true;
    } else {
      $('#user-account').addClass('error');
      return false;
    }
  });

  $('#btn-login-logintap').on('click', function () {
    $('#alert').text('').css('display', 'none');
    $.ajax({
      url: '/users/loginbylogintap',
      data: {
        login: $('#admin-email').val()
      },
      success: function (data) {
        $('#logintap-spinner').css('display', 'block');
        let sessionUUID = data.meta.sessionUUID;
        let checkTimer = setInterval(check, 1000);
        let host = window.location.hostname;

        function check() {
          $.ajax({
            url: '/users/logintaplogin',
            data: {
              sessionUUID: sessionUUID
            },
            success: function (data) {
              console.log(data);
              let parsed = JSON.parse(data);
              if (parsed.meta.state != 'No action by user') {
                clearInterval(checkTimer);

                if (parsed.meta.state == 'Yes') {
                  document.location.href = '/bc/index';
                }

                if (parsed.meta.state == 'No') {
                  $('#logintap-spinner').css('display', 'none');
                  $('#alert').text('Rejected by user').css('display', 'block');
                }

                if (parsed.meta.state == 'Unauthorized') {
                  $('#logintap-spinner').css('display', 'none');
                  $('#alert').text(parsed.meta.state).css('display', 'block');
                }
              }
            }
          });
        }
      },
      error: function (data) {
        $('#alert').text(data.responseJSON.errors[0].title).css('display', 'block');
      }
    });
  });

  $('#login-admin').on('submit', function () {
    if (
      !$('#password')
//      || !$('#password').val()
//      || $('#password').length === 0
      || $('#password').val().length === 0
    ) {
      $('#password').addClass('error');
      //alert('please cnfirm');
      $('#password').parent().css({"border-color": "#F00",
        "border-weight":"3px",
        "border-style":"solid"});
      return false;
    } else
    if (!$('#admin-email').val() && !$('#admin-phone').val()) {
      $('#admin-phone').addClass('error');
      $('#admin-email').addClass('error');
      return false;
    } else if (!$('#admin-phone').intlTelInput('isValidNumber') && $('#admin-email').length === 0) {
      $('#admin-phone').addClass('error');
      return false;
    } else if ($('#admin-email').length === 0) {
      $('#admin-email').addClass('error');
      return false;
    }
  });

  $('#reset-pass').on('click', function () {
    return $('#login').intlTelInput('isValidNumber');
  });


  // дублирование кода это очень мило (сохранение данных в гриде объектов только для телефона, ибо из-за плагина маски не работает прошлый листинер)
  $(document).on('change', '[data-column="Phone"] input', function (e) {
    const name = $(this).data('name');
    const url = $(this).data('url');
    const appid = $(this).data('appid');
    const depends = $(this).data('depends');
    const haschild = $(this).data('haschild');
    const elem = this;
    let value = $(this).serialize();
    let obj = null;

    /// Обработка Enabled в объектах, когда убирается чек с чекбокса
    if ((name == 'Enabled') && (value == '')) {
      value = 'Enabled=0&currentApp=' + appid;
    }
    if ((name == 'Enabled') && (value == 'Enabled=1')) {
      value = 'Enabled=1&currentApp=' + appid;
    }

    $.ajax({
      url: url,
      type: 'POST',
      data: {value: value},
      beforeSend: function (xhr) {
      },
    }).done(function (a, b, c) {
      try {
        obj = JSON && JSON.parse(a) || $.parseJSON(a);
        if (obj.error === '') {
          $(elem).addClass('green');
          $(elem).removeClass('red');
          // отключено до момента переделки зависимостей по-новому
          //if ((depends != undefined && depends !== "") || (haschild != undefined && haschild !== "0")) {
          //    console.log(depends);
          //    $.gritter.add({
          //        title: lang('Please refresh grid!'),
          //        text: obj.error,
          //        class_name: 'growl-warning',
          //        sticky: false,
          //        time: ''
          //    });
          //}
          if ((name == 'Enabled') && (value == 'Enabled=1&currentApp=' + appid)) {
            $.fn.yiiGridView.update('my-model-grid');
          }
        } else {
          $(elem).removeClass('green');
          $(elem).addClass('red');
        }
      } catch (e) {
        $(elem).removeClass('green');
        $(elem).addClass('red');
      }
    }).fail(function (a, b, c) {
      $(elem).removeClass('green');
      $(elem).addClass('red');
    }).always(function (a, b, c) {
    });
  });

  const smartFiltersView = new window.SmartFiltersView();

  // load scripts /apiproviders/index
  if (MOBSTEDAPP.ApiProviders) {
    require.ensure(['views/apiproviders/grid/ApiProvidersView'], function (require) {
      const ApiProvidersView = require('views/apiproviders/grid/ApiProvidersView');
      const apiProvidersView = new ApiProvidersView({
        collection: new ApiProvidersCollection(MOBSTEDAPP.ApiProviders),
      });
      $('#apiProvidersSet').html(apiProvidersView.$el);
    }, 'ApiProvidersView');
  }

});
