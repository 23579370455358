import WTextSchema from './WTextSchema';
import WImageSchema from './WImageSchema';
import WBotsSchema from './WBotsSchema';
import WA2hsSchema from './WA2hsSchema';
import WVcardSchema from './WVcardSchema';

export default [
  WTextSchema,
  WImageSchema,
  WBotsSchema,
  WA2hsSchema,
  WVcardSchema,
];
