var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b({ line: _vm.isDeferredA2HS }) },
    [
      _vm.editorMode ? _c("div", { class: _vm.b("mask") }) : _vm._e(),
      _vm._v(" "),
      [
        _c("div", { class: _vm.b("presentation") }, [
          _c("div", { class: _vm.b("app-info") }, [
            _c(
              "div",
              { class: _vm.b("app-icon") },
              [
                _vm.appLogo.style
                  ? _c("div", {
                      class: _vm.b("app-logo"),
                      style: _vm.appLogo.style,
                    })
                  : _vm.appLogo.component
                  ? _c(_vm.appLogo.component, {
                      tag: "component",
                      attrs: { width: "40" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.b("app-name") }, [
              _vm._v("\n          " + _vm._s(_vm.appName) + "\n        "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.b("icon-arrow") },
            [_c("IconArrowCurvedRight", { attrs: { width: "40" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.b("icon-smartphone") },
            [_c("IconSmartphone", { attrs: { width: "48" } })],
            1
          ),
        ]),
        _vm._v(" "),
        !_vm.isDeferredA2HS
          ? _c(
              "button",
              {
                class: _vm.b("button-action"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showInstruction.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { class: _vm.b("icon-play") },
                  [_c("IconPlayCircle", { attrs: { width: "30" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v("\n         " + _vm._s(_vm.buttonText) + "\n      "),
                ]),
              ]
            )
          : _c(
              "button",
              {
                class: _vm.b("button-a2hs"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onA2HSPromt.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("WidgetWA2hs.add")) + "\n    "
                ),
              ]
            ),
      ],
      _vm._v(" "),
      !_vm.editorMode
        ? _c(
            "modal",
            {
              staticClass: "w-a2hs-modal",
              attrs: {
                name: _vm.modalCode,
                adaptive: true,
                clickToClose: false,
                height: "auto",
                transition: "modal-pop-inout",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "w-a2hs-modal__button-close-instruction",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.hideInstruction.apply(null, arguments)
                    },
                  },
                },
                [_c("IconClose", { attrs: { width: "16" } })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-a2hs-modal__instruction" }, [
                _c("img", { attrs: { src: _vm.instructionPath, alt: "" } }),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }