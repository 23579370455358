'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Views
var TriggerOperationSettingsView = require('views/TriggerOperationSettingsView');

// Templates
var _triggerOperationItem = require('text!bcTemplates/trigger-operations/triggerOperationItem.html');

// Models
var OperationModel = require('models/OperationModel');
var TriggerOperationModel = require('models/TriggerOperationModel');
var TriggerOperationItemModel = require('models/TriggerOperationItemModel');

module.exports = Backbone.View.extend({

  tagName: 'tr',

  events: {
    'click td>a': 'editTriggerOperation',
    'click [data-js-toggle="editTriggerOperation"]': 'editTriggerOperation',
    'click [data-js-toggle="deleteTriggerOperation"]': 'deleteTriggerOperation',
  },


  initialize: function (options) {

    this.triggerOperationsView = options.triggerOperationsView;
    this.tpl = _.template(_triggerOperationItem);
    this.type = options.type || 'onDefault';
    this.offset = options.offset || 0;

  },

  render: function () {

    var t = this;

    t.$el.html(t.tpl({
      type: t.type,
      offset: t.offset,
      triggerOperation: t.model.toJSON(),
    }));

  },

  close: function () {
    this.undelegateEvents();
    this.$el.removeData().unbind();
    this.remove();
  },

  editTriggerOperation: function (e) {
    e.preventDefault();

    this.triggerOperationSettingsView = new TriggerOperationSettingsView({
      operationView: this,
      operationsView: this.triggerOperationsView.operationsView,
      model: this.model.get('operation')
    });

    this.triggerOperationSettingsView.render();
  },

  deleteTriggerOperation: function (e) {
    e.preventDefault();

    var t = this;

    t.model.destroy({

      success: function (model, response, options) {
        if (response && _.isEmpty(response.error)) {
          t.triggerOperationsView.triggerView.fetchTriggerOperations();
          // t.close();
        }
      },
      error: function (model, xhr, options) {

      }

    });
  },

  // Сохранение настроек операции триггера
  save: function () {
    var t = this;
    console.log('Model 123123213:');
    console.log(t.model);
    t.saveOperation();
    /*
    t.model.save({}, {
        success: function(m, response) {

            if (response && _.isEmpty(response.error)) {
                if (response.operationId && response.triggerOperationId) {
                    var tempModel = t.model.toJSON();
                    tempModel.id = response.operationId;
                    tempModel.operation.id = response.operationId;
                    tempModel.triggerOperation.id = response.triggerOperationId;
                    t.model.clear();
                    t.model.set(_.omit(tempModel, ['operationId', 'error', 'triggerOperationId']));
                    t.triggerOperationsView.collection.add(t.model);
                }
                t.triggerOperationsView.render();
                grit(lang('Save'), 'growl-success');
            } else {
                grit(lang('Error save'), 'growl-warning');
            }

        },
        error: function(m, response) {
            grit(lang('Error save'), 'growl-warning');
            console.log(response);
        },
        complete: function(m, response) {
            //console.log(response);
        }
    });*/
  },

  saveOperation: function () {
    var t = this,
      tempOperationModel = new OperationModel(t.model.get('operation'));

    // сохраним изменения самой операции (table name: operations)
    tempOperationModel.save({}, {
      success: function (model, response) {
        t.saveTriggerOperation();
      }
    });
  },

  saveTriggerOperation: function () {
    var t = this;

    var triggerOperation = t.model.get('triggerOperation');

    if (t.model.get('success')) {
      var successTriggerOperationModel = new TriggerOperationModel();
      successTriggerOperationModel.set(t.model.get('success').triggerOperation);
      // сохраним сохраним/создадим запись в таблице triggeroperations
      successTriggerOperationModel.save({}, {
        success: function(model, response) {
          triggerOperation.SuccessOperationId = model.get('id');
          t.model.set('triggerOperation', triggerOperation);
          // запишем ссылку на операцию, в апи не реализовано, делаем в несколько запросов
          t._save(new TriggerOperationModel(triggerOperation));
        }
      })
      console.log('TreeView:', t.triggerOperationsView);
    } else {
      triggerOperation.SuccessOperationId = null;
      t.model.set('triggerOperation', triggerOperation);
      // запишем ссылку на операцию, в апи не реализовано, делаем в несколько запросов
      t._save(new TriggerOperationModel(triggerOperation));
    }

    if (t.model.get('fail')) {
      var failTriggerOperationModel = new TriggerOperationModel();
      failTriggerOperationModel.set(t.model.get('fail').triggerOperation);
      // сохраним сохраним/создадим запись в таблице triggeroperations
      failTriggerOperationModel.save({}, {
        success: function(model, response) {
          triggerOperation.FailOperationId = model.get('id');
          t.model.set('triggerOperation', triggerOperation);
          // запишем ссылку на операцию, в апи не реализовано, делаем в несколько запросов
          t._save(new TriggerOperationModel(triggerOperation));
        }
      })
    } else {
      triggerOperation.FailOperationId = null;
      t.model.set('triggerOperation', triggerOperation);
      // запишем ссылку на операцию, в апи не реализовано, делаем в несколько запросов
      t._save(new TriggerOperationModel(triggerOperation));
    }

  },
  _save: function (model) {
    var t = this;
    model.save({}, {
      success: function (m, response) {
        t.triggerOperationSettingsView.$el.find('#triggerOperationCancel').attr('disabled', false);
        t.triggerOperationSettingsView.$el.find('#triggerOperationSave').attr('disabled', false);
      }
    });
  }


});