'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/apiproviders/apiprovidersapi",

    defaults: {
        id: null,
        Name: 'NewApiProvider',
        Description: null,
        IconUrl: null,
        AuthenticationDoc: null,
        BaseUrl: null,
        Active: true,
        LastModified: null,
        IconPath: null
    },

    parse: function(response, options) {
        return _.omit(response, 'error', 'useErrorText');
    },

    initialize: function() {
    }

});