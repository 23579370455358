import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.value',
    label: 'Default Value'
  }),
  makeControlProps('input', {
    pathValue: 'properties.placeholder',
    label: 'Placeholder'
  }),
  makeControlProps('inputType', {
    pathValue: 'properties.inputType',
    label: 'Input type',
    subcontrols: {
      'text': [
        makeControlProps('input', {
          pathValue: 'properties.mask',
          label: 'Input mask',
          hint: '# - digit, S - Letter,<br/>\r\n N - alphanumeric'
        }),
      ]
    }
  }),
  visibilityProps,
  loopControlProps
];

const view = [
  {
    selector: '.mbst-text-input .text-input',
    controls: [
      typographyProps,
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps
    ]
  }
];

const actions = []

export default {
  properties,
  view,
  actions
}
