export default class LoggerStrategy {

  constructor() {}

  /**
   * @param {*} name
   * @param {*} data
   */
  log(name, data) {}

  /**
   * @param {*} name
   * @param {*} data
   */
  info(name, data) {}

  /**
   * @param {*} name
   * @param {*} data
   */
  warn(name, data) {}

  /**
   * @param {*} name
   * @param {*} data
   */
  error(name, data) {}

  /**
   * @param {Error} error
   * @param {*} [data=null]
   */
  exception(error, data = null) {}

}
