<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 74.46 128"
    :width="width"
    :height="height"
  >
    <path
      d="M65.83 0H8.63A8.63 8.63 0 0 0 0 8.63v110.7A8.67 8.67 0 0 0 8.63 128h57.2a8.63 8.63 0 0 0 8.63-8.63V8.63A8.63 8.63 0 0 0 65.83 0zM28.05 6.22h18.29a1.06 1.06 0 1 1 0 2.12H28.05a1.06 1.06 0 0 1 0-2.12zm9.14 117.46a4.35 4.35 0 1 1 4.32-4.38v.07a4.31 4.31 0 0 1-4.32 4.31zM68.35 112H6.18V13.71h62.17z"
    ></path>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-smartphone',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
