var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _c(
      "button",
      {
        class: _vm.b("button-duplicate"),
        attrs: { title: "Duplicate" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.handlerDuplicate.apply(null, arguments)
          },
        },
      },
      [_c("IconCopy", { attrs: { height: "11" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        class: _vm.b("button-delete"),
        attrs: { title: "Delete" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.handlerDelete.apply(null, arguments)
          },
        },
      },
      [_c("IconTrash", { attrs: { height: "12" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }