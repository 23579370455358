var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-card",
    { staticClass: "hashtag-generator", staticStyle: { width: "480px" } },
    [
      _c("q-card-section", [
        _c(
          "div",
          { staticClass: "row items-center" },
          [
            _c("div", { staticClass: "text-h6" }, [
              _vm._v("Hashtag Generator"),
            ]),
            _vm._v(" "),
            _c("q-space"),
            _vm._v(" "),
            _c("q-btn", {
              directives: [{ name: "close-popup", rawName: "v-close-popup" }],
              attrs: { icon: "close", round: "", flat: "", dense: "" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("q-separator"),
      _vm._v(" "),
      _c(
        "q-card-section",
        {
          staticClass: "scroll",
          staticStyle: { "min-height": "340px", "max-height": "50vh" },
        },
        [
          _c("q-select", {
            attrs: {
              outlined: "",
              dense: "",
              "options-dense": "",
              "fill-input": "",
              "use-input": "",
              "input-debounce": "100",
              readonly: !!_vm.currentApplication,
              "hide-dropdown-icon": !!_vm.currentApplication,
              options: _vm.options_applications,
              label: "Application",
            },
            on: {
              filter: (event, update) =>
                _vm.onFilter(event, update, "applications"),
            },
            scopedSlots: _vm._u([
              {
                key: "selected-item",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "text-grey" }, [
                      _vm._v("(ID: " + _vm._s(scope.opt.value) + ")"),
                    ]),
                  ]
                },
              },
              {
                key: "option",
                fn: function (scope) {
                  return [
                    _c(
                      "q-item",
                      _vm._g(
                        _vm._b({}, "q-item", scope.itemProps, false),
                        scope.itemEvents
                      ),
                      [
                        _c(
                          "q-item-section",
                          [
                            _c(
                              "q-item-label",
                              { staticClass: "row" },
                              [
                                _vm._v(
                                  "\n              " + _vm._s(scope.opt.label)
                                ),
                                _c("q-space"),
                                _c("span", { staticClass: "text-grey" }, [
                                  _vm._v(
                                    "(ID: " + _vm._s(scope.opt.value) + ")"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "no-option",
                fn: function () {
                  return [
                    _c(
                      "q-item",
                      [
                        _c("q-item-section", { staticClass: "text-grey" }, [
                          _vm._v("\n            No results\n          "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.modelApplication,
              callback: function ($$v) {
                _vm.modelApplication = $$v
              },
              expression: "modelApplication",
            },
          }),
          _vm._v(" "),
          _c("q-separator", { staticClass: "q-mt-md q-mb-md" }),
          _vm._v(" "),
          _vm._l(_vm.stackOfDropdowns, function (data, $ind) {
            return _c(_vm.controls[data.controlName], {
              key: $ind,
              tag: "component",
              staticClass: "q-mt-md",
              attrs: { data: data },
              on: {
                input: function ($event) {
                  return _vm.onChangeHashtag($event, $ind)
                },
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "q-pa-md hashtag-generator__preview" },
        [
          _c("div", { staticClass: "text-caption text-uppercase q-mb-sm" }, [
            _vm._v("Preview"),
          ]),
          _vm._v(" "),
          _c("q-select", {
            attrs: {
              outlined: "",
              dense: "",
              "options-dense": "",
              "fill-input": "",
              "use-input": "",
              "hide-selected": "",
              "input-debounce": "100",
              options: _vm.options_objects,
              label: "Select Object",
            },
            on: {
              filter: (event, update) => _vm.onFilter(event, update, "objects"),
            },
            scopedSlots: _vm._u([
              {
                key: "no-option",
                fn: function () {
                  return [
                    _c(
                      "q-item",
                      [
                        _c("q-item-section", { staticClass: "text-grey" }, [
                          _vm._v("\n            No results\n          "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.modelObject,
              callback: function ($$v) {
                _vm.modelObject = $$v
              },
              expression: "modelObject",
            },
          }),
          _vm._v(" "),
          _vm.previewHashtag
            ? _c("pre", { staticClass: "q-mt-md" }, [
                _vm._v(_vm._s(_vm.previewHashtag)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "q-card-section",
        [
          _c(
            "q-input",
            {
              ref: "resultingHashtag",
              attrs: {
                outlined: "",
                dense: "",
                readonly: "",
                label: "Resulting hashtag",
              },
              scopedSlots: _vm._u([
                {
                  key: "after",
                  fn: function () {
                    return [
                      _c(
                        "q-btn",
                        {
                          attrs: {
                            flat: "",
                            color: "primary",
                            icon: "file_copy",
                            disabled: !_vm.resultingHashtag.tag,
                          },
                          on: { click: _vm.onCopy },
                        },
                        [
                          _c(
                            "q-tooltip",
                            {
                              attrs: {
                                anchor: "top middle",
                                self: "bottom middle",
                              },
                            },
                            [
                              _vm._v(
                                "\n            Copy to the Clipboard\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.resultingHashtag.tag,
                callback: function ($$v) {
                  _vm.$set(_vm.resultingHashtag, "tag", $$v)
                },
                expression: "resultingHashtag.tag",
              },
            },
            [
              _c(
                "q-popup-proxy",
                {
                  attrs: {
                    anchor: "center middle",
                    self: "center middle",
                    "no-focus": "",
                  },
                  model: {
                    value: _vm.isCopied,
                    callback: function ($$v) {
                      _vm.isCopied = $$v
                    },
                    expression: "isCopied",
                  },
                },
                [
                  _c(
                    "q-banner",
                    {
                      staticClass: "bg-grey-7 text-white",
                      attrs: { dense: "", rounded: "" },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.copiedMsg) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("q-separator"),
      _vm._v(" "),
      _c(
        "q-card-section",
        { attrs: { align: "right" } },
        [
          _c("q-btn", {
            directives: [{ name: "close-popup", rawName: "v-close-popup" }],
            attrs: { flat: "", label: "Cancel", "no-caps": "" },
          }),
          _vm._v(" "),
          _c("q-btn", {
            directives: [{ name: "close-popup", rawName: "v-close-popup" }],
            attrs: {
              unelevated: "",
              "no-caps": "",
              label: "Insert at cursor",
              color: "positive",
              disabled: !_vm.resultingHashtag.tag || !_vm.lastFocusEl,
            },
            on: { click: _vm.onInsert },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }