<template>
  <div
    v-if="serviceMessageText"
    :class="b({ isError: serviceMessageIsError })"
  >
    <div
      :class="b('text')"
    >
      {{ serviceMessageText }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'service-message',

    props: {
      serviceMessageText: {
        type: String,
      },
      serviceMessageIsError: {
        type: Boolean,
      },
    },
  };
</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .service-message
    &_is-error
      //

    &__text
      ~/_is-error &
        color red
</style>
