import Vue from 'vue';
import Vuex from 'vuex';
import VueMeta from 'vue-meta';
import router from '../router';
import store from '../store';
import Quasar from 'quasar';
import Shepherd from 'shepherd.js';
import HashtagGenerator from '../components/HashtagGenerator/Index';
import MakeEventBus from 'general/utils/MakeEventBus';
import Spinner from '@/bc/components/FullContainerSpinner';
import Header from '@/bc/components/LayoutHeader/Index';
import $ from 'jquery';

import { MakeResource } from '@/general/utils/MakeResource';
const $http = MakeResource();
Vue.prototype.$http = $http; // axios instance ($get, $post, ... return data)
store.$http = $http;

import { ApiServicesFactory } from '@/general/api/ApiFactory';
const $api = ApiServicesFactory($http)
Vue.prototype.$api = $api;
store.$api = $api;

import ToursController from 'general/tours'

const EventBus = MakeEventBus('root');

Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(Quasar, {
  config: {
    notify: {
      position: 'top',
      timeout: 3000
    },
    loadingBar: {
      color: 'white',
      'skip-hijack': true,
    }
  }
});

store.$q = Vue.prototype.$q;

Vue.prototype.$tours = ToursController({ $router: router, $store: store })

//credit to @Bill Criswell for this filter
Vue.filter(
  'truncate',
  (text, stop, clamp) =>
    text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
);

Vue.prototype.$shepherd = Shepherd;

// TODO: bad bad bad =(
window.lastFocusEl = null;

export default new Vue({
  el: '#v-app-root',
  router, store,
  components: {
    [Header.name]: Header,
    [HashtagGenerator.name]: HashtagGenerator,
    Spinner,
  },
  data: () => ({
    isCreatingApplication: false,
    isOpenHashtagGenerator: false,
  }),
  async created() {
    EventBus.$on('root:onShowHashtagGenerator', this.onShowHashtagGenerator);
    EventBus.$on('root:showCreatingApplication', payload => this.isCreatingApplication = payload);
    EventBus.$on('root:startTour', this.startTour);

    $(document).on('click', '#onShowHashtagGenerator', e => {
      this.onShowHashtagGenerator();
    });

    $(document).on('blur', 'input[type="text"], textarea', function() {
      if (!this.classList.contains('q-select__input') && !this.readOnly) {
        // TODO: bad bad bad =(
        window.lastFocusEl = this;
      }
      return false;
    });

    this.$router.onReady(async route => {
      const { appId } = (route || {}).params || {};
      if (appId) await this.$store.dispatch('application/fetchDataById', appId);
    });
  },
  async mounted() {
    this.$root.$el.style.display = 'block';
  },
  methods: {
    onShowHashtagGenerator() {
      this.isOpenHashtagGenerator = true;
    },
    onGoto(url, target) {
      if (target) {
        window.open(url, target);
      } else {
        window.location = url;
      }
    },
    startTour(activeTour) {
      const key = 'tour';
      const routeName = this.$route.name;
      const tour = this.$q.localStorage.getItem(key) || {};

      setTimeout(() => {
        const { tourName, extraProps } = activeTour.options || {}
        const data = (tour[routeName] || []).find(guide => guide.name === tourName);
        let isVisitTour = false;

        if (!data) {
          if (!tour[routeName]) tour[routeName] = [];
          tour[routeName].push({
            name: tourName,
            props: extraProps,
            isVisit: true,
          })
        } else {
          data.props = Object.assign({}, data.props, extraProps);
          isVisitTour = data.isVisit;
          data.isVisit = true;
        }

        this.$q.localStorage.set(key, tour);

        if (!isVisitTour && activeTour)
          activeTour.start();
      }, 0);
    },
  }
});
