var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: _vm.b({ isActive: _vm.isActive }),
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.start.apply(null, arguments)
        },
      },
    },
    [
      _vm.isActive
        ? _c("div", { class: _vm.b("timer") }, [
            _vm._v("\n    " + _vm._s(_vm.freezingCounter) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }