/*global PRODUCTION */

import Raven from 'raven-js';

const apiUrl = 'https://1337daa7c04c40778c1f413baa0b160d@sentry.io/180423';
const environment = (PRODUCTION) ? 'prod' : 'dev';

if (!Raven.isSetup()) {
  Raven
    .config(apiUrl, {
      environment,
    })
    .install();
}
