import {
  makeControlProps,
  visibilityProps,
  loopControlProps,
  backgroundProps, typographyProps, sizingProps,
  $t,
} from './helper';

import Vue from 'vue';

import _ from 'lodash';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.text',
    label: 'Caption'
  }),
  makeControlProps('select', {
    pathValue: 'properties.typeButton',
    label: 'Button design',
    options: [
      {label: 'Default', value: ''},
      {label: 'Outline', value: 'outline'},
      {label: 'Flat', value: 'flat'},
      {label: 'Glossy', value: 'glossy'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.roundButton',
    label: 'Button rounding',
    options: [
      {label: 'Default', value: ''},
      {label: 'Round (icon only)', value: 'round'}
    ]
  }),
  makeControlProps('input', {
    pathValue: 'properties.iconCls',
    label: 'Icon class',
    placeholder: 'fas fa-home',
    hint: {
      text: $t('general.iconsPackageHint'),
      action: () => { window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank'); },
    },
    extractClass: true,
    subcontrols: [
      makeControlProps('select', {
        pathValue: 'properties.iconStack',
        label: 'Button layout',
        options: [
          { label: 'Inline icon', value: false },
          { label: 'Stacked icon', value: true },
        ],
        subcontrols: {
          'false': [
            makeControlProps('select', {
              pathValue: 'properties.iconInlinePosition',
              label: 'Icon inline position',
              options: [
                {label: 'Near text', value: 'text'},
                {label: 'Near border', value: 'border'}
              ]
            }),
          ]
        }
      }),
      makeControlProps('select', (componentData) => {
        if (componentData.properties.roundButton === 'round' && componentData.css.all){
          // const i = componentData.css.ios.find(el => el.selector = '.mbst-button').rules.find(el=> el.directive == 'border-radius');
          // const a = componentData.css.android.find(el => el.selector = '.mbst-button').rules.find(el=> el.directive == 'border-radius');
          // if (i) {
          //   Vue.set(i, 'value', '');
          //   Vue.set(a, 'value', '');
          // }
          // debugger
          let a = componentData.css.all.find(el => el.selector === '.mbst-button')
          if (a && a.rules['border-radius']) {
            Vue.set(a.rules, 'border-radius', '');
          }

          return {};
        }
        return {
          pathValue: 'properties.iconPosition',
          label: 'Icon position',
          options: [
            { label: componentData.properties.iconStack ? 'Above' : 'Left', value: 'left' },
            { label: componentData.properties.iconStack ? 'Below' : 'Right', value: 'right' },
          ],
        }
      })
    ]
  }),

  visibilityProps,
  loopControlProps,
];

const view = [
  // {
  //   selector: 'div:first-of-type',
  //   controls: [
  //     makeControlProps('select', {
  //       label: 'Display',
  //       pathValue: 'display',
  //       options: [{label: 'Block', value: 'block'}, {label: 'None', value: 'none'}],
  //     })
  //   ]
  // },
  {
    selector: '.row',
    controls: [
      sizingProps
    ]
  },
  // {
  //   selector: ',.mbst-button-container',
  //   controls: [
  //     {
  //       controlName: 'v-control-select',
  //       label: 'Display',
  //       pathValue: 'display',
  //       options: [{label: 'Visible', value: ''}, {label: 'None', value: 'none'}, { label: 'Inline', value: 'inline-block' }],
  //     }
  //   ]
  // },
  {
    selector: '.mbst-button-container .q-btn',
    controls: [
      _.omit(typographyProps, ['align']),
    ]
  },
  {
    selector: '.mbst-button .q-btn__content',
    controls: [
      {
          controlName: 'v-control-btn-rdio',
          label: 'Text align',
          pathValue: 'justify-content',
          options: [
            {
              icon: 'fa fa-align-left',
              value: 'flex-start',
            },
            {
              icon: 'fa fa-align-center',
              value: 'center',
            },
            {
              icon: 'fa fa-align-right',
              value: 'flex-end',
            },
          ]
      }
    ]
  },
  {
    selector: '.mbst-button',
    controls: [
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps
    ]
  },
  {
    selector: '',
    controls: [
      makeControlProps('select', {
        label: 'Display',
        pathValue: 'display',
        options: [{label: 'Visible', value: ''}, {label: 'None', value: 'none'}, { label: 'Inline', value: 'inline-block' }],
      }),
      makeControlProps('select', {
        selector: '',
        label: 'Positioning',
        pathValue: 'position',
        options: [{label: 'Default', value: ''}, {label: 'Absolute', value: 'absolute'}],
        subcontrols: {
          'absolute': [
            makeControlProps('inputUnits', {
              selector: '',
              pathValue: 'top',
              label: 'Top',
              measure: ['px', 'em', '%', 'rem'],
            }),
            makeControlProps('inputUnits', {
              selector: '',
              pathValue: 'left',
              label: 'Left',
              measure: ['px', 'em', '%', 'rem'],
            }),
          ]
        }
      })
    ]
  },
  {
    selector: '.mbst-button i',
    label: 'Icon style',
    controls: [
      _.omit(typographyProps, 'font', 'align', 'textTransform', 'whiteSpace', 'lineHeight', 'letterSpacing'),
    ]
  },
];

export default {
  properties,
  actions: [true],
  view
}
