var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _vm.shownButtonClose
      ? _c(
          "button",
          {
            class: _vm.b("button-close"),
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handlerClose.apply(null, arguments)
              },
            },
          },
          [_c("IconClose", { attrs: { width: "10" } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.shownHeader && (_vm.headerIcon || _vm.headerTitle)
      ? _c("div", { class: _vm.b("header") }, [
          _vm.headerIcon
            ? _c("div", { class: _vm.b("header-icon") }, [
                _c("img", {
                  attrs: { src: _vm.headerIcon, alt: "header-icon" },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.headerTitle
            ? _c("div", { class: _vm.b("header-title") }, [
                _vm._v("\n      " + _vm._s(_vm.headerTitle) + "\n    "),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.b("body") }, [_vm._t("default")], 2),
    _vm._v(" "),
    _vm.shownFooter
      ? _c("div", { class: _vm.b("footer") }, [
          _c("div", { class: _vm.b("controls") }, [
            _c(
              "button",
              {
                class: _vm.b("button-link"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handlerRemindNever.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("WidgetModalInnerBase.remind2weeks")) +
                    "\n\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.b("button-link"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handlerRemindLater.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("WidgetModalInnerBase.remindLater")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }