'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/triggers/triggerapi",
    // @todo MBST3.1
    // defaults: {
    //     id: null,
    //     TriggerName: 'New Trigger',
    //     AutoStart: false,
    //     AutoAllRecordsLimitCount: 0,
    //     AutoAllRecordsLimitDate: null,
    //     AutoAllRecordsLimitState: null, // limit count/no limit
    //     AutoAllRecordsSchedule: null,
    //     AutoAllRecordsScope: true,
    //     AutoNewRecordsInstantly: false,
    //     AutoNewRecordsSchedule: null,
    //     AutoNewRecordsScope: false,
    //     ManualRecordsScope: 'all', // all/new
    //     SmartfilterBranch: 'objects', // objects/events/lists
    //     SmartfilterId: null,
    //     State: 'Idle', // Idle/Scheduled/Executing
    //     StartedExecution: null, // пока что не знаю где используется
    //     FinishedExecution: null, // пока что не знаю где используется
    //     PlannedToStart: null, // пока что не знаю где используется
    //     Active: false, // true/false
    //     Schedule: 'today',
    //     LastRun: 'today',
    //     Executed: 12,
    //     Failed: 100
    // },
    // @todo END

      defaults: {
        id: null,
        TriggerName: 'New Trigger',
        AutoStart: false,
        AutoAllRecordsLimitCount: 1,
        AutoAllRecordsLimitDate: null,
        AutoAllRecordsLimitState: null, // limit count/no limit
        AutoAllRecordsSchedule: null,
        AutoAllRecordsScope: false,
        AutoNewRecordsInstantly: true,
        AutoNewRecordsSchedule: null,
        AutoNewRecordsScope: true,
        ManualRecordsScope: 'new', // all/new
        SmartfilterBranch: 'objects', // objects/events/lists
        SmartfilterId: null,
        State: 'Idle', // Idle/Scheduled/Executing
        StartedExecution: null, // пока что не знаю где используется
        FinishedExecution: null, // пока что не знаю где используется
        PlannedToStart: null, // пока что не знаю где используется
        Active: false, // true/false
        Schedule: '',
        LastRun: '',
        Executed: 0,
        Failed: 0
      },

    initialize: function() {
        // console.log(this);
    }

});