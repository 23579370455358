<template>
  <IconPicture
    :class="b()"
    :width="width"
    :height="height"
  />
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconPicture from './IconPicture';

  export default {
    name: 'icon-component-w-image',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconPicture,
    },
  };
</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .icon-component-w-image
    flex(center, center)
</style>
