var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("circle", {
          attrs: { fill: "#62a5d9", cx: "64", cy: "64", r: "64" },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#fff",
            d: "M95.14 36.82L83.71 94.45s-1.6 4-6 2.08L51.34 76.3l-9.6-4.63-16.14-5.44s-2.48-.88-2.72-2.8 2.8-3 2.8-3L89.87 35.3s5.27-2.3 5.27 1.52z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#d7e4f0",
            d: "M49.31 93.8s-.77-.07-1.73-3.11-5.84-19-5.84-19l38.77-24.64s2.24-1.36 2.16 0c0 0 .4.24-.8 1.36S51.42 75.82 51.42 75.82",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#bccee2",
            d: "M61.45 84.06L51 93.57a1.83 1.83 0 0 1-1.71.23l2-17.67",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }