import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import logger from '../logger';

Vue.use(VueI18n);

const langManager = new Vue({
  data: () => ({
    /**
     * Текущий установленный язык
     *
     * @type {string}
     */
    currentLang: '',

    /**
     * Экземпляр компонента
     *
     * @type {VueI18n}
     */
    i18n: new VueI18n({
      fallbackLocale: 'en_us'
    }),

    /**
     * Зарегистрированные языки
     *
     * @type {Set}
     */
    registeredLanguages: new Set(),

    /**
     * Зарегистрированные группы
     *
     * @type {Map}
     */
    registeredGroups: new Map(),
  }),

  methods: {
    /**
     *
     */
    languagePack (localization){
      const i18n = new VueI18n({
        locale: this.i18n.locale ? this.i18n.locale.toLowerCase().replace('-', '_') : 'en_US',
        messages: localization
      });
      return text => i18n.t(text);
    },

    /**
     * Регистрация группы
     *
     * @param {string} path
     * @param {string} groupName
     * @returns {Map}
     */
    registerGroup(path, groupName) {
      try {
        return this.registeredGroups.set(groupName, {
          path,
          loadedLanguages: new Map(),
        });
      } catch (error) {
        logger.exception(error);
      }
    },

    /**
     * Регистрация языка
     *
     * @param {string} lang
     * @returns {Set}
     */
    registerLanguage(lang) {
      try {
        return this.registeredLanguages.add(lang);
      } catch (error) {
        logger.exception(error);
      }
    },

    /**
     * Проверка зарегистрирована ли группа
     *
     * @param {string} groupName
     * @returns {boolean}
     */
    isRegisteredGroup(groupName) {
      try {
        return this.registeredGroups.has(groupName);
      } catch (error) {
        logger.exception(error);
      }
    },

    /**
     * Проверка загружен ли язык в группе
     *
     * @param {string} groupName
     * @param {string} lang
     * @returns {boolean}
     */
    isLoadedLang(groupName, lang) {
      try {
        if (!this.isRegisteredGroup(groupName)) {
          return false;
        }

        return this.registeredGroups[groupName].has(lang);
      } catch (error) {
        logger.exception(error);
      }
    },

    /**
     * Установка языка в экземпляре VueI18n, axios и в тэге html
     *
     * @param {string} lang
     * @returns {boolean}
     */
    setLanguage(lang) {
      try {
        // this.load();
        this.currentLang = lang;

        // установка языка в глобальный инстанс
        this.i18n.locale = lang;

        // axios.defaults.headers.common['Accept-Language'] = lang;
        document.querySelector('html')
          .setAttribute('lang', lang);
      } catch (error) {
        logger.exception(error);
      }
    },

    /**
     * Динамическая загрузка языковых файлов
     */
    // load() {
    //   try {
    //     this.registeredGroups.forEach((group, key) => {
    //       this.registeredLanguages.forEach(registeredLang => {
    //         const lang = group.loadedLanguages.get(registeredLang);
    //
    //         if (!lang) {
    //           this.registeredLanguages.add(registeredLang);
    //
    //           return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${key}/${registeredLang}.json`)
    //             .then(messages => {
    //               this.i18n.setLocaleMessage(registeredLang, messages.default);
    //             })
    //             .catch(console.error);
    //         }
    //       });
    //     });
    //   } catch (error) {
    //     logger.exception(error);
    //   }
    // },

    /**
     * Получение инстанса i18n
     *
     * @param {string} groupName
     *
     * @returns {VueI18n|boolean}
     */
    getI18n(groupName = '') {
      if (!groupName) {
        return this.i18n;
      }

      const group = this.registeredGroups.get(groupName);

      if (group) {
        return group.i18n;
      }

      return false;
    },
  },
});

export default langManager;
