var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b({ isDefault: !_vm.src }) }, [
    _c("img", {
      class: _vm.b("image"),
      attrs: { src: _vm.internalSrc || _vm.srcDefault, alt: _vm.alt },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }