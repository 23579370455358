var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vddl-draggable",
    {
      class: _vm.b({ isActive: _vm.selectedComponentUuid === _vm.state.uuid }),
      attrs: {
        draggable: _vm.state,
        index: _vm.index,
        wrapper: _vm.list,
        moved: _vm.handlerMoved,
        type: _vm.type,
        "effect-allowed": "move",
      },
    },
    [
      _c("WidgetEditorContentAreaComponentControls", {
        class: _vm.b("controls"),
        on: {
          duplicate: function ($event) {
            return _vm.handlerDuplicate(_vm.index, _vm.state)
          },
          delete: function ($event) {
            return _vm.handlerDelete(_vm.state)
          },
        },
      }),
      _vm._v(" "),
      _c(
        _vm.state.name,
        _vm._b(
          {
            tag: "component",
            attrs: { "editor-mode": true },
            nativeOn: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handlerClick(_vm.state)
              },
            },
          },
          "component",
          _vm.state.properties,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }