var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.b({
        theme: _vm.theme,
        isDisabled: _vm.disabled,
        isChecked: _vm.isChecked,
        hasFail: _vm.errors.length,
      }),
    },
    [
      _c("input", {
        staticClass: "form-control",
        attrs: {
          id: _vm.vId,
          type: "checkbox",
          name: _vm.name,
          required: _vm.required,
          disabled: _vm.disabled,
        },
        domProps: { checked: _vm.isChecked },
        on: { change: _vm.handlerChange },
      }),
      _vm._v(" "),
      _c(
        "label",
        { class: _vm.b("checkbox"), attrs: { for: _vm.vId } },
        [
          _c("IconCheck", {
            class: _vm.b("icon-check"),
            attrs: { width: "13" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.label
        ? _c("label", {
            class: _vm.b("label"),
            attrs: { for: _vm.vId },
            domProps: { innerHTML: _vm._s(_vm.label) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }