<template>
  <div :class="b({ isDisabled: disabled })">
    <toggle-button
      v-model="isChecked"
      :sync="sync"
      :speed="speed"
      :disabled="disabled"
      :color="color"
      :cssColors="cssColors"
      :labels="labels"
      :switchColor="switchColor"
      :width="width"
      :height="height"
      :name="name"
    ></toggle-button>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'control-switch',

    mixins: [
      baseMixin,
    ],

    model: {
      prop: 'checked',
      event: 'update',
    },

    props: {
      // default properties for toggle-button:
      checked: {
        type: Boolean,
        default: false,
      },
      sync: {
        type: Boolean,
        default: true,
      },
      speed: {
        type: Number,
        default: 300,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      color: {
        type: [String, Object],
        default() {
          return {
            checked: '#75c791',
            unchecked: '#c77c77',
          };
        },
      },
      cssColors: {
        type: Boolean,
        default: false,
      },
      labels: {
        type: [Boolean, Object],
        default: false,
      },
      switchColor: {
        type: [String, Object],
      },
      width: {
        type: Number,
        default: 50,
      },
      height: {
        type: Number,
        default: 22,
      },
      name: {
        type: String,
      },
    },

    data: () => ({
      isChecked: false,
      errors: [],
    }),

    watch: {
      checked: {
        handler(value) {
          this.isChecked = value;
        },
        immediate: true,
      },

      isChecked(value) {
        this.isChecked = value;
        this.$emit('update', value);
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-switch
    &_is-disabled
      cursor not-allowed

    > label
      ~/_is-disabled&
        pointer-events none

    > .vue-js-switch
      margin-bottom 0 !important
</style>
