'use strict';

// Libraries
const _ = require('underscore');
const Backbone = require('backbone');

// Views
const APIProviderMethodsView = require('views/apiproviders/accordion/ApiProviderMethodsView');

// Templates
const _apiproviders = require('text!bcTemplates/apiproviders/apiproviders.html');

module.exports = Backbone.View.extend({

  tagName: 'div',
  className: 'b-global-box__body',

  initialize: function (options) {
    const APIProvidersCollection = Backbone.Collection.extend();

    this.tpl = _.template(_apiproviders);
    this.apiProviderId = options.apiProviderId;
    this.apiProviderMethodId = options.apiProviderMethodId;
    this.triggerOperationSettingsView = options.triggerOperationSettingsView;
    this.itemView = [];
    this.collection = new APIProvidersCollection(options.collection);

    this.setActive(this.apiProviderId);
  },

  setActive: function (id) {
    _.each(this.collection.models, (model) => {
      model.set('isActive', (id === model.get('id')));
    });
  },

  render: function () {
    this.$el.html(this.tpl());

    const $apiProviderMethods = _.map(this.collection.models, (model) => {
      const apiProviderMethodsView = new APIProviderMethodsView({
        apiProviderId: model.get('id'),
        apiProviderMethodId: this.apiProviderMethodId,
        parentView: this,
        model: model,
      });

      this.itemView.push(apiProviderMethodsView);
      apiProviderMethodsView.render();

      return apiProviderMethodsView.$el;
    });

    this.$('#apiProviders').append($apiProviderMethods);
  },

});
