import { backgroundDefault } from './helper';

export default {
  name: 'mbst-toolbar__item',
  aliasName: 'Column Toolbar',
  properties: {
    backendname: 'column-toolbar'
  },
  css: {
    ios: [
      {
        selector: '.mbst-toolbar__item',
        rules: [
          { directive: 'width', value: '50%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ],
      android: [
      {
        selector: '.mbst-toolbar__item',
        rules: [
          { directive: 'width', value: '50%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ]
  },
  components: []
}
