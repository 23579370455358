<i18n src="general/lang/WidgetSettingsScenariosItem.json"></i18n>

<template>
  <div :class="b({ isActive: jsonData.active })">
    <div :class="b('wrap')">
      <div :class="b('controls')">
        <button
          :class="b('button-toggle', { isActive: jsonData.active })"
          @click.prevent="toggle"
        >
          <q-icon name="fas fa-check" />
        </button>

        <button
          v-if="!isEditing"
          :class="b('button-edit', { isBlink: isButtonEditBlink })"
          @click.prevent="edit"
        >
          <q-icon name="fas fa-pen" />
        </button>

        <template v-if="isEditing">
          <button
            :class="b('button-save')"
            @click.prevent="apply"
          >
            <q-icon name="far fa-save" />
          </button>

          <button
            :class="b('button-cancel')"
            @click.prevent="cancel"
          >
            <q-icon name="far fa-times" />
          </button>
        </template>

        <button
          :class="b('button-delete')"
          @click.prevent="deleteItem"
        >
          <q-icon name="far fa-trash-alt" />
        </button>
      </div>

      <div
        v-if="!isEditing"
        :class="b('mask')"
        @click.prevent="handlerDisabledControls"
      ></div>

      <div :class="b('row', { description: true })">
        <ControlTextarea
          v-model.lazy="jsonData.description"
          :label-before="$t('WidgetSettingsScenariosItem.descriptionLabel')"
          :disabled="!isEditing"
        />
      </div>

      <div :class="b('row', { conditions: true })">
        <div :class="b('condition-box')">
          <div :class="b('condition-box-name')"></div>
          <div :class="b('condition-box-enabled')">
            <HintHelper :content="$t('WidgetSettingsScenariosItem.conditions.hintEnabled')" />
          </div>
          <div :class="b('condition-box-installed')">
            <HintHelper :content="$t('WidgetSettingsScenariosItem.conditions.hintInstalled')" />
          </div>
        </div>

        <template v-for="(condition, key) in jsonData.conditions">
          <div
            v-if="condition"
            :key="key"
            :class="b('condition-box')"
          >
            <div :class="b('condition-box-name')">
              {{ channelNames[key] }}:
            </div>
            <div :class="b('condition-box-enabled')">
              <ControlCheckbox
                v-model="condition.enabled"
                :disabled="!isEditing"
              />
            </div>
            <div :class="b('condition-box-installed')">
              <ControlSwitch
                v-model="condition.installed"
                :disabled="!isEditing || !condition.enabled"
                :labels="true"
              />
            </div>
          </div>
        </template>

        <div
          :class="b('condition-box-hint')"
          v-html="conditionsHint"
        ></div>
      </div>

      <div :class="b('row', { where: true })">
        <ControlSelect
          v-model="jsonData.where.type"
          :label-before="$t('WidgetSettingsScenariosItem.where.type.label')"
          :options="whereTypeOptions"
          :disabled="!isEditing"
        />

        <br v-if="jsonData.where.type" />

        <ControlSelect
          v-if="jsonData.where.type === TYPE_WHERE_SCREEN"
          v-model="jsonData.where.value"
          :label-before="$t('WidgetSettingsScenariosItem.where.value.label')"
          :options="whereTypeScreensOptions"
          :disabled="!isEditing"
        />
        <ControlSelect
          v-else-if="jsonData.where.type === TYPE_WHERE_ACTION"
          v-model="jsonData.where.value"
          :label-before="$t('WidgetSettingsScenariosItem.where.value.label')"
          :options="whereTypeActionsOptions"
          :disabled="!isEditing"
        />
        <ControlCounter
          v-else-if="jsonData.where.type === TYPE_WHERE_HIT"
          v-model.number="jsonData.where.value"
          :label-before="$t('WidgetSettingsScenariosItem.where.value.label')"
          :min="0"
          :disabled="!isEditing"
        />
        <ControlInput
          v-else-if="jsonData.where.type"
          v-model="jsonData.where.value"
          :label-before="$t('WidgetSettingsScenariosItem.where.value.label')"
          :disabled="!isEditing"
        />
      </div>

      <div :class="b('row', { when: true })">
        <ControlCounter
          v-model.number="jsonData.delay"
          :label-before="$t('WidgetSettingsScenariosItem.whenLabel')"
          :min="0"
          :disabled="!isEditing"
        />
      </div>

      <div :class="b('row', { counters: true })">
        <ControlCounter
          v-model.number="jsonData.counters.total"
          :label-before="$t('WidgetSettingsScenariosItem.countersTotalLabel')"
          :min="0"
          :disabled="!isEditing"
        />

        <br />

        <ControlCounter
          v-model.number="jsonData.counters.inSession"
          :label-before="$t('WidgetSettingsScenariosItem.countersInSessionLabel')"
          :min="0"
          :disabled="!isEditing"
        />

        <button
          :class="b('button-reset-counters')"
          class="btn btn-xs btn-danger"
          :disabled="!isEditing"
          @click.prevent="resetCounters"
        >{{ $t('WidgetSettingsScenariosItem.buttonResetCounters') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import logger from 'general/logger';
  import { baseMixin, noticeMixin, confirmationMixin } from 'general/mixins';
  import localForageFactory from 'general/utils/localForageFactory';
  import IconPencil from 'general/components/icons/IconPencil';
  import IconDiskette from 'general/components/icons/IconDiskette';
  import IconClose from 'general/components/icons/IconClose';
  import IconTrashCross from 'general/components/icons/IconTrashCross';
  import IconCheck from 'general/components/icons/IconCheck';
  import ControlInput from 'general/components/controls/ControlInput';
  import ControlTextarea from 'general/components/controls/ControlTextarea';
  import ControlCheckbox from 'general/components/controls/ControlCheckbox';
  import ControlSelect from 'general/components/controls/ControlSelect';
  import ControlSwitch from 'general/components/controls/ControlSwitch';
  import ControlCounter from 'general/components/controls/ControlCounter';
  import HintHelper from 'general/components/HintHelper';
  import {
    TYPE_WHERE_SCREEN,
    TYPE_WHERE_ACTION,
    TYPE_WHERE_HIT,
  } from '~@/bc/components/WidgetSettings/store/widgetScenarios.types';

  const { mapGetters, mapActions } = createNamespacedHelpers('widgetSettings');

  const channelCodes = [
    'icon',
    'vCard',
    'bots',
    'push',
  ];
  const channelNames = {
    icon: 'Icon',
    vCard: 'vCard',
    bots: 'Bots',
    push: 'Push',
  };

  export default {
    name: 'widget-settings-scenarios-item',

    mixins: [
      baseMixin,
      noticeMixin,
      confirmationMixin,
    ],

    components: {
      IconPencil,
      IconDiskette,
      IconClose,
      IconTrashCross,
      IconCheck,
      ControlInput,
      ControlTextarea,
      ControlCheckbox,
      ControlSelect,
      ControlSwitch,
      ControlCounter,
      HintHelper,
    },

    model: {
      prop: 'model',
      event: 'update',
    },

    props: {
      model: {
        type: Object,
        required: true,
      },
      editing: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      modelState: null,
      isUpdating: false,
      isEditing: false,
      disabled: true,
      isButtonEditBlink: false,
      channelNames,
      TYPE_WHERE_SCREEN,
      TYPE_WHERE_ACTION,
      TYPE_WHERE_HIT,
    }),

    computed: {
      ...mapGetters({
        screens: 'widgetScenarios/getScreens',
        actions: 'widgetScenarios/getActions',
      }),

      whereTypeOptions() {
        return [
          {
            label: this.$t('WidgetSettingsScenariosItem.where.type.options.empty'),
            value: null,
          },
          {
            label: this.$t('WidgetSettingsScenariosItem.where.type.options.screen'),
            value: TYPE_WHERE_SCREEN,
          },
          {
            label: this.$t('WidgetSettingsScenariosItem.where.type.options.action'),
            value: TYPE_WHERE_ACTION,
          },
          {
            label: this.$t('WidgetSettingsScenariosItem.where.type.options.hit'),
            value: TYPE_WHERE_HIT,
          },
        ];
      },

      whereTypeScreensOptions() {
        const screens = [];

        if (this.screens) {
          this.screens.forEach((screen) => {
            const { id, attributes } = screen;
            screens.push({
              label: `${attributes.Name} (${id})`,
              value: id,
            });
          });
        }

        return [
          {
            label: this.$t('WidgetSettingsScenariosItem.where.value.options.empty'),
            value: null,
          },
          ...screens,
        ];
      },

      whereTypeActionsOptions() {
        const actions = [];

        if (this.actions) {
          this.actions.forEach((item) => {
            const action = this.handlerAction(item);
            if (action) {
              actions.push(action);
            }
          });
        }

        return [
          {
            label: this.$t('WidgetSettingsScenariosItem.where.value.options.empty'),
            value: null,
          },
          ...actions,
        ];
      },

      jsonData() {
        return this.modelState.attributes.json_data;
      },

      conditionsHint() {
        let text = '';
        let totalCounter = 0;
        let installedCounter = 0;
        let notInstalledCounter = 0;

        channelCodes.forEach((code) => {
          const condition = this.jsonData.conditions[code];
          const name = channelNames[code];

          if (!condition.enabled) {
            return;
          }

          text += (totalCounter > 0)
            ? `<br />${this.$t('WidgetSettingsScenariosItem.conditions.hintResultAnd')} `
            : '<br />';

          if (condition.installed) {
            text += this.$t('WidgetSettingsScenariosItem.conditions.hintResultInstalled', { name });
            installedCounter += 1;
          } else {
            text += this.$t('WidgetSettingsScenariosItem.conditions.hintResultNotInstalled', { name });
            notInstalledCounter += 1;
          }

          totalCounter += 1;
        });

        if (totalCounter > 0) {
          text = this.$t('WidgetSettingsScenariosItem.conditions.hintResultBegin') + text;
        }
        if (
          installedCounter === channelCodes.length
          || notInstalledCounter === channelCodes.length
        ) {
          text = this.$t('WidgetSettingsScenariosItem.conditions.hintResultBegin');

          if (installedCounter === channelCodes.length) {
            text += ` ${this.$t('WidgetSettingsScenariosItem.conditions.hintResultInstalledAll')}`;
          }
          if (notInstalledCounter === channelCodes.length) {
            text += ` ${this.$t('WidgetSettingsScenariosItem.conditions.hintResultNotInstalledAll')}`;
          }
        }

        return text;
      },
    },

    watch: {
      model: {
        async handler(value) {
          this.modelState = _.cloneDeep(value);
          await this.$nextTick();
          this.isUpdating = false;
        },
        immediate: true,
        deep: true,
      },

      editing: {
        handler(value) {
          this.isEditing = value;
        },
        immediate: true,
      },

      'modelState.attributes.widgetTypeId'(data) {
        if (typeof data !== 'number') {
          this.modelState.attributes.widgetTypeId = parseInt(data, 10);
        }
      },

      'jsonData.where.type': {
        handler(data) {
          if (!data) {
            this.jsonData.where.value = '';
          } else if (data === TYPE_WHERE_HIT) {
            this.jsonData.where.value = 0;
          }
        },
        //immediate: true,
      },
    },

    methods: {
      ...mapActions({
        update: 'widgetScenarios/update',
        delete: 'widgetScenarios/delete',
      }),

      /**
       * ...
       */
      toggle() {
        this.jsonData.active = !this.jsonData.active;
        this.updateItem();
      },

      /**
       * ...
       */
      edit() {
        this.isEditing = true;
      },

      /**
       * ...
       */
      cancel() {
        this.isEditing = false;
      },

      /**
       * ...
       */
      apply() {
        this.isEditing = false;
        this.updateItem();
      },

      /**
       * ...
       */
      updateItem() {
        if (this.isUpdating) {
          return;
        }

        this.isUpdating = true;
        const updated = this.update({
          element: this.modelState,
          editing: this.editing,
        });

        if (updated) {
          this.notice(this.$t('WidgetSettingsScenariosItem.noticeUpdateSuccess'), this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice(this.$t('WidgetSettingsScenariosItem.noticeUpdateError'), this.NOTICE_LEVEL_ERROR);
        }
      },

      /**
       * Генерация события удаления элемента
       */
      deleteItem() {
        const text = this.$t('WidgetSettingsScenariosItem.deletionConfirmationText');
        if (!this.confirm(text)) {
          return;
        }

        const deleted = this.delete(this.modelState.id);

        if (deleted) {
          this.notice(this.$t('WidgetSettingsScenariosItem.noticeDeleteSuccess'), this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice(this.$t('WidgetSettingsScenariosItem.noticeDeleteError'), this.NOTICE_LEVEL_ERROR);
        }
      },

      /**
       * TODO: реализовать логику
       *
       * Очистка счетчиков
       */
      resetCounters() {
        const text = this.$t('WidgetSettingsScenariosItem.resetCountersConfirmationText');
        if (!this.confirm(text)) {
          return;
        }

        // реализовать перебор всех объектов текущего приложения и очищать хранилище для каждого
        const { id: scenarioId } = this.modelState;
        // TODO: реализовать логику получения
        const applicationId = 0;
        const objectIds = this.getAllObjectIds(applicationId);
        // TODO: асинхронность в цикле - спорный вопрос, надо тестить, вероятно нужен рефакторинг
        objectIds.forEach(async (objectId) => {
          const localForage = this.createLocalForage(applicationId, objectId);
          if (localForage) {
            const scenariosCounters = await localForage.getItem('scenariosCounters');
            const scenarioCounters = scenariosCounters[scenarioId];
            if (scenarioCounters) {
              scenarioCounters.inSession = 0;
              scenarioCounters.total = 0;
              localForage.setItem('scenariosCounters', scenariosCounters);
            }
          }
        });

        logger.log('TODO: not implemented `resetCounters`!');
      },

      /**
       * Активация мерцания кнопки редактирования
       */
      handlerDisabledControls() {
        if (this.isButtonEditBlink) {
          return;
        }
        this.isButtonEditBlink = true;
        setTimeout(() => {
          this.isButtonEditBlink = false;
        }, 500);
      },

      /**
       * @param {Object} action
       * @returns {Object}
       */
      handlerAction(action) {
        if (!action) {
          return null;
        }
        // исключаются события перехода по экранам, т.к. они обрабатываются отдельно
        const { options, type } = action.attributes;
        if (type === 'goto' && options.type === 'screen') {
          return null;
        }
        return {
          label: this.handlerActionLabel(action),
          value: action.attributes.uuid,
        };
      },

      /**
       * @param {Object} action
       * @returns {string}
       */
      handlerActionLabel(action) {
        if (!action) {
          return '';
        }
        const { options, type } = action.attributes;
        const name = this.$t(`WidgetSettingsScenariosItem.actions.${type}`);
        let value = '...';

        switch (type) {
          // case 'goto': {
          //   if (options.type === 'screen') {
          //     value = options.screenId;
          //   } else if (options.type === 'url') {
          //     value = options.url;
          //   }
          //   break;
          // }

          case 'createEvent':
            // options.actionName = "my button"
            value = options.actionName;
            break;

          case 'updateObject': {
            // options.value = [{ key: "Enabled", value: "0" }]
            const element = options.value[0];
            value = element ? `${element.key}:${element.value}` : '';
            break;
          }

          case 'executeOperations': {
            // options.value = [ ??? ]
            break;
          }

          case 'setVariable': {
            // value = [{ key: "xxx", value: "123" }]
            const element = options.value[0];
            value = element ? `${element.key}:${element.value}` : '';
            break;
          }
          default:
        }

        return `${name || type} (${value})`;
      },

      /**
       * TODO: реализовать логику
       *
       * @param {number} applicationId
       * @returns {Array}
       */
      getAllObjectIds(applicationId) {
        return [];
      },

      /**
       * TODO: раскомментировать
       *
       * Инициализация LocalForage
       *
       * @param {number} applicationId
       * @param {number} objectId
       * @returns {Object}
       */
      createLocalForage(applicationId, objectId) {
        return null;
        // return localForageFactory({
        //   applicationId,
        //   objectId,
        // });
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-settings-scenarios-item
    width 100%
    position relative
    box-shadow 0 1px 5px 0 rgba(#000, .25)
    transition-duration $duration

    &_is-active
      border-color lightness(#eee, 60%)

    &__debug-box
      border-bottom 1px solid #fff
      padding 5px 10px

    &__controls
      flex(center, center)
      flex-direction column
      flex-shrink 0
      position absolute
      top -10px
      left -15px
      z-index 11

    &__wrap
      display flex
      width 100%

    &__mask
      absolute-parent(10)
      cursor not-allowed

    &__row
      border-right 1px solid #eee
      padding 10px

      +is-last()
        border-width 0


      &_description
        width 100%

      &_conditions
        flex-shrink 0
        width 200px

      &_where
        flex-shrink 0
        width 140px

      &_when
        flex-shrink 0
        width 140px

      &_counters
        flex-shrink 0
        width 140px

    &__button-toggle
    &__button-edit
    &__button-save
    &__button-cancel
    &__button-delete
      reset-button()
      flex(center, center)
      size(24px)
      link()
      border-radius 50%
      margin-bottom 8px
      position relative
      z-index 1
      box-shadow 0 0 2px 0 rgba(#000, .2)
      outline none

      &:disabled
        background-color lighten($brand-black, 90%)
        color lighten($brand-black, 30%)

    &__button-toggle
      &:not(^[1]_is-active)
        state-background(#fff, $brand-success)

        svg, i
          opacity .25

          ^[1]:hover&
            color #fff
            opacity 1

      &_is-active
        state-background(#fff, $brand-danger)
        state-color($brand-success, #fff)

    &__button-edit
      state-background(#fff, $brand-info)
      state-color($brand-info, #fff)
      position relative

      &_is-blink
        animation-duration .5s
        animation-name widget-settings-scenarios-item-button-blink

        +before()
          border-radius 50%
          absolute-parent()
          opacity 1
          box-shadow 0 0 3px 1px $brand-info
          animation-duration .5s
          animation-name widget-settings-scenarios-item-button-before-blink

    &__button-save
      state-background(#fff, $brand-success)
      state-color($brand-success, #fff)

    &__button-cancel
      state-background(#fff, $brand-danger)
      state-color($brand-danger, #fff)

    &__button-delete
      state-background(#fff, $brand-danger)
      state-color($brand-danger, #fff)

    &__button-reset-counters
      margin-top 15px

    &__condition-box
      flex()

      +not-last()
        margin-bottom 10px

    &__condition-box-name
    &__condition-box-enabled
    &__condition-box-installed
      flex(center, center)

    &__condition-box-name
      width 100%

    &__condition-box-enabled
      flex-shrink 0
      width 40px

    &__condition-box-installed
      flex-shrink 0
      width 60px

    &__condition-box-hint
      //min-height 70px
      margin-top 10px
      typography(12px, 14px)

  @keyframes widget-settings-scenarios-item-button-blink
    0%
      background-color $brand-info
      color #fff
    100%
      background-color #fff
      color $brand-info

  @keyframes widget-settings-scenarios-item-button-before-blink
    0%
      position-all(0)
      opacity 1
    100%
      position-all(-15px)
      opacity 0

  div .control-textarea__container textarea .form-control
    background: #fff

</style>
