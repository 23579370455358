<template>
  <div :class="b({ isDisabled: disabled, direction })">
    <label
      v-if="labelBefore"
      :class="b('label-before')"
      :for="vId"
    >
      <span v-html="labelBefore"></span>
      <HintHelper
        v-if="hintText"
        :class="b('hint-helper')"
        :content="hintText"
      />
    </label>

    <div :class="b('container')">
      <input
        :id="vId"
        class="form-control"
        :type="type"
        :value="localValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handlerInput"
        @change="handlerChange"
      />
    </div>

    <label
      v-if="labelAfter"
      :class="b('label-after')"
      :for="vId"
      v-html="labelAfter"
    ></label>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';
  import HintHelper from 'general/components/HintHelper';

  export default {
    name: 'control-input',

    mixins: [
      baseMixin,
    ],

    components: {
      HintHelper,
    },

    model: {
      prop: 'value',
      event: 'update',
    },

    props: {
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return ['password', 'text', 'email', 'number'].includes(value);
        },
      },
      value: {
        type: [String, Number],
      },
      placeholder: {
        type: [String, Number],
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Активация отслеживания на каждый ввод данных (иначе отслеживание только на потерю фокуса)
       */
      inputReactivity: {
        type: Boolean,
        default: false,
      },

      /**
       * Настраивает порядок отображения описаний и поля
       */
      direction: {
        type: String,
        default: 'vertical',
        validator(value) {
          return ['vertical', 'horizontal'].includes(value);
        },
      },

      labelBefore: {
        type: String,
      },
      labelAfter: {
        type: String,
      },
      hintText: {
        type: String,
      },
    },

    data: () => ({
      localValue: null,
    }),

    watch: {
      value: {
        handler(data) {
          this.localValue = data;
        },
        immediate: true,
      },
    },

    methods: {
      handlerInput(event) {
        if (this.inputReactivity) {
          this.handlerUpdate(event);
        }
      },

      handlerChange(event) {
        this.handlerUpdate(event);
      },

      handlerUpdate(event) {
        const value = event.target.value;

        this.localValue = value;
        this.$emit('update', value);
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-input
    display flex

    &_direction_vertical
      flex-direction column

    &_direction_horizontal
      flex-direction row

    &_is-disabled
      cursor not-allowed

    > label
      ~/_is-disabled&
        pointer-events none

    &__label-before
      ~/_direction_vertical &
        margin-bottom 10px
        typography(14px)

      ~/_direction_horizontal &
        display flex
        align-items flex-end
        margin-right 10px

    &__container
      width 100%

      > input
        &:disabled
          pointer-events none

    &__label-after
      margin-left 10px
</style>
