export default {
  id: 0,
  type: 'Widget Type',
  attributes: {
    applicationId: 0,
    widgetConfigurationId: 0,
    json_data: {
      name: 'Widget type name',
      code: '',
      active: true,
      countScenarios: 0,
      types: [],
      modal: {
        header: {
          shown: true,
          title: '',
          icon: {
            src: '',
          },
          shownButtonClose: false,
        },
        footer: {
          shown: true,
        },
      },
      components: [],
    },
  },
};
