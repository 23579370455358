import { backgroundDefault } from './helper';
import listItem from './listItem';

export default {
  name: 'mbst-list',
  aliasName: 'List & Swipe',
  properties: {
    backendname: 'list',
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-list',
        rules: [
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-list',
        rules: [
          ...backgroundDefault,
        ]
      }
    ]
  },
  items: [
    listItem,
    listItem,
    listItem,
  ]
}
