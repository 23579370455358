import { backgroundDefault } from './helper';
import rowItem from './rowItem';

export default {
  name: 'mbst-row',
  aliasName: 'Row',
  properties: {
    backendname: 'row',
    shadow: '',
    shadowPosition: '',
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-row',
        rules: [
          { directive: 'width', value: '100%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-row',
        rules: [
          { directive: 'width', value: '100%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ]
  },
  items: [
    rowItem,
    rowItem,
  ]
}
