var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 12.11 9.28",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.11 1.81a.72.72 0 0 1-.22.53L6.23 8 5.17 9.06a.75.75 0 0 1-1.06 0L3 8 .22 5.17a.75.75 0 0 1 0-1.06L1.28 3a.72.72 0 0 1 .53-.22.72.72 0 0 1 .53.22l2.3 2.3L9.77.22a.75.75 0 0 1 1.06 0l1.06 1.06a.73.73 0 0 1 .22.53z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }