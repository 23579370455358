<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 12 12"
    :width="width"
    :height="height"
  >
    <path
      d="M7 5h4a1 1 0 0 1 0 2H7v4a1 1 0 0 1-2 0V7H1a1 1 0 0 1 0-2h4V1a1 1 0 0 1 2 0v4z"
    ></path>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-plus',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
