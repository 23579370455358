var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 22.957 22.957",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M16.947.42H5.019L0 4.908v17.594h9.1v-1.578H1.778V6.628h5.149v-4.63h8.243v7.983h1.777z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M16.433 13.266l3.779-3.78 2.745 2.745-3.779 3.779 3.779 3.784-2.745 2.744-3.779-3.779-3.784 3.779-2.744-2.744 3.78-3.784-3.78-3.779 2.744-2.745z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }