import IconComponentWText from './IconComponentWText';
import IconComponentWImage from './IconComponentWImage';
import IconComponentWBots from './IconComponentWBots';
import IconComponentWA2hs from './IconComponentWA2hs';
import IconComponentWVcard from './IconComponentWVcard';

export default {
  IconComponentWText,
  IconComponentWImage,
  IconComponentWBots,
  IconComponentWA2hs,
  IconComponentWVcard,
};
