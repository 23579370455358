export default {
  id: 0,
  type: 'Widget Scenario',
  attributes: {
    applicationId: 0,
    widgetTypeId: 0,
    json_data: {
      active: true,
      conditions: {
        icon: {
          enabled: false,
          installed: false,
        },
        vCard: {
          enabled: false,
          installed: false,
        },
        bots: {
          enabled: false,
          installed: false,
        },
        push: {
          enabled: false,
          installed: false,
        },
      },
      where: {
        type: '',
        value: '',
      },
      delay: 0,
      counters: {
        inSession: 0,
        total: 0,
      },
      description: '',
    },
  },
};
