import Vue from 'vue';
import Router from 'vue-router';
// import store from '../store';

const Main = () => import('../pages/main');
const Tokens = () => import('../pages/Tokens.vue');
const ActionJournal = () => import('../pages/ActionJournal.vue');
const AppSettings = () => import('../pages/application/settings/General.vue');
const Widgets = () => import('../pages/application/Widgets.vue');
const PwaLess = () => import('../pages/application/pwaless/Index.vue');
const PwaLessWidgets = () => import('../pages/application/pwaless/Widgets.vue');
const PwaLessWidget = () => import('../pages/application/pwaless/Widget.vue');
const Builder = () => import('../pages/application/Builder.vue');
const ChatBots = () => import('../pages/application/ChatBots.vue');
const Objects = () => import('../pages/application/Objects.vue');
const Events = () => import('../pages/application/Events.vue');
const Statuses = () => import('../pages/application/Statuses.vue');
const Security = () => import('../pages/application/Security.vue');
const InvitationNotications = () => import('../pages/application/InvitationNotifications.vue');
const ApiLogs = () => import('../pages/ApiLogs.vue');
const Partners = () => import('../pages/Partners.vue');
const HomeLists = () => import('../pages/application/lists/Index.vue');
const Lists = () => import('../pages/application/lists/Lists.vue');
const ListTables = () => import('../pages/application/lists/Tables.vue');
const ListTable = () => import('../pages/application/lists/Table.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/',                        name: 'dashboard',      component: Main },
    { path: '/tokens/index',            name: 'tokens',         component: Tokens },
    { path: '/actionjournal/index',     name: 'action-journal', component: ActionJournal },
    { path: '/myprofile/index',         name: 'profile' },

    // General
    { path: '/users/index/:appId',          name: 'users' },
    { path: '/accountsettings/index',       name: 'account-settings' },
    { path: '/senderpool/index/:appId',     name: 'outbox' },

    // Integrations
    { path: '/apiproviders/index',          name: 'api-providers' },
    { path: '/logs/index',                  name: 'api-logs',                 component: ApiLogs },

    // App Setup
    { path: '/applications/edit/:appId/2',  name: 'app-settings',             component: AppSettings,     props: true },
    { path: '/applications/edit/:appId/3',  name: 'builder',                  component: Builder,         props: true },
    { path: '/applications/edit/:appId/4',  name: 'security',                 component: Security },
    { path: '/widgetsettings/edit/:appId',  name: 'widgets',                  component: Widgets },
    { path: '/pwaless/index/:appId',        name: 'pwaless',                  component: PwaLess,         props: true,
      children: [
        { path: '',                         name: 'pwaless-widgets',          component: PwaLessWidgets,  props: true },
        { path: 'widgets/:widgetId',        name: 'pwaless-widget',           component: PwaLessWidget,   props: true },
      ]
    },
    { path: '/bot/edit/:appId',             name: 'chat-bots',                component: ChatBots },
    { path: '/applications/edit/:appId/7',  name: 'invitation-notifications', component: InvitationNotications },

    // App Data
    { path: '/objects/index/:appId',                name: 'objects',          component: Objects },
    { path: '/backend/index/:appId',                name: 'events',           component: Events },
    { path: '/lists/index/:appId/',                 name: 'home-lists',       component: HomeLists,   props: true,
      children: [
        { path: '',                                 name: 'lists',            component: Lists,       props: true },
        { path: 'tables/:listId',                   name: 'list-tables',      component: ListTables,  props: true },
        { path: 'tables/:listId/table/:tableName',  name: 'list-table',       component: ListTable,   props: true },
      ]
    },
    { path: '/statuses/edit/:appId',                name: 'statuses',         component: Statuses },
    { path: '/partners/index',                      name: 'partners',         component: Partners },

    // ** New routes
    // { path: '/applications/:id/settings/general', component: () => import('./pages/applications/settings/General.vue') },
    // { path: '/applications/:id/lists', name: 'lists', component: () => import('./pages/applications/Lists.vue') },
  ]
})

// router.beforeEach((to, from, next) => {
  // console.log(store);
  // store.dispatch('application/test');
  // store.commit('application/set', { 'path': 'id', payload: to.params.id });
  // next();
// })

export default router;
