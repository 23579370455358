const constructorState = {
  os: 'ios',
  media: 'xs',
  clipboard: '',
  devmode: {
    renderHashtags: true,
    displayOncomponentLabels: true,
    enableLiveMode: false
  }
}

export default {
  ...constructorState,
}
