'use strict';

// Libraries
import logger from 'general/logger';

var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Models
var OperationModel = require('models/OperationModel');
var TriggerOperationItemModel = require('models/TriggerOperationItemModel');

// Views
var TransformationsView = require('views/transformations/TransformationsView');
var ConditionalDataTransformationView = require('views/ConditionalDataTransformationView');
var ExecutionView = require('views/execution/ExecutionView');
var ExecuteAfterView = require('views/execute-after/ExecuteAfterView');
var APIProvidersView = require('views/apiproviders/accordion/ApiProvidersView');

// Templates
var _triggerOperationSettings = require('text!bcTemplates/triggerOperationSettings.html');

// Helper
var PubSub = require('helper/pubsub');

const ApiAppsCollection = Backbone.Collection.extend({
  filterKey: function (apiKeyId) {
    return _.filter(this.models, function (apiApp) {
      return apiApp.get('apiKeyId') === apiKeyId;
    });
  },
});


// INIT in TriggerOperationsView
// ===================================================
module.exports = Backbone.View.extend({

  tagName: 'div',
  className: 'details-body b-modal-hero__body b-global-box',
  id: 'triggerOperationSettings',

  events: {
    'click #triggerOperationCancel': 'close', // возврат к списку операций
    'click #triggerOperationCancelOnTopPanel': 'close', // возврат к списку операций
    'click #triggerOperationSave': 'save', // сохранение настроек триггера
    'click #addTransformation': 'addTransformation',
  },

  bindings: {
    '#OperationName': 'Name',
    '#apiKeyId': {
      observe: 'APIKeyId',
      initialize: function ($el) {
        this.selectTwoBinding($el);
      },
      selectOptions: {
        collection: function () {
          return this.apiKeys;
        },
        defaultOption: {
          label: 'Choose API key...',
          value: null
        },
        labelPath: 'Name',
        valuePath: 'id'
      },
      onSet: function (val) {
        this.filterApiApps(val);
        if (!val) this.model.set('APIApplicationId', null);
        this.model.set('APIKeyId', val);
        return val;
      }
    },
    '#apiApplicationId': {
      observe: 'APIApplicationId',
      initialize: function ($el) {
        this.selectTwoBinding($el);
      },
      selectOptions: {
        collection: function () {
          return this.apiApps;
        },
        defaultOption: {
          label: 'Choose API Application ...',
          value: null,
        },
        labelPath: 'name',
        valuePath: 'id',
      },
      onSet: function (val) {
        this.model.set('APIApplicationId', val);
        return val;
      },
    },
  },

  initialize: function (options) {

    var t = this;
    t.options = options || {};
    t.operationView = t.options.operationView || null;
    t.operationsView = t.options.operationsView || null;
    t.model = new OperationModel(t.options.model);
    t.tpl = _.template(_triggerOperationSettings);

    t.apiKeys = $.extend(true, [], MOBSTEDAPP.APIKeys);
    t.apiApps = $.extend(true, [], MOBSTEDAPP.APIApps);

    PubSub.on('setConditionalDataTransformations', t.setConditionalDataTransformations, t);

    this.apiAppsCollection = new ApiAppsCollection($.extend(true, [], MOBSTEDAPP.APIApps));
    this.filterApiApps(this.model.get('APIKeyId'));

    t.dataTransformationsView = new TransformationsView({
      title: '',
      collection: t.model.get('DataTransformations')
    });
    t.conditionalDataTransformationView = new ConditionalDataTransformationView({
      collection: t.model.get('ConditionalDataTransformations')
    });

    t.executionView = new ExecutionView({
      parentView: t,
      collection: t.getDataExecution(t.model.get('APIProviderId'), t.model.get('APIProviderMethodId'))
    });

    var ExecuteAfterModel = Backbone.Model.extend();
    t.executeAfterView = new ExecuteAfterView({
      parentView: t,
      model: new ExecuteAfterModel({
        successOperationId: t.operationView.model.get('success') ?
          t.operationView.model.get('success').operation.id :
          null,
        successOperation: t.operationsView.collection.toJSON(),
        failOperationId: t.operationView.model.get('fail') ?
          t.operationView.model.get('fail').operation.id :
          null,
        failOperation: t.operationsView.collection.toJSON(),
      })
    });
    console.log('OperationView', t.operationView);

    t.apiProvidersView = new APIProvidersView({
      apiProviderId: t.model.get('APIProviderId'),
      apiProviderMethodId: t.model.get('APIProviderMethodId'),
      triggerOperationSettingsView: t,
      collection: MOBSTEDAPP.APIProviders
    });
  },

  filterApiApps: function (apiKeyId) {
    this.apiAppsCollection = new ApiAppsCollection($.extend(true, [], MOBSTEDAPP.APIApps));
    this.apiApps = new ApiAppsCollection(this.apiAppsCollection.filterKey(apiKeyId));
    this.stickit();
  },

  render: function () {
    var t = this;
    console.info('Operation Model:');
    console.log(t.model);
    t.$el.empty();

    var $triggerOperationsSettingsView = t.tpl({
      operation: t.model.toJSON(),
      apiKeys: t.apiKeys,
      apiApps: t.apiApps
    });
    t.$el.html($triggerOperationsSettingsView);

    // Render Data Transformations
    t.$el.find('#DataTransformations .b-collapse__body').append(t.dataTransformationsView.render());

    // Render Conditional Data Transformations
    t.conditionalDataTransformationView.setElement(t.$el.find('#ConditionalDataTransformation .b-collapse__body'));
    t.conditionalDataTransformationView.render();

    // Render Execution
    t.renderExecution();

    // @todo MBST3.1
    // Render Execute After Operation
    //t.executeAfterView.render();
    //t.$('#ExecuteAfterOperation .b-collapse__body').append(t.executeAfterView.$el);
    // @todo END

    // Render API Providers
    t.apiProvidersView.render();
    t.$('.b-apiproviders').append(t.apiProvidersView.$el);

    $('#tabpane-trigger').append(t.$el);

    t.$el.css('padding-bottom', t.$('>.b-global-box__footer').outerHeight());

    var hBox = t.$('.b-trigger-operations__settings>.b-global-box__body').outerHeight();
    var d = t.$('.b-trigger-operations__settings>.b-global-box__body .b-collapse:last').outerHeight();

    t.$('.b-trigger-operations__settings>.b-global-box__body')
      .css('padding-bottom', hBox - d);

    // Data binding
    t.stickit();
    _.each(t.dataTransformationsView.itemViews, function (itemView) {
      var bindings = {
        '[name="dataTransformationsKey"]': 'key',
        '[name="dataTransformationsValue"]': 'value'
      };
      itemView.stickit(itemView.model, bindings);
    });

    _.each(t.conditionalDataTransformationView.itemViews, function (itemView) {
      _.each(itemView.conditionsViews.itemViews, function (conditionsView) {
        var bindings = {
          '[name="dataConditionsField"]': 'field',
          '[name="dataConditionsValue"]': 'value',
          '[name="dataConditionsOperation"]': {
            observe: 'operation',
            selectOptions: {
              collection: function () {
                return conditionsView.conditionRulesView.operandTpls.operand.value;
              }
            }
          }
        };
        conditionsView.stickit(conditionsView.model, bindings);
      });
      _.each(itemView.transformationsViews.itemViews, function (transformationsView) {
        var bindings = {
          '[name="dataTransformationsKey"]': 'key',
          '[name="dataTransformationsValue"]': 'value'
        };
        transformationsView.stickit(transformationsView.model, bindings);
      });
    });

    // TODO: переделать на универсальное говнишко
    t.selectTwo();
  },

  getDataExecution: function (apiProviderId, apiProviderMethodId) {
    // TODO: поножевщина, переделать потом на чо нить нормальное, сейчас хуяк-хуяк и в продакшен
    var t = this;

    if (apiProviderId) {
      var currentApiProvider = _.find(MOBSTEDAPP.APIProviders, function (item) {
        return item.id === apiProviderId;
      }) || null;
      if (!currentApiProvider) return [];
      var capm = _.find(currentApiProvider.APIProviderMethods, function (item) {
        return item.id === apiProviderMethodId;
      });
      var currentApiProviderMethod = [];

      try {
        currentApiProviderMethod = JSON && JSON.parse(capm.Params) || $.parseJSON(capm.Params);
      } catch (error) {
        logger.exception(error, capm.Params);
      }

      _.each(currentApiProviderMethod, function (method) {
        method.isSystem = true;
      });
      var params = currentApiProviderMethod.concat(t.model.get('ExtendedParams'));
      var values = t.model.get('ParamsValues');

      return _.map(params, function (item) {
        if (values[item.name]) {
          item.value = values[item.name];
        } else {
          item.value = '';
        }
        return item;
      });

    } else {
      return [];
    }
  },

  // Render Execution Block
  renderExecution: function () {
    this.executionView.render();
    this.$('#Execution .b-collapse__body .b-execution__box').html(this.executionView.$el);
  },

  // TODO: сейчас вызывается при каждом изменении параметров, переделать только на сохранение изменений, хер знает
  setConditionalDataTransformations: function (data) {
    var dataTransformations = this.dataTransformationsView.collection.toJSON() || [],
      conditionalDataTransformation = this.conditionalDataTransformationView.collection.toJSON() || [];

    this.model.set({
      DataTransformations: dataTransformations,
      ConditionalDataTransformations: conditionalDataTransformation
    });
  },

  close: function (e) {
    e.preventDefault();
    PubSub.off('setConditionalDataTransformations');
    this.conditionalDataTransformationView.close();
    this.dataTransformationsView.close();
    this.undelegateEvents();
    this.$el.removeData().unbind();
    this.remove();
    this.operationView.triggerOperationsView.triggerView.fetchTriggerOperations();
  },

  makeTriggerOperationItemModel: function(operationId, obj) {
    var t = this,
      level = t.operationView.model.get('triggerOperation').Level || 0,
      operationModel = t.operationsView.collection.get(operationId),
      dataModel = {
        triggerOperation: {
          id: obj ? obj.id : null,
          TriggerId: t.operationView.triggerOperationsView.triggerView.model.get('id'),
          OperationId: operationId,
          SortOrder: obj ? obj.SortOrder : null,
          SuccessOperationId: obj ? obj.SuccessOperationId : null,
          FailOperationId: obj ? obj.FailOperationId : null,
          AlwaysOperationId: obj ? obj.AlwaysOperationId : null,
          Level: obj ? obj.Level : level + 1
        },
        operation: operationModel ? operationModel.get('operation') : null,
        operationExt: operationModel ? operationModel.get('operationExt') : null,
      },
      model = new TriggerOperationItemModel(dataModel);

    return {
      getModel: function () {
        return model;
      },
      getObject: function() {
        return model.toJSON();
      }
    }
  },

  save: function (e) {
    e.preventDefault();
    this.$el.find('#triggerOperationCancel').attr('disabled', true);
    this.$el.find('#triggerOperationSave').attr('disabled', true);

    var t = this,
      successOpt = this.operationView.model.get('success'),
      successOptId = t.executeAfterView.model.get('successOperationId'),
      failOpt = this.operationView.model.get('fail'),
      failOptId = t.executeAfterView.model.get('failOperationId');

    if (successOptId) {
      var successObj = successOpt ? successOpt.triggerOperation : null;
      var successTriggerOperationItemModel = t.makeTriggerOperationItemModel(successOptId, successObj);
      this.operationView.model.set('success', successTriggerOperationItemModel.getObject());
    } else {
      this.operationView.model.set('success', null);
    }
    if (failOptId) {
      var failObj = failOpt ? failOpt.triggerOperation : null;
      var failTriggerOperationItemModel = t.makeTriggerOperationItemModel(failOptId, failObj);
      this.operationView.model.set('fail', failTriggerOperationItemModel.getObject());
    } else {
      this.operationView.model.set('fail', null);
    }

    var opExt = this.operationView.model.get('operationExt');

    if (t.model.get('APIKeyId'))
      opExt.APIKeyName = _.find(MOBSTEDAPP.APIKeys, function (key) {
        return key.id === t.model.get('APIKeyId')
      }).Name;

    if (t.model.get('APIApplicationId'))
      opExt.APIApplicationName = _.find(MOBSTEDAPP.APIApps, function (app) {
        return app.id === t.model.get('APIApplicationId')
      }).name;

    this.operationView.model.set({
      operation: this.model.toJSON(),
      operationExt: opExt
    });

    // return false

    this.operationView.save();
  },

  // Навешивает на селекты seletc2
  // ----------------------------------------------------
  selectTwo: function () {

    $('.b-trigger__settings .b-constructor__select').each(function () {

      var select = $(this).children('select'),
        options = {};

      if ($(this).attr('data-placeholder') && $(this).attr('data-placeholder') !== '') {
        options.placeholder = $(this).attr('data-placeholder');
      }

      switch (select.attr('data-type')) {
        case 'tags':
          options.tags = true;
          break;

        case 'nosearch':
          options.minimumResultsForSearch = -1;
          break;

      }

      if (!select.hasClass('select2-hidden-accessible')) {
        select.select2(options);
      }

    });

  },

  selectTwoBinding: function (select) {

    var options = {};

    switch (select.attr('data-type')) {
      case 'tags':
        options.tags = true;
        break;

      case 'nosearch':
        options.minimumResultsForSearch = -1;
        break;

    }

    if (!select.hasClass('select2-hidden-accessible')) {
      select.select2(options);
    }

  },


});