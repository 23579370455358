var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b({ isDisabled: _vm.disabled, hasFail: _vm.errors.length }) },
    [
      _vm.labelBefore
        ? _c("label", {
            class: _vm.b("label-before"),
            attrs: { for: _vm.vId },
            domProps: { innerHTML: _vm._s(_vm.labelBefore) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.b("container") }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { id: _vm.vId, type: "text", disabled: _vm.disabled },
          domProps: { value: _vm.result },
          on: { input: _vm.handlerInput, change: _vm.handlerChange },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.b("controls") }, [
          _c(
            "button",
            {
              class: _vm.b("button-increment"),
              attrs: { disabled: _vm.disabled || _vm.value === _vm.max },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.increase.apply(null, arguments)
                },
              },
            },
            [_c("IconPlus", { attrs: { width: "10" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.b("button-decrement"),
              attrs: { disabled: _vm.disabled || _vm.value === _vm.min },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.decrease.apply(null, arguments)
                },
              },
            },
            [_c("IconMinus", { attrs: { width: "10" } })],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.labelAfter
        ? _c("label", {
            class: _vm.b("label-after"),
            attrs: { for: _vm.vId },
            domProps: { innerHTML: _vm._s(_vm.labelAfter) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }