var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M64 0a63.31 63.31 0 0 1 32 8.56A65 65 0 0 1 119.44 32a64.09 64.09 0 0 1 0 64A65 65 0 0 1 96 119.44a64.09 64.09 0 0 1-64 0A65 65 0 0 1 8.56 96a64.09 64.09 0 0 1 0-64A65 65 0 0 1 32 8.56 63.31 63.31 0 0 1 64 0zm32 68.56a5.28 5.28 0 0 0 0-9.12L50.64 32.72a5 5 0 0 0-5.28 0 5.13 5.13 0 0 0-2.72 4.72v53.28a5.13 5.13 0 0 0 2.72 4.72A6.48 6.48 0 0 0 48 96a5 5 0 0 0 2.64-.72z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }