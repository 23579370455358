<template>
  <IconBase
    :icon-name="$options.name"
    :width="width"
    :height="height"
  >
    <g>
      <circle
        fill="#62a5d9" cx="64" cy="64" r="64"
      ></circle>
      <path
        fill="#fff"
        d="M95.14 36.82L83.71 94.45s-1.6 4-6 2.08L51.34 76.3l-9.6-4.63-16.14-5.44s-2.48-.88-2.72-2.8 2.8-3 2.8-3L89.87 35.3s5.27-2.3 5.27 1.52z"
      ></path>
      <path
        fill="#d7e4f0"
        d="M49.31 93.8s-.77-.07-1.73-3.11-5.84-19-5.84-19l38.77-24.64s2.24-1.36 2.16 0c0 0 .4.24-.8 1.36S51.42 75.82 51.42 75.82"
      ></path>
      <path
        fill="#bccee2"
        d="M61.45 84.06L51 93.57a1.83 1.83 0 0 1-1.71.23l2-17.67"
      ></path>
    </g>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-telegram',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
