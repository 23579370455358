import { makeControlProps, backgroundProps, typographyProps } from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.name',
    label: 'Screen Name'
  }),
  makeControlProps('select', {
    pathValue: 'properties.leftpanel',
    label: 'Left panel',
    options: [
      {label: 'Show', value: true},
      {label: 'Hide', value: false},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.leftpanelBehavior',
    label: 'Left panel behavior',
    options: [
      {label: 'Default', value: 'default'},
      {label: 'Desktop', value: 'desktop'},
      {label: 'Mobile', value: 'mobile'}
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.rightpanel',
    label: 'Right panel',
    options: [
      {label: 'Show', value: true},
      {label: 'Hide', value: false},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.rightpanelBehavior',
    label: 'Right panel behavior',
    options: [
      {label: 'Default', value: 'default'},
      {label: 'Desktop', value: 'desktop'},
      {label: 'Mobile', value: 'mobile'}
    ]
  })
]
// const actions = [
//   makeControlProps('checkbox', {
//     pathValue: 'properties.createEvent',
//     label: 'Create Event and Save all screen data to it'
//   })
// ]
const view = [
  {
    selector: '',
    controls: [
      typographyProps,
      // Object.assign({}, backgroundProps, { label: 'Main background' }),
    ],
  },
  {
    selector: '.screen .screen__content',
    controls: [
      Object.assign({}, backgroundProps, { label: 'Content background' }),
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width',
        },
        maxWidth: {
          label: 'Max. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-width',
        },
        minWidth: {
          label: 'Min. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-width',
        },
      }),
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
    ],
  },
]

export default {
  properties,
  view,
  actions: [true]
}
