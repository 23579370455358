const MU_DEV = 'https://localhost:3000'
const IS_DEV = process.env.NODE_ENV === 'development'

export const NODE_ENV = process.env.NODE_ENV

export const BASE_URL = 'https://##TENANT##-admin.##SERVER##'
// export const BASE_URL = 'https://brusnika-admin.mbst.xyz'

export const MU_URL = IS_DEV ? MU_DEV : document.location.origin.replace('-admin', '')

export const WIDGETS = {
  REMIND_TIMEOUT:  15*60, // 15 min
  SESSION_TIMEOUT: 30*60, // 30 minutes

};
