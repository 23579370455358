var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 95.09 128",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M47.54 17.44V0L23.77 23.26l23.77 23.3V29.07A35.42 35.42 0 0 1 83.2 64 36.78 36.78 0 0 1 79 80.27L88 89a51.26 51.26 0 0 0 7.14-25 47.22 47.22 0 0 0-47.6-46.56zm0 81.49A35.41 35.41 0 0 1 11.89 64 36.77 36.77 0 0 1 16 47.73L7.13 39A51.14 51.14 0 0 0 0 64a47.2 47.2 0 0 0 47.54 46.56V128l23.77-23.26-23.77-23.3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }