import { makeControlProps, loopControlProps, visibilityProps, typographyProps } from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('options', {
    type: 'collapse',
    pathValue: 'properties.options',
    label: 'Options'
  }),
  visibilityProps,
  loopControlProps,
];

const view = [
  {
    selector: '.mbst-checkboxes',
    label: "Checkbox item",
    controls: [typographyProps],
  },
  {
    selector: '.mbst-checkboxes .list',
    label: 'Group',
    controls: [
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width'
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height'
        }
      }),
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      makeControlProps('background', {
        label: 'Background',
        color: {
          label: 'Color',
          pathValue: 'background-color'
        }
      })
    ]
  }
];

const actions = [];

export default {
  properties,
  view,
  actions
}
