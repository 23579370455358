<template>
  <div
    :class="b()"
    class="popovers"
    :title="content"
    data-toggle="popover"
    data-html="true"
    :data-content="content"
    :data-placement="placement"
    :data-trigger="trigger"
  >
    ?
  </div>

  <!-- TODO: удалить атрибут title, когда исправится работа tooltip'а -->

  <!--
    data-container="body"
    :title="content"
  -->
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'hint-helper',

    mixins: [
      baseMixin,
    ],

    props: {
      content: {
        type: String,
        required: true,
      },
      placement: {
        type: String,
        default: 'right',
      },
      trigger: {
        type: String,
        // default: 'hover',
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .hint-helper
    reset-button()
    flex(center, center)
    size(20px)
    state-background(#fff, $brand-info)
    state-color($brand-black, #fff)
    link()
    border-radius 50%
    typography(14px)
    font-weight 700
</style>
