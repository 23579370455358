var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b({ isDisabled: _vm.disabled }) }, [
    _vm.labelBefore
      ? _c("label", {
          class: _vm.b("label-before"),
          attrs: { for: _vm.vId },
          domProps: { innerHTML: _vm._s(_vm.labelBefore) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.b("container") }, [
      _c("textarea", {
        staticClass: "form-control",
        attrs: { id: _vm.vId, disabled: _vm.disabled },
        domProps: { innerHTML: _vm._s(_vm.localValue) },
        on: { input: _vm.handlerInput, change: _vm.handlerChange },
      }),
    ]),
    _vm._v(" "),
    _vm.labelAfter
      ? _c("label", {
          class: _vm.b("label-after"),
          attrs: { for: _vm.vId },
          domProps: { innerHTML: _vm._s(_vm.labelAfter) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }