export default {
  uuid: null,
  type: 'base',
  name: 'w-vcard',
  aliasName: 'WVcard',
  iconPreview: {
    component: 'IconComponentWVcard',
    width: 41,
  },
  properties: {
    companyName: '',
    companyEmail: '',
    companyPhone: '',
  },
  actions: {},
};
