var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.b(false),
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        role: "presentation",
        viewBox: _vm.viewBox,
        width: _vm.width,
        height: _vm.height,
        "aria-labelledby": _vm.iconName,
        fill: _vm.iconColor,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }