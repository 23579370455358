<i18n src="general/lang/WidgetWA2hs.json"></i18n>

<template>
  <div :class="b({ 'line': isDeferredA2HS })">
    <div
      v-if="editorMode"
      :class="b('mask')"
    ></div>

    <template>
      <div :class="b('presentation')">
        <div :class="b('app-info')">
          <div :class="b('app-icon')">
            <div
              v-if="appLogo.style"
              :class="b('app-logo')"
              :style="appLogo.style"
            ></div>
            <component
              v-else-if="appLogo.component"
              :is="appLogo.component"
              width="40"
            />
          </div>
          <div :class="b('app-name')">
            {{ appName }}
          </div>
        </div>

        <div :class="b('icon-arrow')">
          <IconArrowCurvedRight width="40" />
        </div>

        <div :class="b('icon-smartphone')">
          <IconSmartphone width="48" />
        </div>
      </div>

      <button
        v-if="!isDeferredA2HS"
        :class="b('button-action')"
        @click.prevent="showInstruction"
      >
        <div :class="b('icon-play')">
          <IconPlayCircle width="30" />
        </div>

        <span>
           {{ buttonText }}
        </span>
      </button>

      <button
        v-else
        :class="b('button-a2hs')"
        @click.prevent="onA2HSPromt"
      >
        {{ $t('WidgetWA2hs.add') }}
      </button>

    </template>

    <modal
      v-if="!editorMode"
      :name="modalCode"
      class="w-a2hs-modal"
      :adaptive="true"
      :clickToClose="false"
      height="auto"
      transition="modal-pop-inout"
    >
      <button
        class="w-a2hs-modal__button-close-instruction"
        @click.prevent="hideInstruction"
      >
        <IconClose width="16" />
      </button>

      <div class="w-a2hs-modal__instruction">
        <img :src="instructionPath" alt="" />
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import UAParser from 'ua-parser-js';
  import logger from 'general/logger';
  import baseMixin from 'general/mixins/baseMixin';
  import IconClose from 'general/components/icons/IconClose';
  import IconLogo from 'general/components/icons/IconLogo';
  import IconPlayCircle from 'general/components/icons/IconPlayCircle';
  import IconArrowCurvedRight from 'general/components/icons/IconArrowCurvedRight';
  import IconSmartphone from 'general/components/icons/IconSmartphone';

  const INSTRUCTIONS = new Set([
// for android os
    'android-opera-en.gif',
    'android-opera-ru.gif',
    'android-firefox-en.gif',
    'android-firefox-ru.gif',
    'android-yandex-en.gif',
    'android-yandex-ru.gif',
    'android-androidbrowser-en.gif',
    'android-androidbrowser-ru.gif',
    'android-chrome-en.gif',
    'android-chrome-ru.gif',

// for ios
    'ios-mobilesafari-en.gif',
    'ios-mobilesafari-ru.gif',
    'ios-13-mobilesafari-en.gif',
    'ios-13-mobilesafari-ru.gif',
    'ios-telegram-en.gif',
    'ios-telegram-ru.gif',
    'ios-vk-en.gif',
    'ios-vk-ru.gif',
  ]);

  const MSNS = [
    'telegram',
    'vk',
  ];

  const referrerMark = [
    'telegram.messenger',
    'vk.com',
  ];

  export default {
    name: 'w-a2hs',

    mixins: [
      baseMixin,
    ],

    components: {
      IconClose,
      IconPlayCircle,
      IconArrowCurvedRight,
      IconSmartphone,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      buttonText: {
        type: String,
      },

      widgetType: {
        type: Object,
      },
    },

    data: () => ({
      isShown: false,
      isShowInstruction: false,
    }),

    computed: {
      ...mapState({
        tenant: state => state.tenant || '',
        applicationId: state => state.applicationId || 0,
        lang: state => state.lang || '',
        application: state => state.application || null,
      }),

      ...mapGetters({
        baseUrl: 'getBaseUrl',
      }),

      ua: vm => new UAParser(window.navigator.userAgent),
      os: vm => vm.ua.getOS().name.toLowerCase().replace(' ', ''),
      osVersion: vm => vm.ua.getOS().version,
      browser: vm => vm.ua.getBrowser().name.toLowerCase().replace(' ', ''),

      browserLang() {
        let lang = navigator.language || navigator.userLanguage;
        return lang.replace(/-.+/g, '');
      },

      referrer() {
        return document.referrer;
      },

      modalCode() {
        return `${this.vId}-instruction`;
      },

      appName() {
        return (this.application) ? this.application.attributes.Name : '';
      },

      appLogo() {
        let style = null;
        if (!this.editorMode) {
          const url = `${this.baseUrl}/tenants/${this.tenant}/iconapp/${this.applicationId}/icon76x76.png`;
          style = {
            backgroundImage: `url('${url}')`,
          };
        }
        return {
          style,
          component: IconLogo,
        };
      },

      instructionPath() {
        if (this.editorMode) return '';

        const { browser, browserLang, os, osVersion, baseUrl } = this;
        const osV = Number(osVersion.split('.')[0]);
        const instrPath = `${baseUrl}/assets/gif/`;
        let instructionFileName = `${os}${os === 'ios' ? osV >= 13 ? '-13' : '' : ''}-${browser}-${browserLang}.gif`;

        if (!INSTRUCTIONS.has(instructionFileName)) {
          const candyFile = `android-chrome-${browserLang}.gif`;
          if (INSTRUCTIONS.has(candyFile)) {
            instructionFileName = candyFile;
          } else instructionFileName = 'android-chrome-en.gif';
        }

        return `${instrPath}${instructionFileName}`;
      },

      isDeferredA2HS: vm => !vm.editorMode ? !!window.mbst.A2HS.deferredPrompt : false,
    },

    methods: {
      showInstruction() {
        if (this.editorMode) {
          return;
        }
        if (
          this.isDeferredA2HS &&
          this.os == 'android' &&
          this.browser == 'chrome'
        ) {
          window.mbst.A2HS.prompt();
        } else {
          this.$modal.show(this.modalCode);
        }
      },

      onA2HSPromt() {
        if (this.editorMode) return;
        window.mbst.A2HS.prompt();
      },

      hideInstruction() {
        if (this.editorMode) {
          return;
        }
        this.$modal.hide(this.modalCode);
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-a2hs
    padding 10px 25px
    position relative

    &__mask
      absolute-parent(1)

    &__presentation
      flex(center, center)
      margin-x(auto)
      margin-bottom 30px

    &__icon-arrow
      margin-x(12px)
      position relative
      top -10px

    &__app-info
      flex(center, center)
      flex-direction column

    &__app-icon
      flex(center, center)
      size(55px)
      border-radius 8px
      margin-top 8px
      box-shadow 0 1px 4px 0 rgba(#000, .3)
      overflow hidden

    &__app-logo
      size(56px)
      bg-image(cover)

    &__app-name
      margin-top 8px
      typography(11px)

    &__icon-smartphone
      color #999

    &__button-a2hs
      padding 20px 0px 20px 5px
      border 0
      border-radius 0
      color #4682EA
      font-size 18px
      background none
      font-weight 500

      &:focus, &:active
        outline none

    &__button-action
      button()
      button_theme('big')
      display flex
      align-items center
      padding 15px 20px
      margin-x(auto)
      typography(16px)
      color #fff

      span
        margin-top 2px
        margin-left 10px
        text-transform uppercase

    &__icon-play
      flex(center, center)
      color #fff
      position relative

    &_line
      flex(space-between, center)

      .w-a2hs__presentation
        margin-bottom 0
        margin-left 0

  .w-a2hs-modal
    &__instruction
      size(100%)
      flex(center, center)

      img
        max-size(100%)

    &__button-close-instruction
      reset-button()
      flex(center, center)
      size(40px)
      margin-top -20px
      background-color rgba($color-red, .25)
      border 1px solid rgba($color-red, .35)
      color rgba($color-red, .6)
      position absolute
      top 50%
      left 0
      box-shadow 0 1px 5px 0 rgba(0, 0, 0, .3)
</style>
