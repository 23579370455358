'use strict';

import "inputmask/dist/inputmask/jquery.inputmask";
import "inputmask/dist/inputmask/phone-codes/phone";
import Inputmask from "inputmask/dist/inputmask/inputmask.phone.extensions";
export default Inputmask

// module.exports = require("inputmask/dist/inputmask/inputmask");
// require("inputmask/dist/inputmask/inputmask.extensions");
// require("inputmask/dist/inputmask/inputmask.phone.extensions");
// require("inputmask/dist/inputmask/jquery.inputmask");
// require("inputmask/dist/inputmask/phone-codes/phone");