<i18n src="general/lang/WidgetEditorComponentPalette.json"></i18n>

<template>
  <div :class="b()">
    <div :class="b('title')">
      {{ $t('WidgetEditorComponentPalette.title') }}
    </div>

    <div :class="b('list')">
      <vddl-draggable
        v-for="(component, index) in components"
        :key="index"
        :class="b('component')"
        :draggable="component"
        :copied="copied"
        :type="'component'"
        effect-allowed="copy"
        :data-tour="`wc-${component.name}`"
      >
        <div :class="b('component-icon')">
          <component
            v-if="component.iconPreview.component"
            :is="component.iconPreview.component"
            :width="component.iconPreview.width || 100"
          ></component>
        </div>

        <div :class="b('component-name')">
          {{ component.aliasName }}
        </div>
      </vddl-draggable>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import baseMixin from 'general/mixins/baseMixin';
  import widgetComponentIcons from 'general/components/icons/widgetComponentIcons';

  const { mapGetters } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-editor-component-palette',

    mixins: [
      baseMixin,
    ],

    components: {
      ...widgetComponentIcons,
    },

    computed: {
      ...mapGetters({
        components: 'componentPalette/getComponents',
      }),
    },

    methods: {
      copied() {},
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor-component-palette
    width 300px
    border 1px solid #eee

    &__title
      border-bottom 1px dashed #eee
      padding-bottom 5px
      margin-top 5px
      margin-bottom 20px
      typography(14px, 24px)
      text-align center

    &__list
      display flex
      flex-wrap wrap
      padding-x(10px)

    &__component
      flex()
      flex-direction column
      width 50%
      margin-bottom 20px
      link()
      state-background(transparent, rgba($brand-info, .15))
      cursor grab

    &__component-icon
      flex(center, center)
      min-height 50px
      margin-y(auto)

    &__component-name
      margin-top 5px
      text-align center
</style>
