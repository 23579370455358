import {
  makeControlProps,
  loopControlProps,
  backgroundProps,
  visibilityProps,
  typographyProps,
  sizingProps,
} from './helper'

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname',
  }),
  makeControlProps('input', {
    pathValue: 'properties.defaultvalue',
    label: 'Default Value',
  }),
  makeControlProps('select', {
    pathValue: 'properties.iscached',
    label: 'Cache value',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('input', {
    pathValue: 'properties.label',
    label: 'Label',
  }),
  makeControlProps('input', {
    pathValue: 'properties.placeholder',
    label: 'Placeholder',
  }),
  makeControlProps('inputType', {
    pathValue: 'properties.inputType',
    label: 'Input type',
    subcontrols: {
      text: [
        makeControlProps('input', {
          pathValue: 'properties.mask',
          label: 'Input mask',
          hint: '# - digit, S - Letter,<br/>\r\n N - alphanumeric',
        }),
        makeControlProps('select', {
          pathValue: 'properties.displayMask',
          label: 'Display mask',
          options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
          noreset: true,
        }),
        makeControlProps('select', {
          pathValue: 'properties.unmaskedValue',
          label: 'Value unmasked',
          options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        }),
      ],
      date: [
        makeControlProps('input', {
          pathValue: 'properties.datemask',
          label: 'Date mask',
          hint: 'DD - day, MM - Month,<br/>\r\n YYYY - year',
          noreset: true,
        }),
        makeControlProps('select', {
          pathValue: 'properties.displayMask',
          label: 'Display mask',
          options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
          noreset: true,
        }),
      ],
      'datetime-local': [
        makeControlProps('input', {
          pathValue: 'properties.datemask',
          label: 'Datetime mask',
          hint: 'DD - day, MM - Month,<br/>\r\n YYYY - year, HH - hours, mm - minutes',
          noreset: true,
        }),
        makeControlProps('select', {
          pathValue: 'properties.displayMask',
          label: 'Display mask',
          options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
          noreset: true,
        }),
      ],
    },
  }),
  makeControlProps('select', {
    pathValue: 'properties.design',
    label: 'Design',
    options: [
      { label: 'standard', value: '' },
      { label: 'filled', value: 'filled' },
      { label: 'outlined', value: 'outlined' },
      { label: 'standout', value: 'standout' },
      { label: 'borderless', value: 'borderless' },
    ],
  }),
  makeControlProps('select', {
    pathValue: 'properties.darkMode',
    label: 'Dark mode',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('select', {
    pathValue: 'properties.clearable',
    label: 'Show clear icon',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  visibilityProps,
  loopControlProps,
]

const view = [
  {
    selector: '.mbst-text-input input',
    controls: [typographyProps],
  },
  {
    selector: '.mbst-text-input .q-field__control',
    controls: [
      makeControlProps('typography', {
        label: 'Label styling',
        color: {
          label: 'Focused color',
          pathValue: 'color',
        },
      }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      makeControlProps('spacing', { label: 'Spacing' }),
    ],
  },
  {
    selector: '.mbst-text-input .q-field__control:before',
    controls: [makeControlProps('borders', { label: 'Borders' })],
  },
  {
    selector: '.mbst-text-input',
    controls: [
      _.omit(sizingProps, ['height', 'maxHeight', 'minHeight']),
      // makeControlProps('spacing', { label: 'Spacing' }),
      // makeControlProps('borders', { label: 'Borders' }),
      backgroundProps,
    ],
  },
]

const viewMigration = [
  {
    from: '.mbst-text-input',
    to: '.mbst-text-input .q-field__control:before',
    directives: [
      'border-width',
      'border-style',
      'border-color',
      'border-bottom-width',
      'border-bottom-style',
      'border-bottom-color',
      'border-top-width',
      'border-top-style',
      'border-top-color',
      'border-right-width',
      'border-right-style',
      'border-right-color',
      'border-left-width',
      'border-left-style',
      'border-left-color',
    ],
  },
  {
    from: '.mbst-text-input',
    to: '.mbst-text-input .q-field__control',
    directives: ['padding', 'margin'],
  },
]

const actions = []

export default {
  properties,
  view,
  viewMigration,
  actions,
}
