import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const instanceList = {};
const debug = process.env.NODE_ENV !== 'production';

/**
 * Фабрика сервиса хранилища
 *
 * @param {string} instanceId - id экземпляра
 * @returns {Store}
 * @constructor
 */
const factoryStore = (instanceId = 'global') => {
  if (!(instanceId in instanceList)) {
    instanceList[instanceId] = new Vuex.Store({
      state: {
        _storeInstanceId: instanceId,
      },

      strict: debug,
    });
  }

  return instanceList[instanceId];
};

export default factoryStore;
