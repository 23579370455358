export default {
  uuid: null,
  type: '',
  name: 'w-text',
  aliasName: 'WHtml',
  iconPreview: {
    component: 'IconComponentWText',
  },
  properties: {
    text: 'Lorem ipsum...',
  },
  actions: {},
};
