var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.b({
        isDisabled: _vm.disabled,
        isPickerOpened: _vm.isPickerOpened,
      }),
    },
    [
      _c("div", { class: _vm.b("container") }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { id: _vm.vId, type: "text", disabled: _vm.disabled },
          domProps: { value: _vm.colorInput },
          on: {
            input: _vm.onChange,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.enterKeyPressed.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("button", {
          class: _vm.b("button-toggle-picker"),
          style: _vm.styleButtonTogglePicker,
          attrs: { disabled: _vm.disabled },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.togglePicker.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.b("picker-wrap") },
          [
            _c("color-picker", {
              model: {
                value: _vm.colorPicker,
                callback: function ($$v) {
                  _vm.colorPicker = $$v
                },
                expression: "colorPicker",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.labelAfter
        ? _c("label", {
            class: _vm.b("label-after"),
            attrs: { for: _vm.vId },
            domProps: { innerHTML: _vm._s(_vm.labelAfter) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }