<template>
  <div :class="b({ theme })">
    <WBotsItemMask
      v-if="!editorMode && maskEnabled && isShownMask"
      :bot="bot"
    />

    <a v-if="isDisabledNotDefined"
      :class="b('link')"
      :href="editorMode ? '#' : url"
      :target="(isViber || editorMode) ? false : '_blank'"
    >
      <div :class="b('media')">

        <!--<div-->
          <!--v-if="isDisabled"-->
          <!--:class="b('status', { isDisabled })"-->
        <!--&gt;</div>-->

        <!--v-if="!isDisabled"-->
        <div :class="b('status', { isSubscribed })"></div>

        <template>
          <img
            v-if="icon.src && !icon.backgroundUrl"
            :class="b('icon')"
            :src="icon.src"
            :alt="title"
          />

          <img
            v-if="icon.backgroundUrl"
            :class="b('icon')"
            :src="icon.backgroundUrl"
            :alt="title"
            :style="handlerStylePreview()"
          />

          <component
            :is="icon.component"
            v-else-if="icon.component"
            :class="b('icon')"
            width="32"
          ></component>

          <IconLogo
            v-else
            width="48"
          />

        </template>
      </div>

      <div :class="b('title')">
        {{ title }}
      </div>
    </a>

    <div v-else-if="isSubscribed">
      <div :class="b('media')"
           v-on:click="onChangeChannel({key: bot.options.channel, value: -1})"
      >

        <!--<div-->
          <!--v-if="isDisabled"-->
          <!--:class="b('status', { isDisabled })"-->
        <!--&gt;</div>-->

        <!--v-if="!isDisabled"-->
        <div
          :class="b('status', { isSubscribed })"
        ></div>

        <template>
          <img
            v-if="icon.src && !icon.backgroundUrl"
            :class="b('icon')"
            :src="icon.src"
            :alt="title"
          />

          <img
            v-if="icon.backgroundUrl"
            :class="b('icon')"
            :src="icon.backgroundUrl"
            :alt="title"
            :style="handlerStylePreview()"
          />

          <component
            :is="icon.component"
            v-else-if="icon.component"
            :class="b('icon')"
            width="32"
          ></component>

          <IconLogo
            v-else
            width="48"
          />

        </template>
      </div>

      <div :class="b('title')">
        {{ title }}
      </div>
    </div>

    <div v-else-if="isDisabled">
      <div :class="b('media')"
           v-on:click="onChangeChannel({key: bot.options.channel, value: 1})"
      >

        <!--<div-->
          <!--v-if="isDisabled"-->
          <!--:class="b('status', { isDisabled })"-->
        <!--&gt;</div>-->

        <!--v-if="!isDisabled"-->
        <div
          :class="b('status', { isSubscribed })"
        ></div>

        <template>
          <img
            v-if="icon.src && !icon.backgroundUrl"
            :class="b('icon')"
            :src="icon.src"
            :alt="title"
          />

          <img
            v-if="icon.backgroundUrl"
            :class="b('icon')"
            :src="icon.backgroundUrl"
            :alt="title"
            :style="handlerStylePreview()"
          />

          <component
            :is="icon.component"
            v-else-if="icon.component"
            :class="b('icon')"
            width="32"
          ></component>

          <IconLogo
            v-else
            width="48"
          />

        </template>
      </div>

      <div :class="b('title')">
        {{ title }}
      </div>
    </div>

    <!--<span v-if="!isNotPush" :class="('tristate tristate-switcher')" stile="position:absolute"></span>-->
    <!--<span v-if="isNotPush" :class="('tristate tristate-switcher')" stile="position:absolute">-->
    <!--<input type="radio" id="item2-state-off_for" :name="'groups_' +bot.id" :id="'groups_id_' +bot.id" value="-1"-->
           <!--:checked="isDisabled"-->
           <!--v-on:click="bot.options.undefinedStatus=true;changeChannel(bot.options.objid,bot.options.channel+'=-1');"-->
    <!--&gt;-->
    <!--<input type="radio" id="item2-state-null_for" :name="'groups_' +bot.id" :id="'groups_id_' +bot.id" value="0"-->
           <!--:checked="isDisabledNotDefined"-->
           <!--v-on:click="bot.options.subscribed=true;changeChannel(bot.options.objid,bot.options.channel+'=0');"-->
    <!--&gt;-->
    <!--<input type="radio" id="item2-state-on_for" :name="'groups_' +bot.id" :id="'groups_id_' +bot.id" value="1"-->
           <!--:checked="isSubscribed"-->
           <!--v-on:click="bot.options.disabled=true;changeChannel(bot.options.objid,bot.options.channel+'=1');"-->
    <!--&gt;-->
    <!--<i></i>-->
    <!--<label for="item2-state-null"></label>-->
    <!--<label for="item2-state-on"></label>-->
      <!--<label for="item2-state-off"></label>-->
  <!--</span>-->
  </div>
</template>

<script>
  import MakeEventBus from 'general/utils/MakeEventBus';
  import baseMixin from 'general/mixins/baseMixin';
  import IconLogo from 'general/components/icons/IconLogo';
  import IconTelegram from 'general/components/icons/IconTelegram';
  import IconViber from 'general/components/icons/IconViber';
  import IconVk from 'general/components/icons/IconVk';
  import IconPush from 'general/components/icons/IconLogo';
  import IconPushRed from 'general/components/icons/IconPushRed';
  import IconFacebookMessenger from 'general/components/icons/IconFacebookMessenger';
  import WBotsItemMask from './WBotsItemMask';
  const eventBus = MakeEventBus('wBots');
  import { mapGetters } from 'vuex';

  export default {
    name: 'w-bots-item',

    mixins: [
      baseMixin,
    ],

    components: {
      WBotsItemMask,
      IconLogo,
      IconTelegram,
      IconViber,
      IconVk,
      IconPush,
      IconPushRed,
      IconFacebookMessenger,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      theme: {
        type: String,
        default: 'default',
      },

      bot: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      isShownMask: true,
      isSubscribedM: null,
      isDisabledM: null,
      isDisabledNotDefinedM: null,
    }),

    computed: {
      ...mapGetters({
        applicationId: 'getApplicationId',
        objectId: 'getObjectId'
      }),
      url: vm => vm.bot.options.url,
      icon: vm => vm.bot.options.icon || {},
      title: vm => vm.bot.options.title || null,
      maskEnabled: vm => vm.bot.options.maskEnabled || false,
      isSubscribed() {
        if(this.isSubscribedM!==null)return this.isSubscribedM;
        return this.bot.options.subscribed || false;
      },
      isDisabledNotDefined() {
        if(this.isDisabledNotDefinedM!==null)return this.isDisabledNotDefinedM;
        return this.bot.options.undefinedStatus || false;
      },
      isDisabled() {
        if(this.isDisabledM!==null)return this.isDisabledM;
        return this.bot.options.disabled || false;
      },
      isViber() {
        return (this.bot.code=='viber');
      },
      isNotPush: vm => vm.bot.id > 0,
    },

    created() {
      eventBus.$on('show-all-mask', this.showMask);
      eventBus.$on('mask:stop-timer', this.hideMask);
    },

    methods: {
      async onChangeChannel(value) {
        if (this.bot.code === 'push') {
          if (Notification.permission === 'granted') {
            await this.changeChannel(value);
          } else {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
              window.mbst.bus.$emit('global:messagingGetTokenAndSendToServer');
              await this.changeChannel(value);
            } else {
              eventBus.$emit('mask:notificationPermission', permission);
            }
          }
        } else {
          await this.changeChannel(value);
        }
      },
      async changeChannel(value) {
        try {
          const http = this.$store.state.http;
          const endpoint = `/v4/objects/${this.objectId}`;
          const params = { [value.key]: value.value };
          await http.put(endpoint, null, { params });

          // не стал разбираться в логике Саши, оставил как есть
          if (this.isDisabledNotDefinedM == null) {
            this.isDisabledNotDefinedM = this.bot.options.undefinedStatus;
          }
          if (this.isDisabledM == null) {
            this.isDisabledM = this.bot.options.disabled;
          }
          if (this.isSubscribedM == null) {
            this.isSubscribedM = this.bot.options.subscribed;
          }
          this.isSubscribedM = !this.isSubscribedM;
          this.undefinedStatus = false;
          this.isDisabledM = !this.isDisabledM;
        } catch (e) {
          console.log(e);
        }
      },

      handlerStylePreview() {
        return {
          // backgroundImage: `url('${this.icon.backgroundUrl}')`,
          width: '45px',
          height: '45px',
        };
      },

      showMask(currentBot) {
        if (!currentBot || currentBot.id === this.bot.id) {
          this.isShownMask = true;
        }
      },

      hideMask(currentBot, timer) {
        if (!currentBot || currentBot.id === this.bot.id) {
          this.isShownMask = false;
          if (this.bot.code === 'vk' && timer) {
            const win = window.open(this.url, '_blank');
            if (!win) window.location = this.url; // sometimes a browser blocks pop-up windows (ios safari)
          }
        }
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-bots-item
    max-width 90px
    padding 2px 2px 0
    position relative
    transition-duration $duration

    &_theme_default&
      flex-direction column

    &_theme_priority&
      button()
      flex(center, center)
      flex-direction row
      min-width 160px
      background-color #f9f9fb
      padding 15px 20px

    &__link
      flex(center, center)
      flex-direction column
      link()

    &__media
      ~/_theme_default &
        button()
        flex(center, center)
        size(56px)
        background-color #f9f9fb
        position relative
        color gray
        box-sizing border-box

      ~/_theme_priority &
        margin-right 10px

    &__icon
      //

    &__status
      size(10px)
      background-color #ddd
      border-radius 50%
      position absolute
      top -2px
      right -2px

      &_is-subscribed
        background-color $color-green

      &_is-disabled
        background-color $color-red

    &__title
      color #444
      text-align center
      ~/_theme_default &
        margin-top 8px
        typography(12px)

      ~/_theme_priority &
        typography(18px)
</style>
