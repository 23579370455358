var _ = require('underscore');

module.exports = {
    initHashtags: function() {

        MOBSTEDAPP.hashtags = {};
        MOBSTEDAPP.hashtags.backendname = {
            label: lang('Backendname'),
            value: []
        };
        MOBSTEDAPP.hashtags.variable = {
            label: lang('Variable'),
            value: []
        };
        MOBSTEDAPP.backendname = [];
        var fields, screenName, snippetName;

        _.each(MOBSTEDAPP.backboneScreens, function(screen, indx) {
            if (!_.isUndefined(screen)) {
                _.each(screen.elements, function(model) {

                    fields = model.fields || model.get("fields");
                    snippetName = model.title || model.get("title");
                    screenName = screen.fields.Name.value || '';

                    if (fields) {
                        if (fields.namebackend && !_.isEmpty(fields.namebackend.value)) {
                            MOBSTEDAPP.backendname.push({
                                screenId: parseInt(screen.id),
                                screenName: screenName,
                                modelId: model.id || model.cid,
                                name: fields.namebackend.value,
                            });

                            if (_.isObject(fields.hideDataFromObject) && snippetName != 'File Button' && snippetName != 'Photo') {
                                MOBSTEDAPP.hashtags.backendname.value.push({
                                    screenId: parseInt(screen.id),
                                    screenName: screenName,
                                    modelId: model.id || model.cid,
                                    name: fields.namebackend.value
                                });
                                if (snippetName === 'Select Basic' || snippetName === 'Radio Buttons' || snippetName === 'Checkboxes') {
                                    MOBSTEDAPP.hashtags.backendname.value.push({
                                        screenId: parseInt(screen.id),
                                        screenName: screenName,
                                        modelId: model.id || model.cid,
                                        name: fields.namebackend.value + ':text',
                                    });
                                    MOBSTEDAPP.hashtags.backendname.value.push({
                                        screenId: parseInt(screen.id),
                                        screenName: screenName,
                                        modelId: model.id || model.cid,
                                        name: fields.namebackend.value + ':value',
                                    });
                                }
                            }
                        }
                        if (fields.variable) {
                            var variables = JSON.parse(fields.variable.value);
                            if (_.isArray(variables)) {
                                _.each(variables, function(variablesItem, indx) {
                                    if (!_.isEmpty(variablesItem.variableName))
                                        MOBSTEDAPP.hashtags.variable.value.push({
                                            screenId: parseInt(screen.id),
                                            screenName: screenName,
                                            modelId: model.id || model.cid,
                                            name: variablesItem.variableName
                                        });
                                });
                            } else {
                                if (!_.isEmpty(variables.variableName))
                                    MOBSTEDAPP.hashtags.variable.value.push({
                                        screenId: parseInt(screen.id),
                                        screenName: screenName,
                                        modelId: model.id || model.cid,
                                        name: variables.variableName
                                    });
                            }
                        }
                    }

                    if (snippetName == 'Row') {
                        _.each(model.columns, function(column) {
                            _.each(column, function(snippetModel, indx) {

                                fields = snippetModel.fields || snippetModel.get("fields");
                                snippetName = snippetModel.title || snippetModel.get("title");

                                if (fields) {
                                    if (fields.namebackend && !_.isEmpty(fields.namebackend.value)) {
                                        MOBSTEDAPP.backendname.push({
                                            screenId: parseInt(screen.id),
                                            screenName: screenName,
                                            modelId: model.id || model.cid,
                                            name: fields.namebackend.value,
                                        });
                                        if (_.isObject(fields.hideDataFromObject) && snippetName != 'File Button' && snippetName != 'Photo') {
                                            MOBSTEDAPP.hashtags.backendname.value.push({
                                                screenName: screenName,
                                                name: fields.namebackend.value
                                            });
                                            if (snippetName === 'Select Basic' || snippetName === 'Radio Buttons' || snippetName === 'Checkboxes') {
                                                MOBSTEDAPP.hashtags.backendname.value.push({
                                                    screenName: screenName,
                                                    name: fields.namebackend.value + ':text',
                                                });
                                                MOBSTEDAPP.hashtags.backendname.value.push({
                                                    screenName: screenName,
                                                    name: fields.namebackend.value + ':value',
                                                });
                                            }
                                        }
                                    }
                                    if (fields.variable) {
                                        var variables = JSON.parse(fields.variable.value);
                                        if (_.isArray(variables)) {
                                            _.each(variables, function(variablesItem, indx) {
                                                if (!_.isEmpty(variablesItem.variableName))
                                                    MOBSTEDAPP.hashtags.variable.value.push({
                                                        screenId: parseInt(screen.id),
                                                        screenName: screenName,
                                                        modelId: model.id || model.cid,
                                                        name: variablesItem.variableName
                                                    });
                                            });
                                        } else {
                                            if (!_.isEmpty(variables.variableName))
                                                MOBSTEDAPP.hashtags.variable.value.push({
                                                    screenId: parseInt(screen.id),
                                                    screenName: screenName,
                                                    modelId: model.id || model.cid,
                                                    name: variables.variableName
                                                });
                                        }
                                    }
                                }

                            });
                        });
                    }

                });
            }
        });

        MOBSTEDAPP.hashtags = $.extend(true, {}, MOBSTEDAPP.hashtags, MOBSTEDAPP.staticHashtags);
    }
}