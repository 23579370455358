'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/apiproviders/apiprovidermethodsapi",

    defaults: {
        id: null,
        APIProviderId: null,
        ConsumesType: 'application/json',
        GetParams: null,
        PostParams: null,
        LastModified: null,
        Name: null,
        Method: 'GET',
        Params: null,
        Path: null,
        ProducesType: 'application/json',
        Responces: null,
        SortOrder: null,
        isActive: false
    },

    initialize: function() {
    }

});