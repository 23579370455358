const dict = require('general/lang/WidgetEditorPropertyPane.json');
const $t = (prop, def) => {
  if (window.MOBSTEDAPP && window.MOBSTEDAPP.lang) {
    return dict[window.MOBSTEDAPP.lang]['w-text'][prop] || def || prop;
  } else {
    return def || prop;
  }
}

const properties = {
  text: {
    uuid: null,
    componentName: 'ControlTextarea',
    properties: {
      labelBefore: $t('html', 'Html'),
      value: '',
    },
    vModelPropertyName: 'value',
  },
};

export default {
  properties,
};
