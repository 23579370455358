var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 369.793 369.792",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M320.83 140.434l-1.759-.627-6.87-16.399.745-1.685c20.812-47.201 19.377-48.609 15.925-52.031L301.11 42.61c-1.135-1.126-3.128-1.918-4.846-1.918-1.562 0-6.293 0-47.294 18.57l-1.644.738-16.916-6.812-.679-1.684C210.45 3.762 208.475 3.762 203.677 3.762h-39.205c-4.78 0-6.957 0-24.836 47.825l-.673 1.741-16.828 6.86-1.609-.669c-27.752-11.7-43.956-17.633-48.18-17.633-1.714 0-3.714.769-4.854 1.892l-27.787 27.16c-3.525 3.477-4.987 4.933 16.915 51.149l.805 1.714-6.881 16.381-1.684.651C0 159.715 0 161.556 0 166.474v38.418c0 4.931 0 6.979 48.957 24.524l1.75.618 6.882 16.333-.739 1.669c-20.812 47.223-19.492 48.501-15.949 52.025L68.62 327.18c1.162 1.117 3.173 1.915 4.888 1.915 1.552 0 6.272 0 47.3-18.561l1.643-.769 16.927 6.846.658 1.693c19.293 47.726 21.275 47.726 26.076 47.726h39.217c4.924 0 6.966 0 24.859-47.857l.667-1.742 16.855-6.814 1.604.654c27.729 11.733 43.925 17.654 48.122 17.654 1.699 0 3.717-.745 4.876-1.893l27.832-27.219c3.501-3.495 4.96-4.924-16.981-51.096l-.816-1.734 6.869-16.31 1.64-.643c48.938-18.981 48.938-20.831 48.938-25.755V164.88c-.001-4.93-.001-6.966-48.964-24.446zM184.896 247.203c-35.038 0-63.542-27.959-63.542-62.3 0-34.342 28.505-62.264 63.542-62.264 35.023 0 63.522 27.928 63.522 62.264.001 34.335-28.498 62.3-63.522 62.3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }