<template>
  <div :class="b()">
    <div
      v-if="labelBefore"
      :class="b('label-before')"
      v-html="labelBefore"
    ></div>

    <div :class="b('list')">
      <ControlCheckbox
        v-for="(element, index) in elements"
        v-if="element"
        :key="index"
        :class="b('item')"
        v-bind="element"
      />
    </div>

    <div
      v-if="hint"
      :class="b('hint')"
      v-html="hint"
    ></div>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';
  import ControlCheckbox from './ControlCheckbox';

  export default {
    name: 'control-checkbox-list',

    mixins: [
      baseMixin,
    ],

    components: {
      ControlCheckbox,
    },

    model: {
      prop: 'elements',
      event: 'update',
    },

    props: {
      elements: {
        type: Array,
        required: true,
      },
      labelBefore: {
        type: String,
      },
      hint: {
        type: String,
      },
    },

    watch: {
      elements: {
        handler(data) {
          this.$emit('update', data);
        },
        deep: true,
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-checkbox-list
    &__label-before
      margin-bottom 20px

    &__list
      //

    &__item
      +not-last()
        margin-bottom 10px

    &__hint
      margin-top 20px
      color $color-gray-dark
</style>
