import LoggerStrategy from './LoggerStrategy';
import LoggerDevStrategy from './LoggerDevStrategy';
import LoggerProdStrategy from './LoggerProdStrategy';

export default class Logger extends LoggerStrategy {

  constructor(type) {
    super();

    switch (type) {
      case 'prod':
        this.strategy = new LoggerProdStrategy();
        break;

      case 'dev':
      default:
        this.strategy = new LoggerDevStrategy();
    }
  }

  log(...args) {
    this.strategy.log(args);

    return this;
  }

  info(name, data = null) {
    this.strategy.info(name, data);

    return this;
  }

  warn(name, data = null) {
    this.strategy.warn(name, data);

    return this;
  }

  error(name, data = null) {
    this.strategy.error(name, data);

    return this;
  }

  exception(name, data = null) {
    this.strategy.exception(name, data);

    return this;
  }

}
