/*global countSelected */
import logger from 'general/logger';
import Backbone from 'backbone';
import Cookie from 'js-cookie';
window.countSelected = 0;
require('jquery.ba-bbq');
require('refactor/lib/jquery-ui');
require('refactor/lib/jquery.yiigridview');
require('gritter/js/jquery.gritter');
require('bootstrap');
require('select2');
var geolocation = require('refactor/geolocation');
var InputMask = require('refactor/lib/jquery.mask');
var Datepicker = require('refactor/lib/datepicker');
var Colorpicker = require('refactor/lib/colorpicker.min');
require('refactor/lib/jquery.magnific-popup.min');
var justtimezone = require('refactor/lib/jstz-1.0.4.min');
var _ = require('underscore');

var WarningManualView = require('views/trigger/WarningManualView');
//const ModalHeroView = require('views/modal-hero/ModalHeroView');
var ModalHero = require('refactor/modal-hero');

module.exports = {

    /** здесь вешается событие для сохранения настроек аккаунта */
    accountSettingsEvents: function () {

        // messages
        $(document.body).on('click', 'button#save-messages-email', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/saveemail/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-mailgun', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savemailgun/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-smscru', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savesmsc/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-twiliocom', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savetwilio/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-messagebird', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savembird/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-whatsapp', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savewhatsapp/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-viber', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/saveviber/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-messages-telegram', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savetelegram/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        // databases
        $(document.body).on('click', 'button#save-databases-db', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savedb/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', '[name="databases-federated-switcher"]', function(e) {
            var formdata = $(this).val();
            $.ajax({
                url: "/accountsettings/savefederatedstate/",
                type: "POST",
                data: {'state':formdata},
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-databases-federated', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savefederated/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        // interface
        $(document.body).on('click', 'button#save-interface', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/saveinterface/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        // fiscals
        $(document.body).on('click', '#atoldelete', function(e) {
            $.ajax({
                url: "/accountsettings/deleteatol/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#fiscal-atol-replace').html(obj.html);
                }
            });
        });

        // jwt
        $(document.body).on('click', 'button#save-jwt', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savejwt/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        // jwt-default-set
        // welcome spagetti
        $(document.body).on('click', 'button#save-jwt-default', function(e) {
            e.preventDefault();
            //var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savejwtdefault/",
                type: "POST",
                data: {},
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    grit(lang('Set up'), 'growl-success');
                    $('#jwt-form').find('#access-token-exp').val(obj.accessTokenExp);
                    $('#jwt-form').find('#refresh-token-exp').val(obj.refreshTokenExp);
                }
            }).fail(function (a, b, c) {
                grit(lang('Not set up'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        // payments
        $(document.body).on('click', '#alfabankdelete', function(e) {
            $.ajax({
                url: "/accountsettings/deletealfabank/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-alfabank-replace').html(obj.html);
                }
            });
        });

        /** сохранение настроек альфа банка*/
        $(document.body).on('click', 'button#save-payments-alfabankrbs', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savealfabank/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="alfabank[hiddenaccname]"]').val($('[name="alfabank[accname]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-alfabank-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', '#rfibankdelete', function(e) {
            $.ajax({
                url: "/accountsettings/deleterfibank/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-rfibank-replace').html(obj.html);
                }
            });
        });

        $(document.body).on('click', '#nkodelete', function(e) {
            $.ajax({
                url: "/accountsettings/deletenko/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-nko-replace').html(obj.html);
                }
            });
        });

        /** for deleting SNGB payment settings */
        $(document.body).on('click', '#sngbdelete', function(e) {
            $.ajax({
                url: "/accountsettings/deletesngb/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-sngb-replace').html(obj.html);
                }
            });
        });



        /** for deleting SBER payment settings */
        $(document.body).on('click', '#sberdelete', function(e) {
            $.ajax({
                url: "/accountsettings/deletesber/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-sber-replace').html(obj.html);
                }
            });
        });


        /** сохранение настроек рфи банка*/
        $(document.body).on('click', 'button#save-payments-rfibank', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/saverfibank/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="rfibank[hiddenaccname]"]').val($('[name="rfibank[accname]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-rfibank-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        /** сохранение настроек nko банка*/
        $(document.body).on('click', 'button#save-payments-nkorbs', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savenko/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="nko[hiddenaccname]"]').val($('[name="nko[accountName]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-nko-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        /** for saving SNGB payment settings */
        $(document.body).on('click', 'button#save-payments-sngb', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savesngb/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="sngb[hiddenaccname]"]').val($('[name="sngb[accountName]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-sngb-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });


        /** for saving SBER payment settings */
        $(document.body).on('click', 'button#save-payments-SBER', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savesber/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="sber[hiddenaccname]"]').val($('[name="sber[accountName]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-sber-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });


        $(document).on('change', '[name="rfibank[transfer_type]"]', e => {
            let transfer_type = $(e.target).val(),
                $recipientSetting = $('#recipient-settings');
            if (transfer_type != '') {
                $recipientSetting.show();
            } else {
                $recipientSetting.hide();
            }
        })

        /** сохранение настроек атола*/
        $(document.body).on('click', 'button#save-fiscals-atol', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/saveatol/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="atol[hiddenaccname]"]').val($('[name="atol[accname]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#fiscal-atol-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', '#braintreedelete', function(e) {
            $.ajax({
                url: "/accountsettings/deletebraintree/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-braintree-replace').html(obj.html);
                }
            });
        });

        $(document.body).on('click', 'button#save-payments-braintreegateway', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savebraintree/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="braintree[hiddenaccname]"]').val($('[name="braintree[accname]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-braintree-replace').html(obj.html);
                    }
                }

            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', '#stripedelete', function(e) {
            $.ajax({
                url: "/accountsettings/deletestripe/",
                type: "POST",
                data: {hiddenacckey: $(this).attr('data-eventid')}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.html !== '') {
                    $('#payments-stripe-replace').html(obj.html);
                }
            });
        });

        $(document.body).on('click', 'button#save-payments-stripe', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savestripe/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else {
                    $('[name="stripe[hiddenaccname]"]').val($('[name="stripe[accname]"]').val());
                    grit(lang('Saved'), 'growl-success');
                    $('.details-close').click();
                    if (obj.html !== '') {
                        $('#payments-stripe-replace').html(obj.html);
                    }
                }
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $(document.body).on('click', 'button#save-payments-payanyway', function(e) {
            e.preventDefault();
            var formdata = $(this).closest('form').serialize();
            $.ajax({
                url: "/accountsettings/savepayanyway/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });
    },

    /** здесь вешается событие для сохранения профиля */
    myProfileEvents: function () {
        $(document.body).on('click', 'button#save-myprofile', function(e) {
            e.preventDefault();
            var timezoneAb = $('#timezone-profile option:selected').attr('data-abbrev');
            var formdata = $('#save-myprofile').closest('form').serialize() + '&myprofile[timezoneab]=' + timezoneAb;
            $.ajax({
                url: "/myprofile/save/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        // $(document.body).on('click', 'a[data-profilemessageslastid]', function (e) {
        //     e.preventDefault();
        //     var lastid = $(this).attr('data-profilemessageslastid');
        //     var that = this;
        //     //console.info(lastid);
        //     var formdata = {lastid: lastid};
        //     $.ajax({
        //             url: "/myprofile/getmessagespart",
        //             type: "POST",
        //             data: formdata,
        //             dataType: 'json',
        //             beforeSend: function() {
        //                 $(that).addClass('btn-more--load');
        //             }
        //         }).done(function (a, b, c) {
        //         if (a.error !== undefined && a.error === "" ){
        //             $('#myprofilecomments').prepend(a.html);
        //             $(that).attr('data-profilemessageslastid', a.id);
        //         } else {
        //             console.info(a.error);
        //         }
        //     }).always(function() {
        //         $(that).removeClass('btn-more--load');
        //     });
        // });
    },

    // регистрация и защита приложения (степ 3)
    registrationAndSecurity: function() {

        const useAppAnyoneNoReg       = $('#use-app-anyone-noreg'),
          useAppAnyoneReg             = $('#use-app-anyone-reg'),
          useAppOnlyUsers             = $('#use-app-only-users'),

          // verifyUsersNone             = $('#verify-users-none'),
          verifyUsersEmail            = $('#verify-users-email'),
          verifyUsersSms              = $('#verify-users-sms'),
          verifyCodeTimeout           = $('#verify-code-timeout'),

          requestVerifyFirsttime      = $('#request-verify-firsttime'),
          requestVerifyEverytime      = $('#request-verify-everytime'),

          additionalProtectPin        = $('#additional-protect-pin'),
          additionalProtectGeo        = $('#additional-protect-geo'),
          additionalProtectIp         = $('#additional-protect-ip'),

          panelUseApp                 = $('#use-app'),
          panelVerifyUsers            = $('#verify-users'),
          panelRequestVerify          = $('#request-verify'),
          panelAdditionalProtect      = $('#additional-protect'),
          googletokenClientId         = $('#google-token-ClientId'),
          googletokenClientSecret     = $('#google-token-ClientSecret'),
          googleSaveStep3show         = $('#googleSaveStep3show'),
          additionalexternalid         = $('#additional-externalid'),
          additionalexternalidGoogle         = $('#additional-externalidgoogle'),
          additionalexternalidAd         = $('#additional-externalidAd'),


          appId                       = $('#id-app').val();


      googleSaveStep3show.click(function(e) {

        let formdata = {
            appId: appId,
          googletokenClientId: googletokenClientId.val(),
          googletokenClientSecret: googletokenClientSecret.val(),
        };

        e.preventDefault();

        $.ajax({
          url: "/applications/setOpenIdOauthKeys/",
          type: "POST",
          data: formdata
        }).done(function (a, b, c) {
          var obj = {};
          try {
            obj = JSON && JSON.parse(a) || $.parseJSON(a);
          } catch (error) {
            logger.exception(error, a);
          }
          if (obj.error !== '') {
            grit(obj.error, 'growl-danger');
          } else grit(lang('Saved'), 'growl-success');
        }).fail(function (a, b, c) {
          grit(lang('Save failed'), 'growl-danger');
        }).always(function (a, b, c) {
        });
      });

        if (!useAppOnlyUsers.is(':checked')){
            //$('#selfreg-url').removeClass('panel-disabled');
        }
        if (additionalProtectGeo.is(':checked')) {
            geolocation.start();
        }
        if (useAppAnyoneReg.is(':checked') || useAppOnlyUsers.is(':checked')) {
            panelVerifyUsers.removeClass('panel-disabled');
            panelVerifyUsers.find('input[type="radio"]').attr('disabled', false);
            verifyCodeTimeout.attr('disabled', false);
        }
        if (verifyUsersEmail.is(':checked') || verifyUsersSms.is(':checked')) {
            panelRequestVerify.removeClass('panel-disabled');
            panelRequestVerify.find('input[type="radio"]').attr('disabled', false);
        }

        panelUseApp.find('input[type="radio"]').each(function() {
            $(this).change(function() {
                if (useAppAnyoneReg.is(':checked') || useAppOnlyUsers.is(':checked')) {
                    panelVerifyUsers.removeClass('panel-disabled');
                    panelVerifyUsers.find('input[type="radio"]').attr('disabled', false);
                    verifyCodeTimeout.attr('disabled', false);
                } else {
                    panelVerifyUsers.addClass('panel-disabled');
                    panelVerifyUsers.find('input[type="radio"]').attr({'disabled': true, 'checked': false});
                    //verifyUsersNone.attr('checked', true);
                    panelRequestVerify.addClass('panel-disabled');
                    panelRequestVerify.find('input[type="radio"]').attr('disabled', true);
                    requestVerifyFirsttime.attr('checked', true);
                }
                // if(useAppOnlyUsers.is(':checked')){
                //     $('#selfreg-url').addClass('panel-disabled');
                // }else{
                //     $('#selfreg-url').removeClass('panel-disabled');
                // }
            });
        });

        panelVerifyUsers.find('input[type="radio"]').each(function() {
            $(this).change(function() {
                if (verifyUsersEmail.is(':checked') || verifyUsersSms.is(':checked')) {
                    panelRequestVerify.removeClass('panel-disabled');
                    panelRequestVerify.find('input[type="radio"]').attr('disabled', false);
                } else {
                    panelRequestVerify.addClass('panel-disabled');
                    panelRequestVerify.find('input[type="radio"]').attr('disabled', true);
                    requestVerifyFirsttime.attr('checked', true);
                }
            });
        });

        useAppAnyoneNoReg.change(function() {
            stepsSaver({
                id: appId,
                autcreateobjects: $(this).val(),
                autnone: 'none',
                auteverytime: false,
                autpin: false,
                useAppAnyoneNoReg: true
            });
            //autpin - вырубаем пин т.к это пункт "кто угодно без регистрации"
            //useAppAnyoneNoReg - явно указываем что мы нажали $('#use-app-anyone-noreg')
        });
        useAppAnyoneReg.change(function() {
            let data = {
                id: appId,
                autcreateobjects: $(this).val(),
                // autpin: true,
                useAppAnyoneReg: true
            };
            if ( !verifyUsersSms.is(":checked") && !verifyUsersEmail.is(":checked") ) data.autphone = verifyUsersSms.val();
            if (verifyUsersSms.is(":checked")) data.autphone = verifyUsersSms.val();
            if (verifyUsersEmail.is(":checked")) data.autemail = verifyUsersEmail.val();

            stepsSaver(data);
            //autpin - врубаем пин т.к это пункт "кто угодно после регистрации"
            //autemail - врубаем сразу авторизацию по email, т.к. непонятно что было включено раньше
            //useAppAnyoneReg - явно указываем что мы нажали $('#use-app-anyone-reg')
        });
        useAppOnlyUsers.change(function() {
            let data = {
                id: appId,
                autcreateobjects: $(this).val(),
                // autpin: true,
                useAppOnlyUsers: true
            };
            if ( !verifyUsersSms.is(":checked") && !verifyUsersEmail.is(":checked") ) data.autphone = verifyUsersSms.val();
            if (verifyUsersSms.is(":checked")) data.autphone = verifyUsersSms.val();
            if (verifyUsersEmail.is(":checked")) data.autemail = verifyUsersEmail.val();

            stepsSaver(data);
            //autpin - врубаем пин т.к это пункт "указанные мной"
            //autemail - врубаем сразу авторизацию по email
            //useAppOnlyUsers - явно указываем что мы нажали $('#use-app-only-users')
        });

        // verifyUsersNone.change(function() {
        //     stepsSaver({id: appId, autnone: $(this).val(), auteverytime: false});
        // });
        verifyUsersEmail.change(function() {
            stepsSaver({
                id: appId,
                autemail: $(this).val(),
                verifyUsersEmail: true
            });
            //$('#additional-protect-pin').change();//запускаем провереку на вывод поапапа с предупреждением
        });
        verifyUsersSms.change(function() {
            stepsSaver({
                id: appId,
                autphone: $(this).val(),
                verifyUsersSms: true
            });
            //$('#additional-protect-pin').change();//запускаем провереку на вывод поапапа с предупреждением
        });

        requestVerifyFirsttime.change(function() {
            stepsSaver({id: appId, auteverytime: $(this).val()});
        });
        requestVerifyEverytime.change(function() {
            stepsSaver({id: appId, auteverytime: $(this).val()});
        });

        verifyCodeTimeout.change(function() {
            stepsSaver({
                id: appId,
                codeConfirmationTime: $(this).val()
            });
        });


        additionalProtectPin.change(function () {
            stepsSaver({ id: appId,
                autpin: $(this)
                  .is(':checked'),
            });

            let text = '';
            if ($('#use-app-only-users')
              .is(':checked')) {
                if ($(this)
                  .is(':checked')
                ) {//выбран пункт PIN

                    if ($('#verify-users-email')
                      .is(':checked')) {//выбрано подтверждение по email+pin
                        text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Email / PIN set BEFORE they start using the app. Otherwise these objects/users would not enter.');
                    }
                    ;

                    if ($('#verify-users-sms')
                      .is(':checked')) {//выбрано подтверждение по sms+pin
                        text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Phone / PIN set BEFORE they start using the app. Otherwise these objects/users would not enter.');
                    }
                    ;

                    let titlePopUp = lang('Attention!');

                    function show_popupMail() {
                        const Model = Backbone.Model.extend();
                        const warningManualView = new WarningManualView({
                            parentView: this,
                            isFooter: true,
                            model: new Model({
                                title: titlePopUp,
                                text: '<p> <h5> ' + text + ' </h5> </p>',
                                footer: [
                                    { text: lang('Close'), className: 'btn-default', attr: 'data-dismiss="modal"' },
                                ],
                            }),
                        });

                        warningManualView.renderInner();
                        $('body')
                          .append(warningManualView.$el);
                        $(warningManualView.el)
                          .modal('show');

                    };
                    setTimeout(show_popupMail, 1000);

                }
            }
            // else {
            //
            //     if ($('#verify-users-email')
            //       .is(':checked')) {//выбрано подтверждение по email
            //         text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Email set BEFORE they start using the app. Otherwise these objects/users would not enter.');
            //     }
            //     ;
            //
            //     if ($('#verify-users-sms')
            //       .is(':checked')) {//выбрано подтверждение по sms
            //         text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Phone set BEFORE they start using the app. Otherwise these objects/users would not enter.');
            //     }
            //     ;
            //
            // }
            ;

        });


        additionalexternalidGoogle.change(function () {
            // console.log('$(this).is(\':checked\')',$(this).is(':checked'));
            if ($(this)
              .is(':checked')) {
                $('#extra-securityPSkeys1').css('display', 'block');
            } else {
                $('#extra-securityPSkeys1').css('display', 'none');
            }
            $.ajax({
                url: "/applications/setexternalid/",
                type: "POST",
                data: { appId:appId,   authextidGoogle: $(this)
                      .is(':checked'),},
            }).done(function (a, b, c) {
                var obj = {};
                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }
                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });


        });

        additionalexternalidAd.change(function () {
            // console.log('$(this).is(\':checked\')',$(this).is(':checked'));
            if ($(this)
              .is(':checked')) {
                $('#extra-securityPSkeys2').css('display', 'block');
            } else {
                $('#extra-securityPSkeys2').css('display', 'none');
            }
            $.ajax({
                url: "/applications/setexternalid/",
                type: "POST",
                data: { appId:appId,   authextidAd: $(this)
                      .is(':checked'),},
            }).done(function (a, b, c) {
                var obj = {};
                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }
                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });


        additionalexternalid.change(function () {
            // console.log('$(this).is(\':checked\')',$(this).is(':checked'));
            if ($(this)
              .is(':checked')) {
                // document.getElementById("additional-externalidGoogleAddiv").removeAttribute("style");
                // $('#additional-externalidGoogleAddiv').attr("style","");
                // $('#additional-externalidGoogleAddiv').css('display',null);
                // document.getElementById("additional-externalidGoogleAddiv").style.display = null;
                $('#additional-externalidGoogleAddiv').css('display', 'block');
            } else {
                $('#additional-externalidGoogleAddiv').css('display', 'none');
            }
            $.ajax({
                url: "/applications/setexternalid/",
                type: "POST",
                data: { appId:appId,   authextid: $(this)
                      .is(':checked'),},
            }).done(function (a, b, c) {
                var obj = {};
                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }
                if (obj.error !== '') {
                    grit(obj.error, 'growl-danger');
                } else grit(lang('Saved'), 'growl-success');
            }).fail(function (a, b, c) {
                grit(lang('Save failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        additionalProtectIp.change(function() {
            stepsSaver({id: appId, autip: $(this).is(':checked')});
        });
        additionalProtectGeo.change(function() {
            stepsSaver({id: appId, autgeo: $(this).is(':checked')});
        });

        // для точки геолокации искать вызовы в файле geolocation.js
        $(document.body).on('change', '#range', function() {
            const that = $(this);
            stepsSaver({id: appId, range: that.val()});
        });

        $('#ip-mask1').on('input paste', function(e){
            var text = this.value;
            var ntext = '';
            for (let i = 0; i < text.length; i++) {
                var chr = text.substr(i, 1);
                if (chr==='0' || chr==='1' || chr==='2' || chr==='3' || chr==='4' || chr==='5' ||
                    chr==='6' || chr==='7' || chr==='8' || chr==='9' || chr==='.') {
                    ntext = ntext + text.substr(i, 1);
                }

            }
            this.value = ntext;
        });

        $('#ip-mask2').on('input paste', function(e){
            var text = this.value;
            var ntext = '';
            for (let i = 0; i < text.length; i++) {
                var chr = text.substr(i, 1);
                if (chr==='0' || chr==='1' || chr==='2' || chr==='3' || chr==='4' || chr==='5' ||
                    chr==='6' || chr==='7' || chr==='8' || chr==='9' || chr==='.') {
                    ntext = ntext + text.substr(i, 1);
                }

            }
            this.value = ntext;
        });


        $(document.body).on('change', '#ip-mask1', function() {
            const that = $(this);
            stepsSaver({id: appId, mask1: that.val()});
        });
        $(document.body).on('change', '#ip-mask2', function() {
            const that = $(this);
            stepsSaver({id: appId, mask2: that.val()});
        });
        $(document.body).on('change', '#login-count', function() {
            const that = $(this);
            stepsSaver({id: appId, logincount: that.val()});
        });
        $(document.body).on('change', '#loginobjectnamehashtag', function() {
            const that = $(this);
            stepsSaver({id: appId, loginobjectnamehashtag: that.val()});
        });
        $(document.body).on('change', '#return_visitor_timeout', function() {
            const that = $(this);
            stepsSaver({id: appId, return_visitor_timeout: that.val()});
        });
    },


    // Импорт объектов (образец MailChimp)
    // --------------------------------------------------------------------------------------------------

    importObjects: function() {

        // Редактирование колонки
        // --------------------------------------------------------------------------------------------------

        $(document.body).on('click', '.b-import__col-edit', function(e) {

            e.preventDefault();
            var col = parseInt($(this).closest('th').attr('data-col-number')),
                newcol = $('th[data-col-number="' + getActiveCol() + '"]').hasClass('new-col') ? true : false; // узнаем в какой мы сейчас форме (создаем новую или редактируем)
            nextColumn(col, newcol);

        });


        // Скипнуть колонку
        // --------------------------------------------------------------------------------------------------

        $(document.body).on('click', '.b-import__col-delete', function(e) {

            e.preventDefault();
            var col = parseInt($(this).closest('th').attr('data-col-number'));
            deleteCol(col);

        });


        // Новая колонка или смена из существующих
        // --------------------------------------------------------------------------------------------------

        $(document.body).on('change', 'select[name*="col-name"]', function(e) {

            var self    = $(this),
                th      = self.closest('th'), // заголовок колонки, там вся инфа о колонке + формы для редактирования
                col     = th.attr('data-col-number'), // порядковый номер колонки, над которой работаем (пригодится для вычисления td и других элементов)
                td      = th.closest('table').find('tr td:nth-child(' + col + ')'); // все td колонки, над которой работаем

            th.siblings().removeClass('new-col active-col');
            td.siblings().removeClass('new-col active-col');

            if (self.val() === 'new') { // выбрали новую
                th.removeClass('active-col').addClass('new-col');
                td.removeClass('active-col').addClass('new-col');
                $('#newcol-name-' + col).focus();
            } else if (self.val() !== '' && self.val() !== 'new') {
                th.removeClass('new-col').addClass('active-col');
                td.removeClass('new-col').addClass('active-col');
            }

        });


        // Сохранить колонку
        // --------------------------------------------------------------------------------------------------

        $(document.body).on('click', '.b-import__col-save', function(e) {

            e.preventDefault();
            var col = parseInt($(this).closest('th').attr('data-col-number')),
                newcol = $(this).parent().hasClass('b-import__newcol-form') ? true : false; // узнаем в какой мы сейчас форме (создаем новую или редактируем)
            nextColumn('next', newcol);

        });


        // Не создавать новое имя колонки
        // --------------------------------------------------------------------------------------------------

        $(document.body).on('click', '.b-import__col-cancel', function(e) {

            e.preventDefault();

            var self            = $(this),
                th              = self.closest('th'), // заголовок колонки, там вся инфа о колонке + формы для редактирования
                col             = th.attr('data-col-number'), // порядковый номер колонки, над которой работаем (пригодится для вычисления td и других элементов)
                td              = th.closest('table').find('tr td:nth-child(' + col + ')'); // все td колонки, над которой работаем

            $('[name="col-name-' + col + '"]')[0].selectedIndex = 0; // первая позияция в селекте

            th.removeClass('new-col').addClass('active-col');
            td.removeClass('new-col').addClass('active-col');

        });

        $(document.body).on('click', '#UpdateExistingRecords', function(e) {

            if ($(this).attr("checked")=="checked")
            {
                $("#primaryKeyBlock").show();
            }
            else
                $("#primaryKeyBlock").hide();

        });


        // Редактирование следующей колонки
        // --------------------------------------------------------------------------------------------------

        function nextColumn(idCol, newcol) {

            var currentCol = getActiveCol();

            if (idCol === 'next') {
                if (currentCol >= 1) {
                    var i = currentCol + 1,
                        isNext = false;
                    for (i; i <= column_count; i++) {
                        if ($('th[data-col-number="' + i + '"]').hasClass('unmatched-col')) {
                            idCol = i;
                            isNext = true;
                            break;
                        }
                    }
                    if (currentCol > 1 && !isNext) {
                        for (i = 1; i <= column_count; i++) {
                            if ($('th[data-col-number="' + i + '"]').hasClass('unmatched-col')) {
                                idCol = currentCol != i ? i : 0;
                                isNext = true;
                                break;
                            }
                        }
                    }
                }
                var success = saveColumn(currentCol, newcol);
                if (success) {
                    editColumn(idCol); // переходим к следующей
                }
            } else {
                editColumn(idCol); // переходим на которую щелкнули
            }

            return false;

        }


        // Скипаем колонку и переходим к следующей
        // --------------------------------------------------------------------------------------------------

        function deleteCol(col) {

            if (!col) {
                if (getActiveCol() >= 1) {
                    col = getActiveCol();
                } else {
                    return false;
                }
            }

            var th      = $('th[data-col-number="' + col + '"'), // заголовок колонки, там вся инфа о колонке + формы для редактирования
                td      = th.closest('table').find('tr td:nth-child(' + col + ')'); // все td колонки, над которой работаем

            $('[name="col-name-' + col + '"]')[0].selectedIndex = 0;

            th.siblings().removeClass('new-col active-col');
            td.siblings().removeClass('new-col active-col');

            th.removeClass('new-col active-col unmatched-col success-col').addClass('delete-col');
            td.removeClass('new-col active-col unmatched-col success-col').addClass('delete-col');

            if (typeof(column_map[col]) != 'undefined') {
                delete merge_map[column_map[col]];
                unreserveMerge(column_map[col]);
            }
            column_map[col] = false;

            var idCol;

            if (col >= 1) {
                var i = col + 1,
                    isNext = false;
                for (i; i <= column_count; i++) {
                    if ($('th[data-col-number="' + i + '"]').hasClass('unmatched-col')) {
                        idCol = i;
                        isNext = true;
                        break;
                    }
                }
                if (col > 1 && !isNext) {
                    for (i = 1; i <= column_count; i++) {
                        if ($('th[data-col-number="' + i + '"]').hasClass('unmatched-col')) {
                            idCol = i;
                            isNext = true;
                            break;
                        }
                    }
                }
            }

            editColumn(idCol);

        }


        // Редактирование колонки
        // --------------------------------------------------------------------------------------------------

        function editColumn(col) {

            var th          = $('th[data-col-number="' + col + '"]'), // заголовок колонки, там вся инфа о колонке + формы для редактирования
                td          = th.closest('table').find('tr td:nth-child(' + col + ')'), // все td колонки, над которой работаем
                btnImport   = $('#startImportObjects'), // кнопка в футере, импорт
                halfBox     = $('.b-import__wr').outerWidth() / 2,
                halfTh      = th.outerWidth() / 2,
                scrollWidth = 20;

            th.siblings().removeClass('new-col active-col');
            td.siblings().removeClass('new-col active-col');

            th.addClass('active-col');
            td.addClass('active-col');

            if (col >= 1) {
                var i = 1;
                for (i; i <= col-1; i++) {
                    scrollWidth += $('th[data-col-number="' + i + '"]').outerWidth() + 10;
                }
                $('.b-import__wr').stop().animate({scrollLeft: scrollWidth - halfBox + halfTh}, '350', 'swing');
            }

            var columnLength = 0;

            for (var key in column_map) {
                columnLength++;
            }

            if (column_count == columnLength && !$.isEmptyObject(merge_map)) {
                btnImport.attr('disabled', false);
            } else {
                btnImport.attr('disabled', true);
            }

        }


        // Сохранение колонки и работа с опциями в селектах, выбраную опцию блокируем в соседних селектах, и разблокируем при выборе другой опции
        // --------------------------------------------------------------------------------------------------

        function saveColumn(col, newcol) {

            var th              = $('th[data-col-number="' + col + '"]'), // заголовок колонки, там вся инфа о колонке + формы для редактирования
                colName         = th.find('.b-import__col-name'), // тег с названием текущей колонки
                colType         = th.find('.b-import__col-type span'), // это тег где хранится информация о типе текущей колонки
                td              = th.closest('table').find('tr td:nth-child(' + col + ')'), // все td колонки, над которой работаем
                selectedItem    = $('[name="col-name-' + col + '"] :selected'), // выбранная опция в селекте (название колонки)
                columnName      = !newcol ? selectedItem.val() : $('#newcol-name-' + col).val(), // новое название колонки
                columnType      = !newcol ? selectedItem.attr('data-type-col') : $('[name="newcol-type-' + col + '"]').val(); // новый тип колонки

            if (columnName == 'new') {
                alert(lang('Please select a valid column name before proceeding'));
                return false
            } else if (columnName == '') {
                alert(lang('Please make a selection or skip this field.'));
                return false
            } else {
                if (newcol) {
                    var columnsAddName = $('[name*="col-name"]:not([name="col-name-' + col + '"]) #new-import-columns'),// все селекты, кроме текущего (название колонки)
                        columnAddName = $('[name="col-name-' + col + '"] #new-import-columns'); // текущий селект

                    var opt = document.createElement('option');
                    opt.value = columnName;
                    opt.text = columnName;
                    opt.setAttribute('data-type-col', columnType);

                    columnsAddName.append(opt);

                    var opt1 = document.createElement('option');
                    opt1.value = columnName;
                    opt1.text = columnName;
                    opt1.selected = true;
                    opt1.setAttribute('data-type-col', columnType);

                    columnAddName.append(opt1);
                }
                unreserveMerge(column_map[col]);
                column_map[col] = columnName;

                for (var prop in merge_map) {
                    if (merge_map[prop] == col) {
                        delete merge_map[prop];
                        unreserveMerge(columnName);
                    }
                }
                merge_map[columnName] = col;
                reserveMerge(columnName, col);
            }

            colName.text(columnName);
            colType.text(columnType);
            th.removeClass('new-col active-col delete-col unmatched-col').addClass('success-col');
            td.removeClass('new-col active-col delete-col unmatched-col').addClass('success-col');

            return true;

        }

        // блокируем во всех селектах опцию кроме текущего селекта
        function reserveMerge(merge, col) {
            $('[name*="col-name"] option[value="' + merge + '"]').attr('disabled', true);
            $('[name="col-name-' + col + '"] option[value="' + merge + '"]').attr('disabled', false);
        }

        // разблокируем во все селектах опцию
        function unreserveMerge(merge) {
            $('[name*="col-name"] option[value="' + merge + '"]').attr('disabled', false);
        }


        // ИД активной колонки
        // --------------------------------------------------------------------------------------------------

        function getActiveCol() {

            var activeCol = $('th.active-col, th.new-col');

            if (activeCol[0]) {
                return parseInt(activeCol.attr('data-col-number'));
            }

        }

    },

    util: function () {

        /////
        $(document).on('click', '#log-yii', function(e) {
                e.preventDefault();
                $('body').css('overflow', 'auto');
                $('.yiiLog').css({
                    'position': 'absolute',
                    'top': 0,
                    'z-index': 9999
                });
            });
        ////

        var that = this;

        that.bind();


        // Tooltip
        // $('.tooltips, [data-toggle="tooltip"]').tooltip({ container: 'body'});

        // фиксирует элементы при горизонтальном скролле таблицы в гридах
        $('.b-main-box__inner').on('scroll', function(e) {

            var left        = $('.b-main-box__inner').scrollLeft(),
                bottom      = $('.b-main-box__inner').scrollTop(),
                header      = $('.b-main-box__header'),
                footerSF    = $('.b-create-sf__footer'),
                footer      = $('.b-main-box__footer');

            header.css( 'left', left );
            footer.css( 'left', left );
            //footer.css( 'bottom', -bottom );
            if (footerSF) {
                footerSF.css( 'bottom', -bottom );
            }

        });

        if ($('.b-main-box__inner--noscroll').length > 0) {

            $(document.body).on('shown.bs.tab', '.b-tab__item a', function(e) {
                $('.b-account-settings__scrollable').scrollTop(0);
                $('.b-settings-box-nav__item').removeClass("b-settings-box-nav__item--active").first().addClass("b-settings-box-nav__item--active");
                $('.b-settings-box-nav').css('top', 0);
                settingsBodyScroll();
            });

            $(document.body).on('click', '.b-settings-box-nav a', function(e) {
                e.preventDefault();
                var target = $(this.hash);
                if (target.length) {
                    settingsNavFixPos();
                    $('.b-account-settings__scrollable').animate({
                        scrollTop: target.position().top - 40
                    }, 250);
                }
                $(this).parent()
                    .addClass('b-settings-box-nav__item--active')
                    .siblings().removeClass('b-settings-box-nav__item--active');

                setTimeout(settingsBodyScroll, 450);
            });



        }

        function settingsNavFixPos() {
            $('.b-account-settings__scrollable').off('scroll').on('scroll', function(e) {

                var nav = $('.b-settings-box-nav'),
                    top = $(this).scrollTop();

                nav.css( 'top', top);

            });
        }

        function settingsBodyScroll() {
            var lastId,
                topMenu = $(".tab-pane.active .b-settings-box-nav"),
                menuItems = topMenu.find("a"),
                scrollItems = menuItems.map(function(){
                    var item = $($(this).attr("href"));
                    if (item.length) { return item; }
                }),
                heightActiveTab = $(".tab-pane.active").outerHeight(),
                $scrollerBox = $(".tab-pane.active .b-settings-box-scroller");

            if ($scrollerBox.outerHeight() > heightActiveTab) {

                var heightLastEl = $scrollerBox.find(".b-settings-box").last().outerHeight(),
                    pb = heightActiveTab - 60 - heightLastEl;

                $scrollerBox.css('padding-bottom', pb);

            }

            $('.b-account-settings__scrollable').off('scroll').on('scroll', function(e) {

                var nav = $('.b-settings-box-nav'),
                    top = $(this).scrollTop();

                var cur = scrollItems.map(function(){
                    if ($(this).position().top <= top + 40)
                        return this;
                });

                cur = cur[cur.length-1];
                var id = cur && cur.length ? cur[0].id : "";

                if (lastId !== id) {
                    lastId = id;
                    // Set/remove active class
                    menuItems
                        .parent().removeClass("b-settings-box-nav__item--active")
                        .end().filter("[href='#"+id+"']").parent().addClass("b-settings-box-nav__item--active");
                }

                nav.css( 'top', top);

            });
        }

        $(document.body).on('change', '.b-settings-box-switcher input[type="radio"]', function(e) {
            var $box = $('#' + $(this).attr('data-box'));
            if ($(this).val() == 1) {
                $box.css('display', 'block');
                if ($('.b-main-box__inner--noscroll').length > 0) {
                    $('.b-account-settings__scrollable').animate({
                        scrollTop: $box.position().top - 40
                    }, 250);
                }
            } else {
                $box.css('display', 'none');
            }
        });


        $('.mobsted-header .filter').toggle(function (e) {
            e.preventDefault();
            $(this).find('i').removeClass('fa-eye').addClass('fa-eye-slash');
            $('.mobsted-header').css('padding-right', '60px');
            $('.mobsted-tools-t').hide();
        }, function (e) {
            e.preventDefault();
            $(this).find('i').removeClass('fa-eye-slash').addClass('fa-eye');
            $('.mobsted-header').css('padding-right', '15px');
            $('.mobsted-tools-t').show();
        });

        if ($('.b-publish').length > 0) {
            $('.b-publish').css('padding-bottom', heightHeading($('.b-publish-heading')) + 'px');
            $('.b-publish-wr').css('padding-bottom', heightHeading($('.b-publish-footer')) + 'px');
            $('.b-publish-footer').css('top', -heightHeading($('.b-publish-footer')) + 'px');
        }

        // сворачивает / разворачивает блоки .b-collapse
        $(document.body).on('click', '.b-collapse__header', function(e) {

            var self = $(this).find('> a'),
                icon = self.children('i'),
                collapseBody = self.closest('.b-collapse').find('.b-collapse__body');

            if (!self.hasClass('active')) {
                self.addClass('active');
                icon.attr('class', 'fa fa-plus-square');
                collapseBody.css('display', 'none');
            } else {
                self.removeClass('active');
                icon.attr('class', 'fa fa-minus-square');
                collapseBody.css('display', 'block');
            }

        });
        $(document.body).on('click', '.b-collapse__header a', function(e) {
            e.preventDefault();
        });


        // Popover
        $('.popovers').popover();

        $('.input-daterange').datepicker({
            format: "yyyy-mm-dd",
            todayHighlight: true
        });

        //change mask in properties constructor
        $(document.body).on('click', 'label[data-mask-preset]', function() {
            $('.setmask').val($(this).attr('data-mask-preset')).trigger('change');

            if ($(this).hasClass('isreverse')) {
                $('.setreverse').attr('checked', true).trigger('change');
            } else {
                $('.setreverse').attr('checked', false).trigger('change');
            }
        });

        // Show panel buttons when hovering panel heading
        $('.panel-heading').hover(function () {
            $(this).find('.panel-btns').fadeIn('fast');
        }, function () {
            $(this).find('.panel-btns').fadeOut('fast');
        });

        $('.btn-lp').on('click', function () {
            $('.popovers').popover('hide');
        });

        //  показать qr code на шаге 3
        $("body").delegate('#qrCodeStep3show','click', function () {
            var appId = $("#id-app").val(),
                formdata = 'id=' + appId;
            $.ajax({
                url: "/objects/shownewlinkmodalqr/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                $('#replaceqrCodeStep3Div').html(a);
                $('#showhelper3').click();
            }).fail(function (a, b, c) {
            }).always(function (a, b, c) {
            });
        });

        //  показать qr code на шаге 6
        $("body").delegate('#qrCodeStep6show','click', function () {
            var appId = $("#id-app").val(),
                screenId = $("#id-screen").val(),
                formdata = 'id=' + appId + '&screenid=' + screenId;
            $.ajax({
                url: "/objects/showstep6modalqr/",
                type: "POST",
                data: formdata,
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                $('#replaceqrCodeStep6Div').html(a);
                $('#showhelper6').click();
            }).fail(function (a, b, c) {
            }).always(function (a, b, c) {
            });
        });

        // обработчик клика на qr ссылке в объектах конструктора
        $("body").delegate('.btn-lp[data-objid]','click', function () {
            var appId = $("#id-app").val();
            if(appId == undefined
                || appId == 'undefined' || typeof appId == undefined
                || typeof appId == 'undefined'){
                // console.log('try to get appId from tappId')
                appId = $(this).data('tappid');
                var timer45 = setInterval(function () {
                    let checkboxes = document.getElementsByClassName('modal-backdrop');
                    for (let index = 0; index < checkboxes.length; index++) {
                        checkboxes[index].remove()
                        clearInterval(timer45)
                    }
                }, 500);
            }
            var objId = $(this).data('objid');
            var formdata = 'id=' + appId + '&objid=' + objId;
            $.ajax({
                    url: "/objects/showmodalqr/",
                    type: "POST",
                    data: formdata,
                    beforeSend: function (xhr) {}
                }).done(function (a, b, c) {
                    $('#replaceslivepreview2').html(a);
                    $('#showhelper').click();
                }).fail(function (a, b, c) {
                }).always(function (a, b, c) {
                });
        });

        // обработчик клика на qr ссылке в пользователях конструктора
        $("body").delegate('.btn-lp[data-userid]','click', function () {
            var appId = $("#id-app").val();
            var userId = $(this).data('userid');
            var formdata = {userId: userId};
            $.ajax(
                {
                    url: "/users/showmodalqr/",
                    type: "POST",
                    data: formdata,
                    beforeSend: function (xhr) {
                    }
                }).done(function (a, b, c) {
                    $('#replaceslivepreview3').html(a);
                    $('#showhelper').click();
                })
                .fail(function (a, b, c) {

                })
                .always(function (a, b, c) {
                });
        });

        // скрывает popover при клике вне области popover
        $(document).click(function (event) {
            if ($(event.target).closest('.popover').length == 0 && $(event.target).hasClass('popovers') == false) {
                $('.popovers').popover('hide');
            }
        });

        $('.b-category-tpl__item > a').click(function (e) {

            e.preventDefault();

            $('.b-category-tpl__nav .b-category-tpl__item--active').each(function () {
                $(this).find('.b-category-tpl__sub').slideUp('fast');
                $(this).removeClass('b-category-tpl__item--active');
            });

            var child = $(this).parent().find('.b-category-tpl__sub');

            if (!child.is(':visible')) {
                clickOnParent(this);
                child.slideDown('fast');
                if (!child.parent().hasClass('b-category-tpl__item--active'))
                    child.parent().addClass('b-category-tpl__item--active');
            } else {
                clickOnParent(this);
                child.slideUp('fast');
                child.parent().removeClass('b-category-tpl__item--active');
            }

        });

        function get_current_total_count(countSelected) {
            var checked = $('#checkboxes_all').prop('checked');

            if (checked) {
                var appid = $('#appid').val();
                var table = $('#selected-row-all').attr('data-grid');
                $.ajax({
                    url: '/applications/getcountrecords?appid='+appid+'&table='+table,
                    type: "GET"
                }).done(function (a, b, c) {
                    if (a > countSelected) {
                        var selectedRow = $('#selected-row-all');
                        var tagA = $('#selected-row-all a');
                        var html = tagA.html();
                        tagA.html(lang('Select all') + '(' + a +')');
                        selectedRow.attr('style', '');
                        selectedRow.attr('data-count', a);
                    }
                }).fail(function (a, b, c) {}).always(function (a, b, c) {});
            } else {
                var selectedRow = $('#selected-row-all');
                selectedRow.attr('style', 'display:none');
                selectedRow.attr('data-onoff', 'off');
                $('#selected-row-all a').attr('style', '');
                $('#hideunhide').attr('style', '');
            }
        }

        //ставим флаг что было выбрано все или наоборот
        $(document.body).on('click', '#selected-row-all', function () {
            var onoff = $(this).attr('data-onoff');
            var tagA = $('#selected-row-all a');
            if (onoff == 'on') {
                var a = $(this).attr('data-count');
                tagA.html(lang('Select all') + '(' + a +')');
                tagA.attr('style', '');
                $(this).attr('data-onoff', 'off');
                $('#hideunhide').attr('style', '');
            } else if (onoff == 'off' || typeof onoff === "undefined") {
                tagA.html(lang('All (') + $(this).attr('data-count') + lang(') rows were selected. Click here to cancel.'));
                tagA.attr('style', 'color:red');
                $(this).attr('data-onoff', 'on');
                $('#hideunhide').attr('style', 'display:none');
            }
        });

        //подсветка всех строк в объектах
        $(document.body).on('change', '#checkboxes_all', function () {

            var checked = $(this).prop('checked');
            if (checked) countSelected = 0;
            $('.grid-view tbody tr[id^="row"]').each(function () {

                var checkBox = $(this).find('.checkbox-column input');

                checkBox.attr('checked', checked);
                if (checked) {
                    countSelected += 1;
                    $(this).addClass('checked');
                } else {
                    countSelected -= 1;
                    $(this).removeClass('checked');
                }
                enable_itemopt(checked, countSelected);

            });

            get_current_total_count(countSelected);

        });

        $(document.body).on('click', '.modal .btn', function () {
            $(this).parents('.modal').modal('hide');
        });

        $(document.body).on('click', '.full-size', function () {
            if ($(this).hasClass('fa-expand')) {
                $(this).removeClass('fa-expand').addClass('fa-compress');
            } else {
                $(this).removeClass('fa-compress').addClass('fa-expand');
            }
            $('.b-app-body').toggleClass('full');
        });

        //выделение action в легенде конструктора
        $(document.body).on('click', '.b-legend input[type="text"]', function () {
            $(this).select();
        });


        //подсветка выбронной строки в объектах
        $(document.body).on('change', 'tbody .checkbox-column input', function () {
            var self = $(this),
                checked = self.prop('checked');

            if (checked) {
                self.closest('tr').addClass('checked');
                countSelected += 1;
            } else {
                self.closest('tr').removeClass('checked');
                countSelected -= 1;
            }
            enable_itemopt(checked, countSelected);
            get_current_total_count(countSelected);
        });

        function enable_itemopt(enable, count) {

            var ckboxLength         = $('.grid-view tbody input[name="checkboxes\[\]"]').length,
                ckboxLengthChecked  = $('.grid-view tbody input[name="checkboxes\[\]"]:checked').length,
                ch                  = ckboxLengthChecked == 0;

            $('#checkboxes_all').attr('checked', ckboxLength == ckboxLengthChecked);

            if (enable) {
                $('.b-main-box__footer').addClass('b-main-box__footer--active');
                $('.b-main-box').css({
                    'padding-bottom': '50px',
                });
            } else {
                if (ch) {
                    $('.b-main-box__footer').removeClass('b-main-box__footer--active');
                    $('.b-main-box').css({
                        'padding-bottom': '0px',
                    });
                }
            }
            $('#selected-row').text(count);
        }

        // настрокйка таблиц у грида (скрытие и перемещение колонок)
        $(document.body).on('click', '.b-setup-data__table', function (e) {
            e.preventDefault();

            var settingsCol = $('.b-setup-table'),
                sortUrl = $('#sort-url').val(),
                pb = $('.b-setup-table__header').outerHeight();

            if (settingsCol.hasClass('b-setup-table--active')) {
                settingsCol.removeClass('b-setup-table--active');
            } else {
                settingsCol.addClass('b-setup-table--active');
                settingsCol.css('padding-bottom', pb);

                settingsCol.find('.b-setup-table__body').sortable({

                    stop: function (event, ui) {

                        var data = $(this).sortable('serialize'),
                            appid = $(this).data('appid');

                        $.ajax(
                            {
                                url: sortUrl,
                                type: "POST",
                                data: {columns: data,appid:appid},
                                beforeSend: function (xhr) {
                                }
                            }).done(function (a, b, c) {
                                $('#updateGridp').attr('style', '');
                                //$.fn.yiiGridView.update("my-model-grid");
                            })
                            .fail(function (a, b, c) {

                            })
                            .always(function (a, b, c) {
                            });
                    }
                });
            }
        });
        $(document.body).on('click', '.b-setup-table__close', function (e) {
            e.preventDefault();
            $('.b-setup-table').removeClass('b-setup-table--active');
        });

        $(document.body).on('click', '.b-setup-collumn__close', function (e) {
            e.preventDefault();
            $('.b-setup-collumn').removeClass('b-setup-collumn--active');
            $('.b-setup-collumn #properties').html('');
        });

        // вызов легенды в шаге 6
        $(document.body).on('click', '#show-legend', function (e) {
            e.preventDefault();
            set_legend(false);
        });
        // END вызов легенды в шаге 6

        $(document.body).on('click', '#nav-screens-minimize', function (e) {
            e.preventDefault();
            $('.constructor').addClass('nav-scr-min');
            $(this).addClass('active').siblings('span').removeClass('active');
            $(this).parent().addClass('on');
        });
        $(document.body).on('click', '#nav-screens-maximize', function (e) {
            e.preventDefault();
            e.preventDefault();
            $(this).toggleClass('active');
            $('.constructor').toggleClass('nav-scr-min');
            $(this).parent().toggleClass('on');
            // $('.constructor').removeClass('nav-scr-min');
            // $(this).addClass('active').siblings('span').removeClass('active');
            // $(this).parent().removeClass('on');
        });

        // для кнопок фильтра в backend просто добавляет или удаляет класс
        $(document.body).on('click', '.btn-toggle', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
        });

        $(document.body).on('focus', 'input[type=number]', function (e) {
            $(this).on('mousewheel.disableScroll', function (e) {
                e.preventDefault()
            })
        });
        $(document.body).on('blur', 'input[type=number]', function (e) {
            $(this).off('mousewheel.disableScroll')
        });
        $(document.body).on('click', '#preview-msg-close', function(e) {
            var self = $(this),
                closeContainer = self.parents('.b-preview-msg');
            closeContainer.remove();

          let smsChecked = $('#msg-method-sms').is(":checked");
          if (smsChecked && $('#preview-msg-close').text() !== "") {
            $('#div-i3').show();
          } else {
            $('#div-i3').hide();
          }

        });

        /* временно */
        $('.selectDefault').select2();

        //$('#colorpicker').colorpicker();

        $('#app-desc #name-app').change(function () {
            var str = '';
            str += $(this).val();
            $('#i-frame-name_app').text(str);

        });

        step1_element_events();

        /* временно eof */

        if ($('.js-colorpicker').length > 0) {
            $('.js-colorpicker').colorpicker({
                customClass: 'colorpicker-2x',
                sliders: {
                    saturation: {
                        maxLeft: 200,
                        maxTop: 200
                    },
                    hue: {
                        maxTop: 200
                    },
                    alpha: {
                        maxTop: 200
                    }
                },
                colorSelectors: {
                    '#e4e7ea': '#e4e7ea',
                    '#428bca': '#428bca',
                    '#5cb85c': '#5cb85c',
                    '#5bc0de': '#5bc0de',
                    '#f0ad4e': '#f0ad4e',
                    '#d9534f': '#d9534f'
                }
            });
        }

        $(document).off('changeColor.colorpicker').on('changeColor.colorpicker', '.js-colorpicker', function(e) {
            $(this).parent().find('[data-preview-color]').css('background-color', $(this).val());
        });

        $(document).off('hidePicker.colorpicker').on('hidePicker.colorpicker', '.js-colorpicker', function(e) {
            $(this).change();
        });

        /** кусочек кода для обслуживания таблицы шаблонов уведомлений на шаге 7 в конструкторе - начало */
        function saveInNotifyTemplates(that, field, value) {
            var appId = $(that).closest('table').attr('data-appid');
            var type = $(that).closest('tr').attr('data-type');
            $.ajax({
                url: "/applications/savenotifytemplate/",
                type: "POST",
                data: {type:type, appId:appId, field:field, value:value},
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === ''){
                    grit(lang('Saved'), 'growl-success');
                } else {
                    grit(lang('Not saved'), 'growl-danger');
                }
            }).fail(function (a, b, c) { });
        }

        var delay = (function(){
            var timer = 0;
            return function(callback, ms){
                clearTimeout (timer);
                timer = setTimeout(callback, ms);
            };
        })();

        $(document.body).on('input paste', '[data-notifymessage]', function(e) {
            var that = this;
            delay(function(){
                var val = $(that).val();
                saveInNotifyTemplates(that, 'message', val);
            }, 1000);
        });

        $(document.body).on('change', '[data-notifystate]', function(e) {
            var that = this;
            //delay(function(){
            //
            //}, 1000);
            var val = $(that).prop('checked');
            saveInNotifyTemplates(that, 'state', val);
        });
        /** кусочек кода для обслуживания таблицы шаблонов уведомлений на шаге 7 в конструкторе - конец */

    },

    bind: function() {

        _.each($('.js-mgp'), item => {
          $(item).magnificPopup({
            type: 'image',
            removalDelay: 300,
            mainClass: 'mfp-fade'
          });
        });



        $(document.body).on('click', '.ht', function(e){
            e.preventDefault();
            var self = $(this),
                st = self.parents('.app-group').find('table, .nav-screens-list');
            self.toggleClass('active');
            if (self.hasClass('active')) {
                st.hide();
                self.children().removeClass('fa-minus-square').addClass('fa-plus-square');
            } else {
                st.show();
                self.children().removeClass('fa-plus-square').addClass('fa-minus-square');
            }
        });

        $(document.body).on('click', '#sb-all', function(){
            $('.search-box-tab__pane').addClass('active');
        });
        $(document.body).on('blur', '#top-search', function(){
            var self = $(this);
            if (self.val() !== '') {
                self.addClass('active');
                $('#btn-sb').css('display','block');
            } else {
                self.removeClass('active');
                $('#btn-sb').fadeOut(100);
            }
        });
        $(document.body).on('focus', '#top-search', function(){
            $('#btn-sb').fadeIn(150);
        });

        $(document.body).delegate('[data-disablefiltercolumn]', 'click', function() {

            $('#' + $(this).data('disablefiltercolumn')).val('');
            var e = $.Event("keydown", { keyCode: 13 });
            $('#' + $(this).data('disablefiltercolumn')).trigger(e);

//    appId = $(this).data('appid');
//    $.ajax({
//        url: "/backend/dropsingleheaderfilter/",
//        type: "POST",
//        data: {appid: appId, filter: $(this).data('disablefiltercolumn')},
//    }).done(function (a, b, c) {
//            obj = JSON && JSON.parse(a) || $.parseJSON(a);
//            if (obj.error === ''){
//                location.reload(false);
//            } else {
//                grit(lang('Error drop header filter'), 'growl-danger');
//            }
//        }).fail(function (a, b, c) { });
        });

        $(document.body).delegate('[data-disablefilterall]', 'click', function() {
            //location.reload(false);
            var appId = $(this).data('appid');
            $.ajax({
                url: "/backend/dropallheaderfilter/",
                type: "POST",
                data: {appid: appId},
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === ''){
                    location.reload(false);
                } else {
                    grit(lang('Error drop header filter'), 'growl-danger');
                }
            }).fail(function (a, b, c) { });
        });


        $('body').delegate('[data-descdelete]', 'click', function () {
            var descId = $(this).data('descdelete');
            var that = this;
            $.ajax({
                url: "/publishapp/descdelete/",
                type: "POST",
                data: {descId: descId},
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === ''){
                    var tr = $(that).parents('.b-publish-item');
                    tr.fadeOut(400, function(){
                        tr.remove();
                    });
                }
            }).fail(function (a, b, c) { });
        });

        $(document).on('focusin', '.EditableColumnInput', function (event) {
            $(this).data('val', $(this).val());
        });

        $(document).on('input paste keydown change', '.EditableColumnInput', function (event) {
            if ((event.type=='change')) {
                //var id = $(this).data('id');
                var that = this;
                var name = $(this).data('name');
                var url = $(this).data('url');
                var value = $(this).serialize();
                var appid=$(this).data('appid');
                var depends = $(this).data('depends');
                var haschild = $(this).data('haschild');
                var elem = this;
                var obj = null;
                if ((name=='Enabled')&&(value=="")) { value="Enabled=0&currentApp="+appid;} /// Обработка Enabled в объектах, когда убирается чек с чекбокса
                if ((name=='Enabled')&&(value=="Enabled=1")) { value="Enabled=1&currentApp="+appid;} ; /// Обработка Enabled в объектах, когда убирается чек с чекбокса

                if ((name == 'TestRole') && (value == "")) {
                    value="TestRole=0&currentApp=" + appid;
                }
                if ((name == 'TestRole') && (value == "TestRole=1")) {
                    value="TestRole=1&currentApp=" + appid;
                }

                $.ajax({
                        url: url,
                        type: "POST",
                        data: {value: value},
                        beforeSend: function (xhr) {
                        }
                    }).done(function (a, b, c) {
                        try {
                            obj = JSON && JSON.parse(a) || $.parseJSON(a);
                            if (obj.error === "") {
                                $(elem).addClass('green');
                                $(elem).removeClass('red');

                                grit(lang('Saved'), 'growl-success');

                                if ((name=='Enabled')&&(value=="Enabled=1&currentApp="+appid)) {
                                    $.fn.yiiGridView.update("my-model-grid");
                                }
                            } else {
                                $(elem).removeClass('green');
                                $(elem).addClass('red');
                                grit(obj.error, 'growl-danger');
                                $(that).val($(that).data('val'));
                            }
                        } catch (error) {
                            logger.exception(error, a);
                            $(elem).removeClass('green');
                            $(elem).addClass('red');
                            grit(lang('Not saved'), 'growl-danger');
                            $(that).val($(that).data('val'));
                        }
                    }).fail(function (a, b, c) {
                        $(elem).removeClass('green');
                        $(elem).addClass('red');
                        grit(lang('Not saved'), 'growl-danger');
                        $(that).val($(that).data('val'));
                    }).always(function (a, b, c) { });
            }
        });

        $("body").delegate('#addRow', 'click', function (event) {
            //console.log($('#my-model-grid').yiiGridView('getUrl'));
            var appid = $(this).data("appid");
            var url = "/objects/addrow?id=" + appid;
            var obj = null;
            $.ajax({
                url: url,
                type: "POST",
                beforeSend: function (xhr) {}
            })
                .done(function (a, b, c) {
                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        grit(lang('Success'), 'growl-success');
                        $.fn.yiiGridView.update("my-model-grid", {data:{newObjId: obj.objid}});
                    } else grit(lang('Error'), 'growl-danger');
                })
                .fail(function (a, b, c) {
                    grit(lang('Error'), 'growl-danger');
                })
                .always(function (a, b, c) { });
        });

        $(document).on('click', '#addCol', function (e) {
            // e.preventDefault();
            $("#data .form-control, #data .b-data-test").attr('disabled', 'disabled');
            var appid = $(this).data('appid');
            $.ajax({
                url: "/objects/addcolumngetform/",
                type: "POST",
                data: {id: appid},
                beforeSend: function (xhr) { }
            }).done(function (a, b, c) {
                try {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    if (obj.error === "") {
                        if (_.isArray(obj.hashtags)) { console.log(obj);
                            MOBSTEDAPP.staticHashtags = obj.hashtags;
                            HashTags.initHashtags();
                        }
                        set_legend(false);
                        $('.b-setup-collumn').addClass('b-setup-collumn--active');
                        var pb = $('.b-setup-collumn__header').outerHeight();
                        $('.b-setup-collumn').css('padding-bottom', pb);
                        $("#properties").html(obj.html);

                    } else grit(lang('RESTRICTED'), 'growl-danger', obj.error);
                } catch (error) {
                    logger.exception(error, a);
                }
            }).fail(function (a, b, c) {
                grit(lang('Error'), 'growl-danger');
            }).always(function (a, b, c) {});
        });

        $(document).on('click', '#addPrimary', function (e) {
            e.preventDefault();
            var appid = $(this).data("appid");
            var column = $(this).data("column");
            var button=this;
            $.ajax({
                url: "/objects/addcolumn/",
                type: "POST",
                data: {id: appid, column: column},
                beforeSend: function (xhr) {
                }
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    $(button).addClass('disabled');
                    $.fn.yiiGridView.update("my-model-grid");
                    if (_.isArray(obj.hashtags)) {
                        MOBSTEDAPP.staticHashtags = obj.hashtags;
                        HashTags.initHashtags();
                    }
                    $('ul.b-setup-table__body').replaceWith(obj.propHtml);
                    refreshObjectColumnSettings(appid);
                } else {
                    //incon("error", obj.error);
                }

            }).fail(function (a, b, c) {
                //incon("error", "Ошибка сервера.");
            }).always(function (a, b, c) {
            });
        });

        $(document).on('click', '#saveColumn', function (e) {
            e.preventDefault();

            var appid = $(this).data("appid"),
                column = $(this).data("column"),
                button = this,
                protectedcolumn = $("#ObjectColumns_Protected").prop("checked"),
                useasnamecolumn = $("#ObjectColumns_UseAsName").prop("checked"),
                inviewsmcolumn = $("#ObjectColumns_InShortViewSM").prop("checked");

            if (protectedcolumn == true) {
                protectedcolumn = 1;
            } else {
                protectedcolumn = 0;
            }
            if (useasnamecolumn == true) {
                useasnamecolumn = 1;
            } else {
                useasnamecolumn = 0;
            }
            if (inviewsmcolumn == true) {
                inviewsmcolumn = 1;
            } else {
                inviewsmcolumn = 0;
            }

            var sm = $("#ObjectColumns_SMVisible").prop("checked");
            if (sm == true) {
                sm = 1;
            } else {
                sm = 0;
            }

            var autoReg = $("#ObjectColumns_AutoRegistration").prop("checked");
            if (autoReg == true) {
                autoReg = 1;
            } else {
                autoReg = 0;
            }

            var isFileType = $("#type_file_text").prop("checked"), columnType;
            if (isFileType === false){
                columnType = 'text';
            } else {
                columnType = 'file';
            }

            var newColumnName = $('input[name="ObjectColumns[ColumnName]"]').val();
            // var depends = $("#ObjectColumns_Depends").val();
            var url;

            if ($(this).data('startscreen') === 1) {
                url = "/objects/savedepend/";
            } else {
                url = "/objects/renamecolumn/";
            }

            var defVal = $("#ObjectColumns_DefaultValue").val();

            $.ajax({
                url: url,
                type: "POST",
                data: {id: appid, ColumnName: column, NewColumnName: newColumnName,
                    protectedcolumn: protectedcolumn,
                    type: columnType,
                    sm: sm,
                    depends: null,
                    autoReg:autoReg,
                    usename: useasnamecolumn,
                    viewsm: inviewsmcolumn,
                    defVal: defVal},
                beforeSend: function (xhr) { }
            }).done(function (a, b, c) {
                try {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);

                    $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                    set_legend(false);

                    if (_.isArray(obj.hashtags)) {
                        MOBSTEDAPP.staticHashtags = obj.hashtags;
                        HashTags.initHashtags();
                    }

                    $.fn.yiiGridView.update("my-model-grid");
                    $('ul.b-setup-table__body').replaceWith(obj.html);
                } catch (error) {
                    logger.exception(error, a);
                    grit(lang('Rename failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
                }

                if (obj.error === "") {
                    $(button).attr("data-column", newColumnName);
                    $(button).data("column", newColumnName);

                    $("#deleteColumn").attr("data-column", newColumnName);
                    $("#deleteColumn").data("column", newColumnName);
                    //console.log('Yes');
                    //$.fn.yiiGridView.update("my-model-grid");
                    grit(lang('Rename success'), 'growl-success');

                    refreshObjectColumnSettings(appid);

                } else gritlong(obj.error, 'growl-danger');
            }).fail(function (a, b, c) {
                grit(lang('Rename failed'), 'growl-danger', lang('Server error'));
            }).always(function (a, b, c) { });
        });

        $(document).on('click', '#deleteColumn', function (e) {
            e.preventDefault();
            var appid = $(this).data("appid");
            var column = $(this).data("column");
            var button = this;
            var reassign = $(this).data("withreassign");
            if (reassign !== undefined) {
                reassign = $("#reassignColumn").val();
            } else reassign = "";
            console.log(reassign);
            $.ajax({
                url: "/objects/deletecolumn/",
                type: "POST",
                data: {id: appid, ColumnName: column, reassign: reassign},
                beforeSend: function (xhr) { }
            }).done(function (a, b, c) {
                try {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);

                    if (obj.error === "") {
                        set_legend(false);
                        if (column=='Email') {
                            $('[data-addemail]').removeClass('disabled');
                        }
                        if (column=='Phone') {
                            $('[data-addphone]').removeClass('disabled');
                        }
                        if (column=='PIN') {
                            $('[data-addpin]').removeClass('disabled');
                        }
                        $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                        $('.b-setup-collumn #properties').html('');
                        var windowDialog = $('#attemptdelete-cancel').closest('.details');
                        destroyDialog(windowDialog);

                        $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                        $.fn.yiiGridView.update("my-model-grid");
                        /** удалили колонку в гриде?, обновляем и пропертиес грида */
                        $('ul.b-setup-table__body').replaceWith(obj.html);
                        grit('Delete success', 'growl-success');

                        if (_.isArray(obj.hashtags)) {
                            MOBSTEDAPP.staticHashtags = obj.hashtags;
                            HashTags.initHashtags();
                        }

                        $("#properties").html("");
                        refreshObjectColumnSettings(appid);
                    } else grit(obj.error, 'growl-danger', obj.error);
                } catch (error) {
                    logger.exception(error, a);
                    grit(lang('Delete failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
                }
            }).fail(function (a, b, c) {
                grit(lang('Delete failed'), 'growl-danger', lang('Server error'));
            }).always(function (a, b, c){ });
        });


        /** нажатие на кнопку удаления в пропертис колонки бекенда */
        $(document).on('click', '#deleteBackendColumn', function (e) {
            e.preventDefault();
            var appid = $(this).data("appid");
            var column = $(this).data("column");
            var button = this;

            $.ajax({
                url: "/backend/deletecolumn/",
                type: "POST",
                data: {id: appid, ColumnName: column},
                beforeSend: function (xhr) { }
            }).done(function (a, b, c) {
                try {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);

                    if (obj.error === "") {

                        $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                        $('.b-setup-collumn #properties').html('');
                        var windowDialog = $('#attemptbackenddelete-cancel').closest('.details');
                        destroyDialog(windowDialog);

                        $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                        $.fn.yiiGridView.update("my-model-grid");
                        /** удалили колонку в гриде?, обновляем и пропертиес грида */
                        $('ul.b-setup-table__body').replaceWith(obj.html);
                        grit('Delete success', 'growl-success');


                        $("#properties").html("");
                        //srefreshObjectColumnSettings(appid);
                    } else grit(lang('Delete failed'), 'growl-danger', obj.error);
                } catch (error) {
                    logger.exception(error, a);
                    grit(lang('Delete failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
                }
            }).fail(function (a, b, c) {
                grit(lang('Delete failed'), 'growl-danger', lang('Server error'));
            }).always(function (a, b, c){ });
        });

        /** нажатие на кнопку сохранения в пропертис колонки бекенда */
        $(document).on('click', '#saveBackendColumn', function (e) {
            e.preventDefault();
            var appid           = $(this).data("appid");
            var column          = $(this).data("column");
            var newColumnName   = $('input[name="backend[ColumnName]"]').val();
            var defaultValue   = $('input[name="backend[defaultvalue]"]').val();
            var hideFromMU   = $('input[name="backend[hidefrommu]"]').is(':checked');
            var button = this;
            $.ajax({
                url: "/backend/renamecolumn/",
                type: "POST",
                data: {
                    id: appid,
                    ColumnName: column,
                    NewColumnName: newColumnName,
                    defaultvalue: defaultValue,
                    hidefrommu: hideFromMU
                    },
                beforeSend: function (xhr) { }
            }).done(function (a, b, c) {
                try {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    $('.b-setup-collumn').removeClass('b-setup-collumn--active');

                    $.fn.yiiGridView.update("my-model-grid");
                    $('ul.b-setup-table__body').replaceWith(obj.html);
                } catch (error) {
                    logger.exception(error, a);
                    grit(lang('Rename failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
                }

                if (obj.error === "") {
                    $(button).attr("data-column", newColumnName);
                    $(button).data("column", newColumnName);

                    $("#deleteColumn").attr("data-column", newColumnName);
                    $("#deleteColumn").data("column", newColumnName);
                    //console.log('Yes');
                    //$.fn.yiiGridView.update("my-model-grid");
                    grit(lang('Rename success'), 'growl-success');

                    //refreshObjectColumnSettings(appid);

                } else grit(obj.error, 'growl-danger');
            }).fail(function (a, b, c) {
                grit(lang('Rename failed'), 'growl-danger', lang('Server error'));
            }).always(function (a, b, c) { });
        });

        // show right-side panel for save column properties changes
        $("body").delegate('#columnedit', 'click', function (e) {
            $("#data .form-control, #data .b-data-test").attr('disabled', 'disabled');
            var fieldname = $(this).data('fieldname');
            var appid = $(this).data('appid'), url;

            if ($(this).data('grid') === 'backend') {
                url = "/backend/properties/";
            } else {
                url = "/objects/properties/";
            }

            $.ajax({
                    url: url,
                    type: "POST",
                    data: {fieldname: fieldname, appid:appid},
                    beforeSend: function (xhr) { }
                }).done(function (a, b, c) {
                    try {
                        let obj = JSON && JSON.parse(a) || $.parseJSON(a);
                        if (obj.error === "") {
                            $('.b-setup-collumn').addClass('b-setup-collumn--active');
                            var pb = $('.b-setup-collumn__header').outerHeight();
                            $('.b-setup-collumn').css('padding-bottom', pb);
                            $("#properties").html(obj.html);
                            $("#datatab2").html(obj.dataHtml);
                        } else {
                            grit(lang('RESTRICTED'), 'growl-danger', obj.error);
                        }
                    } catch (error) {
                        logger.exception(error, a);
                    }
                }).fail(function (a, b, c) { }).always(function (a, b, c) { });
        });

        $("body").delegate('#updateGrid', 'click', function (event) {
            $('.b-setup-table').removeClass('b-setup-table--active');
            $.fn.yiiGridView.update("my-model-grid");
            $('#updateGridp').attr('style', 'display:none;');
        });

        $("body").delegate('input[data-column]', 'change', function (event) {

            //var id = $(this).data('id');
            var column = $(this).data('column');
            var appid = $(this).data('appid');
            var visible = $(this).is(':checked');
            var msec = 500;
            console.log("beautiful day");
            $.ajax(
                {
                    url: '/backendcolumns/setcolumnvisibility',
                    type: "POST",
                    data: {column: column, visible: visible,id:appid},
                    beforeSend: function (xhr) {
                    }

                }).done(function (a, b, c) {
                    // console.log(a);
                    try {
                        let obj = JSON && JSON.parse(a) || $.parseJSON(a);
                        //console.log(obj);

                        if (obj.error === "") {

                            $('#updateGridp').attr('style', '');

                            //if (visible == false) {
                            //    $('[data-hidemark="hidemark' + column + '"]').fadeOut(msec);
                            //}
                            //else {
                            //    $('[data-hidemark="hidemark' + column + '"]').fadeIn(msec);
                            //}

                            //$.fn.yiiGridView.update("my-model-grid");
                        }
                        else {

                            //incon("error", obj.error);
                        }
                    }
                    catch (error) {
                        logger.exception(error, a);
                        //incon("error", "Неверный формат JSON ответа от сервера");
                    }
                })
                .fail(function (a, b, c) {
                    $(elem).removeClass('green');
                    $(elem).addClass('red');
                    //incon("error", "Ошибка сервера.");

                })
                .always(function (a, b, c) {
                });


        });
        $("body").delegate('[data-columnobj]', 'change', function (event) {

            //var id = $(this).data('id');
            var column = $(this).data('columnobj');
            var appid = $(this).data('appid');
            var visible = $(this).is(':checked');
            var msec = 200;
            $.ajax({
                    url: '/objectcolumns/setcolumnvisibility',
                    type: "POST",
                    data: {column: column, visible: visible,id:appid},
                    beforeSend: function (xhr) {}
                }).done(function (a, b, c) {
                    try {
                        let obj = JSON && JSON.parse(a) || $.parseJSON(a);

                        if (obj.error === "") {
                            $('#updateGridp').attr('style', '');
                            //if (visible == false) {
                            //    $('[data-hidemark="hidemark' + column + '"]').fadeOut(msec);
                            //}
                            //else {
                            //    $('[data-hidemark="hidemark' + column + '"]').fadeIn(msec);
                            //}

                        } else {

                        }
                    }
                    catch (error) {
                        logger.exception(error, a);
                    }
                })
                .fail(function (a, b, c) {
                    $(elem).removeClass('green');
                    $(elem).addClass('red');
                })
                .always(function (a, b, c) {
                });


        });


        $("body").delegate('[data-columnsimplegrid]', 'change', function (event) {
            var column = $(this).data('columnsimplegrid');
            var grid = $(this).data('grid');
            var appid = $(this).data('appid');
            var visible = $(this).is(':checked');
            var msec = 200;
            $.ajax({
                url: '/statuses/simplegridsetcolumnvisibility',
                type: "POST",
                data: {column: column, visible: visible, id:appid, grid: grid},
                beforeSend: function (xhr) {}
            }).done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    if (visible == false) {
                        $('[data-hidemark="hidemark' + column + '"]').fadeOut(msec);
                    }
                    else {
                        $('[data-hidemark="hidemark' + column + '"]').fadeIn(msec);
                    }
                }
            }).fail(function (a, b, c) {
                $(elem).removeClass('green');
                $(elem).addClass('red');
            }).always(function (a, b, c) {});
        });


        // Обработчик клика на кнопку для перехода к созданию нового статуса в справочнике статусов
        $('#createstatus').click(function (e) {
            e.preventDefault();
            var appId = $(this).data('appid');
            $.ajax({
                url: "/statuses/statuscreate/" + appId,
                type: "POST",
                dataType: 'json',
            }).done(function (a, b, c) {
                    if (a.error === "" && a.id !== "") {
                        modalView("/statuses/view/" + a.id, 'POST', {},  "statuses");
                        //ModalHero.modalView("/statuses/view/" + a.id, 'POST', {}, 'statuses');
                    } else {}
                })
                .fail(function (a, b, c) {})
                .always(function (a, b, c) {});
        });


        // Обработчик клика на кнопку для перехода к созданию нового юзера! в справочнике юзеров
        $('#createuser').click(function (e) {
            ModalHero.modalView("/users/usercreate/", 'POST', {}, 'usercreate');
        });

        $('body').delegate('#saveuserform', 'click', function (e) {
            var form = $('#user-form').serialize();
            var saveButton = this;
            $.ajax({
                url: "/users/usersave",
                type: "POST",
                data: form,
                dataType: 'json',
            }).done(function (a, b, c) {
                if (a.error === "") {
                    grit(lang('Saved'), 'growl-success');
                    $(saveButton).closest('#modal-hero').find('.details-close').click();
                } else {
                    grit(a.error, 'growl-error');
                }
            })
            .fail(function (a, b, c){})
            .always(function (a, b, c){});
        });


        $('#createrole').click(function (e) {
            e.preventDefault();
            $.ajax({
                url: "/roles/rolecreate",
                type: "POST",
                dataType: 'json',
            })
                .done(function (a, b, c) {
                    if (a.error === "" && a.id !== "") {
                        //modalView("/users/view/" + a.id, "users");
                        modalView("/roles/view/" + a.id, 'POST', {
                            actionType: a.crud,
                        }, 'roles');
                    }
                })
                .fail(function (a, b, c){})
                .always(function (a, b, c){});
        });

        // Обработчик клика на кнопку для создания новой колонки
        //$('#addCol').click(function (e) {
        //    e.preventDefault();
        //    Pace.restart();
        //    var appId = $(this).data('appid');
        //    var columnname = $(this).data('column');
        //    //console.log(appId);
        //    $.ajax(
        //         {
        //             url: "/objects/addcolumn",
        //             type: "POST",
        //             data: {id:appId},
        //             beforeSend: function (xhr) {
        //             }
        //         }).done(function (a, b, c) {
        //             try {
        //                 obj = JSON && JSON.parse(a) || $.parseJSON(a);
        //                 if (obj.error === "") {
        //                     showDialog('#modal-hero',obj.html);
        //                 }
        //                 else {
        //                     //incon("error", obj.error);
        //                 }
        //             }
        //             catch (e) {
        //                 //incon("error", "Неверный формат JSON ответа от сервера");
        //             }
        //         })
        //         .fail(function (a, b, c) {
        //             //incon("error", "Ошибка сервера.");
        //         })
        //         .always(function (a, b, c) {
        //         });
        //
        //});

        $("body").delegate('#new-request', 'click', function (event) {
            event.preventDefault();

            $(this).attr("class", "hide");
            $.fn.yiiGridView.update("my-model-grid"
                , {data:{lastId:$('#new-request').attr('data-updatelastid')}}
            );

            $('#gridLastId').val($('#new-request').attr('data-updatelastid'));
        });

        $(document).ready( function(){
            // Здесь задается наименование приложения и шага в хедере при переходах первоначальной загрузке страницы
            $('#title-step').html($('.b-wizard__item--active a').html());
            $('#title-appName').text($('#name-app').val());
            $('#name-app').select();
            inputSaverEvents();
            // saving focused item in constructor properties panel
            //$("#properties-comp-inner").find('input').each(function(){
            //    $(this).focus(function(){
            //        localStorage.setItem('focused',this.attr('id'));
            //    });
            //
            //});

        });

        /** предположил, что этот обработчик больше не используется */
        //$("body").delegate('[data-filter]', 'click', function (event) {
        //
        //    var filter = $(this).data("filter");
        //    var value = $(this).data("value");
        //    var appid = $(this).data("appid");
        //    var state = !$(this).hasClass('active'); // TRUE - значит было нажато, а теперь отжато. То есть state=true значит отключить фильтр
        //
        //    if (value=='all') {
        //        //logic when all button is pressed or unpressed
        //    }
        //
        //    if (filter === "date") {
        //        var itemDropDownDate    = $(this).parents('li'),
        //            dropDownDate        = itemDropDownDate.parent(),
        //            parentDateRange     = dropDownDate.parent(),
        //            dateRange           = parentDateRange.find('#daterange'),
        //            date1               = $('#filterDate #date1').val(),
        //            date2               = $('#filterDate #date2').val();
        //
        //        if ($(this).data('noname') === undefined) {
        //            if ($(this).text() !== 'Date Filter Off') {
        //                parentDateRange.find('#dropdownDate').removeClass('btn-default').addClass('btn-filtered');
        //            } else {
        //                parentDateRange.find('#dropdownDate').removeClass('btn-filtered').addClass('btn-default');
        //            }
        //            parentDateRange.find('#dropdownDate').html($(this).text() + '<span class="caret"></span>');
        //            dropDownDate.find('li').removeClass('active');
        //            itemDropDownDate.addClass('active');
        //        }
        //
        //        if (value === "custom") {
        //            dateRange.attr('style', 'display: inline-block;');
        //        } else {
        //            dateRange.attr('style', 'display: none;');
        //        }
        //
        //    }
        //
        //    if (filter === "columnvalue") {
        //        var column = $(this).data("column");
        //        var condition = $(this).data("condition");
        //    }
        //
        //    var url = "/backend/savefilterbutton";
        //    Pace.restart();
        //
        //    $.ajax({
        //        url: url,
        //        type: "POST",
        //        data: {filter: filter, state: state, value:value, appid:appid, date1:date1, date2:date2, column: column, condition: condition},
        //        beforeSend: function (xhr) { }
        //    }).done(function (a, b, c) {
        //        try {
        //            obj = JSON && JSON.parse(a) || $.parseJSON(a);
        //
        //            if (obj.error === "") {
        //                $('#gridLastId').val(obj.id);
        //                grit(lang('Saved'), 'growl-success');
        //
        //                if (obj.page !== undefined) {var page = obj.page} else {var page = 1}
        //                $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
        //            } else {
        //                grit(lang('Not Saved'), 'growl-danger');
        //            }
        //        } catch (e) {
        //            grit(lang('Not Saved'), 'growl-danger', lang('Wrong JSON response'));
        //        }
        //    }).fail(function (a, b, c) {
        //        grit(lang('Not Saved'), 'growl-danger', lang('Server or Network error'));
        //    }).always(function (a, b, c) { });
        //});

        $("body").delegate("[data-copy]", "click", function (e) {
            e.preventDefault();
            var url = $(this).data('copy');
            var appid = $('#id-app').val();
            var objectid = $(this).data('objid');
            var that = this;

            $.ajax(
                {
                    url: url,
                    type: "POST",
                    data: {id: objectid, appid: appid, model:$(this).data('model')}
                }).done(function (a, b, c)
                {
                    let obj = {};
                    let page;

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {

                        if ($(that).data('model') !== undefined && $(that).data('model') === "backend") {
                            if (obj.page !== undefined) {page = obj.page} else {page = 1}
                            $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                        } else {
                            $.fn.yiiGridView.update("my-model-grid");
                        }

                        $('body').css({'overflow': 'visible', 'padding-right': '0px'});
                        $('.details').hide();
                        $.gritter.add({
                            title: lang('Copied'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '1000'
                        });
                    }
                    else {
                        $.gritter.add({
                            title: lang('Copy failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });

                    }
                    ;
                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Copy failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });
        });

        $("body").delegate("#ColumnIncredibleStartScreen", "change", function(e){
            e.preventDefault();

            var asObject = $('[data-grid="import-objects"]').html();
            var asRole = $('#createrole').html(), url;
            if (asObject !== undefined) {
                url = '/objects/changestartscreen';
            } else if (asRole === undefined) {
                url = '/users/changestartscreen';
            } else {
                url = '/roles/changestartscreen';
            }

            var objectid = $(this).data('elementid');
            var screenid = $(this).val();
            var selector = this;
            var appid = $(this).data('appid');
            /** делим этот евент теперь с юзером */
            $.ajax({
                url: url,
                type: "GET",
                data: {screenid: screenid, objectid: objectid, appid:appid}
            })
                .done(function (a, b, c) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        grit(lang('Changed'), 'growl-success');
                    } else {
                        grit(lang('Change failed'), 'growl-danger');
                    }
                }).fail(function (a, b, c) {
                    grit(lang('Change failed'), 'growl-danger');
                }).always(function (a, b, c) { });
        });

        $("body").delegate("#ColumnIncredibleUserId", "change", function(e){
            e.preventDefault();
            var backendid = $(this).data('elementid');
            var userid = $(this).val();
            var selector = this;

            $.ajax({
                url: '/backend/changesmuser',
                type: "POST",
                data: {userid: userid, backendid: backendid}
            })
                .done(function (a, b, c) {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        //$(selector).attr('style', obj.html);
                        $.gritter.add({
                            title: lang('Changed'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '1000'
                        });
                    } else {
                        $.gritter.add({
                            title: lang('Change failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });
                    }
                })
                .fail(function (a, b, c) {
                    $.gritter.add({
                        title: lang('Change failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });
                })
                .always(function (a, b, c) {
                });
        });

        $("body").delegate("#ColumnSuperStatusId", "change", function(e){
            e.preventDefault();
            var backendid = $(this).data('elementid');
            var statusid = $(this).val();
            var selector = this;

            $.ajax({
                url: '/backend/changestatus',
                type: "POST",
                data: {statusid: statusid, backendid: backendid}
            })
                .done(function (a, b, c) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        $(selector).attr('style', obj.html);
                        $.gritter.add({
                            title: lang('Changed'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '1000'
                        });
                    } else {
                        $.gritter.add({
                            title: lang('Change failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });
                    }
                })
                .fail(function (a, b, c) {
                    $.gritter.add({
                        title: lang('Change failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });
                })
                .always(function (a, b, c) {
                });
        });

        $("body").delegate("[data-multipledelete]", "click", function (e) {
            e.preventDefault();
            var url = $(this).data('multipledelete');
            var nodel = $(this).data('nodelete');
            var ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');
            //console.log(ids);
            var that = this;

            var windowDialog = $(this).closest('.details');
            var forAll = check_for_all();
            var appId = $('#appid').val();
            var ajdata;
            (nodel == 'disable') ? ajdata = {deleteIds: ids, Enabled: 0, model:$(this).data('model'), forAll:forAll, appId:appId} :
                ajdata = {deleteIds: ids, model:$(this).data('model'), forAll:forAll, appId:appId}

            $.ajax({
                url: url,
                type: "POST",
                data: ajdata
            }).done(function (a, b, c) {
                ModalHero.destroyDialog(windowDialog);
                let obj = {};
                let page;

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {

                    if ($(that).data('model') !== undefined && $(that).data('model') === "backend") {
                        if (obj.page !== undefined) {page = obj.page} else {page = 1}
                        $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                    } else {
                        $.fn.yiiGridView.update("my-model-grid");
                    }
                    grit(lang('Deleted'), 'growl-success');
                } else {
                    grit(obj.errorMessage, 'growl-danger');
                }
            }).fail(function (a, b, c) {
                grit(lang('Delete failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $("body").delegate("[data-delete]", "click", function (e) /// DELETE ROW IN ANY GRID
        {
            e.preventDefault();

            var url = $(this).data('delete');
            var appid = $(this).data('appid');
            var objectid = $(this).data('objid');
            var that = this;

            //    if ($(this).data('toggle') === "status-delete") {
            //        var url = $(this).data('path');
            //    }
            //console.log('boroda');
            if (confirm('Delete, really?')) {
                $.ajax({
                        url: url,
                        type: "POST",
                        data: {id: objectid, appid: appid, model:$(this).data('model')}
                    }).done(function (a, b, c) {
                        let obj = {};
                        let page;

                        try {
                            obj = JSON && JSON.parse(a) || $.parseJSON(a);
                        } catch (error) {
                            logger.exception(error, a);
                        }

                        if (obj.error === "") {
                            //console.log($(that).data('model'));
                            if ($(that).data('model') !== undefined && $(that).data('model') === "backend") {
                                if (obj.page !== undefined) {page = obj.page} else {page = 1}
                                $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                            } else {
                                $.fn.yiiGridView.update("my-model-grid");
                            }

                            $('body').css({'overflow': 'visible', 'padding-right': '0px'});
                            $('.details').hide();
                            grit(lang('Deleted'), 'growl-success');
                        } else {
                          let errorText = obj.error;
                          grit(lang(errorText), 'growl-danger');
                        }
                }).fail(function (a, b, c) {
                    grit(lang('Delete failed'), 'growl-danger');
                }).always(function (a, b, c) { });
            }

        });


        $("body").delegate("#Test-messages-email", "click", function (e)
        {
            e.preventDefault();

            // var url = $(this).data('invite');
            // var appid = $(this).data('appid');
            // var objectid = $(this).data('objid');
            var value1='test smtp mobsted';
            var valueS='test smtp mobsted';

            let emailtarget = $('#testemail-email').val()

            let t = this;

            const auth = Cookie.getJSON('__mbst_authdata');
            let accessToken = auth['accessToken'];
            var url = window.location.href;
            var arr = url.split("/");
            let portalThis = arr[2];

            var fd = new FormData();
            fd.append("email", emailtarget);
            fd.append("subject", valueS);
            fd.append("body", value1);


            //dataType: 'json',
            //data: vardata,

            let vardata = [];
            vardata['email'] = emailtarget;
            vardata['subject'] = valueS;
            vardata['body'] = value1;

            //{email: emailtarget, subject: valueS, body:value1},

            $.ajax(
              {
                  url: '/api/v8/messages/sendbysmtp',
                  type: 'POST',
                  data: JSON.stringify({
                      email: emailtarget,
                      subject: valueS,
                      body:value1
                  }),
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + accessToken,
                  },
              })
              .done(function (a, b, c) {
                  // try {
                  //     console.log(a);
                  //     console.log(b);
                  //     console.log(c);
                  // } catch (error) {
                  //     logger.exception(error, a);
                  // }
                  if (a.error === undefined) {
                      $.fn.yiiGridView.update('my-model-grid');
                      $.gritter.add({
                          title: lang('Sended success'),
                          text: '',
                          class_name: 'growl-success',
                          sticky: false,
                          time: '1000',
                      });
                  } else {
                      $.gritter.add({
                          title: lang('fail send'),
                          text: '',
                          class_name: 'growl-danger',
                          sticky: false,
                          time: '1000',
                      });

                  }
                  ;
              })
              .fail(function (a, b, c) {
                  $.gritter.add({
                      title: lang('fail send'),
                      text: '',
                      class_name: 'growl-danger',
                      sticky: false,
                      time: '1000',
                  });

              })
              .always(function (a, b, c) {
              });

        });


        $("body").delegate("[data-invite]", "click", function (e) /// DELETE ROW IN ANY GRID
        {
            e.preventDefault();

            var url = $(this).data('invite');
            var appid = $(this).data('appid');
            var objectid = $(this).data('objid');
            var value='Enabled=1';
            let enabled = false;

//    if ($(this).data('toggle') === "status-delete") {
//        var url = $(this).data('path');
//    }

            $.ajax(
                {
                    url: url,
                    type: "POST",
                    data: {value:value}
                }).done(function (a, b, c)
                {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        $.fn.yiiGridView.update("my-model-grid");
                        $.gritter.add({
                            title: lang('Enabled'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '1000'
                        });

                        //all is "ok"? let make invite message
                        let t = this;

                        const auth = Cookie.getJSON('__mbst_authdata');
                        let accessToken = auth['accessToken'];
                        var url = window.location.href;
                        var arr = url.split("/");
                        let portalThis = arr[2];
                        // let vardata = [];
                        // vardata['objectId'] = objectid;
                        // vardata['ApplicationId'] = appid;
                        // vardata['invite'] = 1;
                        // vardata['message'] =  lang('We remind you that you are registered on the portal ') + portalThis + '. ' +lang('Your link to enter: ');

                        $.ajax(
                          {
                              url: '/api/v8/object/sendmessage',
                              type: 'POST',
                              //dataType: 'json',
                              //data: vardata,
                              data: JSON.stringify({
                                  objectId: objectid,
                                  ApplicationId: appid,
                                  invite:1,
                                 // message: lang('We remind you that you are registered on the portal ') + portalThis + ' ' +lang('Your link to enter: ')
                                  message: ('Hello, you are invited to "' + $('.mbst-header__app-name').text() + '". Click here')
                              }),
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': 'Bearer ' + accessToken,
                              },
                          })
                          .done(function (a, b, c) {
                              // try {
                              //     console.log(a);
                              //     console.log(b);
                              //     console.log(c);
                              // } catch (error) {
                              //     logger.exception(error, a);
                              // }
                              if (a.error === undefined) {
                                  $.fn.yiiGridView.update('my-model-grid');
                                  $.gritter.add({
                                      title: lang('Invited'),
                                      text: '',
                                      class_name: 'growl-success',
                                      sticky: false,
                                      time: '1000',
                                  });
                              } else {
                                  $.gritter.add({
                                      title: lang('Invite failed'),
                                      text: '',
                                      class_name: 'growl-danger',
                                      sticky: false,
                                      time: '1000',
                                  });

                              }
                              ;
                          })
                          .fail(function (a, b, c) {
                              $.gritter.add({
                                  title: lang('Invite failed'),
                                  text: '',
                                  class_name: 'growl-danger',
                                  sticky: false,
                                  time: '1000',
                              });

                          })
                          .always(function (a, b, c) {
                          });
                    }
                    else {
                        $.gritter.add({
                            title: lang('Enable failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });

                    }
                    ;
                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Enable failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });

        });

        $(document.body).on('click', 'input[name="who"]', function() {

            $('#btn-invite').attr('data-invite-who', $(this).val());

            if ($(this).val() === 'Objects') {

                $('.filter-invite .form-group').each(function () {

                    var self = $(this),
                        objCount = self.find('label').attr('data-objcount');

                    self.find('label em').text(objCount);
                    self.find('input[type="radio"]').attr('disabled', false);

                    $('.who-invite #startScreenDiv').show();

                });

            } else if ($(this).val() === 'Both') {
                $('.who-invite #startScreenDiv').show();
            } else {

                $('.filter-invite .form-group').each(function() {

                    var self = $(this);

                    self.find('label em').text('');
                    self.find('input[type="radio"]').attr({'disabled': true, 'checked': false});

                });

                $('.who-invite #startScreenDiv').hide();

            }

        });

        $(document.body).on('click', 'input[name="method"]', function() {

            var self        = $(this),
                selfVal     = self.val(),
                whoBoth     = $('input#who-both'),
                whoOther    = $('input#who-other');

            if (selfVal === 'qrcode' || selfVal === 'url') {

                whoOther.attr('disabled', false);
                whoBoth.attr('disabled', true);
                if (whoBoth.is(':checked')) {
                    $('.who-invite .form-group:first').find('input[type="radio"]').attr('checked', true).click();
                }
                $('#btn-invite').attr({'data-grid': 'create-qr-url', 'data-invite-type': selfVal}).text(lang('Generation'));
                $('.panel-finish .info').css('display', 'none');

            } else {

                if (whoOther.is(':checked')) {
                    $('.who-invite .form-group:first').find('input[type="radio"]').attr('checked', true).click();
                }
                whoOther.attr('disabled', true);
                whoBoth.attr('disabled', false);
                $('#btn-invite').attr({'data-grid': 'confirm', 'data-invite-type': selfVal}).text(lang('Invite now'));
                $('.panel-finish .info').css('display', 'inline-block');

            }

        });

        function inviter(noSendGetCost) {
            if (noSendGetCost == false) {
                var form = $("#invitation");
                var value = $(form).serialize(), startScreenId;

                if ($("#startScreenDiv").attr('style') === "" ||
                    $("#startScreenDiv").attr('style') === undefined) {
                    startScreenId = $("#startScreen").val();
                } else {
                    startScreenId = "0";
                }

                $.ajax({
                    url: '/applications/inviteeverybody',
                    type: "POST",
                    data: {value:value, startScreenId:startScreenId, nosendgetcost: noSendGetCost},
                    beforeSend: function() {
                        if (noSendGetCost === true) {
                            $("#totalprice").html('<img src="/process.gif" style="height:20px;">');
                        }
                    }
                }).done(function (a, b, c) {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        if (noSendGetCost === false) {
                            grit(lang('Invited'), 'growl-success');
                        } else {
                            $("#totalprice").html(obj.cost);
                        }
                    } else {
                        if (noSendGetCost === false) {
                            grit(lang('Invite failed'), 'growl-danger');
                        } else {
                            $("#totalprice").html(lang("Error"));
                        }
                    }
                }).fail(function (a, b, c) {
                    if (noSendGetCost === false) {
                        grit(lang('Invite failed'), 'growl-danger');
                    }
                }).always(function (a, b, c) { });
            }

        }


        $("body").delegate("#inviteeverybody", "click", function (e) {
            e.preventDefault();
            inviter(false);
        });

        $("body").delegate("[data-tochangesta]", "click", function (e) ///
        {
            e.preventDefault();
            $.ajax(
                {
                    url: '/backend/dostatus',
                    type: "POST",
                    data: {type: $(this).data('tochangesta'), appid: $(this).data('appid')}
                }).done(function (a, b, c)
                {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        ModalHero.showDialog('#modal-hero', obj.html);
                        //$("#mes-subject").focus();
                    }
                    else {
                        $.gritter.add({
                            title: lang('Failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });

                    };
                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });
        });

        $("body").delegate("[data-tochangeresp]", "click", function (e) ///
        {
            e.preventDefault();
            $.ajax(
                {
                    url: '/backend/doresponsible',
                    type: "POST",
                    data: {type: $(this).data('tochangeresp'), appid: $(this).data('appid')}
                }).done(function (a, b, c)
                {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        ModalHero.showDialog('#modal-hero', obj.html);
                        //$("#mes-subject").focus();
                    }
                    else {
                        $.gritter.add({
                            title: lang('Failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });

                    };
                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });
        });

        $("body").delegate("[data-multiplechangesta]", "change", function (e) {
            e.preventDefault();
            var url = '/backend/multiplechangestatus';
            var ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');

            var forAll = check_for_all();
            var appId = $('#appid').val();

            $.ajax({
                url: url,
                type: "POST",
                data: {elementIds: ids, newStatusId: $(this).val(), forAll: forAll, model: "backend", appId: appId}
            }).done(function (a, b, c) {
                let obj = {};
                let page;

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    $('#grid_refresh').val('1');
                    grit(lang('Changed'), 'growl-success');
                    if (obj.page !== undefined) {page = obj.page} else {page = 1}
                    $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                } else {
                    grit(lang('Change failed'), 'growl-danger');
                }
            }).fail(function (a, b, c) {
                grit(lang('Change failed'), 'growl-danger');
            }).always(function (a, b, c) {});
        });

        $("body").delegate("[data-multiplechangeresp]", "change", function (e) {
            e.preventDefault();
            var url = '/backend/multiplechangeresponsible';
            var ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');

            var forAll = check_for_all();
            var appId = $('#appid').val();

            $.ajax({
                url: url,
                type: "POST",
                data: {elementIds: ids, newRespId: $(this).val(), model: "backend", appId:appId, forAll:forAll}
            }).done(function (a, b, c) {
                let obj = {};
                let page;

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    $('#grid_refresh').val('1');
                    grit(lang('Changed'), 'growl-success');
                    if (obj.page !== undefined) {page = obj.page} else {page = 1}
                    $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                } else {
                    grit(lang('Change failed'), 'growl-danger');
                }
            }).fail(function (a, b, c) {
                grit(lang('Change failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $("body").delegate("#publishapp", "click", function (e) {
            e.preventDefault();
            var checkDescription = false;
            var checkVideoScript = false;
            var catid = $('#choose-catpromo').val();
            var appid = $(this).data('appid');
            var url = '/applications/publish/' + appid;
            var tempName = $('#temp-name').val();
            var tempDescription = $('#temp-description').val();
            var tempVideoScript = $('#temp-videoscript').val(), publicState;
            if ($('#meth1').attr('checked') == 'checked') {
                publicState = 0;
            } else {
                publicState = 1;
            }

            if (tempDescription.length <= 4999) {
                checkDescription = true;
            } else {
                alert(lang("Sorry, this description is too long, allowed less 5000 symbols."));
            }

            var urlregex = new RegExp(
                "^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)");
            if (urlregex.test(tempVideoScript)) {
                checkVideoScript = true;
            } else {
                alert(lang("Sorry, allowed only link for video content in video fields."));
            }

            if (checkDescription){
            if (checkVideoScript){
                $.ajax({
                    url: url,
                    type: "POST",
                    data: {
                        name: tempName,
                        description: tempDescription,
                        publicState: publicState,
                        catid: catid,
                        video: tempVideoScript
                    }
                }).done(function (a, b, c) {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        grit(lang('Published'), 'growl-success');
                        //say 'ok' for user
                    } else {
                        grit(lang('Publish failed'), 'growl-danger');
                        //some thing wrong in given data
                    }
                    window.location.href = "/";
                    //if already is ok - after execution ajax we'll reload current page
                }).fail(function (a, b, c) {
                    grit(lang('Publish failed'), 'growl-danger');
                    window.location.href = "/";
                    //or show 'danger' and return on current page
                });
            }}
        });

        $("body").delegate("[data-print]", "click", function (e) {
            e.preventDefault();
            var url = '/' + $(this).data('print') + '/print/' + $(this).data('eventid');
            window.open(url, '_blank');
        });

        $("body").delegate("[data-torotatereadstatus]", "click", function (e) ///
        {
            e.preventDefault();

            var userid = $(this).data('eventid'), ids;
            if (userid !== '' && userid !== undefined){
                ids = [userid];
            } else {
                ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');
            }

            var appid = $(this).data('appid');
            var setstate = $(this).data('state');
            var forAll = check_for_all();

            $.ajax(
                {
                    url: '/backend/rotatereadstatus',
                    type: "POST",
                    data: {type: $(this).data('torotatereadstatus'), ids: ids, appId: appid, setstate: setstate, forAll:forAll, model:"backend"}
                }).done(function (a, b, c)
                {
                    let obj = {};
                    let page;

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        if (obj.page !== undefined) {page = obj.page} else {page = 1}
                        $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                        grit(lang('Success'), 'growl-success');
                    }
                    else {
                        grit(lang('Failed'), 'growl-danger');
                    };
                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });
        });

        $("body").delegate("[data-toinvite]", "click", function (e) ///
        {
            e.preventDefault();

            var userid = $(this).data('eventid'), ids;
            if (userid !== '' && userid !== undefined){
                ids = [userid];
            } else {
                ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');
            }

            var appid = $(this).data('appid');

            $.ajax(
                {
                    url: '/users/invite',
                    type: "POST",
                    data: {type: $(this).data('toinvite'), ids: ids, appid: appid}
                }).done(function (a, b, c)
                {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        $.gritter.add({
                            title: lang('Invited'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '1000'
                        });
                    }
                    else {
                        $.gritter.add({
                            title: lang('Failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });
                    };
                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });
        });

        function message3ViewControl() {
          let smsChecked = $('#msg-method-sms').prop('checked');
          if (smsChecked && $('#preview-msg-close').text() !== "") {
            $('#div-i3').show();
          } else {
            $('#div-i3').hide();
          }
        }

        function subCheckersDisable(context, state) {
            var form = $($(context).closest('.b-container__body.choose-form'));
            $(form).find('#msg-method-push').prop('disabled', state);
            $(form).find('#msg-method-email').prop('disabled', state);
            $(form).find('#msg-method-sms').prop('disabled', state);
        }
        function subCheckersCheck(context, state) {
            var form = $($(context).closest('.b-container__body.choose-form'));
            $(form).find('#msg-method-push').prop('checked', state);
            $(form).find('#msg-method-email').prop('checked', state);
            $(form).find('#msg-method-sms').prop('checked', state);
        }

        $(document.body).on('click', 'input[name="msg-method"]', function() {

            var form = $($(this).closest('.b-container__body.choose-form'));

            var guaranteed = $(form).find('#msg-method-guaranteed'),
                parallel = $(form).find('#msg-method-parallel'),
                push = $(form).find('#msg-method-push'),
                email = $(form).find('#msg-method-email'),
                sms = $(form).find('#msg-method-sms'),

                guaranteedChecked = $(guaranteed).prop('checked'),
                parallelChecked = $(parallel).prop('checked'),
                pushChecked = $(push).prop('checked'),
                emailChecked = $(email).prop('checked'),
                smsChecked = $(sms).prop('checked'),

                emailLock = $(email).attr('data-lock'),
                phoneLock = $(sms).attr('data-lock');

            var subCheck = true;
            if (pushChecked !== true && emailChecked !== true && smsChecked !== true) {
                var subCheck = false;
            }

            if ($(this).prop('id') === 'msg-method-guaranteed') {
                if (guaranteedChecked === true) {
                    $(parallel).prop('checked', false);
                    subCheckersDisable(this, true);
                    subCheckersCheck(this, false);
                } else {
                    $(parallel).prop('checked', true);
                    subCheckersDisable(this, false);
                    subCheckersCheck(this, true);
                }
            } else if ($(this).prop('id') === 'msg-method-parallel') {
                if (parallelChecked === true) {
                    $(guaranteed).prop('checked', false);
                    subCheckersDisable(this, false);
                    if (!subCheck) {
                        subCheckersCheck(this, true);
                    }
                } else {
                    $(guaranteed).prop('checked', true);
                    subCheckersDisable(this, true);
                    subCheckersCheck(this, false);
                }
            } else {
                if (!subCheck && guaranteedChecked !== undefined) {
                    $(guaranteed).prop('checked', true);
                    $(parallel).prop('checked', false);
                    subCheckersDisable(this, true);
                }
            }

            if ($(sms).prop('checked')) {
                $('#smspartcountindicator').prop('style', '');
            } else $('#smspartcountindicator').prop('style', 'display:none');

            message3ViewControl();
        });

        $("body").delegate("#sendmessage", "click", function (e) {   // Отправка кастомного сообщения в объектах
            e.preventDefault();

            var eventid = $(this).attr('data-eventid');
            var appid = $(this).attr('data-appid');
            var forone = $(this).attr('data-forone'), ids, startScreenId;

            if (forone === '1'){
                ids = [eventid];
            } else {
                ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');
            }

            if ($('#startScreenDiv1').attr('style') === '') {
                startScreenId = $('#startScreenDiv1').find('#startScreen').val();
            } else {
                startScreenId = '0';
            }
            $('#mes-body').val($('#mes-body').val().replace( /\n/g, '<br \\>' )); /// for taking line endings beacuse serialize dont take them.
            var type = $("#sendpoint").val(),
                formdata = $(".sexybaby").serialize(),
                //choosedata = $(".choose-form").serialize();

                guaranteed = $('#msg-method-guaranteed').is(':checked'),
                parallel = $('#msg-method-parallel').is(':checked'),
                push = $('#msg-method-push').is(':checked'),
                email = $('#msg-method-email').is(':checked'),
                sms = $('#msg-method-sms').is(':checked');

            var forAll = check_for_all();
            $.ajax({
                    url: '/senderpool/sendmessage',
                    type: "POST",
                    data: {
                        sendIds: ids,
                        type: type,
                        formdata: formdata,
                        appid: appid,
                        startScreenId: startScreenId,
                        forAll: forAll,
                        guaranteed: guaranteed,
                        parallel: parallel,
                        push: push,
                        email: email,
                        sms: sms
                    }
                }).done(function (a, b, c) {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        $('body').css({'overflow': 'visible', 'padding-right': '0px'});
                        $('.templates #details').hide();
                        grit(lang('Success'), 'growl-success');
                    } else {
                        grit(lang('Failed'), 'growl-danger');
                    }
                }).fail(function (a, b, c) {
                    grit(lang('Failed'), 'growl-danger');
                }) .always(function (a, b, c) {  });
        });

        $("body").delegate("#saveSMSelector", "click", function (e) {
            e.preventDefault();
            var userid = $('#responsible').val();
            var backendid = $('#backendid').val();
            var url = '/backend/changesmuser';

            $.ajax({
                url: url,
                type: "POST",
                data: {userid: userid, backendid: backendid}
            })
                .done(function (a, b, c) {
                    let obj = {};
                    let page;

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        if (obj.page !== undefined) {page = obj.page} else {page = 1}
                        $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                        grit(lang('Saved'), 'growl-success');
                    } else {
                        grit(lang('Save failed'), 'growl-danger');
                    }
                }).fail(function (a, b, c) {
                    grit(lang('Save failed'), 'growl-danger');
                }).always(function (a, b, c) { });
        });

        $("body").delegate("#saveStatusSelector", "click", function (e)
        {
            e.preventDefault();
            var statusid = $('#status').val();
            var backendid = $('#backendid').val();
            var url = '/backend/changestatus';

            $.ajax(
                {
                    url: url,
                    type: "POST",
                    data: {statusid: statusid, backendid: backendid}
                }).done(function (a, b, c)
                {
                    let obj = {};
                    let page;

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        if (obj.page !== undefined) {page = obj.page} else {page = 1}
                        $.fn.yiiGridView.update("my-model-grid",{data:{Backend_page:page}});
                        $.gritter.add({
                            title: lang('Saved'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '1000'
                        });
                    }
                    else {
                        $.gritter.add({
                            title: lang('Save failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });

                    }

                })
                .fail(function (a, b, c)
                {
                    $.gritter.add({
                        title: lang('Save failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });

                })
                .always(function (a, b, c)
                {
                });
        });

        $(document.body).on('click', 'a[data-lastid]', function (e) {
            e.preventDefault();
            var lastid = $(this).attr('data-lastid');
            var linkid = $(this).data('linkid');
            var linkname = $(this).data('linkname');
            var that = this;
            //console.info(lastid);
            var formdata = {lastid: lastid, linkid: linkid, linkname: linkname};
            $.ajax(
                {
                    url: "/backend/getpart",
                    type: "POST",
                    data: formdata,
                    dataType: 'json',
                    beforeSend: function() {
                        $(that).addClass('btn-more--load');
                    }
                }).done(function (a, b, c) {
                if (a.error !== undefined && a.error === "" ){
                    $('#smcomments').prepend(a.html);
                    $(that).attr('data-lastid', a.id);
                } else {
                    console.info(a.error);
                }
            }).always(function() {
                $(that).removeClass('btn-more--load');
            });
        });

        $(document.body).on('click', '#sendcomment', function (e) {
            e.preventDefault();
            var text = $('#r_comment').val();
            var linkid = $(this).data('linkid');
            var linkname = $(this).data('linkname');
            var that = this;
            //console.info(lastid);
            var formdata = {text: text, linkid: linkid, linkname: linkname, who:$(this).data('who')};
            $.ajax(
                {
                    url: "/backend/add",
                    type: "POST",
                    data: formdata,
                    dataType: 'json',
                    beforeSend: function() {
                        $(that).addClass('load');
                    }
                }).done(function (a, b, c) {
                if (a.error !== undefined && a.error === "" ){
                    $('#r_comment').val('');
                    $('#smcomments').append(a.html);
                    var comments = $('.ms-message .b-container__body'),
                        hComments = $('#smcomments').height();
                    comments.scrollTop(hComments);
                } else {
                    console.info(a.error);
                }
            }).always(function() {
                $(that).removeClass('load');
            });
        });


        $(document.body).delegate('#setlang', 'click', function (e) {
            e.preventDefault();
            //var langCode = $(this).data('lang');
            var langCode = $(this).data('lang'),
                switchLang = $('.btn-header--lang');
            $.ajax(
                {
                    url: "/site/setlang",
                    type: "POST",
                    data: {newlang: langCode},
                    dataType: 'json'
                }).done(function (a, b, c) {
                if (a.error !== undefined && a.error === "" ){
                    $.gritter.add({
                        title: lang('Success'),
                        text: '',
                        class_name: 'growl-success',
                        sticky: false,
                        time: '1000'
                    });
                    switchLang.addClass('active');
                    window.location.reload();
                } else {
                    $.gritter.add({
                        title: a.error,
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });
                }
            });
        });

        $(document.body).delegate('a[data-messagelastid]', 'click', function (e) {

            e.preventDefault();
            var appid = $(this).attr('data-appid');
            var lastid = $(this).attr('data-messagelastid'),
                linkid = $(this).data('linkid'),
                linkname = $(this).data('linkname'),
                that = this,
                formdata = {lastid: lastid, linkid: linkid, linkname: linkname, appid: appid};
            $.ajax(
                {
                    url: "/senderpooltemplate/getpart",
                    type: "POST",
                    data: formdata,
                    dataType: 'json'
                }).done(function (a, b, c) {
                if (a.error !== undefined && a.error === "") {
                    $('#smcomments').append(a.html);
                    $(that).attr('data-messagelastid', a.id);
                } else {
                    console.info(a.error);
                    if(a.html && lastid>0){
                        $('#smcomments').append(a.html);
                    }
                    gritlong(lang(a.error), 'growl-danger');
                }
            });
        });

        /** евент для обработки нажатия на кнопку добавления колонки в бекенд */
        $(document).on('click', '#addBackendCol', function (e) {
            // e.preventDefault();
            $("#data .form-control, #data .b-data-test").attr('disabled', 'disabled');
            var appid = $(this).data('appid');
            $.ajax({
                url: "/backend/addcolumngetform/",
                type: "POST",
                data: {id: appid},
                beforeSend: function (xhr) { }
            }).done(function (a, b, c) {
                try {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    if (obj.error === "") {
                        $('.b-setup-collumn').addClass('b-setup-collumn--active');
                        var pb = $('.b-setup-collumn__header').outerHeight();
                        $('.b-setup-collumn').css('padding-bottom', pb);
                        $("#properties").html(obj.html);

                    } else grit(lang('RESTRICTED'), 'growl-danger', obj.error);
                } catch (error) {
                    logger.exception(error, a);
                    //grit(lang('Error'), 'growl-danger');
                }
            }).fail(function (a, b, c) {
                grit(lang('Error'), 'growl-danger');
            }).always(function (a, b, c) {});
        });

        $(document.body).delegate('input[data-setuptemplate]', 'click', function (e) {
            var templateId = $(this).data('setuptemplate');
            var startScreenId = $(this).data('startscreenid');
            $('#mes-subject').val($('#setuptemplatesubject' + templateId).html());
            $('#mes-body').val($('#setuptemplatebody' + templateId).html());

            if (startScreenId !== undefined && startScreenId !== "0") {
                $('#startScreenDiv1').attr('style', '');
                $('#startScreenDiv1').find('#startScreen').val(startScreenId);
            }
        });

        $("body").delegate(':input[name="grid_file"]', "change", function (e){

            var fileObject = $(this)[0].files[0];
            var linkid = $(this).data('linkid');
            var linkname = $(this).data('linkname');
            var columnname = $(this).data('column');

            var appid = MOBSTEDAPP.application.id;
            if  (typeof appid == 'undefined')   {
                // "Utils" was not imported for use here.
                // appid = Utils.getParameterByName('appid');//MOBSTEDAPP.appId; -- it could does not work
                appid = $('#appid').val();
            }

            var postUrl = '/applications/uploadfiles';

            if (fileObject !== undefined) {
                var fd = new FormData();
                var fileExtension = fileObject.name.split('.').pop().toLowerCase();
                var fileSize = fileObject.size;

                fd.append("appid", appid);
                fd.append("linkid", linkid);
                fd.append("linkname", linkname);
                fd.append("column", columnname);
                fd.append("UploadedFiles[image]", fileObject);

                var input = $(this).data("type");
                var linkName = $(this).data("linkname");
                var linkId = $(this).data("linkid");
                //var replaceContainer = $().data("type");

                var checkExt = false;
                var checkSize = false;

                var blacklist = "php js sh";
                var checkExtRes = blacklist.indexOf(fileExtension);
                if (checkExtRes === -1) {
                    checkExt = true;
                    if (fileSize <= 52428800) {
                        checkSize = true;
                    }
                }


                if (checkExt) {
                    if (checkSize) {
                        $.ajax({
                            url: postUrl,
                            type: 'POST',
                            cache: false,
                            data: fd,
                            datatype: 'json',
                            processData: false,
                            contentType: false,
                            success: function (data) {
                                let obj = {};

                                try {
                                    obj = JSON && JSON.parse(data) || $.parseJSON(data);
                                } catch (error) {
                                    logger.exception(error, data);
                                }

                                if (obj.error === "") {
                                    console.log(linkid);
                                    if (linkid !== undefined) {
                                        $('#' + linkName + '_' + columnname + '_' + linkId).html(obj.html);
                                        $(input).val('');
                                    } else {
                                        console.log('div'+'#'+linkName+'_'+columnname+'.attach');
                                        /** ветка для подстановки текста файла в панель настройки у колонки */
                                        $('div'+'#'+linkName+'_'+columnname+'.attach').html(obj.html);
                                    }

                                } else {
                                    alert(obj.error);
                                }
                            },
                            error: function () {
                                alert(lang("error upload file!"));
                            }
                        });
                    } else {
                        alert(lang("Sorry, this file size is larger than allowed!"));
                    }
                } else {
                    alert(lang("Sorry, this file type not supported!"));
                }
            }

        });

        $(document).delegate('#pullactionsbtn', 'click', function (e) {
            var appId = $('#appid').val();
            $.ajax({
                url: '/backend/pullactions/' + appId,
                type: 'POST',
                datatype: 'json',
                success: function (data) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(data) || $.parseJSON(data);
                    } catch (error) {
                        logger.exception(error, data);
                    }

                    if (obj.error !== "") {

                        $.gritter.add({
                            title: lang('Pull actions failed'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '1000'
                        });

                    } else {
                        if (obj.stype > 0) {
                            $.gritter.add({
                                title: obj.success,
                                text: '',
                                class_name: 'growl-success',
                                sticky: false,
                                time: '1000'
                            });
                        }
                        if (obj.stype === 0) {
                            $.gritter.add({
                                title: obj.success,
                                text: '',
                                class_name: 'growl-info',
                                sticky: false,
                                time: '1000'
                            });
                        }
                    }
                },
                error: function () {
                    $.gritter.add({
                        title: lang('Pull actions failed'),
                        text: '',
                        class_name: 'growl-danger',
                        sticky: false,
                        time: '1000'
                    });
                }
            });
        });

        $(document).delegate('#deletefile', 'click', function (e) {
            var parent = $(this).parent();
            var fileId = $(parent).data('fileid');
            var property = $(parent).data('property'), data = {};

            if (property !== undefined) {
                data = {appId: MOBSTEDAPP.appId, objectColumn: property};
            }

            $.ajax({
                url: '/files/filedelete/' + fileId,
                type: 'POST',
                data: data,
                datatype: 'json',
                success: function (data) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(data) || $.parseJSON(data);
                    } catch (error) {
                        logger.exception(error, data);
                    }

                    if (obj.error === "") {
                        $(parent).parent().text('');
                    } else {
                        alert(obj.error);
                    }
                },
                error: function () {
                    console.log(lang("error delete file!"));
                }
            });
        });

        $(document.body).on('change', '#r_file', function (e) {
            //e.preventDefault();

            var fileObject = $(':input[type="file"]')[0].files[0],
                self = $(this),
                linkid = self.data('linkid'),
                linkname = self.data('linkname');

            //console.log(fileObject);
            if (fileObject !== undefined) {
                var fd = new FormData(),
                    fileExtension = fileObject.name.split('.').pop().toLowerCase(),
                    fileSize = fileObject.size;

                fd.append("linkid", linkid);
                fd.append("linkname", linkname);
                fd.append("who", $(this).data('who'));
                fd.append("UploadedFiles[image]", fileObject);

                var input = $(':input[type="file"]').data("type");

                var checkExt = false;
                var checkSize = false;
                var postUrl = '/serviceman/uploadfiles';
                //console.log();

//            if (input === "filebtn") {
//                if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "gif" || fileExtension === "png") {
//                    checkExt = true;
//                    if (fileSize <= 10485760) {
//                        checkSize = true;
//                        postUrl = '/serviceman/uploadimages';
//                    }
//                }
//            } else {
//                var blacklist = "php js sh";
//                var checkExtRes = blacklist.indexOf(fileExtension);
//                if (checkExtRes === -1) {
//                    checkExt = true;
//                    if (fileSize <= 52428800) {
//                        checkSize = true;
//                        postUrl = '/serviceman/uploadfiles';
//                    }
//                }
//            }


                var blacklist = "php js sh";
                var checkExtRes = blacklist.indexOf(fileExtension);
                if (checkExtRes === -1) {
                    checkExt = true;
                    if (fileSize <= 52428800) {
                        checkSize = true;
                        postUrl = '/serviceman/uploadfiles';
                    }
                }


                if (checkExt) {
                    if (checkSize) {
                        $.ajax({
                            url: postUrl,
                            type: 'POST',
                            cache: false,
                            data: fd,
                            datatype: 'json',
                            processData: false,
                            contentType: false,
                            beforeSend: function() {
                                self.parents('.btn-upload').addClass('load');
                            }
                        }).done(function(a,b,c) {
                            let obj = {};

                            try {
                                obj = JSON && JSON.parse(a) || $.parseJSON(a);
                            } catch (error) {
                                logger.exception(error, a);
                            }

                            if (obj.error === "") {
//                                var url = obj.url;
//                                $("#properties-comp #source").val(obj.url).trigger("input");
//                                if (obj.filename !== undefined) {
//                                    $("#properties-comp #label").val(obj.filename).trigger("input");
//                                }
                                $('#smcomments').append(obj.html);
                                var comments = $('.ms-message .b-container__body'),
                                    hComments = $('#smcomments').height();
                                $('img').load(function() {
                                    comments.scrollTop(hComments);
                                });
                            } else {
                                alert(obj.error);
                            }
                        }).fail(function() {
                            alert(lang("error upload file!"));
                        }).always(function() {
                            self.parents('.btn-upload').removeClass('load');
                        });
                    } else {
                        alert(lang("Sorry, this file size is larger than allowed!"));
                    }
                } else {
                    alert(lang("Sorry, this file type not supported!"));
                }
            }

        });

        $(document).delegate('[data-calculatesendprice]', 'click', function (e) {
            inviter(true);
        });


        $(document.body).on('click', '.b-data-report', function(e) {

            e.preventDefault();
            var self        = $(this),
                appId       = self.attr('data-appid'),
                type        = self.attr('data-type');

            $.ajax({
                    url: '/applications/reportdata',
                    type: 'POST',
                    data: {type: type, appid: appId},
                    beforeSend: function() {
                        self.addClass('load');
                    }
                })
                .done(function(a, b, c) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error !== "") {
                        $.gritter.add({
                            title: lang('Fail'),
                            text: '',
                            class_name: 'growl-danger',
                            sticky: false,
                            time: '500'
                        });
                    } else {
                        $.gritter.add({
                            title: lang('Requested'),
                            text: '',
                            class_name: 'growl-success',
                            sticky: false,
                            time: '500'
                        });
                    }
                    self.removeClass('load');
                })
                .fail(function(a, b, c) {
                    $.gritter.add({
                        title: lang('Fail'),
                        text: '',
                        class_name: 'growl-success',
                        sticky: false,
                        time: '500'
                    });
                    self.removeClass('load');
                })
                .always(function(a, b, c) {});

        });

// example request RESTApi
        $(document.body).on('click', '#testRestApi', function(){
            $.ajax({
                type: 'POST',
                url: 'http://jsonstub.com/your/first/request',
                contentType: 'application/json',
                beforeSend: function (request) {
                    request.setRequestHeader('JsonStub-User-Key', 'ae998dad-0a19-4319-9e1f-10c7e08db66e');
                    request.setRequestHeader('JsonStub-Project-Key', 'd5452d9d-8110-4fd3-b6be-cd4beb6bb3b1');
                }
            }).done(function (data) {
                alert(JSON.stringify(data, null, 4));
            });
        });


        $(document.body).delegate('a[data-type="rest-api"], a[data-type="mysql"]', 'click', function(e){
            e.preventDefault();
            var self        = $(this),
                type        = self.attr('data-type'),
                appId       = self.attr('data-appid'),
                column      = self.attr('data-column');
            $.ajax({
                url: '/objectcolumns/setusetype',
                type: 'POST',
                data: {type: type, appid: appId, column:column},
                beforeSend: function() {
                    self.addClass('load');
                }
            }).done(function(a, b, c) {
                let obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== "") {
                    grit(lang('Switch failed'), 'growl-danger');
                } else {
                    grit(lang('Switched'), 'growl-success');
                }
                self.removeClass('load');
            }).fail(function(a, b, c) {
                grit(lang('Switch failed'), 'growl-danger');
                self.removeClass('load');
            }).always(function(a, b, c) {});
        });


        $(document.body).delegate('#externaldatastate, #rest-api-auth, #rest-api-request, #rest-api-url, #rest-api-response, ' +
            '#rest-api-key, #rest-api-value, #rest-api-basic-user, #rest-api-basic-password, ' +
            '#mysql-host, #mysql-db, #mysql-user, #mysql-password, #mysql-request, #mysql-key', 'input paste change', function(e){
            var restSwitch  = $('[data-type="rest-api"]'),
                appId       = restSwitch.attr('data-appid'),
                column      = restSwitch.attr('data-column'),
                option      = $(this).attr('id'),
                value       = $(this).val();

            if (option === 'rest-api-auth' && value === 'none') {
                $('#rest-api-basic-user').parent().attr('style','display:none;');
                $('#rest-api-basic-password').parent().attr('style','display:none;');
            } else if (option === 'rest-api-auth') {
                $('#rest-api-basic-user').parent().attr('style','');
                $('#rest-api-basic-password').parent().attr('style','');
            }

            //console.log();

            if (option === 'externaldatastate') {
                value = $(this).is(':checked');
            }
            $.ajax({
                url: '/objectcolumns/savedataoption',
                type: 'POST',
                data: {appid: appId, column:column, option:option, value:value},
                beforeSend: function() {}
            }).done(function(a, b, c) {
                let obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== "") {
                    grit(lang('Failed'), 'growl-danger');
                } else {
                    grit(lang('Saved'), 'growl-success');
                }
            }).fail(function(a, b, c) {
                grit(lang('Failed'), 'growl-danger');
            }).always(function(a, b, c) {});
        });

        /** отправка запроса на проверку удаленного соединения в свойствах колонки объекта */
        $(document.body).delegate('#testconnection', 'click', function(e){
            e.preventDefault();
            var restSwitch  = $('[data-type="rest-api"]'),
                appId       = restSwitch.attr('data-appid'),
                column      = restSwitch.attr('data-column');
            $.ajax({
                url: '/objectcolumns/testconnection',
                type: 'POST',
                data: {appid: appId, column:column},
                beforeSend: function() {}
            }).done(function(a, b, c) {
                let obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== "") {
                    grit(lang(obj.error), 'growl-danger');
                } else {
                    $('#rest-api-key').html(obj.html);
                    $('#mysql-key').html(obj.html);
                    grit(lang('Connect success'), 'growl-success');
                }
            }).fail(function(a, b, c) {
                grit(lang('Failed'), 'growl-danger');
            }).always(function(a, b, c) {});
        });

        $(document.body).on('click', "input[type='checkbox'][name^='Settings']", function(e)
        {
            var appid = $(this).data('appid');
            var roleid = $(this).data('roleid');
            var method = $(this).data('method'), enabled = 1;
            if (this.checked == false) {
                enabled = 0;
            }

            $.ajax({
                //url: '/security/savenotification',
                url: '/roles/savenotification',
                type: 'POST',
                data: {
                    appid: appid,
                    roleid: roleid,
                    enabled: enabled,
                    method: method
                },
                beforeSend: function () {}
            }).done(function (a, b, c) {
                let obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== "") {
                    grit(lang(obj.error), 'growl-danger');
                } else {
                    grit(lang('Save success'), 'growl-success');
                }
            }).fail(function (a, b, c) {
                grit(lang('Failed saving'), 'growl-danger');
            }).always(function (a, b, c) {});
        });

        function copySmartFilter(filterId, appid) {
            // контекст привязал к progressWizard
            let context = 'objects'
            if ($('#progressWizard').html() === undefined) {
                context = 'backend';
            }
            $.ajax({
                url: '/backend/copysmartfilter',
                type: 'POST',
                data: {
                    appid: appid,
                    filterId: filterId,
                    context: context
                }
            }).done(function (a, b, c){
                let obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== "") {
                    grit(lang(obj.error), 'growl-danger');
                } else {
                    grit(lang('Copied'), 'growl-success');
                    $('.b-create-sf__list tbody').append(obj.trHtml);
                    $('.b-smart-filters__items').append(obj.widgetHtml);
                    window.updateFontSize();
                    window.heightSmartFilters();
                }
            }).fail(function (a, b, c) {
                grit(lang('Not copied'), 'growl-danger');
            }).always(function (a, b, c) {
                $('div#'+filterId).removeClass('b-smart-filters__item--menu');
            });
        }

        $(document.body).on('click', '.b-smart-filters__menu a#filtercopyinmenu', function(e) {
            e.preventDefault();
            var filterId = $(this).parents('.b-smart-filters__item').attr('id');
            var appid = $('#appid').val();
            copySmartFilter(filterId, appid);
        });

        $(document.body).on('click', '.b-create-sf__item a[data-grid="copy-sf"]', function(e) {
            e.preventDefault();
            var filterId = $(this).parents('.b-create-sf__item').attr('data-filterid');
            var appid = $('#appid').val();
            copySmartFilter(filterId, appid);
        });

        $('#generate-keys').on('click', function(e) {
            $.ajax({
                url: '/users/generatekeys',
                type: 'POST',
                data: {}
            }).done(function (a, b, c){
                let obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error !== "") {
                    grit(lang(obj.error), 'growl-danger');
                } else {
                    grit(lang('Copied'), 'growl-success');
                    $('#key1-profile').val(obj.key1);
                    $('#key2-profile').val(obj.key2);
                    $('#base64-profile').val(obj.base64);
                }
            }).fail(function (a, b, c) {
                grit(lang('Failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });

        $('#save-jwtsecret').on('click', function(e) {
            $.ajax({
                url: '/users/savejwtsecret',
                type: 'POST',
                data: {jwtsecret:  $('#jwtsecret-profile').val()}
            }).done(function (a, b, c){
                let obj = JSON && JSON.parse(a) || $.parseJSON(a);
                if (obj.error !== "") {
                    grit(lang(obj.error), 'growl-danger');
                } else {
                    grit(lang('Saved'), 'growl-success');
                }
            }).fail(function (a, b, c) {
                grit(lang('Failed'), 'growl-danger');
            }).always(function (a, b, c) {
            });
        });


        $('#autotimezone-profile').on('click', function(e) {
            if ($(this).is(':checked')) {
                $('#timezone-profile').attr('disabled', '');
            } else {
                $('#timezone-profile').removeAttr('disabled');
            }
        });

        /** кликнуть по радиокнопке при клике по внешнему блоку */
        $('body').delegate('div[data-crutch]', 'click', function (e) {
            var item = $(this).closest('.b-app-items__item');
            var radio = $(item).find('[data-categoryid]');
            $(radio).click();
        });


        function check_for_all() {
            var attr = $('#selected-row-all').attr('data-onoff');
            if (typeof attr !== "undefined") {
                return attr;
            } else return "off";
        }
        //////////////////////
        /*
         Весь спаггети код сюда писать!!!!
         не выходите за return
         */


        ///////////////////////////////не переходить эту мега черту
    },

    heightHeading: function(heading) {
        var h = heading.outerHeight();
        return h;
    }


}

window.searchSend = function(url, data, position) {
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        beforeSend: function(xhr) {
            var loadingSpinner = document.createElement('i');
            loadingSpinner.setAttribute('class', 'ajax-spinner');
            $('.search-box-nav__item a').each(function (){
                if ($(this).attr('href') === position)
                    $(this).find('span').html(loadingSpinner);
            });
        }
    })
        .done(function(a, b, c) {
            let obj = {};

            try {
                obj = JSON && JSON.parse(a) || $.parseJSON(a);
            } catch (error) {
                logger.exception(error, a);
            }

            if (obj.error === ''){
                $(position).find('.ajax-spinner').remove();
                $(position).append(obj.html);
                $('.search-box-nav__item a').each(function (){
                    if ($(this).attr('href') === position)
                        $(this).find('span').html(obj.count);
                });
            } else {
                $('.search-box-nav__item a').each(function (){
                    if ($(this).attr('href') === position)
                        $(this).find('span').html('0');
                });
                $(position).find('.ajax-spinner').remove();
                $(position).append('<span>no results</span>');
            }
        })
        .fail(function(a, b, c) {

        })
        .always(function(a, b, c) {

        })
}

// Эта функция навешивает события на элементы страницы bc/index
window.bcindex_element_events = function() {

    // Обработчик клика на кнопку публикации приложения
    $(document.body).on('click', '[data-toggle="publish"]', function(e) {
        //e.preventDefault();
        if ($(this).data('path')) {
            let appId = $(this).data('path');
            $.ajax({
                url: "/applications/prepublish/",
                type: "POST",
                data: {appId: appId},
            })
                .done(function (a, b, c) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === ''){
                        window.location.href = "/publishapp/index/" + appId;
                    }
                }).fail(function (a, b, c) { });
        }
    });

}

window.set_legend = function(msg) {
    var appid1 = $('#appid').val(),
        formdata = {appid: appid1, msg: msg};
    $.ajax(
        {
            url: "/objects/showlegend",
            type: "POST",
            data: formdata,
            dataType: 'json'
        }).done(function (a, b, c) {
            if (a.error !== undefined && a.error === "" ){
                if (!msg || msg === undefined || msg === '') {
                    $('#legend-inner').html(a.html);
                }
                else {
                    $('#legend-msg-inner').html(a.html);

                    $(document.body).on('click', '.b-legend__item', function() {

                        var self = $(this).find('.add-action'),
                            action = self.siblings('input').val(),
                            //pasteAction = $('.sexybaby .paste-action'),
                            pasteAction = $('.sexybaby #title-invite'),
                            pasteActionVal = pasteAction.val();
                        if (pasteActionVal !== undefined) {
                            if (pasteActionVal === '' || pasteActionVal.substr(length - 1) === ' ') {
                                pasteAction.val(pasteAction.val() + action).focus().change();
                            } else {
                                pasteAction.val(pasteAction.val() + ' ' + action).focus().change();
                            }
                        } else {
                            pasteAction = $('.sexybaby #mes-body'),
                            pasteActionVal = pasteAction.val();
                            if (pasteActionVal !== undefined) {
                                if (pasteActionVal === '' || pasteActionVal.substr(length - 1) === ' ') {
                                    pasteAction.val(pasteAction.val() + action).focus().change();
                                } else {
                                    pasteAction.val(pasteAction.val() + ' ' + action).focus().change();
                                }
                            }
                        }

                    });
                    $(document.body).on('focus', '.sexybaby .form-control', function() {
                        var self = $(this),
                            wrSelf = self.parent();
                        if (!self.hasClass('paste-action')) {
                            self.addClass('paste-action');
                            wrSelf.siblings('.form-group').find('.form-control').removeClass('paste-action');
                        }
                    });
                }

            } else {
                //incon("warning", "Данные не получены.");
            }

        });

}

// Эта функция навешивает события на элементы шага 1 конструктора
window.step1_element_events = function() {

    // Обработчик клика на radio button-е приложения
    $(document.body).on('click', '.b-templates .b-app-items__item input[type="radio"]', function() {

        let self        = $(this),
            appItem     = self.parents('.b-app-items__item'),
            tenantPaid = true;  /// Потом как нибудь сюда подадим


         $('.b-wizard__btn--next').removeClass('disabled').addClass('b-wizard__btn--blink');

        if (tenantPaid==false) {
            //alert('Oplatil Suka?');
        }
        else {
            $('.b-wizard').removeClass('b-wizard--newapp').addClass('b-wizard--createapp');
            appItem.addClass('b-app-items__item--active').siblings().removeClass('b-app-items__item--active');

        }

    });

    // Обработчик кнопки закрытия всплывающего окна деталей и видео (один на двоих)
    $('.details-close').on('click', function () {
        $('body').css({'overflow': 'visible', 'padding-right': '0px'});
        $('.details').hide();
    });

    $('.terminal-table').css('padding-bottom', heightHeading($('.terminal-header')) + 'px');


}

window.heightHeading = function(heading) {
    var h = heading.outerHeight();
    return h;
}

window.overScroll = function(header, footer) {
    var hHeader = heightHeading(header),
        hFooter = heightHeading(footer),
        pb = hHeader + hFooter;
    return pb;
}

// Эта функция получает из базы шаблоны-приложения по заданному ИД РОДИТЕЛЬСКОЙ категории
// и устанавливает их в шаге 1 конструктора, а затем навешивает заново события на плашках
window.clickOnParent = function(Obj) {
    if ($(Obj).data('path')) {
        var categoryId = $(Obj).data('path'),
            catText = $(Obj).find('span').text();
    }
    $.ajax(
        {
            url: "/papplications/category/" + categoryId,
            type: "POST",
            beforeSend: function (xhr) {
            }
        }).done(function (a, b, c) {
            if (a !== "") {
                $('#step1apps').html(a);
                if (catText === 'Blank') {
//                if (categoryId === 12) { // пока так
                    $('#step1apps .b-app-items__item').addClass('b-app-items__item--active');
                    $('#step1apps .b-app-items__item input[name="radio"]').attr('checked', true);
                    $('.b-wizard').removeClass('b-wizard--newapp').addClass('b-wizard--createapp');
                    $('.b-wizard__btn--next').removeClass('disabled').addClass('b-wizard__btn--blink');
                }

                step1_element_events();
            } else {
                $('#step1apps').html(lang("No available templates in this category"));
                $('.b-wizard').removeClass('b-wizard--createapp').addClass('b-wizard--newapp');
                $('.b-wizard__btn--next').addClass('disabled').removeClass('b-wizard__btn--blink');
            }
        })
        .fail(function (a, b, c) {
            //incon("error", "Приложения родительской категории не получены.");
        })
        .always(function (a, b, c) {
            //errcon("message");
        });
}

window.refreshObjectColumnSettings = function (appid) {
    $.ajax({
        url: "/objects/refreshcolumnsettings/",
        type: "GET",
        data: {id: appid},
        beforeSend: function (xhr) {
        }
    })
    .done(function (a, b, c) {
        try {
            var obj = JSON && JSON.parse(a) || $.parseJSON(a);
            $("#settings-collumn").html(obj.html);
            var settingsCol = $('#settings-collumn');
            settingsCol.removeClass('open');
            $.gritter.add({
                title: lang('Settings updated'),
                text: '',
                class_name: 'growl-success',
                sticky: false,
                time: ''
            });
        }
        catch (error) {
            logger.exception(error, a);
            $.gritter.add({
                title: lang('Invalid JSON in response'),
                text: error,
                class_name: 'growl-danger',
                sticky: false,
                time: ''
            });
        }

    })
    .fail(function (a) {
        var obj = {};

        try {
            obj = JSON && JSON.parse(a) || $.parseJSON(a);
        } catch (error) {
            logger.exception(error, a);
        }

        $.gritter.add({
            title: lang('Refresh column settings failed'),
            text: obj.error,
            class_name: 'growl-danger',
            sticky: false,
            time: ''
        });

    });
}


/** обработчик значков индетификаторов обязательных для заполнения полей */
window.asteriskUserModalForm = function() {
    var email = $('[name="user-email"]').val();
    var password = $('[name="user-password"]').val();
    var phone = $('[name="user-phone"]').val();
    var pin = $('[name="user-pin"]').val();

    if (email.length === 0) {
        $("#astphone").attr('style', '');
    } else $("#astphone").attr('style', 'display:none');

    if (phone.length === 0) {
        $("#astemail").attr('style', '');
    } else $("#astemail").attr('style', 'display:none');

    if (password.length === 0) {
        $("#astpin").attr('style', '');
    } else $("#astpin").attr('style', 'display:none');

    if (pin.length === 0) {
        $("#astpassword").attr('style', '');
    } else $("#astpassword").attr('style', 'display:none');
}

window.inputModalUserEvents = function() {
    $('.tooltips, [data-toggle="tooltip"]').tooltip({ container: 'body'});
    $('#username').select();

    $("#automaticaltimezone").on("change", function(event) {
        var checked = $(this).is(':checked');
        if (checked) {
            $("#timezone").prop('disabled', true);
        } else {
            $("#timezone").prop('disabled', false);
        }
    });

    $('[name="user-email"]').on("input", function(event) {
        asteriskUserModalForm();
    });

    $('[name="user-password"]').on("input", function(event) {
        asteriskUserModalForm();
    });

    $('[name="user-phone"]').on("input", function(event) {
        asteriskUserModalForm();
    });

    $('[name="user-pin"]').on("input", function(event) {
        asteriskUserModalForm();
    });

}

window.inputStatusSaverEvents = function(){
    var statusId = $('#id-status').val();
    var delay = (function(){
        var timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();

    // Модальное окно редактирования статуса
    $('#statusname').on('input', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                Saver({id: statusId, name: $(that).val()},"/statuses/statussaver/");
            }, 1000);
        }
    });

    $('#whocanuse').on('input', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                Saver({id: statusId, whocanuse: $(that).val()},"/statuses/statussaver/");
            }, 1000);
        }
    });

    $('#statussortorder').on('input', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                Saver({id: statusId, sortorder: $(that).val()},"/statuses/statussaver/");
            }, 1000);
        }
    });

    $("#statuspicker1").change(function (event) {
        var that = this;
        delay(function(){
            Saver({id: statusId, fontcolor: $(that).val()},"/statuses/statussaver/");
        }, 1000);
    });

    $("#statuspicker2").change(function (event) {
        var that = this;
        delay(function(){
            Saver({id: statusId, backgroundcolor: $(that).val()},"/statuses/statussaver/");
        }, 1000);
    });
}

window.inputRoleInUserEvent = function() {
    $(document).
    off("change",'[data-roleusers2]').
    on("change",'[data-roleusers2]', function (event) {
        grit(lang('Saving...Wait'), 'growl-warning');

        $.ajax({
            url: '/users/rolescontrol',
            type: "POST",
            data: {
                userid: $('#id-user').val(),
                checked: $(this).is(':checked'),
                roleid: $(this).attr('data-roleusers2')},
            cache: false,
        }).done(function (a, b, c) {
            try {
                var obj = JSON && JSON.parse(a) || $.parseJSON(a);
                if (obj.error === "") {
                    grit(lang('Saved'), 'growl-success');
                } else grit(obj.error, 'growl-danger');
            }
            catch (error) {
                logger.exception(error, a);
                grit(lang('Save failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
            }
        }).fail(function (a, b, c) {
            grit(lang('Not Saved'), 'growl-danger');
        }).always(function (a, b, c) {});

    });
}

window.inputRoleSaverEvents = function() {

    /** клики на типе уведомлений обрабатываются своими событиями с вызовом урл roles/savenotification */
    /** смена стартового экрана также обрабатывается своим событием с вызовом урл roles/changestartscreen */

    /** перехват измененений в модальной форме редактирования роли */
    $(document).
    off('change', '[data-roleinfo-fields]').
    on('change', '[data-roleinfo-fields]', function (event) {
        console.log('step4');
        console.log('ad');
        var roleId = $('#id-role').val();
        var that = this;
        var name = $('#rolename').val();
        RoleSaver({id: roleId, name: name}, "/roles/rolesaver/");
    });

    /** обработчик клика на чекбокс связанного юзера */
    $(document).
    off("change",'[data-roleusers]').
    on("change",'[data-roleusers]', function (event) {
        console.log('step3');
        var roleId = $('#id-role').val();
        var that = this;
        RoleSaver({roleid: roleId, checked: $(that).is(':checked'), userid: $(that).attr('data-roleusers')},"/roles/createupdate/");
    });

    /** обработчик клика на чекбокс с экшеннеймом */
    $(document).
    off("change",'[data-actionsecurity]').
    on("change",'[data-actionsecurity]', function (event) {
        console.log('step2');
        var roleId = $('#id-role').val();
        var that = this;
        RoleSaver({roleid: roleId, appid: $(that).data('appid'), checked: $(that).is(':checked'), actionid: $(that).attr('data-actionsecurity')},"/roles/createupdate/");
    });


    /** обработчик клика на чекбокс object, constr, backend */
    $(document).
    off("change",'[data-security="security"]').
    on("change",'[data-security="security"]',function (event) {
        console.log('step1');
        var roleId = $('#id-role').val();
        var that = this;
        RoleSaver(
            {
                roleid: roleId,
                appid: $(that).data('appid'),
                checked: $(that).is(':checked'),
                name: $(that).attr('name'),
                actionname: 1
            },
            "/roles/createupdate/"
        );
    });

}

window.inputUserSaverEvents = function() {
// OBSOLETE FUNCTION , REMOVE LATER

    var userId = $('#id-user').val();
    var delay = (function(){
        var timer = 1;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    });

    //$(document).on("change",".actionsecurity",function (event) {
    //    var userId = $('#id-user').val();
    //    that = this;
    //    Saver({userid: userId, appid: $(that).data('appid'), checked: $(that).is(':checked'), actionname: $(that).data('actionname')},"/actions/createupdate/");
    //});

    $(document).on("change",".security",function (event) {
        var asRoles = $('#createrole').html();
        if (asRoles === undefined) {
            var userId = $('#id-user').val();
            var that = this;
            Saver({userid: userId, appid: $(that).data('appid'), checked: $(that).is(':checked'), name: $(that).attr('name')},"/security/createupdate/");
        }
    });

    $("#superuser").on("change", function(event) {
        var userId = $('#id-user').val();
        var that = this;

        var self            = $(this),
            $securityTable  = $('.security-table');

        if (self.is(':checked')) {
            $securityTable.css('display', 'none');
        } else {
            $securityTable.css('display', 'table');
        }

        Saver({id: userId, superuser: $(that).is(':checked')}, "/users/usersaver/");
    });

    $(document).on('change','#timezone',(function(event) {
        var that=this;
        var userId = $('#id-user').val();

        var timezone=$('#timezone').val();
        Saver({id: userId, timezone:timezone},"/users/usersaver/");
    }));
}

window.RoleSaver = function(formdata,url) {
    grit(lang('Saving...Wait'), 'growl-warning');
    $.ajax({
        url: url,
        type: "POST",
        data: formdata,
        cache: false,
    }).done(function (a, b, c) {
        try {
            var obj = JSON && JSON.parse(a) || $.parseJSON(a);

            if (obj.error === "") {
                /** синхронизируем состояние чекбокса на бекенд */
                let t = $('#backend' + formdata.appid);
                if (obj.crud === 'roleaction') {
                    if (obj.backend === '1') {
                        t.attr('checked', true);
                    } else if (obj.backend === '0') {
                        t.attr('checked', false);
                    }
                }

                /** синхронизируем пачкой состояния чекбоксов экшенов при вкл/выкл чекбокса бекенда */
                if (obj.crud === 'access') {
                    if (obj.backend === '1') {
                        t.parents('td').siblings('.actions-collumn').find('.ckbox').each(function (e) {
                            $(this).find('input').attr('checked', true);
                        });
                    } else if (obj.backend === '0') {
                        t.parents('td').siblings('.actions-collumn').find('.ckbox').each(function (e) {
                            $(this).find('input').attr('checked', false);
                        });
                    }
                }

                /** показать или скрыть панель настройки уведомлений для приложения */
                if (obj.backend === '1') {
                    $('#Unotify' + formdata.appid).css('display', 'block');
                } else if (obj.backend === '0') {
                    $('#Unotify' + formdata.appid).css('display', 'none');
                }

                $.fn.yiiGridView.update("my-model-grid");
                grit(lang('Saved'), 'growl-success');
            } else grit(obj.error, 'growl-danger');
        }
        catch (error) {
            logger.exception(error, a);
            grit(lang('Save failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
        }
    }).fail(function (a, b, c) {
        grit(lang('Not Saved'), 'growl-danger');
    }).always(function (a, b, c) {});
}

window.Saver = function(formdata,url) {
    grit(lang('Saving...Wait'), 'growl-warning');
    $.ajax({
            url: url,
            type: "POST",
            data: formdata,
            // dataType : 'json',
            cache: false,
        }).done(function (a, b, c) {
            try {
                var obj = JSON && JSON.parse(a) || $.parseJSON(a);
                let t = $('#backend' + formdata.appid);

                if (obj.error === "") {
                    if (formdata.name === "Backend") {
                        if ( !t.is(':checked') ) {
                            t.parents('td').siblings('.actions-collumn').find('.ckbox').each(function (e) {
                                $(this).find('input').attr('checked', false);
                            });
                        } else {
                            t.parents('td').siblings('.actions-collumn').find('.ckbox').each(function (e) {
                                $(this).find('input').attr('checked', true);
                            });
                        }
                    }
                    if (formdata.actionname) {
                        t = $('input[id*="action' + formdata.appid + '"]');

                        if ( !t.is(':checked') ) {
                            t.parents('td').siblings('.backend-collumn').find('input').attr('checked', false);
                        } else {
                            t.parents('td').siblings('.backend-collumn').find('input').attr('checked', true);
                        }
                    }
                    if (obj.backend === '1') {
                        $('#Unotify' + formdata.appid).css('display', 'block');
                    } else {
                        $('#Unotify' + formdata.appid).css('display', 'none');
                    }
                    $.fn.yiiGridView.update("my-model-grid");
                    grit(lang('Saved'), 'growl-success');
                } else grit(obj.error, 'growl-danger');
            }
            catch (error) {
                logger.exception(error, a);
                grit(lang('Save failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
            }
        }).fail(function (a, b, c) {
            grit(lang('Not Saved'), 'growl-danger');
        }).always(function (a, b, c) {});
}

window.stepsSaver = function(formdata) {
    var panelIp = $('.panel-ip'),
        panelMaps = $('.panel-maps');
    $.ajax(
        {
            url: "/applications/stepssaver/",
            type: "POST",
            data: formdata,
            cache: false
        }).done(function (a, b, c) {
            try {
                var obj = JSON && JSON.parse(a) || $.parseJSON(a);



                if (obj.error === "") {
                    if ((formdata.autcreateobjects !== undefined && formdata.autcreateobjects === 'false')) {
                        //$('#selfreg-url').attr('style', 'display:none;');
                    } else  $('#selfreg-url').attr('style', '');

                    if (obj.notifyhtml !== "" && obj.replaceid !== "") {
                        $('.block-notofications').html(obj.notifyhtml);
                    }
                    if (obj.iphtml !== "" && obj.replaceid !== "") {
                        if($('.panel-ip').length > 0) {
                            panelIp.replaceWith(obj.iphtml);
                        } else {
                            $('#' + obj.replaceid).prepend(obj.iphtml);
                        }
                    }
                    if (obj.geohtml !== "" && obj.replaceid !== "") {
                        if ($('.panel-maps').length > 0) {
                            panelMaps.replaceWith(obj.geohtml);
                        } else {
                            $('#' + obj.replaceid).append(obj.geohtml);
                        }
                        geolocation.start();
                    }

                    //
                    if (formdata.useAppAnyoneNoReg !== undefined && formdata.useAppAnyoneNoReg) {
                      //если нажали Anyone without registration на шаге 7
                        if (formdata.autcreateobjects !== undefined) {
                            if(formdata.autcreateobjects){//и оно точно точно выбрано
                                $('#request-verify-firsttime').prop('checked', true);//убираем метод аутентификации
                                $('#request-verify-everytime').prop('checked', false);//убираем метод аутентификации
                                $('#verify-users-email').prop('checked', false);//убираем метод аутентификации
                                $('#verify-users-sms').prop('checked', false);//убираем метод аутентификации
                                $('#additional-protect-pin').prop('checked', false);//снимаем галочку PIN
                                $('#additional-protect-pin-box').addClass('panel-disabled');//делаем панель PIN серой
                                $('#additional-protect-pin').attr('disabled', true);//делаем панель PIN недоступной
                            }
                        }
                    }
                    //
                    if (
                      ((formdata.verifyUsersEmail !== undefined && formdata.verifyUsersEmail)
                        ||
                        (formdata.verifyUsersSms !== undefined && formdata.verifyUsersSms))
                      && $('#use-app-only-users')
                        .is(':checked')
                    ) {

                        let text = '';
                        if ($('#additional-protect-pin')
                          .is(':checked')
                        ) {//выбран пункт PIN

                            if ($('#verify-users-email')
                              .is(':checked')) {//выбрано подтверждение по email+pin
                                text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Email / PIN set BEFORE they start using the app. Otherwise these objects/users would not enter.');
                            }
                            ;

                            if ($('#verify-users-sms')
                              .is(':checked')) {//выбрано подтверждение по sms+pin
                                text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Phone / PIN set BEFORE they start using the app. Otherwise these objects/users would not enter.');
                            }
                            ;

                        } else {

                            if ($('#verify-users-email')
                              .is(':checked')) {//выбрано подтверждение по email
                                text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Email set BEFORE they start using the app. Otherwise these objects/users would not enter.');
                            }
                            ;

                            if ($('#verify-users-sms')
                              .is(':checked')) {//выбрано подтверждение по sms
                                text = lang('Attention! For this set of security options, please make sure that all your objects/users HAVE Phone set BEFORE they start using the app. Otherwise these objects/users would not enter.');
                            }
                            ;

                        }
                        ;

                        let titlePopUp = lang('Attention!');

                        function show_popupMail() {
                            const Model = Backbone.Model.extend();
                            var warningManualView = new WarningManualView({
                                parentView: this,
                                isFooter: true,
                                model: new Model({
                                    title: titlePopUp,
                                    text: '<p> <h5> ' + text + ' </h5> </p>',
                                    footer: [
                                        { text: lang('Close'), className: 'btn-default', attr: 'data-dismiss="modal"' },
                                    ],
                                }),
                            });

                            warningManualView.renderInner();
                            $('body')
                              .append(warningManualView.$el);
                            $(warningManualView.el)
                              .modal('show');

                        };
                        setTimeout(show_popupMail, 500);

                    }
                    if (
                      (formdata.useAppAnyoneReg !== undefined && formdata.useAppAnyoneReg)
                      ||
                      (formdata.useAppOnlyUsers !== undefined && formdata.useAppOnlyUsers)
                    ) {
                        //если нажали Anyone after registration and authentication или
                    //Only those added by me на шаге 7
                        if (formdata.autcreateobjects !== undefined) {
                            // и занчение точно установлено
                            if(formdata.autcreateobjects){
                                if (
                                  !$('#verify-users-sms').is(":checked")
                                  &&
                                  !$('#verify-users-email').is(":checked")
                                )
                                {//если метод аутентификации не выбран
                                    $('#verify-users-sms').prop('checked', true);//выбираем метода аутентификации - sms(т.к. пустым его оставлять нельзя)
                                }

                                //$('#verify-users-sms').change();

                                if (formdata.useAppAnyoneReg || formdata.useAppOnlyUsers) {
                                    if ($('#verify-users-email')
                                      .is(':checked')) {
                                        $('#verify-users-email')
                                          .change();
                                    }
                                    if ($('#verify-users-sms')
                                      .is(':checked')) {
                                        $('#verify-users-sms')
                                          .change();
                                    }
                                }


                                //$('#additional-protect-pin').prop('checked', true);//устанавливаем галочку PIN
                                $('#additional-protect-pin-box').removeClass('panel-disabled');//делаем панель PIN норального вида
                                $('#additional-protect-pin').attr('disabled', false);//делаем панель PIN доступной
                                //$('#additional-protect-pin').change();//запускаем провереку на вывод поапапа с предупреждением
                            }
                        }
                    }

                    // включает/выключает на степе 7 пункты qr & url
                    if (formdata.autcreateobjects !== undefined && formdata.autcreateobjects == 'true') {
                        $('#method-qr, #method-url').attr('disabled', false);
                    } else if (formdata.autcreateobjects !== undefined && formdata.autcreateobjects == 'false') {
                        if ($('#method-qr').is(':checked') || $('#method-url').is(':checked')) {
                            $('#method-email').attr('checked', true).click();
                        }
                        $('#method-qr, #method-url').attr('disabled', true);
                    }
                    grit(lang('Saved'), 'growl-success');
                } else grit(lang('Save failed'), 'growl-danger');
            }
            catch (error) {
                logger.exception(error, a);
                grit(lang('Save failed'), 'growl-danger', lang("Invalid format JSON response from the server"));
            }
        }).fail(function (a, b, c) {
            grit(lang('Not Saved'), 'growl-danger');
        }).always(function (a, b, c) {
        });
}

window.inputSaverEvents = function(){
    var appId = $('#id-app').val();

    var delay = (function(){
        var timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();

    // step 2
    $(document).on('change', '#standalone-ios', function(e) {
        stepsSaver({
            id: appId,
            standalone_ios: $(this).is(':checked') ? 1 : 0
        });
    });

    $(document).on('change', '#theme_color', function(e) {
        stepsSaver({
            id: appId,
            theme_color: $(this).val()
        });
    });

    $(document).on('change', '#background_color', function(e) {
        stepsSaver({
            id: appId,
            background_color: $(this).val()
        });
    });

    $("body").delegate('#name-app', 'input', function (event) {
        if (event.type == 'input'){
            var that = this;
            $('#title-appName').text($('#name-app').val());
            delay(function(){
                if ($('#desktop-name').val() === '' || $('#desktop-name').val() === $('#name-app').attr('data-oldname')) {
                    $('#desktop-name').val($('#name-app').val());
                    $('#preview-icon-title').text($('#desktop-name').val());
                    emulInputEvent('input', $('#desktop-name'));
                }
                $('#name-app').attr('data-oldname', $('#name-app').val());
                stepsSaver({id: appId, nameapp: $(that).val()});
            }, 1000);
        }
    });

    function emulInputEvent(eventType, that) {
        if (eventType == 'input'){
            $('#preview-icon-title').text($('#desktop-name').val());
            delay(function(){
                stepsSaver({id: appId, desktopname: $(that).val()});
            }, 1000);
        }
    }

    $("body").delegate('#desktop-name', 'input', function (event) {
        emulInputEvent(event.type, this);
    });

    $("body").delegate('#name-shdescription', 'input', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, nameshdescription: $(that).val()});
            }, 1000);
        }
    });
    $(document).on('input', '#popupTitle', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, popupTitle: $(that).val()});
            }, 1000);
        }
    });
    $(document).on('input', '#popupText', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, popupText: $(that).val()});
            }, 1000);
        }
    });
    $(document).on('input', '#popupSubscribe', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, popupSubscribe: $(that).val()});
            }, 1000);
        }
    });
    $(document).on('input', '#popupUnSubscribe', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, popupUnSubscribe: $(that).val()});
            }, 1000);
        }
    });
    $(document).on('input', '#popupNothingText', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, popupNothingText: $(that).val()});
            }, 1000);
        }
    });
    $(document).on('input', '#popupCancelText', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, popupCancelText: $(that).val()});
            }, 1000);
        }
    });

    $(document).on('input', '#orgName', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, orgName: $(that).val()});
            }, 1000);
        }
    });

    $(document).on('input', '#orgEmail', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, orgEmail: $(that).val()});
            }, 1000);
        }
    });

    $(document).on('input', '#orgPhone', function (event) {
        if (event.type == 'input'){
            var that = this;
            delay(function(){
                stepsSaver({id: appId, orgPhone: $(that).val()});
            }, 1000);
        }
    });




    $(document.body).on('change', '#uploadassetlinks', function (e) {
        //e.preventDefault();

        var fileObject = $(this)[0].files[0],
            self = $(this);

        if (fileObject !== undefined) {
            var fd = new FormData();
            var fileExtension = fileObject.name.split('.').pop().toLowerCase();
            var fileSize = fileObject.size;
            fd.append("id", appId);
            fd.append("UploadedFiles[image]", fileObject);

            var checkExt = false;
            var checkSize = false;
            var postUrl = '/twa/uploadadassetlinks';

            if (fileExtension === "json") {
                checkExt = true;
                if (fileSize <= 10485760) {
                    checkSize = true;
                    postUrl = '/twa/uploadadassetlinks';
                }
            }
            if (checkExt) {
                if (checkSize) {
                    var that = $(this);
                    that.parents('.btn-upload-crt').removeClass('btn-danger error-load success-load').addClass('load btn-success');
                    $.ajax({
                        url: postUrl,
                        type: 'POST',
                        cache: false,
                        data: fd,
                        datatype: 'json',
                        processData: false,
                        contentType: false,
                        success: function (data) {
                            var obj = {};

                            try {
                                obj = JSON && JSON.parse(data) || $.parseJSON(data);
                            } catch (error) {
                                logger.exception(error, data);
                            }

                            if (obj.error === "") {
                                that.parents('.btn-upload-crt').removeClass('load btn-danger error-load').addClass('btn-success success-load');
                                $('#preview-icon img').attr('src', (obj.urlicon + '?' + Math.floor(Math.random() * (9999 - 0)) + 0));
                                $.gritter.add({
                                    title: lang('Saved'),
                                    text: '',
                                    class_name: 'growl-success',
                                    sticky: false,
                                    time: '1000'
                                });
                            } else {
                                that.parents('.btn-upload-crt').removeClass('load btn-success').addClass('btn-danger error-load');
                                $.gritter.add({
                                    title: obj.error,//lang('Save failed'),
                                    text: obj.error,
                                    class_name: 'growl-danger',
                                    sticky: false,
                                    time: '1000'
                                });
                            }
                            self.val('');
                        },
                        error: function (error) {
                            $.gritter.add({
                                title: lang('Save failed'),
                                text: error,
                                class_name: 'growl-danger',
                                sticky: false,
                                time: '1000'
                            });
                            that.parents('.btn-upload-crt').removeClass('load btn-danger error-load').addClass('btn-success success-load')
                            $('#preview-icon img').attr('src', (obj.urlicon + '?' + Math.floor(Math.random() * (9999 - 0)) + 0));
                        }
                    });
                } else {
                    alert("Sorry, this file size is larger than allowed!");
                }
            } else {
                alert("Sorry, this file type not supported!");
            }
        }

    });



    $(document.body).on('change', '#g_iconapp', function (e) {
        //e.preventDefault();

        var fileObject = $(this)[0].files[0],
            self = $(this);

        if (fileObject !== undefined) {
            var fd = new FormData();
            var fileExtension = fileObject.name.split('.').pop().toLowerCase();
            var fileSize = fileObject.size;
            fd.append("id", appId);
            fd.append("UploadedFiles[image]", fileObject);

            var checkExt = false;
            var checkSize = false;
            var postUrl = '/applications/stepssaver';

            if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "gif" || fileExtension === "png") {
                checkExt = true;
                if (fileSize <= 10485760) {
                    checkSize = true;
                    postUrl = '/applications/stepssaver';
                }
            }
            if (checkExt) {
                if (checkSize) {
                    var that = $(this);
                    that.parents('.btn-upload-crt').removeClass('btn-danger error-load success-load').addClass('load btn-success');
                    $.ajax({
                        url: postUrl,
                        type: 'POST',
                        cache: false,
                        data: fd,
                        datatype: 'json',
                        processData: false,
                        contentType: false,
                        success: function (data) {
                            var obj = {};

                            try {
                                obj = JSON && JSON.parse(data) || $.parseJSON(data);
                            } catch (error) {
                                logger.exception(error, data);
                            }

                            if (obj.error === "") {
                                that.parents('.btn-upload-crt').removeClass('load btn-danger error-load').addClass('btn-success success-load');
                                $('#preview-icon img').attr('src', (obj.urlicon + '?' + Math.floor(Math.random() * (9999 - 0)) + 0));
                                $.gritter.add({
                                    title: lang('Saved'),
                                    text: '',
                                    class_name: 'growl-success',
                                    sticky: false,
                                    time: '1000'
                                });
                            } else {
                                that.parents('.btn-upload-crt').removeClass('load btn-success').addClass('btn-danger error-load');
                                $.gritter.add({
                                    title: obj.error,//lang('Save failed'),
                                    text: obj.error,
                                    class_name: 'growl-danger',
                                    sticky: false,
                                    time: '1000'
                                });
                            }
                            self.val('');
                        },
                        error: function (error) {
                            $.gritter.add({
                                title: lang('Save failed'),
                                text: error,
                                class_name: 'growl-danger',
                                sticky: false,
                                time: '1000'
                            });
                            that.parents('.btn-upload-crt').removeClass('load btn-danger error-load').addClass('btn-success success-load')
                            $('#preview-icon img').attr('src', (obj.urlicon + '?' + Math.floor(Math.random() * (9999 - 0)) + 0));
                        }
                    });
                } else {
                    alert("Sorry, this file size is larger than allowed!");
                }
            } else {
                alert("Sorry, this file type not supported!");
            }
        }

    });

    $(document.body).on('click', '.b-publish-item--edit .e-close', function (e) {
        var that = $(this),
            descId = that.parents('.b-publish-item--edit').find('#desc-id').val();
        $.ajax({
            url: "/publishapp/desceditclose/",
            type: "POST",
            data: {descId: descId},
        })
            .done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === ''){
                    var tr = $(that).parents('.b-publish-item--edit');
                    tr.replaceWith(obj.html);
                    $('.b-publish-list').removeClass('b-publish-list--edit');
                }
            })
            .fail(function (a, b, c) { })
            .always(function (a, b, c) { });
    });

    $(document.body).on('click', '.b-publish-item .edit', function (e) {
        var that = $(this),
            descId = that.data('descedit');
        $.ajax({
            url: "/publishapp/descedit/",
            type: "POST",
            data: {descId: descId},
        })
            .done(function (a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === ''){
                    var tr = $(that).parents('.b-publish-item');
                    tr.replaceWith(obj.html);
                    $('.b-publish-list').addClass('b-publish-list--edit');
                    $('.b-publish-item--edit').find('textarea').select();

                }
            })
            .fail(function (a, b, c) { })
            .always(function (a, b, c) { });
    });

    $(document.body).on('click', '.scr_temp_edit', function (e) {
        //e.preventDefault();
        var self = $(this),
            panelEdit = self.parents('.b-publish-item__edit'),
            item = self.parents('.b-publish-item'),
            fileObject = panelEdit.find('.screenstemplatefile')[0].files[0],
            appId = $('#app-id').val(),
            descId = $('#desc-id').val(),
            screenCat = panelEdit.find('.choose-cat').val(),
            screenDesc = $('.g_desc').val(),
            fd = new FormData();

        fd.append("id", appId);
        fd.append("descId", descId);
        fd.append("screenCat", screenCat);
        fd.append("screenDesc", screenDesc);

        if (fileObject !== undefined) {
            var fileExtension = fileObject.name.split('.').pop().toLowerCase();
            var fileSize = fileObject.size;
            fd.append("UploadedFiles[image]", fileObject);

            var checkExt = false;
            var checkSize = false;

            if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "gif" || fileExtension === "png") {
                if (fileSize >= 10485760) {
                    alert( lang("Sorry, this file size is larger than allowed!"));
                    return false;
                }
            } else {
                alert(lang("Sorry, this file type not supported!"));
                return false;
            }
        }

        $.ajax({
            url: '/applications/screenstemplateedit',
            type: 'POST',
            cache: false,
            data: fd,
            datatype: 'json',
            processData: false,
            contentType: false,
            beforeSend: function () {
                self.addClass('load');
            },
            success: function (data) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(data) || $.parseJSON(data);
                } catch (error) {
                    logger.exception(error, data);
                }

                if (obj.error === "") {
                    self.removeClass('load');
                    item.replaceWith(obj.html);
                    $('.b-publish-list').removeClass('b-publish-list--edit');
                } else {
                    self.removeClass('load btn-primary').addClass('btn-danger');
                }
            },
            error: function () {
                self.removeClass('load btn-primary').addClass('btn-danger');
            }
        });


    });

    $(document.body).on('click', '#g_screenstemplate', function (e) {
        //e.preventDefault();

        var fileObject = $('#g_screenstemplatefile')[0].files[0];
        var appId = $('#app-id').val();
        var screenCat = $('#choose-cat').val();
        var screenDesc = $('#g_desc').val();
        if (fileObject !== undefined) {
            var fd = new FormData();
            var fileExtension = fileObject.name.split('.').pop().toLowerCase();
            var fileSize = fileObject.size;
            var fileType = fileObject.type;
            var fileWidth = fileObject.width;
            var fileHeigth = fileObject.height;
            var ValidImageTypes = ["image/gif", "image/jpg", "image/jpeg", "image/png"];//get valid mime headers
            var ValidExtension = ["jpg", "jpeg", "gif", "png"];//get valid extensions
            fd.append("id", appId);
            fd.append("screenCat", screenCat);
            fd.append("screenDesc", screenDesc);
            fd.append("UploadedFiles[image]", fileObject);

            var checkExt = false;
            var checkSize = false;

            var checkProportional = false;
            var checkWidth = false;
            var checkHeigth = false;

            var checkSizeImage = false;

            var postUrl = '/applications/screenstemplate';


            if ($.inArray(fileType, ValidImageTypes) < 0 || $.inArray(fileExtension, ValidExtension) < 0) {
                alert(lang("Sorry, this file type not supported! Allowed only: \"jpg\", \"jpeg\", \"gif\", \"png\""));
            } else {
                checkExt = true;
            }

            if ( fileWidth <= 2000 && fileWidth >= 320) {
                checkWidth = true;
            }

            if ( fileHeigth <= 2000 && fileHeigth >= 320) {
                checkHeigth = true;
            }

            if ((Math.max(fileHeigth,fileWidth)/Math.min(fileHeigth,fileWidth))<=2){
                checkProportional = true;
            }

            if (checkHeigth && checkWidth && checkProportional) {
                checkSizeImage = true;
            } else {
                alert(lang("Sorry, this image size is not allowed. " +
                    "The side of image have to be in diapason from 320 to 2000 pixels. " +
                    "And relation between both of side does not exceed 2 to 1"));
            }

            if (fileSize <= 1024000 && fileSize >= 10240) {
                //10,48576 Megabytes... it's too much for allowed images, guess it?
                //page, docs and images with size lager or equal 10 MB will't includ in index base of any searsh system
                //set maximal filesize 1 MB
                checkSize = true;
                postUrl = '/applications/screenstemplate';
            } else {
                alert(lang("Sorry, this file size is larger than allowed! Image size allowed between 10Kb and 1 MB"));
            }


            if (checkExt) {
                if (checkSize && checkSizeImage) {
                    var that = $(this),
                        pList = $('#desc_template');
                    $.ajax({
                        url: postUrl,
                        type: 'POST',
                        cache: false,
                        data: fd,
                        datatype: 'json',
                        processData: false,
                        contentType: false,
                        beforeSend: function () {
                            that.addClass('load');
                        },
                        success: function (data) {
                            var obj = {};

                            try {
                                obj = JSON && JSON.parse(data) || $.parseJSON(data);
                            } catch (error) {
                                logger.exception(error, data);
                            }

                            if (obj.error === "") {
                                that.removeClass('load');
                                pList.prepend(obj.html).scrollTop(0);
                                $('#g_desc').val('');
                                $('#g_screenstemplatefile').val('');
                            } else {
                                that.removeClass('load btn-primary').addClass('btn-danger');
                            }
                        },
                        error: function () {
                            that.removeClass('load btn-primary').addClass('btn-danger');
                        }
                    });
                } else {
                    alert(lang("Sorry, this file size is larger than allowed!"));
                }
            } else {
                alert(lang("Sorry, this file type not supported!"));
            }
        }

    });


    // step 4

    $(document.body).delegate('#notify1', 'change', function () {
        stepsSaver({id: appId, notify1: $(this).val()});
    });

    $(document.body).delegate('#notify2', 'change', function () {
        stepsSaver({id: appId, notify2: $(this).val()});
    });

    $(document.body).delegate('#notify3', 'change', function () {
        stepsSaver({id: appId, notify3: $(this).val()});
    });

    $(document.body).delegate('#onepart-sms', 'change', function () {
        stepsSaver({id: appId, onepartSms: $(this).val()});
    });


    $(document.body).delegate('#pushtimeout', 'change', function () {
        stepsSaver({id: appId, pushtimeout: $(this).val()});
    });

    $(document.body).delegate('#emailtimeout', 'change', function () {
        stepsSaver({id: appId, emailtimeout: $(this).val()});
    });

    $(document.body).delegate('#smstimeout', 'change', function () {
        stepsSaver({id: appId, smstimeout: $(this).val()});
    });

}


window.repeat = function(s, count) {
    return new Array(count + 1).join(s);
}

window.formatJson = function(json) {
        var i           = 0,
            il          = 0,
            tab         = "    ",
            newJson     = "",
            indentLevel = 0,
            inString    = false,
            currentChar = null;

        for (i = 0, il = json.length; i < il; i += 1) {
            currentChar = json.charAt(i);

            switch (currentChar) {
                case '{':
                case '[':
                    if (!inString) {
                        newJson += currentChar + "\n" + repeat(tab, indentLevel + 1);
                        indentLevel += 1;
                    } else {
                        newJson += currentChar;
                    }
                    break;
                case '}':
                case ']':
                    if (!inString) {
                        indentLevel -= 1;
                        newJson += "\n" + repeat(tab, indentLevel) + currentChar;
                    } else {
                        newJson += currentChar;
                    }
                    break;
                case ',':
                    if (!inString) {
                        newJson += ",\n" + repeat(tab, indentLevel);
                    } else {
                        newJson += currentChar;
                    }
                    break;
                case ':':
                    if (!inString) {
                        newJson += ": ";
                    } else {
                        newJson += currentChar;
                    }
                    break;
                case ' ':
                case "\n":
                case "\t":
                    if (inString) {
                        newJson += currentChar;
                    }
                    break;
                case '"':
                    if (i > 0 && json.charAt(i - 1) !== '\\') {
                        inString = !inString;
                    }
                    newJson += currentChar;
                    break;
                default:
                    newJson += currentChar;
                    break;
            }
        }

        return newJson;
    }

window.grit = function(title, classname, text) {
    $.gritter.add({
        title: title,
        text: text || "",
        class_name: classname,
        sticky: false,
        time: '1000'
    });
}
window.gritlong = function(title, classname, text, time9 = 9000) {
    $.gritter.add({
        title: title,
        text: text || "",
        class_name: classname,
        sticky: false,
        time: time9
    });
}

/*
    =================================================
    сюда не писать говно код!!!
    писать внутри
    define([], function() { return { сюда вот, так что скролльте вверх } });
    =================================================
 */
