var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 384 384",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M64 341.333C64 364.8 83.2 384 106.667 384h170.667C300.8 384 320 364.8 320 341.333v-256H64v256zm52.587-151.893l30.187-30.187L192 204.48l45.227-45.227 30.187 30.187-45.227 45.227 45.227 45.227-30.187 30.187L192 264.853l-45.227 45.227-30.187-30.187 45.227-45.227-45.226-45.226zM266.667 21.333L245.333 0H138.667l-21.334 21.333H42.667V64h298.666V21.333z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }