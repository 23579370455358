<i18n src="general/lang/WidgetEditorPropertyPane.json"></i18n>

<template>
  <div :class="b()">
    <div :class="b('header')">
      <template v-if="!UIControls">
        <div :class="b('title')">
          {{ $t('WidgetEditorPropertyPane.title') }}
        </div>
      </template>

      <template v-else>
        <button
          :class="b('button-close', 'btn btn-default btn-xs')"
          :title="$t('WidgetEditorPropertyPane.buttonCloseTitle')"
          @click.prevent="clearPropertyPane"
        >
          <IconClose width="12" />
        </button>

        <div :class="b('title')">
          {{ $t('WidgetEditorPropertyPane.titleSelect') }}
        </div>
      </template>
    </div>

    <div :class="b('body', { isEmpty })">
      <template v-for="(propertyComponent, propertyName) in UIControls">
        <component
          v-if="propertyComponent"
          :key="propertyComponent.uuid"
          :is="propertyComponent.componentName"
          v-model="propertyComponent.properties[propertyComponent.vModelPropertyName]"
          :class="b('item')"
          v-bind="propertyComponent.propertiesBind"
        ></component>
      </template>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { uuid } from 'vue-uuid';
  import { createNamespacedHelpers } from 'vuex';
  import controls from 'general/components/controls';
  import IconClose from 'general/components/icons/IconClose';

  const { mapState, mapActions } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-editor-property-pane',

    components: {
      ...controls,
      IconClose,
    },

    data: () => ({
      currentComponent: null,
      UIControls: null
    }),

    computed: {
      ...mapState({
        schema: state => state.propertyPane.schema,
        component: state => state.propertyPane.component,
        lastComponentUuid: state => state.propertyPane.lastComponentUuid,
      }),

      isEmpty() {
        return !this.UIControls;
      },
    },

    watch: {
      component: {
        handler(data) {
          const widgetComponentModel = this.getWidgetComponentModel(data);
          this.currentComponent = _.cloneDeep(data);
          this.UIControls = this.getPropertiesModel(data, widgetComponentModel) || null;
        },
        immediate: true,
        deep: true,
      },

      UIControls: {
        handler(data) {
          this.handlerPropertiesModelWatched(data);
        },
        immediate: true,
        deep: true,
      },

      currentComponent: {
        handler(data, oldData) {
          this.handlerCurrentComponentWatched(data, oldData);
        },
        deep: true,
      },
    },

    methods: {
      ...mapActions({
        updateComponent: 'editor/updateComponent',
        resetSelectedComponentUuid: 'editor/resetSelectedComponentUuid',
        resetComponent: 'propertyPane/resetComponent',
      }),

      handlerPropertiesModelWatched(UIControls) {
        if (!UIControls || !this.currentComponent) {
          return;
        }
        // обновление соответствующего свойства у компонента, откинув тех.данные
        Object.entries(this.currentComponent.properties)
          .forEach((element) => {
            const key = element[0];
            const propertyModel = UIControls[key];

            if (propertyModel) {
              const data = propertyModel.properties[propertyModel.vModelPropertyName];
              if (typeof data !== 'undefined') {
                this.currentComponent.properties[key] = data;
              }
            }
          });
      },

      handlerCurrentComponentWatched(currentComponent, oldCurrentComponent) {
        // если это не первое обновление свойства и другой компонент
        if (currentComponent && oldCurrentComponent) {
          // || currentComponent.uuid !== this.lastComponentUuid
          this.updateComponent(currentComponent);
        }
      },

      /**
       * Получить список UI компонентов выбранного компонента виджета
       *
       * @param {Object} component
       * @returns {Object}
       */
      getWidgetComponentModel(component) {
        if (
          component
          && this.schema
          && this.schema[component.name]
          && this.schema[component.name].properties
        ) {
          return _.cloneDeep(this.schema[component.name]);
        }
        return null;
      },

      /**
       * @param {Object} component
       * @param {Object} model
       * @returns {Object}
       */
      getPropertiesModel(component, model) {
        if (!component || !model || !model.properties) {
          return null;
        }
        const { properties } = model;

        // перебор полей группы "properties"
        Object.entries(properties)
          .forEach((element) => {
            const fieldKey = element[0];
            const field = element[1];
            const componentProperty = component.properties[fieldKey];

            field.uuid = uuid.v1();

            // заполнение реактивного значения поля
            if (componentProperty) {
              field.properties[field.vModelPropertyName] = componentProperty;
            }
            // создание копии свойств с исключением реактивного свойства
            field.propertiesBind = _.cloneDeep(_.omit(field.properties, field.vModelPropertyName));
          });

        return properties;
      },

      /**
       * Очистка панели свойств
       */
      clearPropertyPane() {
        this.resetSelectedComponentUuid();
        this.resetComponent();
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor-property-pane
    display flex
    flex-direction column
    width 300px
    border 1px solid #eee

    &__header
      border-bottom 1px dashed #eee
      padding-y(5px)
      position relative

    &__button-close
      position absolute
      top 50%
      left 10px
      transform translateY(-50%)

    &__title
      typography(14px, 24px)
      text-align center

    &__body
      height 100%
      padding 20px 15px 10px

      &_is-empty
        background-color #f7f7f7

    &__item
      margin-bottom 15px

      +is-last()
        margin-bottom 0
</style>
