<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 126.39 128"
    :width="width"
    :height="height"
  >
    <g>
      <path
        fill="#00a6ff"
        d="M63.18 0C28.29 0 0 26.63 0 59.47c0 18.73 9.19 35.4 23.55 46.3V128l21-11.72a67.16 67.16 0 0 0 18.7 2.63c34.9 0 63.18-26.62 63.18-59.47S98.08 0 63.18 0z"
      ></path>
      <path
        fill="#fff"
        d="M72.8 60.08L56.57 43.17 21.96 79.54l30.93-17.36 16.64 17.36 34.35-36.37L72.8 60.08z"
      ></path>
    </g>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-facebook-messenger',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
