var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-select", {
    attrs: {
      outlined: "",
      dense: "",
      "options-dense": "",
      "hide-selected": "",
      "fill-input": "",
      "use-input": "",
      "input-debounce": "100",
      options: _vm.options,
      label: _vm.data.label,
    },
    on: { filter: _vm.onFilter },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function () {
          return [
            _vm.model !== null
              ? _c("q-icon", {
                  staticClass: "cursor-pointer",
                  attrs: { name: "clear" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.model = null
                    },
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "no-option",
        fn: function () {
          return [
            _c(
              "q-item",
              [
                _c("q-item-section", { staticClass: "text-grey" }, [
                  _vm._v("\n        No results\n      "),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }