var _ = require('underscore');
var Pace = require('refactor/lib/pace');

module.exports = {
    init: function() {
        this.bind();

        this.itemsData = [];
    },

    bind: function() {

        var that = this;
        $(document.body).on('click', '#print-qr-url', function(e) {

            e.preventDefault();

            var value       = $("#invitation").serialize(),
                title       = $('#title-invite').val(),
                typePrint   = $('#btn-invite').attr('data-invite-type'),
                nameSM      = true,
                countQR     = 1, startScreenId = "0";

            if ($("#startScreenDiv").attr('style') === "") {
                startScreenId = $("#startScreen").val();
            }

            if ($('#name-sm').is(':checked')) {
                nameSM = true;
            } else {
                nameSM = false;
            }

            if ($('#col-q').length > 0 && $('#col-q').val() > 0) {
                countQR = $('#col-q').val();
            }

            $.ajax({
                url: '/applications/printqrcode/',
                type: 'POST',
                data: {value: value, startScreenId: startScreenId, title: title, typeprint: typePrint, namesm: nameSM, countqr: countQR},
                beforeSend: function() {}
            })
                .done(function(a, b, c) {
                    var obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    if (obj.error !== "") {

                    } else {
                        $('.b-print').html(obj.html).show();
                        $(document.body).css('overflow', 'auto');
                        //отступы между qr-code
                        $('#space-around').spinner({
                            min: 0,
                            max: 20,
                            spin: function( event, ui ) {
                                $('.b-print-page-item').css('padding', ui.value);
                            },
                            change: function( event, ui ) {
                                var space = $('#space-around').val();
                                if (space != '' && space <= 20) {
                                    $('.b-print-page-item').css('padding', space);
                                } else if (space != '' && space > 20) {
                                    $('#space-around').val(20);
                                    $('.b-print-page-item').css('padding', '20px');
                                } else {
                                    $('#space-around').val(0);
                                    $('.b-print-page-item').css('padding', '0px');
                                }
                            }
                        });
                        that.collectData();
                    }
                })
                .fail(function(a, b, c) {

                })
                .always(function(a, b, c) {});

        });
        //обратно на 7 степ
        $(document.body).on('click', '.b-print-tools__back', function(e) {
            e.preventDefault();
            $(document.body).css('overflow', 'hidden');
            $('.b-print').html('').hide();
        });
        //печать
        $(document.body).on('click', '.b-print-tools__print', function(e) {
            e.preventDefault();
            window.print();
        });
        //количество колонокqr-code на странице при печати
        $(document.body).on('click', 'input[name="pcol"]', function(e) {
            $('.b-print-page').attr('class', 'b-print-page b-print-page--w' + $(this).val());
        });
        //включение/открючение разделительных линий
        $(document.body).on('click', 'input[name="pline"]', function(e) {
            $('.b-print-page').attr('data-dividing', $(this).val());
        });
        //изменение типа отображения qr-code списком/гридом
        $(document.body).on('click', 'input[name="playout"]', function(e) {
            var val = $(this).val();
            $('.b-print-page').attr('class', 'b-print-page b-print-page--' + val);
            if (val === 'list') {
                $('input[name="pcol"]').attr('disabled', true);
                $('.b-print-page').attr('class', 'b-print-page b-print-page--list');
            } else {
                $('input[name="pcol"]').attr('disabled', false);
                $('.b-print-page').attr('class', 'b-print-page b-print-page--w' + $('input[name="pcol"]:checked').val());
            }
        });

    },

    checkHeight: function() {
        var items = $('.b-print-page-item');

        //Math.floor(height * 0.264583);
    },

    collectData: function() {
        var that = this;
        _.each($('.b-print-page-item'), function(item, k) {
            var opt = {};
            opt.img = $(item).find('img').attr('src');
            opt.url = $(item).find('img').attr('alt');
            opt.title = $(item).find('.b-print-page-item__title').text();
            that.itemsData.push(opt);
        });

        console.log(that.itemsData);
    }

}
