import { ExtendDefault } from './defaultProperties'

export default ExtendDefault({
  name: 'mbst-video-player',
  aliasName: 'Video Player',
  properties: {
    backendname: 'videoplayer',
    source: '',
    poster: '',
    autoplay: false,
    bottomControls: false,
    loop: false,
    muted: false,
    noControls: false,
    disabledSeek: false,
    hideFullscreenBtn: false,
    hidePlayBtn: false,
    hideSettingsBtn: false,
    hideVolumeBtn: false,
    dark: false
  },
  actions: [],
  css: {
    all: [
      {
        selector: '.mbst-video-player',
        rules: {
          'background-color': 'black',
        },
      }
    ],
  },
})
