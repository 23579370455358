'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Views

// Colletions

// Templates


module.exports = Backbone.View.extend({
    initialize: function() {
        window.MOBSTEDAPP.sfTriggers = window.MOBSTEDAPP.sfTriggers || [];
        this.SFViews = [];
        this.render();
    },

    render: function() {
        var that = this,
            SFView = Backbone.View.extend(),
            SFModel = Backbone.Model.extend({
                defaults: {
                    id: null,
                    triggers: []
                }
            });

        _.each($('.b-smart-filters__item'), function(item) {
            var triggers = _.filter(MOBSTEDAPP.sfTriggers, function(t){ return t.SmartfilterId == $(item).attr('id'); });
            var m = new SFModel({
                id: parseInt( $(item).attr('id') ),
                triggers: triggers
            });

            that.SFViews.push(new SFView({
                el: item,
                model: m
            }));
        });

        var statusTrigger = '#CCC';

        _.each(that.SFViews, function(view) {
            var mTriggers = view.model.get('triggers');
            if (!_.isEmpty(mTriggers)) {
                if (_.find(mTriggers, function(t) { return t.Active })) {
                    statusTrigger = '#428BCA';
                }
                view.$('.b-smart-filters__inner').append('<i class="fa fa-bolt" style="color: '+ statusTrigger +';"></i>');
            }
        });
    }

});