<template>
  <div :class="b()">
    <div
      v-if="editorMode"
      :class="b('mask')"
    ></div>

    <WBotsInstructions0
      v-if="!editorMode && instruction0Shown"
      :editor-mode="editorMode"
    />

    <WBotsInstructions1
      v-if="!editorMode && instruction1Shown"
      :editor-mode="editorMode"
    />

    <WBotsInstructions2
      v-if="!editorMode && instruction2Shown"
      :editor-mode="editorMode"
    />

    <WBotsInstructions3
      v-if="!editorMode && instruction3Shown"
      :editor-mode="editorMode"
    />

    <WBotsCode
      v-if="!editorMode && isCodeShown"
      :editor-mode="editorMode"
    />

    <div :class="b('list')">
      <template v-for="bot in botList">
        <WBotsItem
          v-if="bot && !bot.hidden"
          :ref="`item${bot.code}`"
          :key="bot.code"
          :class="[b('item'), {'is-hide': (currentBot && bot.code !== currentBot.code && isCodeShown)}]"
          :editor-mode="editorMode"
          theme="default"
          :bot="bot"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import MakeEventBus from 'general/utils/MakeEventBus';
  import baseMixin from 'general/mixins/baseMixin';
  import botOptionsSchema from 'general/components/widgetComponents/schemas/botOptionsSchema';
  import WBotsCode from './WBotsCode';
  import WBotsInstructions0 from './WBotsInstructions0';
  import WBotsInstructions1 from './WBotsInstructions1';
  import WBotsInstructions2 from './WBotsInstructions2';
  import WBotsInstructions3 from './WBotsInstructions3';
  import WBotsItem from './WBotsItem';

  const eventBus = MakeEventBus('wBots');

  export default {
    name: 'w-bots',

    mixins: [
      baseMixin,
    ],

    components: {
      WBotsInstructions0,
      WBotsInstructions1,
      WBotsInstructions2,
      WBotsInstructions3,
      WBotsCode,
      WBotsItem,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      bots: {
        type: Array,
        required: true,
        default() {
          return ['auto'];
        },
      },

      widgetType: {
        type: Object,
      },
    },

    data: () => ({
      currentBot: null,
      isCodeShown: false,
      instruction0Shown: false,
      instruction1Shown: false,
      instruction2Shown: false,
      instruction3Shown: false,
      isResetMask: false,
    }),

    computed: {
      ...mapGetters({
        storeUsers: 'wBots/getUsers',
        storeBots: 'wBots/getBots',
        storeApp: 'wBots/getApp',
        storeObj: 'wBots/getObj',
      }),
      botList() {
        // dummy data in editor mode
        let bots = (this.editorMode) ? botOptionsSchema : _.cloneDeep(this.storeBots);
        if(this.editorMode){
          return bots;
        }
        // var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        let FacebookChannel = (this.storeObj) ? this.storeObj.attributes.FacebookChannel : '';
        let TelegramChannel = (this.storeObj) ? this.storeObj.attributes.TelegramChannel : '';
        let ViberChannel = (this.storeObj) ? this.storeObj.attributes.ViberChannel : '';
        let VKChannel = (this.storeObj) ? this.storeObj.attributes.VKChannel : '';
        let objid = (this.storeObj) ? this.storeObj.id : '';
        if(!(this.editorMode)) {
          let users =  (this.storeUsers);
          //console.log(users);
          bots.forEach(function (element) {
            element.options.objid = objid;
            let channel = 0;
            if (element.code == 'facebook' && FacebookChannel !== null) {

              element.options.channel = 'FacebookChannel';
              element.options.maskEnabled = false;
              users.forEach(function (user) {if(element.code==user.code){
                channel = parseInt(FacebookChannel, 10);
                if(channel==0){channel=-1;}
              }})

            }
            if (element.code == 'telegram' && TelegramChannel !== null) {

              element.options.channel = 'TelegramChannel';
              users.forEach(function (user) {if(element.code==user.code){
                channel = parseInt(TelegramChannel, 10);
                if(channel==0){channel=-1;}
              }})

            }
            if (element.code == 'viber' && ViberChannel !== null) {

              element.options.channel = 'ViberChannel';
              users.forEach(function (user) {if(element.code==user.code){
                channel = parseInt(ViberChannel, 10);
                if(channel==0){channel=-1;}
              }})

            }
            if (element.code == 'vk' && VKChannel !== null) {

              element.options.channel = 'VKChannel';
              users.forEach(function (user) {if(element.code==user.code){
                channel = parseInt(VKChannel, 10);
                if(channel==0){channel=-1;}
              }})

            }

            if (channel < 0) {
              element.options.disabled = true;
              element.options.subscribed = false;
              element.options.undefinedStatus = false;
            }
            if (channel > 0) {
              element.options.disabled = false;
              element.options.subscribed = true;
              element.options.undefinedStatus = false;
            }
            if (channel == 0) {
              element.options.disabled = false;
              element.options.subscribed = false;
              element.options.undefinedStatus = true;
            }


          });
        }

        if (!iOS && !(this.editorMode)) {//if (Notification.permission !== 'granted'){// use "granted", "denied" or "default"
          let iconName = 'icon-logo';
          let isSubscribed = false;
          let isDisabled = false;
          let isUndefined = false;
          let appName = (this.storeApp) ? this.storeApp.attributes.desktopName : '';
          let url = (this.storeApp) ? this.storeApp.attributes.mainThumbnailImageUrl : '';
          let backgroundUrl = '';

          if (url !== null) {
            backgroundUrl = 'https://' + this.storeApp.attributes.tenant + '-admin.' + this.storeApp.attributes.server + '/' + url;
          }

          const chromePushChanel = ((this.storeObj || {}).attributes || {}).ChromePush;
          const Notification = window.Notification || window.webkitNotification;
          if (Notification) {
            if (Notification.permission === 'granted' && chromePushChanel > 0) { /*var iconName = 'icon-pushR';*/
              isSubscribed = true;
            }
            if (Notification.permission === 'denied' || Notification.permission === 'default' || chromePushChanel <= 0) {
              isDisabled = true;
            }
          }
          //console.log(url);
          let push = {
            code: 'push', id: 0,
            options: {
              title: appName,//'This app\n',//+'(push)',//
              url: null,
              icon: {
                component: iconName,
                src: '',//url,
                backgroundUrl: backgroundUrl,//url,
              },
              channel: 'ChromePush',
              exclusion: false,
              backgroundUrl: url,
              subscribed: isSubscribed,
              disabled: isDisabled,
              undefinedStatus: isUndefined,
              maskEnabled: false,
              hidden: false,
            },
          };

          if (!(this.editorMode)){
            bots.unshift(push);
          }

        }

        return bots;
      },
    },

    created() {
      eventBus.$on('mask:start-timer', this.handlerMaskStart);
      eventBus.$on('mask:notificationPermission', this.checkNotificationPermission);
      eventBus.$on('code:closed', this.handlerCodeClosed);
    },

    methods: {
      async handlerMaskStart(bot) {
        this.currentBot = bot;
        if (bot.code !== 'push') {
          //if (!bot.options.disabled && !bot.options.subscribed) {
          if (bot.options.undefinedStatus) {
            this.isCodeShown = true;
          }
        }
        // if (bot.options.disabled) {
        //   this.isCodeShown = false;
        //   this.instruction0Shown = true;
        // }
        // this.deactivateBots(this.currentBot);
      },

      checkNotificationPermission(permission) {
        console.log('checkNotificationPermission -> ', permission);
        if (permission === 'denied') {
          this.instruction2Shown = true;
        }
      },

      handlerCodeClosed() {
        this.isCodeShown = false;
        this.instruction1Shown = false;
        this.instruction2Shown = false;
        this.instruction3Shown = false;
        eventBus.$emit('reset-mask', this.currentBot);
        eventBus.$emit('show-all-mask');
        this.activateBots();
        this.currentBot = null;
      },

      deactivateBots(currentBot = null) {
        let bots = this.botList;

        if (currentBot) {
          bots = bots.filter(bot => (bot.code !== currentBot.code));
        }

        bots.forEach((bot) => {
          bot.hidden = true;
        });
      },

      activateBots() {
        this.botList.forEach((bot) => {
          bot.hidden = false;
        });
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-bots
    padding 10px 15px
    position relative

    &__mask
      absolute-parent(1)

    &__list
      flex(center, center)
      flex-wrap wrap
      margin-x(-6px)
      margin-bottom -15px

    &__item
      margin-x(6px)
      margin-bottom 15px
      opacity 1
      transform scale(1)
      transition .225s ease-in-out

      &.is-hide
        opacity .25
        transform scale(.65)
</style>
