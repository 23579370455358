export default $axios => {
  const resource = '/screen'

  const methods = {
    /**
     * Create Screen
     * @param data
     * @param config {Object} axios config
     * @returns {Promise<any>}
     */
    create: (data, config = {}) => $axios.$post(resource, data, config),

    /**
     * Get a list of screens
     * @param config {Object} axios config
     * @param config.params {Object}
     * @param config.params.ApplicationId {Number}
     * @param config.params.page {Number}
     * @param config.params.pageSize {Number}
     * @returns {Promise<any>}
     */
    get: (config = {}) => $axios.$get(resource, config),

    /**
     * Remove screen
     * @param id {Number}
     * @param config {Object} axios config
     * @returns {Promise<any>}
     */
    remove: (id, config = {}) => $axios.$delete(`${resource}/${id}`, config),

    /**
     * Check replace screens across tenant
     * @param {Object} data payload - type formData
     * @param {number} data.from_id screen ID from
     * @param {number} data.to_id screen ID to
     * @param {Object} config axios config
     * @returns {Promise<any>}
     */
    checkReplace: (data, config = {}) => {
      const formData = new FormData()
      for (const key of Object.keys(data)) {
        formData.append(key, data[key])
      }
      return $axios.$post(`${resource}/checkreplace`, formData, config)
    },

    /**
     * Replace screens across tenant
     * @param {Object} data payload - type formData
     * @param {number} data.from_id screen ID from
     * @param {number} data.to_id screen ID to
     * @param {number} data.saveOriginalAsTmp 1 or 0
     * @param {Object} config axios config
     * @returns
     */
    replace: (data, config = {}) => {
      const formData = new FormData()
      for (const key of Object.keys(data)) {
        formData.append(key, data[key])
      }
      return $axios.$post(`${resource}/replace`, formData, config)
    },
  }

  return methods
}
