<template>
  <div :class="b({ isDisabled: disabled, isPickerOpened })">
    <div :class="b('container')">
      <input
        :id="vId"
        class="form-control"
        type="text"
        :value="colorInput"
        :disabled="disabled"
        @input="onChange"
        @keyup.enter="enterKeyPressed"
      />

      <button
        :class="b('button-toggle-picker')"
        :style="styleButtonTogglePicker"
        :disabled="disabled"
        @click.prevent="togglePicker"
      ></button>

      <div :class="b('picker-wrap')">
        <color-picker v-model="colorPicker"></color-picker>
      </div>
    </div>

    <label
      v-if="labelAfter"
      :class="b('label-after')"
      :for="vId"
      v-html="labelAfter"
    ></label>
  </div>
</template>

<script>
  import { Chrome } from 'vue-color';
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'control-color-picker',

    mixins: [
      baseMixin,
    ],

    components: {
      colorPicker: Chrome,
    },

    model: {
      prop: 'value',
      event: 'update',
    },

    props: {
      value: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      labelAfter: {
        type: String,
      },
    },

    data: () => ({
      // colorPicker: this.value,
      // colorInput: this.value,
      isPickerOpened: false,
    }),

    computed: {
      colorPicker: vm => vm.value,
      colorInput: vm => vm.value,
      styleButtonTogglePicker: vm => {
        return {
          backgroundColor: vm.colorInput,
        };
      },
    },

    watch: {
      colorPicker: {
        handler(value) {
          if (typeof value === 'string') {
            return value;
          }

          let result = value.hex.toLowerCase();

          if (value.rgba.a < 1) {
            result = `rgba(${value.rgba.r}, ${value.rgba.g}, ${value.rgba.b}, ${value.rgba.a})`;
          }

          // замена значения в input
          this.colorInput = result;

          return result;
        },
        immediate: true,
      },
    },

    methods: {
      onChange(event) {
        this.$emit('update', event.target.value);
      },

      togglePicker() {
        this.isPickerOpened = !this.isPickerOpened;
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-color-picker
    &_is-disabled
      cursor not-allowed

    > label
      ~/_is-disabled&
        pointer-events none

    &__container
      display inline-block
      width 300px
      position relative

    &__button-toggle-picker
      link()
      reset-button()
      width 32px
      border-left 1px solid #ccc
      position absolute
      top 1px
      right 1px
      bottom 1px
      box-shadow inset 0 0 0 4px #fff
      outline none

    &__picker-wrap
      display none
      position absolute
      right 0
      bottom 100%
      z-index 1000

      ~/_is-picker-opened &
        display block

    &__label-after
      margin-left 10px
</style>
