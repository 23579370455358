<template>
  <vddl-draggable
    :class="b({ isActive: (selectedComponentUuid === state.uuid) })"
    :draggable="state"
    :index="index"
    :wrapper="list"
    :moved="handlerMoved"
    :type="type"
    effect-allowed="move"
  >
    <WidgetEditorContentAreaComponentControls
      :class="b('controls')"
      @duplicate="handlerDuplicate(index, state)"
      @delete="handlerDelete(state)"
    />

    <component
      :is="state.name"
      :editor-mode="true"
      v-bind="state.properties"
      @click.native.prevent="handlerClick(state)"
    />
  </vddl-draggable>
</template>

<script>
  import _ from 'lodash';
  import { uuid } from 'vue-uuid';
  import { createNamespacedHelpers } from 'vuex';
  import logger from 'general/logger';
  import widgetComponents from 'general/components/widgetComponents';
  import WidgetEditorContentAreaComponentControls from './WidgetEditorContentAreaComponentControls';

  const { mapGetters, mapActions } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-editor-content-area-component',

    components: {
      WidgetEditorContentAreaComponentControls,
      ...widgetComponents,
    },

    props: {
      state: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
      },
      list: {
        type: Array,
      },
      type: {
        type: String
      }
    },

    computed: {
      ...mapGetters({
        selectedComponentUuid: 'editor/getSelectedComponentUuid',
      }),
    },

    methods: {
      ...mapActions({
        setSelectedComponentUuid: 'editor/setSelectedComponentUuid',
        addComponent: 'editor/addComponent',
        deleteComponent: 'editor/deleteComponent',
        resetSelectedComponentUuid: 'editor/resetSelectedComponentUuid',
        setPropertyPaneComponent: 'propertyPane/setComponent',
        resetPropertyPaneComponent: 'propertyPane/resetComponent',
      }),

      handlerMoved({ draggable: component }) {
        this.deleteComponent(component);
      },

      handlerClick(component) {
        if (component.uuid !== this.selectedComponentUuid) {
          this.setSelectedComponentUuid(component.uuid);
          this.setPropertyPaneComponent(component);
        }
      },

      handlerDuplicate(index, component) {
        const componentClone = _.cloneDeep(component);
        componentClone.uuid = uuid.v1();
        this.addComponent({ index: index + 1, component: componentClone });
        this.setSelectedComponentUuid(componentClone.uuid);
        this.setPropertyPaneComponent(componentClone);
      },

      handlerDelete(component) {
        this.deleteComponent(component);

        if (this.selectedComponentUuid === component.uuid) {
          this.resetSelectedComponentUuid();
          this.resetPropertyPaneComponent();
        }
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor-content-area-component
    position relative
    cursor pointer

    &_is-active
      box-shadow 0 0 0 1px red

    &:hover
      box-shadow 0 0 0 1px #eee

      ~/_is-active&
        box-shadow 0 0 0 1px red

    &__controls
      position absolute
      top -8px
      right -8px
      z-index 10
      opacity 0

      ~/:hover &
        opacity .8

      &:hover
      ~/_is-active &
        opacity 1 !important
</style>
