import { backgroundDefault } from './helper';

export default {
  name: 'mbst-row__item',
  aliasName: 'Column row',
  properties: {
    backendname: 'column-row',
    shadow: '',
    shadowPosition: '',
  },
  css: {
    ios: [
      {
        selector: '.mbst-row__item',
        rules: [
          { directive: 'width', value: '50%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ],
      android: [
      {
        selector: '.mbst-row__item',
        rules: [
          { directive: 'width', value: '50%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ]
  },
  components: []
}
