'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Templates
var _baseModal = require('text!bcTemplates/bootstrap-modal/modal.html');
var _footerModal = require('text!bcTemplates/bootstrap-modal/modal-footer.html');

module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade',

    initialize: function(options) {
        var t = this;

        if (options.parentView) {
            t.parentView = options.parentView;
        }

        t.baseTpl = _.template(_baseModal);
        t.footerTpl = _.template(_footerModal);
        t.isFooter = options.isFooter;

        t.data = {
            title: '',
            footer: ''
        };
    },

    render: function() {
        var t = this;

        if (t.isFooter) {
            t.data.footer = t.footerTpl({ btns: t.model.get('footer') });
        }
        t.data.title = t.model.get('title');

        t.$el.html(t.baseTpl(t.data));

        $(t.el).on('hidden.bs.modal', function(e) {
            t.close();
        });
    },

    close: function() {
        this.$el.off('hidden.bs.modal');
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }

});