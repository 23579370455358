import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  // makeControlProps('input', {
  //   pathValue: 'properties.intCount',
  //   label: 'Integer part count',
  //   type: 'number',
  //   min: 0,
  //   max: 5,
  // }),
  makeControlProps('select', {
    pathValue: 'properties.intCount',
    label: 'Integer part count',
    options: [
      {label: '1', value: 1},
      {label: '2', value: 2},
      {label: '3', value: 3},
      {label: '4', value: 4},
      {label: '5', value: 5},
      {label: '6', value: 6},
      {label: '7', value: 7},
      {label: '8', value: 8},
      {label: '9', value: 9},
      {label: '10', value: 10},
    ],
  }),
  // makeControlProps('input', {
  //   pathValue: 'properties.fractCount',
  //   label: 'Fractional part count',
  //   type: 'number',
  //   min: 0,
  //   max: 4,
  // }),
  makeControlProps('select', {
    pathValue: 'properties.fractCount',
    label: 'Decimal part count',
    options: [
      {label: '0', value: 0},
      {label: '1', value: 1},
      {label: '2', value: 2},
      {label: '3', value: 3},
      {label: '4', value: 4},
    ],
  }),
  makeControlProps('input', {
    pathValue: 'properties.defaultvalue',
    label: 'Default Value',
    type: 'text',
  }),
  makeControlProps('select', {
    pathValue: 'properties.buttonShow',
    label: 'Show edit button',
    options: [
      {label: 'Show', value: true},
      {label: 'Hide', value: false},
    ],
    subcontrols: {
      'true': [
        makeControlProps('input', {
          pathValue: 'properties.buttonLabel',
          label: 'Text on button'
        }),
      ]
    }
  }),

  makeControlProps('expansion', {
    pathValue: 'properties.popupCollapse',
    label: 'Popup properties',
    subcontrols: [

      makeControlProps('input', {
        pathValue: 'properties.editMeterName',
        label: 'Meter name'
      }),
      makeControlProps('input', {
        pathValue: 'properties.editLocation',
        label: 'Meter area'
      }),
      makeControlProps('input', {
        pathValue: 'properties.editDataHistoryLabel',
        type: 'text',
        label: 'Meter readings history'
      }),
      makeControlProps('input', {
        pathValue: 'properties.editDataHistory',
        type: 'text',
        label: 'Meter readings value'
      }),
      makeControlProps('input', {
        pathValue: 'properties.editDateChecked',
        label: 'Date checked'
      }),
      makeControlProps('input', {
        pathValue: 'properties.editNewDataLabel',
        label: 'New data label'
      }),
      makeControlProps('input', {
        pathValue: 'properties.units',
        label: 'Units',
        hint: 'Use markdown syntax m^3^ to display units as cubic meters (m<sup>3</sup>)'
      }),
      makeControlProps('select', {
        pathValue: 'properties.editShowDiff',
        label: 'Remaining',
        options: [
          {label: 'Show', value: true},
          {label: 'Hide', value: false},
        ],
        subcontrols: {
          'true': [
            makeControlProps('input', {
              pathValue: 'properties.editDiffLabel',
              label: 'Remaining label'
            }),
          ]
        }
      }),

      makeControlProps('input', {
        pathValue: 'properties.editSaveButton',
        label:  'Text on button in dialog'
      }),

    ]
  }),

  visibilityProps,
  loopControlProps
];

const view = [
  {
    selector: '.mbst-meter-input',
    controls: [
      makeControlProps('spacing', { label: 'Spacing' }),
    ]
  },
  {
    selector: '.mbst-meter-input .digits-container',
    controls: [
      typographyProps,
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps
    ]
  }
];

const actions = [true]

export default {
  properties,
  view,
  actions
}
