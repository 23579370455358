<template>
  <button
    :class="b({ isActive })"
    @click.prevent="start"
  >
    <div
      v-if="isActive"
      :class="b('timer')"
    >
      {{ freezingCounter }}
    </div>
  </button>
</template>

<script>
  import MakeEventBus from 'general/utils/MakeEventBus';
  import baseMixin from 'general/mixins/baseMixin';

  const eventBus = MakeEventBus('wBots');

  export default {
    name: 'w-bots-item-mask',

    mixins: [
      baseMixin,
    ],

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      bot: {
        type: Object,
        required: true,
      },

      timer: {
        type: Number,
        default: 3,
      },
    },

    data: () => ({
      isActive: false,
      freezingCounter: 0,
      timers: {
        mask: 0,
      },
    }),

    watch: {
      timer: {
        handler(data) {
          this.freezingCounter = data;
        },
        immediate: true,
      },
    },

    created() {
      eventBus.$on('reset-mask', this.reset);
    },

    methods: {
      reset(currentBot) {
        if (currentBot && currentBot.id === this.bot.id) {
          this.stop();
        }
      },

      start() {
        this.isActive = true;

        clearInterval(this.timers.mask);
        this.timers.mask = setInterval(() => {
          this.freezingCounter -= 1;

          if (this.freezingCounter <= 0) {
            this.stop(true);
          }
        }, 1000);

        eventBus.$emit('mask:start-timer', this.bot);
      },

      stop(timer) {
        this.isActive = false;
        this.freezingCounter = this.timer;
        clearInterval(this.timers.mask);

        eventBus.$emit('mask:stop-timer', this.bot, timer);
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-bots-item-mask
    reset-button()
    flex(flex-start, center)
    flex-direction column
    size(100%)
    background-color transparent
    absolute-parent(1)
    transition-duration $duration
    outline none
    cursor pointer

    &_is-active
      background-color rgba(#fff, .7)

    &__timer
      size(100%, 58px)
      flex-shrink 0
      flex(center, center)
      typography(20px)
      font-weight 700
      color $color-red
</style>
