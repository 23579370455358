import { uuid } from 'vue-uuid';

export default {
  props: {
    id: {
      type: [String, Number],
    },

    debug: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    vName: vm => vm.$options.name,
    vId: vm => vm.id || `${vm.vName}-${vm._uid}`,
    vUuid: () => uuid.v1(),
  },
};
