import logger from 'general/logger';
import MakeHttp from 'general/utils/MakeHttp';
import widgetScenarioSchema from '../../schemas/widgetScenarioSchema';

const http = MakeHttp();
const endpoint = '/widget/scenario/';
const SET_ALL = 'SET_ALL';
const ADD = 'ADD';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const SET_SCREENS = 'SET_SCREENS';
const SET_ACTIONS = 'SET_ACTIONS';

export default {
  namespaced: true,

  state: () => ({
    elements: [],
    pagination: null,
    screens: [],
    actions: [],
  }),

  getters: {

    getElements: state => state.elements,
    getScreens: state => state.screens,
    getActions: state => state.actions,

    getElementsByWidgetTypeId: state => widgetTypeId => (
      state.elements.filter(element => (element.attributes.widgetTypeId === widgetTypeId))
    ),

  },

  actions: {

    /**
     * @param dispatch
     * @returns {Promise}
     */
    async init({ dispatch }) {
      return Promise.all([
        dispatch('fetchAll'),
        dispatch('fetchScreens'),
        dispatch('fetchActions'),
      ]);
    },

    /**
     * @param rootState
     * @param commit
     * @returns {Promise<Array|boolean>}
     */
    async fetchAll({ rootState, commit }) {
      const query = {
        applicationId: rootState.widgetSettings.applicationId,
        page: 1,
        pageSize: 30,
      };
      const { data: elements, meta: pagination } = await http.get(endpoint, query);

      if (!elements || !pagination) {
        return false;
      }

      commit(SET_ALL, { elements, pagination });
      return elements;
    },

    /**
     * @param rootState
     * @param commit
     * @returns {Promise<Array|boolean>}
     */
    async fetchScreens({ rootState, commit }) {
      const url = '/screen/';
      const query = {
        ApplicationId: rootState.widgetSettings.applicationId,
        page: 1,
        pageSize: 500,
      };
      const { data: elements } = await http.get(url, query);

      if (!elements) {
        return false;
      }

      logger.log(
        'widgetScenarios:fetchScreens!',
        'elements:', elements,
      );

      commit(SET_SCREENS, elements);
      return elements;
    },

    /**
     * @param rootState
     * @param commit
     * @returns {Promise<Array|boolean>}
     */
    async fetchActions({ rootState, commit }) {
      const { applicationId } = rootState.widgetSettings;
      const url = `/screens/actions/${applicationId}`;
      const { data: elements } = await http.get(url);

      if (!elements) {
        return false;
      }

      logger.log(
        'widgetScenarios:fetchActions!',
        'elements:', elements,
      );

      commit(SET_ACTIONS, elements);
      return elements;
    },

    /**
     * @param rootState
     * @param commit
     * @param widgetTypeId
     * @returns {Promise<Object|boolean>}
     */
    async add({ rootState, commit }, widgetTypeId) {
      const data = widgetScenarioSchema.attributes;
      data.applicationId = rootState.widgetSettings.applicationId;
      data.widgetTypeId = widgetTypeId;
      const { data: element } = await http.post(endpoint, data);

      if (!element) {
        return false;
      }

      element.isEditing = true;
      commit(ADD, element);
      return element;
    },

    /**
     * @param state
     * @param commit
     * @param payload
     * @returns {Promise<Object|boolean>}
     */
    async update({ state, commit }, payload) {
      logger.log(
        'widgetScenarios:update!',
        'payload:', payload,
      );

      const url = `${endpoint}${payload.element.id}`;
      const query = {
        applicationId: payload.element.attributes.applicationId,
        widgetTypeId: payload.element.attributes.widgetTypeId,
        json_data: payload.element.attributes.json_data,
      };
      const { data: element, meta: { affected } } = await http.put(url, query);

      if (!affected) {
        return false;
      }

      element.isEditing = payload.editing;
      const index = state.elements.findIndex(item => (item.id === element.id));
      commit(UPDATE, { index, element });
      return element;
    },

    /**
     * @param state
     * @param commit
     * @param id
     * @returns {Promise<boolean>}
     */
    async delete({ state, commit }, id) {
      const url = `${endpoint}${id}`;
      const { meta: { affected } } = await http.delete(url);

      if (!affected) {
        return false;
      }

      const index = state.elements.findIndex(element => (element.id === id));
      commit(DELETE, index);
      return true;
    },

  },

  mutations: {

    [SET_ALL](state, { elements, pagination }) {
      state.elements = elements;
      state.pagination = pagination;
    },

    [ADD](state, element) {
      state.elements.push(element);
    },

    [UPDATE](state, { index, element }) {
      state.elements.splice(index, 1, element);
    },

    [DELETE](state, index) {
      state.elements.splice(index, 1);
    },

    [SET_SCREENS](state, elements) {
      state.screens = elements;
    },

    [SET_ACTIONS](state, elements) {
      state.actions = elements;
    },

  },
};
