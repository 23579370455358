'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Views
var ConditionalDataTransformationItemView = require('views/ConditionalDataTransformationItemView');

// Collections
var ConditionalDataTransformationCollection = require('collections/ConditionalDataTransformationCollection');

// Helper
var PubSub = require('helper/pubsub');


module.exports = Backbone.View.extend({

    events: {
        'click [data-js-action="addConditional"]': 'addConditional',
    },

    initialize: function(options) {
        this.collection = new ConditionalDataTransformationCollection(options.collection);
        this.itemViews = [];

        this.listenTo(this.collection, 'remove', this.setConditionalDataTransformations, this);
        this.listenTo(this.collection, 'change', this.setConditionalDataTransformations, this);
    },

    render: function() {
        var t = this;

        var $conditionalDataTransformationItemViews = _.map(t.collection.models, function(model, k) {
            var conditionalDataTransformationItemView = new ConditionalDataTransformationItemView({
                cdtId: k,
                parentView: t,
                model: model
            });
            t.itemViews.push(conditionalDataTransformationItemView);
            return conditionalDataTransformationItemView.render();
        });
        t.$('>div').append($conditionalDataTransformationItemViews);
    },

    addConditional: function(e) {
        e.preventDefault();
        var t = this;

        var ConditionalDataTransformationModel = Backbone.Model.extend({
            defaults: {
                conditions: [
                    {field: '', andor: 'and', value: '', operation: '=='}
                ],
                transformations: [
                    {key: '', value: ''}
                ]
            }
        });
        var conditionalDataTransformationModel = new ConditionalDataTransformationModel();

        t.collection.add(conditionalDataTransformationModel);

        var conditionalDataTransformationItemView = new ConditionalDataTransformationItemView({
            cdtId: t.collection.length - 1,
            model: conditionalDataTransformationModel
        });
        t.itemViews.push(conditionalDataTransformationItemView);

        t.$('>div').append(conditionalDataTransformationItemView.render());

        _.each(conditionalDataTransformationItemView.conditionsViews.itemViews, function(conditionsView) {
            var bindings = {
                '[name="dataConditionsField"]': 'field',
                '[name="dataConditionsValue"]': 'value',
                '[name="dataConditionsOperation"]': {
                    observe: 'operation',
                    selectOptions: {
                        collection: function () {
                            return conditionsView.conditionRulesView.operandTpls.operand.value;
                        }
                    }
                },
            };
            conditionsView.stickit(conditionsView.model, bindings);
        });
        _.each(conditionalDataTransformationItemView.transformationsViews.itemViews, function(transformationsView) {
            var bindings = {
                '[name="dataTransformationsKey"]': 'key',
                '[name="dataTransformationsValue"]': 'value'
            };
            transformationsView.stickit(transformationsView.model, bindings);
        });
    },

    close: function() {
        _.each(this.itemViews, function(itemView){
            if (itemView.close){
                itemView.close();
            }
        });
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    setConditionalDataTransformations: function() {
        console.log(this.collection);
        PubSub.trigger('setConditionalDataTransformations'); // TriggerOperationSettingsView
    }

});