var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("IconPicture", {
    class: _vm.b(),
    attrs: { width: _vm.width, height: _vm.height },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }