import { backgroundDefault } from './helper';

export default {
  name: 'mbst-flexrow__col',
  aliasName: 'cell',
  properties: {
    backendname: 'cell',
    colwidth: '6',
    offset: '',
    valign: '',
    shadow: '',
    shadowPosition: '',
    customClasses: ''
  },
  css: {
    ios: [
      {
        selector: '.mbst-row__col',
        rules: [
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-row__col',
        rules: [
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ]
  },
  components: []
}
