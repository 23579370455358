var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b() },
    [
      _c("div", { class: _vm.b("list-title") }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("WidgetSettingsScenarios.title")) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.elements.length ? _c("WidgetSettingsScenariosHead") : _vm._e(),
      _vm._v(" "),
      _vm.elements.length > 3
        ? _c("div", { class: _vm.b("controls") }, [
            _c(
              "button",
              {
                staticClass: "btn btn-xs btn-success",
                class: _vm.b("button-add"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addItem.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus mr5" }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("WidgetSettingsScenarios.buttonAdd"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.elements.length
        ? _c(
            "div",
            { class: _vm.b("list") },
            [
              _vm._l(_vm.elements, function (element) {
                return [
                  element
                    ? _c("WidgetSettingsScenariosItem", {
                        key: element.id,
                        class: _vm.b("item"),
                        attrs: {
                          debug: false,
                          model: element,
                          editing: element.isEditing || false,
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.elements.length
        ? _c("div", { class: _vm.b("message-list-is-empty") }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("WidgetSettingsScenarios.messageListIsEmpty")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.b("controls") }, [
        _c(
          "button",
          {
            staticClass: "btn btn-xs btn-success",
            class: _vm.b("button-add"),
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addItem.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "fa fa-plus mr5" }),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("WidgetSettingsScenarios.buttonAdd"))),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }