import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import mutations from './mutations';
import application from './application';
import lists from './lists';
import builder from './builder'
import pwalessWidgets from './pwalessWidgets';

// TODO: refactor old constructor
import constructorModules from './constructorModules';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    ...constructorModules,
    application,
    lists,
    builder,
    pwalessWidgets
  },
});
