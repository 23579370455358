<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 12.11 9.28"
    :width="width"
    :height="height"
  >
    <path
      d="M12.11 1.81a.72.72 0 0 1-.22.53L6.23 8 5.17 9.06a.75.75 0 0 1-1.06 0L3 8 .22 5.17a.75.75 0 0 1 0-1.06L1.28 3a.72.72 0 0 1 .53-.22.72.72 0 0 1 .53.22l2.3 2.3L9.77.22a.75.75 0 0 1 1.06 0l1.06 1.06a.73.73 0 0 1 .22.53z"
    ></path>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-check',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
