'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Templates
var _tpl = require('text!bcTemplates/crontab/crontab.html');

module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade',
    id: 'cronSetting',

    events: {
        'click [data-js-toggle="saveCronSetting"]': 'save'
    },

    initialize: function(options) {
        var t = this;
        t.tpl = _.template(_tpl);
        t.parentView = options.parentView;
        t.type = options.type;

        var Model = Backbone.Model.extend({
            defaults: {
                minute: '*',
                hour: '*',
                dayofweek: '*',
                dayofmonth: '*',
                month: '*'
            }
        });
        t.model = new Model(options.model);

        t.regex = {
            every: /\*/,
            interval: /\d+-\d+/,
            intervalEvery: /\d+-\d+\/\d+/,
            on: /^\+?(0|[1-9]\d*)$/,
            list: /\d+,/
        };

        t.cronData = {
            minute: {},
            hour: {},
            dayofweek: {
                list: [
                    {
                        label: lang('Mon'),
                        value: 1,
                        checked: false
                    },
                    {
                        label: lang('Tue'),
                        value: 2,
                        checked: false
                    },
                    {
                        label: lang('Wed'),
                        value: 3,
                        checked: false
                    },
                    {
                        label: lang('Thu'),
                        value: 4,
                        checked: false
                    },
                    {
                        label: lang('Fri'),
                        value: 5,
                        checked: false
                    },
                    {
                        label: lang('Sat'),
                        value: 6,
                        checked: false
                    },
                    {
                        label: lang('Sun'),
                        value: 0,
                        checked: false
                    },
                ]
            },
            dayofmonth: {
                list: [
                    {
                        label: lang('Jan'),
                        value: 1,
                        checked: false
                    },
                    {
                        label: lang('Feb'),
                        value: 2,
                        checked: false
                    },
                    {
                        label: lang('Mar'),
                        value: 3,
                        checked: false
                    },
                    {
                        label: lang('Apr'),
                        value: 4,
                        checked: false
                    },
                    {
                        label: lang('May'),
                        value: 5,
                        checked: false
                    },
                    {
                        label: lang('Jun'),
                        value: 6,
                        checked: false
                    },
                    {
                        label: lang('Jul'),
                        value: 7,
                        checked: false
                    },
                    {
                        label: lang('Aug'),
                        value: 8,
                        checked: false
                    },
                    {
                        label: lang('Sep'),
                        value: 9,
                        checked: false
                    },
                    {
                        label: lang('Oct'),
                        value: 10,
                        checked: false
                    },
                    {
                        label: lang('Nov'),
                        value: 11,
                        checked: false
                    },
                    {
                        label: lang('Dec'),
                        value: 12,
                        checked: false
                    },
                ]
            },
            month: {}
        };

        _.each(t.cronData, function(v, k) {
            v.value = t.model.get(k);

            _.each(t.regex, function(val, key) {
                if (v.value.search(val) != -1) {
                    v.type = key;
                }
            });

            switch (v.type) {
                case 'every':
                    if (v.value === '*') {
                        v.value = '1';
                    } else {
                        v.value = v.value.replace('*/', '');
                    }
                    break;
                case 'interval':
                    v.interval = v.value.split('-');
                    break;
                case 'intervalEvery':
                    v.interval = v.value.match(t.regex.interval)[0].split('-');
                    v.value = v.value.match(/\/\d+/)[0].replace('/', '');
                    break;
                case 'list':
                    var ar = v.value.split(',');
                    v.list = _.map(v.list, function (item) {
                        if ( _.find(ar, function(num) { return num == item.value; }) ) {
                            item.checked = true;
                        }
                        return item;
                    });
                    break;
            }

            if (k === 'dayofweek' || k === 'dayofmonth' && v.type === 'on') {
                v.type = 'list';
                v.list = _.map(v.list, function (item) {
                    if ( _.find([v.value], function(num) { return num == item.value; }) ) {
                        item.checked = true;
                    }
                    return item;
                });
            }
        });
    },

    render: function() {
        var t = this;
        t.$el.empty();

        t.$el.html(t.tpl(t.cronData));

        $(t.el).on('hidden.bs.modal', function(e) {
            t.close();
        });
    },

    save: function(e) {
        e.preventDefault();
         var t = this,
             cronData = {
                 minute: '*',
                 hour: '*',
                 dayofweek: '*',
                 dayofmonth: '*',
                 month: '*'
             };

        _.each(t.$('.b-collapse'), function(box) {
            var type = $(box).find('input[type="radio"]:checked').val(), key = $(box).attr('data-target');
            switch (type) {
                case 'every':
                    var e = '1';
                    if ($(box).find('input[name="every"]').val() != '') {
                        e = $(box).find('input[name="every"]').val();
                    }
                    cronData[key] = '*/' + e;
                    break;
                case 'interval':
                    var from = '0', through = '1';
                    if ($(box).find('input[name="from"]').val() != '') {
                        from = $(box).find('input[name="from"]').val();
                    }
                    if ($(box).find('input[name="through"]').val() != '') {
                        through = $(box).find('input[name="through"]').val();
                    }
                    cronData[key] = from + '-' + through;

                    if ($(box).find('input[type="checkbox"][name="'+key+'"]').is(':checked')) {
                        var e = '1';
                        if ($(box).find('input[name="intervalEvery"]').val() != '') {
                            e = $(box).find('input[name="intervalEvery"]').val();
                        }
                        cronData[key] += '/' + e;
                    }
                    break;
                case 'on':
                    var on = '0';
                    if ($(box).find('input[name="on"]').val() != '') {
                        on = $(box).find('input[name="on"]').val();
                    }
                    cronData[key] = on;
                    break;
                case 'list':
                    var k = $(box).find('.cron-list-data').attr('data-target');
                    var cronListData = _.map($(box).find('.cron-list-data input[type="checkbox"]:checked'), function(checkbox) {
                        return $(checkbox).val();
                    });
                    cronData[k] = cronListData.join(',');
                    break;
            }
        });

        t.parentView.model.set(t.type, JSON.stringify(cronData));
        console.log(cronData);
    },

    close: function() {
        this.$el.off('hidden.bs.modal');
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }

});