import { backgroundDefault } from './helper';

export default {
  name: 'mbst-list__item',
  aliasName: 'ListItem',
  properties: {
    backendname: 'listitem',
    text: 'List item',
    caption: '',
    iconLeft: '',
    iconRight: 'fal fa-angle-right',
    leftSlideCollapse: true,
    leftSlideEnabled: false,
    leftSlideIcon: '',
    leftSlideText: '',
    rightSlideCollapse: true,
    rightSlideEnabled: false,
    rightSlideIcon: '',
    rightSlideText: '',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  css: {
    ios: [
      {
        selector: '.mbst-list__item .q-slide-item__content',
        rules: [
          ...backgroundDefault
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-list__item .q-slide-item__content',
        rules: [
          ...backgroundDefault
        ]
      }
    ]
  },
  components: []
}
