<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 1087.28 705.45"
    :width="width"
    :height="height"
  >
    <defs>
      <linearGradient
        id="a"
        x1="-67.55"
        y1="920.17"
        x2="-66.55"
        y2="920.17"
        gradientTransform="matrix(0 522.56 522.56 0 -480722.98 35734.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#e91e79" />
        <stop offset=".12" stop-color="#db2483" />
        <stop offset=".33" stop-color="#b7349c" />
        <stop offset=".63" stop-color="#7d4ec5" />
        <stop offset=".98" stop-color="#2e71fc" />
        <stop offset="1" stop-color="#2a73ff" />
      </linearGradient>

      <g data-name="defs for logotype">
        <clipPath id="K">
          <path
            d="M413.05 126.75c-22.4 4.38-38.47 23.61-44 40.44l-69.8 196.06a54.15 54.15 0 0 0 43.13 71.61c-7.6-11.89-11.75-24.67-11.75-38a66.54 66.54 0 0 1 5.69-26.66l68.59-192.8a27.78 27.78 0 0 1 1.54-3.49c7-17 19-32.16 34.58-44.55a50.75 50.75 0 0 0-18.56-3.56 49 49 0 0 0-9.42.91"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="L"
          x1="-67.55"
          y1="920.15"
          x2="-66.55"
          y2="920.15"
          gradientTransform="matrix(0 612.82 612.82 0 -563517.15 41529.22)"
          xlink:href="#a"
        />
        <clipPath id="M">
          <path
            d="M646.25 129.4c15.61 12.39 27.57 27.59 34.58 44.55a27.87 27.87 0 0 1 1.54 3.49L751 370.22a66.54 66.54 0 0 1 5.69 26.66c0 13.3-4.14 26.07-11.75 38A54.15 54.15 0 0 0 788 363.25l-69.72-196.07c-5.58-16.83-21.65-36.06-44.05-40.44a49 49 0 0 0-9.42-.91 50.73 50.73 0 0 0-18.56 3.56"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="N"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 612.82 612.82 0 -563177.99 41258.31)"
          xlink:href="#a"
        />
        <clipPath id="O">
          <path
            d="M623.78 145.7l-54.71 66.72c-8.28 10-17.59 13.2-25.43 13.32-7.83-.12-17.15-3.31-25.43-13.32L463.5 145.7a56.2 56.2 0 0 0-22.5-16.3c-15.61 12.39-27.57 27.59-34.58 44.55 3.17-6.14 8.14-9.78 14-10.7a16 16 0 0 1 14.17 6.1l57.5 70.12c14.93 19.4 37.3 23.72 51.47 23.72s36.54-4.32 51.47-23.72l57.5-70.12a16 16 0 0 1 14.17-6.1c5.91.92 10.87 4.56 14 10.7-7-17-19-32.16-34.58-44.55a56.19 56.19 0 0 0-22.46 16.31"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="P"
          x1="-67.55"
          y1="920.25"
          x2="-66.55"
          y2="920.25"
          gradientTransform="matrix(-276.09 0 0 276.09 -17958.9 -253876.85)"
          xlink:href="#a"
        />
        <clipPath id="Q">
          <path
            d="M543.64 329.06h-1.74a124.53 124.53 0 0 0-48.48 9.78l-136.81 57.88c-10.33 3.38-16.43-1.46-18.3-3.22a16.52 16.52 0 0 1-3.95-17.77l1.88-5.29a66.49 66.49 0 0 0-5.61 26.44c0 13.3 4.14 26.07 11.75 38a54 54 0 0 0 28.75-3.88l136.81-57.88a97.37 97.37 0 0 1 71.39 0L716.15 431a53.94 53.94 0 0 0 28.75 3.81c7.6-11.89 11.75-24.67 11.75-38a66.47 66.47 0 0 0-5.59-26.38l1.86 5.23A16.51 16.51 0 0 1 749 393.5c-1.86 1.75-8 6.59-18.29 3.22l-136.85-57.88a124.54 124.54 0 0 0-48.48-9.78z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="R"
          x1="-67.55"
          y1="920.13"
          x2="-66.55"
          y2="920.13"
          gradientTransform="matrix(0 -460.66 -460.66 0 424410.03 -30463.1)"
          xlink:href="#a"
        />
      </g>

      <g
        v-if="!isSimple"
        data-name="defs for MOBSTED"
      >
        <clipPath id="b">
          <path
            fill="none"
            d="M60.86 615.19V488.01h18.37l41.68 112.17h.36l40.98-112.17h18.19v127.18h-12.01v-113.4h-.35l-41.69 113.4h-11.31l-41.86-113.4h-.35v113.4H60.86z"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="M266.46 603.71a41.62 41.62 0 0 1-14.66-12.37 52.93 52.93 0 0 1-8.48-18 85.48 85.48 0 0 1 0-42.92 53.94 53.94 0 0 1 8.48-18.1 42.91 42.91 0 0 1 14.66-12.63q8.92-4.77 21.11-4.77a48.63 48.63 0 0 1 19.79 3.89 40.53 40.53 0 0 1 15 11.3 51.62 51.62 0 0 1 9.46 17.89 80 80 0 0 1 3.27 23.76 82.65 82.65 0 0 1-2.74 21.38 53 53 0 0 1-8.48 18 42.39 42.39 0 0 1-14.66 12.45q-8.92 4.68-21.29 4.68-12.55 0-21.46-4.59M262 490a53.34 53.34 0 0 0-18.37 14.4 62.91 62.91 0 0 0-11.22 21.28 92 92 0 0 0-.09 52 61.75 61.75 0 0 0 11.13 21.2A52.91 52.91 0 0 0 262 613.25q11.12 5.29 25.79 5.3t25.79-5.3a52.91 52.91 0 0 0 18.55-14.4 63 63 0 0 0 11.22-21.2 86 86 0 0 0 3.8-25.88 88.38 88.38 0 0 0-3.8-26.23A62 62 0 0 0 332 504.17a52.73 52.73 0 0 0-18.72-14.31q-11.22-5.21-26.05-5.21-14.31 0-25.26 5.3"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="d"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 522.56 522.56 0 -480555.84 35640.78)"
          xlink:href="#a"
        />
        <clipPath id="e">
          <path
            d="M408 554.43h32a111.36 111.36 0 0 1 15.37 1 35.42 35.42 0 0 1 11.76 3.57 18.33 18.33 0 0 1 7.6 7.51q2.65 4.86 2.65 12.81 0 8.3-2.38 13.33a17.3 17.3 0 0 1-7.15 7.78 31.87 31.87 0 0 1-12.1 3.62 145.85 145.85 0 0 1-17.22.89H408zm0-56.17h27.74a102.41 102.41 0 0 1 16.69 1.15 31.6 31.6 0 0 1 11 3.71 15.39 15.39 0 0 1 6.18 6.71 23.92 23.92 0 0 1 1.94 10.17 29.48 29.48 0 0 1-1.68 10.33 17.3 17.3 0 0 1-5.56 7.6 26.67 26.67 0 0 1-10.25 4.68 65.86 65.86 0 0 1-15.72 1.59H408zm-12-10.24v127.17h51.93a71.19 71.19 0 0 0 16.69-1.85 34.32 34.32 0 0 0 13.16-6.1 28.57 28.57 0 0 0 8.57-11.12q3.09-6.89 3.09-16.78 0-12.72-6.45-21.2t-19.69-10.42v-.35a27.09 27.09 0 0 0 7.51-2.56 21.33 21.33 0 0 0 6.45-5.12 25.69 25.69 0 0 0 4.59-8.12 33.94 33.94 0 0 0 1.71-11.57 35.71 35.71 0 0 0-2.29-13 25 25 0 0 0-7.33-10.16 35.09 35.09 0 0 0-13-6.54 69.65 69.65 0 0 0-19.07-2.3z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="f"
          x1="-67.55"
          y1="920.18"
          x2="-66.55"
          y2="920.18"
          gradientTransform="matrix(0 522.56 522.56 0 -480409.44 35554.51)"
          xlink:href="#a"
        />
        <clipPath id="g">
          <path
            d="M562.94 487.49a39.78 39.78 0 0 0-13.51 7.6 31.55 31.55 0 0 0-8.13 11 31.08 31.08 0 0 0-2.74 12.71q0 9.37 3.36 15.46a29.81 29.81 0 0 0 8.74 10 45 45 0 0 0 12.34 6.1q7 2.3 14.31 4t14.3 3.45a53.53 53.53 0 0 1 12.39 4.65 26 26 0 0 1 8.74 7.51q3.36 4.6 3.36 12a27.54 27.54 0 0 1-1.86 10.07 22.62 22.62 0 0 1-5.74 8.39 27.8 27.8 0 0 1-10.07 5.74 45.29 45.29 0 0 1-14.66 2.12 53.13 53.13 0 0 1-13.6-1.67 31.46 31.46 0 0 1-11.17-5.38 28.48 28.48 0 0 1-7.95-9.63 37.65 37.65 0 0 1-3.8-14.39h-11.69a43.91 43.91 0 0 0 3.44 18.1 35.6 35.6 0 0 0 9.63 12.9 40.62 40.62 0 0 0 14.66 7.78 63.2 63.2 0 0 0 18.37 2.56q22.08 0 34.27-9.71t12.19-27.38q0-9.18-3.09-15.27a28.46 28.46 0 0 0-8.22-10 42.26 42.26 0 0 0-11.75-6.2q-6.62-2.29-13.69-4t-13.86-3.17a72.27 72.27 0 0 1-12.45-3.83 31.12 31.12 0 0 1-9.54-6.1 17.26 17.26 0 0 1-4.95-9.89 21.62 21.62 0 0 1 .53-9.89 22.38 22.38 0 0 1 5.12-9 27.22 27.22 0 0 1 10-6.62 41.07 41.07 0 0 1 15.46-2.56 42.88 42.88 0 0 1 11.66 1.59 29.86 29.86 0 0 1 10.07 4.95 23.57 23.57 0 0 1 7 8.66 28.55 28.55 0 0 1 2.56 12.54h11.48a40.52 40.52 0 0 0-2.69-14.68 33.81 33.81 0 0 0-8-12.1 37.39 37.39 0 0 0-13.33-8.22 53.76 53.76 0 0 0-18.81-3 56.9 56.9 0 0 0-18.72 2.83"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="h"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 522.56 522.56 0 -480262.81 35477.58)"
          xlink:href="#a"
        />
        <clipPath id="i">
          <path
            fill="none"
            d="M654.95 488.01v10.25h40.27v116.93h12.01V498.26h40.27v-10.25h-92.55z"
          />
        </clipPath>
        <linearGradient
          id="j"
          x1="-67.55"
          y1="920.13"
          x2="-66.55"
          y2="920.13"
          gradientTransform="matrix(0 522.56 522.56 0 -480126.24 35412.26)"
          xlink:href="#a"
        />
        <clipPath id="k">
          <path
            fill="none"
            d="M786.13 488.01v127.18h86.38v-10.24h-74.37v-50.52h65.53v-10.25h-65.53v-45.92h72.6v-10.25h-84.61z"
          />
        </clipPath>
        <linearGradient
          id="l"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 522.56 522.56 0 -480015.01 35342.07)"
          xlink:href="#a"
        />
        <clipPath id="m">
          <path
            d="M934.57 498.26h28.79a138.67 138.67 0 0 1 15.72.89 37.55 37.55 0 0 1 13.43 3.85 33.65 33.65 0 0 1 11 8.92 45.26 45.26 0 0 1 6.71 11.83 57.64 57.64 0 0 1 3.36 13.61 115.2 115.2 0 0 1 .88 14 100.22 100.22 0 0 1-1 13.6 64.08 64.08 0 0 1-3.35 13.51 47.51 47.51 0 0 1-6.45 11.93 28.79 28.79 0 0 1-10.42 8.66 44.17 44.17 0 0 1-14.6 4.94 113.48 113.48 0 0 1-15.28 1h-28.79zm-12-10.24v127.17H966a124.3 124.3 0 0 0 12.54-.62 65.59 65.59 0 0 0 11.92-2.3 49.81 49.81 0 0 0 11-4.68 38.94 38.94 0 0 0 9.63-7.95 46.09 46.09 0 0 0 7.33-10.51 66.29 66.29 0 0 0 4.68-12 85.1 85.1 0 0 0 2.56-12.81 106.6 106.6 0 0 0 .79-12.89 108.69 108.69 0 0 0-1-13.86 81.94 81.94 0 0 0-3.35-14.84 65.15 65.15 0 0 0-6.45-14 38.71 38.71 0 0 0-10.42-11.22 45.33 45.33 0 0 0-9.1-5.12 50.76 50.76 0 0 0-9.63-2.92 71.72 71.72 0 0 0-10.07-1.23Q971.31 488 966 488z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="n"
          x1="-67.55"
          y1="920.19"
          x2="-66.55"
          y2="920.19"
          gradientTransform="matrix(0 522.56 522.56 0 -479879.57 35261.44)"
          xlink:href="#a"
        />
      </g>

      <g
        v-if="!isSimple"
        data-name="defs for way to mobile"
      >
        <clipPath id="o">
          <path
            fill="none"
            d="M691.23 651.39l-7.81 25.16-6.55-25.16h-3.85l-6.89 25.39-7.7-25.39h-3.44l9.07 29.75h4.14l6.6-24.98h.12l6.49 24.98h3.67l9.65-29.75h-3.5z"
          />
        </clipPath>
        <linearGradient
          id="p"
          x1="-67.55"
          y1="920.16"
          x2="-66.55"
          y2="920.16"
          gradientTransform="matrix(0 594.93 594.93 0 -546759.39 40329.14)"
          xlink:href="#a"
        />
        <clipPath id="q">
          <path
            d="M710.26 678.53a6.05 6.05 0 0 1-2.07-1 5 5 0 0 1-1.43-1.67 4.9 4.9 0 0 1-.55-2.38 5.1 5.1 0 0 1 2.21-4.54 12.63 12.63 0 0 1 6.17-1.78q2.24-.17 4.48-.46a17 17 0 0 0 4.42-1.21v4.65a7.79 7.79 0 0 1-.92 3.88 8 8 0 0 1-2.44 2.7 10.84 10.84 0 0 1-3.47 1.58 15.63 15.63 0 0 1-4 .52 9.17 9.17 0 0 1-2.38-.32m1.32-27.4a11.11 11.11 0 0 0-3.79 1.73 9.11 9.11 0 0 0-2.67 3 10.61 10.61 0 0 0-1.21 4.51h3.5a8.74 8.74 0 0 1 .95-3.24 6 6 0 0 1 1.84-2.07 7.12 7.12 0 0 1 2.61-1.09 15.85 15.85 0 0 1 3.27-.32q4 0 5.71 1.69a5.88 5.88 0 0 1 1.69 4.34 8.54 8.54 0 0 1-.2 1.75 1.83 1.83 0 0 1-1.06 1.35 8.84 8.84 0 0 1-2.21.63q-1.35.23-2.33.35l-4.77.63q-10.4 1.38-10.4 9a8.17 8.17 0 0 0 .8 3.76 7.94 7.94 0 0 0 2.18 2.67 9.09 9.09 0 0 0 3.19 1.61 13.52 13.52 0 0 0 3.76.52 14.66 14.66 0 0 0 6.09-1.26 14.93 14.93 0 0 0 5-3.68 9.45 9.45 0 0 0 .12 1.52 4.24 4.24 0 0 0 .43 1.32 3.35 3.35 0 0 0 .83 1 2.65 2.65 0 0 0 1.38.54 6.74 6.74 0 0 0 1.55.17 5.32 5.32 0 0 0 1.52-.23l1.18-.35v-2.7q-2.18.8-2.9.26t-.72-2.55v-16.2a10.27 10.27 0 0 0-.83-4.36 7.21 7.21 0 0 0-2.3-2.84 9.77 9.77 0 0 0-3.45-1.55 17.58 17.58 0 0 0-4.22-.49 18.67 18.67 0 0 0-4.57.54"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="r"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546721.41 40294.9)"
          xlink:href="#a"
        />
        <clipPath id="s">
          <path
            d="M760.08 651.39l-9.53 25.55-9.55-25.55h-3.85l11.54 29.93a12.36 12.36 0 0 0-.92 2.38 18.35 18.35 0 0 1-1 2.69 7.73 7.73 0 0 1-1.52 2.21 3.19 3.19 0 0 1-2.35.92 9.33 9.33 0 0 1-1.47-.12l-1.41-.23v3.27a14.52 14.52 0 0 0 1.52.29 12.59 12.59 0 0 0 1.75.12 5.56 5.56 0 0 0 3.07-.83 8.34 8.34 0 0 0 2.35-2.41 22.8 22.8 0 0 0 2-3.82q.95-2.24 2-5.05l11.08-29.35z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="t"
          x1="-67.55"
          y1="920.16"
          x2="-66.55"
          y2="920.16"
          gradientTransform="matrix(0 594.93 594.93 0 -546685.4 40269.88)"
          xlink:href="#a"
        />
        <clipPath id="u">
          <path
            d="M794.77 643.13v8.27h-4.19v2.87h4.19V676q0 3.33 1.35 4.62a5.47 5.47 0 0 0 3.93 1.29 8.54 8.54 0 0 0 2.29-.29c.69-.19 1.24-.32 1.67-.4v-2.81c-.46.11-.93.21-1.41.29a8.61 8.61 0 0 1-1.41.12 4.94 4.94 0 0 1-1.69-.23 1.64 1.64 0 0 1-.89-.75 3.16 3.16 0 0 1-.34-1.32q-.06-.8-.06-1.9v-20.35h5.57v-2.87h-5.57v-8.27z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="v"
          x1="-67.55"
          y1="920.16"
          x2="-66.55"
          y2="920.16"
          gradientTransform="matrix(0 594.93 594.93 0 -546639.06 40229.12)"
          xlink:href="#a"
        />
        <clipPath id="w">
          <path
            d="M821.31 677.93a8.6 8.6 0 0 1-3.16-2.58 11.86 11.86 0 0 1-1.92-4 18.58 18.58 0 0 1-.66-5.08 19 19 0 0 1 .57-4.68 12.38 12.38 0 0 1 1.78-4 9 9 0 0 1 3.13-2.81 9.43 9.43 0 0 1 4.62-1.06 10.08 10.08 0 0 1 4.65 1 8.53 8.53 0 0 1 3.1 2.67 11.74 11.74 0 0 1 1.78 4 20.34 20.34 0 0 1 .58 4.94 18.67 18.67 0 0 1-.58 4.65 12.79 12.79 0 0 1-1.78 4 8.91 8.91 0 0 1-3.13 2.84 9.41 9.41 0 0 1-4.62 1.06 9.86 9.86 0 0 1-4.36-.92m-1.67-26.1a12.26 12.26 0 0 0-4.31 3.36 14.45 14.45 0 0 0-2.59 5 21 21 0 0 0-.86 6.12 19.55 19.55 0 0 0 1.12 6.76 14.06 14.06 0 0 0 3 4.88 12 12 0 0 0 4.37 2.93 15.58 15.58 0 0 0 5.37 1 14 14 0 0 0 5.66-1.12 12.27 12.27 0 0 0 4.36-3.19 14.52 14.52 0 0 0 2.79-4.94 19.94 19.94 0 0 0 1-6.43 22 22 0 0 0-.83-6.14 13.75 13.75 0 0 0-2.56-5 12.39 12.39 0 0 0-4.31-3.33 14 14 0 0 0-6.09-1.24 13.83 13.83 0 0 0-6 1.24"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="x"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546611.19 40206.1)"
          xlink:href="#a"
        />
        <clipPath id="y">
          <path
            d="M895.36 652a10 10 0 0 0-4 4.33 12.39 12.39 0 0 0-1.12-2.24 7.11 7.11 0 0 0-1.67-1.81 8 8 0 0 0-2.44-1.24 11.61 11.61 0 0 0-3.5-.46 11.1 11.1 0 0 0-5.4 1.35 9.08 9.08 0 0 0-3.79 3.93h-.11v-4.48h-3.45v29.75h3.45v-16q0-.92.09-2a17.13 17.13 0 0 1 .32-2.18 14.63 14.63 0 0 1 .6-2.1 6.61 6.61 0 0 1 .95-1.75 8.33 8.33 0 0 1 6.72-3.39q3.68 0 5 1.84a8.39 8.39 0 0 1 1.35 5.05v20.56h3.44v-16c0-.61 0-1.28.09-2a17.48 17.48 0 0 1 .32-2.18 14.63 14.63 0 0 1 .6-2.1 6.66 6.66 0 0 1 .95-1.75 8.34 8.34 0 0 1 6.72-3.39q3.67 0 5 1.84a8.39 8.39 0 0 1 1.35 5.05v20.56h3.44v-18.83A29.76 29.76 0 0 0 910 658a10.18 10.18 0 0 0-1.29-3.76 7.29 7.29 0 0 0-2.79-2.64 10 10 0 0 0-4.85-1 11.45 11.45 0 0 0-5.71 1.41"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="z"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546548.42 40153.85)"
          xlink:href="#a"
        />
        <clipPath id="A">
          <path
            d="M930 677.93a8.6 8.6 0 0 1-3.16-2.58 11.86 11.86 0 0 1-1.92-4 18.54 18.54 0 0 1-.66-5.08 19 19 0 0 1 .57-4.68 12.42 12.42 0 0 1 1.78-4 9.05 9.05 0 0 1 3.13-2.81 9.43 9.43 0 0 1 4.62-1.06 10.07 10.07 0 0 1 4.65 1 8.53 8.53 0 0 1 3.1 2.67 11.74 11.74 0 0 1 1.78 4 20.39 20.39 0 0 1 .57 4.94 18.72 18.72 0 0 1-.57 4.65 12.79 12.79 0 0 1-1.78 4 8.92 8.92 0 0 1-3.13 2.84 9.41 9.41 0 0 1-4.62 1.06 9.86 9.86 0 0 1-4.36-.92m-1.67-26.1a12.26 12.26 0 0 0-4.31 3.36 14.47 14.47 0 0 0-2.58 5 21 21 0 0 0-.86 6.12 19.55 19.55 0 0 0 1.09 6.83 14 14 0 0 0 3 4.88 12 12 0 0 0 4.36 2.93 15.59 15.59 0 0 0 5.37 1 14 14 0 0 0 5.65-1.12 12.28 12.28 0 0 0 4.37-3.19 14.51 14.51 0 0 0 2.79-4.94 19.94 19.94 0 0 0 1-6.43 22 22 0 0 0-.83-6.14 13.74 13.74 0 0 0-2.56-5 12.39 12.39 0 0 0-4.31-3.33 14 14 0 0 0-6.09-1.24 13.83 13.83 0 0 0-6 1.24"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="B"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546502.53 40117.66)"
          xlink:href="#a"
        />
        <clipPath id="C">
          <path
            d="M968.32 677.87a9.09 9.09 0 0 1-3.16-2.67 12.19 12.19 0 0 1-1.92-4 18.29 18.29 0 0 1-.66-5 16.43 16.43 0 0 1 .69-4.8 12.46 12.46 0 0 1 2-4 9.76 9.76 0 0 1 3.16-2.7 8.75 8.75 0 0 1 4.22-1 9.65 9.65 0 0 1 4.62 1.06 9.1 9.1 0 0 1 3.24 2.9 12.53 12.53 0 0 1 1.84 4.28 19.36 19.36 0 0 1 .46 5.25 14.61 14.61 0 0 1-.78 5 10.57 10.57 0 0 1-2.15 3.65 9.18 9.18 0 0 1-3.22 2.27 10.22 10.22 0 0 1-4 .77 9.27 9.27 0 0 1-4.31-1m-9.19-38.07v41.35h3.45v-5.23h.12a9.28 9.28 0 0 0 1.92 2.61 11.57 11.57 0 0 0 2.58 1.87 13.78 13.78 0 0 0 2.9 1.15 11.27 11.27 0 0 0 2.93.4 14.19 14.19 0 0 0 6.14-1.21 11.49 11.49 0 0 0 4.16-3.3 13.48 13.48 0 0 0 2.38-5 24.42 24.42 0 0 0 .75-6.14 22 22 0 0 0-.81-6 14.79 14.79 0 0 0-2.44-5 12.07 12.07 0 0 0-4.13-3.45 12.67 12.67 0 0 0-5.89-1.29 13.72 13.72 0 0 0-6.06 1.41 10.66 10.66 0 0 0-4.57 4.16v-16.35z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="D"
          x1="-67.55"
          y1="920.18"
          x2="-66.55"
          y2="920.18"
          gradientTransform="matrix(0 594.93 594.93 0 -546470.66 40084.5)"
          xlink:href="#a"
        />
        <clipPath id="E">
          <path
            d="M998.65 681.14h3.45v-29.75h-3.45zm-.17-36.24h3.79v-5.1h-3.79z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="F"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546436.47 40061.49)"
          xlink:href="#a"
        />
        <clipPath id="G">
          <path
            fill="none"
            d="M1015.7 639.79h3.45v41.35h-3.45z"
          />
        </clipPath>
        <linearGradient
          id="H"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546419.43 40048.14)"
          xlink:href="#a"
        />
        <clipPath id="I">
          <path
            d="M1035.34 660.18a11.61 11.61 0 0 1 2-3.33 9.32 9.32 0 0 1 3-2.3 9.06 9.06 0 0 1 4-.86 9.51 9.51 0 0 1 4.08.83 8.46 8.46 0 0 1 3 2.24 9.63 9.63 0 0 1 1.78 3.33 13.67 13.67 0 0 1 .6 4.11h-19.41a15.42 15.42 0 0 1 1-4m3.27-8.35a12.53 12.53 0 0 0-4.28 3.39 15.64 15.64 0 0 0-2.7 5 19.49 19.49 0 0 0-.95 6.15 22.39 22.39 0 0 0 .8 6.12 13.9 13.9 0 0 0 2.47 4.94 11.62 11.62 0 0 0 4.25 3.3 14.43 14.43 0 0 0 6.14 1.21 14.87 14.87 0 0 0 4.94-.75 11.16 11.16 0 0 0 3.68-2.1 12.17 12.17 0 0 0 2.58-3.16 18.6 18.6 0 0 0 1.66-3.94h-3.85a8.58 8.58 0 0 1-3.22 5.05 9.51 9.51 0 0 1-5.8 1.78 9.65 9.65 0 0 1-4.36-.95 9.07 9.07 0 0 1-3.13-2.53 11 11 0 0 1-1.87-3.74 16.08 16.08 0 0 1-.63-4.56h23.09q.06-8-3.45-12.26a11.86 11.86 0 0 0-9.65-4.22 12.89 12.89 0 0 0-5.74 1.24"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="J"
          x1="-67.55"
          y1="920.17"
          x2="-66.55"
          y2="920.17"
          gradientTransform="matrix(0 594.93 594.93 0 -546392.75 40028.48)"
          xlink:href="#a"
        />
      </g>
    </defs>

    <g>
      <g data-name="logotype">
        <g clip-path="url(#K)">
          <path
            transform="rotate(-49.9 366.489 280.336)"
            fill="url(#L)"
            d="M200.3 123.8h332.4v313.08H200.3z"
          />
        </g>
        <g clip-path="url(#M)">
          <path
            transform="rotate(-49.9 720.768 280.348)"
            fill="url(#N)"
            d="M554.58 123.8h332.4v313.08h-332.4z"
          />
        </g>
        <g clip-path="url(#O)">
          <path
            transform="rotate(-35.3 543.63 196.282)"
            fill="url(#P)"
            d="M393.02 62.42h301.23v267.73H393.02z"
          />
        </g>
        <g clip-path="url(#Q)">
          <path
            transform="rotate(-67.1 543.611 382.636)"
            fill="url(#R)"
            d="M411.39 165.57h264.49v434.12H411.39z"
          />
        </g>
      </g>

      <g
        v-if="!isSimple"
        data-name="MOBSTED"
      >
        <g clip-path="url(#b)">
          <path
            transform="rotate(-36.57 120.676 551.636)"
            fill="url(#a)"
            d="M34.74 464.91h171.81V638.3H34.74z"
          />
        </g>
        <g clip-path="url(#c)">
          <path
            transform="rotate(-36.57 287.88 551.632)"
            fill="url(#d)"
            d="M200.36 462.53h174.96v178.15H200.36z"
          />
        </g>
        <g clip-path="url(#e)">
          <path
            transform="rotate(-36.57 442.711 551.635)"
            fill="url(#f)"
            d="M367.26 472.69h150.82V630.5H367.26z"
          />
        </g>
        <g clip-path="url(#g)">
          <path
            transform="rotate(-36.57 581.905 551.624)"
            fill="url(#h)"
            d="M504.78 470.26h154.11v162.67H504.78z"
          />
        </g>
        <g clip-path="url(#i)">
          <path
            transform="rotate(-36.57 701.288 551.629)"
            fill="url(#j)"
            d="M626.17 472.96h150.11v157.29H626.17z"
          />
        </g>
        <g clip-path="url(#k)">
          <path
            transform="rotate(-36.57 829.4 551.631)"
            fill="url(#l)"
            d="M756.74 474.8h145.15v153.6H756.74z"
          />
        </g>
        <g clip-path="url(#m)">
          <path
            transform="rotate(-36.57 974.586 551.62)"
            fill="url(#n)"
            d="M894.9 469.59h159.19v164.02H894.9z"
          />
        </g>
      </g>

      <g
        v-if="!isSimple"
        data-name="way to mobile"
      >
        <g clip-path="url(#o)">
          <path
            transform="rotate(-54.53 674.798 666.252)"
            fill="url(#p)"
            d="M651.21 641.45h47.29v49.63h-47.29z"
          />
        </g>
        <g clip-path="url(#q)">
          <path
            transform="rotate(-54.53 716.495 666.254)"
            fill="url(#r)"
            d="M695.65 645.76h41.8v41.02h-41.8z"
          />
        </g>
        <g clip-path="url(#s)">
          <path
            transform="rotate(-54.53 750.433 672.116)"
            fill="url(#t)"
            d="M725.88 649.24h49.23V695h-49.23z"
          />
        </g>
        <g clip-path="url(#u)">
          <path
            transform="rotate(-54.53 797.237 662.52)"
            fill="url(#v)"
            d="M777.59 645.8H817v33.47h-39.41z"
          />
        </g>
        <g clip-path="url(#w)">
          <path
            transform="rotate(-54.53 825.601 666.259)"
            fill="url(#x)"
            d="M804.9 645.94h41.54v40.65H804.9z"
          />
        </g>
        <g clip-path="url(#y)">
          <path
            transform="rotate(-54.53 890.037 665.86)"
            fill="url(#z)"
            d="M865.93 640.54h48.34v50.66h-48.34z"
          />
        </g>
        <g clip-path="url(#A)">
          <path
            transform="rotate(-54.53 934.253 666.263)"
            fill="url(#B)"
            d="M913.56 645.94h41.54v40.65h-41.54z"
          />
        </g>
        <g clip-path="url(#C)">
          <path
            transform="rotate(-54.53 972.731 660.861)"
            fill="url(#D)"
            d="M947.71 637.51h50.19v46.73h-50.19z"
          />
        </g>
        <g clip-path="url(#E)">
          <path
            transform="rotate(-54.53 1000.297 660.456)"
            fill="url(#F)"
            d="M982.43 646.93h35.87v27.08h-35.87z"
          />
        </g>
        <g clip-path="url(#G)">
          <path
            transform="rotate(-54.53 1017.348 660.464)"
            fill="url(#H)"
            d="M999.59 647.07h35.67v26.8h-35.67z"
          />
        </g>
        <g clip-path="url(#I)">
          <path
            transform="rotate(-54.53 1044.013 666.262)"
            fill="url(#J)"
            d="M1023.55 646.26h41.09v40.02h-41.09z"
          />
        </g>
      </g>
    </g>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-logo',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },

    props: {
      isSimple: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
