import { backgroundDefault } from './helper';
import sliderSlide from './sliderSlide';

export default {
  name: 'mbst-slider',
  aliasName: 'Slider',
  properties: {
    backendname: 'slider',
    autoplay: false,
    autoplayDelay: 2500,
    navControls: ['dots'],
    effect: 'slide',
    controlColor: "grey",
    // fullscreen: false,
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  actions: null,
  css: {
    all: [
      {
        selector: '.mbst-slider',
        rules: {
          height: '300px'
        }
      }
    ]
    // ios: [
    //   {
    //     selector: '.mbst-slider',
    //     rules: []
    //   }
    // ],
    // android: [
    //   {
    //     selector: '.mbst-slider',
    //     rules: []
    //   }
    // ]
  },
  items: [
    sliderSlide,
    sliderSlide,
  ]
}
