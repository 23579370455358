import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  isLoadingPage: false,
  isOpenedDialog: false,
  $dComponentName: null,
  $dData: null,
  rawData: {},
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
