import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.text',
    label: 'Text'
  }),
  visibilityProps,
  loopControlProps
]

const view = [
  {
    selector: '.mbst-link',
    controls: [
      typographyProps,
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width'
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height'
        }
      }),
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps
    ]
  }
]

const actions = [true]

export default {
  properties,
  view,
  actions
}
