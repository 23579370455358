import { backgroundDefault } from './helper';

export default {
  name: 'mbst-screen',
  aliasName: 'Screen',
  properties: {
    backendname: 'Screen',
    name: 'New screen',
    leftpanel: false,
    leftpanelBehavior: 'default',
    rightpanel: false,
    rightpanelBehavior: 'default'
  },
  actions: null,
  css: {
    ios: [
      {
        //selector: '.screen__content',
        selector: '',
        rules: [
          ...backgroundDefault,
          { directive: 'font-size', value: '16px' },
        ]
      }
    ],
    android: [
      {
        //selector: '.screen__content',
        selector: '',
        rules: [
          ...backgroundDefault,
        ]
      }
    ]
  },
}
