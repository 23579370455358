var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShown
    ? _c(
        "div",
        { class: _vm.b() },
        [
          _c(
            "button",
            {
              class: _vm.b("button-close"),
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_c("IconClose", { attrs: { width: "10" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.b("columns") }, [
            _vm.isApple ? _c("div", { class: _vm.b("fake-button") }) : _vm._e(),
            _vm._v(" "),
            _c("ol", [
              _c("li", [_vm._v(_vm._s(_vm.$t("WBotsInstructions.a20")))]),
              _vm._v(" "),
              _c("li", [_vm._v(_vm._s(_vm.$t("WBotsInstructions.a21")))]),
              _vm._v(" "),
              _c("li", [_vm._v(_vm._s(_vm.$t("WBotsInstructions.a22")))]),
              _vm._v(" "),
              _c("li", [_vm._v(_vm._s(_vm.$t("WBotsInstructions.a23")))]),
            ]),
          ]),
          _vm._v(" "),
          _vm.hint
            ? _c("div", { class: _vm.b("hint") }, [
                _vm._v("\n    " + _vm._s(_vm.hintMessage) + "\n  "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ServiceMessage",
            _vm._b(
              { class: _vm.b("service-message") },
              "ServiceMessage",
              _vm.serviceMessageData,
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }