<template>
  <q-input
    v-model.number="model"
    :label="data.label"
    clearable
    clear-icon="close"
    type="number"
    min="0"
    step="1"
    outlined dense
  ></q-input>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'hg-ui-input-number',
    props: {
      data: {
        type: Object,
      },
    },
    data: function() {
      return {
        model: this.data.value || null
      }
    },
    watch: {
      model(value) {
        if (this.data.callback) {
          this.data.callback(value);
        } else {
          const option = (_.isNil(value) || value === '') ? null : {
            collection: this.data.collection,
            generateHashtag: this.data.generateHashtag,
            label: `Index [${value}]`,
            value,
          }
          this.$emit('input', option);
        }
      }
    }
  }
</script>
