export default {
  uuid: null,
  type: 'base',
  name: 'w-bots',
  aliasName: 'WBots',
  iconPreview: {
    component: 'IconComponentWBots',
  },
  properties: {
    bots: ['vk', 'viber'],
  },
  actions: {},
};
