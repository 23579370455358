var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 121.38 128",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            fill: "#665cac",
            d: "M107.68 12.48C104.5 9.56 91.64.23 63 .09c0 0-33.77-2-50.23 13.06C3.63 22.32.4 35.72.08 52.36s-.79 47.77 29.25 56.22v12.89s-.19 5.23 3.25 6.28c4.15 1.29 6.59-2.67 10.56-7 2.17-2.34 5.19-5.8 7.46-8.43 20.53 1.73 36.34-2.23 38.13-2.81 4.14-1.34 27.62-4.36 31.42-35.5 3.94-32.01-1.92-52.37-12.47-61.53z",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { fill: "#fff" } }, [
          _c("path", {
            attrs: {
              d: "M74.8 53.27a1.62 1.62 0 0 1-1.62-1.54c-.26-5.5-2.85-8.19-8.15-8.47A1.62 1.62 0 0 1 65.2 40c7 .37 10.87 4.37 11.22 11.55a1.63 1.63 0 0 1-1.54 1.7z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M83.1 56.05a1.62 1.62 0 0 1-1.58-1.65c.12-5.54-1.46-10-4.84-13.7s-8-5.69-14.11-6.13A1.62 1.62 0 0 1 61 32.84a1.63 1.63 0 0 1 1.73-1.5c6.89.49 12.36 2.9 16.28 7.17a22 22 0 0 1 5.69 16 1.62 1.62 0 0 1-1.6 1.54z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M91.61 59.38A1.62 1.62 0 0 1 90 57.77c-.08-9.76-3-17.53-8.72-23.12s-13-8.43-21.48-8.49a1.62 1.62 0 1 1 0-3.23c9.4.06 17.38 3.22 23.73 9.41s9.61 14.75 9.69 25.4a1.63 1.63 0 0 1-1.61 1.64zM65.44 73.33a4.39 4.39 0 0 0 3.5-1.33l2.38-3a5.25 5.25 0 0 1 6.68-.91c1.52.87 4.24 2.54 5.93 3.78s5.52 4.42 5.52 4.42a4.58 4.58 0 0 1 1 6 24.68 24.68 0 0 1-5 6.12 9.84 9.84 0 0 1-5 2.44 2.71 2.71 0 0 1-.73 0 6.89 6.89 0 0 1-2.15-.33l-.06-.07c-2.55-.71-6.8-2.52-13.89-6.42a82.7 82.7 0 0 1-11.66-7.7 62.64 62.64 0 0 1-5.24-4.65l-.17-.17-.18-.18-.17-.17-.17-.17a62.7 62.7 0 0 1-4.65-5.25 82.41 82.41 0 0 1-7.7-11.66c-3.9-7.08-5.71-11.33-6.42-13.88l-.07-.06a6.11 6.11 0 0 1-.33-2.15 2.71 2.71 0 0 1 0-.73 9.83 9.83 0 0 1 2.44-5.06 24.55 24.55 0 0 1 6.13-5c2.31-1.19 4.5-.79 6 1 0 0 3.1 3.71 4.42 5.51a70.35 70.35 0 0 1 3.75 5.99 5.28 5.28 0 0 1-.92 6.67l-3 2.38a4.42 4.42 0 0 0-1.33 3.49s4.46 16.85 21.09 21.09z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }