import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

const properties = [

  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),

  makeControlProps('select', {
    pathValue: 'properties.navControls',
    label: 'Navigation controls',
    options: [
      {label: 'No', value: []},
      {label: 'Dots', value: ['dots']},
      {label: 'Arrows', value: ['arrows']},
      {label: 'Dots and Arrows', value: ['dots', 'arrows']},
    ]
  }),

  makeControlProps('select', {
    pathValue: 'properties.effect',
    label: 'Transition effect',
    options: [
      {label: 'None', value: ''},
      {label: 'Slide', value: 'slide'},
      {label: 'Fade', value: 'fade'},
      {label: 'Scale', value: 'scale'},
    ]
  }),

  makeControlProps('qcolor', {
    pathValue: 'properties.controlColor',
    label: 'Controls color',
  }),

  makeControlProps('select', {
    pathValue: 'properties.autoplay',
    label: 'Autoplay',
    options: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ],
    subcontrols: {
      'true': [
        makeControlProps('input', {
          pathValue: 'properties.autoplayDelay',
          type: 'number',
          label: 'Autoplay delay (milliseconds)',
          min: 250,
          placeholder: 2500,
        }),
      ]
    }
  }),

  // makeControlProps('select', {
  //   pathValue: 'properties.fullscreen',
  //   label: 'Fullscreen',
  //   options: [
  //     {label: 'Yes', value: true},
  //     {label: 'No', value: false},
  //   ]
  // }),

  visibilityProps,
  loopControlProps,

  makeControlProps('items', {
    type: 'collapse',
    label: 'Slider items',
  }),
]

const view = [
  {
    selector: '.mbst-slider',
    controls: [
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width'
        },
        maxWidth: {
          label: 'Max. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-width'
        },
        minWidth: {
          label: 'Min. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-width'
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height'
        },
        maxHeight: {
          label: 'Max. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-height'
        },
        minHeight: {
          label: 'Min. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-height'
        },
      }),
      makeControlProps('spacing', { label: 'Spacing' }),
    ]
  },
]

const actions = []

export default {
  properties,
  view,
  actions
}
