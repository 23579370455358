import _ from 'lodash';
import botOptionsSchema from 'general/components/widgetComponents/schemas/botOptionsSchema';

const dict = require('general/lang/WidgetEditorPropertyPane.json');
const $t = (prop, def) => {
  if (window.MOBSTEDAPP && window.MOBSTEDAPP.lang) {
    return dict[window.MOBSTEDAPP.lang]['w-bots'][prop] || def || prop;
  } else {
    return def || prop;
  }
}

const botOptions = _.cloneDeep(botOptionsSchema);
const valueElements = Object.entries(botOptions).map(element => ({
  label: element[1].options.title,
  checked: false,
  disabled: true,
}));
const elements = [
  {
    label: 'Auto',
    checked: true,
    disabled: true,
  },
  ...valueElements,
];

const properties = {
};

export default {
  properties,
};
