'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/triggers/operationsapi",

    defaults: {
        operation: {
            id: null,
            Name: 'NewOperation',
            APIProviderId: null,
            APIKeyId: null,
            APIApplicationId: null,
            APIProviderMethodId: null,
            ParamsValues: {},
            DataTransformations: [],
            ConditionalDataTransformations: [],
            LastModified: null,
            ExtendedParams: []
        },
        operationExt: {
            APIKeyName: null,
            APIProviderMethodName: null,
            APIProviderName: null,
            uuid: null
        }
    },

    initialize: function() {
    }

});