var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 90 90",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M90 79S80 30.333 36.125 30.333V11L0 43.276l36.125 33.455V54.94C59.939 54.94 77.582 57.051 90 79z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }