export default {
  name: 'mbst-radio',
  aliasName: 'Radio Button',
  properties: {
    backendname: 'radio-button',
    label: 'Radio Button',
    labelPosition: 'right',
    selected: false,
    value: '',
    iscached: false,
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
    ],
    android: [
    ]
  },
}
