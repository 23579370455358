var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b({ isDisabled: _vm.disabled }) },
    [
      _c("toggle-button", {
        attrs: {
          sync: _vm.sync,
          speed: _vm.speed,
          disabled: _vm.disabled,
          color: _vm.color,
          cssColors: _vm.cssColors,
          labels: _vm.labels,
          switchColor: _vm.switchColor,
          width: _vm.width,
          height: _vm.height,
          name: _vm.name,
        },
        model: {
          value: _vm.isChecked,
          callback: function ($$v) {
            _vm.isChecked = $$v
          },
          expression: "isChecked",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }