import { backgroundDefault } from './helper';

export default {
  name: 'mbst-text-area',
  aliasName: 'Text Area',
  properties: {
    backendname: 'textarea',
    placeholder: 'My Text Area',
    rows: '3',
    value: '',
    defaultvalue: '',
    iscached: false,
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-text-area',
        rules: [
          { directive: 'background-color', value: '#efeff4' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-text-area',
        rules: [
          { directive: 'background-color', value: '#efeff4' },
          ...backgroundDefault,
        ]
      }
    ]
  },
}
