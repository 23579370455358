var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.bots
      ? _c(
          "div",
          [
            _vm._l(_vm.bots, function (bot, ind) {
              return _c("bot-item", {
                key: ind,
                attrs: {
                  bot: bot,
                  "app-id": _vm.appId,
                  "default-icon": _vm.appIcon,
                  http: _vm.http,
                },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.comingSoon, function (item, ind) {
              return _c(
                "div",
                {
                  key: "c-" + ind,
                  staticClass: "b-settings-box b-bot-settings",
                },
                [
                  _c("div", { staticClass: "b-settings-box__header" }, [
                    _vm._v(_vm._s(item)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "b-settings-box__body" }, [
                    _vm._v(_vm._s(_vm.lang("Coming soon"))),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      : _c("div", [_vm._v(_vm._s(_vm.lang("Loading data...")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }