var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _vm.elements.length > 3
      ? _c("div", { class: _vm.b("controls") }, [
          _c(
            "button",
            {
              staticClass: "btn btn-xs btn-success",
              class: _vm.b("button-add"),
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addItem.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-plus mr5" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("WidgetSettingsTypes.buttonAdd"))),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.elements.length
      ? _c(
          "div",
          { class: _vm.b("list") },
          [
            _vm._l(_vm.elements, function (element) {
              return [
                element
                  ? _c(
                      "div",
                      {
                        key: element.id,
                        class: _vm.b("item"),
                        attrs: {
                          "data-tour": element.attributes.json_data.name,
                        },
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.b("item-card") },
                          [
                            _c("WidgetSettingsTypesItem", {
                              attrs: {
                                model: element,
                                editing: element.isEditing || false,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.b("item-scenarios") },
                          [
                            _c("WidgetSettingsScenarios", {
                              attrs: { "widget-type-id": element.id },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.elements.length
      ? _c("div", { class: _vm.b("message-list-is-empty") }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("WidgetSettingsTypes.messageCompositeListIsEmpty")
              ) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "q-pb-lg", class: _vm.b("controls") }, [
      _c(
        "button",
        {
          staticClass: "btn btn-xs btn-success",
          class: _vm.b("button-add"),
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.addItem.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "fa fa-plus mr5" }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("WidgetSettingsTypes.buttonAdd")))]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }