import { loopControlProps, makeControlProps, visibilityProps } from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),

  makeControlProps('textarea', {
    pathValue: 'properties.value',
    label: 'HTML code',
    highlight: 'html'
  }),

  makeControlProps('textarea', {
    pathValue: 'properties.script',
    label: 'Javascript code',
    highlight: 'js',
    hint: "You can load any custom library or paste javascript code here"
  }),
  visibilityProps,
  loopControlProps
];

const view = [
];

const actions = [true]

export default {
  properties,
  view,
  actions
}
