import {
  makeControlProps, loopControlProps, sizingProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

import Vue from 'vue';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  visibilityProps,
  loopControlProps,
  makeControlProps('items', {
    type: 'collapse',
    label: 'Items',
  }),
]

const view = [
  {
    selector: '.mbst-list',

    controls: [
      typographyProps,
      sizingProps,
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      backgroundProps,
      makeControlProps('items', {
        type: 'collapse',
        label: 'Items',
      })
    ]
  }
]

const actions = []

export default {
  properties,
  view,
  actions
}
