import { loopControlProps, makeControlProps, visibilityProps } from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('options', {
    pathValue: 'properties.options',
    label: 'Options'
  }),
  visibilityProps,
  loopControlProps
];

const view = [

];

const actions = [];

export default {
  properties,
  view,
  actions
}
