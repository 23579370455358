<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 90 90"
    :width="width"
    :height="height"
  >
    <path
      d="M90 79S80 30.333 36.125 30.333V11L0 43.276l36.125 33.455V54.94C59.939 54.94 77.582 57.051 90 79z"
    />
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-back',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
