import { loopControlProps, makeControlProps, visibilityProps } from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('uploadImage', {
    pathValue: 'properties.image',
    label: 'Source image'
  }),
  visibilityProps,
  loopControlProps
]

const view = [
  {
    selector: '.mbst-image',
    controls: [
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width'
        },
        maxWidth: {
          label: 'Max. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-width'
        },
        minWidth: {
          label: 'Min. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-width'
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height'
        },
        maxHeight: {
          label: 'Max. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-height'
        },
        minHeight: {
          label: 'Min. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-height'
        },
      }),
      {
        controlName: 'v-control-select',
        label: 'Display',
        pathValue: 'display',
        options: [{label: 'Visible', value: ''}, {label: 'None', value: 'none'}, { label: 'Inline', value: 'inline' }],
      },
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      makeControlProps('background', {
        label: 'Background',
        color: {
          label: 'Color',
          pathValue: 'background-color'
        }
      })
    ]
  }
]

const actions = [true]

export default {
  properties,
  view,
  actions
}
