export default {
  uuid: null,
  type: 'base',
  name: 'w-a2hs',
  aliasName: 'WA2hs',
  iconPreview: {
    component: 'IconComponentWA2hs',
    width: 87,
  },
  properties: {
    buttonText: 'Show instruction',
  },
  actions: {},
};
