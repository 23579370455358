export default {
  name: 'mbst-meter-input',
  aliasName: 'Meter Input',
  properties: {
    backendname: 'meter-input',
    value: '',
    defaultvalue: '',
    intCount: 4,
    fractCount: 2,
    buttonShow: false,
    buttonLabel: 'Edit',
    popupCollapse: true,
    editMeterName: 'Hot water',
    editLocation: 'Kitchen',
    editDataHistory: '',
    editDataHistoryLabel: '',
    editDateChecked: '',
    editNewDataLabel: '',
    units: 'm^3^',
    editShowDiff: true,
    editDiffLabel: 'Consumed',
    editSaveButton: 'Save',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: [],
  css: {
    ios: [
      {
        selector: '.mbst-meter-input .digits-container',
        rules: [
          { directive: 'font-size', value: '22px' },
          { directive: 'background', value: '#C8C8E9' },
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-meter-input .digits-container',
        rules: [
          { directive: 'font-size', value: '22px' },
          { directive: 'background', value: '#C8C8E9' },
        ]
      }
    ]
  },
}
