import { copyToClipboard } from 'quasar'

// const copyToClipboard = function (text) {
//   if (navigator.clipboard) {
//     try {
//       navigator.clipboard.writeText(text)
//       return true
//     } catch (err) {
//       return false
//     }
//   }
// }

const constructorMutations = {
  setApplicationId(state, id) {
    state.applicationId = Number(id);
  },
  changeView(state, value) {
    if (value.os) state.os = value.os
    if (value.media) state.media = value.media
  },
  toClipboard (state, value) {
    // ** save screenId info in component. It usefull for set syncing while pasting on any other screen
    if (typeof value === 'object') value.screenId = state.screen.id

    if (Array.isArray(value) || typeof value === 'object') {
      value = JSON.stringify(value)
    }
    state.clipboard = value
    copyToClipboard(value)
      .then(() => {
        this.$q.notify({ message: 'Copied', type: 'positive' })
      })
      .catch(err => {
        console.log(err)
        // debugger
      })
  },
  saveInterfaceStatus (state, params) {
    let intSettings = this.$q.localStorage.getItem('interface') || {}
    intSettings = Object.assign(intSettings, params)
    this.$q.localStorage.set('interface', intSettings)
  },
  setDevmode (state, { key, value }) {
    state.devmode[key] = value
  }
}

export default {
  ...constructorMutations,
}
