import LoggerStrategy from './LoggerStrategy';
import './RavenInit';

export default class LoggerProdStrategy extends LoggerStrategy {

  constructor() {
    super();
  }

  /**
   * Dummy
   */
  log() {}

  /**
   * In the Sentry, a "info" level issue is created
   */
  info(message) {
    if (typeof Raven !== 'function') {
      return false;
    }

    Raven.captureMessage(message, {
      level: 'info',
    });
  }

  /**
   * In the Sentry, a "warn" level issue is created
   */
  warn(message) {
    if (typeof Raven !== 'function') {
      return false;
    }

    Raven.captureMessage(message, {
      level: 'warning',
    });
  }

  /**
   * In the Sentry, a "error" level issue is created
   */
  error(message) {
    if (typeof Raven !== 'function') {
      return false;
    }

    Raven.captureMessage(message, {
      level: 'error',
    });
  }

  /**
   * In the Sentry, a "error" level issue is created
   * contains an exception and stacktrace
   */
  exception(error, data = null) {
    if (typeof Raven !== 'function') {
      return false;
    }

    Raven.captureException(error);
  }

}
