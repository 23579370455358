var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b() },
    [
      _vm.editorMode ? _c("div", { class: _vm.b("mask") }) : _vm._e(),
      _vm._v(" "),
      !_vm.editorMode && _vm.instruction0Shown
        ? _c("WBotsInstructions0", { attrs: { "editor-mode": _vm.editorMode } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.editorMode && _vm.instruction1Shown
        ? _c("WBotsInstructions1", { attrs: { "editor-mode": _vm.editorMode } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.editorMode && _vm.instruction2Shown
        ? _c("WBotsInstructions2", { attrs: { "editor-mode": _vm.editorMode } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.editorMode && _vm.instruction3Shown
        ? _c("WBotsInstructions3", { attrs: { "editor-mode": _vm.editorMode } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.editorMode && _vm.isCodeShown
        ? _c("WBotsCode", { attrs: { "editor-mode": _vm.editorMode } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("list") },
        [
          _vm._l(_vm.botList, function (bot) {
            return [
              bot && !bot.hidden
                ? _c("WBotsItem", {
                    key: bot.code,
                    ref: `item${bot.code}`,
                    refInFor: true,
                    class: [
                      _vm.b("item"),
                      {
                        "is-hide":
                          _vm.currentBot &&
                          bot.code !== _vm.currentBot.code &&
                          _vm.isCodeShown,
                      },
                    ],
                    attrs: {
                      "editor-mode": _vm.editorMode,
                      theme: "default",
                      bot: bot,
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }