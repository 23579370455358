import merge from 'lodash/merge'

const DefaultProperties = {
  note: '',
  properties: {
    visibility: {
      conditions: [],
      hidden: false,
    }
  },
  actions: [],
  css: {
    all: []
  }
}

export const ExtendDefault = (props) => {
  return merge({}, DefaultProperties, props)
}

export default DefaultProperties
