import {
  makeControlProps, loopControlProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname',
  }),
  makeControlProps('textarea', {
    pathValue: 'properties.text',
    label: 'Text',
  }),
  makeControlProps('select', {
    pathValue: 'properties.shadow',
    label: 'Shadow',
    options: [
      {label: '-', value: ''},
      {label: '1', value: '1'},
      {label: '2', value: '2'},
      {label: '3', value: '3'},
      {label: '4', value: '4'},
      {label: '5', value: '5'},
      {label: '6', value: '6'},
      {label: '7', value: '7'},
      {label: '8', value: '8'},
      {label: '9', value: '9'},
      {label: '10', value: '10'},
    ],
    subcontrols: [
      makeControlProps('select', {
        pathValue: 'properties.shadowPosition',
        label: 'Shadow position',
        options: [
          {label: 'Down', value: ''},
          {label: 'Up', value: 'up-'},
        ]
      })
    ]
  }),
  visibilityProps,
  loopControlProps,
];

const view = [
  {
    selector: '.mbst-text',
    controls: [
      typographyProps,
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width',
        },
        maxWidth: {
          label: 'Max. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-width',
        },
        minWidth: {
          label: 'Min. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-width',
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height',
        },
        maxHeight: {
          label: 'Max. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-height',
        },
        minHeight: {
          label: 'Min. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-height',
        },
      }),
      makeControlProps('container', {
        subcontrols: [
          makeControlProps('select', {
            label: 'Positioning',
            pathValue: 'position',
            options: [{label: 'Defailt', value: ''}, {label: 'Absolute', value: 'absolute'}],
          })
        ]
      }),
      {
        controlName: 'v-control-select',
        label: 'Display',
        pathValue: 'display',
        options: [{label: 'Visible', value: ''}, {label: 'None', value: 'none'}, { label: 'Inline', value: 'inline' }],
      },
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
    ],
  },
];

const actions = [true];

export default {
  properties,
  view,
  actions,
  events: ['click', 'reactive']
};
