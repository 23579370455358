'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Views
var ConditionRulesView = require('views/conditions/ConditionRulesView');

// Templates
var _condition = require('text!bcTemplates/conditions/condition.html');
var _conditionInput = require('text!bcTemplates/conditions/controls/input.html');

// Helper
var PubSub = require('helper/pubsub');


module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'b-constructor__row',

    events: {
        'click [data-js-action="deleteCondition"]': 'deleteCondition',
    },

    initialize: function(options) {
        this.mainTpl = _.template(_condition);
        this.controlsTpl = {
            'input': _.template(_conditionInput),
            // seletc2, datepicker, timepicker, etc.
        };
        this.conditionsView = options.conditionsView;
    },

    render: function() {
        var t = this;
        t.$el.empty();

        t.model.set('fieldType', 'string'); // необходимо чтобы сразу из базы было понятно какого типа данные

        t.conditionRulesView = new ConditionRulesView({
            model: t.model
        });

        var conditionTpl = t.mainTpl({
            data: t.model.toJSON(),
            property: { // field
                selector: 'input', // OR select
                name: 'dataConditionsField',
                chooseDataType: false
            },
            dataType: 'string',
            controlsTpl: t.controlsTpl
        });
        t.$el.append(conditionTpl);

        t.conditionRulesView.setElement(t.$el.find('.b-constructor__rules'));
        t.conditionRulesView.render();

        return t.$el;
    },

    close: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    deleteCondition: function(e) {
        e.preventDefault();

        if (this.model.collection.length > 1) {
            this.model.collection.remove(this.model);
            this.undelegateEvents();
            this.$el.removeData().unbind();
            this.remove();
        } else {
            this.model.set({
                field: '',
                operation: '==',
                value: ''
            })
            this.$el.replaceWith(this.render());
        }

        this.conditionsView.deleteLabels(); // ConditionsView

    }

});