import screens from './screens';
import screen from './screen';
import paletteComponents from './paletteComponents';
import settingsPanel from './settingsPanel';

export default {
  screens,
  screen,
  paletteComponents,
  settingsPanel,
}
