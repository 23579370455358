/* global grit */

const NOTICE_LEVEL_INFO = 'NOTICE_LEVEL_INFO';
const NOTICE_LEVEL_SUCCESS = 'NOTICE_LEVEL_SUCCESS';
const NOTICE_LEVEL_WARNING = 'NOTICE_LEVEL_WARNING';
const NOTICE_LEVEL_ERROR = 'NOTICE_LEVEL_ERROR';
const levelList = {
  NOTICE_LEVEL_SUCCESS,
  NOTICE_LEVEL_WARNING,
  NOTICE_LEVEL_ERROR,
};
const levelAliases = {
  [NOTICE_LEVEL_INFO]: 'info',
  [NOTICE_LEVEL_SUCCESS]: 'success',
  [NOTICE_LEVEL_WARNING]: 'warning',
  [NOTICE_LEVEL_ERROR]: 'danger',
};

export default {
  data: () => ({
    ...levelList,
  }),

  methods: {
    /**
     * @param {string} text
     * @param {string} level
     * @returns {void}
     */
    notice(text, level = NOTICE_LEVEL_INFO) {
      if (!(level in levelList)) {
        console.warn('Parameter "level" is invalid!');
        return;
      }
      const levelAlias = levelAliases[level];
      grit(text, `growl-${levelAlias}`);
      console.log(`${levelAlias}: ${text}`);
    },
  },
};
