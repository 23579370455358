/* global document, $ */

import axios from 'axios';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import logger from 'general/logger';
import { replaceSnippets } from 'general/utils/utils';
import { BASE_URL } from 'general/env';

const AUTH_KEY = '__mbst_authdata';

const lang = (key) => {
  try {
    return window.lang(key);
  } catch (error) {
    return key;
  }
};

/**
 * Фабрика сервиса авторизации
 *
 * @param {Object} config
 * @returns {Object}
 * @constructor
 */
const factoryAuth = (config = {}) => {
  const defaultConfig = {
    tenant: '',
    server: '',
    applicationId: null,
    objectId: null,
    typeService: 'bc', // bc; widgets
  };
  const _config = {
    ...defaultConfig,
    ...config,
  };
  let host = '';

  if (_config.tenant && _config.server) {
    host = replaceSnippets(BASE_URL, {
      TENANT: _config.tenant,
      SERVER: _config.server,
    });
  }

  return {

    async authorize(params = {}) {
      const { typeService, applicationId, objectId } = _config;
      let extParams = {};
      if (typeService == 'widgets') {
        extParams = { applicationId, objectId };
      }
      const url = typeService == 'bc' ? `${host}/api/v8/authorize` : `${host}/api/v8/${typeService}/authorize`;
      let auth = null;
      try {
        const query = {
          params: { ...extParams, ...params },
        };
        const { data: { data: { attributes } } } = await axios.get(url, query);

        auth = {
          access_token: attributes.accessToken,
          refresh_token: attributes.refreshToken,
        };
        this.setAuthData(auth);
      } catch(e) {
        console.log(e);
        console.log(e.response);
      }

      return auth;
    },

    /**
     * Получение авторизационных данных из cookies
     * @param {null|string} key
     * @returns {Object|string|null}
     */
    getAuthData(key = null) {
      try {
        const auth = Cookie.getJSON(AUTH_KEY);
        return key ? auth[key] : auth;
      } catch (e) {
        return null;
      }
    },

    /**
     * Получение Access Token
     * @returns {string|null}
     */
    getAccessToken() {
      return this.getAuthData('accessToken');
    },

    /**
     * Получение Refresh Token
     * @returns {string|null}
     */
    getRefreshToken() {
      return this.getAuthData('refreshToken');
    },

    isRememberMe() {
      return this.getAuthData('rememberMe');
    },

    /**
     * Получение даты протухания токена
     * @param typeToken {string} accessToken OR refreshToken
     * @returns {Date|null}
     */
    getTokenExpirationDate(typeToken = 'accessToken') {
      try {
        const encodedToken = this.getAuthData(typeToken);
        if (!encodedToken) {
          return null;
        }
        const { exp } = jwtDecode(encodedToken);
        if (!exp) {
          return null;
        }
        let expDate = new Date(0);
        expDate.setUTCSeconds(exp);

        return expDate;
      } catch (e) {
        return null;
      }
    },

    /**
     * Установка авторизационных данных в cookies
     * @param data {Object}
     */
    setAuthData(data) {
      const oldAuthData = this.getAuthData();
      Cookie.set(AUTH_KEY, {
        ...oldAuthData,
        ...{
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
        },
      });
    },

    /**
     * Установка авторизационых заголовков для jQuery
     * Backbone использует тот же jQuery, поэтому достаточно для него установить
     * @param token
     */
    setAuthHeaders(token) {
      if ($) {
        $.ajaxSetup({ headers: { 'Authorization': `Bearer ${token}` } });
      }
    },

    logout() {
      Cookie.remove(AUTH_KEY);
      window.location = `${host}/users/logout`;
    },

    async refreshToken() {
      const { typeService } = _config;
      const url = typeService == 'bc' ? `${host}/api/v8/refresh` : `${host}/api/v8/${typeService}/refresh`;
      const refreshToken = this.getRefreshToken();
      return axios.get(url, {
        params: { refresh_token: refreshToken },
      });
    },

    init() {
      if ($) {
        $.ajaxSetup({
          error: ({ status }) => {
            if (status === 403) {
              this.alertLogout(lang('Access is denied. Please, authorize again.'));
            }
          },
        });
      }
      const accessToken = this.getAccessToken();
      if (accessToken) {
        this.setAuthHeaders(accessToken);
      }
    },

    getErrorData(data) {
      let error = {};
      if (data.errors) {
        error.code = data.errors[0].code || null;
        error.detail = data.errors[0].detail || null;
      } else {
        error.code = data.ErrorNNumber || null;
        error.detail = data.ErrorText || null;
      }
      return error;
    },

    alertLogout(msg) {
      alert(msg);
      this.logout();
    },

  };
};

export default factoryAuth;
