import ControlInput from './ControlInput';
import ControlTextarea from './ControlTextarea';
import ControlCheckbox from './ControlCheckbox';
import ControlCheckboxList from './ControlCheckboxList';
import ControlSelect from './ControlSelect';
import ControlFile from './ControlFile';
import ControlSwitch from './ControlSwitch';
import ControlCounter from './ControlCounter';
import ControlColorPicker from './ControlColorPicker';

export default {
  ControlInput,
  ControlTextarea,
  ControlCheckbox,
  ControlCheckboxList,
  ControlSelect,
  ControlFile,
  ControlSwitch,
  ControlCounter,
  ControlColorPicker,
};
