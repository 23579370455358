var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _c("div", { class: _vm.b("workspace") }, [
      _c(
        "div",
        { class: _vm.b("component-palette-container") },
        [
          _c("div", { class: _vm.b("controls") }, [
            _c(
              "button",
              {
                class: _vm.b("button-back", "btn btn-default btn-sm"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.back.apply(null, arguments)
                  },
                },
              },
              [
                _c("q-icon", { attrs: { name: "far fa-long-arrow-alt-left" } }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("WidgetEditor.buttonBack")) +
                      "\n          "
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("WidgetEditorComponentPalette"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("demo-modal-container") },
        [
          _c("div", { class: _vm.b("controls") }, [
            _c(
              "button",
              {
                class: _vm.b("button-edit-modal", "btn btn-xs btn-info"),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.setEditingModal.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("WidgetEditor.buttonEditModal")) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("SmartphoneWrapper", [
            _c(
              "div",
              {
                class: _vm.b("widget-editor-demo-modal-wrap", {
                  isEditingModal: _vm.isEditingModal,
                }),
              },
              [
                _c("WidgetEditorDemoModal", {
                  attrs: { "json-data": _vm.jsonData },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("property-pane-container") },
        [
          _c(
            "div",
            { class: _vm.b("widget-type-properties") },
            [
              _c("ControlInput", {
                class: _vm.b("field"),
                attrs: {
                  direction: "horizontal",
                  "label-before": _vm.$t("WidgetEditor.props.name.label"),
                  "hint-text": _vm.$t("WidgetEditor.props.name.hint"),
                },
                model: {
                  value: _vm.jsonData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.jsonData, "name", $$v)
                  },
                  expression: "jsonData.name",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.b("field") },
                [
                  _c("ControlInput", {
                    attrs: {
                      direction: "horizontal",
                      "label-before": _vm.$t("WidgetEditor.props.code.label"),
                      "hint-text": _vm.$t("WidgetEditor.props.code.hint"),
                    },
                    model: {
                      value: _vm.jsonData.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.jsonData, "code", $$v)
                      },
                      expression: "jsonData.code",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { class: _vm.b("field-code-box") }, [
                    _vm._v(
                      "\n            API: mobsted.widgets." +
                        _vm._s(_vm.jsonData.code) +
                        ".show()\n          "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEditingModal
            ? _c("div", { class: _vm.b("demo-modal-properties") }, [
                _c("div", { class: _vm.b("demo-modal-properties-header") }, [
                  _c(
                    "button",
                    {
                      class: _vm.b(
                        "demo-modal-properties-button-close",
                        "btn btn-default btn-xs"
                      ),
                      attrs: { title: "Save and close modal properties" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetEditingModal.apply(null, arguments)
                        },
                      },
                    },
                    [_c("IconClose", { attrs: { width: "12" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { class: _vm.b("demo-modal-properties-title") }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("WidgetEditor.demoModalPropertiesTitle")
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.b("demo-modal-properties-body") },
                  [
                    _c("ControlCheckbox", {
                      class: _vm.b("field"),
                      attrs: {
                        label: _vm.$t(
                          "WidgetEditor.props.headerShownButtonClose.label"
                        ),
                      },
                      model: {
                        value: _vm.jsonData.modal.header.shownButtonClose,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.jsonData.modal.header,
                            "shownButtonClose",
                            $$v
                          )
                        },
                        expression: "jsonData.modal.header.shownButtonClose",
                      },
                    }),
                    _vm._v(" "),
                    _c("ControlCheckbox", {
                      class: _vm.b("field"),
                      attrs: {
                        label: _vm.$t("WidgetEditor.props.headerShown.label"),
                      },
                      model: {
                        value: _vm.jsonData.modal.header.shown,
                        callback: function ($$v) {
                          _vm.$set(_vm.jsonData.modal.header, "shown", $$v)
                        },
                        expression: "jsonData.modal.header.shown",
                      },
                    }),
                    _vm._v(" "),
                    _vm.jsonData.modal.header.shown
                      ? _c("ControlInput", {
                          class: _vm.b("field"),
                          attrs: {
                            direction: "horizontal",
                            "label-before": _vm.$t(
                              "WidgetEditor.props.headerTitle.label"
                            ),
                            "hint-text": _vm.$t(
                              "WidgetEditor.props.headerTitle.hint"
                            ),
                          },
                          model: {
                            value: _vm.jsonData.modal.header.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.jsonData.modal.header, "title", $$v)
                            },
                            expression: "jsonData.modal.header.title",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ControlCheckbox", {
                      class: _vm.b("field"),
                      attrs: {
                        label: _vm.$t("WidgetEditor.props.footerShown.label"),
                      },
                      model: {
                        value: _vm.jsonData.modal.footer.shown,
                        callback: function ($$v) {
                          _vm.$set(_vm.jsonData.modal.footer, "shown", $$v)
                        },
                        expression: "jsonData.modal.footer.shown",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c("WidgetEditorPropertyPane", { class: _vm.b("property-pane") }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }