export default {
  name: 'mbst-uploadfile',
  aliasName: 'Upload File', // Не менять! От него зависит выбор компонентов для CreateEvent Files
  properties: {
    backendname: 'uploadfile',
    text: 'Upload file',
    typeButton: 'default',
    visibility: {
      conditions: [],
      hidden: false,
    },
    sizes: {
      //in KB
      minSize: 100,
      maxSize: 10240, //10 mb
    },
    multiple: {
      enabled: true,
      maximum: 10,
    },
    autoCompress: {
      enabled: true,
      size: 2048,
      maxWidth: 1024,
      maxHeight: 1024,
      checkOrientation: true,
      quality: 0.8, //The quality of the output image. It must be a number between 0 and 1. Be careful to use 1 as it may make the size of the output image become larger.
      convertSize: 5000, //PNG files over this value will be converted to JPEGs. To disable this, just set the value to Infinity.
    },
    source: 'files&camera',
    acceptFiles: {
      mimeTypes: undefined,
      extensions: ['jpg', 'jpeg', 'png', 'gif', 'mov', 'avi', 'mp4', 'docx', 'xlsx', 'pdf', 'csv'],
      // captureFromCameraOnly: false
    },
    listText: 'Selected files',
  },
  // actions: null,
  css: {
    all: [
      {
        selector: '.mbst-upload button',
        rules: {
          'background-color': '#027be3',
          'color': '#FFFFFF'
        },
      },
    ],
  },
}
