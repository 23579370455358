export default {
  name: 'mbst-checkboxes',
  aliasName: 'Checkboxes',
  properties: {
    backendname: 'checkboxes',
    options: [
      { label: 'Label option', value: 'Value option', selected: false, conditions: [] },
    ],
    visibility: {
      conditions: [],
      hidden: false
    },
    value: ''
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-checkboxes .list',
        rules: []
      },
      {
        selector: '.mbst-checkboxes .list-item',
        rules: []
      }
    ],
    android: [
      {
        selector: '.mbst-checkboxes .list',
        rules: []
      },
      {
        selector: '.mbst-checkboxes .list-item',
        rules: []
      }
    ],
  },
};
