/*global google */
let geocoder;
let map;

let gmarkers = [];
let cityCircle = [];

function geo_initialize() {
    geocoder = new google.maps.Geocoder();
    
    let listing_lat = $('.reg-sec #latitude').val(),
        listing_lon = $('.reg-sec #longitude').val();
    
    var mapOptions = {
      flat:false,
      noClear:false,
      zoom: 17,
      scrollwheel: false,
      draggable: true,
      disableDefaultUI:false,
      center: new google.maps.LatLng( listing_lat, listing_lon),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    map = new google.maps.Map(document.getElementById('googleMaps'), mapOptions);
    google.maps.visualRefresh = true;

    set_google_search(map);

    if ((listing_lon === '' && listing_lat === '') || (listing_lon === '0.000000' && listing_lat === '0.000000')) {
        myposition(map);
        listing_lat = position.coords.latitude;
        listing_lon = position.coords.longitude;
    }

    const point = new google.maps.LatLng( listing_lat, listing_lon);
    placeSavedMarker(point);
    
    google.maps.event.addListener(map, 'click', function(event) {
      placeMarker(event.latLng);
    });

}

// ================== MY LOCATION ==================

if(  document.getElementById('geolocation-button') ){
  google.maps.event.addDomListener(document.getElementById('geolocation-button'), 'click', function (e) {
    e.preventDefault();
      console.log('posit');
    myposition(map);
  });  
}

function myposition(map){    
  if(navigator.geolocation){
    navigator.geolocation.getCurrentPosition(showMyPosition,errorCallback,{timeout:10000});
  }
  else
  {
    alert("Geolocation is not supported by this browser.");
  }
}

function errorCallback(){
  alert("The browser couldn't detect your position!");
}

function showMyPosition(pos){
  const MyPoint = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
  map.setCenter(MyPoint);   
  
  placeMarker(MyPoint);
  map.setZoom(17);
}


// ================== END MY LOCATION ==================

$(document.body).on('keyup mouseup', '.reg-sec #range', function() {

  let range_m     = $('.reg-sec #range').val(),
      listing_lat = $('.reg-sec #latitude').val(),
      listing_lon = $('.reg-sec #longitude').val(),
      point       = new google.maps.LatLng( listing_lat, listing_lon);

  rangeArea(range_m, point);
});

function set_google_search(map) {
    let input     = (document.getElementById('google-search')),
        searchBox = new google.maps.places.SearchBox(input);
    
    google.maps.event.addListener(searchBox, 'places_changed', function(event) {
      removeMarkers();
      removeCircle()

      let places  = searchBox.getPlaces(),
          range_m = $('.reg-sec #range').val();
        
      if (places.length == 0) {
        return;
      }
      
      let bounds = new google.maps.LatLngBounds();
      for (let i = 0, place; place = places[i]; i++) {

        placeMarker(place.geometry.location);

        bounds.extend(place.geometry.location);

      }

      rangeArea(range_m, bounds.getCenter());

      map.fitBounds(bounds);
      map.setZoom(17);

    });
    
}

function rangeArea(range_m, rangeCenter) {
  removeCircle();
  let range = new google.maps.Circle({
    strokeColor: "#428BCA",
    strokeOpacity: 0.8,
    strokeWeight: 5,
    fillColor: "#428BCA",
    fillOpacity: 0.35,
    map: map,
    center: rangeCenter,
    radius: parseInt(range_m),
    editable: true
  });
  cityCircle.push(range);

  google.maps.event.addListener(range, 'radius_changed', function() {
    $(".reg-sec #range").val( parseInt(range.getRadius()) );
    stepsSaver({id: $('#id-app').val(), range: parseInt(range.getRadius())});
  });

  google.maps.event.addListener(range, 'center_changed', function() {
    placeMarker(range.getCenter());
  });

}

function placeSavedMarker(location) {
  removeMarkers();removeCircle();

  const range_m = $('.reg-sec #range').val();
  const marker = new google.maps.Marker({
    position: location,
    animation: google.maps.Animation.BOUNCE,
    map: map
  });
    gmarkers.push(marker);

    rangeArea(range_m, location);
    
  // const infowindow = new google.maps.InfoWindow({
  //   content: 'Latitude: ' + location.lat() + '<br>Longitude: ' + location.lng()
  // });
  
    // infowindow.open(map,marker);

}

function placeMarker(location) {
  removeMarkers();
  removeCircle();

  const range_m = $('.reg-sec #range').val();
  const marker = new google.maps.Marker({
    position: location,
    animation: google.maps.Animation.BOUNCE,
    map: map
  });
    gmarkers.push(marker);

    rangeArea(range_m, location);
    
  // const infowindow = new google.maps.InfoWindow({
  //   content: 'Latitude: ' + location.lat() + '<br>Longitude: ' + location.lng()
  // });
  
   // infowindow.open(map,marker);
   $(".reg-sec #latitude").val( location.lat() );
   $(".reg-sec #longitude").val( location.lng() );

   stepsSaver({id: $('#id-app').val(), latitude: location.lat(), longitude: location.lng()});

}

function removeMarkers(){
    for (let i = 0; i < gmarkers.length; i++){
        gmarkers[i].setMap(null);
    }
}
function removeCircle(){
    for (let i = 0; i < cityCircle.length; i++){
        cityCircle[i].setMap(null);
    }
}

module.exports = {
  start () {
    geo_initialize()
  }
}
