<i18n src="general/lang/WidgetSettings.json"></i18n>

<template>
  <div :class="b()" class="bg-grey-1">
    <div :class="b('container')" class="q-pa-lg">

      <q-breadcrumbs class="text-h6 text-weight-regular col-auto- q-mb-lg">
        <template v-slot:separator>
          <q-icon
            size="1.25em"
            name="chevron_right"
            color="primary"
          />
        </template>
        <q-breadcrumbs-el
          label="Widgets"
          :class="{'cursor-pointer': isSystemWidgets}"
          @click="isSystemWidgets = false"
        />
        <q-breadcrumbs-el
          v-if="isSystemWidgets"
          label="System Widgets"
        />
      </q-breadcrumbs>

      <div v-if="!isSystemWidgets">
        <div class="row q-col-gutter-lg">
          <div class="col-xl-3 col-lg-4 col-sm-6">
            <q-card class="full-height cursor-pointer" v-ripple @click="isSystemWidgets = true">
              <q-card-section>
                <div class="text-h6">System Widgets</div>
                <div class="text-body1 text-grey-7">This section manages app saving and push prompts for the applications you build on Mobsted from scratch.</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6">
            <q-card class="full-height cursor-pointer" v-ripple @click="onGoToPwaLessWidgets">
              <q-card-section class="full-height cursor-pointer" v-ripple>
                <div class="text-h6">PWA JS Capsule Widgets</div>
                <div class="text-body1 text-grey-7">This section hosts app saving and push request prompts for the PWA JS Capsule, only.</div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>

      <div v-else>
        <WidgetSettingsTypes v-if="!configurableWidgetType" />
        <WidgetEditor v-if="configurableWidgetType" />
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import logger from 'general/logger';
  import baseMixin from 'general/mixins/baseMixin';
  import storeMixin from './store/storeMixin';
  import WidgetSettingsTypes from './WidgetSettingsTypes';
  import WidgetEditor from './WidgetEditor/WidgetEditor';

  const { mapGetters, mapActions } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-settings',

    mixins: [
      baseMixin,
      storeMixin,
    ],

    components: {
      WidgetSettingsTypes,
      WidgetEditor,
    },

    props: {
      tenant: {
        required: true,
      },
      server: {
        required: true,
      },
      token: {
        required: true,
      },
      applicationId: {
        type: Number,
        required: true,
      },
    },

    data: () => ({
      isSystemWidgets: false,

    }),

    computed: {
      ...mapGetters({
        configurableWidgetType: 'widgetTypes/getConfigurableElement',
      }),
      appId: vm => MOBSTEDAPP.application.id
    },

    async created() {
      this.setTenant(this.tenant);
      this.setServer(this.server);
      this.setApplicationId(this.applicationId);
      await this.fetchTypes();
      await this.initScenariosStore();

      logger.log(
        'WidgetSettings!',
        '$store:', this.$store,
      );
    },

    methods: {
      ...mapActions({
        setTenant: 'setTenant',
        setServer: 'setServer',
        setApplicationId: 'setApplicationId',
        fetchTypes: 'widgetTypes/fetchAll',
        initScenariosStore: 'widgetScenarios/init',
      }),
      onGoToPwaLessWidgets() {
        window.location = `/pwaless/index/${this.appId}`
      }
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-settings
    height 100%
    padding 30px 15px
    margin-x(-10px)
    overflow-y auto

    &__container
      max-width 1600px
      height 100%
      margin-x(auto)
</style>
