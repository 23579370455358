import { backgroundDefault } from './helper';

export default {
  name: 'mbst-link',
  aliasName: 'Link',
  properties: {
    backendname: 'link',
    text: 'My Link',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: [],
  css: {
    ios: [
      {
        selector: '.mbst-link',
        rules: [
          { directive: 'color', value: '#337ab7' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-link',
        rules: [
          { directive: 'color', value: '#337ab7' },
          ...backgroundDefault,
        ]
      }
    ]
  },
}
