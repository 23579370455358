import _ from 'lodash';
import schema from '@/bc/pages/constructor/schemaSettings/index';
import MakeHttp from '@/general/utils/MakeHttp';
const http = MakeHttp();

const state = {
  data: null,
  os: 'all',
  cssScope: 'all',
  tempAction: null,
  operationsList: [],
  objectColumns: [],
  schema
};

const getters = {};

const actions = {
  async getOperations({ commit }) {
    try {
      const { data: data } = await http.get(`/operations/list`);
      commit('setOperationsList', data);
    } catch (e) {
      console.error(e);
    }
  },
  async getObjects({ commit, rootState }) {
    try {
      const { applicationId } = rootState;
      const { data: data } = await http.get(`/object`, {
        applicationId,
        page: 1,
        pageSize: 1,
      });
      commit('setObjectColumns', _.keys(data[0].attributes));
    } catch (e) {
      console.error(e);
    }
  }
};

const mutations = {
  changeOs(state, os) {
    state.os = os;
  },
  changeCssScope(state, cssScope) {
    state.cssScope = cssScope;
  },
  setAction(state, action) {
    state.tempAction = action;
  },
  deleteAction(state) {
    state.tempAction = null;
  },
  updateAction (state, {value, pathValue}) {
    const action = _.cloneDeep(state.tempAction)
    _.set(action, pathValue, value);
    state.tempAction = action
  },
  deleteData(state) {
    state.data = null;
  },
  setData(state, data) {
    state.data = data;
  },
  setOperationsList(state, operations) {
    state.operationsList = operations;
  },
  setObjectColumns(state, columns) {
    state.objectColumns = columns;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
