export default $axios => {
  const resource = '/partners'

  const methods = {
    /**
     * Create sub account for partnership program
     * @param data
     * @param data.name
     * @param data.email
     * @param config {Object} axios config
     * @returns {Promise<any>}
     */
    create: (data, config = {}) => $axios.$post(resource, data, config),

    /**
     * check account enabled for partnership reseller program
     * @param {Object} config - axios config
     * @returns {Promise<any>}
     */
    checkIn: (config = {}) => $axios.$post(resource, { justcheck: 1 }, config),
    
    stat: (data = { mode: 'full' }, config = {}) => $axios.$post(`${resource}stat`, data, config),
  }

  return methods
}
