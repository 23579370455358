import screen from './screen';
import button from './button';
import textInput from './textInput';
// import input from './input';
import meterInput from './meterInput';
import numberInput from './numberInput';
import radioButtons from './radioButtons';
import checkboxes from './checkboxes';
import select from './select';
import textArea from './textArea';
import customHtml from './customHtml';
import text from './text';
import link from './link';
import image from './image';
import divider from './divider';
import row from './row';
import rowItem from './rowItem';
import toolbar from './toolbar';
import toolbarItem from './toolbarItem';
import bottomToolbarItem from './bottomToolbarItem';
import uploadFile from './uploadFile';
import bottomToolbar from './bottomToolbar';
import flexRow from './flexRow';
import flexRowCol from './flexRowCol';
import tabs from './tabs';
import tabsTab from './tabsTab';
import slider from './slider';
import sliderItem from './sliderSlide';
import radioButton from './radioButton';
import checkBox from './checkBox';
import list from './list';
import listItem from './listItem';
import videoPlayer from './videoPlayer';

import flexRowL1 from './flexRowL1';
import flexRowL2 from './flexRowL2';
import flexRowL2x2 from './flexRowL2x2';
import flexRowL3x3 from './flexRowL3x3';

export default {
  [screen.name]: screen,
  [text.name]: text,
  [button.name]: button,
  [textInput.name]: textInput,
  [numberInput.name]: numberInput,
  [radioButtons.name]: radioButtons,
  [checkboxes.name]: checkboxes,
  [select.name]: select,
  [textArea.name]: textArea,
  [meterInput.name]: meterInput,
  [customHtml.name]: customHtml,
  [link.name]: link,
  [image.name]: image,
  [divider.name]: divider,
  [row.name]: row,
  [flexRow.name]: flexRow,
  [flexRowCol.name]: flexRowCol,
  [rowItem.name]: rowItem,
  [uploadFile.name]: uploadFile,
  [toolbar.name]: toolbar,
  [toolbarItem.name]: toolbarItem,
  [bottomToolbar.name]: bottomToolbar,
  [bottomToolbarItem.name]: bottomToolbarItem,
  [tabs.name]: tabs,
  [tabsTab.name]: tabsTab,
  [slider.name]: slider,
  [sliderItem.name]: sliderItem,
  // [input.name]: input,
  [radioButton.name]: radioButton,
  [checkBox.name]: checkBox,
  [list.name]: list,
  [listItem.name]: listItem,
  [videoPlayer.name]: videoPlayer,
}

export const Layouts = {
  'mbst-layout2x2': flexRowL2x2,
  'mbst-layout3x3': flexRowL3x3,
  'mbst-layout1': flexRowL1,
  'mbst-layout2': flexRowL2
}
