'use strict';

// Libraries
import logger from 'general/logger';

const _ = require('underscore');
const Backbone = require('backbone');

// Templates
const _apiProviderMethodsItem = require('text!bcTemplates/apiproviders/apiProviderMethodsItem.html');

module.exports = Backbone.View.extend({

  tagName: 'li',

  events: {
    'click': 'select',
  },

  initialize: function (options) {
    this.tpl = _.template(_apiProviderMethodsItem);
    this.parentView = options.parentView;
    this.triggerOperationSettingsView = options.triggerOperationSettingsView;

    const params = this.model.get('Params');
    let obj = {};

    try {
      obj = JSON && JSON.parse(params) || $.parseJSON(params);

      if (_.isString(this.model.get('Params'))) {
        this.model.set('Params', obj);
      }
    } catch (error) {
      logger.exception(error, params);
    }
  },

  render: function () {
    this.$el.empty();
    this.$el.html(this.tpl(this.model.toJSON()));
  },

  select: function () {
    this.parentView.setActive(this.model.get('id'));
    this.parentView.render();

    this.triggerOperationSettingsView.model.set({
      APIProviderId: this.parentView.apiProviderId,
      APIProviderMethodId: this.model.get('id'),
    });

    this.triggerOperationSettingsView.filterApiKeys(this.parentView.apiProviderId);

    this.triggerOperationSettingsView.executionView.collection
      .reset(this.triggerOperationSettingsView.getDataExecution(this.parentView.apiProviderId, this.model.get('id')));
    this.triggerOperationSettingsView.executionView.render();
  },

});
