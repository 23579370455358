var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b(false, "i-frame i-frame-large clearfix") }, [
    _c("div", { staticClass: "i-frame-camera" }),
    _vm._v(" "),
    _c("div", { staticClass: "i-frame-sensor" }),
    _vm._v(" "),
    _c("div", { staticClass: "i-frame-earpiece" }),
    _vm._v(" "),
    _c("div", { staticClass: "i-frame-inner" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "i-frame-home" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }