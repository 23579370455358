'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/triggers/triggeroperationsapi",

    defaults: {
        id: null,
        TriggerId: null,
        OperationId: null,
        SortOrder: null,
        SuccessOperationId: null,
        FailOperationId: null,
        AlwaysOperationId: null,
        Level: 0
    },

    initialize: function() {
    }

});