var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b({ isActive: _vm.jsonData.active }) }, [
    _c("div", { class: _vm.b("card") }, [
      _c("div", { class: _vm.b("controls") }, [
        _c(
          "button",
          {
            class: _vm.b("button-toggle", { isActive: _vm.jsonData.active }),
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggle.apply(null, arguments)
              },
            },
          },
          [_c("q-icon", { attrs: { name: "fas fa-check" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: _vm.b("button-setup"),
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.setupItem.apply(null, arguments)
              },
            },
          },
          [_c("q-icon", { attrs: { name: "fas fa-cog" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: _vm.b("button-delete"),
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.deleteItem.apply(null, arguments)
              },
            },
          },
          [_c("q-icon", { attrs: { name: "far fa-trash-alt" } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.b("header") }, [
        _c("div", {
          class: _vm.b("name"),
          domProps: { innerHTML: _vm._s(_vm.jsonData.name) },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.b("info") }, [
          _c(
            "div",
            {
              class: _vm.b("count-scenarios"),
              attrs: {
                title: _vm.$t("WidgetSettingsTypesItem.hintCountScenarios"),
              },
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.jsonData.countScenarios) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.b("preview") },
        [
          _c(
            "WidgetModalInnerBase",
            {
              class: _vm.b("widget-modal-inner-base"),
              attrs: {
                "editor-mode": true,
                header: _vm.jsonData.modal.header,
                footer: _vm.jsonData.modal.footer,
              },
            },
            [
              _vm._l(_vm.jsonData.components, function (component) {
                return [
                  component
                    ? _c(
                        component.name,
                        _vm._b(
                          {
                            key: component.uuid,
                            tag: "component",
                            attrs: { "editor-mode": true },
                          },
                          "component",
                          component.properties,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("types") },
      [
        _vm._l(_vm.jsonData.types, function (type, index) {
          return [
            type
              ? _c(
                  "div",
                  {
                    key: index,
                    class: _vm.b("types-item"),
                    attrs: { title: "Widget base type" },
                  },
                  [_vm._v("\n        " + _vm._s(type) + "\n      ")]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }