var Pace = require('refactor/lib/pace');
var HighCharts = require('refactor/lib/highcharts');

module.exports = {

    init: function() {

        this.base_chart_options = {
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: null,
            },
            global: {
                useUTC: false
            },
            plotOptions: {
                line: {
                    turboThreshold: 0,
                    shadow: false,
                    lineWidth: 1,
                    marker: {
                        symbol: 'circle',
                        lineWidth: 2,
                        lineColor: '#fff',
                        radius: 5
                    },
                    incompleteStyle: {
                        'stroke-dasharray': '3,5'
                    }
                },
                column: {
                    stacking: 'normal',
                    groupPadding: 0.1,
                    shadow: false,
                    borderWidth: 0
                },
                series: {
                    cursor: 'pointer',
                    animation: {
                        duration: 300,
                    }
                }
            },
            tooltip: {
                backgroundColor: 'rgb(255, 255, 255)',
                borderWidth: 1,
                borderRadius: 5,
                shadow: false,
                useHTML: true,
            },
            series: [],
        };

        this.line_options = {
            chart: {
                type: 'line',
            },
            tooltip: {
                crosshairs: true
            },
            xAxis: {
                type: 'datetime',
            },
        };

        this.pie_options = {
            chart: {
                type: 'pie',
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)'
            },
            xAxis: {},
        };

        this.bar_options = {
            chart: {
                type: 'column',
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            xAxis: {
                categories: [],
            },
        };

        this.type_chart = 'bar';

        this.bind();

    },

    bind: function() {
        var that = this;

        // загрузка свежих данных с рендером чарта переключаем таб, скрываем таблицу
        $(document.body).on('click', '#chartcolumn', function(e) {

            e.preventDefault();
            that.get_charts_data(that.type_chart);

        });

        // удаляем чарт показываем таблицу переключаем таб
        $(document.body).on('click', '#tablecolumn', function(e) {

            e.preventDefault();

            var chartsBox = $('#charts-box'),
                tableBox = $('#table-box'),
                tab = $(this).closest('.b-tab__item');

            if (that.chart) {
                that.chart.destroy();
                that.chart = undefined;
                chartsBox.removeClass('b-charts--active');
                tableBox.addClass('b-table--active');
                tab.addClass('b-tab__item--active').siblings().removeClass('b-tab__item--active');
                $('.b-setup-data__table').addClass('b-setup-data__table--active').siblings().removeClass('b-setup-data__chart--active');
            }

        });

        // выбор типа чарта и загрузка свежих данных с рендером чарта
        $(document.body).on('click', 'a[data-type-chart]', function(e) {

            e.preventDefault();

            var icon = $(this).children('.fa').attr('class');

            that.type_chart = $(this).attr('data-type-chart');
            $(this).parent().addClass('active').siblings().removeClass('active');
            $('#chartcolumn .fa').attr('class', icon);
            that.get_charts_data(that.type_chart);

        });

    },

    /**
     * получаем данные для чарта
     * @param renderTo в каком теге отрисовать [id]
     */
    get_charts_data: function(type_chart) {
        var that = this,
            btn = $('#chartcolumn'),
            tab = btn.closest('.b-tab__item');

        $.ajax({
            url: '/backend/chartsdata',
            type: 'POST',
            data: {appid: $('#appid').val(), type_chart: type_chart},
            beforeSend: function() {
                btn.addClass('b-spinner--progress').find('.b-spinner').addClass('b-spinner--anim');
                $('.b-smart-filters__item--active').find('.b-smart-filters__last-update i').attr('class','fa fa-refresh fa-spin');
            }
        }).done(function(a,b,c) {
            var obj = JSON && JSON.parse(a) || $.parseJSON(a),
                series = [];

            if (obj.charts.series) {
                series = obj.charts.series;
            } else {
                series = obj.charts;
            }

            that.render_chart('charts-box', series);
            // сбрасываем на ноль счетчик минут на активной плашке смарт-фильтра
            $('.b-smart-filters__item--active').find('div[data-countup]').attr('data-countup', '0');
            // меняем лейбл на плашке активного смарт-фильтра на "прямо сейчас"
            $('.b-smart-filters__item--active').find('.b-smart-filters__last-update').html(lang('just now') + ' <i class="fa fa-refresh">');

        }).fail(function(a,b,c) {
        }).always(function(a,b,c) {
            btn.removeClass('b-spinner--progress').find('.b-spinner').removeClass('b-spinner--anim');
            tab.addClass('b-tab__item--active').siblings().removeClass('b-tab__item--active');
            // отключаем вращение индикатора обновления на плашке активного смарт-фильтра
            $('.b-smart-filters__item--active').find('.b-smart-filters__last-update i').attr('class','fa fa-refresh');
        });
    },

    /**
     * рисуем чарт
     * @param renderTo в каком теге отрисовать [id]
     * @param added_series данные для чарта
     */
    render_chart: function(renderTo, added_series) {

        var that = this,
            options,
            chartsBox = $('#charts-box'),
            tableBox = $('#table-box');

        switch (that.type_chart) {
            case 'line':
                options = that.line_options;
                options = $.extend(true, {}, that.base_chart_options, options);

                /**
                 * пример подачи данных для чарта типа line
                 * @param data: [[значение по оси-Х, значение по оси-Y]]
                 * @param color: '#f32000' задаст кастомный цвет для линии евента
                 */
                options.series = added_series;

                break;

            case 'pie':
                options = that.pie_options;
                options = $.extend(true, {}, that.base_chart_options, options);
                options.series[0] = added_series;
                break;

            case 'bar':
                options = that.bar_options;
                options = $.extend(true, {}, that.base_chart_options, options);
                options.series[0] = added_series;
                break;
        }

        options.chart.renderTo = renderTo;

        if (that.chart)
            that.chart.destroy();

        if (!chartsBox.hasClass('b-charts--active')) {
            chartsBox.addClass('b-charts--active');
            tableBox.removeClass('b-table--active');
        }

        that.chart = new Highcharts.Chart(options);
        $('.b-setup-data__chart').addClass('b-setup-data__chart--active').siblings().removeClass('b-setup-data__table--active');
        $('.b-main-box__inner').scrollLeft(0);

    },

    /**
     * функция, которая перерисует чарт (позже пригодится, возможно)
     */
    redraw_chart: function() {

        var that = this;

        switch (that.type_chart) {
            case 'line':

                break;

            case 'pie':

                break;

            case 'bar':

                that.chart.xAxis[0].setCategories(['yasha', 'tusha']);
                that.chart.series[0].setData([10, 40]);
                break;
        }

        that.chart.redraw();
    },

}