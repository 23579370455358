import { BASE_URL } from 'general/env';
import { replaceSnippets } from 'general/utils/utils';
import factoryStoreMixin from 'general/utils/factoryStoreMixin';
import widgetTypes from './modules/widgetTypes';
import widgetScenarios from './modules/widgetScenarios';
import componentPalette from './modules/componentPalette';
import editor from './modules/editor';
import propertyPane from './modules/propertyPane';

const name = 'widgetSettings';
const SET_TENANT = 'SET_TENANT';
const SET_SERVER = 'SET_SERVER';
const SET_APPLICATION_ID = 'SET_APPLICATION_ID';

const module = {
  namespaced: true,

  state: () => ({
    tenant: '',
    server: '',
    applicationId: 0,
    autoSave: false,
  }),

  getters: {

    getTenant: state => state.tenant,
    getServer: state => state.server,
    getApplicationId: state => state.applicationId,
    getAutoSave: state => state.autoSave,
    getBaseUrl: (state) => {
      let url = '';
      if (state.tenant && state.server) {
        url = replaceSnippets(BASE_URL, {
          TENANT: state.tenant,
          SERVER: state.server,
        });
      }
      return url;
    },

  },

  actions: {

    setTenant({ commit }, payload) {
      commit(SET_TENANT, payload);
    },

    setServer({ commit }, payload) {
      commit(SET_SERVER, payload);
    },

    setApplicationId({ commit }, payload) {
      commit(SET_APPLICATION_ID, payload);
    },

  },

  mutations: {

    [SET_TENANT](state, payload) {
      state.tenant = payload;
    },

    [SET_SERVER](state, payload) {
      state.server = payload;
    },

    [SET_APPLICATION_ID](state, payload) {
      state.applicationId = payload;
    },

  },

  modules: {
    widgetTypes,
    widgetScenarios,
    componentPalette,
    editor,
    propertyPane,
  },
};

export default factoryStoreMixin(name, module);
