// ************************************************************************************************
// *************************************** HELPER FUNCTIONS ***************************************
// ************************************************************************************************

// ** for future iteration NodeList`s
HTMLCollection.prototype.forEach = Array.prototype.forEach

/**
 * Creates node clone. Remove all UUIDs in components tree, and assign baseUUDI (type of components)
 * Uses to create custom components from HTML template
 */
// const _clearComponent = (domNode, isRoot = true) => {
//   if (isRoot) {
//     domNode = domNode.cloneNode(true)
//     domNode.dataset.baseUuid = uuidv4()
//   }
//   if (domNode.dataset.uuid) delete domNode.dataset.uuid
//   if (domNode.childElementCount) {
//     for (const child of domNode.children) {
//       _clearComponent(child, false)
//     }
//   }
//   return domNode
// }

/**
 *
 */
// const _DOMElementGetXPath = (el) => {
//   const getPosition = (node) => {
//     let pos = 0
//     while (node.previousElementSibling && node.nodeName === node.previousElementSibling.nodeName) {
//       pos++
//       node = node.previousElementSibling
//     }
//     return pos ? `[${pos + 1}]` : ''
//   }
//   const path = []
//   do {
//     path.unshift(el.nodeName + getPosition(el))
//   } while ((el.nodeName !== 'HTML') && (el = el.parentNode))
//
//   return path
// }

/**
 *
 */
// const _DOMElementGetTitle = (el) => {
//   if (!el) return ''
//   const title = [`${el.tagName} `]
//   if (el.classList.value) {
//     title.push(el.classList.value.replaceAll(' ', '.'))
//   }
//   return title.join(' ')
// }

/**
 *
 */
// const _DOMElementGetClasses = (node, result) => {
//   if (!result) {
//     result = new Set()
//   } else {
//     node.className.split(' ').forEach(el => { if (el) result.add(el) })
//   }
//   if (node.parentNode && node.parentNode.className !== undefined) _DOMElementGetClasses(node.parentNode, result)
//   return Array.from(result)
// }

/**
 *
 */
// const _fnComputedStyle = function () {
//   return window.site.contentWindow.getComputedStyle(this)
// }

// ************************************************************************************************
// ************************************** EXPORTED MUTATIONS **************************************
// ************************************************************************************************

/**
 *
 */
// const DOMMutationCallback = function (mutationsList, observer) {
//   for (const mutation of mutationsList) {
//     if (mutation.type === 'attributes') {
//       console.log('The ' + mutation.attributeName + ' attribute was modified.')
//       this.commit('builder/currentElementRefreshHook', Math.random())
//     }
//   }
// }

/**
 *
 */
// let DOMMutationObserver = null

/**
 *
 */
export function setCurrentElement (state, element) {
  if (element === null) {
    state.currentDOMElement = null
    return
  }

  // ** element as XPath
  // if (Array.isArray(element)) {
  //   element.forEach((el, i) => (element[i] = el.toLowerCase() === 'svg' ? 'svg:svg' : el))
  //   const Result = document.evaluate(`/${element.join('/')}`, window.site.contentWindow.document, prefix => prefix === 'svg' ? 'http://www.w3.org/2000/svg' : null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
  //   element = Result ? Result.singleNodeValue : null
  // }
  // **

  // Create DOMNode mutation observer
  // DOMMutationObserver = DOMMutationObserver || new MutationObserver(DOMMutationCallback.bind(this))
  // DOMMutationObserver.disconnect() // Drop previous observer

  if (element) {
    // ** Run observer on current element
    // DOMMutationObserver.observe(element, { attributes: true })
    state.currentDOMElement = {
      element,
      // title: _DOMElementGetTitle(element),
      // xpath: _DOMElementGetXPath(element),
      // parentClasses: _DOMElementGetClasses(element),
      is: tagname => (element.tagName.toLowerCase() === tagname.toLowerCase()),
      // refreshHook: null
    }

    // ** define dynamic property `className`
    // Object.defineProperty(state.currentDOMElement, 'className', {
    //   get: function () {
    //     return state.currentDOMElement.element.className// .split(' ')
    //   },
    //   set: function (value) {
    //     if (Array.isArray(value)) value = value.join(' ')
    //     state.currentDOMElement.element.className = value
    //   }
    // })
  } else {
    state.currentDOMElement = null
  }
}

/**
 *
 */
// export function currentElementRefreshHook (state, payload) {
//   if (!state.currentDOMElement) return
//   state.currentDOMElement.refreshHook = payload
// }


/**
 *
 */
export function toClipboard (state, payload) {
  state.clipboard = payload
}

/**
 *
 */
export function setStatus (state, payload) {
  if (!payload.label) return
  const i = state.status.findIndex(el => el.label === payload.label)
  if (payload.value) {
    if (i !== -1) state.status.splice(i, 1, payload)
    else state.status.push(payload)
  } else if (i !== -1) {
    state.status.splice(i, 1)
  }
}

/**
 *
 */
export function saveInterface (state, payload) {
  const { key, value } = payload
  if (typeof value === 'object' && state.interface[key]) {
    state.interface[key] = Object.assign(state.interface[key], value)
  } else {
    state.interface[key] = value
  }
}
