'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var select2 = require('select2');

// Templates
var _conditionOperand = require('text!bcTemplates/conditions/conditionOperand.html');
var _conditionValue = require('text!bcTemplates/conditions/conditionValue.html');
var _input = require('text!bcTemplates/conditions/controls/input.html');
var _select2 = require('text!bcTemplates/conditions/controls/select2.html');


module.exports = Backbone.View.extend({


    events: {
        'change [name="dataConditionsOperation"]': 'selecetOperand'
    },

    bindings: {
        '[name="dataConditionsValue"]': 'value'
    },

    initialize: function(options) {
        var t = this;

        t.controlsTpl = {
            'label': '',
            'none': _.template('<div class="b-constructor__value"></div>'),
            'input': _.template(_input),
            'select2': _.template(_select2),
            // label
            // input, select2(tag, nosearch)
            // dataPicker, dateRangePicker, timePicker
        }

        // operand init
        t.operandValue = {
            string: {
                operand: {
                    control: 'select2',
                    name: 'dataConditionsOperation',
                    typeControl: 'nosearch',
                    value: [
                        {label: '=', value: '==', selected: true, type: 'string'},
                        {label: '<>', value: '<>', selected: false, type: 'string'},
                        {label: 'in', value: 'like', selected: false, type: 'string'},
                        {label: 'out', value: 'not like', selected: false, type: 'string'},
                        {label: 'is set', value: 'is null', selected: false, type: 'none'},
                        {label: 'is not set', value: 'is not null', selected: false, type: 'none'}
                    ]
                }
            }
        }

        // value init
        t.valueValue = {
            string: {
                value: {
                    control: 'input',
                    name: 'dataConditionsValue',
                    value: t.model.get('value')
                }
            },
            none: {
                value: {
                    control: 'none'
                }
            }
        }

        t.initOperand('string');

    },

    initOperand: function(operandTplType) {
        var t = this;

        _.each(t.operandValue[operandTplType], function(item, k) {
            item.value = _.map(item.value, function(item) {
                if (item.value === t.model.get('operation')) {
                    item.selected = true;
                    t.model.set('valueTplType', item.type);
                    t.initValue(item.type);
                } else {
                    item.selected = false;
                }
                return item;
            });
        });

        t.operandTpls = t.operandValue[operandTplType];
    },

    initValue: function(valueTplType) {
        this.valueTpls = this.valueValue[valueTplType];
    },

    render: function() {
        var t = this;
        t.$el.empty();
        var operand = _.template(_conditionOperand)({
            controls: t.controlsTpl,
            operand: t.operandTpls
        });
        var val = _.template(_conditionValue)({
            controls: t.controlsTpl,
            value: t.valueTpls
        });
        t.$el.append(operand);
        t.$el.append(val);


        // select2
        var select = t.$el.find('.b-constructor__select select'),
            options = {};

        if (select.attr('data-placeholder') && select.attr('data-placeholder') !== '') {
            options.placeholder = select.attr('data-placeholder');
        }

        switch (select.attr('data-type')) {
            case 'tags':
                options.tags = true;
                break;

            case 'nosearch':
                options.minimumResultsForSearch = -1;
                break;

        }

        if (!select.hasClass('select2-hidden-accessible')) {
            select.select2(options);
        }

        t.stickit();
    },

    close: function() {
        this.remove();
    },

    selecetOperand: function(e) {
        var selected = $(e.target).find('option:selected');
        this.model.set('operation', selected.val());

        if (selected.attr('data-type') !== this.model.get('valueTplType')) {
            this.initOperand('string');
            this.render();
        }
    },


});