import {
  makeControlProps,
  visibilityProps,
  typographyProps, backgroundProps,
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.text',
    label: 'Caption Button'
  }),
  makeControlProps('select', {
    pathValue: 'properties.typeButton',
    label: 'Type Button',
    options: [
      {label: 'Default', value: 'default'},
      {label: 'Outline', value: 'outline'},
      {label: 'Quiet', value: 'quiet'}
    ]
  }),

  makeControlProps('input', {
    pathValue: 'properties.listText',
    label: 'List header',
  }),

  makeControlProps('select', {
    pathValue: 'properties.source',
    label: 'Source',
    options: [
      {label: 'files', value: 'files'},
      {label: 'camera', value: 'camera'},
      {label: 'files and camera', value: 'files&camera'}
    ],
    subcontrols: {
      'files': [
        makeControlProps('checkboxes', {
          pathValue: 'properties.acceptFiles.extensions',
          label: 'Allowed file extensions',
          options: [
            {label: 'jpg', value: 'jpg'},
            {label: 'jpeg', value: 'jpeg'},
            {label: 'png', value: 'png'},
            {label: 'gif', value: 'gif'},
            {label: 'webp', value: 'webp'},
            {label: 'mov', value: 'mov'},
            {label: 'avi', value: 'avi'},
            {label: 'mp4', value: 'mp4'},
            {label: 'mp3', value: 'mp3'},
            {label: 'zip', value: 'zip'},
            {label: 'docx', value: 'docx'},
            {label: 'xlsx', value: 'xlsx'},
            {label: 'pdf', value: 'pdf'},
          ]
        })
      ],
      'files&camera': [
        makeControlProps('checkboxes', {
          pathValue: 'properties.acceptFiles.extensions',
          label: 'Allowed file extensions',
          options: [
            {label: 'jpg', value: 'jpg'},
            {label: 'jpeg', value: 'jpeg'},
            {label: 'png', value: 'png'},
            {label: 'gif', value: 'gif'},
            {label: 'webp', value: 'webp'},
            {label: 'mov', value: 'mov'},
            {label: 'avi', value: 'avi'},
            {label: 'mp4', value: 'mp4'},
            {label: 'mp3', value: 'mp3'},
            {label: 'zip', value: 'zip'},
            {label: 'docx', value: 'docx'},
            {label: 'xlsx', value: 'xlsx'},
            {label: 'pdf', value: 'pdf'},
          ]
        }),
      ]
    }
  }),



  // ["jpg","jpeg","png","gif","webp","mov","avi","mp3","zip","docx","xlsx","pdf"]

  makeControlProps('inputWithDescription', {
    pathValue: 'properties.sizes.minSize',
    label: 'Minimal allowed file size (Kbytes)',
    description: 'Default 100 Kbytes',
    type: 'number',
    step: 500,
    min: 100,
    max: 50000,
  }),

  makeControlProps('inputWithDescription', {
    pathValue: 'properties.sizes.maxSize',
    label: 'Maximal allowed file size (Kbytes)',
    description: '0 means no limits',
    type: 'number',
    step: 500,
    min: 100,
    max: 50000,
  }),

  makeControlProps('multipleFiles', {
    pathValue: 'properties.multiple',
    label: 'Allow multiple files',
    enabled:
      {
        pathValue: 'properties.multiple.enabled',
        label: 'Allow multiple files',
      },
    count:
      {
        pathValue: 'properties.multiple.maximum',
        label: 'Maximum number of files allowed',
        description: '0 means no limits',
        type: 'number',
        step: 1,
        min: 0,
        max: 256,
      },
  }),

  makeControlProps('imageCompression', {
    pathValue: 'properties.autoCompress',
    enabled:
      {
        pathValue: 'properties.autoCompress.enabled',
        label: 'Autocompress large files',
      },
    size:
      {
        pathValue: 'properties.autoCompress.size',
        label: 'Minimal size to enable Auto Compression (Kbytes)',
        description: 'Default is 2048 (~2Mb)',
        type: 'number',
        step: 1024,
        min: 1024,
        max: 50000,
      },
    maxWidth:
      {
        pathValue: 'properties.autoCompress.maxWidth',
        label: 'Maximal width of compressed image',
        description: 'Default is 1024',
        type: 'number',
        step: 128,
        min: 32,
        max: 4096,
      },
    maxHeight:
      {
        pathValue: 'properties.autoCompress.maxHeight',
        label: 'Maximal height of compressed image',
        description: 'Default is 1024',
        type: 'number',
        step: 128,
        min: 32,
        max: 4096,
      },
    quality:
      {
        pathValue: 'properties.autoCompress.quality',
        label: 'Compression quality, 0-no, 1-max.',
        description: 'Default is 0.8',
        type: 'number',
        step: 0.1,
        min: 0.1,
        max: 1.0,
      },
    convertSize:
      {
        pathValue: 'properties.autoCompress.convertSize',
        label: 'Convert large PNG to JPG starting from (Kbytes)',
        description: 'Default is 5Mb',
        type: 'number',
        step: 1024,
        min: 1024,
        max: 50000,
      },
    checkOrientation:
      {
        pathValue: 'properties.autoCompress.checkOrientation',
        label: 'Preserve image orientation',
      },
    captureFromCameraOnly:
      {
        pathValue: 'properties.captureFromCameraOnly',
        label: 'Allow only capture from camera',
      },
  }),

  visibilityProps,
  // loopControlProps, // Think that loop for UploadButton is not needed
];

const view = [
  {
    selector: '.mbst-upload button',
    controls: [
      typographyProps,
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width',
        },
        maxWidth: {
          label: 'Max. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-width',
        },
        minWidth: {
          label: 'Min. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-width',
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height',
        },
        maxHeight: {
          label: 'Max. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-height',
        },
        minHeight: {
          label: 'Min. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-height',
        },
      }),
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
    ],
  },
]
const viewMigration = [
  {
    from: '.mbst-upload',
    to: '.mbst-upload button',
    directives: [
      'border-width',
      'border-style',
      'border-color',
      'border-bottom-width',
      'border-bottom-style',
      'border-bottom-color',
      'border-top-width',
      'border-top-style',
      'border-top-color',
      'border-right-width',
      'border-right-style',
      'border-right-color',
      'border-left-width',
      'border-left-style',
      'border-left-color',
      'border-radius',
      'padding',
      'margin',
      'font-family',
      'font-size',
      'font-style',
      'font-weight',
      'text-align',
      'text-decoration',
      'text-transform',
      'white-space',
      'letter-spacing',
      'line-height',
      'color',
      'width',
      'max-width',
      'min-width',
      'height',
      'min-height',
      'min-height',
      'background-color',
      'background-image',
      'background-repeat',
      'background-size',
      'background-position-x',
      'background-position-y',
    ],
  },
]
const actions = [];

export default {
  properties,
  actions,
  viewMigration,
  view,
}
