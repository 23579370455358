'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Templates
var _transformation = require('text!bcTemplates/transformations/transformation.html');


module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'b-constructor__row',

    events: {
        'click [data-js-action="deleteTransformation"]': 'deleteTransformation',
    },

    initialize: function(options) {
        this.tpl = _.template(_transformation);
        this.tId = options.tId;
    },

    render: function() {
        this.$el.empty();
        this.$el.append(
            this.tpl({
                id: this.tId
            })
        );
        return this.$el;
    },

    close: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    deleteTransformation: function(e) {
        e.preventDefault();
        this.model.collection.remove(this.model);
        this.close();
    }

});