import {
  makeControlProps,
  backgroundProps,
  typographyProps,
  sizingProps,
  loopControlProps,
  $t
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('select', {
    pathValue: 'properties.colwidth',
    label: 'Default width',
    hint: {
      text: $t('general.layout12columnHint'),
      action: () => { window.open('https://www.w3schools.com/css/css_rwd_grid.asp', '_blank'); },
    },

    options: [
      {label: 'not set', value: ''},
      {label: 'fit content', value: 'auto'},
      {label: '0', value: '0'},
      {label: '1', value: '1'},
      {label: '2', value: '2'},
      {label: '3', value: '3'},
      {label: '4', value: '4'},
      {label: '5', value: '5'},
      {label: '6', value: '6'},
      {label: '7', value: '7'},
      {label: '8', value: '8'},
      {label: '9', value: '9'},
      {label: '10', value: '10'},
      {label: '11', value: '11'},
      {label: '12', value: '12'},
    ]
  }),

  makeControlProps('select', {
    pathValue: 'properties.offset',
    label: 'Offset',
    options: [
      {label: 'not set', value: ''},
      {label: '1', value: '1'},
      {label: '2', value: '2'},
      {label: '3', value: '3'},
      {label: '4', value: '4'},
      {label: '5', value: '5'},
      {label: '6', value: '6'},
      {label: '7', value: '7'},
      {label: '8', value: '8'},
      {label: '9', value: '9'},
      {label: '10', value: '10'},
      {label: '11', value: '11'},
      {label: '12', value: '12'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.valign',
    label: 'Vertical align',
    options: [
      {label: 'No', value: ''},
      {label: 'Top', value: 'self-start'},
      {label: 'Middle', value: 'self-center'},
      {label: 'Bottom', value: 'self-end'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.shadow',
    label: 'Shadow',
    options: [
      {label: 'not set', value: ''},
      {label: '1', value: '1'},
      {label: '2', value: '2'},
      {label: '3', value: '3'},
      {label: '4', value: '4'},
      {label: '5', value: '5'},
      {label: '6', value: '6'},
      {label: '7', value: '7'},
      {label: '8', value: '8'},
      {label: '9', value: '9'},
      {label: '10', value: '10'},
    ],
    subcontrols: [
      makeControlProps('select', {
        pathValue: 'properties.shadowPosition',
        label: 'Shadow position',
        options: [
          {label: 'Down', value: ''},
          {label: 'Up', value: 'up-'},
        ]
      })
    ]
  }),

  makeControlProps('customClassSelect', {
    pathValue: 'properties.customClasses',
    label: 'Adaptive width',
    hint: {
      text: $t('general.layout12columnHint'),
      action: () => { window.open('https://www.w3schools.com/css/css_rwd_grid.asp', '_blank'); },
    }
  }),

  // makeControlProps('expansion', {
  //   pathValue: 'properties.popupCollapse',
  //   label: 'Adaptive width!',
  //   subcontrols: [
  //
  //     makeControlProps('customClassSelect', {
  //       pathValue: 'properties.customClasses',
  //       label: 'Adaptive width',
  //       hint: {
  //         text: $t('general.layout12columnHint'),
  //         action: () => { window.open('https://www.w3schools.com/css/css_rwd_grid.asp', '_blank'); },
  //       }
  //     })
  // ]}),

  // makeControlProps('input', {
  //   pathValue: 'properties.customClasses',
  //   label: 'Custom CSS classes'
  // }),

  loopControlProps
]

const view = [
  {
    selector: '.mbst-row__col',
    controls: [
      typographyProps,
      sizingProps,
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
    ]
  }
]

const actions = []

export default {
  properties,
  view,
  actions
}
