export default {
  VERSION: '0.0.1',
  name: 'mbst-text-input',
  aliasName: 'Text Input',
  properties: {
    backendname: 'text-input',
    label: 'My Input',
    placeholder: 'My Input',
    value: '',
    defaultvalue: '',
    iscached: false,
    inputType: 'text',
    darkMode: false,
    design: '',
    mask: '',
    datemask: 'DD.MM.YYYY',
    unmaskedValue: true,
    displayMask: false,
    clearable: false,
    visibility: {
      conditions: [],
      hidden: false,
    },
  },
  actions: null,
  css: {
    all: [
      {
        selector: '.mbst-text-input .q-field__control:before',
        rules: {},
      },
    ],
    ios: [
      {
        selector: '.mbst-text-input',
        rules: [],
      },
    ],
    android: [
      {
        selector: '.mbst-text-input',
        rules: [],
      },
    ],
  },
}
