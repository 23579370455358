var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            fill: "#527eb0",
            d: "M44.37 0h39.27C119.47 0 128 8.54 128 44.37v39.27c0 35.83-8.53 44.36-44.36 44.36H44.37C8.54 128 0 119.43 0 83.64V44.37C0 8.54 8.54 0 44.37 0z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#fff",
            d: "M-3,63 C-3,36.48066298342542 33.02486187845304,15 77.5,15 C121.97513812154696,15 158,36.48066298342542 158,63 C158,89.51933701657458 121.97513812154696,111 77.5,111 C33.02486187845304,111 -3,89.51933701657458 -3,63 Z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }