/*global isFixHeadBodyModal */
import logger from 'general/logger';
import Cookie from 'js-cookie';
const _ = require('underscore');
const Colorpicker = require('refactor/lib/colorpicker.min');
const Select2 = require('refactor/lib/select2');
const SmartFilters = require('refactor/smart-filters');
const Utils = require('refactor/helper/utils');
const HashTags = require('refactor/hashtags');

module.exports = {

    init: function () {
        this.bind();
        window.smartFilters = SmartFilters;


        $(document.body).delegate('.b-conditions-row__select--property', 'change', function() {
            console.log("CHANGE .b-conditions-row__select--property");
            var self    = $(this),
                $row    = self.closest('.b-conditions-row'),
                $rules  = $row.find('.b-conditions-rules'),
                rowIndex   = $(this).parent().parent().data('index'),

                data    = {propertyVal: self.val(), appId: self.data('appid'), rowIndex: rowIndex};

            var value = $('[data-index="'+rowIndex+'"]').find('[data-id="selectvalue"]').val(),
                condition = $('[data-index="'+rowIndex+'"]').find('[data-id="selectcondition"]').val(),
                appid = $('[data-index="'+rowIndex+'"]').find('[data-id="selectcolumn"]').data('appid'),
                column = $('[data-index="'+rowIndex+'"]').find('[data-id="selectcolumn"]').val(),

                columnName = column,
                value1 = value,
                value2 = value,
                andor = "or",
                presetName = "",
                filterId = $(this).parent().parent().attr('filterid'),
                filterRow = $(this).parent().parent();


            if ( self.val() !== '') {
                $row.attr('data-condition', self.val());
                /** чтобы не дублировать весь этот г..лазурь, просто переключаем урл на roles */
                $.ajax({
                    //url: "/users/conditionsrules/",
                    url: "/roles/conditionsrules/",
                    type: "POST",
                    data: data,
                    beforeSend: function() {}
                }).done(function(a, b, c) {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        $rules.replaceWith(obj.html);

                        $('.b-conditions-row__select--value').each(function() {
                            if ($(this).attr('class') !== 'b-conditions-row__select--value select2-hidden-accessible') {
                                $(this).select2({
                                    placeholder: lang("Property"),
                                    tags: true
                                });
                            }
                        });

                        $('.b-conditions-row__select--con').each(function() {
                            if ($(this).attr('class') !== 'b-conditions-row__select--con select2-hidden-accessible') {
                                $(this).select2({
                                    minimumResultsForSearch: -1
                                });
                            }
                        });

                        $('#l_where').css('visibility', 'hidden');
                        $('.b-conditions-labels__line').css('display', 'block');

                        var values = obj.columnvalues;
                        for (var x in values) {
                            //console.log(values[x]);
                            $('[data-index="'+rowIndex+'"]').find('[data-id="selectvalue"]').append('<option>' + values[x][self.val()] + '</option>');
                        }
                        $('.b-conditions-new_row').show();

                        var  data = {appId:appid, filterId: filterId, columnName: columnName,
                            condition:condition, value1:value1, value2:value2, andor:andor, presetName:presetName, roleId: $('#id-role').val()};
                        if (value !== '') {
                            initFilter(data, filterRow);
                        }
                    } else {
                    }
                }).fail(function(a, b, c) {
                }).always(function(a, b, c) {
                });
            }
        });
//        }
//    });

        // выбираем в двух последних селектах
        //$('.b-conditions-row__select--con, .b-conditions-row__select--value').each(function() {
        //if ($(this).data('listen') === "") {
        //$(this).attr('data-listen', "1");
        $(document.body).delegate('.b-conditions-row__select--con, .b-conditions-row__select--value', 'change', function() {
            console.log("CHANGE .b-conditions-row__select--con, .b-conditions-row__select--value");
            var filterId = $(this).parent().parent().parent().attr('data-filterid'),
                condition = $('[data-filterid="'+filterId+'"]').find('[data-id="selectcondition"]').val(),
                appid = $('[data-filterid="'+filterId+'"]').find('[data-id="selectcolumn"]').data('appid'),
                columnName = $('[data-filterid="'+filterId+'"]').find('[data-id="selectcolumn"]').val(),
                value1 = $('[data-filterid="'+filterId+'"]').find('[data-id="selectvalue"]').val(),
                value2 = $('[data-filterid="'+filterId+'"]').find('[data-id="selectvalue"]').val(),
                andor = "or",
                presetName = "",
                roleId = $('#id-role').val();

            var data = {appId:appid, filterId: filterId, columnName: columnName,
                condition:condition, value1:value1, value2:value2, andor:andor, presetName:presetName, roleId:roleId};
            if (value1 !== '') {
                var filterRow = $(this).parent().parent().parent();
                initFilter(data, filterRow);
            }
        });
        //}
        //});

        //удаляем строку
        //$('.b-conditions-row__delete').each(function() {
        //if ($(this).data('listen') === "") {
        //$(this).attr('data-listen', "1");
        $(document.body).delegate('.b-conditions-row__delete', 'click', function() {
            console.log("CHANGE .b-conditions-row__delete");
            var self        = $(this),
                $row        = self.closest('.b-conditions-row'),
                $labels     = $('.b-conditions-labels'),
                condition   = '',
                roleId = $('#id-role').val();
            $.ajax({
                //url: "/userfilters/deletefilter/",
                url: "/roles/deletefilter/",
                type: "POST",
                data: {filterId: $(this).parent().data('filterid'), roleId:roleId},
                beforeSend: function() {}
            }).done(function(a, b, c) {

            });
            console.log($('.b-conditions-rows .b-conditions-row').length);
            if ($('.b-conditions-rows .b-conditions-row').length > 2) {
                $row.remove();
                if ($('.b-conditions-rows .b-conditions-row').last().attr('data-condition') !== '') {
                    $('.b-conditions-new_row').show();
                } else {
                    $('.b-conditions-new_row').hide();
                }
            } else if ($('.b-conditions-rows .b-conditions-row').length == 2) {
                $row.remove();
                $('#l_switcher').hide();
                $('.b-conditions-new_row').children().attr('data-stat', 'switcher');
                //$('#l_switcher input').each(function() {
                //    if ($(this).is(':checked')) {
                //        condition = $(this).val();
                //    }
                //});
                //$labels.append('<div class="b-conditions-labels__label" data-condition="' + condition + '" >' + condition + '</div>');
                if ($('.b-conditions-rows .b-conditions-row').last().attr('data-condition') !== '') {
                    $('.b-conditions-new_row').show();
                } else {
                    $('.b-conditions-new_row').hide();
                    $('#l_where').css('visibility', 'visible');
                    $('.b-conditions-labels__line').css('display', 'none');

                }
            } else if ($('.b-conditions-rows .b-conditions-row').length == 1) {

                $('.b-conditions-row__select--property').val(null).trigger("change");
                $('.b-conditions-rules').html('');
                $('#l_where').css('visibility', 'visible');
                $('.b-conditions-labels__line').css('display', 'none');
                $('.b-conditions-new_row').hide();

            }
            $labels.find('[data-condition]').last().remove();
        });
        //  }
        // });

        //добавляем новую строку
        //$('.b-conditions__btn').each(function() {
        //if ($(this).data('listen') === "") {
        //$(this).attr('data-listen', "1");
        $(document.body).delegate('.b-conditions__btn', 'click', function() {
            console.log("CHANGE .b-conditions__btn");
            var self        = $(this),
                data        = {appId: self.data('appid')},
                $tempRow    = $("#tempRow").html(),
                condition   = '';

            $('.b-conditions-new_row').hide();
            $('.b-conditions-rows').append($tempRow);

            $('.b-conditions-rows .b-conditions-row__select--replace').removeClass('b-conditions-row__select--replace').addClass('b-conditions-row__select--property');

            $('.b-conditions-row__select--property').select2({
                placeholder: lang("Property")
            });

            if (self.attr('data-stat') === 'switcher') {

                $('.b-conditions #l_switcher').show();
                self.attr('data-stat', 'process');

            } else {

                $('#l_switcher input').each(function() {

                    if ($(this).is(':checked')) {

                        condition = $(this).val();

                    }

                });

                $('.b-conditions-labels').append('<div class="b-conditions-labels__label" data-condition="' + condition + '" >' + condition + '</div>');

            }

        });
        //  }
        //});



        //переключаем and/or
        //$('#l_switcher input').each(function() {
        //if ($(this).data('listen') === "") {
        //$(this).attr('data-listen', "1");
        $(document.body).delegate('.b-conditions #l_switcher input', 'change', function() {
            console.log("CHANGE #l_switcher input");
            var conditionVal = $(this).val();
            var appId = $('.b-conditions #l_switcher').parent().parent().find('.b-conditions-row__select--property').data('appid');
            $.ajax({
                url: "/userfilters/changeandor/",
                type: "POST",
                data: {appId:appId, userId:$('#id-user').val(), state:conditionVal},
                beforeSend: function() {}
            }).done(function(a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    $('.b-conditions-labels [data-condition]').each(function() {
                        $(this).text(conditionVal);
                    });
                }
            });
        });
        //}
        //});



        $(document.body).delegate('#startImportObjects', 'click', function (e){
            e.preventDefault();
            var appId = $('#id-app').val(),
                self = $(this),
                cols = [];

            $('[data-col-number]').each(function() {
                var colName     = $($(this).find('.b-import__col-name')[0]).html();
                var colClass    = $(this).attr('class');
                if (colClass === 'success-col') {
                    cols[cols.length] = $($(this).find('.b-import__col-name')[0]).html();
                } else {
                    cols[cols.length] = "";
                }
            });
            var target = $(this).attr('data-target');
            var updateExisting=0;
            var primaryKey='';
            if ($('#UpdateExistingRecords').attr("checked")=="checked")
            {
                updateExisting=1;
                primaryKey=$('#primaryKey').val();
            }

            $('#startImportObjects').click(false);
            $('#startImportObjects').click(function(){return false;});
            
            $.ajax({
                url: "/objects/startimportobjects/",
                type: "POST",
                data: {appId: appId, cols: cols, targetfile: target, primaryKey: primaryKey, updateExisting: updateExisting},
                beforeSend: function() {
                    self.attr('disabled', true).find('.b-spinner').addClass('b-spinner--anim');

                }
            }).done(function(a, b, c) {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    grit(lang('Success'), 'growl-success');
                    $('#choose_columns').remove();
                    $('#importReport').html(obj.html).addClass('b-import-report--active');
                    self.css('visibility', 'hidden').find('.b-spinner').removeClass('b-spinner--anim');
                    $('.b-import__total').removeClass('b-import__total--active');
                    $.fn.yiiGridView.update("my-model-grid");
                } else {
                    grit(lang('Import failed'), 'growl-danger');
                    self.attr('disabled', false).find('.b-spinner').removeClass('b-spinner--anim');
                }
            });


        });

        $(document.body).on('change', '#uploadForImport', function (e) {

            var fileObject = $(this)[0].files[0],
                self = $(this),
                btn = self.closest('.btn-upload');

            if (fileObject !== undefined) {

                var appId = $('#id-app').val(),
                    fd = new FormData(),
                    fileExtension = fileObject.name.split('.').pop().toLowerCase(),
                    fileSize = fileObject.size;

                fd.append("id", appId);
                fd.append("UploadedFiles[image]", fileObject);

                var checkExt = false;
                var checkSize = false;
                var postUrl = '/objects/uploadforimport';

                if (fileExtension === "csv" || fileExtension === "xls" || fileExtension === "xlsx") {
                    checkExt = true;
                    if (fileSize <= 10485760) {
                        checkSize = true;
                    }
                }
                if (checkExt) {
                    if (checkSize) {
                        $.ajax({
                            url: postUrl,
                            type: 'POST',
                            cache: false,
                            data: fd,
                            datatype: 'json',
                            processData: false,
                            contentType: false,
                            beforeSend: function() {
                                btn.addClass('b-btn--load');
                                btn.find('.b-spinner').addClass('b-spinner--anim');
                            },
                            success: function (data) {
                                let obj = {};

                                try {
                                    obj = JSON && JSON.parse(data) || $.parseJSON(data);
                                } catch (error) {
                                    logger.exception(error, data);
                                }

                                if (obj.error === "") {
                                    //console.log(obj.uploaded);
                                    $('#choose_upload_file').remove();
                                    $('#choose_columns').html(obj.html).addClass('b-import--active');
                                    $('#startImportObjects').attr({'data-target': obj.target, 'disabled': true}).css('visibility', 'visible');
                                    $('#row_count').text(row_count);
                                    $('.b-import__total').addClass('b-import__total--active');
                                    grit(lang('Saved'), 'growl-success');
                                } else {
                                    grit(lang('Save failed'), 'growl-danger', obj.error);
                                    self.val('');
                                    btn.removeClass('b-btn--load');
                                    btn.find('.b-spinner').removeClass('b-spinner--anim');
                                }
                            },
                            error: function (a) {
                                let obj = {};

                                try {
                                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                                } catch (error) {
                                    logger.exception(error, a);
                                }

                                grit(lang('Save failed'), 'growl-danger', obj.error);
                                self.val('');
                                btn.removeClass('b-btn--load');
                                btn.find('.b-spinner').removeClass('b-spinner--anim');
                            }
                        });
                    } else {
                        alert("Sorry, this file size is larger than allowed!");
                    }
                } else {
                    alert("Sorry, this file type not supported!");
                }
            }

        });


    },

    bind: function () {
        var that = this;

        $(document.body).on('click', '[data-toggle="modal-hero"]', function (e) {
            e.preventDefault();

            var eventid = $(this).data('eventid'),
                grid    = $(this).attr('data-grid'),
                tosend  = $(this).data('tosend'),
                appid   = $('#id-app').val(), ids;



            if (eventid !== '' && eventid !== undefined){
                ids = [eventid];
            } else {
                if ($('#my-model-grid').length > 0) {
                    ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');
                }
            }

            if (grid === 'statuses') {
                let url = "/statuses/view/" + eventid;
                modalView(url, 'GET', {}, grid);
            }
            else if (grid === 'backend') {
                let url = "/backend/view/" + eventid;
                modalView(url, 'GET', {}, grid);
            }
            else if (grid === 'users') {
                let url = "/users/view/" + eventid;
                modalView(url, 'GET', {}, grid);
            }
            else if (grid === 'senderpool') {
                let url = "/senderpool/view/" + eventid;
                modalView(url, 'GET', {}, grid);
            }
            else if (grid === 'roles') {
                let url = "/roles/view/" + eventid;
                modalView(url, 'GET', {
                    actionType: 'update',
                }, grid);
            }
            else if (grid === 'search') {
                let url = "/search/index";
                modalView(url, 'GET', {}, grid);
            }
            else if (grid === 'searchinput') {
                $(this).unbind().keydown(function(e) {
                    if(e.which == 13) {
                        $(this).closest('div').children('a').click();
                    }
                });
            }
            else if (grid === 'codeweb') {
                let url = "/createapp/codeweb",
                short_link  = $(this).data('url');
                modalView(url, 'POST', 'short_link=' + short_link, grid);
            }
            else if (tosend !== '' && tosend !== undefined) {
                let url = '/senderpool/domessage',
                    data = {type: tosend, eventid: ids, appid: appid};
                modalView(url, 'POST', data, 'tosend-' + tosend);
            }
            else if (grid === 'details-template') {
                let url = '/papplications/showdetail/',
                    appId = $(this).data('path'),
                    appNumber = $(this).data('number'),
                    data = 'id=' + appId + '&number=' + appNumber;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'video-template') {
                let url = '/papplications/showvideo/',
                    appId = $(this).data('path'),
                    appNumber = $(this).data('number'),
                    data = 'id=' + appId + '&number=' + appNumber;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'app-list') {
                let url = '/applications/attemptdelete/',
                    appId = $(this).data('path'),
                    data = 'id=' + appId;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'attemptcolumndelete') {
                let url = '/objects/attemptcolumndelete/',
                    appId = $(this).data('path'),
                    column = $(this).data('column'),
                    data = 'id=' + appId + '&column=' + column;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'attemptbackendcolumndelete') {
                let url = '/backend/attemptcolumndelete/',
                    appId = $(this).data('path'),
                    column = $(this).data('column'),
                    data = 'id=' + appId + '&column=' + column;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'deleteobjects') {
                let url = '/objects/attemptobjectsdelete/',
                    data = {value:0};
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'conditions') {
                let url = '/users/conditionsobjfield/',
                    appId = $(this).data('appid'),
                    userId = $('#id-user').val(),
                    data = 'appId=' + appId + '&userId=' + userId;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'role-conditions') {
                let url = '/roles/conditionsobjfield/',
                    appId = $(this).data('appid'),
                    roleId = $('#id-role').val(),
                    data = 'appId=' + appId + '&roleId=' + roleId;
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'create-qr-url') {
                let url = '/applications/createqrcodeurl/';
                modalView(url, 'GET', {type: $(this).attr('data-invite-type'), who: $(this).attr('data-invite-who')}, grid);
            }
            else if (grid === 'confirm') {
                let url = '/applications/confirm/',
                    data = 'context=' + $(this).data('context') + '&totalprice=' + $('#totalprice').text();
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'import-objects') {
                let url = '/objects/import/',
                    data = 'appId=' + $('#appid').val();
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'modal-pay') {
                let url = '/applications/modalpay/',
                    data = {};
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'create-sf') { // создание смарт фильтра
                let appId = $('#appid').val(),
                    url = '/backend/createsmartfilter/',
                    data = {appId: appId};
                that.modalView(url, 'POST', data, grid);
            }
            else if (grid === 'atolcreate') {
                let url = "/accountsettings/atolcreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'alfabankcreate') {
                let url = "/accountsettings/alfabankcreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'alfabankedit') {
                let url = "/accountsettings/alfabankedit/",
                data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'rfibankcreate') {
                let url = "/accountsettings/rfibankcreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'rfibankedit') {
                let url = "/accountsettings/rfibankedit/",
                data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }

            else if (grid === 'sbercreate') {
                let url = "/accountsettings/sbercreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'sberedit') {
                let url = "/accountsettings/sberedit/",
                  data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }


            else if (grid === 'nkocreate') {
                let url = "/accountsettings/nkocreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'nkoedit') {
                let url = "/accountsettings/nkoedit/",
                data = {hiddenacckey: eventid};
                 modalView(url, 'POST', data, grid);
            }


            else if (grid === 'sngbcreate') {
                let url = "/accountsettings/sngbcreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'sngbedit') {
                let url = "/accountsettings/sngbedit/",
                    data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }


            else if (grid === 'atoledit') {
                let url = "/accountsettings/atoledit/",
                data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'braintreecreate') {
                let url = "/accountsettings/braintreecreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'braintreeedit') {
                let url = "/accountsettings/braintreeedit/",
                data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'stripecreate') {
                let url = "/accountsettings/stripecreate/";
                modalView(url, 'POST', {}, grid);
            }
            else if (grid === 'stripeedit') {
                let url = "/accountsettings/stripeedit/",
                data = {hiddenacckey: eventid};
                modalView(url, 'POST', data, grid);
            }
            else if (grid === 'edit-sf') {
                let appId = $('#appid').val();
                let filterId = $(this).parents('tr').attr('data-filterid');
                if (filterId === undefined) { // это значит что редактирование активировано из меню виджета
                    filterId = $(this).parents('.b-smart-filters__item').attr('id');
                }
                let url = '/backend/editsmartfilter/',
                    data = {appId: appId, filterId: filterId};
                that.modalView(url, 'POST', data, grid);
                $('div#'+filterId).removeClass('b-smart-filters__item--menu');
            }
            else if (grid === 'deletesmartfilter') {
                let filterName = $(this).parents('tr').find('#trSmartFilterName').html();
                let filterId = $(this).parents('tr').attr('data-filterid');
                if (filterName === undefined && filterId === undefined) { // это значит что удаление активировано из меню виджета
                    filterName = $(this).parents('.b-smart-filters__item').find('.b-smart-filters__name').html();
                    filterId = $(this).parents('.b-smart-filters__item').attr('id');
                }
                let url = '/backend/smartfilterattemptdelete/',
                    appId = $(this).data('path'),
                    data = {filterId: filterId, filterName: filterName};
                modalView(url, 'POST', data, grid);
            } else if (grid === 'changePhoneEmail') { // изменение телефона и/или емэйла
                let dataColumn = $(this).attr('data-column');
                let obj = {};

                try {
                    obj = JSON && JSON.parse(dataColumn) || $.parseJSON(dataColumn);
                } catch (error) {
                    logger.exception(error, dataColumn);
                }

                let url = '/objects/changephoneemailview/',
                    data = {
                        appId: $('#id-app').val(),
                        objId: $(this).attr('data-objid'),
                        column: obj,
                    };
                that.modalView(url, 'POST', data, grid);
            }
        });

        $(document.body)
          .on('click', '.details-uncheckboxcolunms', function () {
              let checktype = $(this)
                .attr('data-checktype') + 'CheckboxForMu';
              let checkboxes = document.getElementsByClassName(checktype);
              for (let index = 0; index < checkboxes.length; index++) {
                  if (checkboxes[index].checked) {
                      checkboxes[index].checked = false;
                  }
              }
          });
        $(document.body)
          .on('click', '.details-checkboxcolunms', function () {
              let checktype = $(this)
                .attr('data-checktype') + 'CheckboxForMu';
              let checkboxes = document.getElementsByClassName(checktype);
              for (let index = 0; index < checkboxes.length; index++) {
                  if (!checkboxes[index].checked) {
                      checkboxes[index].checked = true;
                  }
              }
          });


        $(document.body).on('click', '.details-close', function () {
            var windowDialog = $(this).closest('.details');
            var type = $(this).attr('data-modaltype');
            if (type === undefined) {
                $.fn.yiiGridView.update("my-model-grid");
                that.destroyDialog(windowDialog);
            } else if (type === "event-modal-window") {
                that.destroyDialog(windowDialog); //FIX-80
            } else if (type === "changeobjphoneemail") {
                /** отправим запрос на изменение email & phone у объекта и чтобы еще телефоны в девайсес поменялись
                 */
                var objid = $(this).attr('data-objid'),
                    phone = $('#chageObjPhone').val(),
                    appId = $('#id-app').val(),
                    email = $('#chageObjEmail').val();
                $.ajax({
                    url: "/objects/changeobjphoneemaildo/",
                    type: "POST",
                    data: {objid: objid, phone: phone, email: email, appId: appId},
                    beforeSend: function() {}
                }).done(function(a, b, c) {
                    let obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error === "") {
                        grit(lang('Success'), 'growl-success');
                        $.fn.yiiGridView.update("my-model-grid");
                        that.destroyDialog(windowDialog);
                    } else {
                        grit(obj.error, 'growl-danger');
                        that.destroyDialog(windowDialog);
                    }
                }).fail(function(a,b,c){
                    grit(lang('Not changed'), 'growl-danger');
                    that.destroyDialog(windowDialog);
                });
            } else if (type === "smartfilter") {
                // save modal form data to smart filter

                var smartName = $('#smartFilterName').val(),
                    smartMeasure = $('#smartFilterMeasure').val(),
                    smartDescription = $('#smartFilterDescription').val(),
                    smartFilterId = $('#smartFilterIdHidden').val(),
                    smartUserId = $('#smartFilterOwner').val();

                // conditions begin
                var conditions = new Array;
                $('#sf_conditions').find('.b-constructor__row').each(function() {
                    //вытаскиваем сохраняемые данные из строки
                    var id = $(this).attr('id');
                    var property = $(this).find('select[data-id="properties"] option:selected').val();      // table.field
                    var type = $(this).find('.b-constructor__type .active a').attr('data-type');                    // string, number, boolean, date, time
                    var mode = $(this).find('select[data-id="mode"] option:selected').val();                // running, fixed
                    var operand = $(this).find('select[data-id="operand"] option:selected').val();          // >,<,<>,=,between....

                    var checked = $('#sf_conditions').find('#con_and').is(':checked');

                    let andOr, selects, inputs, value1, value2;

                    if (checked === true) {
                        andOr = 'and';
                    } else {
                        andOr = 'or';
                    }

                    if (property !== undefined && property !== "") {
                        if (type === 'string') {
                            if (operand === '=' || operand === '<>' || operand === 'like' || operand === 'not like') {
                                selects = $(this).find('select[data-id="value"]');
                                if (selects[0] != undefined) {
                                    value1 = $(selects[0]).val();
                                }
                            } else if (operand === 'is null') {
                            } else if (operand === 'is not null') {
                            }
                        } else if (type === 'number') {
                            if (operand === '=' || operand === '>' || operand === '<') {
                                inputs = $(this).find('input[data-id="value"]');
                                if (inputs[0] != undefined) {
                                    value1 = $(inputs[0]).val();
                                }
                            } else if (operand === 'between') {
                                inputs = $(this).find('input[data-id="value"]');
                                if (inputs[0] != undefined) {
                                    value1 = $(inputs[0]).val();
                                }
                                if (inputs[1] != undefined) {
                                    value2 = $(inputs[1]).val();
                                }
                            }
                        } else if (type === 'boolean') {
                            if (operand === 'true') {
                            } else if (operand === 'false') {
                            }
                        } else if (type === 'date') {
                            if (mode === 'running') {
                                if (operand === '<>' || operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    selects = $(this).find('select[data-id="value"]');
                                    if (selects[0] != undefined) {
                                        value2 = $(selects[0]).val();
                                    }
                                }
                            } else if (mode === 'fixed') {
                                if (operand === 'between') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    if (inputs[1] != undefined) {
                                        value2 = $(inputs[1]).val();
                                    }
                                } else if (operand === '=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                }
                            }
                        } else if (type === 'time') {
                            if (mode === 'running') {
                                if (operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                }
                            } else if (mode === 'fixed') {
                                if (operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                } else if (operand === 'between') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    if (inputs[1] != undefined) {
                                        value2 = $(inputs[1]).val();
                                    }
                                }
                            }
                        }

                        conditions.push({id: id, property: property, type: type, mode: mode, operand: operand, value1: value1, value2: value2, andOr: andOr});
                    }

                });
                // conditions end

                // sorts begin
                var sorts = new Array;
                $('#sf_sorting').find('.b-constructor__row').each(function() {
                    //вытаскиваем сохраняемые данные из строки
                    var id = $(this).attr('id');
                    var property = $(this).find('select[data-id="properties"] option:selected').val();
                    var ascDesc = $(this).find('select[data-id="sort"] option:selected').val();
                    if (property !== undefined && property !== "") {
                        sorts.push({id: id, property: property, value1: ascDesc});
                    }
                });
                // sorts end

                // groupSorts begin
                var groupSorts = new Array;
                var secondSortGroup = 'groupMain';
                if($('#smartFilteruseGroupingCheck').is(':checked'))
                $('#sf_GroupSorting').find('.b-constructor__row').each(function() {
                    //вытаскиваем сохраняемые данные из строки
                    var id = $(this).attr('id');
                    var property = $(this).find('select[data-id="properties"] option:selected').val();
                    var ascDesc = $(this).find('select[data-id="sort"] option:selected').val();

                  var aggregateGroupFrom = '';
                  let inputsGp1 = $(this).find('input[data-id="aggregateGroupFrom"]');
                  if (inputsGp1[0] != undefined) {
                    aggregateGroupFrom = $(inputsGp1[0]).val();
                  }

                  var aggregateGroupTo = '';
                  let inputsGp2 = $(this).find('input[data-id="aggregateGroupTo"]');
                  if (inputsGp2[0] != undefined) {
                    aggregateGroupTo = $(inputsGp2[0]).val();
                  }

                    if (property !== undefined && property !== "") {
                        if(secondSortGroup === 'subSorting' && groupSorts.length<1){

                        } else {
                            groupSorts.push({id: id, property: property, mode: secondSortGroup, value1: ascDesc,  value2: ''+aggregateGroupFrom+'-'+aggregateGroupTo+''});
                        }

                    }
                    secondSortGroup = 'subSorting';
                });



                // groupSorts end

                // agregate begin
                var agregates = new Array;
                $('#sf_aggregate').find('.b-collapse__body').each(function() {
                    //вытаскиваем сохраняемые данные из строки
                    var id = $(this).attr('id');
                    var property = $(this).find('select[data-id="aggregate-properties"] option:selected').val();
                    var agregateType = $(this).find('select[data-id="aggregate"] option:selected').val();

                    if (property !== undefined && property !== "") {
                        agregates.push({id: id, property: property, value1: agregateType});
                    }
                });
                // agregate end

                // periods begin
                var periods = new Array;
                $('#sf_first_period').find('.b-constructor__row').each(function() {
                    //вытаскиваем сохраняемые данные из строки
                    var id = $(this).attr('id');
                    var property = $(this).find('select[data-id="properties"] option:selected').val();      // table.field
                    var type = $(this).find('.b-constructor__type .active a').attr('data-type');                    // string, number, boolean, date, time
                    var mode = $(this).find('select[data-id="mode"] option:selected').val();                // running, fixed
                    var operand = $(this).find('select[data-id="operand"] option:selected').val();          // >,<,<>,=,between....

                    var checked = $('#sf_first_period').find('#con_and').is(':checked');

                    let andOr, selects, inputs, value1, value2;

                    if (checked === true) {
                        andOr = 'and';
                    } else {
                        andOr = 'or';
                    }


                    if (property !== undefined && property !== "") {
                        if (type === 'date') {
                            if (mode === 'running') {
                                if (operand === '<>' || operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    selects = $(this).find('select[data-id="value"]');
                                    if (selects[0] != undefined) {
                                        value2 = $(selects[0]).val();
                                    }
                                }
                            } else if (mode === 'fixed') {
                                if (operand === 'between') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    if (inputs[1] != undefined) {
                                        value2 = $(inputs[1]).val();
                                    }
                                } else if (operand === '=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                }
                            }
                        } else if (type === 'time') {
                            if (mode === 'running') {
                                if (operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                }
                            } else if (mode === 'fixed') {
                                if (operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                } else if (operand === 'between') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    if (inputs[1] != undefined) {
                                        value2 = $(inputs[1]).val();
                                    }
                                }
                            }
                        }

                        var checked1p = $('#sf_first_period').find('#fperiod_and').is(':checked');

                        if (checked1p === true) {
                            andOr = 'and';
                        } else {
                            andOr = 'or';
                        }

                        periods.push({id: id, property: property, type: type, mode: mode, operand: operand, value1: value1, value2: value2, andOr: andOr});
                    }
                });
                // periods end

                // periods begin
                var periods2 = new Array;
                $('#sf_second_period').find('.b-constructor__row').each(function() {
                    //вытаскиваем сохраняемые данные из строки
                    var id = $(this).attr('id');
                    var property = $(this).find('select[data-id="properties"] option:selected').val();      // table.field
                    var type = $(this).find('.b-constructor__type .active a').attr('data-type');                    // string, number, boolean, date, time
                    var mode = $(this).find('select[data-id="mode"] option:selected').val();                // running, fixed
                    var operand = $(this).find('select[data-id="operand"] option:selected').val();          // >,<,<>,=,between....

                    var checked = $('#sf_second_period').find('#con_and').is(':checked');

                    let andOr, selects, inputs, value1, value2;
                    if (checked === true) {
                        andOr = 'and';
                    } else {
                        andOr = 'or';
                    }

                    if (property !== undefined && property !== "") {
                        if (type === 'date') {
                            if (mode === 'running') {
                                if (operand === '<>' || operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    selects = $(this).find('select[data-id="value"]');
                                    if (selects[0] != undefined) {
                                        value2 = $(selects[0]).val();
                                    }
                                }
                            } else if (mode === 'fixed') {
                                if (operand === 'between') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    if (inputs[1] != undefined) {
                                        value2 = $(inputs[1]).val();
                                    }
                                } else if (operand === '=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                }
                            }
                        } else if (type === 'time') {
                            if (mode === 'running') {
                                if (operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                }
                            } else if (mode === 'fixed') {
                                if (operand === '<=' || operand === '>=') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                } else if (operand === 'between') {
                                    inputs = $(this).find('input[data-id="value"]');
                                    if (inputs[0] != undefined) {
                                        value1 = $(inputs[0]).val();
                                    }
                                    if (inputs[1] != undefined) {
                                        value2 = $(inputs[1]).val();
                                    }
                                }
                            }
                        }

                        periods2.push({id: id, property: property, type: type, mode: mode, operand: operand, value1: value1, value2: value2, andOr: andOr});
                    }
                });
                // periods2 end

                // контекст привязал к progressWizard
                let context;
                if ($('#progressWizard').html() === undefined) {
                    context = 'backend';
                } else {
                    context = 'objects';
                }


                let checkboxes = document.getElementsByClassName('checkboxForMuColumn');
                let checkboxesChecked = {};
                for (let index = 0; index < checkboxes.length; index++) {
                    if (checkboxes[index].checked) {
                        let arrayOfstr = (checkboxes[index].value).split(':');
                        if (typeof checkboxesChecked[arrayOfstr[0]] == 'undefined') {
                            checkboxesChecked[arrayOfstr[0]] = [];
                        }
                        checkboxesChecked[arrayOfstr[0]].push(arrayOfstr[1]);
                    }
                }

                let strJsonWithColumns = JSON.stringify(checkboxesChecked);

                $.ajax({
                    url: '/backend/savesmartfilter',
                    type: 'POST',
                    data: {
                        appId: $('#appid').val(),
                        smartFilterId: smartFilterId,
                        smartUserId: smartUserId,
                        smartName: smartName,
                        smartMeasure: smartMeasure,
                        smartDescription: smartDescription,
                        conditions: JSON.stringify(conditions),
                        sorts: JSON.stringify(sorts),
                        groupSorts: JSON.stringify(groupSorts),
                        agregates: JSON.stringify(agregates),
                        periods: JSON.stringify(periods),
                        periods2: JSON.stringify(periods2),
                        context: context
                    },
                    beforeSend: function() {}
                }).done(function(a,b,c) {
                    var obj = {};

                    try {
                        obj = JSON && JSON.parse(a) || $.parseJSON(a);
                    } catch (error) {
                        logger.exception(error, a);
                    }

                    if (obj.error !== undefined && obj.error === '') {

                        let t = this;
                        const auth = Cookie.getJSON('__mbst_authdata');
                        let accessToken = auth['accessToken'];
                        $.ajax({
                            url: '/api/v8/filters/set/columns',
                            type: 'POST',
                            data: {
                                applicationId: $('#appid')
                                  .val(),
                                filterName: smartName,
                                selectColumns: strJsonWithColumns,
                            },
                            headers: {
                                'Authorization': 'Bearer ' + accessToken,
                            },
                            beforeSend: function () {
                            },
                        })
                          .done(function (a1, b1, c1) {
                              grit(lang('Success'), 'growl-success');
                          })
                          .fail(function (a1, b1, c1) {
                          })
                          .always(function (a1, b1, c1) {
                          });

                        if (obj.trHtml === "" && obj.widgetHtml === "") {
                            // эта веточка означает что мы только оредактировали уже существующий фильтр
                            $('tr[data-filterid="'+smartFilterId+'"]').find('#trSmartFilterName').html(smartName);
                            $('.b-smart-filters__item#'+smartFilterId+' .b-smart-filters__name').html(smartName);
                            $('.b-smart-filters__item#'+smartFilterId+' .b-smart-filters__cell--unit').html(smartMeasure);
                            $('.b-smart-filters__item#'+smartFilterId+' .tooltiptext').html(smartDescription);


                        } else {
                            // а тут мы добавляем потрошки нового
                            $('.b-create-sf__list tbody').append(obj.trHtml);
                            $('.b-smart-filters__items').append(obj.widgetHtml);
                            window.updateFontSize();
                            window.heightSmartFilters();
                            // здесь нажмем на свежий фильтр, чтобы он правильно включился и сработали все триггеры при этом
                            var lastPlashka = $('.b-smart-filters__item').last();
                            lastPlashka.find('.b-smart-filters__inner').click();
                        }

                        if (_.isArray(obj.hashtags)) {
                            MOBSTEDAPP.staticHashtags = obj.hashtags;
                            HashTags.initHashtags();
                        }

                        that.destroyDialog(windowDialog);
                    } else {
                        grit(obj.error, 'growl-danger');
                    }
                }).fail(function(a,b,c){
                }).always(function(a,b,c){
                });
            }
        });

        $(document).on('hidePicker.colorpicker', '#statuspicker1', function(e) {
            $('#statuspicker1').change();
        });
        $(document).on('hidePicker.colorpicker', '#statuspicker2', function(e) {
            $('#statuspicker2').change();
        });

        // $(document).on('shown.bs.tab', '.b-modal-hero__heading-tab [data-toggle="tab"]', function (e) {
        //     var modalHero = $(e.target).closest('.b-modal-hero__inner');
        //     that.cssModalHero(modalHero);
        // });

        $(document.body).on('click', '#smartfilterdelete', function () {

            var windowDialog = $(this).closest('.details');
            if ($(this).data('path')) {
                var filterId = $(this).data('path');
            }

            $.ajax({
                url: "/backend/smartfilterdelete/",
                type: "POST",
                data: {filterId: filterId, appId: MOBSTEDAPP.appId}
            }).done(function (a, b, c) {
                var res = {};

                try {
                    res = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (res.error === '') {
                    grit(lang('Delete success'), 'growl-success');
                    $('tr[data-filterid="' + filterId + '"]').remove();
                    $('#' + filterId + '.b-smart-filters__item').remove();
                    if (_.isArray(res.hashtags)) {
                        MOBSTEDAPP.staticHashtags = res.hashtags;
                        HashTags.initHashtags();
                    }
                } else {
                    grit(lang('Delete fail'), 'growl-fail');
                }
            }).fail(function (a, b, c) {
                grit(lang('Delete fail'), 'growl-fail');
            }).always(function (a, b, c) {
                destroyDialog(windowDialog);
            });
        });
        $(document.body).on('click', '#deletecancel', function () {
            var windowDialog = $(this).closest('.details');
            destroyDialog(windowDialog);
        });
    },

    cssModalHero: function(modalHero) {
        var $modalHeroInner = $(modalHero),
            $modalHeroBody,
            isTabModal = false,
            heightHeader = $modalHeroInner.find('.b-modal-hero__heading').outerHeight(),
            heightFooter,
            heightBody;

        // модальное окно с табами
        if ($modalHeroInner.hasClass('b-modal-hero--tab')) {
            isTabModal = true;
        }

        if (isTabModal) {
            var $activePane = $modalHeroInner.find('.b-modal-hero__tab-pane.active');

            $activePane.find('.b-global-box').each(function() {
                Utils.globalBoxInit($(this));
            });

            if ($modalHeroInner.hasClass('col-md-12')) {

                $modalHeroInner.css({
                    // 'padding-bottom': heightHeader + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                var $modalHeroFooter = $activePane.find('.b-modal-hero__footer');
                heightFooter = $modalHeroFooter.outerHeight();

                if ($modalHeroFooter) {
                    // $activePane.css("padding-bottom", heightFooter);
                    $modalHeroFooter.css("top", -heightFooter + "px");
                }

            } else {

                $modalHeroInner.find('.b-modal-hero__body').css("height", "auto");
                $modalHeroInner.find('.b-modal-hero__tab-content').css({
                    height: "auto",
                    overflow: "auto"
                });
                // $modalHeroInner.css({
                //     'padding-bottom': (hAll - hBody) + 'px',
                // });

            }

        } else {
            $modalHeroBody = $modalHeroInner.find('.b-modal-hero__body');
            // внутренняя часть модального окна с фиксированной напкой
            if ($modalHeroBody.hasClass('b-modal-hero__body--fix-head')) {
                isFixHeadBodyModal = true;
            }


        }
    },

    modalView: function(url, type, data, grid) {

        var t = this;

        $.ajax({
            url: url,
            type: type,
            data: data,
        }).done(function (a, b, c) {
            var obj = {};

            try {
                obj = JSON && JSON.parse(a) || $.parseJSON(a);
            } catch (error) {
                logger.exception(error, a);
            }

            if (obj.error === "") {

                if (grid === 'create-sf' || grid === 'edit-sf') {
                    t.showDialog('#modal-hero', obj.html);
                    SmartFilters.createSmartFilter.init();

                    if (obj.triggers && obj.apiproviders && obj.apikeys)
                        ClientApp.triggersInit(obj.triggers, obj.apiproviders, obj.apikeys, obj.apiapps);

                    if (grid === 'create-sf') {
                        $('.details').find('#smartFilterName').focus().select();
                    }
                } else {
                    t.showDialog('#modal-hero', obj.html);
                }

            } else {
                $.gritter.add({
                    title: lang('Failed'),
                    text: '',
                    class_name: 'growl-danger',
                    sticky: false,
                    time: '500'
                });
            }
        }).fail(function (a, b, c) {
            $.gritter.add({
                title: lang('Failed'),
                text: '',
                class_name: 'growl-danger',
                sticky: false,
                time: '500'
            });
        }).always(function (a, b, c) {
        });

    },

    destroyDialog: function(context) {

        var that = this;

        if ($('#grid_refresh').val() === '1'){
            //$.fn.yiiGridView.update("my-model-grid");
            $('.b-main-box__footer').removeClass('b-main-box__footer--active');
            countSelected = 0;
        }
        $(document.body).off('click', '.b-legend__item .add-action');
        $(document.body).off('click', '#preview-msg');
        $(document.body).off('click', '#bcappdelete');
        $(document.body).off('click', '[data-toggle="createapp"]');

        $('#container1').remove();
        $('#iframe1').remove();
        $('#buttonM1').remove();
        $('#closer1').remove();

        SmartFilters.createSmartFilter.unbind();
        ClientApp.triggersDestroy();

        //$(document.body).off('change', '.b-conditions-row__select--property');
        //$(document.body).off('click', '.b-conditions-row__delete');
        //$(document.body).off('click', '.b-conditions__btn');

        context.hide();

        if (commentInterval !== undefined) {
            clearInterval(commentInterval);
        }

        //$('#statuspicker1').colorpicker('destroy');
        //$('#statuspicker2').colorpicker('destroy');
        $( "#statusname" ).unbind();
        $( "#statussortorder" ).unbind();
        //$('#statuspicker1').colorpicker('destroy');
        //$('#statuspicker2').colorpicker('destroy');
        $( "#statuspicker1" ).unbind();
        $( "#statuspicker2" ).unbind();

        $( "#username" ).unbind();
        $( "#useremail" ).unbind();
        $( "#userphone" ).unbind();
        $( "#userpin" ).unbind();
        $( "#superuser" ).unbind();
        $( ".security" ).unbind();
        $( ".actionsecurity" ).unbind();

    },

    showDialog: function(object, html) {
        var t = this;
        $(object)
            .html(html)
            .fadeIn(150);

        window.setTimeout(function () {
            $(".js-autofill-off").hide();
        },1);

        var $modalHero = $(object),
            $modalHeroInner = $modalHero.find('.b-modal-hero__inner'),
            isTabModal = false,
            heightHeader = $modalHeroInner.find('.b-modal-hero__heading').outerHeight(),
            heightFooter,
            heightBody,


            hHeading = heightHeading($(object+' .details-heading')),
            hFooter = heightHeading($(object+' .details-footer')),
            hBody = $(object+' .details-wrapper').hasClass('col-md-12') ? '' : heightHeading($(object+' .details-body')),
            hAll = hHeading + hFooter + hBody;

        if ($modalHeroInner.hasClass('b-modal-hero--tab')) {
            isTabModal = true;
        }

        if (isTabModal) {
            var $activePane = $modalHeroInner.find('.b-modal-hero__tab-pane.active');
            if ($modalHeroInner.hasClass('col-md-12')) {
                $modalHeroInner.css({
                    // 'padding-bottom': heightHeader + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                var $modalHeroFooter = $activePane.find('.b-modal-hero__footer');
                heightFooter = $modalHeroFooter.outerHeight();
                if ($modalHeroFooter) {
                    // $activePane.css("padding-bottom", heightFooter);
                    $modalHeroFooter.css("top", -heightFooter + "px");
                }
            } else {
                $activePane.find('.b-modal-hero__body').css("height", "auto");
                $modalHeroInner.find('.b-modal-hero__tab-content').css({
                    height: "auto",
                    overflow: "auto"
                });
                // $modalHeroInner.css({
                //     'padding-bottom': (hAll - hBody) + 'px',
                // });
            }

        } else {

            if ( $(object+' .details-wrapper').hasClass('col-md-12') ) {
                $(object+' .details-wrapper').css({
                    // 'padding-bottom': (hAll - hBody) + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                $(object+' .details-body').css('height', '100%');
            } else if ($(object+' .details-wrapper').attr('id') === "donosetheightinJS") {
                $(object+' .details-wrapper').css({
                    // 'padding-bottom': (hAll - hBody) + 'px',
                    'height': 'auto'
                });
            } else {
                $(object+' .details-wrapper').css({
                    // 'padding-bottom': (hAll - hBody) + 'px',
                    'height': hAll + 'px'
                });
            }

            $(object + ' .details-footer').css('top', - hFooter + 'px');

        }


        $(object + ' .selectDefault').select2({
            minimumResultsForSearch: -1
        });
        var delay = (function(){
            var timer = 0;
            return function(callback, ms){
                clearTimeout (timer);
                timer = setTimeout(callback, ms);
            };
        })();

        $('#statuspicker1').colorpicker({
            format: 'hex',
            customClass: 'colorpicker-2x',
            sliders: {
                saturation: {
                    maxLeft: 200,
                    maxTop: 200
                },
                hue: {
                    maxTop: 200
                },
                alpha: {
                    maxTop: 200
                }
            }
        }).on('changeColor', function (ev) {
            $('#statuspicker1').attr('style', 'cursor:pointer; background-color:' + ev.color.toHex() + '; color:' + ev.color.toHex() + ';');
            $("#statusstyleexample").attr("style", 'background-color:' + $('#statuspicker2').val() + '; color:' + $('#statuspicker1').val() + ';');
        });

        $('#statuspicker2').colorpicker({
            format: 'hex',
            customClass: 'colorpicker-2x',
            sliders: {
                saturation: {
                    maxLeft: 200,
                    maxTop: 200
                },
                hue: {
                    maxTop: 200
                },
                alpha: {
                    maxTop: 200
                }
            }
        }).on('changeColor', function (ev) {
            $('#statuspicker2').attr('style', 'cursor:pointer; background-color:' + ev.color.toHex() + '; color:' + ev.color.toHex() + ';');
            $('#statusstyleexample').attr('style', 'background-color:' + $('#statuspicker2').val() + '; color:' + $('#statuspicker1').val() + ';');
        });

        // Модальное окно редактирования статуса
        $('#ObjectColumns_ColumnName').on('change', function (event) {
            if (event.type == 'change'){
                let that = this;
                Saver({ ColumnName: $(that).data('value'), NewColumnName:$(that).val() , id: $(that).data('appid'), protectedcolumn:''},'/objects/renamecolumn');
                refreshObjectColumnSettings($(that).data('appid'));
            }
        });


        inputMask({alias: 'phone', androidHack: "rtfm"}).mask($('[data-js-mask="phone"]'));

    }
}

window.modalView = function(url, type, data, grid) {

        console.log(url);
        $.ajax({
            url: url,
            type: type,
            data: data,
            beforeSend: function (xhr) {
            }
        }).done(function (a, b, c) {
            try {
                var obj = {};

                try {
                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                } catch (error) {
                    logger.exception(error, a);
                }

                if (obj.error === "") {
                    if (grid === "search") {
                        var searchInput = $('#top-search').val(),
                            searchData = {query: searchInput};

                        if (searchInput !== '') {
                            searchSend('/applications/searchapp/', searchData, '#sb-app');
                            searchSend('/applications/searchobj/', searchData, '#sb-obj');
                            searchSend('/applications/searchbackend/', searchData, '#sb-bc');
                            searchSend('/applications/searchconstructor/', searchData, '#sb-cnstr');
                            searchSend('/applications/searchusers/', searchData, '#sb-users');
                            searchSend('/applications/searchstatuses/', searchData, '#sb-stat');
                            $('#keyword').text(searchInput);
                        }
                    } else if (grid.substr(0, 6) === 'tosend') {
                        $('#mes-subject').focus().addClass('paste-action');
                        if (grid === 'tosend-objects') {
                            set_legend(true);
                            $(document.body).on('click', '#preview-msg', function () {
                                var eventid = $('#sendmessage').attr('data-eventid');
                                var forone = $('#sendmessage').attr('data-forone');
                                var ids;
                                var appId = $('#appid').val();
                                var type = $("#sendpoint").val();
                                var formdata = $(".sexybaby").serialize();
                                var startScreenId = "0";

                                if (forone === '1'){
                                    ids = [eventid];
                                } else {
                                    ids = $('#my-model-grid').yiiGridView.getChecked('my-model-grid', 'checkboxes[]');
                                }



                                if ($("#startScreenDiv1").attr('style') === "") {
                                    startScreenId = $("#startScreenDiv1").find("#startScreen").val();
                                } else {
                                    startScreenId = "0";
                                }
                                $.ajax({
                                        url: '/senderpool/previewmessage',
                                        type: 'POST',
                                        data: {type: type, sendIds: ids, appId: appId, formdata: formdata, startScreenId: startScreenId}
                                    })
                                    .done(function (a, b, c) {
                                        obj = {};
                                        console.log(123);
                                        try {
                                            obj = JSON && JSON.parse(a) || $.parseJSON(a);
                                        } catch (error) {
                                            logger.exception(error, a);
                                        }

                                        if (obj.error === "") {
                                            $('.b-container-preview').append(obj.html);
                                              let smsChecked = $('#msg-method-sms').is(":checked");
                                              if (smsChecked && $('#preview-msg-close').text() !== "") {
                                                $('#div-i3').show();
                                              } else {
                                                $('#div-i3').hide();
                                              }
                                        }
                                    })
                                    .fail(function (a, b, c) {

                                    })
                                    .always(function (a, b, c) {

                                    });
                            });

                            // special for set start screen on paste hashtag #InviteUrl#
                            $(document.body).delegate('#mes-subject, #mes-body', 'input paste change', function () {
                                if (strpos($("#mes-subject").val(), '#Object:InviteUrl#', 0) !== false || strpos($("#mes-body").val(), '#Object:InviteUrl#', 0) !== false) {
                                    $("#startScreenDiv1").attr('style', '');
                                } else {
                                    $("#startScreenDiv1").attr('style', 'display:none;');
                                }
                            });

                        }

                        $(document.body).delegate('#mes-body', 'input paste change', function () {
                            //if ($('#msg-method-sms').is(':checked')) {
                            var formdata = $(".sexybaby").serialize();
                            $.ajax({
                                url: "/senderpool/partperperson/",
                                type: "POST",
                                data: {formdata:formdata},
                                beforeSend: function (xhr) {}
                            }).done(function (a, b, c) {
                                var obj = {};

                                try {
                                    obj = JSON && JSON.parse(a) || $.parseJSON(a);
                                } catch (error) {
                                    logger.exception(error, a);
                                }

                                if (obj.error === "") {
                                    $('#smspartcountindicator').html(obj.html);
                                } else {
                                    $('#smspartcountindicator').html('');
                                }
                            });
                            //}
                        });

                    } else if (grid == 'create-qr-url') {
                        $('#title-invite').focus().addClass('paste-action');
                        set_legend(true);
                    } else if (grid == 'attemptcolumndelete') {
                        $(document.body).on('click', '#attemptdelete-cancel', function () {
                            var windowDialog = $(this).closest('.details');
                            destroyDialog(windowDialog);
                            $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                            $('.b-setup-collumn #properties').html('');
                        });
                    } else if (grid == 'attemptbackendcolumndelete') {
                        $(document.body).on('click', '#attemptbackenddelete-cancel', function () {
                            var windowDialog = $(this).closest('.details');
                            destroyDialog(windowDialog);
                            $('.b-setup-collumn').removeClass('b-setup-collumn--active');
                            $('.b-setup-collumn #properties').html('');
                        });
                    } else if (grid === 'backend') {

                        //при открытии окна скроллим комменты на нормальное положение
                        var comments = $('.ms-message .b-container__body'),
                            hComments = $('#smcomments').height();
                        comments.scrollTop(hComments);

                        //автообновление комментариев в backend-view (на сокеты надо переделывать все это говнище)
                        commentInterval = setInterval(function () {
                            if ($('#sendcomment').length > 0) {

                                var eventId = $('#sendcomment').attr('data-linkid');
                                var appId = $('#id-app').val();

                                $.ajax({
                                    url: "/backend/setnew",
                                    type: "POST",
                                    data: {eventId: eventId, appId: appId},
                                    cache: false
                                }).done(function (a, b, c) {
                                    var answer = {};

                                    try {
                                        answer = JSON && JSON.parse(a) || $.parseJSON(a);
                                    } catch (error) {
                                        logger.exception(error, a);
                                    }

                                    if (answer.error === "") {

                                        var refresh = answer.refresh,
                                            itemComment = $('a[href="#comments"]'),
                                            cCount = itemComment.find('.c-count').text();

                                        if (cCount !== refresh['comments']) {
                                            itemComment.find('.c-count').addClass('update').text(refresh['comments']);
                                        }

                                        var html = answer.html;
                                        for (var x in html) {
                                            if ($('.b-msg-item[data-commentid="' + x + '"]').html() === undefined) {
                                                $('#smcomments').append(html[x][0]);

                                            }
                                        }
                                        comments.scrollTop($('#smcomments').height());

                                    }
                                });
                            }
                        }, 10000);
                    } else if (grid === 'senderpool') {
                        $(document.body).on('click', ' #spool-msg-close', function () {
                            var windowDialog = $(this).closest('.details');
                            destroyDialog(windowDialog);
                        });
                    }

                    if (grid === 'conditions') {
                        showDialog('#modal-hero-inner', obj.html);
                        conditionsObjField();
                    } else if (grid === 'role-conditions') {
                        showDialog('#modal-hero-inner', obj.html);
                        conditionsObjField();
                    } else if (grid === 'alfabankcreate' || grid === 'alfabankedit') {
                        showDialog('#modal-hero', obj.html);
                        $('#accname-alfabank').focus();
                    } else if (grid === 'rfibankcreate' || grid === 'rfibankedit') {
                        showDialog('#modal-hero', obj.html);
                        $('#accname-rfibank').focus();
                    } else if (grid === 'atolcreate' || grid === 'atoledit') {
                        showDialog('#modal-hero', obj.html);
                        $('#accname-atol').focus();
                    } else if (grid === 'braintreecreate' || grid === 'braintreeedit') {
                        showDialog('#modal-hero', obj.html);
                        $('#accname-braintree').focus();
                    } else if (grid === 'stripecreate' || grid === 'stripeedit') {
                        showDialog('#modal-hero', obj.html);
                        $('#accname-stripe').focus();
                    } else {
                        showDialog('#modal-hero', obj.html);
                        if (grid === 'statuses') {
                            inputStatusSaverEvents();
                        } else if (grid === 'users') {
                            inputModalUserEvents();
                        } else if (grid === 'roles') {
                            inputRoleSaverEvents();
                        }
                        if (grid === 'backend') {
                            //Chat.initialize(MOBSTEDAPP.currentChat); чаты в евенте у бц
                        }
                        if (grid === 'usercreate') {
                            inputModalUserEvents();
                        }
                    }

                    if (grid == 'details-template') {
                        $('.b-slider').owlCarousel({
                            items: 5,
                            lazyLoad: true,
                            autoHeight:false,
                            itemsCustom : [
                                [600, 1],
                                [700, 2],
                                [1000, 3],
                                [1200, 4],
                                [1600, 5]
                            ],
                        });
                        $('.b-slider').each(function() { // the containers for all your galleries
                            $(this).magnificPopup({
                                delegate: 'a', // the selector for gallery item
                                type: 'image',
                                gallery: {
                                    enabled:true
                                },
                                removalDelay: 300,
                                mainClass: 'mfp-fade'
                            });
                        });
                    }
                } else grit(lang('Failed'), 'growl-danger');
            }
            catch (e) {
                console.log(e.message);
                grit(lang('Failed'), 'growl-danger');
            }
        }).fail(function (a, b, c) {
            grit(lang('Failed'), 'growl-danger');
        }).always(function (a, b, c) {});
    }

    var commentInterval = undefined;

window.showDialog = function(object, html) {
        $(object)
            .html(html)
            .fadeIn(150);

        window.setTimeout(function () {
            $(".js-autofill-off").hide();
        },1);

        var $modalHero = $(object),
            $modalHeroInner = $modalHero.find('.b-modal-hero__inner'),
            isTabModal = false,
            heightHeader = $modalHeroInner.find('.b-modal-hero__heading').outerHeight(),
            heightFooter,
            heightBody,


            hHeading = heightHeading($(object+' .details-heading')),
            hFooter = heightHeading($(object+' .details-footer')) || 0,
            hBody = $(object+' .details-wrapper').hasClass('col-md-12') ? 0 : heightHeading($(object+' .details-body')),
            hAll = hHeading + hFooter + hBody;

        if ($modalHeroInner.hasClass('b-modal-hero--tab')) {
            isTabModal = true;
        }

        if (isTabModal) {
            var $activePane = $modalHeroInner.find('.b-modal-hero__tab-pane.active');
            if ($modalHeroInner.hasClass('col-md-12')) {
                $modalHeroInner.css({
                    // 'padding-bottom': heightHeader + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                var $modalHeroFooter = $activePane.find('.b-modal-hero__footer');
                heightFooter = $modalHeroFooter.outerHeight();
                if ($modalHeroFooter) {
                    // $activePane.css("padding-bottom", heightFooter);
                    $modalHeroFooter.css("top", -heightFooter + "px");
                }
            } else {
                $activePane.find('.b-modal-hero__body').css("height", "auto");
                $modalHeroInner.find('.b-modal-hero__tab-content').css({
                    height: "auto",
                    overflow: "auto"
                });
                // $modalHeroInner.css({
                //     'padding-bottom': (hAll - hBody) + 'px',
                // });
            }

        } else {

            if ( $(object+' .details-wrapper').hasClass('col-md-12') ) {
                $(object+' .details-wrapper').css({
                    'padding-bottom': (hAll - hBody) + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                $(object+' .details-body').css('height', '100%');
            } else if ($(object+' .details-wrapper').attr('id') === "donosetheightinJS") {
                $(object+' .details-wrapper').css({
                    // 'padding-bottom': (hAll - hBody) + 'px',
                    'height': 'auto'
                });
            } else {
                $(object+' .details-wrapper').css({
                    // 'padding-bottom': (hAll - hBody) + 'px',
                    'height': hAll + 'px'
                });
            }

            $(object + ' .details-footer').css('top', - hFooter + 'px');

        }


        $(object + ' .selectDefault').select2({
            minimumResultsForSearch: -1
        });
        var delay = (function(){
            var timer = 0;
            return function(callback, ms){
                clearTimeout (timer);
                timer = setTimeout(callback, ms);
            };
        })();

        $('#statuspicker1').colorpicker({
            format: 'hex',
            customClass: 'colorpicker-2x',
            sliders: {
                saturation: {
                    maxLeft: 200,
                    maxTop: 200
                },
                hue: {
                    maxTop: 200
                },
                alpha: {
                    maxTop: 200
                }
            }
        }).on('changeColor', function (ev) {
            $('#statuspicker1').attr('style', 'cursor:pointer; background-color:' + ev.color.toHex() + '; color:' + ev.color.toHex() + ';');
            $("#statusstyleexample").attr("style", 'background-color:' + $('#statuspicker2').val() + '; color:' + $('#statuspicker1').val() + ';');
        });

        $('#statuspicker2').colorpicker({
            format: 'hex',
            customClass: 'colorpicker-2x',
            sliders: {
                saturation: {
                    maxLeft: 200,
                    maxTop: 200
                },
                hue: {
                    maxTop: 200
                },
                alpha: {
                    maxTop: 200
                }
            }
        }).on('changeColor', function (ev) {
            $('#statuspicker2').attr('style', 'cursor:pointer; background-color:' + ev.color.toHex() + '; color:' + ev.color.toHex() + ';');
            $('#statusstyleexample').attr('style', 'background-color:' + $('#statuspicker2').val() + '; color:' + $('#statuspicker1').val() + ';');
        });

        // Модальное окно редактирования статуса
        $('#ObjectColumns_ColumnName').on('change', function (event) {
            if (event.type == 'change'){
                let that = this;
                Saver({ ColumnName: $(that).data('value'), NewColumnName:$(that).val() , id: $(that).data('appid'), protectedcolumn:''},'/objects/renamecolumn');
                refreshObjectColumnSettings($(that).data('appid'));
            }
        });


        inputMask({alias: 'phone', androidHack: "rtfm"}).mask($('[data-js-mask="phone"]'));

    }
window.destroyDialog = function(context) {
        if ($('#grid_refresh').val() === '1'){
            //$.fn.yiiGridView.update("my-model-grid");
            $('.b-main-box__footer').removeClass('b-main-box__footer--active');
            countSelected = 0;
        }
        $(document.body).off('click', '.b-legend__item .add-action');
        $(document.body).off('click', '#preview-msg');
        $(document.body).off('click', '#bcappdelete');
        $(document.body).off('click', '[data-toggle="createapp"]');

        $('#container1').remove();
        $('#iframe1').remove();
        $('#buttonM1').remove();
        $('#closer1').remove();

        $('#statuspicker1').colorpicker('destroy');
        $('#statuspicker2').colorpicker('destroy');

        //$(document.body).off('change', '.b-conditions-row__select--property');
        //$(document.body).off('click', '.b-conditions-row__delete');
        //$(document.body).off('click', '.b-conditions__btn');

        context.hide();

        if (commentInterval !== undefined) {
            clearInterval(commentInterval);
        }


        $( "#statusname" ).unbind();
        $( "#statussortorder" ).unbind();
        $( "#statuspicker1" ).unbind();
        $( "#statuspicker2" ).unbind();

        $( "#username" ).unbind();
        $( "#useremail" ).unbind();
        $( "#userphone" ).unbind();
        $( "#userpin" ).unbind();
        $( "#superuser" ).unbind();
        $( ".security" ).unbind();
        $( ".actionsecurity" ).unbind();
    }

window.strpos = function(haystack, needle, offset) {
        //  discuss at: http://phpjs.org/functions/strpos/
        // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // improved by: Onno Marsman
        // improved by: Brett Zamir (http://brett-zamir.me)
        // bugfixed by: Daniel Esteban
        //   example 1: strpos('Kevin van Zonneveld', 'e', 5);
        //   returns 1: 14

        var i = (haystack + '')
            .indexOf(needle, (offset || 0));
        return i === -1 ? false : i;
    }

window.initFilter = function(data, filterRow) {
        /** и тут чтобы не дублировать просто переключаем урл на roles */
        $.ajax({
            //url: "/userfilters/addorupdatefilter/",
            url: "/roles/addorupdatefilter/",
            type: "POST",
            data: data,
            beforeSend: function() {}
        }).done(function(a, b, c) {
            let obj = {};

            try {
                obj = JSON && JSON.parse(a) || $.parseJSON(a);
            } catch (error) {
                logger.exception(error, a);
            }

            if (obj.error === "") {
                filterRow.attr('data-filterid', obj.id);
            }
        });
    }

window.conditionsObjField = function() {

        $('.b-conditions-row__select--property').each(function() {
            if ($(this).attr('class') !== 'b-conditions-row__select--property select2-hidden-accessible') {
                $(this).select2({
                    placeholder: lang("Property")
                });
            }
        });

        $('.b-conditions-row__select--value').each(function() {
            if ($(this).attr('class') !== 'b-conditions-row__select--value select2-hidden-accessible') {
                $(this).select2({
                    placeholder: lang("Property"),
                    tags: true
                });
            }
        });

        $('.b-conditions-row__select--con').each(function() {
            if ($(this).attr('class') !== 'b-conditions-row__select--con select2-hidden-accessible') {
                $(this).select2({
                    minimumResultsForSearch: -1
                });
            }
        });


    }
