'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Views
var BootstrapModalView = require('views/bootstrap-modal/ModalView');

// Templates
var _modalBody = require('text!bcTemplates/trigger/modal-body.html');

module.exports = BootstrapModalView.extend({

    events: {
        'click [data-js-toggle="executeTrigger"]': 'executeTrigger',
        'click [data-js-toggle="cancelExecutingTrigger"]': 'cancelExecutingTrigger'
    },

    renderInner: function() {
        this.modalBodyTpl = _.template(_modalBody);
        this.data.body = this.modalBodyTpl({text: this.model.get('text')});
        this.render();
        console.log('view');
        console.log(this);
    },

    executeTrigger: function(e) {
        e.preventDefault();
        this.parentView.model.set({
            Active: true,
            State: 'Scheduled'
        });
        this.parentView.save();
    },

    cancelExecutingTrigger: function(e) {
        e.preventDefault();
        console.log(this);
        this.parentView.model.set({
            State: 'Idle'
        });
        this.parentView.save();
    }

});