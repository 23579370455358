'use strict';

// Libraries
const _ = require('underscore');
const Backbone = require('backbone');

// Models
const ApiProviderMethodModel = require('models/ApiProviderMethodModel');

// Views
const APIProviderMethodsItemView = require('views/apiproviders/accordion/ApiProviderMethodsItemView');

// Templates
const _apiProviderMethods = require('text!bcTemplates/apiproviders/apiProviderMethods.html');

module.exports = Backbone.View.extend({

  tagName: 'ul',
  className: 'b-api-pm',

  events: {
    'click .js-toggle-cpllapse': 'toggleCollapse',
  },

  initialize: function (options) {
    this.tpl = _.template(_apiProviderMethods);
    this.apiProviderId = options.apiProviderId;
    this.apiProviderMethodId = options.apiProviderMethodId;
    this.parentView = options.parentView;
    this.itemView = [];

    this.setActive(this.apiProviderMethodId);
  },

  toggleCollapse: function (e) {
    const $link = $(e.currentTarget);

    if ($link.hasClass('is-open')) {
      $link.removeClass('is-open');
      this.$('ul').slideUp(250);
    } else {
      $link.addClass('is-open');
      this.$('ul').slideDown(250);
    }
  },

  setActive: function (id) {
    _.each(this.model.get('APIProviderMethods'), (method) => {
      method.isActive = (id === method.id);
    });
  },

  render: function () {
    const ApiProviderMethodsCollection = Backbone.Collection.extend({model: ApiProviderMethodModel});
    const apiProviderMethodsCollection = new ApiProviderMethodsCollection(this.model.get('APIProviderMethods'));
    const isList = (apiProviderMethodsCollection.length > 0);

    const $apiProviderMethodsItem = _.map(apiProviderMethodsCollection.models, (model) => {
      const apiProviderMethodsItemView = new APIProviderMethodsItemView({
        parentView: this,
        triggerOperationSettingsView: this.parentView.triggerOperationSettingsView,
        model: model,
      });

      this.itemView.push(apiProviderMethodsItemView);
      apiProviderMethodsItemView.render();

      return apiProviderMethodsItemView.$el;
    });

    this.$el.html(this.tpl({
      data: this.model.toJSON(),
      isList: isList,
    }));

    if ($apiProviderMethodsItem.length >= 7) {
      this.$el.addClass(`${this.className}_scrollable`);
    }
    if (isList) {
      this.$('ul').append($apiProviderMethodsItem);
    }
  },

});
