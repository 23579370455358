'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Models
var ExecutionModel = require('models/ExecutionModel');

// Views
var ExecutionItemView = require('views/execution/ExecutionItemView');

// Templates
var _execution = require('text!bcTemplates/execution/execution.html');

module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'b-execution',

    events: {
        'click [data-js-toggle="addExecutionItem"]': 'addItem'
    },

    initialize: function(options) {
        var t = this;
        t.tpl = _.template(_execution);
        t.parentView = options.parentView;
        t.itemViews = [];

        var ExecutionCollection = Backbone.Collection.extend({
            model: ExecutionModel
        });
        t.collection = new ExecutionCollection(options.collection);

        this.listenTo(this.collection, 'remove', this.setExecution, this);
        this.listenTo(this.collection, "change", this.setExecution, this);
    },

    render: function() {
        var t = this;
        t.$el.empty();

        t.$el.html(t.tpl({
            apiKeys: MOBSTEDAPP.APIKeys,
            apiApps: MOBSTEDAPP.APIApps
        }));

        var $executionItems = _.map(t.collection.models, function(model) {
            var executionItemView = new ExecutionItemView({
                parentView: t,
                model: model
            });
            t.itemViews.push(executionItemView);
            executionItemView.render();
            return executionItemView.$el;
        });

        t.$('.b-execution__items').append($executionItems);
    },

    addItem: function(e) {
        e.preventDefault();
        var executionModel = new ExecutionModel();
        this.collection.add(executionModel);

        var executionItemView = new ExecutionItemView({
            parentView: this,
            model: executionModel
        });
        this.itemViews.push(executionItemView);
        executionItemView.render();

        this.$('.b-execution__items').append(executionItemView.$el);

        executionItemView.$('[data-js-toggle="editExecutionField"]').trigger('click');
    },

    setExecution: function() {
        var paramsValues = {}, extendedParams = [];
        _.each(this.collection.models, function(model) {
            paramsValues[model.get('name')] = model.get('value');
            if (!model.get('isSystem')) {
                extendedParams.push(_.omit(model.toJSON(), ['value', 'isSystem']));
            }
        });
        this.parentView.model.set({
            'ParamsValues': paramsValues,
            'ExtendedParams': extendedParams
        });
    }

});