import { backgroundDefault } from './helper';

export default {
  name: 'mbst-slider__slide',
  aliasName: 'SlideItem',
  properties: {
    backendname: 'slide',
    text: '',
    visibility: {
      conditions: [],
      hidden: false
    },
    loop: {
      isEnabled: false,
      dataSource: null,
      aliasName: null
    }
  },
  css: {
    ios: [
      {
        selector: '.mbst-slider__slide',
        rules: [
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-slider__slide',
        rules: [
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ]
  },
  components: []
}
