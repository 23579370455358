import logger from 'general/logger';
import isString from 'lodash/isString'

// import Vue from 'vue'
// import { QSelect } from 'quasar'

const Datepicker = require('refactor/lib/datepicker');
const Timepicker = require('refactor/lib/timepicker');

module.exports = {

  /* global function: window.updateFontSize() - подгонит размер шрифта | window.heightSmartFilters() - подгонит высоту фильтров */

  init: function () {
    this.bind();
    this.sortable();
  },

  sortable: function () {
    $('.b-smart-filters__items').sortable({
      connectWith: '.b-smart-filters__items',
      handle: '.b-smart-filters__header',
      cancel: '.b-smart-filters__menu',
      placeholder: 'b-smart-filters__item b-smart-filters__item--placeholder',
      tolerance: 'pointer',
      start: (event, ui) => {
        const item = ui.item;
        const itemHeader = item.find('.b-smart-filters__header');

        item.css('padding-bottom', itemHeader.outerHeight()).addClass('b-smart-filters__item--drag');
        ui.placeholder.css('height', item.outerHeight());
        $('.b-main-box__inner').css('overflow-x', 'hidden');
        $('.b-smart-filters__items').addClass('b-smart-filters__items--sortable');
      },
      stop: (event, ui) => {
        ui.item.css('padding-bottom', 0).removeClass('b-smart-filters__item--drag');
        $(event.currentTarget).sortable('serialize');
        $('.b-main-box__inner').css('overflow-x', 'auto');
        $('.b-smart-filters__items').removeClass('b-smart-filters__items--sortable');

        const smartFilterId = ui.item.attr('id');
        const prevFilterId = ui.item.prev().attr('id');
        const nextFilterId = ui.item.next().attr('id');

        this.sortFilter(smartFilterId, prevFilterId, nextFilterId);
      },
    });
  },

  bind: function () {

    // Обновить данные смарт-фильтра при нажатии на строку снизу
    $(document.body).on('click', '.b-smart-filters__last-update', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const filterDiv = $(e.currentTarget).closest('.b-smart-filters__item');
      const idSmartFilter = filterDiv.attr('id');

      if (
        filterDiv.attr('class') === 'b-smart-filters__item b-smart-filters__item--active'
        || filterDiv.attr('class') === 'b-smart-filters__item b-smart-filters__item--active b-smart-filters__item--menu'
      ) {
        // обновить вместе с гридом, потому что фильтр активный
        this.updateFilter(idSmartFilter, true);
      } else {
        // обновить только цифры на плашке виджета, потому что фильтр не активный
        this.updateFilter(idSmartFilter, false);
      }
    });

    // Обновить данные смарт-фильтра при нажатии на пункт меню
    $(document.body).on('click', '.b-smart-filters__menu #filterrefreshinmenu', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const filterDiv = $(e.currentTarget).closest('.b-smart-filters__item');
      const idSmartFilter = filterDiv.attr('id');

      if (
        filterDiv.attr('class') === 'b-smart-filters__item b-smart-filters__item--active'
        || filterDiv.attr('class') === 'b-smart-filters__item b-smart-filters__item--active b-smart-filters__item--menu'
      ) {
        // обновить вместе гридом, потому что фильтр активный
        this.updateFilter(idSmartFilter, true);
      } else {
        // обновить только цифры на плашке виджета, потому что фильтр не активный
        this.updateFilter(idSmartFilter, false);
      }
    });

    // Переключение по фильтрам
    $(document.body).on('click', '.b-smart-filters__item > .b-smart-filters__inner', (e) => {
      e.preventDefault();

      const idSmartFilter = $(e.currentTarget).closest('.b-smart-filters__item').attr('id');

      this.toggleFilter(idSmartFilter);
    });

    // скрытие фильтра с помощью контекстного меню фильтра
    $(document.body).on('click', '.b-smart-filters__menu a#filterhideinmenu', (e) => {
      e.preventDefault();

      const state = 'false';
      const filterId = $(e.currentTarget).parents('.b-smart-filters__item').attr('id');
      const context = $(e.currentTarget).attr('data-context');

      this.visibleUnvisibleSmartFilter(state, filterId, context);
    });

    // скрытие/показ фильтра с помощью меню в таблице доступных фильтров
    $(document.body).on('click', '.b-create-sf__list .b-create-sf__item input[type="checkbox"]', (e) => {
      const state = $(e.currentTarget).is(':checked');
      const filterId = $(e.currentTarget).parents('tr').attr('data-filterid');
      const context = $(e.currentTarget).attr('data-context');

      this.visibleUnvisibleSmartFilter(state, filterId, context);
    });

    // Добавление нового фильтра
    $(document.body).on('click', '#smart-filters-add', (e) => {
      e.preventDefault();

      $('.b-create-sf').addClass('b-create-sf--active');
      $('.b-main-box__header').css('position', 'static');
      $('.b-main-box__body, .b-main-box__footer--active, .b-main-box__info-grid, .mobsted-tools-t, .mobsted-tools-b').addClass('el--hide');
    });

    // Отмена добавления нового фильтра
    $(document.body).on('click', '#smart-filters-cancel', (e) => {
      e.preventDefault();

      $('.b-create-sf').removeClass('b-create-sf--active');
      $('.b-main-box__header').css('position', 'relative');
      $('.b-main-box__body, .b-main-box__footer--active, .b-main-box__info-grid, .mobsted-tools-t, .mobsted-tools-b').removeClass('el--hide');
    });

    // Скрытие панели смарт фильтров
    $(document.body).on('click', '#smart-filters-hide', (e) => {
      e.preventDefault();

      const smartFilters = $('.b-smart-filters__items');
      const $this = $(e.currentTarget);
      const iconBtn = $this.find('i');
      const textBtn = $this.find('span');
      let state;

      if (!smartFilters.hasClass('b-smart-filters__items--hide')) {
        smartFilters.addClass('b-smart-filters__items--hide');
        iconBtn.attr('class', 'fa fa-eye');
        textBtn.text(lang('Show filters'));
        state = 1;
      } else {
        smartFilters.removeClass('b-smart-filters__items--hide');
        iconBtn.attr('class', 'fa fa-eye-slash');
        textBtn.text(lang('Hide filters'));
        state = 0;
      }

      const asObject = $('[data-grid="import-objects"]').html();
      const type = (asObject === undefined) ? 'backend' : 'objects';
      const appId = $('#appid').val();

      // сохраним выбор юзера
      $.ajax({
        url: '/backend/savestatefilterspanel',
        type: 'POST',
        data: {
          appId,
          type,
          state,
        },
        beforeSend: () => {},
      })
        .done((a, b, c) => {
          let obj = {};

          try {
            obj = JSON && JSON.parse(a) || $.parseJSON(a);
          } catch (error) {
            logger.exception(error, a);
          }

          if (obj.error !== undefined && obj.error === '') {
            // И что? O_o
          }
        })
        .fail((a, b, c) => {})
        .always((a, b, c) => {});
    });

    // Меню в смарт фильтрах
    $(document.body).on('click', '.b-smart-filters__header > .b-smart-filters__menu', (e) => {
      e.preventDefault();

      $(e.currentTarget).closest('.b-smart-filters__item').toggleClass('b-smart-filters__item--menu').siblings().removeClass('b-smart-filters__item--menu');
    });

    $(document.body).on('click', '.b-smart-filters__menu li a, .b-smart-filters__menu li i', (e) => {
      e.stopPropagation();
    });

    $(document.body).keyup((e) => {
      if (e.keyCode === 27) {
        const windowDialog = $('.details');

        destroyDialog(windowDialog);
      }
    });
  },


  // Переключение по фильтрам
  // ----------------------------------------------------
  toggleFilter: function (idSmartFilter) {
    const state = ($(`#${idSmartFilter}`).hasClass('b-smart-filters__item--active')) ? 0 : 1;
    const context = ($('#gridclass').val() === 'Backend') ? 'backend' : 'objects';
    const appId = $('#appid').val();

    $.ajax({
      url: '/backend/enablesmartfilter',
      type: 'POST',
      data: {
        context,
        state,
        smartFilterId: idSmartFilter,
        appId,
      },
      beforeSend: () => {
        if (state === 1) {
          $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update span').text(lang('update...'));
          this.spinnerFilter(idSmartFilter);
        }
      },
    })
      .done((a, b, c) => {
        let obj = {};

        try {
          obj = JSON && JSON.parse(a) || $.parseJSON(a);
        } catch (error) {
          logger.exception(error, a);
        }

        if (obj.error !== undefined && obj.error === '') {
          if (state === 1) {
            $(`#${idSmartFilter}`).addClass('b-smart-filters__item--active').siblings('.b-smart-filters__item').removeClass('b-smart-filters__item--active');
            this.updateFilter(idSmartFilter, true);
          } else {
            $(`#${idSmartFilter}`).removeClass('b-smart-filters__item--active');
            this.updateFilter(0, true);
          }
        }
      })
      .fail((a, b, c) => {})
      .always((a, b, c) => {
        $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update span').text(lang('just now '));
        this.spinnerFilterDisable(idSmartFilter);
      });
  },


  // Скрытие / показ фильтра
  // ----------------------------------------------------
  visibleUnvisibleSmartFilter: function (state, filterId, context) {
    $.ajax({
      url: '/backend/visibleunvisiblesmartfilter',
      type: 'POST',
      data: {
        state: state,
        filterId: filterId,
        context: context,
      },
    })
      .done((a, b, c) => {
        let obj = {};

        try {
          obj = JSON && JSON.parse(a) || $.parseJSON(a);
        } catch (error) {
          logger.exception(error, a);
        }

        if (obj.error !== '') {
          grit(lang(obj.error), 'growl-danger');
          return false;
        }

        grit(lang('Changed'), 'growl-success');

        const $filter = $(`#${filterId}.b-smart-filters__item`);
        let $newTag;

        if (state === true) {
          if ($filter.attr('data-context') === context) {
            $filter.removeClass('is-hide');
            $newTag = $('<div/>');
            $.each($filter.get(0).attributes, (i, attr) => {
              $newTag.attr(attr.name, attr.value);
            });
            $newTag.html($filter.html());
            $filter.replaceWith($newTag);
          }
        } else {
          if ($filter.attr('data-context') === context) {
            $filter.addClass('is-hide').removeClass('b-smart-filters__item--menu');
            $newTag = $('<span/>');
            $.each($filter.get(0).attributes, (i, attr) => {
              $newTag.attr(attr.name, attr.value);
            });
            $newTag.html($filter.html());

            if ($filter.hasClass('b-smart-filters__item--active')) {
              this.toggleFilter(filterId);
            }

            $filter.replaceWith($newTag);
          }

          if (context === 'backend') {
            $(`input#bsf${filterId}`).prop('checked', false);
          } else {
            $(`input#osf${filterId}`).prop('checked', false);
          }
        }
      })
      .fail((a, b, c) => {
        grit(lang('Not changed'), 'growl-danger');
      })
      .always((a, b, c) => {
        $(`div#${filterId}`).removeClass('b-smart-filters__item--menu');
      });
  },


  // Сортировка фильтров
  // ----------------------------------------------------
  sortFilter: function (idSmartFilter, prevFilterId, nextFilterId) {
    const appId = $('#appid').val();

    $.ajax({
      url: '/backend/sortsmartfilter',
      type: 'POST',
      data: {
        smartFilterId: idSmartFilter,
        prevFilterId,
        nextFilterId,
        appId,
      },
      beforeSend: () => {
        //this.spinnerFilter(idSmartFilter);
      },
    })
      .done((a, b, c) => {
        let obj = {};

        try {
          obj = JSON && JSON.parse(a) || $.parseJSON(a);
        } catch (error) {
          logger.exception(error, a);
        }

        if (obj.error !== undefined && obj.error === '') {
          //$('#' + idSmartFilter).addClass('b-smart-filters__item--active').siblings('.b-smart-filters__item').removeClass('b-smart-filters__item--active');
        }
      })
      .fail((a, b, c) => {})
      .always((a, b, c) => {});
  },

  updateFilterDataInTrAfterSuccessUpdate: function (idSmartFilter, eventCount, objectCount, lastModified) {
    const tr = $(`tr[data-filterid="${idSmartFilter}"]`);

    tr.find('#trSmartFilterEvents').html(eventCount);
    tr.find('#trSmartFilterObjects').html(objectCount);
    tr.find('#trSmartFilterLastModified').html(lastModified);
  },


  // Обновление данных в фильтре
  // ----------------------------------------------------
  updateFilter: function (idSmartFilter, reloadGrid) {
    const appId = $('#appid').val();
    const context = ($('#gridclass').val() === 'Backend') ? 'backend' : 'objects';

    $('#smart-filters-cancel').click();

    if (reloadGrid === true) {
      $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update i').attr('class', 'fa fa-refresh fa-spin');
      $.fn.yiiGridView.update('my-model-grid');
    } else {
      $.ajax({
        url: '/backend/refreshsmartfilterdata',
        type: 'POST',
        data: {
          appId,
          smartFilterId: idSmartFilter,
          context,
        },
        beforeSend: () => {
          $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update span').text(lang('update...'));
          this.spinnerFilter(idSmartFilter);
        },
      })
        .done((a, b, c) => {
          let obj = {};

          try {
            obj = JSON && JSON.parse(a) || $.parseJSON(a);
          } catch (error) {
            logger.exception(error, a);
          }

          if (obj.error !== undefined && obj.error === '') {
            const $smartFilter = $(`.b-smart-filters__item#${idSmartFilter}`);

            $smartFilter.replaceWith(obj.html);

            // вот здесь, если луна полная, обновим в табличке счетчики фильтра
            if (idSmartFilter !== undefined && idSmartFilter !== '') {
              const $tr = $(`tr[data-filterid="${idSmartFilter}"]`);
              const eventsCount = $smartFilter.find('#eventsCount').html();
              const objectsCount = $smartFilter.find('#objectsCount').html();
              const lastRef = $smartFilter.find('#lastRef').html();

              if (eventsCount !== undefined && eventsCount !== '') {
                $tr.find('#trSmartFilterEvents').html(eventsCount);
              }
              if (objectsCount !== undefined && objectsCount !== '') {
                $tr.find('#trSmartFilterObjects').html(objectsCount);
              }
              if (lastRef !== undefined && lastRef !== '') {
                $tr.find('#trSmartFilterLastModified').html(lastRef);
              }
            }

            //this.updateFilterDataInTrAfterSuccessUpdate(idSmartFilter,0,0,'now');
            window.updateFontSize();
            window.heightSmartFilters();
          }
        })
        .fail((a, b, c) => {})
        .always((a, b, c) => {
          const $smartFilter = $(`div#${idSmartFilter}`);

          $smartFilter.removeClass('b-smart-filters__item--menu');
          $smartFilter.find('.b-smart-filters__last-update span').text(lang('just now '));
          this.spinnerFilterDisable(idSmartFilter);
          this.updateTooltips(idSmartFilter);
        });
    }
  },


  // Перлоадер для смарт-фильтров
  // ----------------------------------------------------
  spinnerFilter: function (idSmartFilter) {
    $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update i').attr('class', 'fa fa-refresh fa-spin');
  },

  spinnerFilterDisable: function (idSmartFilter) {
    $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update i').removeClass('fa-spin');
  },


  // Обновление тултипов
  // ----------------------------------------------------
  updateTooltips: function (idSmartFilter) {
    const $buttonLastUpdate = $(`div#${idSmartFilter}`).find('.b-smart-filters__last-update');

    // костыльное принудительное скрытие тултипа, т.к. после обновления плашки он зависает
    $buttonLastUpdate.tooltip('hide');
    // бывает тултип все-же зависает, т.к. js не успевает отработать, удаляем его нахрен из DOM
    const timer = setTimeout(() => {
      $('.tooltip[role="tooltip"]').remove();
      clearTimeout(timer);
    }, 500);

    // переинициализация тултипа, т.к. за каким-то хреном у фильтров заменяется html
    $buttonLastUpdate.tooltip({
      placement: 'bottom',
      originalTitle: 'Click to refresh data',
    });
  },


  // ====================================================
  // Функции для создания нововго смарт фильтра
  // ====================================================

  createSmartFilter: {

    // Начальные установки и всякая фигня
    // ----------------------------------------------------
    init: function () {
      this.selectTwo(); // навесим на селекты select2
      this.datePicker(undefined);
      this.timePicker();
      this.bind();

      // new Vue({
      //   el: '.vue-sf-value',
      //   components: [ QSelect ],
      //
      // })

    },


    bind: function () {
      // Добавление новой строки в зависимоти где находимся (conditions or sorting)
      $(document.body).on('click', '.b-new-sf .b-constructor__btn', (e) => {
        const $this = $(e.currentTarget);
        const section = $this.closest('.b-new-sf__section');
        const context = section.attr('id');

        this.addRow(context);
      });

      // Удаление строки в зависимоти где находимся (conditions or sorting)
      $(document.body).on('click', '.b-new-sf .b-constructor__delete', (e) => {
        const $this = $(e.currentTarget);
        const row = $this.closest('.b-constructor__row');

        this.deleteRow(row);
      });

      // Выбор типа данных у properties
      $(document.body).on('click', '.b-new-sf .b-constructor__type a[data-type]', (e) => {
        const $this = $(e.currentTarget);
        const type = $this.attr('data-type');
        const row = $this.closest('.b-constructor__row');

        $this.closest('.b-constructor__select--property').attr('data-type', type);
        $this.closest('.b-constructor__type').find('>a i').attr('class', $this.find('i').attr('class'));
        $this.closest('li').addClass('active').siblings().removeClass('active');
        this.setTypeProperties(type, row);
      });

      // Выбор условия в зависимости от типа данных у properties
      $(document.body).on('change', '.b-new-sf [data-id="operand"]', (e) => {
        const $this = $(e.currentTarget);
        const type = $this.find('option:selected').attr('data-type');
        const row = $this.closest('.b-constructor__row');

        this.setTypeCondition(type, row);
      });

      // Выбор режима, работает только у типа данных дата и время
      $(document.body).on('change', '.b-new-sf [data-id="mode"]', (e) => {
        const $this = $(e.currentTarget);
        const type = $this.find('option:selected').attr('data-type');
        const row = $this.closest('.b-constructor__row');

        this.setModeCondition(type, row);
      });

      // Выбор properties и выполнение операций в зависимости где находимся conditions or sorting
      $(document.body).on('change', '.b-new-sf [data-id="properties"]', (e) => {
        const $this = $(e.currentTarget);
        const dataType = $this.find('option:selected').attr('data-type');
        const row = $this.closest('.b-constructor__row');

        this.changeProperties(dataType, row);
      });

      // Переключение and/or в зависимости где находимся conditions or sorting
      $(document.body).on('change', '.b-constructor__switcher input[type="radio"]', (e) => {
        const $this = $(e.currentTarget);
        const section = $this.closest('.b-new-sf__section'); // где мы находимся (conditions or sorting)
        const operator = lang($this.val());

        this.changeOperators(operator, section);
      });

      // Compare копирование строк из первого периода во второй
      $(document.body).on('change', '.b-new-sf .b-constructor__compare input[type="checkbox"]', (e) => {
        const $this = $(e.currentTarget);
        const section = $this.closest('.b-new-sf__section');
        const checked = $this.is(':checked');

        this.changeCompare(checked, section);
      });
    },

    unbind: function () {
      $(document.body).off('click', '.b-new-sf .b-constructor__btn');
      $(document.body).off('click', '.b-new-sf .b-constructor__delete');
      $(document.body).off('click', '.b-new-sf .b-constructor__type a[data-type]');
      $(document.body).off('change', '.b-new-sf [data-id="operand"]');
      $(document.body).off('change', '.b-new-sf [data-id="mode"]');
      $(document.body).off('change', '.b-new-sf [data-id="properties"]');
      $(document.body).off('change', '.b-constructor__switcher input[type="radio"]');
      $(document.body).off('change', '.b-new-sf .b-constructor__compare input[type="checkbox"]');
    },


    // Навешивает на селекты seletc2
    // ----------------------------------------------------
    selectTwo: function (secondPeriod) {
      const applicationId = $('#appid').val();

      if (!secondPeriod) {

        $('.b-new-sf .b-constructor__select').each((i, element) => {
          const select = $(element).children('select');
          const options = { placeholder: lang('Properties') };
          const pageSize = 50

          switch (select.attr('data-type')) {
            case 'tags':
              options.tags = true;
              break;

            case 'nosearch':
              options.minimumResultsForSearch = -1;
              break;
          }

          if (select.attr('data-id') === 'value') {
            options.placeholder = lang('Select a value');
            options.ajax = {
              url: '/api/v8/filters/getfieldvalues',
              type: 'GET',
              delay: 250,
              minimumInputLength: 1,
              cache: true,
              data: function(params) {
                const $this = $(this)
                const $row = $this.closest('.b-constructor__row');
                const valueProperties = $row.find('[data-id="properties"]').val();
                let [ fieldType, fieldName , ...rest ] = valueProperties.split('.')

                if (fieldType === 'backend') fieldType = 'events'

                const query = {
                  search: params.term,
                  page: params.page || 1,
                  pageSize,
                  applicationId,
                  fieldName,
                  fieldType
                }

                if (!params.term) delete query.search

                return query;
              },
              processResults: function (data, params) {
                params.page = params.page || 1
                const results = ((data.data || {}).attributes || [])
                  .map(
                    item => ({
                      id: item,
                      text: isString(item) ? item : item.toString()
                    })
                  )
                const pagination = {
                  more: (params.page * pageSize) < (data.meta || {}).total || 0
                }

                return { results, pagination };
              },
              transport: function(params, success, failure) {
                const $request = $.ajax(params);

                $request.then(success);
                $request.fail(success);

                return $request;
              }
            }
          }
          if (select.attr('data-id') === 'aggregate') {
            options.placeholder = lang('Aggregate function');
          }
          if (!select.hasClass('select2-hidden-accessible')) {
            select.select2(options);
          }
        });

      } else {

        $('#sf_second_period .select2').remove();
        $('#sf_second_period .b-constructor__select').each((i, element) => {
          const select = $(element).children('select');
          const options = {placeholder: lang('Properties')};

          select.attr({
            'tabindex': '',
            'class': '',
            'aria-hidden': '',
          });

          switch (select.attr('data-type')) {
            case 'tags':
              options.tags = true;
              break;

            case 'nosearch':
              options.minimumResultsForSearch = -1;
              break;
          }

          if (select.attr('data-id') === 'value') {
            options.placeholder = lang('Select a value');
          }
          if (select.attr('data-id') === 'aggregate') {
            options.placeholder = lang('Aggregate function');
          }
          select.select2(options);
        });

      }
    },


    // Навешивает на инпуты datepicker
    // ----------------------------------------------------
    datePicker: function (row) {
      const format = 'dd.mm.yyyy';
      $('.datepicker').remove();
      let dpInput = $('[data-datepicker="true"] .form-control');
      if (row) {
        dpInput = $(row.find('[data-datepicker="true"] .form-control'));
      }
      // add button after input to rise bootstrap datepicker
      dpInput.after(`
          <button class="datepicker-button" 
            style="
              display: inline-block;
              font-size: .85em;
              margin-right: 8px;
              height: 38px;
              width: 38px;
              margin-left: -13px;
              border-radius: 0 3px 3px 0;
              vertical-align: middle;
              color: #9f9f9f;"
          >
            <i class="fa fa-calendar"></i> 
          </button>
      `);
      const dpButton = $('.datepicker-button');
      dpButton.datepicker({
        format: format,
        autoclose: true,
        todayHighlight: true,
      });
      // change input value after "changeDate" event was fired
      dpButton.datepicker().on("changeDate", function(e) {
        e.preventDefault();
        $(e.target).prev().val(e.format(format));
        return false;
      });
    },


    // Навешивает на инпуты timepicker
    // ----------------------------------------------------
    timePicker: function () {
      $('[data-timepicker="true"] .form-control').timepicker({
        minuteStep: 1,
        showMeridian: false,
      });
    },


    // Выбор properties с определением типа данных и подгрузкой значений
    // ----------------------------------------------------
    changeProperties: function (dataType, row) {
      let icon = '';
      let section = row.closest('.b-new-sf__section').attr('id'); // где мы находимся (conditions or sorting);

      if (section === 'sf_conditions' || section === 'sf_first_period' || section === 'sf_second_period') {
        row.find('.b-constructor__select--property').attr('data-type', dataType);
        switch (dataType) {
          case 'string':
            icon = 'cu cu--string';
            break;
          case 'number':
            icon = 'cu cu--number';
            break;
          case 'boolean':
            icon = 'fa fa-adjust';
            break;
          case 'date':
            icon = 'fa fa-calendar';
            break;
          case 'time':
            icon = 'fa fa-clock-o';
            break;
        }
        row.find('.b-constructor__type li').has(`a[data-type="${dataType}"]`).addClass('active').siblings().removeClass('active');
        row.find('.b-constructor__type > a').removeClass('disabled').find('i').attr('class', icon);
      }

      this.setTypeProperties(dataType, row);
    },


    // Установка типа данных у properties
    // ----------------------------------------------------
    setTypeProperties: function (type, row) {
      const section = row.closest('.b-new-sf__section');                  // где мы находимся (conditions or sorting)
      const sectionId = section.attr('id');                               // (conditions or sorting)
      const newRowContainer = section.find('.b-constructor__new-row');    // блок с кнопкой добавить новую строку
      const rowsContainer = section.find('.b-constructor__rows');         // блок с строками
      const labelsContainer = section.find('.b-constructor__labels');     // блок с метками, переключателем и прочее
      const rowLength = rowsContainer.find('.b-constructor__row').length; // количество уже имеющихся строк
      const valueProperties = row.find('[data-id="properties"]').val();
      const tplRules = $(`#templateRules-${type}`).html();

      if (rowLength === 1) {
        labelsContainer.find('.b-constructor__label--where').css('visibility', 'hidden');
        labelsContainer.find('.b-constructor__line').css('display', 'block');
      }

      if (sectionId === 'sf_conditions' || sectionId === 'sf_first_period' || sectionId === 'sf_second_period') {
        row.find('.b-constructor__rules').html(tplRules);
      } else if (sectionId === 'sf_sorting' || sectionId === 'sf_GroupSorting') {
        row.find('.b-constructor__rules').html($('#tpl-sort').html());
      }

      if (rowsContainer.find('.b-constructor__row:last .b-constructor__rules').html().trim() !== '') {
        newRowContainer.css('display', 'block');
        section.find('.b-constructor__compare').css('display', 'block');
      } else {
        newRowContainer.css('display', 'none');
      }

      this.selectTwo();

      if (type === 'date') {
        this.datePicker(row);
      }
      if (type === 'time') {
        this.timePicker();
      }
    },


    // Выбор операнда и изменение вида полей для задания value
    // ----------------------------------------------------
    setTypeCondition: function (type, row) {
      const dataType = row.find('.b-constructor__select--property').attr('data-type');
      const valueProperties = row.find('[data-id="properties"]').val();
      const tplValue = $(`#tpl-${dataType}-${type}`).html();

      row.find('.b-constructor__value').replaceWith(tplValue);

      this.selectTwo();

      if (dataType === 'date') {
        this.datePicker(row);
      }
      if (dataType === 'time') {
        this.timePicker();
      }
    },


    // Выбор режима (только для типа данных дата и время)
    // ----------------------------------------------------
    setModeCondition: function (type, row) {
      const dataType = row.find('.b-constructor__select--property').attr('data-type');
      const tplOperand = $(`#tplOperand-${dataType}-${type}`).html();

      row.find('.b-constructor__operand').html(tplOperand);

      const operandType = row.find('[data-id="operand"] option:selected').attr('data-type');

      this.setTypeCondition(operandType, row);
    },


    // Выбор опретора (and/or)
    // ----------------------------------------------------
    changeOperators: function (operator, section) {
      const labels = section.find('.b-constructor__labels .b-constructor__label:not(.b-constructor__label--where)');

      labels.text(operator);
    },


    // Включаем компаре или отключаем
    // ----------------------------------------------------
    changeCompare: function (checked, context) {
      const secondPeriod = $('#sf_second_period');
      const rowsFirstPeriod = context.find('.b-constructor__rows');
      const rowsSecondPeriod = secondPeriod.find('.b-constructor__rows');
      const sp = [];
      let andor = '';

      if (!checked) {
        secondPeriod.css('display', 'none');
        rowsSecondPeriod.html('');
        return false;
      }

      secondPeriod.css('display', 'block');
      andor = context.find('.b-constructor__switcher input:checked').val();

      rowsFirstPeriod.find('.b-constructor__row').each((i, element) => {
        const row = $(element);
        const obj = {};

        obj.property = row.find('[data-id="properties"]').val();
        obj.datatype = row.find('.b-constructor__select--property').attr('data-type');
        obj.mode = row.find('[data-id="mode"]').val();

        const operand = row.find('[data-id="operand"]').val();

        obj.operand = operand;

        if (operand === 'between') {
          const values = row.find('[data-id="value"]');

          obj.value1 = values[0].value;
          obj.value2 = values[1].value;
        } else if (operand === '=') {
          obj.value1 = row.find('[data-id="value"]').val();
        } else {
          obj.periodago = row.find('input[data-id="value"]').val();
          obj.periodtype = row.find('select[data-id="value"]').val();
        }

        sp.push(obj);
      });

      rowsSecondPeriod.html(rowsFirstPeriod.html());

      $('#sf_second_period .b-constructor__row').each((i, element) => {
        $(element).attr('id', '');
      });

      this.selectTwo(true);

      const rowSecondPeriod = rowsSecondPeriod.find('.b-constructor__row');

      if (rowSecondPeriod.length > 0) {
        secondPeriod.find('.b-constructor__label--where').css('visibility', 'hidden');
        secondPeriod.find('.b-constructor__line').css('display', 'block');
        secondPeriod.find('.b-constructor__new-row').css('display', 'block');
      }
      if (rowSecondPeriod.length > 1) {
        secondPeriod.find('.b-constructor__switcher').css('display', 'block');
      } else {
        secondPeriod.find('.b-constructor__switcher').css('display', 'none');
      }

      rowSecondPeriod.each((index, element) => {
        const row = $(element);

        row.find(`[data-id="properties"] option[value="${sp[index].property}"]`).attr('data-type', sp[index].datatype);
        row.find('[data-id="properties"]').select2('val', sp[index].property);
        row.find('[data-id="mode"]').select2('val', sp[index].mode);
        row.find('[data-id="operand"]').select2('val', sp[index].operand);

        if (sp[index].operand === 'between') {
          const values = row.find('[data-id="value"]');

          $('.datepicker').remove();
          values[0].value = sp[index].value1;
          values[1].value = sp[index].value2;
        } else if (sp[index].operand === '=') {
          $('.datepicker').remove();
          row.find('[data-id="value"]').val(sp[index].value1);
        } else {
          row.find('input[data-id="value"]').val(sp[index].periodago);
          row.find('select[data-id="value"]').select2('val', sp[index].periodtype);
        }
        if (index > 1) {
          secondPeriod.find('.b-constructor__labels').append(`<div class="b-constructor__label">${andor}</div>`);
        }
      });
    },


    // Добавление новой строки
    // @param: context - определим для какого блока будет добавлена новая строка (conditions or sorting)
    // ----------------------------------------------------
    addRow: function (context) {
      //alert(`#${context}`);
      const section = $(`#${context}`);                                   // где мы находимся (conditions or sorting)
      const rowsContainer = section.find('.b-constructor__rows');         // блок с строками
      const labelsContainer = section.find('.b-constructor__labels');     // блок с метками, переключателем и прочее
      const newRowContainer = section.find('.b-constructor__new-row');    // блок с кнопкой добавить новую строку
      const switcher = labelsContainer.find('.b-constructor__switcher');  // переключатель and/or
      const rowLength = rowsContainer.find('.b-constructor__row').length; // количество уже имеющихся строк
      const templateRow = $(`#templateRow-${context}`).html();

      if (context === 'sf_conditions' || context === 'sf_first_period' || context === 'sf_second_period') {
        if (rowLength === 1) {
          switcher.css('display', 'block'); // показываем переключатель
        } else if (rowLength > 1) {
          labelsContainer.append(`<div class="b-constructor__label">${lang(switcher.find('input:checked').val())}</div>`); // рисуем метки and или or в зависимости от переключателя
        }
      } else if (context === 'sf_sorting' || context === 'sf_GroupSorting' ) {
        labelsContainer.append('<div class="b-constructor__label b-constructor__label--pseudo"></div>');
      }

      rowsContainer.append(templateRow);
      newRowContainer.css('display', 'none');
      this.selectTwo();
    },


    // Удаление строки
    // ----------------------------------------------------
    deleteRow: function (row) {
      const section = row.closest('.b-new-sf__section');                  // где мы находимся (conditions or sorting)
      const sectionId = section.attr('id');                               // (conditions or sorting)
      const rowsContainer = section.find('.b-constructor__rows');         // блок с строками
      const labelsContainer = section.find('.b-constructor__labels');     // блок с метками, переключателем и прочее
      const newRowContainer = section.find('.b-constructor__new-row');    // блок с кнопкой добавить новую строку
      const switcher = labelsContainer.find('.b-constructor__switcher');  // переключатель and/or
      const rowLength = rowsContainer.find('.b-constructor__row').length; // количество уже имеющихся строк

      if (rowLength > 2) {
        row.remove();
        labelsContainer.find('.b-constructor__label:last').remove();
      } else if (rowLength === 2) {
        row.remove();

        if (sectionId === 'sf_conditions' || sectionId === 'sf_first_period' || sectionId === 'sf_second_period') {
          switcher.css('display', 'none'); // скрываем переключатель
        } else {
          labelsContainer.find('.b-constructor__label:last').remove();
        }
      } else if (rowLength === 1) {
        const aggVal = rowsContainer.find('[data-id="aggregate-properties"]');

        if (aggVal.val() !== undefined) {
          aggVal.val(null).trigger('change');
          row.find('[data-id="aggregate"]').val(null).trigger('change');
        } else {
          rowsContainer.find('[data-id="properties"]').val(null).trigger('change');
          row.find('.b-constructor__rules').html('');
        }

        if (sectionId === 'sf_conditions') {
          rowsContainer.find('.b-constructor__type > a').addClass('disabled').children().addClass('cu cu--string');
        } else if (sectionId === 'sf_first_period' || sectionId === 'sf_second_period') {
          rowsContainer.find('.b-constructor__type > a').addClass('disabled').children().addClass('fa fa-calendar');
          section.find('.b-constructor__compare').css('display', 'none');
        }
      }

      const html = rowsContainer.find('.b-constructor__row:last .b-constructor__rules').html();

      if (rowLength <= 2 && html !== undefined && html.trim() === '') {
        labelsContainer.find('.b-constructor__label--where').css('visibility', 'visible');
        labelsContainer.find('.b-constructor__line').css('display', 'none');
      }

      if (html !== undefined && html.trim() !== '') {
        newRowContainer.css('display', 'block');
      } else {
        newRowContainer.css('display', 'none');
      }

      $('[data-datepicker="true"] .form-control').datepicker('remove');
    },

  },
};
