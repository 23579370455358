var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popovers",
      class: _vm.b(),
      attrs: {
        title: _vm.content,
        "data-toggle": "popover",
        "data-html": "true",
        "data-content": _vm.content,
        "data-placement": _vm.placement,
        "data-trigger": _vm.trigger,
      },
    },
    [_vm._v("\n  ?\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }