<i18n src="general/lang/WidgetSettingsTypes.json"></i18n>

<template>
  <div :class="b()">
    <div
      v-if="elements.length > 3"
      :class="b('controls')"
    >
      <button
        :class="b('button-add')"
        class="btn btn-xs btn-success"
        @click.prevent="addItem"
      >
        <i class="fa fa-plus mr5"></i>
        <span>{{ $t('WidgetSettingsTypes.buttonAdd') }}</span>
      </button>
    </div>

    <div
      v-if="elements.length"
      :class="b('list')"
    >
      <template v-for="element in elements">
        <div
          v-if="element"
          :key="element.id"
          :class="b('item')"
          :data-tour="element.attributes.json_data.name"
        >
          <div :class="b('item-card')">
            <WidgetSettingsTypesItem
              :model="element"
              :editing="element.isEditing || false"
            />
          </div>
          <div :class="b('item-scenarios')">
            <WidgetSettingsScenarios
              :widget-type-id="element.id"
            />
          </div>
        </div>
      </template>
    </div>

    <div
      v-if="!elements.length"
      :class="b('message-list-is-empty')"
    >
      {{ $t('WidgetSettingsTypes.messageCompositeListIsEmpty') }}
    </div>

    <div :class="b('controls')" class="q-pb-lg">
      <button
        :class="b('button-add')"
        class="btn btn-xs btn-success"
        @click.prevent="addItem"
      >
        <i class="fa fa-plus mr5"></i>
        <span>{{ $t('WidgetSettingsTypes.buttonAdd') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { baseMixin, noticeMixin } from 'general/mixins';
  import WidgetSettingsTypesItem from './WidgetSettingsTypesItem';
  import WidgetSettingsScenarios from './WidgetSettingsScenarios';

  const { mapActions, mapGetters } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-settings-types',

    mixins: [
      baseMixin,
      noticeMixin,
    ],

    components: {
      WidgetSettingsTypesItem,
      WidgetSettingsScenarios,
    },

    computed: {
      ...mapGetters({
        elements: 'widgetTypes/getElements',
      }),
    },

    methods: {
      ...mapActions({
        add: 'widgetTypes/add',
      }),

      addItem() {
        const added = this.add();

        if (added) {
          this.notice('Success to add new type', this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice('Failed to added new type', this.NOTICE_LEVEL_ERROR);
        }
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-settings-types
    margin-bottom 30px

    &__list
      margin-y(30px)

    &__message-list-is-empty
      margin-y(40px)
      text-align center

    &__item
      flex()
      margin-bottom 40px

    &__item-card
      //

    &__item-scenarios
      width 100%
      margin-left 30px

    &__controls
      //

    &__button-add
      //
</style>
