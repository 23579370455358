var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b() },
    [
      _c(
        "vddl-list",
        {
          class: _vm.b("list"),
          attrs: {
            list: _vm.components,
            drop: _vm.handlerDrop,
            "allowed-types": ["component"],
            "external-sources": true,
          },
        },
        [
          _vm._l(_vm.components, function (component, index) {
            return [
              component
                ? _c("WidgetEditorContentAreaComponent", {
                    key: component.uuid,
                    attrs: {
                      state: component,
                      index: index,
                      type: "component",
                      list: _vm.components,
                    },
                  })
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _c("vddl-placeholder", { class: _vm.b("placeholder") }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("WidgetEditorContentArea.placeholder")) +
                "\n    "
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }