<template>
  <div>
    <div v-if="bots">
      <bot-item v-for="(bot, ind) in bots"
                :key="ind"
                :bot="bot"
                :app-id="appId"
                :default-icon="appIcon"
                :http="http"></bot-item>

      <div class="b-settings-box b-bot-settings" v-for="(item, ind) in comingSoon" :key="'c-'+ind">
        <div class="b-settings-box__header">{{ item }}</div>
        <div class="b-settings-box__body">{{ lang('Coming soon') }}</div>
      </div>
    </div>
    <div v-else>{{ lang('Loading data...') }}</div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { replaceSnippets } from 'general/utils/utils';
  import { BASE_URL } from 'general/env';
  import BotItem from './BotItem';

  export default {
    name: 'bots-settings',
    components: {BotItem},
    props: ['appId', 'appName', 'appIcon', 'tenant', 'token', 'server'],

    data: function () {
      return {
        query: {
          tenantName: this.tenant,
          applicationId: this.appId
        },
        bots: null,
        comingSoon: ['WhatsApp', 'iMessage', 'WeChat', 'Skype', 'Slack', 'QQ']
      }
    },

    computed: {
      http() {
        return axios.create({
        //  baseURL: this.getBaseUrl(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${this.token}`,
          },
        })
      }
    },

    async created() {
      this.bots = await this.getBots();

      console.log('this.bots', this.bots);
    },

    methods: {
      lang(msg) {
        return window.lang(msg);
      },
      getBaseUrl() {
        return replaceSnippets(BASE_URL, {
          TENANT: this.tenant,
          SERVER: this.server,
        });
      },

      /**
       * Get All Bots from App
       * @returns {Promise<any>}
       */
      async getBots() {
        let botsData = null;
        try {
          const res2 = await this.http.get(`/api/v5/list`, {
            params: this.query
          });
          botsData = res2.data;
        } catch (e) {
          console.log(e);
        }

        return botsData;
      },

      /**
       * Get Bot from App
       * @param type {String}
       * @returns {Promise<any>}
       */
      getBot(type) {
        // const defaultBotData = {
        //   type,
        //   TenantName: this.tenant,
        //   ApplicationId: this.appId
        // }
        // return new Promise(async (resolve, reject) => {
        //   try {
        //     const res = await this.http.get(`/api/v5/${type}`, {
        //       params: {query: JSON.stringify(this.query)}
        //     });
        //     resolve(res.data[0] ? { type, ...res.data[0] } : defaultBotData);
        //   } catch (e) {
        //     reject(e);
        //   }
        // })
      }
    }
  }
</script>
