<i18n src="general/lang/WidgetModalInnerBase.json"></i18n>

<template>
  <div :class="b()">
    <button
      v-if="shownButtonClose"
      :class="b('button-close')"
      @click.prevent="handlerClose"
    >
      <IconClose width="10" />
    </button>

    <div
      v-if="shownHeader && (headerIcon || headerTitle)"
      :class="b('header')"
    >
      <div
        v-if="headerIcon"
        :class="b('header-icon')"
      >
        <img
          :src="headerIcon"
          alt="header-icon"
        >
      </div>

      <div
        v-if="headerTitle"
        :class="b('header-title')"
      >
        {{ headerTitle }}
      </div>
    </div>


    <div :class="b('body')">
      <slot />
    </div>

    <div
      v-if="shownFooter"
      :class="b('footer')"
    >
      <div :class="b('controls')">
        <button
          :class="b('button-link')"
          @click.prevent="handlerRemindNever"
        >
          {{ $t('WidgetModalInnerBase.remind2weeks') }}

        </button>

        <button
          :class="b('button-link')"
          @click.prevent="handlerRemindLater"
        >
          {{ $t('WidgetModalInnerBase.remindLater') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import IconClose from 'general/components/icons/IconClose';
  export default {
    name: 'widget-modal-inner-base',

    components: {
      IconClose,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },
      header: {
        type: Object,
      },
      footer: {
        type: Object,
      },
    },

    computed: {
      shownButtonClose() {
        return this.header.shownButtonClose || false;
      },
      shownHeader() {
        return this.header.shown || false;
      },
      headerIcon() {
        return this.header.icon.src || null;
      },
      headerTitle() {
        return this.header.title || null;
      },
      shownFooter() {
        return this.footer.shown || false;
      },
    },

    methods: {
      handlerClose() {
        this.$emit('close');
      },

      handlerRemindNever() {
        this.$emit('remind-never');
      },

      handlerRemindLater() {
        this.$emit('remind-later');
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-modal-inner-base
    display flex
    flex-direction column
    background-color #fff
    color #444
    position relative

    &__button-close
      button()
      button_radial()
      button_theme('close')
      flex(center, center)
      size(24px)
      position absolute
      top -5px
      right -8px

    &__header
      flex(false, center)
      flex-shrink 0
      background-color #3876f6
      padding 15px 20px
      color #fff

    &__header-icon
      margin-right 15px

    &__header-title
      typography(20px, 24px)

    &__body
      padding-y(10px)

    &__footer
      flex-shrink 0
      padding 10px 15px

    &__controls
      flex(space-evenly)

    &__button-link
      reset-button()
      link()
      display block
      padding 10px
      typography(12px)
      color #337ab7
      text-align center
      outline none
</style>
