'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Views
var TransformationsItemView = require('views/transformations/TransformationsItemView');

// Collections
var TransformationsCollection = require('collections/TransformationsCollection');

// Templates
var _transformations = require('text!bcTemplates/transformations/transformations.html');

// Helper
var PubSub = require('helper/pubsub');


module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'transformations',

    events: {
        'click [data-js-action="addTransformation"]': 'addTransformation',
    },

    initialize: function(options) {
        this.tpl = _.template(_transformations);
        this.itemViews = [];
        this.parentView = options.parentView || undefined;
        this.title = options.title || '';
        this.collection = new TransformationsCollection(options.collection);
        //this.listenTo(this.collection, 'add', this.addModel, this);
        this.listenTo(this.collection, 'remove', this.setTransformations, this);
        this.listenTo(this.collection, "change", this.setTransformations, this);
    },

    render: function() {
        var t = this;
        t.$el.empty();
        t.$el.append(t.tpl({
            title: t.title
        }));

        var $transformationsItemViews = _.map(t.collection.models, function(model, k) {
            var transformationsItemView = new TransformationsItemView({
                model: model
            });
            t.itemViews.push(transformationsItemView);
            return transformationsItemView.render();
        });

        t.$el.find('>div').append($transformationsItemViews);

        return t.$el;
    },

    close: function() {
        _.each(this.itemViews, function(itemView){
            if (itemView.close){
                itemView.close();
            }
        });

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    addTransformation: function(e) {
        e.preventDefault();
        var TransformationModel = Backbone.Model.extend({
            defaults: {
                key: '',
                value: ''
            }
        });
        var transformationModel = new TransformationModel();
        var transformationsItemView = new TransformationsItemView({
            model: transformationModel
        });
        this.collection.add(transformationModel);
        this.$el.find('>div').append(transformationsItemView.render());

        var bindings = {
            '[name="dataTransformationsKey"]': 'key',
            '[name="dataTransformationsValue"]': 'value'
        };
        transformationsItemView.stickit(transformationsItemView.model, bindings);
    },

    setTransformations: function() {
        if (this.parentView) {
            this.parentView.model.set('transformations', this.collection.toJSON());
        }
        PubSub.trigger('setConditionalDataTransformations'); // TriggerOperationSettingsView
    }

});