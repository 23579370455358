import screen from './screen';
import button from './button';
import row from './row';
import rowItem from './rowItem';
import textInput from './textInput';
import input from './input';
import meterInput from './meterInput';
import numberInput from './numberInput';
import textArea from './textArea';
import text from './text';
import link from './link';
import checkboxes from './checkboxes';
import radiobuttons from './radiobuttons';
import select from './select';
import image from './image';
import divider from './divider';
import customHtml from './customHtml';
import toolbar from './toolbar';
import uploadFile from './uploadFile';
import toolbarItem from './toolbarItem';
import bottomToolbar from './bottomToolbar';
import bottomToolbarItem from './bottomToolbarItem';
import flexRow from './flexRow';
import flexRowCol from './flexRowCol';
import tabs from './tabs';
import tabsTab from './tabsTab';
import slider from './slider';
import sliderSlide from './sliderSlide';
import radiobutton from './radiobutton';
import checkbox from './checkbox';
import list from './list';
import listItem from './listItem';
import drawerLeft from './drawerLeft'
import drawerRight from './drawerRight'
import videoPlayer from './videoPlayer'

export default {
  'mbst-screen': screen,
  'mbst-button': button,
  'mbst-row': row,
  'mbst-row__item': rowItem,
  'mbst-text-input': textInput,
  'mbst-meter-input': meterInput,
  'mbst-input': input,
  'mbst-number-input': numberInput,
  'mbst-text-area': textArea,
  'mbst-custom-html': customHtml,
  'mbst-text': text,
  'mbst-link': link,
  'mbst-checkboxes': checkboxes,
  'mbst-radio-buttons': radiobuttons,
  'mbst-select': select,
  'mbst-image': image,
  'mbst-divider': divider,
  'mbst-toolbar': toolbar,
  'mbst-uploadfile': uploadFile,
  'mbst-toolbar__item': toolbarItem,
  'mbst-bottom-toolbar': bottomToolbar,
  'mbst-bottom-toolbar__item': bottomToolbarItem,
  'mbst-flexrow': flexRow,
  'mbst-flexrow__col': flexRowCol,
  'mbst-tabs': tabs,
  'mbst-tabs__tab': tabsTab,
  'mbst-slider': slider,
  'mbst-slider__slide': sliderSlide,
  'mbst-radio': radiobutton,
  'mbst-checkbox': checkbox,
  'mbst-list': list,
  'mbst-list__item': listItem,
  'mbst-drawer-left': drawerLeft,
  'mbst-drawer-right': drawerRight,
  'mbst-video-player': videoPlayer,
}
