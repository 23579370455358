import localforage from 'localforage';

const localForageFactory = (config) => {
  const { applicationId, objectId } = config;
  const storeName = `appId_${applicationId}_objId_${objectId}`;

  return localforage.createInstance({
    name: 'MOBSTED WIDGETS',
    storeName,
  });
};

export default localForageFactory;
