<i18n src="general/lang/WidgetSettingsScenariosHead.json"></i18n>

<template>
  <div :class="b()">
    <div :class="b('row', { description: true })">
      <span>{{ $t('WidgetSettingsScenariosHead.titleRowDescription') }}</span>
      <HintHelper :content="$t('WidgetSettingsScenariosHead.hintRowDescription')" />
    </div>

    <div :class="b('row', { conditions: true })">
      <span>{{ $t('WidgetSettingsScenariosHead.titleRowConditions') }}</span>
      <HintHelper :content="$t('WidgetSettingsScenariosHead.hintRowConditions')" />
    </div>

    <div :class="b('row', { where: true })">
      <span>{{ $t('WidgetSettingsScenariosHead.titleRowWhere') }}</span>
      <HintHelper :content="$t('WidgetSettingsScenariosHead.hintRowWhere')" />
    </div>

    <div :class="b('row', { when: true })">
      <span>{{ $t('WidgetSettingsScenariosHead.titleRowWhen') }}</span>
      <HintHelper :content="$t('WidgetSettingsScenariosHead.hintRowWhen')" />
    </div>

    <div :class="b('row', { counters: true })">
      <span>{{ $t('WidgetSettingsScenariosHead.titleRowCounters') }}</span>
      <HintHelper :content="$t('WidgetSettingsScenariosHead.hintRowCounters')" />
    </div>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';
  import HintHelper from 'general/components/HintHelper';

  export default {
    name: 'widget-settings-scenarios-head',

    mixins: [
      baseMixin,
    ],

    components: {
      HintHelper,
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-settings-scenarios-head
    display flex
    width 100%
    background-color lightness(#eee, 95%)
    border 1px solid lightness(#eee, 80%)
    margin-bottom 15px
    typography(14px)
    box-shadow 0 1px 5px 0 rgba(#000, .15)
    transition-duration $duration

    &__row
      flex(center, center)
      border-right 0 solid lightness($brand-info, 80%)
      padding 5px
      font-weight 700

      +not-last()
        border-width 1px

      > span
        margin-right 10px

      &_description
        width 100%

      &_conditions
        flex-shrink 0
        width 200px

      &_where
        flex-shrink 0
        width 140px

      &_when
        flex-shrink 0
        width 140px

      &_counters
        flex-shrink 0
        width 140px
</style>
