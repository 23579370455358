'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Views
var ConditionsView = require('views/conditions/ConditionsView');
var TransformationsView = require('views/transformations/TransformationsView');

// Templates
var _dropdown = require('text!bcTemplates/ui/dropdown.html');


module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'b-trigger-operations__cdt',

    events: {
        'click [data-js-action="deleteConditional"]': 'deleteConditional',
    },

    initialize: function(options) {
        this.cdtId = options.cdtId;
        this.parentView = options.parentView || undefined;
    },

    render: function() {
        var t = this, temp = [];

        t.conditionsViews = new ConditionsView({
            cdtId: t.cdtId,
            parentView: this,
            collection: t.model.get('conditions') || []
        });

        t.transformationsViews = new TransformationsView({
            title: lang('Transformations:'),
            parentView: this,
            collection: t.model.get('transformations') || []
        });

        t.$el.empty();

        var $dropdown = _.template(_dropdown);
        temp.push($dropdown({
            icon: 'fa fa-ellipsis-v',
            text: '',
            items: [
                {
                    icon: 'far fa-clone',
                    text: lang('Copy'),
                    attr: {
                        href: 'javascript:;',
                        'data-js-action': 'copyConditional'
                    }
                },
                {
                    icon: 'far fa-trash-alt',
                    text: lang('Delete'),
                    attr: {
                        href: 'javascript:;',
                        'data-js-action': 'deleteConditional'
                    }
                }
            ]
        }));
        temp.push(t.conditionsViews.render());
        temp.push(t.transformationsViews.render());

        t.$el.append(temp);

        return t.$el;
    },

    deleteConditional: function(e) {
        e.preventDefault();
        this.model.collection.remove(this.model);
        this.close();
    },

    close: function() {
        if (this.conditionsViews.close){
            this.conditionsViews.close();
        }
        if (this.transformationsViews.close){
            this.transformationsViews.close();
        }
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }

});
