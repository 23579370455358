export default {
  props: {
    width: {
      type: [Number, String, Boolean],
      default: 16,
    },

    height: {
      type: [Number, String, Boolean],
      default: null,
    },
  },
};
