import logger from 'general/logger';
import MakeHttp from 'general/utils/MakeHttp';
import widgetTypeSchema from '~@/bc/components/WidgetSettings/schemas/widgetTypeSchema';

const http = MakeHttp();
const endpoint = '/widget/type/';
const TYPE_WIDGET_COMPONENT_BASE = 'base';
const SET_ALL = 'SET_ALL';
const ADD = 'ADD';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const SET_CONFIGURABLE = 'SET_CONFIGURABLE';
const RESET_CONFIGURABLE = 'RESET_CONFIGURABLE';

/**
 * Подготовка элемента
 * Установка признаков базовых типов компонентов виджета
 *
 * @param {Object} widgetType
 */
const prepareType = (widgetType) => {
  const jsonData = widgetType.attributes.json_data;
  jsonData.types = [];
  jsonData.components.forEach((component) => {
    if (component && component.type === TYPE_WIDGET_COMPONENT_BASE) {
      const type = component.name.replace('w-', '');
      if (!jsonData.types.includes(type)) {
        jsonData.types.push(type);
      }
    }
  });
};

export default {
  namespaced: true,

  state: () => ({
    elements: [],
    pagination: null,
    configurableElement: null,
  }),

  getters: {

    getConfigurableElement: state => state.configurableElement,

    getElements: state => state.elements.filter(element => element.attributes.json_data.type !== 'pwaless'),

  },

  actions: {

    /**
     * @param rootState
     * @param commit
     * @returns {Promise<Array|boolean>}
     */
    async fetchAll({ rootState, commit }) {
      const query = {
        applicationId: rootState.widgetSettings.applicationId,
        page: 1,
        pageSize: 30,
      };
      const { data: elements, meta: pagination } = await http.get(endpoint, query);

      if (!elements || !pagination) {
        return false;
      }

      commit(SET_ALL, { elements, pagination });
      return elements;
    },

    /**
     * @param rootState
     * @param commit
     * @returns {Promise<Object|boolean>}
     */
    async add({ rootState, commit }) {
      const data = widgetTypeSchema.attributes;
      data.applicationId = rootState.widgetSettings.applicationId;
      data.widgetConfigurationId = 0;
      const { data: element } = await http.post(endpoint, data);

      if (!element) {
        return false;
      }

      prepareType(element);
      commit(ADD, element);
      return element;
    },

    /**
     * @param state
     * @param commit
     * @param {Object} payload
     * @returns {Promise<Object|boolean>}
     */
    async update({ state, commit }, payload) {
      logger.log(
        'widgetTypes:update!',
        'payload:resetConfigurable', payload,
      );

      const url = `${endpoint}${payload.element.id}`;
      const query = {
        applicationId: payload.element.attributes.applicationId,
        widgetConfigurationId: 0,
        json_data: payload.element.attributes.json_data,
      };
      const request = await http.put(url, query);
      const { data: element, meta: { affected } } = request;

      if (!affected) {
        return false;
      }

      const index = state.elements.findIndex(item => (item.id === element.id));
      prepareType(element);
      commit(UPDATE, { index, element });
      return element;
    },

    /**
     * @param state
     * @param rootGetters
     * @param dispatch
     * @param commit
     * @param id
     * @returns {Promise<boolean>}
     */
    async delete({ state, rootGetters, dispatch, commit }, id) {
      // удаление всех сценариев связанных с текущим типом
      const scenarios = rootGetters['widgetSettings/widgetScenarios/getElementsByWidgetTypeId'](id);
      scenarios.forEach((scenario) => {
        dispatch('widgetSettings/widgetScenarios/delete', scenario.id, { root: true });
      });

      const url = `${endpoint}${id}`;
      const { meta: { affected } } = await http.delete(url);

      if (!affected) {
        return false;
      }

      const index = state.elements.findIndex(item => (item.id === id));
      commit(DELETE, index);
      return true;
    },

    /**
     * @param state
     * @param commit
     * @param id
     */
    async setConfigurable({ state, commit }, id) {
      const element = state.elements.find(item => (item.id === id));
      commit(SET_CONFIGURABLE, element);
    },

    /**
     * @param commit
     */
    async resetConfigurable({ commit }) {
      commit(RESET_CONFIGURABLE);
    },

  },

  mutations: {

    [SET_ALL](state, payload) {
      state.elements = payload.elements;
      state.pagination = payload.pagination;
    },

    [ADD](state, element) {
      state.elements.push(element);
    },

    [UPDATE](state, payload) {
      state.elements.splice(payload.index, 1, payload.element);
    },

    [DELETE](state, index) {
      state.elements.splice(index, 1);
    },

    [SET_CONFIGURABLE](state, element) {
      state.configurableElement = element;
    },

    [RESET_CONFIGURABLE](state) {
      state.configurableElement = null;
    },

  },
};
