export default {
  name: 'mbst-custom-html',
  aliasName: 'Custom JS/HTML',
  properties: {
    backendname: 'html',
    placeholder: '<h1 style="color:red;">Heading example</h1><h2>Centered text</h2>',
    value: '<h1 style="color:red;">Red Heading</h1><h2 style="text-align: center;">Centered text</h2>',
    script: '/* Context object available `MBST` */\r\nconsole.log("test", MBST);',
    visibility: {
      conditions: [],
      hidden: false,
    },
    // needHighlight: true,
  },
  actions: null,
  css: {
    ios: [],
    android: []
  },
}
