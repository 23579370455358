export default {
  name: 'mbst-select',
  aliasName: 'Select',
  properties: {
    backendname: 'select',
    iscached: false,
    label: 'Select',
    clearable: false,
    multiple: false,
    icon: '',
    design: '',
    darkMode: false,
    options: [
      { label: 'Option label', value: 'Option value', selected: true, conditions: [] },
    ],
    dynamicOptions: [],
    dynamicOptionsLabelKey: 'label',
    dynamicOptionsValueKey: 'value',
    allowUserOption: false,
    allowUseFiltering: false,
    visibility: {
      conditions: [],
      hidden: false
    },
    value: ''
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-select .q-field__control',
        rules: [
          { directive: 'color', value: 'inherit' },
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-select .q-field__control',
        rules: [
          { directive: 'color', value: 'inherit' }
        ]
      }
    ]
  },
}
