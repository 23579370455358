<i18n src="general/lang/WidgetEditor.json"></i18n>

<template>
  <div :class="b()">
    <div :class="b('workspace')">
      <div :class="b('component-palette-container')">
        <div :class="b('controls')">
          <button
            :class="b('button-back', 'btn btn-default btn-sm')"
            @click.prevent="back"
          >
            <q-icon name="far fa-long-arrow-alt-left" />
            <span>
              {{ $t('WidgetEditor.buttonBack') }}
            </span>
          </button>
        </div>

        <WidgetEditorComponentPalette />
      </div>

      <div :class="b('demo-modal-container')">
        <div :class="b('controls')">
          <button
            :class="b('button-edit-modal', 'btn btn-xs btn-info')"
            @click.prevent="setEditingModal"
          >
            {{ $t('WidgetEditor.buttonEditModal') }}
          </button>
        </div>

        <SmartphoneWrapper>
          <div :class="b('widget-editor-demo-modal-wrap', { isEditingModal })">
            <WidgetEditorDemoModal :json-data="jsonData" />
          </div>
        </SmartphoneWrapper>
      </div>

      <div :class="b('property-pane-container')">
        <div :class="b('widget-type-properties')">
          <ControlInput
            v-model="jsonData.name"
            :class="b('field')"
            direction="horizontal"
            :label-before="$t('WidgetEditor.props.name.label')"
            :hint-text="$t('WidgetEditor.props.name.hint')"
          />

          <div :class="b('field')">
            <ControlInput
              v-model="jsonData.code"
              direction="horizontal"
              :label-before="$t('WidgetEditor.props.code.label')"
              :hint-text="$t('WidgetEditor.props.code.hint')"
            />
            <div :class="b('field-code-box')">
              API: mobsted.widgets.{{ jsonData.code }}.show()
            </div>
          </div>
        </div>

        <!-- TODO: перенести этот блок и логику в WidgetEditorPropertyPane -->
        <div
          v-if="isEditingModal"
          :class="b('demo-modal-properties')"
        >
          <div :class="b('demo-modal-properties-header')">
            <button
              :class="b('demo-modal-properties-button-close', 'btn btn-default btn-xs')"
              title="Save and close modal properties"
              @click.prevent="resetEditingModal"
            >
              <IconClose width="12" />
            </button>

            <div :class="b('demo-modal-properties-title')">
              {{ $t('WidgetEditor.demoModalPropertiesTitle') }}
            </div>
          </div>

          <div :class="b('demo-modal-properties-body')">
            <ControlCheckbox
              v-model="jsonData.modal.header.shownButtonClose"
              :class="b('field')"
              :label="$t('WidgetEditor.props.headerShownButtonClose.label')"
            />

            <ControlCheckbox
              v-model="jsonData.modal.header.shown"
              :class="b('field')"
              :label="$t('WidgetEditor.props.headerShown.label')"
            />

            <ControlInput
              v-if="jsonData.modal.header.shown"
              v-model="jsonData.modal.header.title"
              :class="b('field')"
              direction="horizontal"
              :label-before="$t('WidgetEditor.props.headerTitle.label')"
              :hint-text="$t('WidgetEditor.props.headerTitle.hint')"
            />

            <ControlCheckbox
              v-model="jsonData.modal.footer.shown"
              :class="b('field')"
              :label="$t('WidgetEditor.props.footerShown.label')"
            />
          </div>
        </div>

        <WidgetEditorPropertyPane
          v-else
          :class="b('property-pane')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import logger from 'general/logger';
  import { noticeMixin } from 'general/mixins';
  import ControlInput from 'general/components/controls/ControlInput';
  import ControlCheckbox from 'general/components/controls/ControlCheckbox';
  import IconBack from 'general/components/icons/IconBack';
  import IconClose from 'general/components/icons/IconClose';
  import SmartphoneWrapper from 'general/components/SmartphoneWrapper';
  import WidgetEditorComponentPalette from './WidgetEditorComponentPalette';
  import WidgetEditorDemoModal from './WidgetEditorDemoModal';
  import WidgetEditorPropertyPane from './WidgetEditorPropertyPane';

  const { mapGetters, mapActions } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-editor',

    mixins: [
      noticeMixin,
    ],

    components: {
      WidgetEditorDemoModal,
      WidgetEditorComponentPalette,
      WidgetEditorPropertyPane,
      SmartphoneWrapper,
      ControlInput,
      ControlCheckbox,
      IconBack,
      IconClose,
    },

    data: () => ({
      widgetType: null,
      autoSave: true,
    }),

    computed: {
      ...mapGetters({
        configurableElement: 'widgetTypes/getConfigurableElement',
        isEditingModal: 'editor/getEditingModal',
      }),

      jsonData() {
        return this.widgetType.attributes.json_data;
      },
    },

    watch: {
      configurableElement: {
        handler(data) {
          if (data) {
            this.widgetType = _.cloneDeep(data);
            this.setComponents(this.jsonData.components);
          }
        },
        immediate: true,
        deep: true,
      },

      jsonData: {
        handler(data) {
          if (data && this.autoSave) {
            logger.log(
              'jsonData!',
              'widgetType:', this.widgetType,
            );

            this.updateItem(this.widgetType);
          }
        },
        deep: true,
      },
    },

    methods: {
      ...mapActions({
        resetConfigurable: 'widgetTypes/resetConfigurable',
        setComponents: 'editor/setComponents',
        update: 'widgetTypes/update',
        setEditingModal: 'editor/setEditingModal',
        resetEditingModal: 'editor/resetEditingModal',
        resetSelectedComponentUuid: 'editor/resetSelectedComponentUuid',
        resetComponent: 'propertyPane/resetComponent',
      }),

      /**
       * ...
       */
      updateItem(element) {
        const updated = this.update({ element });

        if (updated) {
          this.notice(this.$t('WidgetEditor.noticeUpdateSuccess'), this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice(this.$t('WidgetEditor.noticeUpdateError'), this.NOTICE_LEVEL_ERROR);
        }
      },

      back() {
        this.resetConfigurable();
        this.resetSelectedComponentUuid();
        this.resetComponent();
        this.resetEditingModal();
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor
    max-width 1400px
    height 100%
    margin-x(auto)

    &__controls
      height 26px
      margin-bottom 15px

    &__button-back
      flex()
      align-items center

      span
        margin-left 5px

    &__button-edit-modal
      display block
      margin-x(auto)

    &__workspace
      display flex
      margin-top 15px

    &__component-palette-container
      //

    &__demo-modal-container
      margin-x(auto)

    &__widget-editor-demo-modal-wrap
      height 100%
      padding 15px
      background-color rgba(#000, .5)
      overflow-y auto

      &_is-editing-modal
        box-shadow 0 0 0 1px #f00

    &__property-pane-container
      padding-top 41px

    &__widget-type-properties
      margin-bottom 30px

    // TODO: duplicate WidgetEditorPropertyPane styles:
    &__demo-modal-properties
      display flex
      flex-direction column
      width 300px
      border 1px solid #eee

    &__demo-modal-properties-header
      border-bottom 1px dashed #eee
      padding-y(5px)
      position relative

    &__demo-modal-properties-button-close
      position absolute
      top 50%
      left 10px
      transform translateY(-50%)

    &__demo-modal-properties-title
      typography(14px, 24px)
      text-align center

    &__demo-modal-properties-body
      padding 20px 15px 10px
    // TODO: duplicate WidgetEditorPropertyPane styles;

    &__field
      width 100%
      margin-bottom 15px

      +is-last()
        margin-bottom 0

    &__field-code-box
      background-color rgba($brand-info, .3)
      border-radius 3px
      padding 5px 10px
      margin-top 10px

    &__property-pane
      //
</style>
