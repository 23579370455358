export default {
  name: 'mbst-number-input',
  aliasName: 'Number Input',
  properties: {
    backendname: 'number-input',
    placeholder: 'My Number',
    value: '',
    defaultvalue: '',
    iscached: false,
    objectColumn: null,
    dataFormat: '',
    numberMask: '',
    numberAutogrow: false,
    prefix: '',
    suffix: '',
    darkMode: false,
    design: '',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-text-input .text-input',
        rules: []
      }
    ],
    android: [
      {
        selector: '.mbst-text-input .text-input',
        rules: []
      }
    ]
  },
}
