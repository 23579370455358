var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-toggle", {
    attrs: {
      label: _vm.data.label,
      "checked-icon": "check",
      "unchecked-icon": "clear",
      color: "primary",
      dense: "",
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }