import {
  makeControlProps,
  backgroundProps,
  typographyProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  })
]

const view = [
  {
    selector: '.mbst-toolbar__item',
    controls: [
      typographyProps,
      makeControlProps('sizing', {
        label: 'Sizing',
        width: {
          label: 'Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'width'
        },
        maxWidth: {
          label: 'Max. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-width'
        },
        minWidth: {
          label: 'Min. Width',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-width'
        },
        height: {
          label: 'Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'height'
        },
        maxHeight: {
          label: 'Max. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'max-height'
        },
        minHeight: {
          label: 'Min. Height',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'min-height'
        },

      }),
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
    ]
  }
]

const actions = []

export default {
  properties,
  view,
  actions
}
