const SERVICE_MESSAGE_LEVEL_INFO = 'SERVICE_MESSAGE_LEVEL_INFO';
const SERVICE_MESSAGE_LEVEL_SUCCESS = 'SERVICE_MESSAGE_LEVEL_SUCCESS';
const SERVICE_MESSAGE_LEVEL_WARNING = 'SERVICE_MESSAGE_LEVEL_WARNING';
const SERVICE_MESSAGE_LEVEL_ERROR = 'SERVICE_MESSAGE_LEVEL_ERROR';
const levelList = {
  SERVICE_MESSAGE_LEVEL_INFO,
  SERVICE_MESSAGE_LEVEL_SUCCESS,
  SERVICE_MESSAGE_LEVEL_WARNING,
  SERVICE_MESSAGE_LEVEL_ERROR,
};

export default {
  data: () => ({
    ...levelList,
    serviceMessageData: {
      serviceMessageText: '',
      serviceMessageIsError: false,
    },
  }),

  methods: {
    /**
     * Установка служебного сообщения
     *
     * @param {string} text
     * @param {string} level
     */
    setServiceMessage(text, level = SERVICE_MESSAGE_LEVEL_INFO) {
      if (!(level in levelList)) {
        console.warn('Parameter "level" is invalid!');
        return;
      }
      this.serviceMessageData.serviceMessageText = text;
      this.serviceMessageData.serviceMessageIsError = (level === SERVICE_MESSAGE_LEVEL_ERROR);
    },

    /**
     * Сброс служебного сообщения
     */
    resetServiceMessage() {
      this.serviceMessageData.serviceMessageText = '';
      this.serviceMessageData.serviceMessageIsError = false;
    },
  },
};
