/*global MOBSTEDAPP */

// Libraries
const _ = require('underscore');

// Collections
const TriggersCollection = require('collections/TriggersCollection');

// Views
const TriggersView = require('views/TriggersView');

module.exports = {

  triggersInit(triggers, apiProviders, apiKeys, apiApps) {

    // Get API Providers
    MOBSTEDAPP.APIProviders = _.reduce(apiProviders, (res, apiprovider) => {
      apiprovider.isActive = false;
      let obj = _.find(res, item => item.id === apiprovider.APIProviderId);
      if (!obj) {
        let te = {
          id: apiprovider.APIProviderId,
          isActive: false,
          APIProviderName: apiprovider.APIProviderName,
          APIProviderMethods: []
        }
        if (apiprovider.id) te.APIProviderMethods.push(_.omit(apiprovider, ['APIProviderId', 'APIProviderName']));
        res.push(te);
      } else {
        if (apiprovider.id) obj.APIProviderMethods.push(_.omit(apiprovider, ['APIProviderId', 'APIProviderName']));
      }
      return res;
    }, []);

    // Get API Keys
    MOBSTEDAPP.APIKeys = apiKeys;
    MOBSTEDAPP.APIApps = apiApps;

    this.triggersView = new TriggersView({
      collection: new TriggersCollection(triggers),
    });

    this.triggersView.render();

  },
  triggersDestroy() {
    if (this.triggersView)
      this.triggersView.close();
  }

};