import {
  backgroundProps,
  typographyProps,
  loopControlProps,
  makeControlProps,
  visibilityProps,
  sizingProps,
  $t,
} from './helper'

import MakeEventBus from 'general/utils/MakeEventBus'
const EventBus = MakeEventBus('root')

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname',
  }),
  makeControlProps('input', {
    pathValue: 'properties.label',
    label: 'Label',
  }),
  makeControlProps('input', {
    pathValue: 'properties.icon',
    label: 'Icon class',
    placeholder: 'fas fa-map-marker',
    hint: {
      text: $t('general.iconsPackageHint'),
      action: () => {
        window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank')
      },
    },
    extractClass: true,
  }),
  makeControlProps('select', {
    pathValue: 'properties.clearable',
    label: 'Show clear icon',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('select', {
    pathValue: 'properties.design',
    label: 'Design',
    options: [
      { label: 'standard', value: '' },
      { label: 'filled', value: 'filled' },
      { label: 'outlined', value: 'outlined' },
      { label: 'standout', value: 'standout' },
      { label: 'borderless', value: 'borderless' },
    ],
  }),
  makeControlProps('select', {
    pathValue: 'properties.darkMode',
    label: 'Dark mode',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('select', {
    pathValue: 'properties.multiple',
    label: 'Multiple selection',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('select', {
    pathValue: 'properties.allowUserOption',
    label: 'Allow user option',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('select', {
    pathValue: 'properties.allowUseFiltering',
    label: 'Allow use filtering',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),
  makeControlProps('options', {
    pathValue: 'properties.options',
    label: 'Static options',
    allowEmpty: true,
  }),

  makeControlProps('expansion', {
    pathValue: 'properties.popupCollapse',
    label: 'Dynamic options',
    subcontrols: [
      makeControlProps('input', {
        pathValue: 'properties.dynamicOptions',
        label: 'Data Source',
        hint: {
          text: $t('general.insertHashtagHereHint'),
          action: () => {
            EventBus.$emit('root:onShowHashtagGenerator')
          },
        },
      }),
      makeControlProps('input', {
        pathValue: 'properties.dynamicOptionsLabelKey',
        label: 'Label key',
      }),
      makeControlProps('input', {
        pathValue: 'properties.dynamicOptionsValueKey',
        label: 'Value key',
      }),
    ],
  }),

  makeControlProps('select', {
    pathValue: 'properties.iscached',
    label: 'Cache value',
    options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  }),

  visibilityProps,
  loopControlProps,
]

const view = [
  {
    selector: '.mbst-select .q-field__native',
    controls: [
      _.omit(typographyProps, ['font', 'style', 'align']),
      makeControlProps('typography', {
        align: {
          label: 'Align',
          pathValue: 'justify-content',
          options: [
            {
              icon: 'fa fa-align-left',
              value: 'flex-start',
            },
            {
              icon: 'fa fa-align-center',
              value: 'center',
            },
            {
              icon: 'fa fa-align-right',
              value: 'flex-end',
            },
          ],
        },
      }),
    ],
  },
  {
    selector: '.mbst-select',
    controls: [
      _.pick(typographyProps, ['controlName', 'font'])
    ],
  },
  {
    selector: '.mbst-select .q-field__label, .mbst-select .q-field__prepend',
    controls: [
      makeControlProps('typography', {
        label: 'Label styling',
        color: {
          label: 'Label color',
          pathValue: 'color',
        },
      }),
    ],
  },
  {
    selector: '.mbst-select.q-field--focused .q-field__native',
    controls: [
      makeControlProps('typography', {
        // label: 'Label styling',
        color: {
          label: 'Focused color',
          pathValue: 'color',
        },
      }),
      // makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
    ],
  },
  {
    selector: '.mbst-select',
    controls: [_.omit(sizingProps, ['height', 'maxHeight', 'minHeight'])],
  },
  {
    selector: '.mbst-select .q-field__control:before',
    controls: [makeControlProps('borders', { label: 'Borders' })],
  },
  {
    selector: '.mbst-select .q-field__control',
    controls: [
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      makeControlProps('spacing', { label: 'Spacing' }),
      backgroundProps,
    ],
  },
  {
    selector: '.mbst-select.q-field--standout.q-field--focused .q-field__control',
    controls: [
      makeControlProps('background', {
        label: 'Standout background',
        color: {
          label: 'Color',
          pathValue: 'background-color',
        },
      }),
    ],
  },
]

const viewMigration = [
  {
    from: '.mbst-select',
    to: '.mbst-select .q-field__control:before',
    directives: [
      'border-width',
      'border-style',
      'border-color',
      'border-bottom-width',
      'border-bottom-style',
      'border-bottom-color',
      'border-top-width',
      'border-top-style',
      'border-top-color',
      'border-right-width',
      'border-right-style',
      'border-right-color',
      'border-left-width',
      'border-left-style',
      'border-left-color',
    ],
  },
  {
    from: '.mbst-select',
    to: '.mbst-select .q-field__control',
    directives: ['padding', 'margin'],
  },
  {
    from: '.mbst-select .q-field__native',
    to: '.mbst-select',
    directives: ['font-family'],
  }
]

const actions = [true]

export default {
  properties,
  view,
  viewMigration,
  actions,
}
