var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _c("div", { class: _vm.b("title") }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("WidgetEditorComponentPalette.title")) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("list") },
      _vm._l(_vm.components, function (component, index) {
        return _c(
          "vddl-draggable",
          {
            key: index,
            class: _vm.b("component"),
            attrs: {
              draggable: component,
              copied: _vm.copied,
              type: "component",
              "effect-allowed": "copy",
              "data-tour": `wc-${component.name}`,
            },
          },
          [
            _c(
              "div",
              { class: _vm.b("component-icon") },
              [
                component.iconPreview.component
                  ? _c(component.iconPreview.component, {
                      tag: "component",
                      attrs: { width: component.iconPreview.width || 100 },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.b("component-name") }, [
              _vm._v("\n        " + _vm._s(component.aliasName) + "\n      "),
            ]),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }