<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 12 2"
    :width="width"
    :height="height"
  >
    <path
      d="M7 0h4c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1s.4477-1 1-1h6z"
    ></path>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-minus',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },

    props: {
      height: {
        default: 2,
      },
    },
  };
</script>
