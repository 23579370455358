import MakeHttp from 'general/utils/MakeHttp';

const http = MakeHttp();

const SET_COMPONENTS = 'SET_COMPONENTS';
const ADD_COMPONENT = 'ADD_COMPONENT';
const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
const DELETE_COMPONENT = 'DELETE_COMPONENT';
const SET_SELECTED_COMPONENT_UUID = 'SET_SELECTED_COMPONENT_UUID';
const RESET_SELECTED_COMPONENT_UUID = 'RESET_SELECTED_COMPONENT_UUID';
const SET_EDITING_MODAL = 'SET_EDITING_MODAL';
const RESET_EDITING_MODAL = 'RESET_EDITING_MODAL';

const beforeDeleteComponent = async (component) => {
  const { name, properties: { src } } = component;

  if (name === 'w-image' && src) {
    const endpoint = `/files/deletebyname/?filePath=${src}`;
    const { errors, meta: { affectedRecords } } = await http.delete(endpoint);

    if (errors) {
      console.error(errors);
      return false;
    }
    if (affectedRecords) {
      return true;
    }
  }
  return false;
};

export default {
  namespaced: true,

  state: () => ({
    components: [],
    selectedComponentUuid: null,
    editingModal: false,
  }),

  getters: {

    getComponents: state => state.components,

    getSelectedComponentUuid: state => state.selectedComponentUuid,

    getEditingModal: state => state.editingModal,

  },

  actions: {

    async setComponents({ commit }, components) {
      commit(SET_COMPONENTS, components);
    },

    async addComponent({ commit }, { index, component }) {
      commit(ADD_COMPONENT, { index, component });
    },

    async updateComponent({ state, commit }, component) {
      const index = state.components.findIndex(item => (item.uuid === component.uuid));
      commit(UPDATE_COMPONENT, { index, component });
    },

    async deleteComponent({ state, commit }, component) {
      beforeDeleteComponent(component);
      const index = state.components.findIndex(item => (item.uuid === component.uuid));
      commit(DELETE_COMPONENT, index);
    },

    async setSelectedComponentUuid({ state, commit }, uuid) {
      const component = state.components.find(element => (element.uuid === uuid));
      if (component) {
        commit(RESET_EDITING_MODAL);
        commit(SET_SELECTED_COMPONENT_UUID, uuid);
      }
    },

    async resetSelectedComponentUuid({ commit }) {
      commit(RESET_SELECTED_COMPONENT_UUID);
    },

    async setEditingModal({ dispatch, commit }) {
      dispatch('resetSelectedComponentUuid');
      commit(SET_EDITING_MODAL);
    },

    async resetEditingModal({ commit }) {
      commit(RESET_EDITING_MODAL);
    },

  },

  mutations: {

    [SET_COMPONENTS](state, components) {
      state.components = components;
    },

    [ADD_COMPONENT](state, { index, component }) {
      if (!state.components.length) {
        state.components.push(component);
      } else {
        state.components.splice(index, 0, component);
      }
    },

    [UPDATE_COMPONENT](state, { index, component }) {
      state.components.splice(index, 1, component);
    },

    [DELETE_COMPONENT](state, index) {
      state.components.splice(index, 1);
    },

    [SET_SELECTED_COMPONENT_UUID](state, uuid) {
      state.selectedComponentUuid = uuid;
    },

    [RESET_SELECTED_COMPONENT_UUID](state) {
      state.selectedComponentUuid = null;
    },

    [SET_EDITING_MODAL](state) {
      state.editingModal = true;
    },

    [RESET_EDITING_MODAL](state) {
      state.editingModal = false;
    },

  },
};
