export default {
  name: 'mbst-image',
  aliasName: 'Image',
  properties: {
    backendname: 'image',
    image: {
      attributes: {},
      id: null,
      type: 'files',
      thumbs: [],
      selectedId: 0, // тут хранится ID выбранной картинки
      url: '',
      altText: '',
    },
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-image',
        rules: [
          { directive: 'width', value: '100%' }
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-image',
        rules: [
          { directive: 'width', value: '100%' }
        ]
      }
    ]
  },
}
