'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Templates
var _tpl = require('text!bcTemplates/execution/executeAfter.html');

module.exports = Backbone.View.extend({

    tagName: 'div',

    bindings: {
        '#successOperation': {
            observe: 'successOperationId',
            initialize: function($el) {
                this.selectTwo($el);
            },
            selectOptions: {
                collection: function () {
                    return this.model.get('successOperation');
                },
                defaultOption: {
                    label: 'Choose operation...',
                    value: null
                },
                labelPath: 'operation.Name',
                valuePath: 'id'
            },
            onSet: function(val) {
                this.model.set('successOperationId', val);
                return val;
            }
        },
        '#failOperation': {
            observe: 'failOperationId',
            initialize: function($el) {
                this.selectTwo($el);
            },
            selectOptions: {
                collection: function () {
                    return this.model.get('failOperation');
                },
                defaultOption: {
                    label: 'Choose operation...',
                    value: null
                },
                labelPath: 'operation.Name',
                valuePath: 'id'
            },
            onSet: function(val) {
                this.model.set('failOperationId', val);
                return val;
            }
        }
    },

    initialize: function(options) {
        var t = this;
        t.tpl = _.template(_tpl);
        t.parentView = options.parentView;

        console.log('Execute after operation (view):');
        console.log(this);
    },

    render: function() {
        var t = this;
        t.$el.empty();

        t.$el.html(t.tpl(t.model.toJSON()));

        t.stickit();
    },

    close: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    // Навешивает на селекты seletc2
    // ----------------------------------------------------
    selectTwo: function(select) {

        var options = {};

        switch (select.attr('data-type')) {
            case 'tags':
                options.tags = true;
                break;

            case 'nosearch':
                options.minimumResultsForSearch = -1;
                break;

        }

        if (!select.hasClass('select2-hidden-accessible')) {
            select.select2(options);
        }

    },

});