'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');
var Stickit = require('backbone.stickit');

// Models
var OperationsItemModel = require('models/OperationsItemModel');

// Views
var OperationsItemView = require('views/operations/OperationsItemView');
var OperationSettingsView = require('views/operations/OperationSettingsView');

// Templates
var _operations = require('text!bcTemplates/operations/operations.html');

// Helper
var PubSub = require('helper/pubsub');

module.exports = Backbone.View.extend({

    // el: '#triggerOperations',
    tag: 'div',

    events: {
        'click #createTriggerOperation': 'createOperation'
    },

    initialize: function(options) {
        console.log(options);
        this.tpl = _.template(_operations);

        // this.triggerView = options.triggerView;

        this.listenTo(this.collection, "reset", this.render, this);
    },

    render: function() {
        var t = this;
        t.itemView = [];
        t.$el.empty().css('height', '100%');

        t.$el.html(t.tpl());

        var $operationsItemView = _.map(t.collection.models, function(model) {
            var operationsItemView = new OperationsItemView({
                operationsView: t,
                model: model
            });

            t.itemView.push(operationsItemView);
            operationsItemView.render();
            return operationsItemView.$el;
        });

        t.$el.find('.b-trigger-operations__list-body').append($operationsItemView);
    },

    close: function() {
        PubSub.off('initOperationSettings');
        _.each(this.itemView, function(itemView){
            if (itemView.close){
                itemView.close();
            }
        });
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    createOperation: function(e) {
        e.preventDefault();
        var operationItemModel = new OperationsItemModel();

        var operationsItemView = new OperationsItemView({
            operationsView: this,
            model: operationItemModel
        });

        var operationSettingsView = new OperationSettingsView({
            operationView: operationsItemView,
            model: operationItemModel.get('operation')
        });

        operationSettingsView.render();
    }

});