var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _vm.labelBefore
      ? _c("div", {
          class: _vm.b("label-before"),
          domProps: { innerHTML: _vm._s(_vm.labelBefore) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.b("list") },
      _vm._l(_vm.elements, function (element, index) {
        return element
          ? _c(
              "ControlCheckbox",
              _vm._b(
                { key: index, class: _vm.b("item") },
                "ControlCheckbox",
                element,
                false
              )
            )
          : _vm._e()
      }),
      1
    ),
    _vm._v(" "),
    _vm.hint
      ? _c("div", {
          class: _vm.b("hint"),
          domProps: { innerHTML: _vm._s(_vm.hint) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }