var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 12 2",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M7 0h4c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1s.4477-1 1-1h6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }