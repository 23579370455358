'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

module.exports = Backbone.Collection.extend({

    initialize: function() {

    }

});