export default function () {
  return {
    currentDOMElement: null,
    currentMediaQuery: null,
    documentStylesheets: [],
    documentMediaQueries: [],
    clipboard: null,
    components: {
      default: [],
      custom: []
    },
    interface: {
      SelectedNodeActionsbar: true,
      defaultDeviceView: null,
      outlinedTotal: false,
      outlineParents: {
        enabled: false,
        parentNode: null
      }
    },
    status: []
  }
}
