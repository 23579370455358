<template>
  <div :class="b({ theme, isDisabled: disabled, isChecked, hasFail: errors.length })">
    <input
      :id="vId"
      class="form-control"
      type="checkbox"
      :name="name"
      :checked="isChecked"
      :required="required"
      :disabled="disabled"
      @change="handlerChange"
    />

    <label
      :class="b('checkbox')"
      :for="vId"
    >
      <IconCheck
        :class="b('icon-check')"
        width="13"
      />
    </label>

    <label
      v-if="label"
      :class="b('label')"
      :for="vId"
      v-html="label"
    ></label>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';
  import IconCheck from 'general/components/icons/IconCheck';

  export default {
    name: 'control-checkbox',

    mixins: [
      baseMixin,
    ],

    components: {
      IconCheck,
    },

    model: {
      prop: 'checked',
      event: 'update',
    },

    props: {
      name: {
        type: String,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      theme: {
        type: String,
        default: 'default',
      },
      label: {
        type: String,
      },
    },

    data: () => ({
      isChecked: false,
      errors: [],
    }),

    watch: {
      checked: {
        handler(data) {
          this.isChecked = data;
        },
        immediate: true,
      },
    },

    methods: {
      handlerChange(event) {
        const value = event.target.checked;

        this.isChecked = value;
        this.$emit('update', value);
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  $checkbox-size = 24px

  .control-checkbox
    display flex
    position relative

    &_is-disabled
      cursor not-allowed

    > input
      display block
      size($checkbox-size)
      margin 0
      position absolute
      top 0
      left 0
      z-index -1
      opacity 0

    > label
      margin-bottom 0 !important

      ~/_is-disabled&
        pointer-events none

    &__checkbox
      flex(center, center)
      flex-shrink 0
      size($checkbox-size)
      background-color #fff
      border 1px solid #ccc
      border-radius 2px
      cursor pointer
      transition $duration
      box-sizing border-box

      ~/_theme_default &
        //

      ~/_theme_button-radial &
        border-radius 50%

      ~/:hover &
        ~/_theme_button-radial&
          background-color darken($color-green, 20%)

          ~/_is-checked&
            background-color $color-red

      ~/_is-disabled &
        background-color #eee

      ~/_has-fail &
        border-color $brand-danger

    &__icon-check
      transition-duration $duration

      ~/_theme_default &
        opacity 0

      ~/_theme_button-radial &
        opacity .25

      ~/:hover &
        ~/_theme_default&
          opacity .25

        ~/_theme_button-radial&
          color #fff
          opacity 1

      ~/_is-checked &
        ~/_theme_default&
          opacity 1

        ~/_theme_button-radial&
          color darken($color-green, 20%)
          opacity 1

        ~/:hover&
          ~/_theme_default&
            opacity .65

        ~/_is-disabled&
          opacity .5 !important

      ~/_is-disabled &
        opacity 0 !important

    &__label
      display block
      min-height $checkbox-size
      padding-top 5px
      padding-left 10px
      word-break break-all
      cursor pointer
      user-select none

      ~/_is-disabled &
        color #b4b4b4

      a
        link()
        state-color(#000)
        link-underline-1(#000, transparent)
</style>
