'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/triggers/triggeroperationsapi",

    defaults: {
        triggerOperation: {
            id: null,
            TriggerId: null,
            OperationId: null,
            SortOrder: null,
            SuccessOperationId: null,
            FailOperationId: null,
            AlwaysOperationId: null,
            Level: 0
        },
        operation: {
            id: null,
            Name: 'NewOperation',
            APIProviderId: null,
            APIKeyId: null,
            APIApplicationId: null,
            APIProviderMethodId: null,
            ParamsValues: {},
            DataTransformations: [],
            ConditionalDataTransformations: [],
            LastModified: null,
            ExtendedParams: []
        },
        operationExt: {
            APIKeyName: null,
            APIProviderMethodName: null,
            APIProviderName: null
        },
        success: null,
        fail: null,
        always: null
    },

    initialize: function() {
    }

});