import bottomToolbarItem from './bottomToolbarItem';
import { backgroundDefault } from './helper';

export default {
  type: 'outflow',
  name: 'mbst-bottom-toolbar',
  aliasName: 'Bottom Toolbar',
  properties: {
    backendname: 'bottom-toolbar',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-bottom-toolbar',
        rules: [
          { directive: 'width', value: '100%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-bottom-toolbar',
        rules: [
          { directive: 'width', value: '100%' },
          { directive: 'border-width', value: '0' },
          ...backgroundDefault
        ]
      }
    ]
  },
  items: [
    bottomToolbarItem,
    bottomToolbarItem,
  ]
}
