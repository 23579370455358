'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    urlRoot: "/triggers/operationsapi",

    defaults: {
        id: null,
        Name: 'NewOperation',
        APIProviderId: null,
        APIKeyId: null,
        APIApplicationId: null,
        APIProviderMethodId: null,
        ParamsValues: {},
        DataTransformations: [],
        ConditionalDataTransformations: [],
        LastModified: null,
        ExtendedParams: []
    },

    parse: function(response, options) {
        return _.omit(response, 'error');
    },

    initialize: function() {
    }

});