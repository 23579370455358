import { loopControlProps, makeControlProps, visibilityProps, typographyProps, backgroundProps } from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.label',
    label: 'Label'
  }),
  makeControlProps('input', {
    pathValue: 'properties.value',
    label: 'Value'
  }),
  makeControlProps('select', {
    pathValue: 'properties.iscached',
    label: 'Cache value',
    options: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.selected',
    label: 'Selected',
    options: [
      {label: '-', value: false},
      {label: 'selected', value: true},
    ],
  }),
  makeControlProps('select', {
    pathValue: 'properties.labelPosition',
    label: 'Label position',
    options: [
      {label: 'right', value: 'right'},
      {label: 'left', value: 'left'},
    ],
  }),
  visibilityProps,
  loopControlProps
];

const view = [
  {
    selector: '.mbst-radio .q-item__label',
    controls: [
      typographyProps
    ]
  },
  {
    selector: '.mbst-radio .q-radio__inner--active, .mbst-radio .q-radio__inner, .mbst-radio .q-icon',
    controls: [
      makeControlProps('typography', {
        label: 'Control color',
        color: {
          label: 'color',
          pathValue: 'color',
        },
      })
    ]
  },
  {
    selector: '.mbst-radio',
    controls: [
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      backgroundProps,
    ]
  }




];

const actions = [true];

export default {
  properties,
  view,
  actions
}
