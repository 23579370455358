export default {
  name: 'mbst-radio-buttons',
  aliasName: 'Radio Buttons',
  properties: {
    backendname: 'radio-buttons',
    options: [
      { label: 'Label option', value: 'Value option', selected: false, conditions: [] },
    ],
    visibility: {
      conditions: [],
      hidden: false
    },
    value: ''
  },
  actions: null,
  css: {
    ios: [
    ],
    android: [
    ]
  },
}
