import WTextPropsSchema from './WTextPropsSchema';
import WImagePropsSchema from './WImagePropsSchema';
import WBotsPropsSchema from './WBotsPropsSchema';
import WA2hsPropsSchema from './WA2hsPropsSchema';
import WVcardPropsSchema from './WVcardPropsSchema';

export default {
  'w-text': WTextPropsSchema,
  'w-image': WImagePropsSchema,
  'w-bots': WBotsPropsSchema,
  'w-a2hs': WA2hsPropsSchema,
  'w-vcard': WVcardPropsSchema,
};
