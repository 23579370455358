var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "text-caption text-uppercase q-pl-sm" }, [
      _vm._v(_vm._s(_vm.section.title)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row items-center" },
      [
        _vm._l(_vm.section.items, function (item, $ind) {
          return [
            _c(
              "div",
              {
                staticClass: "col-auto mbst-header__item",
                class: {
                  "q-ml-sm": $ind != 0,
                  "is-active": item.name == _vm.activeItem,
                },
              },
              [
                _c(
                  "q-btn",
                  {
                    attrs: {
                      type: "a",
                      round: "",
                      flat: "",
                      "data-tour": `menu-${item.name}`,
                      href: item.to,
                    },
                    on: {
                      click: function ($event) {
                        item.action ? item.action() : undefined
                      },
                    },
                  },
                  [
                    _c("q-icon", {
                      attrs: { size: "1.25em", name: item.icon },
                    }),
                    _vm._v(" "),
                    _c(
                      "q-tooltip",
                      {
                        attrs: {
                          "max-width": "200px",
                          anchor: "bottom middle",
                          self: "top middle",
                          "content-class": "shadow-1",
                        },
                      },
                      [
                        _c("div", { staticClass: "text-subtitle1 q-mb-sm" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-body2" }, [
                          _vm._v(_vm._s(item.description)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }