<template>
  <IconBase
    :icon-name="$options.name"
    :width="width"
    :height="height"
  >
    <g>
      <path
        fill="#527eb0"
        d="M44.37 0h39.27C119.47 0 128 8.54 128 44.37v39.27c0 35.83-8.53 44.36-44.36 44.36H44.37C8.54 128 0 119.43 0 83.64V44.37C0 8.54 8.54 0 44.37 0z"
      ></path>
      <path
        fill="#fff"
        d="M104.81 44.09c.59-2 0-3.43-2.83-3.43h-9.34a4 4 0 0 0-4 2.64s-4.8 11.56-11.51 19.07C75 64.55 74 65.24 72.78 65.24c-.59 0-1.45-.69-1.45-2.67V44.09c0-2.37-.68-3.43-2.68-3.43H54a2.25 2.25 0 0 0-2.34 2.15c0 2.25 3.36 2.76 3.71 9.08v13.76c0 3-.55 3.56-1.73 3.56-3.17 0-10.85-11.62-15.42-24.91-.92-2.64-1.82-3.64-4.21-3.64h-9.35c-2.67 0-3.2 1.26-3.2 2.64 0 2.47 3.16 14.72 14.73 31 7.73 11 18.58 17 28.46 17 5.93 0 6.67-1.34 6.67-3.63v-8.35c0-2.67.56-3.2 2.44-3.2 1.38 0 3.75.69 9.28 6 6.33 6.33 7.37 9.17 10.93 9.17h9.34c2.66 0 4-1.34 3.23-4s-3.9-6.37-7.9-10.87c-2.18-2.57-5.44-5.33-6.43-6.72-1.39-1.78-1-2.57 0-4.15.04-.01 11.43-16.03 12.6-21.46z"
      ></path>
    </g>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-vk',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
