const dict = require('general/lang/WidgetEditorPropertyPane.json');
const $t = (prop, def) => {
  if (window.MOBSTEDAPP && window.MOBSTEDAPP.lang) {
    return dict[window.MOBSTEDAPP.lang]['w-vcard'][prop] || def || prop;
  } else {
    return def || prop;
  }
}
const properties = {
  companyName: {
    uuid: null,
    componentName: 'ControlInput',
    properties: {
      labelBefore: $t('companyName', 'Company name'),
      value: '',
    },
    vModelPropertyName: 'value',
  },

  companyEmail: {
    uuid: null,
    componentName: 'ControlInput',
    properties: {
      labelBefore: $t('companyEmail', 'Company e-mail'),
      value: '',
    },
    vModelPropertyName: 'value',
  },

  companyPhone: {
    uuid: null,
    componentName: 'ControlInput',
    properties: {
      labelBefore: $t('companyPhone', 'Company phone'),
      value: '',
    },
    vModelPropertyName: 'value',
  },
};

export default {
  properties,
};
