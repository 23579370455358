<template>
  <div :class="b()">
    <button
      :class="b('button-duplicate')"
      title="Duplicate"
      @click.prevent="handlerDuplicate"
    >
      <IconCopy height="11" />
    </button>

    <button
      :class="b('button-delete')"
      title="Delete"
      @click.prevent="handlerDelete"
    >
      <IconTrash height="12" />
    </button>
  </div>
</template>

<script>
  import IconCopy from 'general/components/icons/IconCopy';
  import IconTrash from 'general/components/icons/IconTrash';

  export default {
    name: 'widget-editor-content-area-component-controls',

    components: {
      IconCopy,
      IconTrash,
    },

    methods: {
      handlerDuplicate() {
        this.$emit('duplicate');
      },

      handlerDelete() {
        this.$emit('delete');
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-editor-content-area-component-controls
    display flex
    background #fff
    border-radius 3px
    padding-x(3px)
    typography(12px)
    transition-duration $duration
    box-shadow 0 1px 5px 1px rgba(#000, .2)

    &__button-duplicate
    &__button-delete
      reset-button()
      padding 4px 2px
      transition-duration $duration
      outline none

    &__button-duplicate
      state-background(transparent, $brand-info)
      state-color($brand-info, #fff)

    &__button-delete
      state-background(transparent, $brand-danger)
      state-color($brand-danger, #fff)
</style>
