import {
  makeControlProps,
  backgroundProps,
  typographyProps,
  sizingProps,
  loopControlProps,
  visibilityProps,
  $t,
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('input', {
    pathValue: 'properties.text',
    label: 'Label'
  }),
  makeControlProps('input', {
    pathValue: 'properties.caption',
    label: 'Caption'
  }),
  makeControlProps('input', {
    pathValue: 'properties.iconLeft',
    label: 'Left icon class',
    placeholder: '',
    hint: {
      text: $t('general.iconsPackageHint'),
      action: () => { window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank'); },
    },
    extractClass: true,
  }),

  makeControlProps('input', {
    pathValue: 'properties.iconRight',
    label: 'Right icon class',
    placeholder: '',
    hint: {
      text: $t('general.iconsPackageHint'),
      action: () => { window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank'); },
    },
    extractClass: true,
  }),

  makeControlProps('expansion', {
    pathValue: 'properties.leftSlideCollapse',
    label: 'Left slide properties',
    subcontrols: [

      makeControlProps('select', {
        pathValue: 'properties.leftSlideEnabled',
        label: 'Enable left sliding',
        options: [
          {label: 'Yes', value: true},
          {label: 'No', value: false},
        ]
      }),

      makeControlProps('input', {
        pathValue: 'properties.leftSlideIcon',
        label: 'Icon class',
        placeholder: '',
        hint: {
          text: $t('general.iconsPackageHint'),
          action: () => { window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank'); },
        },
        extractClass: true,
      }),
      makeControlProps('input', {
        pathValue: 'properties.leftSlideText',
        label: 'Text'
      }),
    ]
  }),

  makeControlProps('expansion', {
    pathValue: 'properties.rightSlideCollapse',
    label: 'Right slide properties',
    subcontrols: [

      makeControlProps('select', {
        pathValue: 'properties.rightSlideEnabled',
        label: 'Enable right sliding',
        options: [
          {label: 'Yes', value: true},
          {label: 'No', value: false},
        ]
      }),

      makeControlProps('input', {
        pathValue: 'properties.rightSlideIcon',
        label: 'Icon class',
        placeholder: '',
        hint: {
          text: $t('general.iconsPackageHint'),
          action: () => { window.open('https://fontawesome.com/v5.5.0/icons?d=gallery&m=free', '_blank'); },
        },
        extractClass: true,
      }),
      makeControlProps('input', {
        pathValue: 'properties.rightSlideText',
        label: 'Text'
      }),
    ]
  }),

  visibilityProps,
  loopControlProps,
];

const view = [
  {
    selector: '.mbst-list__item .q-slide-item__content',
    controls: [
      typographyProps,
      sizingProps,
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
    ]
  },
  {
    selector: '.mbst-list__item .q-item__label--caption',
    label: 'Caption style',
    controls: [
      typographyProps
    ]
  },
  {
    selector: '.mbst-list__item .mbst-list__item__lefticon',
    controls: [
      makeControlProps('typography', {
        label: 'Left icon style',
        size: {
          label: 'Size',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'font-size',
        },
        color: {
          label: 'Color',
          pathValue: 'color',
        },
      })
    ]
  },
  {
    selector: '.mbst-list__item .mbst-list__item__righticon',
    controls: [
      makeControlProps('typography', {
        label: 'Right icon style',
        size: {
          label: 'Size',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'font-size',
        },
        color: {
          label: 'Color',
          pathValue: 'color',
        },
      })
    ]
  },
  {
    selector: '.mbst-list__item .q-slide-item__left',
    label: 'Left slide style',
    controls: [
      typographyProps,
      backgroundProps,
      makeControlProps('typography', {
        label: 'Icon style',
        selector: '.mbst-list__item .q-slide-item__left i',
        size: {
          label: 'Size',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'font-size',
        },
        color: {
          label: 'Color',
          pathValue: 'color',
        },
      })
    ]
  },
  {
    selector: '.mbst-list__item .q-slide-item__right',
    label: 'Right slide style',
    controls: [
      typographyProps,
      backgroundProps,
      makeControlProps('typography', {
        label: 'Icon style',
        selector: '.mbst-list__item .q-slide-item__right i',
        size: {
          label: 'Size',
          measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
          pathValue: 'font-size',
        },
        color: {
          label: 'Color',
          pathValue: 'color',
        },
      })
    ]
  }
]

export default {
  properties,
  view,
  actions: [true],
  events: ['click', 'slideleft', 'slideright']
}
