<template>
	<div>
		<div class="text-caption text-uppercase q-pl-sm">{{ section.title }}</div>
		<div class="row items-center">
			<template
				v-for="(item, $ind) in section.items"
			>
				<div class="col-auto mbst-header__item" :class="{'q-ml-sm': $ind != 0, 'is-active': item.name == activeItem}">
					<q-btn
						type="a"
						round flat :data-tour="`menu-${item.name}`"
						@click="item.action ? item.action() : undefined"
						:href="item.to"
					>
						<q-icon size="1.25em" :name="item.icon" />
						<q-tooltip
							max-width="200px"
							anchor="bottom middle"
							self="top middle"
							content-class="shadow-1"
						>
							<div class="text-subtitle1 q-mb-sm">{{ item.title }}</div>
							<div class="text-body2">{{ item.description }}</div>
						</q-tooltip>
					</q-btn>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
	  props: {
	    section: {
	      type: Object
			},
			activeItem: {
	      type: String
			}
		},
	}
</script>

<style lang="scss">
	.mbst-header__item {
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background: white;
			opacity: 0;
			transform: scale(0);
			transition: .25s ease-in-out;
			will-change: opacity, trasform;
		}
		&.is-active {
			&::before {
				opacity: 1;
				transform: scale(1);
			}
			.q-icon {
				opacity: 1;
			}
		}

		&.is-middle.is-active {
			&::before {
				bottom: -9px;
			}
		}

		.q-btn:hover {
			color: inherit;
		}
	}
</style>
