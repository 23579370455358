var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.serviceMessageText
    ? _c("div", { class: _vm.b({ isError: _vm.serviceMessageIsError }) }, [
        _c("div", { class: _vm.b("text") }, [
          _vm._v("\n    " + _vm._s(_vm.serviceMessageText) + "\n  "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }