'use strict';

// Libraries
import logger from 'general/logger';

const _ = require('underscore');
const Backbone = require('backbone');
const Stickit = require('backbone.stickit');

// Models
const OperationModel = require('models/OperationModel');

// Views
const TransformationsView = require('views/transformations/TransformationsView');
const ConditionalDataTransformationView = require('views/ConditionalDataTransformationView');
const ExecutionView = require('views/execution/ExecutionView');
const ExecuteAfterView = require('views/execute-after/ExecuteAfterView');
const APIProvidersView = require('views/apiproviders/accordion/ApiProvidersView');

// Templates
const _operationSettings = require('text!bcTemplates/operations/operationSettings.html');

// Helper
const PubSub = require('helper/pubsub');

const ApiKeysCollection = Backbone.Collection.extend({
  filterApiProvider: function (apiProvider) {
    return _.filter(this.models, function (apiKey) {
      return apiKey.get('APIProviderId') === apiProvider;
    });
  },
});

const ApiAppsCollection = Backbone.Collection.extend({
  filterKey: function (apiKeyId) {
    return _.filter(this.models, function (apiApp) {
      return apiApp.get('apiKeyId') === apiKeyId;
    });
  },
});

// INIT in TriggerOperationsView
// ===================================================
module.exports = Backbone.View.extend({

  tagName: 'div',
  className: 'details-body b-modal-hero__body b-global-box',
  id: 'triggerOperationSettings',

  events: {
    'click #operationCancel': 'close', // возврат к списку операций
    'click #operationSave': 'save', // сохранение настроек операции
    'click #addTransformation': 'addTransformation',
  },

  bindings: {
    '#OperationName': {
      observe: 'Name',
      getVal: function ($el, event, options) {
        if ($el.val() !== '') {
          $el.closest('.form-group').removeClass('has-error');
        } else {
          $el.closest('.form-group').addClass('has-error');
        }
        return $el.val();
      },
    },
    '#apiKeyId': {
      observe: 'APIKeyId',
      initialize: function ($el) {
        this.selectTwoBinding($el);
      },
      selectOptions: {
        collection: function () {
          return this.apiKey;
        },
        defaultOption: {
          label: 'Choose API key...',
          value: null,
        },
        labelPath: 'Name',
        valuePath: 'id',
      },
      onSet: function (val) {
        this.filterApiApps(val);
        if (!val) this.model.set('APIApplicationId', null);
        this.model.set('APIKeyId', val);
        return val;
      },
    },
    '#apiApplicationId': {
      observe: 'APIApplicationId',
      initialize: function ($el) {
        this.selectTwoBinding($el);
      },
      selectOptions: {
        collection: function () {
          return this.apiApp;
        },
        defaultOption: {
          label: 'Choose API Application ...',
          value: null,
        },
        labelPath: 'name',
        valuePath: 'id',
      },
      onSet: function (val) {
        this.model.set('APIApplicationId', val);
        return val;
      },
    },
  },

  initialize: function (options) {

    this.options = options || {};
    this.operationView = this.options.operationView || null;
    console.log('Operation View:', this.operationView);
    this.model = new OperationModel(this.options.model);
    this.tpl = _.template(_operationSettings);

    this.apiKeysCollection = new ApiKeysCollection($.extend(true, [], MOBSTEDAPP.APIKeys));
    this.filterApiKeys(this.model.get('APIProviderId'));

    this.apiAppsCollection = new ApiAppsCollection($.extend(true, [], MOBSTEDAPP.APIApps));
    this.filterApiApps(this.model.get('APIKeyId'));

    PubSub.on('setConditionalDataTransformations', this.setConditionalDataTransformations, this);

    this.dataTransformationsView = new TransformationsView({
      title: '',
      collection: this.model.get('DataTransformations'),
    });
    this.conditionalDataTransformationView = new ConditionalDataTransformationView({
      collection: this.model.get('ConditionalDataTransformations'),
    });

    this.executionView = new ExecutionView({
      parentView: this,
      collection: this.getDataExecution(this.model.get('APIProviderId'), this.model.get('APIProviderMethodId')),
    });

    this.apiProvidersView = new APIProvidersView({
      apiProviderId: this.model.get('APIProviderId'),
      apiProviderMethodId: this.model.get('APIProviderMethodId'),
      triggerOperationSettingsView: this,
      collection: MOBSTEDAPP.APIProviders,
    });
  },

  filterApiKeys: function (apiProviderId) {
    this.apiKeysCollection = new ApiKeysCollection($.extend(true, [], MOBSTEDAPP.APIKeys));
    this.apiKey = new ApiKeysCollection(this.apiKeysCollection.filterApiProvider(apiProviderId));
    this.stickit();
  },

  filterApiApps: function (apiKeyId) {
    this.apiAppsCollection = new ApiAppsCollection($.extend(true, [], MOBSTEDAPP.APIApps));
    this.apiApp = new ApiAppsCollection(this.apiAppsCollection.filterKey(apiKeyId));
    this.stickit();
  },

  render: function () {
    this.$el.empty();

    const $triggerOperationsSettingsView = this.tpl({
      operation: this.model.toJSON(),
      apiKeys: this.apiKeys,
      apiApps: this.apiApps,
    });
    this.$el.html($triggerOperationsSettingsView);

    // Render Data Transformations
    this.$el.find('#DataTransformations .b-collapse__body').append(this.dataTransformationsView.render());

    // Render Conditional Data Transformations
    this.conditionalDataTransformationView.setElement(this.$el.find('#ConditionalDataTransformation .b-collapse__body'));
    this.conditionalDataTransformationView.render();

    // Render Execution
    this.renderExecution();

    // Render API Providers
    this.apiProvidersView.render();
    this.$('.b-apiproviders').append(this.apiProvidersView.$el);

    $('#operations').append(this.$el);

    this.$el.css('padding-bottom', this.$('>.b-global-box__footer').outerHeight());

    const hBox = this.$('.b-trigger-operations__settings>.b-global-box__body').outerHeight();
    const d = this.$('.b-trigger-operations__settings>.b-global-box__body .b-collapse:last').outerHeight();

    this.$('.b-trigger-operations__settings>.b-global-box__body').css('padding-bottom', hBox - d);

    // Data binding
    this.stickit();
    _.each(this.dataTransformationsView.itemViews, (itemView) => {
      const bindings = {
        '[name="dataTransformationsKey"]': 'key',
        '[name="dataTransformationsValue"]': 'value',
      };
      itemView.stickit(itemView.model, bindings);
    });

    _.each(this.conditionalDataTransformationView.itemViews, (itemView) => {
      _.each(itemView.conditionsViews.itemViews, (conditionsView) => {
        const bindings = {
          '[name="dataConditionsField"]': 'field',
          '[name="dataConditionsValue"]': 'value',
          '[name="dataConditionsOperation"]': {
            observe: 'operation',
            selectOptions: {
              collection: () => {
                return conditionsView.conditionRulesView.operandTpls.operand.value;
              },
            },
          },
        };
        conditionsView.stickit(conditionsView.model, bindings);
      });
      _.each(itemView.transformationsViews.itemViews, (transformationsView) => {
        const bindings = {
          '[name="dataTransformationsKey"]': 'key',
          '[name="dataTransformationsValue"]': 'value',
        };
        transformationsView.stickit(transformationsView.model, bindings);
      });
    });

    // TODO: переделать на универсальное говнишко
    this.selectTwo();
  },

  getDataExecution: function (apiProviderId, apiProviderMethodId) {
    // TODO: поножевщина, переделать потом на чо нить нормальное, сейчас хуяк-хуяк и в продакшен

    if (apiProviderId) {
      const currentApiProvider = _.find(MOBSTEDAPP.APIProviders, (item) => {
        return item.id === apiProviderId;
      }) || null;

      if (!currentApiProvider) return [];

      const capm = _.find(currentApiProvider.APIProviderMethods, (item) => {
        return item.id === apiProviderMethodId;
      });

      // FIX: если API provider был создан без параметров
      if (capm && !capm.Params) {
        capm.Params = "[]";
      }
      let currentApiProviderMethod = [];

      try {
        currentApiProviderMethod = JSON && JSON.parse(capm.Params) || $.parseJSON(capm.Params);
      } catch (error) {
        logger.exception(error, capm.Params);
      }

      _.each(currentApiProviderMethod, (method) => {
        method.isSystem = true;
      });

      const params = currentApiProviderMethod.concat(this.model.get('ExtendedParams'));
      const values = this.model.get('ParamsValues');

      return _.map(params, (item) => {
        item.value = values[item.name] || '';
        return item;
      });
    }

    return [];
  },

  // Render Execution Block
  renderExecution: function () {
    this.executionView.render();
    this.$('#Execution .b-collapse__body .b-execution__box').html(this.executionView.$el);
  },

  // TODO: сейчас вызывается при каждом изменении параметров, переделать только на сохранение изменений, хер знает
  setConditionalDataTransformations: function (data) {
    const dataTransformations = this.dataTransformationsView.collection.toJSON() || [];
    const conditionalDataTransformation = this.conditionalDataTransformationView.collection.toJSON() || [];

    this.model.set({
      DataTransformations: dataTransformations,
      ConditionalDataTransformations: conditionalDataTransformation,
    });
  },

  close: function (e) {
    e.preventDefault();
    PubSub.off('setConditionalDataTransformations');
    this.conditionalDataTransformationView.close();
    this.dataTransformationsView.close();
    this.undelegateEvents();
    this.$el.removeData().unbind();
    this.remove();
  },

  save: function (e) {
    e.preventDefault();

    const isNew = this.model.isNew();

    if (!!this.model.get('Name')) {
      this.model.save({}, {
        success: (m, response) => {
          if (response && _.isEmpty(response.error)) {
            const opExt = this.operationView.model.get('operationExt');

            if (this.model.get('APIKeyId')) {
              opExt.APIKeyName = _.find(MOBSTEDAPP.APIKeys, (key) => {
                return key.id === this.model.get('APIKeyId');
              }).Name;
            } else {
              opExt.APIKeyName = null;
            }

            if (this.model.get('APIApplicationId')) {
              opExt.APIApplicationName = _.find(MOBSTEDAPP.APIApps, (app) => {
                return app.id === this.model.get('APIApplicationId');
              }).name;
            } else {
              opExt.APIApplicationName = null;
            }

            if (this.model.get('APIProviderId'))
              opExt.APIProviderName = _.find(MOBSTEDAPP.APIProviders, (key) => {
                return key.id === this.model.get('APIProviderId');
              }).APIProviderName;

            this.operationView.model.set({
              id: response.id,
              operation: this.model.toJSON(),
              operationExt: opExt,
            });

            this.operationView.save(isNew);
            grit(lang('Save'), 'growl-success');
          } else {
            if (response.error[0] == 23505) {
              grit(lang('Name duplicate'), 'growl-danger');
            } else {
              grit(lang('Error save'), 'growl-danger');
            }
          }
        },
        error: (error, response) => {
          logger.exception(error, response);
          grit(lang('Error save'), 'growl-warning');
        },
        complete: (m, response) => {
          //console.log(response);
        },
      });
    } else {
      $('#OperationName').closest('.form-group').addClass('has-error');
      grit(lang('Name is undefined'), 'growl-danger');
    }
  },

  // Навешивает на селекты seletc2
  // ----------------------------------------------------
  selectTwo: function () {
    $('.b-trigger__settings .b-constructor__select').each(() => {
      const select = $(this).children('select');
      const options = {};

      if ($(this).attr('data-placeholder') && $(this).attr('data-placeholder') !== '') {
        options.placeholder = $(this).attr('data-placeholder');
      }

      switch (select.attr('data-type')) {
        case 'tags':
          options.tags = true;
          break;

        case 'nosearch':
          options.minimumResultsForSearch = -1;
          break;
      }

      if (!select.hasClass('select2-hidden-accessible')) {
        select.select2(options);
      }
    });
  },

  selectTwoBinding: function (select) {
    const options = {};

    switch (select.attr('data-type')) {
      case 'tags':
        options.tags = true;
        break;

      case 'nosearch':
        options.minimumResultsForSearch = -1;
        break;
    }

    if (!select.hasClass('select2-hidden-accessible')) {
      select.select2(options);
    }
  },

});
