<template>
  <div :class="b(false, 'i-frame i-frame-large clearfix')">
    <div class="i-frame-camera"></div>
    <div class="i-frame-sensor"></div>
    <div class="i-frame-earpiece"></div>
    <div class="i-frame-inner">
      <slot></slot>
    </div>
    <div class="i-frame-home"></div>
  </div>
</template>

<script>
  export default {
    name: 'smartphone-wrapper',
  };
</script>

<style lang="stylus">
  .smartphone-wrapper
    //
</style>
