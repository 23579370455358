export default {
  name: 'mbst-checkbox',
  aliasName: 'Checkbox',
  properties: {
    backendname: 'checkbox',
    label: 'Checkbox',
    labelPosition: 'right',
    selected: false,
    multiple: true,
    value: '',
    iscached: false,
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
    ],
    android: [
    ]
  },
}
