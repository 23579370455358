var  _ = require('underscore');

module.exports = {

    globalBoxInit: function(box) {

        var heightHeader = 0,
            heightFooter = 0;

        if (box.find('>.b-global-box__header')) {
            heightHeader = box.find('>.b-global-box__header').outerHeight();
        }
        if (box.find('>.b-global-box__footer')) {
            heightFooter = box.find('>.b-global-box__footer').outerHeight();
            box.find('>.b-global-box__footer').css("top", -heightFooter);
        }
        box.css("padding-bottom", (heightHeader + heightFooter) + 'px');

    },

    selectTwo: function() {

        var that = this;

        $('select.js-select2').each(function () {

            var $select = $(this),
                options = JSON.parse($select.attr('data-options'));

            if ($select.attr('name') == 'font-family') {
                options.templateResult = that.templateSelet2FontFamily;
            }
            if ($select.attr('id') == 'hashtags-group-backendname' || $select.attr('id') == 'hashtags-group-variable') {
                options.templateResult = that.templateSelet2WithScreenName;
            }

            if (!$select.hasClass('select2-hidden-accessible')) {
                $select.select2(options);
            }

        });

    },

    templateSelet2FontFamily: function(data, container) {
        if (!data.id) { return data.text; }
        var $state = $(
            '<span style="font-family: ' + data.text + ';">' + data.text + '</span>'
        );
        return $state;
    },

    templateSelet2WithScreenName: function(data, container) {
        if (!data.id) { return data.text; }
        var $state = $(
            '<div class="select2__item">'+
                '<span>' + data.text + '</span>'+
                '<span class="select2__screen"><b>' + lang("screen ") + '</b>' + $(data.element).attr("data-screen-name") + '</span>'+
            '</div>'
        );
        return $state;
    },

    getCurrentDate: function() {
        var d = new Date(),
            curr_day = ("0" + d.getDate()).slice(-2),
            curr_month = ("0" + (d.getMonth() + 1)).slice(-2),
            curr_year = d.getFullYear();
        return curr_year + "-" + curr_month + "-" + curr_day;
    },

}