var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.09 128a8 8 0 0 1-6.4-2.72 9 9 0 0 1-.13-12.67 1.59 1.59 0 0 1 .13-.13L112.5 2.69a9 9 0 0 1 12.68-.13l.13.13a9 9 0 0 1 .13 12.67 1.59 1.59 0 0 1-.13.13L15.5 125.27A8 8 0 0 1 9.09 128zm109.82 0a8 8 0 0 1-6.41-2.72L2.69 15.49a9 9 0 0 1-.13-12.67l.13-.13a9 9 0 0 1 12.68-.13 1.59 1.59 0 0 0 .13.13l109.81 109.78a9 9 0 0 1 .13 12.67l-.13.13a8 8 0 0 1-6.4 2.73z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }