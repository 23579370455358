import schema from 'general/components/widgetComponents/schemas/properties';

const SET_COMPONENT = 'SET_COMPONENT';
const RESET_COMPONENT = 'RESET_COMPONENT';

export default {
  namespaced: true,

  state: () => ({
    schema,
    component: null,
    lastComponentUuid: null,
  }),

  getters: {
    //
  },

  actions: {

    async setComponent({ commit }, component) {
      commit(SET_COMPONENT, component);
    },

    async resetComponent({ commit }) {
      commit(RESET_COMPONENT);
    },

  },

  mutations: {

    [SET_COMPONENT](state, component) {
      state.component = component;
      state.lastComponentUuid = component.uuid;
    },

    [RESET_COMPONENT](state) {
      state.component = null;
    },

  },
};
