'use strict';

// Libraries
var _ = require('underscore');
var $ = require('jquery');
var Backbone = require('backbone');

// Templates
var _triggerItem = require('text!bcTemplates/triggerItem.html');

// Helper
var PubSub = require('helper/pubsub');

module.exports = Backbone.View.extend({

    tagName: 'tr',

    events: {
        'click td>a': 'editTrigger',
        'click [data-js-toggle="editTrigger"]': 'editTrigger',
        'click [data-js-toggle="deleteTrigger"]': 'deleteTrigger',
        'click [data-js-toggle="changeActiveTrigger"]': 'changeActiveTrigger',
    },


    initialize: function(options) {
        this.options = options || {};
        this.tpl = _.template(_triggerItem);
    },

    render: function() {
        this.$el.html(this.tpl({
            trigger: this.model.toJSON(),
        }));

        this.stickit();
        return this.$el;
    },

    editTrigger: function(e) {
        e.preventDefault();
        PubSub.trigger('showTriggerSettings', this.model);
    },

    deleteTrigger: function(e) {
        e.preventDefault();

        var t = this, id = t.model.get('id');

        t.model.destroy({

            success: function(model, response, options) {
                if (response && _.isEmpty(response.error)) {
                    t.undelegateEvents();
                    t.$el.removeData().unbind();
                    t.remove();
                    MOBSTEDAPP.sfTriggers = _.without( MOBSTEDAPP.sfTriggers, _.findWhere(MOBSTEDAPP.sfTriggers, {id: id}) );
                }
            },
            error: function(model, xhr, options) {

            }

        });
    },

    changeActiveTrigger: function(e) {
        let active = $(e.target).is(':checked');
        this.model.set('Active', active);
        this.model.save();
    }

});