<template>
  <div :class="b({ isDisabled: disabled })">
    <label
      v-if="labelBefore"
      :class="b('label-before')"
      :for="vId"
      v-html="labelBefore"
    ></label>

    <div :class="b('container')">
      <textarea
        :id="vId"
        class="form-control"
        :disabled="disabled"
        @input="handlerInput"
        @change="handlerChange"
        v-html="localValue"
      ></textarea>
    </div>

    <label
      v-if="labelAfter"
      :class="b('label-after')"
      :for="vId"
      v-html="labelAfter"
    ></label>
  </div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'control-textarea',

    mixins: [
      baseMixin,
    ],

    model: {
      prop: 'value',
      event: 'update',
    },

    props: {
      value: {
        type: [String, Number],
      },
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Активация отслеживания на каждый ввод данных (иначе отслеживание только на потерю фокуса)
       */
      inputReactivity: {
        type: Boolean,
        default: false,
      },

      labelBefore: {
        type: String,
      },
      labelAfter: {
        type: String,
      },
    },

    data: () => ({
      localValue: null,
    }),

    watch: {
      value: {
        handler(data) {
          this.localValue = data;
        },
        immediate: true,
      },
    },

    methods: {
      handlerInput(event) {
        if (this.inputReactivity) {
          this.handlerUpdate(event);
        }
      },

      handlerChange(event) {
        this.handlerUpdate(event);
      },

      handlerUpdate(event) {
        const value = event.target.value;

        this.localValue = value;
        this.$emit('update', value);
      },
    },
  };

</script>

<style lang="stylus">
  @import '~@/general/stylus/_support.styl';

  .control-textarea
    &_is-disabled
      cursor not-allowed

    > label
      ~/_is-disabled&
        pointer-events none

    &__label-before
      margin-bottom 10px

    &__container
      > textarea
        min-height 100px
        resize vertical

        &:disabled
          pointer-events none

    &__label-after
      margin-left 10px
</style>
