<i18n src="general/lang/WidgetSettingsScenarios.json"></i18n>

<template>
  <div :class="b()">
    <div :class="b('list-title')">
      {{ $t('WidgetSettingsScenarios.title') }}
    </div>

    <WidgetSettingsScenariosHead v-if="elements.length" />

    <div
      v-if="elements.length > 3"
      :class="b('controls')"
    >
      <button
        :class="b('button-add')"
        class="btn btn-xs btn-success"
        @click.prevent="addItem"
      >
        <i class="fa fa-plus mr5"></i>
        <span>{{ $t('WidgetSettingsScenarios.buttonAdd') }}</span>
      </button>
    </div>

    <div
      v-if="elements.length"
      :class="b('list')"
    >
      <template v-for="element in elements">
        <WidgetSettingsScenariosItem
          v-if="element"
          :key="element.id"
          :class="b('item')"
          :debug="false"
          :model="element"
          :editing="element.isEditing || false"
        />
      </template>
    </div>

    <div
      v-if="!elements.length"
      :class="b('message-list-is-empty')"
    >
      {{ $t('WidgetSettingsScenarios.messageListIsEmpty') }}
    </div>

    <div :class="b('controls')">
      <button
        :class="b('button-add')"
        class="btn btn-xs btn-success"
        @click.prevent="addItem"
      >
        <i class="fa fa-plus mr5"></i>
        <span>{{ $t('WidgetSettingsScenarios.buttonAdd') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { baseMixin, noticeMixin } from 'general/mixins';
  import WidgetSettingsScenariosHead from './WidgetSettingsScenariosHead';
  import WidgetSettingsScenariosItem from './WidgetSettingsScenariosItem';

  const { mapActions, mapGetters } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-settings-scenarios',

    mixins: [
      baseMixin,
      noticeMixin,
    ],

    components: {
      WidgetSettingsScenariosHead,
      WidgetSettingsScenariosItem,
    },

    props: {
      widgetTypeId: {
        type: Number,
      },
    },

    computed: {
      ...mapGetters({
        allElements: 'widgetScenarios/getElements',
        getElementsByWidgetTypeId: 'widgetScenarios/getElementsByWidgetTypeId',
      }),

      elements() {
        return (this.widgetTypeId)
          ? this.getElementsByWidgetTypeId(this.widgetTypeId)
          : this.allElements;
      },
    },

    methods: {
      ...mapActions({
        add: 'widgetScenarios/add',
      }),

      addItem() {
        const added = this.add(this.widgetTypeId);

        if (added) {
          this.notice('Success to add new scenario', this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice('Failed to added new scenario', this.NOTICE_LEVEL_ERROR);
        }
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-settings-scenarios
    &__list-title
      margin-bottom 15px
      typography(16px)
      font-weight 700

    &__list
      margin-y(20px)

    &__message-list-is-empty
      margin-y(20px)
      text-align center

    &__item
      flex()
      margin-bottom 15px

    &__controls
      //

    &__button-add
      //
</style>
