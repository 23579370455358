<template>
  <div :class="b()" v-html="text"></div>
</template>

<script>
  import baseMixin from 'general/mixins/baseMixin';

  export default {
    name: 'w-text',

    mixins: [
      baseMixin,
    ],

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },

      text: {
        type: String,
        required: true,
      },

      widgetType: {
        type: Object,
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-text
    padding 10px 15px
    font-size 16px
    line-height 1.5em
</style>
