'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Models
var OperationItemModel = require('models/OperationsItemModel');


module.exports = Backbone.Collection.extend({

    url: '/triggers/operationsapi',

    model: OperationItemModel,

    initialize: function() {

    },

    parse: function(response) {
        return _.map(response, function(obj) {
            return {
                id: obj.id,
                operation: {
                    id: obj.id,
                    Name: obj.Name,
                    APIProviderId: obj.APIProviderId,
                    APIKeyId: obj.APIKeyId,
                    APIApplicationId: obj.APIApplicationId,
                    APIProviderMethodId: obj.APIProviderMethodId,
                    ParamsValues: _.isString(obj.ParamsValues) ? JSON.parse(obj.ParamsValues) : obj.ParamsValues,
                    DataTransformations: _.isString(obj.DataTransformations) ? JSON.parse(obj.DataTransformations) : obj.DataTransformations,
                    ConditionalDataTransformations: _.isString(obj.ConditionalDataTransformations) ? JSON.parse(obj.ConditionalDataTransformations) : obj.ConditionalDataTransformations,
                    LastModified: obj.LastModified,
                    ExtendedParams: _.isString(obj.ExtendedParams) ? JSON.parse(obj.ExtendedParams) : obj.ExtendedParams,
                },
                operationExt: {
                    APIKeyName: obj.APIKeyName,
                    APIProviderMethodName: obj.APIProviderMethodName,
                    APIProviderName: obj.APIProviderName,
                    uuid: obj.uuid
                }
            }
        });
    }

});