export default $axios => {
  const resource = '/lists';

  const methods = {
    /**
     * Create List
     * @param {Object} data - content type = x-www-form-urlencoded
     * @param {number} data.AppId
     * @param {string} data.name
     * @param {string} data.description
     * @param {Object} data.structure
     * @param {Array} data.structure.links
     * @param {Object[]} data.structure.tables
     * @param {string} data.structure.tables[].name
     * @param {Object[]} data.structure.tables[].fields
     * @param {string} data.structure.tables[].fields.name
     * @param {string} data.structure.tables[].fields.type
     * @param {Object} config axios config
     * @returns {Promise<any>}
     */
    create: (data, config = {}) => {
      const structure = JSON.stringify(data.structure);
      const query = new URLSearchParams({
        ...data, structure
      });
      return $axios.$post(resource, query, config);
    },

    /**
     * Get all the list of data tables in account
     * @param {Object} config axios config
     * @param {Object} config.params
     * @param {number} config.params.page
     * @param {number} config.params.pageSize
     * @param {number} config.params.appId
     * @returns {Promise<any>}
     */
    get: (config = {}) => $axios.$get(resource, config),

    /**
     * Get all entries in the list data table
     * @param {Object} config axios config
     * @param {Object} config.params
     * @param {number} config.params.page
     * @param {number} config.params.pageSize
     * @param {number} config.params.listId
     * @param {string} config.params.tableName
     * @returns {*}
     */
    getEntriesLDT: (config = {}) => $axios.$get('/list', config),

    remove: (listId, config = {}) => $axios.$delete(`${resource}/${listId}`, config),

    /**
     * Working with list data tables
     */
    table: {

      /**
       * Adding a data table to the list
       * @param {number} listId
       * @param {Object} data - content type = x-www-form-urlencoded
       * @param {string} data.name - Table name
       * @param {Object[]} data.fields
       * @param {string} data.fields[].name - Column name
       * @param {string} data.fields[].type - Column type
       * @param {Object} config axios config
       * @returns {*}
       */
      create: (listId, data, config = {}) => {
        data = new URLSearchParams({
          json: JSON.stringify([data])
        });
        return $axios.$post(`${resource}/${listId}/newtable`, data, config);
      },

      /**
       * Rename data table
       * @param {number} listId
       * @param {Object} data
       * @param {string} data.tableName - Old Table Name
       * @param {string} data.newTableName - New Table Name
       * @param {Object} config axios config
       * @returns {*}
       */
      rename: (listId, data, config = {}) => {
        config.params = {
          ...config.params,
          ...data
        };
        return $axios.$put(`${resource}/${listId}/tablename`, null, config);
      },

      /**
       * Delete data table
       * @param {number} listId
       * @param {Object} data
       * @param {string} data.tableName
       * @param {number} data.hard - Delete with records
       * @param {Object} config axios config
       * @returns {*}
       */
      remove: (listId, data, config = {}) => {
        config.params = {
          hard: 1,
          ...config.params,
          ...data
        };
        return $axios.$delete(`${resource}/${listId}/table`, config);
      }

    },

    /**
     * Working with list data table columns
     */
    column: {

      /**
       * Adding columns to the data table
       * @param {number} listId
       * @param {Object} data - content type = x-www-form-urlencoded
       * @param {string} data.tableName - Table name
       * @param {Object[]} data.fields
       * @param {string} data.fields[].name - Column name
       * @param {string} data.fields[].type - Column type
       * @param {Object} config axios config
       * @returns {*}
       */
      create: (listId, data, config = {}) => {
        data = new URLSearchParams({
          tableName: data.tableName,
          json: JSON.stringify(data.fields)
        });
        return $axios.$post(`${resource}/${listId}/newcolumn`, data, config);
      },

      /**
       * Change column type
       * @param {number} listId - ID List
       * @param {Object} data
       * @param {string} data.tableName - Table Name
       * @param {string} data.columnName - Column Name
       * @param {string} data.newColumnType - New Column Type
       * @param {Object} config axios config
       * @returns {*}
       */
      changeType: (listId, data, config = {}) => {
        config.params = {
          ...config.params,
          ...data
        };
        return $axios.$put(`${resource}/${listId}/columntype`, null, config);
      },

      /**
       * Rename column
       * @param {number} listId
       * @param {Object} data
       * @param {string} data.tableName
       * @param {string} data.columnName - Old Column Name
       * @param {string} data.newColumnName - New Column Name
       * @param {Object} config axios config
       * @returns {*}
       */
      rename: (listId, data, config = {}) => {
        config.params = {
          ...config.params,
          ...data
        };
        return $axios.$put(`${resource}/${listId}/columnname`, null, config);
      },

      /**
       * Delete column
       * @param {number} listId
       * @param {Object} data
       * @param {string} data.tableName
       * @param {string} data.columnName
       * @param {Object} config axios config
       * @returns {*}
       */
      remove: (listId, data, config = {}) => {
        config.params = {
          ...config.params,
          ...data
        };
        return $axios.$delete(`${resource}/${listId}/column`, config);
      }
    },

    /**
     * Working with list data table records
     */
    record: {

      /**
       * Adding record to the list data table
       * @param {Object} data - content type = x-www-form-urlencoded
       * @param {string} data.listId
       * @param {string} data.tableName - Table name
       * @param {Object} data.json
       * @param {Object} config axios config
       * @returns {*}
       */
      create: (data, config = {}) => {
        data.json = JSON.stringify(data.json);
        data = new URLSearchParams(data);
        return $axios.$post('/list', data, config);
      },

      /**
       * Update record of the list data table
       * @param {number} recordId
       * @param {Object} data
       * @param {number} data.listId
       * @param {string} data.tableName
       * @param {Object} data.json
       * @param config
       * @returns {*}
       */
      update: (recordId, data, config = {}) => {
        config.params = {
          ...config.params,
          ...data
        };
        return $axios.$put(`/list/${recordId}`, null, config);
      },

      /**
       * Delete record
       * @param {number} recordId
       * @param {Object} data
       * @param {string} data.listId
       * @param {string} data.tableName
       * @param {Object} config axios config
       * @returns {*}
       */
      remove: (recordId, data, config = {}) => {
        config.params = {
          ...config.params,
          ...data
        };
        return $axios.$delete(`/list/${recordId}`, config);
      }
    }

  };

  return methods;
}
