import { backgroundDefault } from './helper';

export default {
  name: 'mbst-tabs__tab',
  aliasName: 'TabPanel',
  properties: {
    backendname: 'tab',
    label: 'Tab',
    icon: ''
  },
  css: {
    ios: [
      {
        selector: '.mbst-tabs__tab',
        rules: [
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-tabs__tab',
        rules: [
          { directive: 'border-width', value: '0' },
          ...backgroundDefault,
        ]
      }
    ]
  },
  components: []
}
