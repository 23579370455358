import axios from 'axios';
import logger from 'general/logger';
import { replaceSnippets } from 'general/utils/utils';
import { BASE_URL } from 'general/env';
import MakeAuth from './MakeAuth';

const lang = (key) => {
  try {
    return window.lang(key);
  } catch (error) {
    return key;
  }
};

/**
 * Фабрика http-сервиса
 * Преднастроенный экземпляр axios, здесь уже есть авторизационный токен,
 * обработка ошибки 401 (с обновлением токена и повторным выполнением запроса),
 * обработка ошибки 403 (с очисткой кук и редиректом на логин).
 * Default BaseURL - /api/v8
 *
 * TODO: реализовать методы head|options|patch при необходимости
 *
 * @param {Object} httpConfig
 * @returns {Object}
 * @constructor
 */
const factoryHttp = (httpConfig = {}) => {
  const _config = {
    tenant: '',
    server: '',
    baseURL: '/api/v8',
    applicationId: null,
    objectId: null,
    typeService: 'bc', // bc; widgets
    ...httpConfig,
  };
  let host = '';

  if (_config.tenant && _config.server) {
    host = replaceSnippets(BASE_URL, {
      TENANT: _config.tenant,
      SERVER: _config.server,
    });
  }

  const baseURL = `${host}${_config.baseURL}`;
  const http = axios.create({
    baseURL,
  });
  const auth = MakeAuth({
    tenant: _config.tenant,
    server: _config.server,
    applicationId: _config.applicationId,
    objectId: _config.objectId,
    typeService: _config.typeService,
  });

  /**
   * Установка авторизационного заголовка
   */
  http.interceptors.request.use(
    (config) => {
      config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
      const accessToken = auth.getAccessToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      /*
      // сейчас логин реализован стандартной отправкой формы
      // оставлю на будущее
      const url = config.url.split('/');
      if (url[url.length - 1] === 'login') {
        delete config.headers.Authorization;
      }
      */

      return config;
    },
    (error) => Promise.reject(error),
  );

  /**
   * Обработка ошибок запросов, в частности проверка на протухший токен
   * и дальнейшая попытка его обновить и выполнить изначальный запрос/запросы
   */
  http.interceptors.response.use(undefined, async (error) => {
    const { typeService } = _config;
    const { config, response: { status, data } } = error;
    const originalRequest = config;
    const { code: _code } = auth.getErrorData(data);
    const code = (_code) ? parseInt(_code, 10) : null;
    const errorsForLogout = [
      1003, // Authorization header not found
    ];

    if (typeService == 'bc') {
      // Force logout
      if (code && errorsForLogout.includes(code) || status === 403) {
        auth.alertLogout(lang('Access is denied. Please, authorize again.'));
      }
    }

    // If Access Token expired
    if (status === 401 && !originalRequest.__isRetryRequest) {
      if (auth.isRememberMe() && typeService == 'bc' || typeService !== 'bc') {
        try {
          const { data: res } = await auth.refreshToken();
          const baseURL = originalRequest.baseURL;

          let data = {};
          if (res.data) {
            data.access_token = res.data.attributes.accessToken;
            data.refresh_token = res.data.attributes.refreshToken;
          } else {
            data.access_token = res.access_token;
            data.refresh_token = res.refresh_token;
          }

          auth.setAuthData(data);
          originalRequest.__isRetryRequest = true;
          originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
          originalRequest.baseURL = originalRequest.url.search(baseURL) !== -1 ? null : baseURL;

          return axios(originalRequest);
        } catch (error) {
          if (typeService == 'bc') {
            const refreshErrorData = auth.getErrorData(error.response.data);
            auth.alertLogout(refreshErrorData.detail);
          } else {
            const tokens = await auth.authorize();
            originalRequest.headers.Authorization = `Bearer ${tokens.access_token}`;
            originalRequest.baseURL = originalRequest.url.search(baseURL) !== -1 ? null : baseURL;

            return axios(originalRequest);
          }
        }
      } else {
        auth.alertLogout(lang('Access is denied. Please, authorize again.'));
      }
    }

    if (status === 403) {
      if (typeService !== 'bc') {
        const tokens = await auth.authorize()
        originalRequest.headers.Authorization = `Bearer ${tokens.access_token}`
        originalRequest.baseURL = originalRequest.url.search(baseURL) !== -1 ? null : baseURL

        return axios(originalRequest)
      } else {
        auth.alertLogout(lang('Access is denied. Please, authorize again.'));
      }
    }

    return Promise.reject(error);
  });

  /**
   * Методы для работы с http с обработкой ошибок и ответа
   */
  return {

    /**
     * Обертка произвольного запроса
     * Чистый запрос без обработчиков
     *
     * @param {Object} config
     * @returns {Promise<*>}
     */
    async request(config) {
      try {
        const response = await http.request(config);
        const { status, errors, data } = response;

        if (errors) {
          logger.error(errors);
          const errObject = new Error('There is an error in the response from the service');
          throw Object.assign(errObject, errors);
        }

        if (!data) {
          logger.error('Property "data" is not found!');
          throw new Error('Property "data" is not found!');
        }

        // if (!data.data) {
        //   logger.error('Property "data.data" is not found!');
        //   return false;
        // }
        // TODO: поверять, когда все методы API v8 будут это возвращать
        // if (!data.meta) {
        //   logger.error('Property "data.meta" is not found!');
        //   return false;
        // }

        this.dataPrepare(data.data);

        return Promise.resolve(response);
        // return Promise.resolve(data);

      } catch (error) {
        logger.exception(error);
        return Promise.reject(error.response.data || error);
      }
    },

    /**
     * ...
     *
     * @param {string} url
     * @param {Object} params - query параметры
     * @param {Object} config
     * @returns {Promise<Object>}
     */
    async get(url, params = null, config = {}) {
      //try {
        const requestConfig = {
          ...config,
          ...{
            method: 'get',
            url,
            params,
          },
        };
        const { data } = await this.request(requestConfig);
        return data;
      //   const response = await http.request(requestConfig);
      //   const { status, errors, data } = response;
      //
      //   if (status !== 200) {
      //     logger.error(`Server status code: ${status}`);
      //     return false;
      //   }
      //   if (errors) {
      //     logger.error(errors);
      //     return false;
      //   }
      //   if (!data) {
      //     logger.error('Property "data" is not found!');
      //     return false;
      //   }
      //   // if (!data.data) {
      //   //   logger.error('Property "data.data" is not found!');
      //   //   return false;
      //   // }
      //
      //   this.dataPrepare(data.data);
      //
      //   return data;
      // } catch (error) {
      //   logger.exception(error);
      //   return false;
      // }
    },

    /**
     * ...
     *
     * @param {string} url
     * @param {Object} params
     * @param {Object} config
     * @returns {Promise<Object>}
     */
    async post(url, params, config = {}) {
      //try {
        const requestConfig = {
          ...config,
          ...{
            method: 'post',
            url,
            data: params,
          },
        };
        const { data } = await this.request(requestConfig);
        return data;
      //   const response = await http.request(requestConfig);
      //   const { status, errors, data } = response;
      //
      //   if (status !== 200) {
      //     logger.error(`Server status code: ${status}`);
      //     return false;
      //   }
      //   if (errors) {
      //     logger.error(errors);
      //     return false;
      //   }
      //   if (!data) {
      //     logger.error('Property "data" is not found!');
      //     return false;
      //   }
      //   // if (!data.data) {
      //   //   logger.error('Property "data.data" is not found!');
      //   //   return false;
      //   // }
      //   // TODO: поверять, когда все методы API v8 будут это возвращать
      //   // if (!data.meta) {
      //   //   logger.error('Property "data.meta" is not found!');
      //   //   return false;
      //   // }
      //
      //   this.dataPrepare(data.data);
      //
      //   return data;
      // } catch (error) {
      //   logger.exception(error);
      //   return false;
      // }
    },

    /**
     * ...
     *
     * @param {string} url
     * @param {Object} params
     * @param {Object} config
     * @returns {Promise<Object>}
     */
    async put(url, params, config = {}) {
      //try {
        const requestConfig = {
          ...config,
          ...{
            method: 'put',
            url,
            data: params,
          },
        };
        const { data } = await this.request(requestConfig);
        return data;
        // //const response = await http.request(requestConfig);
        // const response = await this.request(requestConfig);
        // const { status, errors, data } = response;
        //
        // if (status !== 200) {
        //   logger.error(`Server status code: ${status}`);
        //   return false;
        // }
        // if (errors) {
        //   logger.error(errors);
        //   return false;
        // }
        // if (!data) {
        //   logger.error('Property "data" is not found!');
        //   return false;
        // }
        //
        // // if (!data.data) {
        // //   logger.error('Property "data.data" is not found!');
        // //   return false;
        // // }
        // // TODO: поверять, когда все методы API v8 будут это возвращать
        // // if (!data.meta) {
        // //   logger.error('Property "data.meta" is not found!');
        // //   return false;
        // // }
        //
        // this.dataPrepare(data.data);
        //
        // return data;

      // } catch (error) {
      //   logger.exception(error);
      //
      //   debugger
      //   let errorObject = new Error('There is an error in the response from the service');
      //   throw Object.assign(errorObject, (error.response.data || error.response));
      //
      //   return false;
      // }
    },

    /**
     * ...
     *
     * @param {string} url
     * @param {Object} config
     * @returns {Promise<Object>}
     */
    async delete(url, config = {}) {
      // try {
        const requestConfig = {
          ...config,
          ...{
            method: 'delete',
            url,
          },
        };
        const { data } = await this.request(requestConfig);
        return data;
      //   const response = await http.request(requestConfig);
      //   const { status, errors, data } = response;
      //
      //   if (status !== 200) {
      //     logger.error(`Server status code: ${status}`);
      //     return false;
      //   }
      //   if (errors) {
      //     logger.error(errors);
      //     return false;
      //   }
      //   if (!data) {
      //     logger.error('Property "data" is not found!');
      //     return false;
      //   }
      //   // if (!data.meta) {
      //   //   logger.error('Property "data.meta" is not found!');
      //   //   return false;
      //   // }
      //
      //   return data;
      // } catch (error) {
      //   logger.exception(error);
      //   return false;
      // }
    },

    /**
     * Предобработка ответа БД
     *
     * @param {*} data
     * @returns {*}
     */
    dataPrepare(data) {
      // конвертация строковых id в числовые
      if (data instanceof Array) {
        data.forEach(this.convertIdToNumber);
      } else if (data !== null && data instanceof Object) {
        this.convertIdToNumber(data);
      }

      return data;
    },

    /**
     * @param {Object} element
     * @returns {Object}
     */
    convertIdToNumber(element) {
      if (element.id) {
        element.id = parseInt(element.id, 10);
      }

      return element;
    },

  };
};

export default factoryHttp;
