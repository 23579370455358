import { backgroundDefault } from './helper';
import flexCol from './flexRowCol';
import flexRow from './flexRow';
import Text from './text';
import Button from './button';
import Image from './image';


// ** Left menu ** //
const leftmenu_i1 = _.cloneDeep(flexCol);
leftmenu_i1.css.all = [{ selector: '.mbst-row__col', rules: { 'min-height': '54px' } }]
leftmenu_i1.properties.colwidth = ""

const leftmenu_i2_btn = _.cloneDeep(Button);
Object.assign(leftmenu_i2_btn.properties, { iconCls: 'far fa-th-large', typeButton: 'flat' })
leftmenu_i2_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

const leftmenu_i2 = _.cloneDeep(flexCol);
leftmenu_i2.properties.colwidth = "auto"
leftmenu_i2.components = [leftmenu_i2_btn]

const leftmenu_i3_btn = _.cloneDeep(Button);
Object.assign(leftmenu_i3_btn.properties, { iconCls: 'far fa-credit-card-blank', typeButton: 'flat' })
leftmenu_i3_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

const leftmenu_i3 = _.cloneDeep(flexCol);
leftmenu_i3.properties.colwidth = "auto"
leftmenu_i3.components = [leftmenu_i3_btn]

const leftmenu_i4_btn = _.cloneDeep(Button);
Object.assign(leftmenu_i4_btn.properties, { iconCls: 'far fa-chart-pie', typeButton: 'flat' })
leftmenu_i4_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

const leftmenu_i4 = _.cloneDeep(flexCol);
leftmenu_i4.properties.colwidth = "auto"
leftmenu_i4.components = [leftmenu_i4_btn]

const leftmenu_i5_btn = _.cloneDeep(Button);
Object.assign(leftmenu_i5_btn.properties, { iconCls: 'fas fa-dollar-sign', typeButton: 'flat' })
leftmenu_i5_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

const leftmenu_i5 = _.cloneDeep(flexCol);
leftmenu_i5.properties.colwidth = "auto"
leftmenu_i5.components = [leftmenu_i5_btn]

const leftmenu_i6_btn = _.cloneDeep(Button);
Object.assign(leftmenu_i6_btn.properties, { iconCls: 'fas fa-signal-alt-3', typeButton: 'flat' })
leftmenu_i6_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

const leftmenu_i6 = _.cloneDeep(flexCol);
leftmenu_i6.properties.colwidth = "auto"
leftmenu_i6.components = [leftmenu_i6_btn]

const leftmenu_i7_btn = _.cloneDeep(Button);
Object.assign(leftmenu_i7_btn.properties, { iconCls: 'fal fa-list-alt', typeButton: 'flat' })
leftmenu_i7_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

const leftmenu_i7 = _.cloneDeep(flexCol);
leftmenu_i7.properties.colwidth = "auto"
leftmenu_i7.components = [leftmenu_i7_btn]

const leftmenu_i8 = _.cloneDeep(flexCol);
leftmenu_i8.properties.colwidth = ""

const leftmenu_row = _.cloneDeep(flexRow);
Object.assign(leftmenu_row.properties, { direction: 'column', outerspacing: 'q-gutter-lg' })
leftmenu_row.items = [leftmenu_i1, leftmenu_i2, leftmenu_i3, leftmenu_i4, leftmenu_i5,leftmenu_i6, leftmenu_i7, leftmenu_i8]

const cell_1 = _.cloneDeep(flexCol);
cell_1.properties.colwidth = ""
cell_1.properties.customClasses = "col-xs-0 col-lg-2"
cell_1.css.all = [{ selector: '.mbst-row__col', rules: { 'background-color': '#C90303' } }]
cell_1.components = [leftmenu_row]

// ** /Left menu ** //


// ** Main center ** //

  // ** header content row **
  const head_cell1_btn = _.cloneDeep(Button);
  Object.assign(head_cell1_btn.properties, { iconCls: 'fas fa-bars', roundButton: 'round', text: '', typeButton: 'flat' })
  head_cell1_btn.css.all = [
    { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)', 'border-radius': '' } },
    { selector: '.row', rules: { width: '52px' } },
    { selector: '.mbst-button-container .q-btn', rules: { color: '#888888' } }
  ]
  head_cell1_btn.actions = [
    {
      type: 'setProperty',
      options: {
        componentName: 'Screen',
        componentUUID: '',
        propertyName: 'leftpanel',
        propertyValue: true
      }
    }
  ]

  const head_cell1 = _.cloneDeep(flexCol);
  head_cell1.properties.colwidth = "auto"
  head_cell1.components = [head_cell1_btn]
  // ---

  const head_text = _.cloneDeep(Text);
  head_text.properties.text = "My Site Name"

  const head_cell2 = _.cloneDeep(flexCol);
  head_cell2.properties.colwidth = ""
  head_cell2.components = [head_text]
  // ---

  const img_avatar = _.cloneDeep(Image);
  img_avatar.properties.image.url = 'https://cdn.iconscout.com/icon/free/png-512/batman-1417681-1197293.png'
  img_avatar.css.all = [
    { selector: '.mbst-image', rules: { height: '40px', width: '40px' }}
  ]

  const head_cell3 = _.cloneDeep(flexCol);
  head_cell3.properties.colwidth = "auto"
  head_cell3.css.all = [
    { selector: '.mbst-row__col', rules: { margin: 'auto 0', padding: '5px' }}
  ]
  head_cell3.components = [img_avatar]
  // ---

  const text_avatar = _.cloneDeep(Text);
  text_avatar.properties.text = "Admin"

  const head_cell4 = _.cloneDeep(flexCol);
  head_cell4.properties.colwidth = "auto"
  head_cell4.css.all = [
    { selector: '.mbst-row__col', rules: { margin: 'auto 0', padding: '5px' }}
  ]
  head_cell4.components = [text_avatar]
  // ---

  const center_row_head_content = _.cloneDeep(flexRow);
  center_row_head_content.properties.shadow = '1'
  center_row_head_content.properties.valign = 'items-center'
  center_row_head_content.css = { all: [{ selector: '.mbst-row', rules: { height: '54px', 'min-height': '54px', width: '100%' }}, { selector: '.mbst-row > .mbs-row__content', rules: { 'background-color': '#ffffff' }}]}
  center_row_head_content.items = [head_cell1, head_cell2, head_cell3, head_cell4]
  // ** /header content row **


const center_row_head = _.cloneDeep(flexCol);
center_row_head.properties.colwidth = "auto"
center_row_head.components = [center_row_head_content]


  // ** menu content row **
  const cntrmenu_i1 = _.cloneDeep(flexCol);
  cntrmenu_i1.properties.colwidth = ""
  // ---

  const cntrmenu_i2_btn = _.cloneDeep(Button);
  Object.assign(cntrmenu_i2_btn.properties, { iconCls: 'far fa-th-large', typeButton: 'flat', iconStack: true })
  cntrmenu_i2_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

  const cntrmenu_i2 = _.cloneDeep(flexCol);
  cntrmenu_i2.properties.colwidth = "auto"
  cntrmenu_i2.components = [cntrmenu_i2_btn]
  // ---

  const cntrmenu_i3_btn = _.cloneDeep(Button);
  Object.assign(cntrmenu_i3_btn.properties, { iconCls: 'far fa-credit-card-blank', typeButton: 'flat', iconStack: true })
  cntrmenu_i3_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

  const cntrmenu_i3 = _.cloneDeep(flexCol);
  cntrmenu_i3.properties.colwidth = "auto"
  cntrmenu_i3.components = [cntrmenu_i3_btn]
  // ---

  const cntrmenu_i4_btn = _.cloneDeep(Button);
  Object.assign(cntrmenu_i4_btn.properties, { iconCls: 'far fa-chart-pie', typeButton: 'flat', iconStack: true })
  cntrmenu_i4_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

  const cntrmenu_i4 = _.cloneDeep(flexCol);
  cntrmenu_i4.properties.colwidth = "auto"
  cntrmenu_i4.components = [cntrmenu_i4_btn]
  // ---

  const cntrmenu_i5_btn = _.cloneDeep(Button);
  Object.assign(cntrmenu_i5_btn.properties, { iconCls: 'fas fa-dollar-sign', typeButton: 'flat', iconStack: true })
  cntrmenu_i5_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

  const cntrmenu_i5 = _.cloneDeep(flexCol);
  cntrmenu_i5.properties.colwidth = "auto"
  cntrmenu_i5.components = [cntrmenu_i5_btn]
  // ---

  const cntrmenu_i6_btn = _.cloneDeep(Button);
  Object.assign(cntrmenu_i6_btn.properties, { iconCls: 'fas fa-signal-alt-3', typeButton: 'flat', iconStack: true })
  cntrmenu_i6_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

  const cntrmenu_i6 = _.cloneDeep(flexCol);
  cntrmenu_i6.properties.colwidth = "auto"
  cntrmenu_i6.components = [cntrmenu_i6_btn]
  // ---

  const cntrmenu_i7_btn = _.cloneDeep(Button);
  Object.assign(cntrmenu_i7_btn.properties, { iconCls: 'fal fa-list-alt', typeButton: 'flat', iconStack: true })
  cntrmenu_i7_btn.css.all = [{ selector: ',.mbst-button-container', rules: { 'min-width': '150px' } }, { selector: '.mbst-button-container .q-btn', rules: { 'text-transform': 'capitalize' } }, { selector: '.mbst-button', rules: { 'background-color': 'rgba(0,0,0,0)' } }]

  const cntrmenu_i7 = _.cloneDeep(flexCol);
  cntrmenu_i7.properties.colwidth = "auto"
  cntrmenu_i7.components = [cntrmenu_i7_btn]
  // ---

  const cntrmenu_i8 = _.cloneDeep(flexCol);
  cntrmenu_i8.properties.colwidth = ""
  // ---




  const center_menu = _.cloneDeep(flexRow);
  center_menu.properties.wrap ='no-wrap'
  center_menu.properties.navigationControls = true
  center_menu.css = {
    all: [
      { selector: '.mbst-row', rules: { display: 'none', width: '100%' }},
      { selector: '.mbst-row > .mbs-row__content', rules: { 'background-color': '#E02121' }}
    ],
    '.media-sm': [
      { selector: '.mbst-row', rules: { display: 'block' }},
    ]
  }
  center_menu.items = [cntrmenu_i1,cntrmenu_i2,cntrmenu_i3,cntrmenu_i4,cntrmenu_i5,cntrmenu_i6,cntrmenu_i7,cntrmenu_i8]
  // ** /menu content row **

const center_row_menu = _.cloneDeep(flexCol);
center_row_menu.properties.colwidth = "auto"
center_row_menu.properties.customClasses = "col-lg-0"
center_row_menu.css.all = [{ selector: '.mbst-row__col', rules: { width: '100%' } }]
center_row_menu.components = [center_menu]

// ** Content main row ** //
  const dash1_text = _.cloneDeep(Text);
  dash1_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash1_text.css = {
    all: [{ selector: '.mbst-text', rules: {
      'border-bottom-color': '#B60303',
      'border-bottom-style': 'solid',
      'border-bottom-width': '1px',
      'border-left-color': '#B60303',
      'border-left-style': 'solid',
      'border-left-width': '1px',
      'border-right-color': '#B60303',
      'border-right-style': 'solid',
      'border-right-width': '1px',
      'border-top-color': '#B60303',
      'border-top-style': 'solid',
      'border-top-width': '1px',
    }}]
  }

  const dash2_1_text =  _.cloneDeep(Text);
  dash2_1_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash2_1_text.css.all =[{ selector: '.mbst-text', rules: {
    'border-bottom-color': '#F8D347',
    'border-bottom-style': 'dashed',
    'border-bottom-width': '1px',
    'border-left-color': '#F8D347',
    'border-left-style': 'dashed',
    'border-left-width': '1px',
    'border-right-color': '#F8D347',
    'border-right-style': 'dashed',
    'border-right-width': '1px',
    'border-top-color': '#F8D347',
    'border-top-style': 'dashed',
    'border-top-width': '1px',
  }}]
  const dash2_1 = _.cloneDeep(flexCol);
  dash2_1.properties.colwidth = "12"
  dash2_1.properties.customClasses = "col-md-4"
  dash2_1.components =[dash2_1_text]

  const dash2_2_text =  _.cloneDeep(Text);
  dash2_2_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash2_2_text.css.all =[{ selector: '.mbst-text', rules: {
    'border-bottom-color': '#9EFA23',
    'border-bottom-style': 'dashed',
    'border-bottom-width': '1px',
    'border-left-color': '#9EFA23',
    'border-left-style': 'dashed',
    'border-left-width': '1px',
    'border-right-color': '#9EFA23',
    'border-right-style': 'dashed',
    'border-right-width': '1px',
    'border-top-color': '#9EFA23',
    'border-top-style': 'dashed',
    'border-top-width': '1px',
  }}]
  const dash2_2 = _.cloneDeep(flexCol);
  dash2_2.properties.colwidth = "12"
  dash2_2.properties.customClasses = "col-md-4"
  dash2_2.components =[dash2_2_text]

  const dash2_3_text =  _.cloneDeep(Text);
  dash2_3_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash2_3_text.css.all =[{ selector: '.mbst-text', rules: {
    'border-bottom-color': '#78FAD0',
    'border-bottom-style': 'dashed',
    'border-bottom-width': '1px',
    'border-left-color': '#78FAD0',
    'border-left-style': 'dashed',
    'border-left-width': '1px',
    'border-right-color': '#78FAD0',
    'border-right-style': 'dashed',
    'border-right-width': '1px',
    'border-top-color': '#78FAD0',
    'border-top-style': 'dashed',
    'border-top-width': '1px',
  }}]
  const dash2_3 = _.cloneDeep(flexCol);
  dash2_3.properties.colwidth = "12"
  dash2_3.properties.customClasses = "col-md-4"
  dash2_3.components =[dash2_3_text]

  const dash2_4_text =  _.cloneDeep(Text);
  dash2_4_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash2_4_text.css.all =[{ selector: '.mbst-text', rules: {
    'border-bottom-color': '#9EFA23',
    'border-bottom-style': 'dashed',
    'border-bottom-width': '1px',
    'border-left-color': '#9EFA23',
    'border-left-style': 'dashed',
    'border-left-width': '1px',
    'border-right-color': '#9EFA23',
    'border-right-style': 'dashed',
    'border-right-width': '1px',
    'border-top-color': '#9EFA23',
    'border-top-style': 'dashed',
    'border-top-width': '1px',
  }}]
  const dash2_4 = _.cloneDeep(flexCol);
  dash2_4.properties.colwidth = "12"
  dash2_4.properties.customClasses = "col-md-4"
  dash2_4.components =[dash2_4_text]

  const dash2_5_text =  _.cloneDeep(Text);
  dash2_5_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash2_5_text.css.all =[{ selector: '.mbst-text', rules: {
    'border-bottom-color': '#FC0DC0',
    'border-bottom-style': 'dashed',
    'border-bottom-width': '1px',
    'border-left-color': '#FC0DC0',
    'border-left-style': 'dashed',
    'border-left-width': '1px',
    'border-right-color': '#FC0DC0',
    'border-right-style': 'dashed',
    'border-right-width': '1px',
    'border-top-color': '#FC0DC0',
    'border-top-style': 'dashed',
    'border-top-width': '1px',
  }}]
  const dash2_5 = _.cloneDeep(flexCol);
  dash2_5.properties.colwidth = "12"
  dash2_5.properties.customClasses = "col-md-4"
  dash2_5.components =[dash2_5_text]

  const dash2_6_text =  _.cloneDeep(Text);
  dash2_6_text.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
  dash2_6_text.css.all =[{ selector: '.mbst-text', rules: {
    'border-bottom-color': '#23B5FA',
    'border-bottom-style': 'dashed',
    'border-bottom-width': '1px',
    'border-left-color': '#23B5FA',
    'border-left-style': 'dashed',
    'border-left-width': '1px',
    'border-right-color': '#23B5FA',
    'border-right-style': 'dashed',
    'border-right-width': '1px',
    'border-top-color': '#23B5FA',
    'border-top-style': 'dashed',
    'border-top-width': '1px',
  }}]
  const dash2_6 = _.cloneDeep(flexCol);
  dash2_6.properties.colwidth = "12"
  dash2_6.properties.customClasses = "col-md-4"
  dash2_6.components =[dash2_6_text]


  const dash1_row = _.cloneDeep(flexRow);
  dash1_row.properties.innerspacing = 'q-col-gutter-md'
  dash1_row.properties.wrap = 'wrap'
  dash1_row.css.all = [{ selector: '.mbst-row', rules: { margin: '10px 0 0 0'}}]
  dash1_row.items = [dash2_1,dash2_2,dash2_3,dash2_4,dash2_5,dash2_6]



  const cont_col_left = _.cloneDeep(flexCol);
  cont_col_left.properties.colwidth = '12'
  cont_col_left.properties.customClasses = 'col-sm-7'
  cont_col_left.components = [dash1_text, dash1_row]


  const cont_col_right_img = _.cloneDeep(Image);
  cont_col_right_img.css.all = [
    { selector: '.mbst-image', rules: { width: '100%' }}
  ]
  cont_col_right_img.properties.image.url = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOYH2Hi48r6ZSssmptsdRxg5ui5U63pj_GKg&usqp=CAU'

  const cont_col_right = _.cloneDeep(flexCol);
  cont_col_right.properties.colwidth = '12'
  cont_col_right.properties.customClasses = 'col-sm-4'
  cont_col_right.components = [cont_col_right_img]

  const content_row = _.cloneDeep(flexRow);
  Object.assign(content_row.properties, { innerspacing: 'q-col-gutter-sm', justify: 'justify-around', wrap: 'wrap' })
  content_row.items = [cont_col_left, cont_col_right]
  // ** /Content main row ** //

const center_row_main = _.cloneDeep(flexCol);
center_row_main.properties.colwidth = ""
center_row_main.css.all = [{ selector: '.mbst-row__col', rules: { padding: '10px 0 0 0' }}]
center_row_main.components = [content_row]


const center_row = _.cloneDeep(flexRow);
Object.assign(center_row.properties, { direction: 'column' })
center_row.css = {
  all: [{ selector: '.mbst-row', rules: { height: '100%' } }],
  '.media-sm': [{ selector: '.mbst-row', rules: { display: 'visible' } }]
}
center_row.items = [center_row_head, center_row_menu, center_row_main]

const cell_2 = _.cloneDeep(flexCol);
cell_2.properties.colwidth = ""
cell_2.components = [center_row]
// ** /Main center ** //

export default {
  name: 'mbst-flexrow',
  aliasName: 'Dashboard',
  properties: {
    backendname: 'flexrow',
    direction: 'row',
    wrap: 'wrap',
    justify: '',
    valign: '',
    outerspacing: '',
    innerspacing: '',
    shadow: '',
    shadowPosition: '',
    navigationControls: false,
    toolbar: '',
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  meta: {
    outlineCells: true,
  },
  actions: null,
  css: {
    all: [
      {
        selector: '.mbst-row',
        rules: {
          'height': '100%',
          'min-height': '100%'
        }
      }
    ],
    // '.media-lg':[
    //   {
    //     selector: '.mbst-row',
    //     rules: {
    //       'max-width': '1200px',
    //       'margin-left': 'auto',
    //       'margin-right': 'auto'
    //     }
    //   }
    // ]
  },
  items: [
    cell_1,
    cell_2,
  ]
}
