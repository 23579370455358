<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    role="presentation"
    :class="b(false)"
    :viewBox="viewBox"
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    :fill="iconColor"
  >
    <slot></slot>
  </svg>
</template>

<script>
  export default {
    name: 'icon-base',

    props: {
      iconName: {
        type: String,
        default: 'box',
      },
      display: {
        type: String,
        default: 'block',
      },
      viewBox: {
        type: String,
        default: '0 0 128 128',
      },
      width: {
        type: [Number, String, Boolean],
        default: false,
      },
      height: {
        type: [Number, String, Boolean],
        default: false,
      },
      iconColor: {
        type: String,
        default: 'currentColor',
      },
    },
  };
</script>

<style lang="stylus">
  .icon-base
    display block
</style>
