export default {
  listsData: state => (state.rawData.data || []).map(
    list => ({
      ...list.attributes,
      id: Number(list.id)
    })
  ),

  optionsLists: (state, getters) => getters.listsData.map(
    list => ({
      label: list.name,
      value: list.id
    })
  ),

  listById: (state, getters) => listId => getters.listsData.find(list => list.id === listId),

  tablesByListId: (state, getters) => listId => ((getters.listById(listId) || {}).structure || {}).tables || [],

  optionsTables: (state, getters) => listId => getters.tablesByListId(listId).map(
    table => ({
      label: table.name,
      value: table.name
    })
  ),

  tableByListIdTableName: (state, getters) =>
    (listId, tableName) =>
      getters.tablesByListId(listId).find(table => table.name === tableName)
}
