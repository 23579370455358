<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 41 45"
    :width="width"
    :height="height"
  >
    <image
      width="41"
      height="45"
      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAtCAMAAAAEL7LSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACvlBMVEX////////////9/f38/Pz//v/8+/z39/j09PX19PT19Pf4+Pr39/nv7u/z8/X6+vzt7u7u7u7+/Pz39/r7+vv///78+/ubvt98qtbr8ffm7fXp8Pb+/v12p9UAVrAAWLB6qdb//v7p7/br8PeMtNs7gsREh8YtecCHsdlims93p9WQuNyFsNkzfMJDh8aLtNz8/f3e5/Ikc70jcrwpdr4eb7s5gcNzpdSFsdmArdeCr9lypdQwesAmdL0seL8hcbwldL3e6PPZ5fEwe8Axe8EteMCmxePY5vRMjMgQZrcicrwgcLsicbwzfME5gMM3fsLb5vIzfcFKi8ixzOYWarlon9KKtNx+rdh/rdiHsttPjsord77b5fI0fsEjcr3M3u5todMRZ7d6qtefwuKZvd+avd+eweJalc0odb4qdr9PjsmLtdyZvuB9q9dAhcWHsdqwzeepx+SqyOSvzOZhms8mdL4gcLxRj8rp7/egwuLS4PBondGJs9uGsNmIs9tSkMoqd7/Y5PEdbrtgmc7W5fLJ2+3J3O3R4vGwzOY2fsIndb4teL8yfMDf6PMndL0cbroecLsgcbwjc70fb7spdr/f6fONttw/hMVDhcVFiMZFh8ZHicdMjMlJishChcb7+/z29vn29/r19vn8+vv//v3t7e319ff+/v/8/P/8/P7z8vPu7e/s7O3q6uvr6uzv7+/m5uXl5eX+/v76+vrLy8vn5+fx8fH09PT19fX5+fm1tbW5ubmxsbHm5ubo6OjX19fe3t7R0dGysrLi4uLZ2dnQ0NDp6emenp7Gxsby8vLPz8+jo6Pg4OCsrKy8vLvExMSqqqqWlpb7+/u/v7+7u7u8vLy2tbbMzMzw8PC5uLmvr6/c3NyampqoqKi6urrCwsKZmZm9vL20tLT29vbAwMDa2trg3+Dz8/Pj4+MAAAAMgw07AAAAAnRSTlPm8i0ECmIAAAABYktHROlR00eUAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH4gwUAQcHXTRUswAAAhNJREFUOMtjYGAkDjAwMBELGBiJVMgIU8nMggswo6hkZWPnwAXY2ViRVDJzcnHzYAfcXJzMyCp5+fi5sQN+Pl4UlQJ4VAqgqBTEo1KQnip5hIACQjz4VQqLiIqKMomJS4iLMQFZIsK4VAqziUpKScvIysnLKSgqSSmLsgnjUCnGoqKqpq4hrqmlqa2jq6eqbyCGQyWToZGxiamZuYWllbWNrZ29gyMTLpVOzi6ubu4enl7ePr5+/r7OTjhVBgTaBrExBYf4hoaFR0RGRcfgVhlrFxfPlJCom5SckpqWnoFPZWZWdk5unm1+QWFRcUlpAG6VZeVMFZVMVabVNTq1dfUNuFU2NjW3tLa1d3R2dWVkdHX3NOJU2dvXP2HipMkTM02AYIpp6VRcKsX4p02fMXPmrNlz5nh4eMydN20+rpDnEWHiXrAQBhZxM4nw4ExLi5cgg8UDkJKppnLpsuUrVmIDK5YvW4qictXqNWvXYQNr16xehaySVWT9ho3YwYb1IsjlEtMmZhZm7ICFeRNqqUh8+QkHm7dshTG3bcepcsdOJsFdu/fs3befaf2BnQcPHcap8sjRY8dPMLGcPHX6zNlz5y9c3IpT5aXLV67uYmLau3/Ptes3bjLduo3bnVfvXLp7+d79Qw92H93+8NHFjbhVnjzG9PjJU6Zn17dvZ3p+/gUzXr8TH0q4VRKpEFjHEV1vAgDChOXIF8bGNgAAAABJRU5ErkJggg=="
    />
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-component-w-vcard',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
