<i18n src="general/lang/WidgetSettingsTypesItem.json"></i18n>

<template>
  <div :class="b({ isActive: jsonData.active })">

    <div :class="b('card')">
      <div :class="b('controls')">
        <button
          :class="b('button-toggle', { isActive: jsonData.active })"
          @click.prevent="toggle"
        >
          <q-icon name="fas fa-check" />
        </button>

        <button
          :class="b('button-setup')"
          @click.prevent="setupItem"
        >
          <q-icon name="fas fa-cog" />
        </button>

        <button
          :class="b('button-delete')"
          @click.prevent="deleteItem"
        >
          <q-icon name="far fa-trash-alt" />
        </button>
      </div>

      <div :class="b('header')">
        <div
          :class="b('name')"
          v-html="jsonData.name"
        ></div>

        <div :class="b('info')">
          <div
            :class="b('count-scenarios')"
            :title="$t('WidgetSettingsTypesItem.hintCountScenarios')"
          >
            {{ jsonData.countScenarios }}
          </div>
        </div>
      </div>

      <div :class="b('preview')">
        <WidgetModalInnerBase
          :class="b('widget-modal-inner-base')"
          :editor-mode="true"
          :header="jsonData.modal.header"
          :footer="jsonData.modal.footer"
        >
          <template v-for="component in jsonData.components">
            <component
              v-if="component"
              :key="component.uuid"
              :is="component.name"
              :editor-mode="true"
              v-bind="component.properties"
            />
          </template>
        </WidgetModalInnerBase>
      </div>
    </div>

    <div :class="b('types')">
      <template v-for="(type, index) in jsonData.types">
        <div
          v-if="type"
          :key="index"
          :class="b('types-item')"
          title="Widget base type"
        >
          {{ type }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { createNamespacedHelpers } from 'vuex';
  import { baseMixin, noticeMixin, confirmationMixin } from 'general/mixins';
  import WidgetModalInnerBase from 'general/components/WidgetModalInnerBase';
  import widgetComponents from 'general/components/widgetComponents';
  import IconGear from 'general/components/icons/IconGear';
  import IconTrashCross from 'general/components/icons/IconTrashCross';
  import IconCheck from 'general/components/icons/IconCheck';

  const { mapActions } = createNamespacedHelpers('widgetSettings');

  export default {
    name: 'widget-settings-types-item',

    mixins: [
      baseMixin,
      noticeMixin,
      confirmationMixin,
    ],

    components: {
      WidgetModalInnerBase,
      ...widgetComponents,
      IconGear,
      IconTrashCross,
      IconCheck,
    },

    model: {
      prop: 'model',
      event: 'update',
    },

    props: {
      model: {
        type: Object,
        required: true,
      },
      editing: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      state: {},
      isUpdating: false,
      isEditing: false,
      disabled: false,
    }),

    computed: {
      autoSave: () => true,
      jsonData: vm => vm.state.attributes.json_data,
    },

    watch: {
      model: {
        handler(value) {
          this.state = _.cloneDeep(value);
          const timer = setTimeout(() => {
            this.isUpdating = false;
            clearTimeout(timer);
          }, 1);
        },
        immediate: true,
        deep: true,
      },

      editing: {
        handler(value) {
          this.isEditing = value;
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions({
        update: 'widgetTypes/update',
        delete: 'widgetTypes/delete',
        setConfigurable: 'widgetTypes/setConfigurable',
      }),

      /**
       * ...
       */
      toggle() {
        this.jsonData.active = !this.jsonData.active;
        this.updateItem(this.jsonData);
      },

      /**
       * ...
       */
      updateItem() {
        if (this.isUpdating) {
          return;
        }

        this.isUpdating = true;
        const element = this.state;
        const updated = this.update({ element });

        if (updated) {
          this.notice('Success to save widget type', this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice('Failed to save widget type', this.NOTICE_LEVEL_ERROR);
        }
      },

      /**
       * Генерация события удаления элемента
       */
      deleteItem() {
        const text = this.$t('WidgetSettingsTypesItem.deletionConfirmationText');
        if (!this.confirm(text)) {
          return;
        }

        const deleted = this.delete(this.state.id);

        if (deleted) {
          this.notice('Success to delete type', this.NOTICE_LEVEL_SUCCESS);
        } else {
          this.notice('Failed to delete type', this.NOTICE_LEVEL_ERROR);
        }
      },

      /**
       * Генерация события настройки элемента
       */
      async setupItem() {
        await this.setConfigurable(this.state.id);
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .widget-settings-types-item
    width 200px
    position relative

    &__card
      box-shadow 0 1px 5px 0 rgba(#000, .25)
      transition-duration $duration

      ~/_is-active &
        border-color lightness($brand-success, 60%)

    &__controls
      position absolute
      top -8px
      left -14px
      z-index 1

    &__header
      flex(false, center)
      padding 10px 10px 8px 20px

    &__name
      margin-right 10px
      typography(14px)
      font-weight 700

    &__info
      flex()
      margin-left auto

    &__count-scenarios
    &__button-toggle
    &__button-setup
    &__button-delete
      reset-button()
      flex(center, center)
      size(20px)
      border-radius 50%
      typography(14px)

    &__count-scenarios
      background-color #fff
      margin-left 5px
      cursor default

    &__button-toggle
    &__button-setup
    &__button-delete
      size(24px)
      link()
      margin-bottom 8px
      box-shadow 0 0 2px 0 rgba(#000, .3)
      outline none

      &:disabled
        background-color lighten($brand-black, 90%)
        color lighten($brand-black, 30%)

    &__button-toggle
      &:not(^[1]_is-active)
        state-background(#fff, $brand-success)

        svg, i
          opacity .25

          ^[1]:hover&
            color #fff
            opacity 1

      &_is-active
        state-background(#fff, $brand-danger)
        state-color($brand-success, #fff)

    &__button-setup
      state-background(#fff, $brand-info)
      state-color($brand-info, #fff)

    &__button-delete
      state-background(#fff, $brand-danger)
      state-color($brand-danger, #fff)

    &__preview
      padding 5px 10px 10px
      font-size 16px
      line-height 1.5em
      zoom .5
      position relative
      overflow hidden
      opacity .4
      transition $duration

      ~/_is-active &
        opacity 1

      +after()
        absolute-parent(1)

    &__widget-modal-inner-base
      box-shadow 0 8px 20px -1px rgba(27, 33, 58, .3)

    &__types
      flex(center, center)
      flex-wrap wrap
      margin-top 10px

    &__types-item
      background-color rgba($brand-info, .25)
      border-radius 5px
      padding 3px 10px
      margin-right 10px
      margin-bottom 10px
      typography(12px)

      +is-last()
        margin-right 0
</style>
