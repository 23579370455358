import {
  makeControlProps, loopControlProps, sizingProps,
  backgroundProps, visibilityProps, typographyProps
} from './helper';

import Vue from 'vue';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('select', {
    pathValue: 'properties.direction',
    label: 'Direction',
    options: [
      {label: 'Row', value: 'row'},
      {label: 'Column', value: 'column'},
    ],
    subcontrols: {
      'row': [
        makeControlProps('select', {
          pathValue: 'properties.wrap',
          label: 'Wrap row',
          options: [
            {label: 'Yes', value: 'wrap'},
            {label: 'No', value: 'no-wrap'},
          ],
          subcontrols: {
            'no-wrap': [
              makeControlProps('select', {
                pathValue: 'properties.navigationControls',
                label: 'Navigation controls',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ]
              }),
            ]
          }
        }),
      ]
    }
  }),
  makeControlProps('select', {
    pathValue: 'properties.justify',
    label: 'Justify',
    options: [
      {label: '-', value: ''},
      {label: 'Left', value: 'justify-start'},
      {label: 'Center', value: 'justify-center'},
      {label: 'Right', value: 'justify-end'},
      {label: 'Space around', value: 'justify-around'},
      {label: 'Space between', value: 'justify-between'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.valign',
    label: 'Blocks align',
    options: [
      {label: '-', value: ''},
      {label: 'Start', value: 'items-start'},
      {label: 'Middle', value: 'items-center'},
      {label: 'End', value: 'items-end'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.outerspacing',
    label: 'Outer spacing',
    options: [
      {label: '-', value: ''},
      {label: 'Extra small', value: 'q-gutter-xs'},
      {label: 'Small', value: 'q-gutter-sm'},
      {label: 'Medium', value: 'q-gutter-md'},
      {label: 'Large', value: 'q-gutter-lg'},
      {label: 'Big', value: 'q-gutter-xl'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.innerspacing',
    label: 'Inner spacing',
    options: [
      {label: '-', value: ''},
      {label: 'Extra small', value: 'q-col-gutter-xs'},
      {label: 'Small', value: 'q-col-gutter-sm'},
      {label: 'Medium', value: 'q-col-gutter-md'},
      {label: 'Large', value: 'q-col-gutter-lg'},
      {label: 'Big', value: 'q-col-gutter-xl'},
    ]
  }),
  makeControlProps('select', {
    pathValue: 'properties.shadow',
    label: 'Shadow',
    options: [
      {label: '-', value: ''},
      {label: '1', value: '1'},
      {label: '2', value: '2'},
      {label: '3', value: '3'},
      {label: '4', value: '4'},
      {label: '5', value: '5'},
      {label: '6', value: '6'},
      {label: '7', value: '7'},
      {label: '8', value: '8'},
      {label: '9', value: '9'},
      {label: '10', value: '10'},
    ],
    subcontrols: [
      makeControlProps('select', {
        pathValue: 'properties.shadowPosition',
        label: 'Shadow position',
        options: [
          {label: 'Down', value: ''},
          {label: 'Up', value: 'up-'},
        ]
      })
    ]
  }),
  // makeControlProps('select', {
  //   pathValue: 'properties.toolbar',
  //   label: 'Toolbar',
  //   options: [
  //     {label: '-', value: ''},
  //     {label: 'Top toolbar', value: 'top'},
  //     {label: 'Bottom toolbar', value: 'bottom'},
  //   ]
  // }),
  makeControlProps('select', {
    pathValue: 'location',
    label: 'Toolbar',
    options: [
      {label: '-', value: ''},
      {label: 'Top toolbar', value: 'top'},
      {label: 'Bottom toolbar', value: 'bottom'},
    ]
  }),
  // makeControlProps('select', (componentData) => {
  //   debugger
  //   Vue.set(componentData, 'location', componentData.properties.toolbar);
  //
  //   return {
  //     pathValue: 'properties.toolbar',
  //     label: 'Toolbar',
  //     options: [
  //       {label: '-', value: ''},
  //       {label: 'Top toolbar', value: 'top'},
  //       {label: 'Bottom toolbar', value: 'bottom'},
  //     ]
  //   }
  // }),
  visibilityProps,
  loopControlProps,
  makeControlProps('items', {
    pathValue: 'meta.outlineCells',
    type: 'collapse',
    label: 'Cells',
  }),
]

const view = [
  {
    selector: '.mbst-row',
    controls: [
      typographyProps,
      sizingProps,
      {
        controlName: 'v-control-select',
        label: 'Display',
        pathValue: 'display',
        options: [{label: 'Visible', value: 'block'}, {label: 'None', value: 'none'}, { label: 'Inline', value: 'inline' }],
      },
      makeControlProps('spacing', { label: 'Spacing' }),
    ]
  },
  {
    selector: '.mbst-row > .mbs-row__content',
    controls: [
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
      backgroundProps,
      makeControlProps('items', {
        pathValue: 'meta.outlineCells',
        type: 'collapse',
        label: 'Cells',
      })
    ]
  }
]

const actions = []

export default {
  properties,
  view,
  actions
}
