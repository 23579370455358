'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');


module.exports = Backbone.Model.extend({

    defaults: {
        name: null,
        description: null,
        in: 'query',
        required: false,
        type: 'string',
        value: '',
        isSystem: false
    },

    initialize: function() {
    }

});