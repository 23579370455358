import WText from './WText';
import WImage from './WImage';
import WBots from './WBots/WBots';
import WA2hs from './WA2hs';
import WVcard from './WVcard';

export default {
  WText,
  WImage,
  WBots,
  WA2hs,
  WVcard,
};
