'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Views
var ConditionsItemView = require('views/conditions/ConditionsItemView');

// Collections
var ConditionsCollection = require('collections/ConditionsCollection');

// Templates
var _conditions = require('text!bcTemplates/conditions/conditions.html');
var _label = require('text!bcTemplates/conditions/controls/label.html');

// Helper
var PubSub = require('helper/pubsub');


module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'conditions',

    events: {
        'click [data-js-action="addCondition"]': 'addCondition',
        'change .b-constructor__switcher input[type="radio"]': 'changeAndOr'
    },

    initialize: function(options) {
        this.tplConditions = _.template(_conditions);
        this.cdtId = options.cdtId;
        this.collection = new ConditionsCollection(options.collection);
        this.itemViews = [];
        this.parentView = options.parentView || undefined;

        this.listenTo(this.collection, "remove", this.setConditions, this);
        this.listenTo(this.collection, "change", this.setConditions, this);
    },

    render: function() {
        var t = this;

        t.$el.empty();

        var conditions = t.tplConditions({
            id: t.cdtId,
            conditions: t.collection.toJSON()
        });
        var $conditions = $(conditions);
        t.$el.append($conditions);

        var $conditionsItemViews = _.map(t.collection.models, function(model, k) {
            var conditionsItemView = new ConditionsItemView({
                model: model,
                conditionsView: t
            });
            t.itemViews.push(conditionsItemView);
            return conditionsItemView.render();
        });

        t.$el.find('.b-constructor__rows').append($conditionsItemViews);

        return t.$el;
    },

    setConditions: function() {
        if (this.parentView) {
            this.parentView.model.set('conditions', _.map(this.collection.toJSON(), function(condition) {
                return _.omit(condition, ['fieldType', 'valueTplType']);
            }));
        }
        PubSub.trigger('setConditionalDataTransformations'); // TriggerOperationSettingsView
    },

    changeAndOr: function(e) {
        var andOr = $(e.target).val();
        _.each(this.collection.models, function(model) {
            model.set('andor', andOr);
        });
        _.each(this.$el.find('.b-constructor__labels .b-constructor__label'), function(label) {
            $(label).text(lang(andOr));
        });
    },

    addCondition: function(e) {
        e.preventDefault();
        var label = this.collection.at(0).get('andor');
        var ConditionModel = Backbone.Model.extend({
            defaults: {
                field: '',
                operation: '==',
                value: '',
                andor: label
            }
        });
        var conditionModel = new ConditionModel();
        var conditionItemView = new ConditionsItemView({
            model: conditionModel,
            conditionsView: this
        });
        this.itemViews.push(conditionItemView);
        this.collection.add(conditionModel);
        this.$el.find('.b-constructor__rows').append(conditionItemView.render());
        this.addLabels();

        var bindings = {
            '[name="dataConditionsField"]': 'field',
            '[name="dataConditionsOperation"]': {
                observe: 'operation',
                selectOptions: {
                    collection: function () {
                        return conditionItemView.conditionRulesView.operandTpls.operand.value;
                    }
                }
            },
        };
        conditionItemView.stickit(conditionItemView.model, bindings);
    },

    addLabels: function() {
        if (this.collection.length > 1) {
            this.$el.find('.b-constructor__switcher').show();
        }
        if (this.collection.length > 2) {
            var label = this.collection.at(0).get('andor');
            this.$el.find('.b-constructor__labels').append(_.template(_label)({text: label}));
        }
    },

    deleteLabels: function() {
        if (this.collection.length <= 1) {
            this.$el.find('.b-constructor__switcher').hide();
        }
        if (this.collection.length > 1) {
            this.$el.find('.b-constructor__label:last-child').remove();
        }
    },

    close: function() {
        _.each(this.itemViews, function(itemView){
            if (itemView.close){
                itemView.close();
            }
        });
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }

});