import { backgroundDefault } from './helper';

export default {
  name: 'mbst-divider',
  aliasName: 'Divider',
  properties: {
    backendname: 'divider',
    visibility: {
      conditions: [],
      hidden: false
    }
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-divider',
        rules: [
          { directive: 'height', value: '1px' },
          { directive: 'background-color', value: '#cccccc' },
          ...backgroundDefault,
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-divider',
        rules: [
          { directive: 'height', value: '1px' },
          { directive: 'background-color', value: '#cccccc' },
          ...backgroundDefault,
        ]
      }
    ]
  },
}
