import widgetComponentsSchemas from 'general/components/widgetComponents/schemas';

export default {
  namespaced: true,

  state: () => ({
    components: widgetComponentsSchemas,
  }),

  getters: {

    getComponents: state => state.components,

  },

  actions: {
    //
  },

  mutations: {
    //
  },
};
