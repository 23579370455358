var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.b({ theme: _vm.theme }) },
    [
      !_vm.editorMode && _vm.maskEnabled && _vm.isShownMask
        ? _c("WBotsItemMask", { attrs: { bot: _vm.bot } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDisabledNotDefined
        ? _c(
            "a",
            {
              class: _vm.b("link"),
              attrs: {
                href: _vm.editorMode ? "#" : _vm.url,
                target: _vm.isViber || _vm.editorMode ? false : "_blank",
              },
            },
            [
              _c(
                "div",
                { class: _vm.b("media") },
                [
                  _c("div", {
                    class: _vm.b("status", { isSubscribed: _vm.isSubscribed }),
                  }),
                  _vm._v(" "),
                  [
                    _vm.icon.src && !_vm.icon.backgroundUrl
                      ? _c("img", {
                          class: _vm.b("icon"),
                          attrs: { src: _vm.icon.src, alt: _vm.title },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.icon.backgroundUrl
                      ? _c("img", {
                          class: _vm.b("icon"),
                          style: _vm.handlerStylePreview(),
                          attrs: {
                            src: _vm.icon.backgroundUrl,
                            alt: _vm.title,
                          },
                        })
                      : _vm.icon.component
                      ? _c(_vm.icon.component, {
                          tag: "component",
                          class: _vm.b("icon"),
                          attrs: { width: "32" },
                        })
                      : _c("IconLogo", { attrs: { width: "48" } }),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { class: _vm.b("title") }, [
                _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
              ]),
            ]
          )
        : _vm.isSubscribed
        ? _c("div", [
            _c(
              "div",
              {
                class: _vm.b("media"),
                on: {
                  click: function ($event) {
                    return _vm.onChangeChannel({
                      key: _vm.bot.options.channel,
                      value: -1,
                    })
                  },
                },
              },
              [
                _c("div", {
                  class: _vm.b("status", { isSubscribed: _vm.isSubscribed }),
                }),
                _vm._v(" "),
                [
                  _vm.icon.src && !_vm.icon.backgroundUrl
                    ? _c("img", {
                        class: _vm.b("icon"),
                        attrs: { src: _vm.icon.src, alt: _vm.title },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.icon.backgroundUrl
                    ? _c("img", {
                        class: _vm.b("icon"),
                        style: _vm.handlerStylePreview(),
                        attrs: { src: _vm.icon.backgroundUrl, alt: _vm.title },
                      })
                    : _vm.icon.component
                    ? _c(_vm.icon.component, {
                        tag: "component",
                        class: _vm.b("icon"),
                        attrs: { width: "32" },
                      })
                    : _c("IconLogo", { attrs: { width: "48" } }),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { class: _vm.b("title") }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ]),
          ])
        : _vm.isDisabled
        ? _c("div", [
            _c(
              "div",
              {
                class: _vm.b("media"),
                on: {
                  click: function ($event) {
                    return _vm.onChangeChannel({
                      key: _vm.bot.options.channel,
                      value: 1,
                    })
                  },
                },
              },
              [
                _c("div", {
                  class: _vm.b("status", { isSubscribed: _vm.isSubscribed }),
                }),
                _vm._v(" "),
                [
                  _vm.icon.src && !_vm.icon.backgroundUrl
                    ? _c("img", {
                        class: _vm.b("icon"),
                        attrs: { src: _vm.icon.src, alt: _vm.title },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.icon.backgroundUrl
                    ? _c("img", {
                        class: _vm.b("icon"),
                        style: _vm.handlerStylePreview(),
                        attrs: { src: _vm.icon.backgroundUrl, alt: _vm.title },
                      })
                    : _vm.icon.component
                    ? _c(_vm.icon.component, {
                        tag: "component",
                        class: _vm.b("icon"),
                        attrs: { width: "32" },
                      })
                    : _c("IconLogo", { attrs: { width: "48" } }),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { class: _vm.b("title") }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }