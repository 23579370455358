var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "b-spinner-disabled" }, [
    _c("div", [
      _c("div", {
        staticClass: "b-spinner b-spinner--anim b-spinner--primary is-lg",
      }),
      _vm._v(" "),
      _vm.isText
        ? _c(
            "div",
            { staticClass: "b-spinner__text" },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.lang("Loading Data...")))]
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }