var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.b() }, [
    _vm.editorMode ? _c("div", { class: _vm.b("mask") }) : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.b("item") }, [
      _c(
        "a",
        {
          class: _vm.b("link"),
          attrs: {
            href: _vm.editorMode ? "#" : _vm.url,
            target: _vm.editorMode ? false : "_blank",
          },
        },
        [
          _c(
            "div",
            { class: _vm.b("media") },
            [_c("IconIdCard", { attrs: { width: "32" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.b("title") }, [
            _vm._v("\n        vCard\n      "),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }