import ApplicationsService from './services/ApplicationsService';
import ScreensService from './services/ScreensService';
import ListsService from './services/ListsService';
import SmartFiltersService from './services/SmartFiltersService';
import WidgetsService from './services/WidgetsService';
import PartnersService from './services/PartnersService';

const services = {
  applications: ApplicationsService,
  screens: ScreensService,
  lists: ListsService,
  smartFilters: SmartFiltersService,
  widgets: WidgetsService,
  partners: PartnersService,
}

export const ApiServiceFactory = $axios => ({
  get: name => services[name]($axios)
})

export const ApiServicesFactory = $axios => {
  let res = {}
  for (const service in services) {
    res[service] = services[service]($axios)
  }
  return res
}
