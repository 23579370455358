<template>
  <IconBase
    :icon-name="$options.name"
    view-box="0 0 128 42.49"
    :width="width"
    :height="height"
  >
    <path
      d="M124.87 36a4.08 4.08 0 0 0 1.15-.51 3.55 3.55 0 0 0 .31-.23 4 4 0 0 0 .69-.68 2.09 2.09 0 0 0 .22-.28 1.49 1.49 0 0 0 .12-.14 4.44 4.44 0 0 0 .46-1v-.11a4 4 0 0 0 .12-1.18v-.16l-2.16-25.38a3 3 0 0 0-3.73-2.72 4.17 4.17 0 0 0-3.12 4.32l1.19 13.66A86 86 0 0 0 98 7.3C61.75-9.64 18 3.9.47 37.49A3.44 3.44 0 0 0 1.26 42a2.82 2.82 0 0 0 .44.26 3.73 3.73 0 0 0 4.71-1.93C22 10.36 61.55-1.45 94.61 14c.15.07 12.53 5.38 21.52 14.14l-15.87-1.28a4.07 4.07 0 0 0-4.09 3.32 3.08 3.08 0 0 0 2.65 3.87l25 2a3.87 3.87 0 0 0 1.05-.05z"
    ></path>
  </IconBase>
</template>

<script>
  import iconSizeMixin from 'general/mixins/iconSizeMixin';
  import IconBase from './IconBase';

  export default {
    name: 'icon-arrow-curved-right',

    mixins: [
      iconSizeMixin,
    ],

    components: {
      IconBase,
    },
  };
</script>
