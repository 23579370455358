import MakeHttp from '@/general/utils/MakeHttp';
const http = MakeHttp();

export default {
  async fetchDataById({ commit }, appId) {
    try {
      const { data } = await this.$api.applications.getById(appId);

      if (!data) throw new Error('Failed load application data');

      commit('set', { path: 'data', payload: data });
      return Promise.resolve(data);
    }
    catch(e) {
      console.error(e);
      return Promise.resolve(null);
    }
    finally {

    }
  },

  async createApplication ({ commit, state }, params) {
    const { data, id } = params
    const apiURL = 'pwalessapps' + (id ? `/${id}` : '')
    try {
      const resp = await http.post(apiURL, data)
      // Object.assign(resp.data.attributes, state.data.attributes, resp.data.attributes)
      // commit('set', { path: 'data', payload: resp.data })

      // console.log(resp);
      // if(resp.data.attributes.isInsales){
      //   commit('set', { path: 'data.isInsales', payload: resp.data.attributes.isInsales });
      // }

      return true
    } catch (e) {
      return e
    }
  }
}
