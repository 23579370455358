var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "WidgetModalInnerBase",
    {
      class: _vm.b(),
      attrs: {
        "editor-mode": true,
        header: _vm.jsonData.modal.header,
        footer: _vm.jsonData.modal.footer,
      },
    },
    [_c("WidgetEditorContentArea")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }