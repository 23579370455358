/**
 * Фабрика миксинов хранилища
 *
 * @param {string} name
 * @param {Object} module
 * @returns {Object}
 */
const factoryStoreMixin = (name, module) => ({
  beforeCreate() {
    if (!(this.$store && this.$store.state && this.$store.state[name])) {
      this.$store.registerModule(name, module);
    }
  },
});

export default factoryStoreMixin;
