<template>
  <q-select
    outlined dense options-dense hide-selected
    fill-input use-input input-debounce="100"
    v-model="model" :options="options" :label="data.label"
    @filter="onFilter"
  >
    <template v-slot:append>
      <q-icon
        v-if="model !== null"
        class="cursor-pointer"
        name="clear"
        @click.stop="model = null"
      />
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
  export default {
    name: 'hg-ui-dropdown',
    props: {
      data: {
        type: Object,
      },
    },
    data: function() {
      return {
        model: this.data.value,
        options: [],
      };
    },
    watch: {
      'data.value': {
        handler(newValue) {
          if (!newValue) this.model = newValue;
        }
      },
      model(newValue) {
        this.$emit('input', newValue);
      },
    },
    methods: {
      onFilter(value, update) {
        update(() => {
          const needle = value.toLowerCase();
          this.options = this.data.collection
            .filter(item => item.label.toLowerCase().indexOf(needle) > -1);
        });
      }
    }
  }
</script>
