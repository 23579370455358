import { backgroundDefault } from './helper';
import tabsTab from './tabsTab';

export default {
  name: 'mbst-tabs',
  aliasName: 'Tabbar',
  properties: {
    backendname: 'tabs',
    direction: 'horizontal',
    barWidth: 4,
    position: 'top',
    activeTab: '1',
    rememberActiveTab: false,
    //activeTabColor: 'primary',
    inlineLabel: true,
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  actions: null,
  css: {
    ios: [
      {
        selector: '.mbst-tabs',
        rules: [
          { directive: 'height', value: '400px' },
        ]
      },
      {
        selector: '.mbst-tabs .q-tabs__content',
        rules: [
          ...backgroundDefault,
        ]
      },
      {
        selector: '.mbst-tabs .mbst-tabs__tabbar .q-tab--active',
        rules: [
          { directive: 'background-color', value: '#337ab7' },
          { directive: 'color', value: '#fff' },
        ]
      }
    ],
    android: [
      {
        selector: '.mbst-tabs',
        rules: [
          { directive: 'height', value: '400px' },
        ]
      },
      {
        selector: '.mbst-tabs .q-tabs__content',
        rules: [
          ...backgroundDefault,
        ]
      },
      {
        selector: '.mbst-tabs .mbst-tabs__tabbar .q-tab--active',
        rules: [
          { directive: 'background-color', value: '#337ab7' },
          { directive: 'color', value: '#fff' },
        ]
      }
    ]
  },
  items: [
    tabsTab,
    tabsTab,
  ]
}
