import {
  makeControlProps,
  backgroundProps,
  typographyProps,
  visibilityProps,
  loopControlProps
} from './helper';

const properties = [
  makeControlProps('input', {
    pathValue: 'properties.backendname',
    label: 'Backendname'
  }),
  makeControlProps('textarea', {
    pathValue: 'properties.text',
    label: 'Text'
  }),

  loopControlProps,
  visibilityProps
];

const view = [
  {
    selector: '.mbst-slider__slide__caption',
    controls: [
      typographyProps,
      makeControlProps('spacing', { label: 'Spacing' }),
      makeControlProps('borders', { label: 'Borders' }),
      makeControlProps('corners', { label: 'Corners', pathValue: 'border-radius' }),
    ]
  },
  {
    selector: '.mbst-slider__slide',
    controls: [
      backgroundProps,
    ]
  }
]

const actions = []

export default {
  properties,
  view,
  actions: [true],
}
