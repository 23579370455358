import _ from 'lodash';
import { backgroundDefault } from './helper';
import flexCol from './flexRowCol';
import flexRow from './flexRow';
import Text from './text';
import Button from './button';

// ** left drawer (нет возможности установить компоненты скрина) **
// const menutitle = _.cloneDeep(Text);
// menutitle.location = 'left'
// menutitle.properties.text = 'Main menu';
// menutitle.css.all = [{ selector: '.mbst-text', rules: { 'font-weight': 'bold', 'font-size': '2em', 'text-align': 'center' } }]

// ** heading cell-1 **
const title = _.cloneDeep(Text);
title.properties.text = 'Heading of my site';
title.css.all = [{ selector: '.mbst-text', rules: { 'font-family': 'Arial, Helvetica, sans-serif', 'font-size': '1.5em', padding: '10px 0px 40px 0px' } }]
title.css['.media-sm'] = [{ selector: '.mbst-text', rules: { 'font-family': 'Arial, Helvetica, sans-serif', 'font-size': '4em', padding: '20px 0px 40px 0px' } }]

const subtitle = _.cloneDeep(Text);
subtitle.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.';

const menubutton = _.cloneDeep(Button);
Object.assign(menubutton.properties, { iconCls: 'fas fa-bars', roundButton: 'round', text: '', typeButton: 'flat' })
menubutton.actions = [
  {
    type: 'setProperty',
    options: {
      componentName: 'Screen',
      componentUUID: '',
      propertyName: 'leftpanel',
      propertyValue: true
    }
  }
]

menubutton.css.all = [
  {
    selector: '',
    rules: {
      position: 'absolute',
      left: '-4px',
      top: '-4px'
    }
  },
  {
    selector: '.mbst-button',
    rules: {
      'background-color': 'rgba(0,0,0,0)'
    }
  },
  {
    selector: ',.mbst-button-container',
    rules: {
      width: '60px'
    }
  },
  {
    selector: '.mbst-button i',
    rules: {
      'font-size': '1.2em'
    }
  },
]
menubutton.css['.media-sm'] = [
  {
    selector: ',.mbst-button-container',
    rules: {
      display: 'none'
    }
  }
]

const cell_1 = _.cloneDeep(flexCol);
cell_1.properties.colwidth = "auto"
cell_1.css.all = [{ selector: '.mbst-row__col', rules: { 'background-color': '#818181', color: '#F5F5F5', 'font-size': '1.5em', 'min-height': '220px', padding: '20px 20px 20px 20px' } }]
cell_1.css['.media-sm'] = [{ selector: '.mbst-row__col', rules: { 'min-height': '300px' } }]
cell_1.components = [menubutton, title, subtitle]

// ** content cell-2 **

  const t = _.cloneDeep(Text);
  t.properties.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.';

  const cont_cell_1 = _.cloneDeep(flexCol);
  cont_cell_1.properties.colwidth = "12"
  cont_cell_1.properties.customClasses = 'col-sm-8';
  cont_cell_1.components = [t]

  const cont_cell_2 = _.cloneDeep(flexCol);
  cont_cell_2.properties.colwidth = "12"
  cont_cell_2.properties.customClasses = 'col-sm-4'
  cont_cell_2.components = [t,t,t]

  const cont_row = _.cloneDeep(flexRow);
  cont_row.properties.innerspacing = 'q-col-gutter-sm'
  cont_row.items = [cont_cell_1, cont_cell_2]

const cell_2 = _.cloneDeep(flexCol);
cell_2.properties.colwidth = ""
cell_2.components = [cont_row]

// ** footer cell-3 **
  const footer_cell_1 = _.cloneDeep(flexCol);
  footer_cell_1.properties.colwidth = "6"
  footer_cell_1.components = [t]

  const footer_cell_2 = _.cloneDeep(flexCol);
  footer_cell_2.properties.colwidth = "6"
  footer_cell_2.components = [t]

  const footer_row = _.cloneDeep(flexRow);
  footer_row.items = [footer_cell_1, footer_cell_2]


const cell_3 = _.cloneDeep(flexCol);
cell_3.properties.colwidth = "auto"
cell_3.css.all = [{ selector: '.mbst-row__col', rules: { 'background-color': '#818181', color: '#F5F5F5', 'min-height': '100px', padding: '10px 10px 10px 10px' } }]
cell_3.components = [footer_row]

export default {
  name: 'mbst-flexrow',
  aliasName: 'Two column',
  properties: {
    backendname: 'flexrow',
    direction: 'column',
    wrap: 'wrap',
    justify: '',
    valign: '',
    outerspacing: '',
    innerspacing: '',
    shadow: '',
    shadowPosition: '',
    navigationControls: false,
    toolbar: '',
    visibility: {
      conditions: [],
      hidden: false
    },
  },
  meta: {
    outlineCells: true,
  },
  actions: null,
  css: {
    all: [
      {
        selector: '.mbst-row',
        rules: {
          'height': '100%',
          'min-height': '100%',
          'background-color': '#FFFFFF'
        }
      }
    ],
    '.media-lg':[
      {
        selector: '.mbst-row',
        rules: {
          'max-width': '1200px',
          'margin-left': 'auto',
          'margin-right': 'auto'
        }
      }
    ]
  },
  items: [
    cell_1,
    cell_2,
    cell_3
  ]
}
