'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Models
var TriggerOperationItemModel = require('models/TriggerOperationItemModel');


module.exports = Backbone.Collection.extend({

    url: '/triggers/triggeroperationsapi',

    model: TriggerOperationItemModel,

    initialize: function() {

    },

    parse: function(response) {
        var t = this;
        return _.map(response, function(obj) {
            return t.parseModel(obj)
        });
    },

    parseModel: function(obj) {
        var t = this;
        return {
            id: obj.id,
            triggerOperation: {
                id: obj.id,
                TriggerId: obj.operation.TriggerId,
                OperationId: obj.operation.id,
                SortOrder: obj.operation.SortOrder,
                SuccessOperationId: obj.operation.SuccessOperationId,
                FailOperationId: obj.operation.FailOperationId,
                AlwaysOperationId: obj.operation.AlwaysOperationId,
                Level: obj.operation.Level
            },
            operation: {
                id: obj.operation.id,
                Name: obj.operation.Name,
                APIProviderId: obj.operation.APIProviderId,
                APIKeyId: obj.operation.APIKeyId,
                APIProviderMethodId: obj.operation.APIProviderMethodId,
                ParamsValues: _.isString(obj.operation.ParamsValues) ? JSON.parse(obj.operation.ParamsValues) : obj.operation.ParamsValues,
                DataTransformations: _.isString(obj.operation.DataTransformations) ? JSON.parse(obj.operation.DataTransformations) : obj.operation.DataTransformations,
                ConditionalDataTransformations: _.isString(obj.operation.ConditionalDataTransformations) ? JSON.parse(obj.operation.ConditionalDataTransformations) : obj.operation.ConditionalDataTransformations,
                LastModified: obj.operation.LastModified,
                ExtendedParams: _.isString(obj.operation.ExtendedParams) ? JSON.parse(obj.operation.ExtendedParams) : obj.operation.ExtendedParams,
            },
            operationExt: {
                APIKeyName: obj.operation.APIKeyName,
                APIProviderMethodName: obj.operation.APIProviderMethodName,
                APIProviderName: obj.operation.APIProviderName
            },
            success: !_.isEmpty(obj.success) ? t.parseModel(obj.success) : null,
            fail: !_.isEmpty(obj.fail) ? t.parseModel(obj.fail) : null,
            always: !_.isEmpty(obj.always) ? t.parseModel(obj.always) : null
        }
    }

});