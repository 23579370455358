var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": _vm.$options.name,
        "view-box": "0 0 125.39 128",
        width: _vm.width,
        height: _vm.height,
      },
    },
    [
      _c("g", [
        _c("path", { attrs: { d: "M70.53 13.06H86.2v26.12H70.53z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M103 0H0v128h125.39V22.44zM26.12 5.22H94V47H26.12zm75.76 117.56h-81V67.92h81z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M34 83.59h18.24a2.61 2.61 0 1 0 0-5.22H34a2.61 2.61 0 1 0 0 5.22zM34 94h26.08a2.61 2.61 0 0 0 0-5.22H34A2.61 2.61 0 0 0 34 94zm34 0a2.62 2.62 0 0 0 1.87-4.45A2.62 2.62 0 1 0 67.92 94z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }