/* global window */
import jQuery from 'jquery';
// import logger from 'general/logger';
import MakeAuth from 'general/utils/MakeAuth';
import { getArrayPathname } from 'general/utils/utils';

const auth = MakeAuth();

window.jQuery = window.$ = jQuery;

// Logger
import '~@/general/utils/logger/RavenInit';

// импортируем легаси прелегаси код
require('./_old/bc');

// здесь уже норм ES6 модули новый экма все дела и вуе вместо бэкбона =)
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/ionicons-v4/ionicons-v4.css';

//import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '~@/../assets/fonts/fontawesome/css/all.css';

import './assets/stylus/quasar/index.styl';
import './scss/index.scss';
import './assets/stylus/general.styl';
import langManager from 'general/utils/LangManager';

// настройка языков
const langs = window.MOBSTEDAPP.langs || [];
langs.forEach(lang => langManager.registerLanguage(lang));
langManager.setLanguage(window.MOBSTEDAPP.lang);

// импорт модулей
import Vue from 'vue';
import axios from 'axios';
import AsyncComputed from 'vue-async-computed';
import vueBemCn from 'vue-bem-cn';
import VueCustomElement from 'vue-custom-element';
import Vddl from 'vddl';
import Tabs from 'vue-tabs-component';
import ToggleButton from 'vue-js-toggle-button';
import VueCollapse from 'vue2-collapse';
import vSelect from 'vue-select';
import 'document-register-element/build/document-register-element';
import MakeStore from 'general/utils/MakeStore';
import 'prismjs';
import 'prismjs/themes/prism.css';
import DevmodeController from './pages/constructor/utils/devmodeController';

import { MakeResource } from '../general/utils/MakeResource';
const $http = MakeResource();
Vue.prototype.$http = $http; // axios instance ($get, $post, ... return data)

import { ApiServicesFactory } from '../general/api/ApiFactory';
const $api = ApiServicesFactory($http)
Vue.prototype.$api = $api;

// регистрация глобальных компонентов
Vue.component('v-select', vSelect);

// установка плагинов
Vue.use(AsyncComputed);
Vue.use(vueBemCn, {
  hyphenate: true,
  methodName: 'b',
  delimiters: {
    ns: '',
    el: '__',
    mod: '_',
    modVal: '_',
  },
});
Vue.use(VueCustomElement);
Vue.use(Vddl);
Vue.use(Tabs);
Vue.use(ToggleButton);
Vue.use(VueCollapse);

Vue.filter('shortUUID', value => {
  if (!value) return '';
  return value.match(/(.+?)(?=-)/)[0];
});

//credit to @Bill Criswell for this filter
Vue.filter(
  'truncate',
  (text, stop, clamp) =>
    text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
);

// импорт пользовательских веб-компонентов
import WidgetSettings from './components/WidgetSettings/WidgetSettings';
import BotsSettings from './components/BotsSettings/BotsSettings';

const arrPath = getArrayPathname();
let store = {};
if (arrPath[0] !== 'applications') {
  store = MakeStore();
}


DevmodeController({ store });

/**
 *
 *
 * регистрация и настройка пользовательских веб-компонентов
 * важен порядок определения элементов в соответствии с зависимостями
 *
 *
 */
[
  WidgetSettings,
  BotsSettings,
].forEach(component => {
  Vue.customElement(component.name, component, {
    beforeCreateVueInstance(RootComponentDefinition) {
      // регистрация хранилища
      RootComponentDefinition.store = store;

      // регистрация плагина интернационализации
      RootComponentDefinition.i18n = langManager.getI18n();

      return RootComponentDefinition;
    },
  });
});

(function($){
  $(function(){
    auth.init();


    const location = window.location.pathname;

    $('#login-admin').on('submit', function (e) {
      //e.preventDefault();
      if (window.PasswordCredential) {
        if (e.target.password.value && e.target['admin-email'].value) {
          var c = new PasswordCredential({
            password: e.target.password.value,
            name: e.target['admin-email'].value,
            email: e.target['admin-email'].value,
            id: `${e.target['admin-email'].value}`,
          });
          return window.navigator.credentials.store(c);
        }
      }
    })

    if ((window.PasswordCredential || window.FederatedCredential) && !auth.getAccessToken() && location === '/users/login') {
      window.navigator.credentials.get({
        password: true,
        mediation: 'required'
      })
        .then(c => {
          $('[autocomplete="username email"]').val(c.email || c.name);
          $('[autocomplete="new-password"]').val(c.password);
          $('#login-admin').submit();
        })
        .then(profile => {
          if (profile) {
            // updateUI(profile);
          }
        })
    }


    if (location === '/users/forgot') {
      $('#recoverypassword').on('submit', (e) => {
        e.preventDefault();

        let data = {
          Email: e.target.email_or_phone.value,
          Self: true
        };

        $('#recoverypassword').find('input').css('border-color', '#ccc'); //это спегетти код, и форма тоже старьё, не хочу ничего переделывать тут чтоб реализовать это по нормальному

        axios.post('/logintap/webhook', data)
          .then((response) => {

            setTimeout(function() {
              window.location.href = '/users/login';
            }, 8000);

            let message = response.data.meta.message || 'Message with instructions sent. Please follow instructions in the message to reset your password';
            $('#recoverypassword').html(`<header class="login-box__title"><span><div class="text-success">${message}</div></span></header>`)

          })
          .catch((error) => {

            setTimeout(function() {
              window.location.href = '/users/login';
            }, 8000);

            let message = 'Message with instructions sent. Please follow instructions in the message to reset your password';
            $('#recoverypassword').html(`<header class="login-box__title"><span><div class="text-success">${message}</div></span></header>`)

            let response = error.response.data;
            let errors = response.errors;

            errors.forEach((validationError) => {
              // logger.log(validationError);
              // console.log(validationError);

              let parameter = validationError.source.parameter;
              parameter = parameter.replace(/\[|\]/g,'');

              $('#recoverypassword').find(`[name="${parameter}"]`).css('border-color', 'red');
              $('#recoverypassword_error').text(validationError.detail);

            })
          });
      });

    }
  });
})(jQuery);

import './layouts/app-root';
