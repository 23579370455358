import { backgroundDefault } from './helper';
import { ExtendDefault } from './defaultProperties'

export default ExtendDefault({
  name: 'mbst-button',
  aliasName: 'Button',
  properties: {
    backendname: 'button',
    text: 'My Button',
    typeButton: '',
    roundButton: '',
    visibility: {
      conditions: [],
      hidden: false,
    },
    iconCls: '',
    iconPosition: 'left',
    iconStack: false,
    iconInlinePosition: 'text'
  },
  actions: [],
  css: {
    all: [
      {
        selector: '.mbst-button',
        rules: {
          'font-size': '17px',
          'line-height': '2em',
          'white-space': 'nowrap',
          'border-radius': '3px 3px 3px 3px'
        }
      }
    ],
    // ios: [
    //   {
    //     selector: '.mbst-button',
    //     rules: [
    //       { directive: 'font-size', value: '17px' },
    //       { directive: 'line-height', value: '2em' },
    //       { directive: 'white-space', value: 'nowrap' },
    //       { directive: 'border-radius', value: '3px' },
    //       ...backgroundDefault
    //     ]
    //   }
    // ],
    // android: [
    //   {
    //     selector: '.mbst-button',
    //     rules: [
    //       { directive: 'font-size', value: '17px' },
    //       { directive: 'line-height', value: '2em' },
    //       { directive: 'white-space', value: 'nowrap' },
    //       { directive: 'border-radius', value: '3px' },
    //       ...backgroundDefault
    //     ]
    //   }
    // ]
  },
})
