const dict = require('general/lang/WidgetEditorPropertyPane.json');
const $t = (prop, def) => {
  if (window.MOBSTEDAPP && window.MOBSTEDAPP.lang) {
    return dict[window.MOBSTEDAPP.lang]['w-image'][prop] || def || prop;
  } else {
    return def || prop;
  }
}

const properties = {
  src: {
    uuid: null,
    componentName: 'ControlFile',
    properties: {
      label: $t('src', 'Image'),
    },
    vModelPropertyName: null,
  },
};

export default {
  properties,
};
