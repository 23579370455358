<template>
  <div class="b-spinner-disabled">
    <div>
      <div class="b-spinner b-spinner--anim b-spinner--primary is-lg"></div>
      <div class="b-spinner__text" v-if="isText"><slot>{{ lang('Loading Data...') }}</slot></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'spinner',
    props: {
      isText: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      lang (msg) {
        return lang(msg);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .b-spinner-disabled {
    text-align: center;
    z-index: 1020;

    .b-spinner {
      width: 60px;
      height: 60px;
    }
  }
  .b-spinner__text {
    font-size: .95em;
    color: #4a535e;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
  }
</style>
