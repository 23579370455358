<template>
  <div class="b-settings-box b-bot-settings" style="position: relative">
    <spinner v-if="(isProcessSave)" :is-text="false"></spinner>
    <div>
      <div class="b-settings-box__header">
        <span>{{ type }}</span> <a :href="'/bot/instruction?type='+bot.type"
                                   target="_blank"
                                   class="btn-sm"><span>{{ lang('Instruction') }}</span> <i class="fa fa-question-circle"></i></a>
      </div>

      <div class="b-settings-box__body">
        <div v-if="bot.type==='facebookbots'">
          <!--<fb:login-button scope="public_profile,email" onlogin="checkLoginState();">-->
          <!--</fb:login-button>-->
          {{ accessToken }}
          <button @click="buttonClicked()">
            <i class="spinner" v-if="isWorking"></i>Login as Facebook
            {{getButtonText}}
          </button>
          <button @click="getPages()">Get Pages</button>
          <ul>
            <li v-for="page in pages">
              <input type="radio" v-model="selectedPage" :value="page.id"> {{ page.name }}
            </li>
          </ul>
          <div v-if="pages.length>0">
            <button @click="setWebhook()">Set webhook</button>
          </div>

          <div v-if="isConnected"
               class="information">
            <h1>My Facebook Information</h1>
            <div class="well">
              <div class="list-item">

                <i>{{name}}</i>
              </div>
              <div class="list-item">

                <i>{{email}}</i>
              </div>
              <div class="list-item">

                <i>{{personalID}}</i>
              </div>
            </div>
          </div>

        </div>

        <div class="form-group" v-if="bot.type === 'viberbots'">
          <label class="control-label">{{ lang('Icon') }}</label>
          <div class="b-bot-settings__icon">
            <img :src="files.length ? files[0].url : bot.IconUrl ? bot.IconUrl : defaultIcon" />
            <file-upload
                    extensions="jpg,jpeg"
                    accept="image/jpeg"
                    id="UploadedFiles"
                    name="UploadedFiles[image]"
                    class="btn btn-default"
                    post-action="/bot/uploadfiles"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload">
              <i class="fa fa-upload mr5"></i> <span>{{ lang('Upload Icon') }}</span>
            </file-upload>
          </div>
        </div>
        <div class="form-group" v-else>
          <label class="control-label">{{ lang('Icon') }}</label>
          <div class="b-bot-settings__icon">
            <img :src="bot.IconUrl ? bot.IconUrl : defaultIcon" />
            <a :href="bot.IconUrl ? bot.IconUrl : defaultIcon"
               :download="downloadTitle"
               class="btn btn-default"><i class="fa fa-download mr5"></i> <span>{{ lang('Download Icon') }}</span></a>
          </div>
        </div>

        <div class="form-group" v-bind:class="classes.Name" v-if="(bot.LastAction)==null || (bot.LastAction)==''">
          <label for="botname" class="control-label">{{ lang('Last activity') }}:
            {{formatDate(bot.lastactivitytz)}}   </label>
          <label for="botname" :style="activityStyle" class="control-label">&nbsp;__</label>
          <span class="help-block" v-if="helpTexts.Name && helpTexts.Name.length > 0">{{helpTexts.Name}}</span>
        </div>

        <div class="form-group" v-bind:class="classes.Name"  >
          <label for="botname" class="control-label" id ="LastAction" :style="{ fontWeight: 'bold' }" >
            {{getTextStatus}}
          </label>
        </div>

        <div class="form-group" v-bind:class="classes.Name">
          <label for="botname" class="control-label">{{ lang('Bot Name') }}</label>
          <input type="text" name="botname" class="form-control" id="botname" v-model="bot.Name">
          <span class="help-block" v-if="helpTexts.Name && helpTexts.Name.length > 0">{{helpTexts.Name}}</span>
        </div>

        <div class="form-group" v-bind:class="classes.Token">
          <label for="token" class="control-label">{{ lang('Token') }}</label>
          <input type="text" name="token" class="form-control" id="token"  v-model="bot.Token">
          <span class="help-block" v-if="helpTexts.Token && helpTexts.Token.length > 0">{{helpTexts.Token}}</span>
        </div>

        <div class="form-group" v-bind:class="classes.ResponseCode" v-if="bot.type === 'facebookbots'">
          <label for="responsecode" class="control-label">{{ lang('Response Code') }}</label>
          <input type="text" name="token" class="form-control" id="responsecode"  v-model="bot.ResponseCode">
          <span class="help-block" v-if="helpTexts.ResponseCode && helpTexts.ResponseCode.length > 0">{{helpTexts.ResponseCode}}</span>
        </div>

        <div class="form-group" v-if="bot.type !== 'viberbots' && bot.type !== 'telegrambots'">
          <label for="webhook" class="control-label">{{ lang('Webhook Address') }}</label>
          <input type="text" name="webhook" class="form-control" id="webhook" readonly :value="webhook">
        </div>

        <div class="form-group" v-bind:class="classes.Secret" v-if="bot.type !== 'viberbots' && bot.type !== 'telegrambots'">
          <label for="botsecret" class="control-label">{{ lang('Bot Secret') }}</label>
          <input type="text" name="botsecret" class="form-control" id="botsecret"   v-model="bot.Secret">
          <span class="help-block" v-if="helpTexts.Secret && helpTexts.Secret.length > 0">{{helpTexts.Secret}}</span>
        </div>

        <div class="form-group" v-bind:class="classes.Confirmation"  v-if="bot.type !== 'viberbots' && bot.type !== 'telegrambots'">
          <label for="botconfirmation" class="control-label">{{ lang('Bot Confirmation') }}</label>
          <input type="text" name="botconfirmation" class="form-control" id="botconfirmation"  v-model="bot.Confirmation">
          <span class="help-block" v-if="helpTexts.Confirmation && helpTexts.Confirmation.length > 0">{{helpTexts.Confirmation}}</span>
        </div>

        <div class="form-group">
          <button class="btn btn-danger pull-left"
                  :disabled="isProcessDelete || blockDeleteButton"
                  @click="deleteBot()"><i class="far fa-trash-alt mr5"></i> <span>{{ lang('Delete') }}</span></button>
          <button class="btn btn-success pull-right"
                  :disabled="isProcessSave || blockSaveButton"
                  @click="save()"><i class="far fa-save mr5"></i> <span>{{ lang('Save') }}</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FileUpload from 'vue-upload-component';
  import http from 'axios';
  import moment from 'moment';
  import Spinner from '@/bc/components/FullContainerSpinner';

  // Vue.filter('formatDate', function(value) {
  //   if (value) {
  //     return moment(String(value)).format('MM/DD/YYYY hh:mm')
  //   }
  // }
  export default {
    name: 'bot-item',
    components: { FileUpload, Spinner },
    props: {
      bot: {
        type: Object,
        required: true
      },
      appId: {
        required: true
      },
      defaultIcon: {
        required: true
      },
      http: {
        required: true
      },
    },
    data() {
      return {
        classes: {
          Name: '',
          Token: '',
          ResponseCode: '',
          Secret: '',
          Confirmation: '',
        },
        helpTexts: {
          Name: '',
          Token: '',
          ResponseCode: '',
          Secret: '',
          Confirmation: '',
        },
        files: [],
        isProcessSave: false,
        isProcessDelete: false,
        blockDeleteButton: false,
        blockSaveButton: false,
        statusText:'',
        edit: false,
        FB: {},
        isConnected: false,
        name: '',
        email: '',
        personalID: '',
        isWorking: false,
        facebookApiVersion: 'v3.3',
        facebookAppId: '448487628944762',
        client_secret:'656be4949c406cf88195e382d77b79aa',
        accessToken: '',
        selectedPage:'',
        pages:[],
        activityStyle: {
          background: this.getBg(this.bot.lastactivitytz, this.bot.nowforbot),
          margin: '15px',
          //filter: 'blur(10px)',
          width: '7px',
          height: '7px',
          borderRadius: '50%',
          display: 'inline-flex',
          fontSize: '50',
          color: '#fff',
          lineHeight: '10px',
          textAlign: 'center'
        }
      }
    },
    computed: {
      getTextStatus() {
        let value = (this.bot.LastAction);
        let nowDate = new Date();
        nowDate.setHours(nowDate.getHours()+1);
        let timeX = moment(nowDate, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY HH:00:00');
        if (value === 'updated'){
          return `This bot mark as updated. Changes will be deployed to bot server at ${((timeX))}`;
        };
        if (value === 'inserted'){
          return `Created. This bot will be deployed to bot server at ${((timeX))}`;
        };
        if (value === 'deleted'){
          return `This bot mark as deleted. This bot will be deactivated at ${((timeX))}`;
        };
      },
      getButtonText() {
        switch (this.isConnected) {
          case true:
            return this.logoutLabel
          case false:
            return this.loginLabel
          default:
            return 'this is default'
        }
      },
      webhook() {
        return `${window.location.origin}/bot/${this.bot.Port}`
      },
      type() {
        const names = {
          'telegrambots': this.lang('Telegram Bot'),
          'viberbots': this.lang('Viber Bot'),
          'facebookbots': this.lang('Facebook Messenger Bot'),
          'vkbots': this.lang('VK Bot')
        };
        return names[this.bot.type];
      },
      downloadTitle () {
        return `icon-${this.type.toLowerCase().split(' ').join('-')}`
      }
    },
    async created () {
      if (!this.bot.id) {
        this.isProcessDelete = !this.isProcessDelete;
      }

      if (this.bot.type === 'facebookbots' && typeof this.bot.id !== 'undefined') {
        const responseStatus = await this.getLoginStatus();
        if (responseStatus.status === 'connected') {
          this.accessToken = responseStatus.authResponse.accessToken;
          this.isConnected = true;
        }
        this.isWorking = false;
      }
    },
    methods: {
      formatDate (value, fmt = 'D MMM YYYY HH:mm:ss') {// hh or HH - depend on server side
        return (value == null)
          ? ''
          : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)// hh or HH - depend on server side
      },
      getBg (value, value2, fmt = 'D MMM YYYY HH:mm:ss') {// hh or HH - depend on server side
        let strDate = (value == null)
          ? ''
          : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt);// hh or HH - depend on server side
        let strNowDate = (value2 == null)
          ? ''
          : moment(value2, 'YYYY-MM-DD HH:mm:ss').format(fmt);// hh or HH - depend on server side
        let serverDate = new Date(strDate);
        //let nowDate = new Date(strNowDate);
        let nowDate = new Date();
        //console.log('nowDate',nowDate);
        //console.log('serverDate',serverDate);
        const difference = nowDate - serverDate;
        //console.log('difference',difference);
        const diffHour = Math.floor(difference/1000/60/60);//difference is miliseq
        //console.log('diffHour',diffHour);
        if (diffHour<2){
          return '#0f0';
        }
        if (diffHour>=2 && diffHour<6){
          return '#fff95b';
        }
        return '#F00';
      },
      async setWebhook()
      {
        let pageToken='';
        _.each(this.pages,(page)=>
        {
          if (page.id===this.selectedPage) {
            pageToken = page.access_token;
          }
        })
        let resp=await http.post('https://graph.facebook.com/v3.3/'+this.selectedPage+'/subscribed_apps?access_token='+pageToken,{

          subscribed_fields : 'messages,' +
            'message_deliveries,' +
            'messaging_optins,' +
            'messaging_optouts,' +
            'messaging_postbacks,' +
            'message_reads,' +
            'messaging_page_feedback,' +
            'messaging_direct_sends,' +
            'public_profile,' +
            'email,',


        });
        let apps=await http.get('https://graph.facebook.com/v3.3/'+this.selectedPage+'/subscribed_apps?access_token='+pageToken);
        apps=apps.data.data;

        let tokens=await http.post('/bot/getFacebookKeys',{client_id:this.facebookAppId,client_secret:this.client_secret});
        console.log('tokens',tokens)

        let resp2=await http.post('https://graph.facebook.com/v3.3/'+apps[0].id+'/subscriptions?access_token='+tokens.data.access_token,{
          object: 'page',
          callback_url: this.webhook,
          fields : 'feed, photos',
        include_values : 'true',
        verify_token : 'thisisaverifystring',

        });
        console.log(resp)
        console.log(resp2)
      },
      getLoginStatus() {
        if (window.FB && window.FB.getLoginStatus) {
          return new Promise(
            resolve => window.FB.getLoginStatus(responseStatus => resolve(responseStatus)),
          );
        }
      },
      fbLogin(options) {
        if (window.FB && window.FB.login) {
          return new Promise(resolve => {
            window.FB.login(response => resolve(response), options);
          });
        }
      },
      fbLogout() {
        if (window.FB && window.FB.logout) {
          return new Promise(resolve => {
            window.FB.logout(response => resolve(response));
          });
        }
      },

      buttonClicked() {
        this.$emit('click')
        if (this.isConnected) {
          this.logout()
        } else {
          this.login()
        }
      },
      login() {
        this.isWorking = true
        let options={scope:
            'public_profile,' +
            'email,' +
            'manage_pages,' +
            'pages_show_list,' +
            'pages_messaging,' +
            'pages_messaging_subscriptions'
            };
        console.log(options)
        console.log(this.loginOptions)
        this.fbLogin(options)
          .then(response => {
            if (response.status === 'connected') {
              this.isConnected = true
            } else {
              this.isConnected = false
            }
            this.isWorking = false
            this.$emit('login', {
              response,
              FB: window.FB
            })
          })
      },
      logout() {
        this.isWorking = true
        this.fbLogout()
          .then(response => {
              this.isWorking = false
              this.isConnected = false
              this.$emit('logout', response)
            }
          )
      },


      async getPages()
      {
        let resp = await http.get(
          `https://graph.facebook.com/v3.3/me/accounts?access_token=${this.accessToken}`
        );
        this.pages=resp.data.data;
        console.log(resp);
      },


      lang(msg) {
        return window.lang(msg);
      },
      inputFile(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          this.$nextTick(function () {
            this.edit = true
          })
        }
        if (!newFile && oldFile) {
          this.edit = false
        }
      },

      inputFilter(newFile, oldFile, prevent) {
        // Add file
        if (newFile && !oldFile) {
          if (newFile.size > 512000) {
            grit(this.lang('Size no more than 512 kb'), 'growl-danger')
            return prevent()
          }
          if (!/\.(jpg|jpeg)$/i.test(newFile.name)) {
            grit(this.lang('Your choice is not a JPEG'), 'growl-danger')
            return prevent()
          }
        }

        if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
          newFile.url = ''
          let URL = window.URL || window.webkitURL
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file)
          }
        }

        // Update file
        if (newFile && oldFile) {
          // Uploaded successfully
          if (newFile.success !== oldFile.success) {
            const { url, error } = JSON.parse(newFile.response)
            if (!error) {
              this.bot.IconUrl = url;
            }
            if (!this.bot.id) {
              this.create();
            } else {
              this.update();
            }
          }
        }

      },
      save() {
        this.isProcessSave = true;
        this.blockDeleteButton = true;
        this.blockSaveButton = true;
        if (this.$refs.upload && !this.$refs.upload.active && this.edit) {
          this.$refs.upload.active = true;
        } else {
          if (!this.bot.id) {
            this.create();
          } else {
            this.update();
          }
        }
      },

      afterSuccessDelete() {
        //this.isProcessDelete = true;

        // if (this.bot.id) {
        //   this.bot.id = undefined;
        //   this.bot.isWorking = false;
        //   this.bot.isConnected = false;
        // }
        //
        // if (this.bot.Name) {
        //   this.bot.Name = '';
        // }
        // if (this.bot.Token) {
        //   this.bot.Token = '';
        // }
        // if (this.bot.ResponseCode) {
        //   this.bot.ResponseCode = '';
        // }
        // if (this.bot.Secret) {
        //   this.bot.Secret = '';
        // }
        // if (this.bot.Confirmation) {
        //   this.bot.Confirmation = '';
        // }
        // if (this.bot.LastActivity) {
        //   this.bot.LastActivity = null;
        // }
        // if (this.bot.lastactivitytz) {
        //   this.bot.lastactivitytz = null;
        // }
        this.activityStyle.background = this.getBg(null, null);
      },

      async deleteBot() {
        this.isProcessDelete = true;
        this.blockDeleteButton = true;
        this.blockSaveButton = true;
        this.classes = {};
        this.helpTexts = {};
        let res = null;
        try {

          let params = {
            Type: this.bot.type,
            ApplicationId: this.bot.ApplicationId,
            Name: this.bot.Name,
            TenantName: this.bot.TenantName
          };

          res = await this.http.delete(`/api/v5/delete/${this.bot.id}`, {data: params});
          console.log(res);
        } catch (e) {
          console.log(e.response.data);

          if (e && e.response && e.response.data && e.response.data.errors) {
            console.log(e.response.data);
            for (let value in e.response.data.errors) {
              let currentError = e.response.data.errors[value];
              console.log('currentError', currentError);

              gritlong(`Fail update: ${currentError.title}  (${currentError.detail}) `, 'growl-danger');
              if (typeof  currentError.source !== 'undefined')
              if (typeof  currentError.source.parameter !== 'undefined') {
                let paramName = currentError.source.parameter;
                console.log('paramName', paramName);

                let replacedParamName = paramName.replace(/(\[|\])/g, '');

                this.classes[replacedParamName] = 'has-error';
                this.helpTexts[replacedParamName] = currentError.detail;
              }
            }
          }
          console.log('Err delete bot: ', e);
        }
        if (res && res.status === 200) {
          this.afterSuccessDelete();

          let curDate = new Date();
          let curHour = curDate.getHours();
          gritlong(`Deleted ${this.type}. This bot will be deactivated at ${((curHour) + 1)}:00`, 'growl-success');
          let formData = new FormData();
          formData.set('LastAction', '');
          this.bot.LastAction = 'deleted';

        } else {
          this.isProcessDelete = false;
          grit(`Fail delete ${this.type}`, 'growl-danger');
        }

        console.log('Result Delete', res);
      },

      async create() {
        let res = null;
        this.classes = {};
        this.helpTexts = {};
        try {
          //console.log(this.bot);
          let formData = new FormData();
          formData.set('Type', this.bot.type);
          formData.set('ApplicationId', this.bot.res.applicationId || this.bot.ApplicationId);

          if (this.bot.Name) {
            formData.set('Name', this.bot.Name);
          }

          if (this.bot.Token) {
            formData.set('Token', this.bot.Token);
          }
          if (this.bot.ResponseCode) {
            formData.set('ResponseCode', this.bot.ResponseCode);
          }
          if (this.bot.Secret) {
            formData.set('Secret', this.bot.Secret);
          }
          if (this.bot.Confirmation) {
            formData.set('Confirmation', this.bot.Confirmation);
          }
          formData.set('TenantName', this.bot.tenantName);
          formData.set('IconUrl', this.bot.IconUrl ? this.bot.IconUrl : window.location.origin + this.defaultIcon);

          res = await this.http.post(`/api/v5/create`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          console.log(res);
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.errors) {
            console.log(e.response.data);

            for (let value in e.response.data.errors) {
              let currentError = e.response.data.errors[value];
              console.log('currentError', currentError);

              gritlong(`Fail update: ${currentError.title}  (${currentError.detail}) `, 'growl-danger');
              if (typeof  currentError.source !== 'undefined')
              if (typeof  currentError.source.parameter !== 'undefined') {
                let paramName = currentError.source.parameter;
                console.log('paramName', paramName);

                let replacedParamName = paramName.replace(/(\[|\])/g, '');

                this.classes[replacedParamName] = 'has-error';
                this.helpTexts[replacedParamName] = currentError.detail;
              }

            }
          }
          console.log('Err update bot: ', e);
        }

        let messageNotificationPopupText = '';
        let messageNotificationPopupColor = '';
        console.log(res);
        console.log(res.status);
        console.log(res.data);
        console.log(res.data.id);
        //console.log(res.data.data);
        //console.log(res.data.data[0]);
        //console.log(res.data.data.id);

        if (res && res.status === 200 &&  res.data  ) {
          //&& res.data.data && res.data.data.id
          let rootRepsonse = res.data;

          if (res.data.data && res.data.data.id){
            let rootRepsonse = res.data.data;
          }
          //else if (res.data.id){
          //  let rootRepsonse = res.data;
          //}

          messageNotificationPopupColor = 'positive';
          this.bot = {...this.bot, ...rootRepsonse.attributes};
          this.bot.id = rootRepsonse.id;
          this.isProcessDelete = false;
          this.activityStyle.background = this.getBg(rootRepsonse.attributes.lastactivitytz, rootRepsonse.attributes.nowforbot);

          let curDate = new Date();
          let curHour = curDate.getHours();
          messageNotificationPopupText = `Saved ${this.type}. This bot will be deployed to bot server at ${((curHour) + 1)}:00`;
          let formData = new FormData();
          formData.set('LastAction', '');
          this.bot.LastAction = 'inserted';

        } else {
          messageNotificationPopupText = `Failed save ${this.type}`;
          messageNotificationPopupColor = 'negative';
        }

        this.$q.notify({
          message: messageNotificationPopupText,
          color: messageNotificationPopupColor
        });

        this.isProcessSave = false;
        console.log('Result CreateUpdate', res);
      },
      async update() {


        this.classes = {};
        this.helpTexts = {};
        let res = null;
        try {

          let params = {
            Type: this.bot.type,
            ApplicationId: this.bot.ApplicationId,
            Name: this.bot.Name,
            IconUrl: this.bot.IconUrl ? this.bot.IconUrl : window.location.origin + this.defaultIcon
          };

          if (this.bot.Secret) {
            params['Secret'] = this.bot.Secret;
          }

          if (this.bot.Confirmation) {
            params['Confirmation'] = this.bot.Confirmation;
          }

          if (this.bot.TenantName) {
            params['TenantName'] = this.bot.TenantName;
          }
          if (this.bot.ResponseCode) {
            params['ResponseCode'] = this.bot.ResponseCode;
          }
          if (this.bot.Token) {
            params['Token'] = this.bot.Token;
          }

          res = await this.http.put(`/api/v5/update/${this.bot.id}`, params);
          console.log(res);
        } catch (e) {
          console.log(e.response.data);

          if (e && e.response && e.response.data && e.response.data.errors) {
            console.log(e.response.data);

            for (let value in e.response.data.errors) {
             let currentError = e.response.data.errors[value];
              console.log('currentError', currentError);

              gritlong(`Fail update: ${currentError.title}  (${currentError.detail}) `, 'growl-danger');
              if (typeof  currentError.source !== 'undefined')
              if (typeof  currentError.source.parameter !== 'undefined'){
                let paramName = currentError.source.parameter;
                console.log('paramName', paramName);

                let replacedParamName = paramName.replace(/(\[|\])/g, '');

                this.classes[replacedParamName] = 'has-error';
                this.helpTexts[replacedParamName] = currentError.detail;
              }
            }
          }
          console.log('Err update bot: ', e);
        }
        if (res && res.status === 200) {
          if (typeof res.data.ErrorText === 'undefined') {
            this.activityStyle.background = this.getBg(res.data.data[0].attributes.lastactivitytz, res.data.data[0].attributes.nowforbot);


            let curDate = new Date();
            let curHour = curDate.getHours();
            gritlong(`Saved ${this.type}. Changes will be deployed to bot server at ${((curHour) + 1)}:00`, 'growl-success');
            let formData = new FormData();
            formData.set('LastAction', '');
            this.bot.LastAction = 'updated';

          } else grit(`Fail update ${this.type}`, 'growl-danger');
        } else grit(`Fail update ${this.type}`, 'growl-danger');

        this.isProcessSave = false;
        console.log('Result CreateUpdate', res);

      }
    }
  }
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .b-bot-settings
    .b-settings-box__header
      display flex
      justify-content space-between
      align-items center

      a
        font-size .65em
        background-color rgba($brand-primary, .25)

    &__icon
      display flex
      align-items center

      img
        margin-right 40px
        border-radius 50%
        object-fit contain
        width 100px
        height 100px
</style>
