'use strict';

// Libraries
var _ = require('underscore');
var Backbone = require('backbone');

// Templates
var _baseModal = require('text!bcTemplates/modal-hero/modal.html');
var _headerModal = require('text!bcTemplates/modal-hero/modal-header.html');
var _footerModal = require('text!bcTemplates/modal-hero/modal-footer.html');

module.exports = Backbone.View.extend({

    tagName: 'div',
    className: 'details-wrapper b-modal-hero__inner', //.b-modal-hero--tab

    initialize: function(options) {
        var t = this;

        if (options.parentView) {
            t.parentView = options.parentView;
        }

        t.baseTpl = _.template(_baseModal);
        t.headerTpl = _.template(_headerModal);
        t.footerTpl = _.template(_footerModal);
        t.isFooter = options.isFooter;

        t.data = {
            header: '',
            body: '',
            footer: ''
        };
    },

    render: function() {
        var t = this;

        if (_.isEmpty(t.data.header)) {
            t.data.header = t.headerTpl(t.model.get('header'));
        }

        if (_.isEmpty(t.data.body)) {
            t.data.body = t.model.get('body');
        }

        if (t.isFooter && _.isEmpty(t.data.footer)) {
            t.data.footer = t.footerTpl(t.model.get('footer'));
        }

        t.$el.addClass('col-md-12').html(t.baseTpl(t.data));
        t.show('#modal-hero', t.$el);
    },

    show: function(object, html) {
        $(object)
            .html(html)
            .fadeIn(150);

        window.setTimeout(function () {
            $(".js-autofill-off").hide();
        },1);

        var $modalHero = $(object),
            $modalHeroInner = $modalHero.find('.b-modal-hero__inner'),
            isTabModal = false,
            heightHeader = $modalHeroInner.find('.b-modal-hero__heading').outerHeight(),
            heightFooter,
            heightBody,


            hHeading = this.heightHeading($(object+' .details-heading')),
            hFooter = this.heightHeading($(object+' .details-footer')) || 0,
            hBody = $(object+' .details-wrapper').hasClass('col-md-12') ? '' : this.heightHeading($(object+' .details-body')),
            hAll = hHeading + hFooter + hBody;

        if ($modalHeroInner.hasClass('b-modal-hero--tab')) {
            isTabModal = true;
        }

        if (isTabModal) {
            var $activePane = $modalHeroInner.find('.b-modal-hero__tab-pane.active');
            if ($modalHeroInner.hasClass('col-md-12')) {
                $modalHeroInner.css({
                    'padding-bottom': heightHeader + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                var $modalHeroFooter = $activePane.find('.b-modal-hero__footer');
                heightFooter = $modalHeroFooter.outerHeight();
                if ($modalHeroFooter) {
                    $activePane.css("padding-bottom", heightFooter);
                    $modalHeroFooter.css("top", -heightFooter + "px");
                }
            } else {
                $activePane.find('.b-modal-hero__body').css("height", "auto");
                $modalHeroInner.find('.b-modal-hero__tab-content').css({
                    height: "auto",
                    overflow: "auto"
                });
                $modalHeroInner.css({
                    'padding-bottom': (hAll - hBody) + 'px',
                });
            }

        } else {

            if ( $(object+' .details-wrapper').hasClass('col-md-12') ) {
                $(object+' .details-wrapper').css({
                    'padding-bottom': (hAll - hBody) + 'px',
                    'margin': '30px',
                    'margin-bottom': '90px',
                    'width': 'auto'
                });
                $(object+' .details-body').css('height', '100%');
            } else if ($(object+' .details-wrapper').attr('id') === "donosetheightinJS") {
                $(object+' .details-wrapper').css({
                    'padding-bottom': (hAll - hBody) + 'px',
                    'height': 'auto'
                });
            } else {
                $(object+' .details-wrapper').css({
                    'padding-bottom': (hAll - hBody) + 'px',
                    'height': hAll + 'px'
                });
            }

            $(object + ' .details-footer').css('top', - hFooter + 'px');

        }

    },

    heightHeading: function(heading) {
        var h = heading.outerHeight();
        return h;
    },

    close: function() {
        $('#modal-hero').hide();
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }

});