export default {

  /**
   * Action to correctly initialize the page (/pwaless/index/:appId/)
   * @param {Function} dispatch
   * @param {Function} commit
   * @param {Object} params
   * @param {number} params.page
   * @param {number} params.pageSize
   * @param {number} params.appId
   * @returns {Promise<void>}
   */
  async fetchDataForPage({ dispatch, commit }, params = { page: 1, pageSize: 20, ...params }) {
    try {
      commit('set', { path: 'isLoadingPage', payload: true });
      const { data } = await dispatch('fetchData', params);

      if (!data) throw new Error(`Couldn't get a pwaless widget.`);
    }
    catch(e) {
      let message
      const { errors } = (e.response || {}).data || {};

      if (!errors) {
        console.error(e);
        message = e.message;
      } else {
        message = errors.reduce((acc, error) => {
          acc += `<p><b>${error.title}</b><br/>${error.detail}</p>`;
          return acc;
        }, '');
      }

      this.$q.notify({ message, color: 'negative', html: true });
    }
    finally {
      commit('set', { path: 'isLoadingPage', payload: false });
    }
  },

  async fetchData({ commit }, params = { page: 1, pageSize: 20, ...params }) {
    try {
      const payload = await this.$api.widgets.get({ params });

      if (!payload.data) throw new Error('Failed load lists of data tables');

      commit('set', { path: 'rawData', payload });
      return Promise.resolve(payload);
    }
    catch(e) {
      return Promise.reject(e);
    }
    finally {

    }
  },
}
