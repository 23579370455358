'use strict';

// Libraries
var _ = require('underscore');
import _lang from 'lodash/lang';
var Backbone = require('backbone');

// Views
var OperationSettingsView = require('views/operations/OperationSettingsView');

//models

var OperationsItemModel = require('models/OperationsItemModel.js');

// Templates
var _operationItem = require('text!bcTemplates/operations/operationItem.html');
var _operationDepends = require('text!bcTemplates/operations/operationDepends.html');

// Helper
var PubSub = require('helper/pubsub');

module.exports = Backbone.View.extend({

    tagName: 'tr',

    events: {
        'click td>a': 'editOperation',
        'click [data-js-toggle="editOperation"]': 'editOperation',
        'click [data-js-toggle="duplicateOperation"]': 'duplicateOperation',
        'click [data-js-toggle="deleteOperation"]': 'deleteOperation',
        'click #operationDependsOk' : 'closeDepends',
    },


    initialize: function(options) {
        this.operationsView = options.operationsView;
        this.tpl = _.template(_operationItem);
        this.tplDepends = _.template(_operationDepends);
        this.type = options.type || 'onDefault';

    },

    render: function() {

        var t = this;
        t.$el.html(t.tpl({
            type: t.type,
            triggerOperation: t.model.toJSON(),
        }));

    },

    close: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    },

    editOperation: function(e) {
        e.preventDefault();

        var operationSettingsView = new OperationSettingsView({
            operationView: this,
            model: this.model.get('operation')
        });
        operationSettingsView.render();
    },

    duplicateOperation: function(e) {
      var t = this;
      $.ajax({
        url: '/triggers/operationsapi/duplicate/' + t.model.get('id'),
        type: 'POST',
        data: {},
      }).done(function(a, b, c) {
        var json = JSON.parse(a);

        let modelJson = _lang.cloneDeep(t.model.toJSON());
        modelJson.id = json.query.id;
        modelJson.operation.Name = json.query.Name;
        modelJson.operation.id = json.query.id;
        console.log(json.query);
        console.log(modelJson);
        var model = new OperationsItemModel();
        model.set(modelJson);

        t.operationsView.collection.add(model);
        t.operationsView.render();
        grit(lang('Saved'), 'growl-success');
      }).fail(function(a, b, c) {
        grit(lang('Error'), 'growl-danger');
      });


      },

    renderOnDepend: function (t, depends) {
        grit(lang('Dependencies exist'), 'growl-danger');

        var id = t.model.get('id');
        var dependsDiv = $('#depend-elements-' + id);
        var tableDepends = dependsDiv.find('table');
        tableDepends.html('');
        _.each(depends.applications, function(app) {
            tableDepends.append(t.tplDepends({
                app: app
            }));
        });

        dependsDiv.attr('style', 'display: block');
    },

    closeDepends: function (e) {
        e.preventDefault();
        var t = this;
        var id = t.model.get('id');
        var dependsDiv = $('#depend-elements-' + id);
        dependsDiv.attr('style', 'display: none');
    },

    deleteOperation: function(e) {
        e.preventDefault();

        var t = this;

        $.ajax({
            url: '/api/v7/operations/speclist/' + t.model.get('id'),
            type: 'GET',
            data: {},
        }).done(function(a, b, c) {
            if (_.isEmpty(a)) {

                t.model.destroy({
                    success: function(model, response, options) {
                        if (response && _.isEmpty(response.id))
                            t.close();

                    },
                    error: function(model, xhr, options) {

                    }

                });

            } else t.renderOnDepend(t, a);
        }).fail(function(a, b, c) {
            grit(lang('Error'), 'growl-danger');
        });

    },

    // Сохранение настроек операции
    save: function(isNew) {
        var t = this;
        if (isNew) {
            t.operationsView.collection.add(t.model);
        }
        t.operationsView.render();
    },



});