<i18n src="general/lang/WBotsInstructions.json"></i18n>

<template>
  <div
    :class="b()"
    v-if="isShown"
  >
    <button
      :class="b('button-close')"
      @click.prevent="close"
    >
      <IconClose width="10" />
    </button>
    <span>{{ $t('WBotsInstructions.a1') }}</span>
    <br>
    <div :class="b('columns')">
      <div
        v-if="isApple"
        :class="b('fake-button')"
      ></div>

      <img src="/img/instruction2.png" style="max-width:100%;"/>

    </div>

    <div
      v-if="hint"
      :class="b('hint')"
    >
      {{ hintMessage }}
    </div>

    <ServiceMessage
      :class="b('service-message')"
      v-bind="serviceMessageData"
    />
  </div>
</template>

<script>
  import UAParser from 'ua-parser-js';
  import { mapState, mapActions } from 'vuex';
  import MakeEventBus from 'general/utils/MakeEventBus';
  import { copyToClipboard, replaceSnippets } from 'general/utils/utils';
  import serviceMessageMixin from 'general/mixins/serviceMessageMixin';
  import ServiceMessage from 'general/components/ServiceMessage';
  import IconClose from 'general/components/icons/IconClose';
  import IconCopy from 'general/components/icons/IconCopy';
  import IconRefresh from 'general/components/icons/IconRefresh';

  const eventBus = MakeEventBus('wBots');

  const ua = new UAParser(window.navigator.userAgent);

  export default {
    name: 'w-bots-code',

    mixins: [
      serviceMessageMixin,
    ],

    components: {
      ServiceMessage,
      IconClose,
      IconCopy,
      IconRefresh,
    },

    props: {
      editorMode: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      isShown: true,
      isError: false,
    }),

    computed: {
      isApple() {
        const device = ua.getDevice();
        return (device && device.vendor) ? device.vendor.toLowerCase() === 'apple' : false;
      },
    },

    async created() {
      if (this.editorMode) {
        return false;
      }

    },

    methods: {
      close() {
        this.isShown = false;
        eventBus.$emit('code:closed');
      },
    },
  };
</script>

<style lang="stylus">
  @import '~stylus/_support.styl';

  .w-bots-code
    flex(center)
    flex-direction column
    background-color $brand-info
    padding 10px
    margin-y(10px)
    position relative
    overflow hidden
    box-shadow 0 0 5px 0 rgba(0, 0, 0, .2)
    overflow initial !important

    &__button-close
      button()
      button_radial()
      button_theme('close')
      flex(center, center)
      size(24px)
      position absolute
      top -10px
      right -10px
      z-index 1

    &__columns
      display flex
      flex-direction columns

    &__code
      margin-x(auto)
      typography(50px)
      font-weight 700
      color #fff
      letter-spacing 12px

    &__fake-button
      size(50px)

    &__button-copy
    &__button-refresh
      reset-button()
      link()
      size(50px)
      flex(center, center)
      flex-direction column
      padding 0
      color #fff
      outline 0

      span
        margin-top 5px
        typography(11px)
        text-transform uppercase

    &__button-copy
      margin-right auto

    &__button-refresh
      margin-left auto

    &__hint
      margin-top 10px
      font-weight 700
      color $color-black
      text-align center

    &__service-message
      margin-top 10px
      color #fff
      text-align center
</style>
