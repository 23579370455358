var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "b-settings-box b-bot-settings",
      staticStyle: { position: "relative" },
    },
    [
      _vm.isProcessSave
        ? _c("spinner", { attrs: { "is-text": false } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "b-settings-box__header" }, [
          _c("span", [_vm._v(_vm._s(_vm.type))]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-sm",
              attrs: {
                href: "/bot/instruction?type=" + _vm.bot.type,
                target: "_blank",
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.lang("Instruction")))]),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-question-circle" }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "b-settings-box__body" }, [
          _vm.bot.type === "facebookbots"
            ? _c("div", [
                _vm._v("\n        " + _vm._s(_vm.accessToken) + "\n        "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.buttonClicked()
                      },
                    },
                  },
                  [
                    _vm.isWorking
                      ? _c("i", { staticClass: "spinner" })
                      : _vm._e(),
                    _vm._v(
                      "Login as Facebook\n          " +
                        _vm._s(_vm.getButtonText) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.getPages()
                      },
                    },
                  },
                  [_vm._v("Get Pages")]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.pages, function (page) {
                    return _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedPage,
                            expression: "selectedPage",
                          },
                        ],
                        attrs: { type: "radio" },
                        domProps: {
                          value: page.id,
                          checked: _vm._q(_vm.selectedPage, page.id),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedPage = page.id
                          },
                        },
                      }),
                      _vm._v(" " + _vm._s(page.name) + "\n          "),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.pages.length > 0
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.setWebhook()
                            },
                          },
                        },
                        [_vm._v("Set webhook")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isConnected
                  ? _c("div", { staticClass: "information" }, [
                      _c("h1", [_vm._v("My Facebook Information")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "well" }, [
                        _c("div", { staticClass: "list-item" }, [
                          _c("i", [_vm._v(_vm._s(_vm.name))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list-item" }, [
                          _c("i", [_vm._v(_vm._s(_vm.email))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list-item" }, [
                          _c("i", [_vm._v(_vm._s(_vm.personalID))]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.bot.type === "viberbots"
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.lang("Icon"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "b-bot-settings__icon" },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.files.length
                          ? _vm.files[0].url
                          : _vm.bot.IconUrl
                          ? _vm.bot.IconUrl
                          : _vm.defaultIcon,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "file-upload",
                      {
                        ref: "upload",
                        staticClass: "btn btn-default",
                        attrs: {
                          extensions: "jpg,jpeg",
                          accept: "image/jpeg",
                          id: "UploadedFiles",
                          name: "UploadedFiles[image]",
                          "post-action": "/bot/uploadfiles",
                        },
                        on: {
                          "input-filter": _vm.inputFilter,
                          "input-file": _vm.inputFile,
                        },
                        model: {
                          value: _vm.files,
                          callback: function ($$v) {
                            _vm.files = $$v
                          },
                          expression: "files",
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-upload mr5" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.lang("Upload Icon")))]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.lang("Icon"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b-bot-settings__icon" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.bot.IconUrl ? _vm.bot.IconUrl : _vm.defaultIcon,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-default",
                      attrs: {
                        href: _vm.bot.IconUrl
                          ? _vm.bot.IconUrl
                          : _vm.defaultIcon,
                        download: _vm.downloadTitle,
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-download mr5" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.lang("Download Icon")))]),
                    ]
                  ),
                ]),
              ]),
          _vm._v(" "),
          _vm.bot.LastAction == null || _vm.bot.LastAction == ""
            ? _c(
                "div",
                { staticClass: "form-group", class: _vm.classes.Name },
                [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "botname" } },
                    [
                      _vm._v(
                        _vm._s(_vm.lang("Last activity")) +
                          ":\n          " +
                          _vm._s(_vm.formatDate(_vm.bot.lastactivitytz)) +
                          "   "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      style: _vm.activityStyle,
                      attrs: { for: "botname" },
                    },
                    [_vm._v(" __")]
                  ),
                  _vm._v(" "),
                  _vm.helpTexts.Name && _vm.helpTexts.Name.length > 0
                    ? _c("span", { staticClass: "help-block" }, [
                        _vm._v(_vm._s(_vm.helpTexts.Name)),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group", class: _vm.classes.Name }, [
            _c(
              "label",
              {
                staticClass: "control-label",
                style: { fontWeight: "bold" },
                attrs: { for: "botname", id: "LastAction" },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.getTextStatus) + "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group", class: _vm.classes.Name }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "botname" } },
              [_vm._v(_vm._s(_vm.lang("Bot Name")))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.bot.Name,
                  expression: "bot.Name",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "botname", id: "botname" },
              domProps: { value: _vm.bot.Name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.bot, "Name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.helpTexts.Name && _vm.helpTexts.Name.length > 0
              ? _c("span", { staticClass: "help-block" }, [
                  _vm._v(_vm._s(_vm.helpTexts.Name)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group", class: _vm.classes.Token }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "token" } },
              [_vm._v(_vm._s(_vm.lang("Token")))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.bot.Token,
                  expression: "bot.Token",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "token", id: "token" },
              domProps: { value: _vm.bot.Token },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.bot, "Token", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.helpTexts.Token && _vm.helpTexts.Token.length > 0
              ? _c("span", { staticClass: "help-block" }, [
                  _vm._v(_vm._s(_vm.helpTexts.Token)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.bot.type === "facebookbots"
            ? _c(
                "div",
                { staticClass: "form-group", class: _vm.classes.ResponseCode },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "responsecode" },
                    },
                    [_vm._v(_vm._s(_vm.lang("Response Code")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bot.ResponseCode,
                        expression: "bot.ResponseCode",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "token", id: "responsecode" },
                    domProps: { value: _vm.bot.ResponseCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.bot, "ResponseCode", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.helpTexts.ResponseCode &&
                  _vm.helpTexts.ResponseCode.length > 0
                    ? _c("span", { staticClass: "help-block" }, [
                        _vm._v(_vm._s(_vm.helpTexts.ResponseCode)),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.bot.type !== "viberbots" && _vm.bot.type !== "telegrambots"
            ? _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "webhook" } },
                  [_vm._v(_vm._s(_vm.lang("Webhook Address")))]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "webhook",
                    id: "webhook",
                    readonly: "",
                  },
                  domProps: { value: _vm.webhook },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.bot.type !== "viberbots" && _vm.bot.type !== "telegrambots"
            ? _c(
                "div",
                { staticClass: "form-group", class: _vm.classes.Secret },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "botsecret" },
                    },
                    [_vm._v(_vm._s(_vm.lang("Bot Secret")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bot.Secret,
                        expression: "bot.Secret",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "botsecret", id: "botsecret" },
                    domProps: { value: _vm.bot.Secret },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.bot, "Secret", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.helpTexts.Secret && _vm.helpTexts.Secret.length > 0
                    ? _c("span", { staticClass: "help-block" }, [
                        _vm._v(_vm._s(_vm.helpTexts.Secret)),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.bot.type !== "viberbots" && _vm.bot.type !== "telegrambots"
            ? _c(
                "div",
                { staticClass: "form-group", class: _vm.classes.Confirmation },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "botconfirmation" },
                    },
                    [_vm._v(_vm._s(_vm.lang("Bot Confirmation")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bot.Confirmation,
                        expression: "bot.Confirmation",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "botconfirmation",
                      id: "botconfirmation",
                    },
                    domProps: { value: _vm.bot.Confirmation },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.bot, "Confirmation", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.helpTexts.Confirmation &&
                  _vm.helpTexts.Confirmation.length > 0
                    ? _c("span", { staticClass: "help-block" }, [
                        _vm._v(_vm._s(_vm.helpTexts.Confirmation)),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-danger pull-left",
                attrs: {
                  disabled: _vm.isProcessDelete || _vm.blockDeleteButton,
                },
                on: {
                  click: function ($event) {
                    return _vm.deleteBot()
                  },
                },
              },
              [
                _c("i", { staticClass: "far fa-trash-alt mr5" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.lang("Delete")))]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success pull-right",
                attrs: { disabled: _vm.isProcessSave || _vm.blockSaveButton },
                on: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              },
              [
                _c("i", { staticClass: "far fa-save mr5" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.lang("Save")))]),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }